<ng-container *ngIf="{ expanded: expanded$ | async } as sync">
  <mat-nav-list [@sidenavAnimationIsExpanded]="sync.expanded" aria-label="Primary">
    <a class="home-link" aria-label="Home" mat-list-item routerLink="/">
      <ng-content select=".logo"></ng-content>
    </a>
    <mat-divider aria-hidden="true"></mat-divider>
    <ng-content></ng-content>
    <mat-divider aria-hidden="true"></mat-divider>
    <button
      class="toggle-button"
      [attr.aria-expanded]="sync.expanded"
      [attr.aria-label]="sync.expanded ? 'Collapse sidebar' : 'Expand sidebar'"
      mat-list-item
      [matTooltip]="sync.expanded ? 'Collapse sidebar' : 'Expand sidebar'"
      (click)="toggleExpand()">
      <span>
        <mat-icon *ngIf="sync.expanded === false" matListItemIcon>chevron_right</mat-icon>
        <mat-icon *ngIf="sync.expanded" matListItemIcon>chevron_left</mat-icon>
      </span>
    </button>
  </mat-nav-list>
</ng-container>

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  NgZone,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import type { EditorView } from '@codemirror/view';
import { CodeMirrorEditorService, CodeMirrorParams } from '../../services';

type CodeEditorOptions = Pick<CodeMirrorParams, 'mimeType'>;

@Component({
  selector: 'share-code-editor',
  templateUrl: './code-editor.component.html',
  styleUrls: ['./code-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CodeEditorComponent,
      multi: true
    },
    CodeMirrorEditorService
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CodeEditorComponent implements AfterViewInit, ControlValueAccessor {
  @Input()
  public options: CodeEditorOptions;

  @ViewChild('ref')
  public readonly editorRef: ElementRef<HTMLElement>;

  private value = '';

  private codeMirrorEditor: EditorView;
  private prevScrollPosition = 0;

  private onChange: (value: string) => void;
  private onTouched: () => void;

  constructor(
    private readonly ngZone: NgZone,
    private readonly codeMirrorEditorService: CodeMirrorEditorService
  ) {}

  public ngAfterViewInit() {
    void this.ngZone.runOutsideAngular(async () => {
      this.codeMirrorEditor = await this.codeMirrorEditorService.getCodeMirror({
        initialValue: this.value,
        parentElement: this.editorRef.nativeElement,
        onFocus: () => this.ngZone.run(() => this.onTouched()),
        onDocChange: (value: string) =>
          this.ngZone.run(() => {
            this.value = value;
            this.onChange(this.value);
          }),
        onScroll: (e: Event) => {
          // track only user's scroll events
          if (!e.isTrusted) {
            return;
          }

          const { x, y } = this.codeMirrorEditor.dom.getBoundingClientRect();
          this.prevScrollPosition = this.codeMirrorEditor.posAtCoords({ x, y });
        },
        ...this.options
      });
    });
  }

  public writeValue(value: string): void {
    if (value === null || value === undefined) {
      return;
    }

    if (!this.codeMirrorEditor) {
      this.value = value;
      return;
    }

    if (value !== this.value) {
      this.value = value;

      this.codeMirrorEditor.dispatch({
        changes: [
          // TODO: temp workaround - clear before inserting new one to avoid expensive recalculation (see NLJ-4884)
          { from: 0, to: this.codeMirrorEditor.state.doc.length, insert: '' },
          { from: 0, insert: this.value }
        ],
        selection: { anchor: this.prevScrollPosition },
        scrollIntoView: true
      });
    }
  }

  public registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(disabled: boolean): void {
    this.codeMirrorEditorService.setReadOnly(disabled);
  }

  public navigateByOffset(offset: number): void {
    this.codeMirrorEditorService.scrollTo(offset);
    this.codeMirrorEditor?.focus();
  }

  public restoreScrollPosition(): void {
    this.codeMirrorEditorService.scrollTo(this.prevScrollPosition);
    this.codeMirrorEditor?.focus();
  }
}

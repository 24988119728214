export enum PaletteColor {
  COLOR_50 = '50',
  COLOR_100 = '100',
  COLOR_200 = '200',
  COLOR_300 = '300',
  COLOR_400 = '400',
  COLOR_500 = '500',
  COLOR_600 = '600',
  COLOR_700 = '700',
  COLOR_800 = '800',
  COLOR_900 = '900',
  COLOR_A100 = 'A100',
  COLOR_A200 = 'A200',
  COLOR_A400 = 'A400',
  COLOR_A700 = 'A700'
}

export enum PaletteType {
  PRIMARY = 'primary',
  ACCENT = 'accent',
  WARN = 'warn'
}

export type Palette = {
  [key in PaletteColor]?: string;
};

export type PaletteWithContrast = Palette & { contrast: Palette };

export type Theme = {
  [key in PaletteType]: PaletteWithContrast;
};

export enum ThemeName {
  DEFAULT = 'default',
  DARK = 'dark'
}

type ThemeDefinition = { [key in PaletteType]: string };
type ThemesDefinitions = { [key in ThemeName]: ThemeDefinition };

export const themesDefinitions: ThemesDefinitions = {
  default: {
    [PaletteType.PRIMARY]: '#08838f',
    [PaletteType.ACCENT]: '#08838f',
    [PaletteType.WARN]: '#995e00'
  },
  dark: {
    [PaletteType.PRIMARY]: '#099fad',
    [PaletteType.ACCENT]: '#099fad',
    [PaletteType.WARN]: '#c97b00'
  }
};

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subject, first, takeUntil, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  MarketplaceCallbackStateStorageService,
  logout,
  selectAuthActiveStatus
} from '@neuralegion/auth-api';
import { BreadcrumbService } from '@neuralegion/core';

// eslint-disable-next-line @angular-eslint/use-component-selector
@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarketplaceCallbackComponent implements OnInit, OnDestroy {
  private readonly gc = new Subject<void>();

  constructor(
    private readonly store: Store,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly marketplaceCallbackStateStorageService: MarketplaceCallbackStateStorageService,

    breadcrumbService: BreadcrumbService
  ) {
    breadcrumbService.configure([]);
  }

  public ngOnInit(): void {
    this.handleStateSaving();
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }

  private handleStateSaving(): void {
    this.route.queryParams
      .pipe(first(), withLatestFrom(this.store.select(selectAuthActiveStatus)), takeUntil(this.gc))
      .subscribe(([params, authorized]: [Params, boolean]) => {
        if (authorized) {
          this.store.dispatch(logout({ skipRouting: true }));
        }

        this.marketplaceCallbackStateStorageService.set(params.marketplaceCallbackState);
        void this.router.navigateByUrl('/signup');
      });
  }
}

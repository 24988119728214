import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PipeTransform,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import {
  Observable,
  Subject,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
  takeUntil,
  withLatestFrom
} from 'rxjs';
import equal from 'fast-deep-equal/es6';
import { ID } from '@neuralegion/api';
import { trackByIdx } from '@neuralegion/core';
import { extractTouchedChanges } from '../../form';

interface Item {
  id: string;
  label: string;
  disabled: boolean;
}

export interface FilterableSelectOptions {
  readonly multiple: boolean;
  readonly required: boolean;
  readonly compact: boolean;
  readonly formatPipe: PipeTransform;
  readonly itemDisabledPipe: PipeTransform;
  readonly placeholder: string;
  readonly itemName: string;
  readonly tmplItem: TemplateRef<unknown>;
  readonly searchField: boolean;
  readonly noLabel: boolean;
  readonly containsHint: boolean;
  readonly hintPosition: 'default' | 'above';
  readonly inheritErrors: boolean;
  readonly showTooltip?: boolean;
}

@Component({
  selector: 'share-filterable-select',
  templateUrl: './filterable-select.component.html',
  styleUrls: ['./filterable-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: FilterableSelectComponent,
      multi: true
    }
  ]
})
export class FilterableSelectComponent<T extends ID & { label?: string }>
  implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy
{
  @Input()
  public data$: Observable<T[]>;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('options')
  public userOptions: Partial<FilterableSelectOptions>;

  @Output()
  public readonly openedChange = new EventEmitter<boolean>();

  @ViewChild('selectAllCheckbox')
  private readonly selectAllCheckbox: MatCheckbox;

  public readonly trackByIdx = trackByIdx;

  public readonly textFilter = new FormControl('');
  public readonly selectControl = new FormControl(null);

  public options: Partial<FilterableSelectOptions> = {
    multiple: true,
    required: false,
    searchField: true,
    itemName: 'item',
    showTooltip: false
  };

  public filteredData$: Observable<Item[]>;
  public empty$: Observable<boolean>;

  private readonly valueSubject = new Subject<string | string[]>();
  private readonly gc = new Subject<void>();

  private onChange: (ids: string[] | string) => void;
  private onTouched: () => void;

  private prevSelectedValues: T['id'][] = [];

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly injector: Injector
  ) {}

  public ngOnInit(): void {
    this.options = {
      ...this.options,
      noLabel: !!this.userOptions.placeholder,
      ...this.userOptions
    };

    this.filteredData$ = combineLatest([
      this.data$.pipe(
        map((items: T[]): Item[] =>
          items.map(
            (item: T): Item => ({
              id: item.id,
              label: this.options.formatPipe
                ? this.options.formatPipe.transform(item)
                : item.label || item.id,
              disabled: this.options.itemDisabledPipe
                ? this.options.itemDisabledPipe.transform(item)
                : false
            })
          )
        )
      ),
      this.textFilter.valueChanges.pipe(startWith(''))
    ]).pipe(
      map(([data, filterValue]: [Item[], string]) =>
        !filterValue
          ? data
          : data.filter((item: Item) =>
              item.label.toLowerCase().includes(filterValue.toLowerCase())
            )
      ),
      takeUntil(this.gc),
      shareReplay(1)
    );

    this.empty$ = this.filteredData$.pipe(map((data: Item[]) => data.length === 0));

    this.selectControl.valueChanges
      .pipe(withLatestFrom(this.filteredData$), takeUntil(this.gc))
      .subscribe(([value, filteredData]: [string[] | string, Item[]]) => {
        if (this.options.multiple) {
          this.updateSelectAllCheckboxState(filteredData, this.selectControl.value || []);
        }

        this.preserveSelectedValues(value, filteredData);
        this.onChange?.(this.options.multiple ? this.prevSelectedValues : value || null);
      });

    this.valueSubject
      .pipe(
        filter((value: string | string[]) => !equal(this.selectControl.value, value)),
        withLatestFrom(this.filteredData$),
        takeUntil(this.gc)
      )
      .subscribe(([value, filteredData]: [string | string[], Item[]]) => {
        this.selectControl.setValue(value, { emitEvent: false });

        if (this.options.multiple) {
          this.prevSelectedValues = Array.isArray(value) ? [...value] : [];
          this.updateSelectAllCheckboxState(filteredData, this.selectControl.value || []);
        }
      });

    extractTouchedChanges(this.selectControl)
      .pipe(
        filter((touched) => touched),
        takeUntil(this.gc)
      )
      .subscribe(() => this.onTouched());
  }

  public ngAfterViewInit(): void {
    this.initTouchedStateListeners();
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }

  public registerOnChange(onChange: (ids: string[] | string) => void): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  public setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.selectControl.disable();
    } else {
      this.selectControl.enable();
    }
  }

  public writeValue(value: string | string[]): void {
    this.valueSubject.next(value);
  }

  public onSelectAllChange(event: MatCheckboxChange, data: Item[]): void {
    if (event.checked) {
      this.selectControl.setValue(data.map((item: Item): string => item.id));
    } else {
      this.selectControl.setValue([]);
    }
  }

  public onOpenedChange(panelOpened: boolean): void {
    if (!panelOpened) {
      this.textFilter.setValue('');
      this.onTouched();
    }
    return this.openedChange.emit(panelOpened);
  }

  private updateSelectAllCheckboxState(allItems: Item[], selectedItems: Item[]): void {
    if (!this.selectAllCheckbox) {
      // hack to update selectAllCheckbox state on first parent control value init
      setTimeout(() => {
        this.updateSelectAllCheckboxState(allItems, selectedItems);
      });
      return;
    }

    if (allItems.length === selectedItems.length) {
      this.selectAllCheckbox.checked = true;
      this.selectAllCheckbox.indeterminate = false;
    } else if (selectedItems.length === 0) {
      this.selectAllCheckbox.checked = false;
      this.selectAllCheckbox.indeterminate = false;
    } else {
      this.selectAllCheckbox.indeterminate = true;
    }
  }

  private preserveSelectedValues(value: string[] | string, filteredData: Item[]): void {
    const multipleSelect = this.options.multiple && Array.isArray(value);
    if (!multipleSelect) {
      return;
    }

    const currentSelectedValue = Array.isArray(value) ? [...value] : [];

    if (this.textFilter.value?.length > 0) {
      /*
       * If a value that was selected before is deselected and not found in the options,
       * it was deselected due to the filtering, so we restore it
       */
      const isSelectedBefore = (previousValue: string): boolean =>
        !currentSelectedValue.includes(previousValue) &&
        !filteredData.some((v) => v.id === previousValue);

      this.prevSelectedValues
        .filter(isSelectedBefore)
        .forEach((previousValue: T['id']) => currentSelectedValue.push(previousValue));
    }

    this.prevSelectedValues = currentSelectedValue;
    this.selectControl.setValue(this.prevSelectedValues, { emitEvent: false });
  }

  private initTouchedStateListeners(): void {
    const parentControl = this.injector.get(NgControl).control;

    extractTouchedChanges(parentControl)
      .pipe(
        filter((touched) => touched),
        distinctUntilChanged(),
        takeUntil(this.gc)
      )
      .subscribe(() => {
        if (!this.selectControl.touched) {
          this.selectControl.markAsTouched();
          this.cdr.detectChanges();
        }
      });

    if (this.options.inheritErrors) {
      parentControl.statusChanges.pipe(takeUntil(this.gc)).subscribe(() => {
        this.selectControl.setErrors(parentControl.errors ? { ...parentControl.errors } : null);
      });
    }
  }
}

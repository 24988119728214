import { Pipe, PipeTransform } from '@angular/core';
import { JobStatus } from '@neuralegion/api';

@Pipe({
  name: 'jobStatusIcon',
  pure: true
})
export class JobStatusIconPipe implements PipeTransform {
  public transform(value: JobStatus): string {
    switch (value) {
      case JobStatus.STOPPED:
        return 'stop_circle';
      case JobStatus.SCHEDULED:
        return 'watch_later';
      case JobStatus.QUEUED:
        return 'playlist_add_circle';
      case JobStatus.PENDING:
      case JobStatus.RUNNING:
      case JobStatus.SEARCHING:
        return 'pending';
      case JobStatus.FAILED:
      case JobStatus.INCOMPLETE:
      case JobStatus.DISRUPTED:
        return 'cancel';
      case JobStatus.DONE:
      case JobStatus.COMPLETE:
        return 'check_circle';
      default:
        return '';
    }
  }
}

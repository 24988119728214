import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { Observable } from 'rxjs';

interface MatSpinnerButtonOptions {
  spinnerText?: string;
  buttonColor?: ThemePalette;
  spinnerColor?: ThemePalette;
  barColor?: ThemePalette;
  raised?: boolean;
  stroked?: boolean;
  flat?: boolean;
  fab?: boolean;
  spinnerSize?: number;
  mode?: ProgressSpinnerMode;
  value?: number;
  fullWidth?: boolean;
  disabled?: boolean;
  disabledTooltip?: string;
  icon?: MatProgressButtonIcon;
  type?: string;
  customClass?: string;
  buttonIcon?: MatProgressButtonIcon;
}

interface MatProgressButtonIcon {
  color?: ThemePalette;
  fontIcon?: string;
  fontSet?: string;
  inline?: boolean;
  svgIcon?: string;
  customClass?: string;
}

const defaultSpinnerButtonOptions: MatSpinnerButtonOptions = {
  spinnerSize: 19,
  raised: false,
  stroked: false,
  buttonColor: 'primary',
  spinnerColor: 'primary',
  fullWidth: false,
  disabled: false,
  disabledTooltip: null,
  mode: 'indeterminate'
};

@Component({
  selector: 'share-spinner-button',
  templateUrl: './spinner-button.component.html',
  styleUrls: ['./spinner-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinnerButtonComponent {
  @Input()
  set options(opts: Partial<MatSpinnerButtonOptions>) {
    this.auxOptions = { ...defaultSpinnerButtonOptions, ...opts };
  }

  get options(): MatSpinnerButtonOptions {
    return this.auxOptions;
  }

  @Input()
  public pending$: Observable<boolean>;

  @Output()
  public readonly btnClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  private auxOptions: MatSpinnerButtonOptions;

  public onClick(event: MouseEvent, pending: boolean): void {
    if (!this.options.disabled && !pending) {
      this.btnClick.emit(event);
    }
  }
}

import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ConsentConfirmationService, UserConsentsService } from '../services';
import { UserConsentsEffects } from '../store/user-consents.effects';
import { userConsentsReducer } from '../store/user-consents.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature('user-consents', userConsentsReducer),
    EffectsModule.forFeature([UserConsentsEffects])
  ],
  declarations: [],
  providers: [ConsentConfirmationService, UserConsentsService]
})
export class UserConsentsModule {}

import { Injectable, OnDestroy } from '@angular/core';
import { Subject, filter, fromEvent, takeUntil } from 'rxjs';
import type { Compartment as CompartmentType, Extension } from '@codemirror/state';
import type { EditorView, ViewUpdate } from '@codemirror/view';
import { CodeMirrorDependencies, CodeMirrorLoaderService } from './code-mirror-loader.service';

export interface CodeMirrorParams {
  initialValue: string;
  parentElement: HTMLElement;
  mimeType: 'application/javascript' | 'application/json' | 'text/yaml';
  onFocus: () => void;
  onDocChange: (value: string) => void;
  onScroll: (e: Event) => void;
}

@Injectable()
export class CodeMirrorEditorService implements OnDestroy {
  private codeMirror: EditorView;
  private codeMirrorDeps: CodeMirrorDependencies;

  private readonlyStateConf: CompartmentType;
  private readonly gc = new Subject<void>();

  constructor(private readonly codeMirrorLoaderService: CodeMirrorLoaderService) {}

  public ngOnDestroy(): void {
    this.codeMirror?.destroy();
    this.gc.next();
    this.gc.unsubscribe();
  }

  public async getCodeMirror(params: CodeMirrorParams): Promise<EditorView> {
    if (!this.codeMirror) {
      this.codeMirrorDeps = await this.codeMirrorLoaderService.importCodeMirrorDependencies();

      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { EditorState, EditorView, Compartment } = this.codeMirrorDeps;
      const { keymap, basicSetup, history, lineNumbers, historyKeymap } = this.codeMirrorDeps;

      this.readonlyStateConf = new Compartment();

      this.codeMirror = new EditorView({
        doc: params.initialValue,
        parent: params.parentElement,
        extensions: [
          basicSetup,
          this.getLanguage(params.mimeType),
          this.getHighlighting(),
          history(),
          EditorView.lineWrapping,
          lineNumbers(),
          keymap.of([...historyKeymap]),
          this.readonlyStateConf.of(EditorState.readOnly.of(false)),
          EditorView.domEventHandlers({
            focus: params.onFocus,
            scroll: params.onScroll
          }),
          EditorView.updateListener.of((update: ViewUpdate) => {
            if (update.docChanged) {
              const value = update.state.doc.toString();
              params.onDocChange(value);
            }
          })
        ]
      });

      this.searchBarCloseListener();
    }

    return this.codeMirror;
  }

  public setReadOnly(readonly: boolean): void {
    this.codeMirror?.dispatch({
      effects: this.readonlyStateConf.reconfigure(
        this.codeMirrorDeps.EditorState.readOnly.of(readonly)
      )
    });
  }

  public scrollTo(position: number): void {
    if (!this.codeMirror) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { EditorView } = this.codeMirrorDeps;
    this.codeMirror.dispatch({
      selection: { anchor: position },
      effects: EditorView.scrollIntoView(position, { y: 'start' })
    });
  }

  private getLanguage(mimeType: CodeMirrorParams['mimeType']): Extension {
    switch (mimeType) {
      case 'application/javascript':
        return this.codeMirrorDeps.languages.javascript();
      case 'application/json':
        return this.codeMirrorDeps.languages.json();
      case 'text/yaml':
        return this.codeMirrorDeps.languages.yaml();
    }
  }

  private getHighlighting(): Extension {
    const { syntaxHighlighting, tags: t } = this.codeMirrorDeps;
    const style = this.codeMirrorDeps.HighlightStyle.define([
      { tag: t.keyword, class: 'cm-keyword' },
      { tag: t.number, class: 'cm-number' },
      { tag: [t.name, t.deleted, t.character, t.propertyName, t.macroName], class: 'cm-property' },
      { tag: [t.definition(t.name), t.separator], class: 'cm-property' },
      { tag: [t.operator, t.operatorKeyword], class: 'cm-operator' },
      { tag: [t.typeName, t.className], class: 'cm-def' },
      { tag: t.self, class: 'cm-self' },
      {
        tag: [
          t.changed,
          t.annotation,
          t.modifier,
          t.namespace,
          t.url,
          t.escape,
          t.regexp,
          t.link,
          t.special(t.string),
          t.function(t.variableName),
          t.labelName
        ],
        class: 'cm-variable'
      },
      { tag: t.meta, class: 'cm-meta' },
      { tag: t.comment, class: 'cm-comment' },
      { tag: [t.atom, t.bool, t.special(t.variableName)], class: 'cm-atom' },
      { tag: [t.processingInstruction, t.string, t.inserted], class: 'cm-string' },
      { tag: t.invalid, class: 'cm-invalid' }
    ]);

    return syntaxHighlighting(style);
  }

  private searchBarCloseListener() {
    // don't propagate on `esc` if search bar is opened
    fromEvent<KeyboardEvent>(this.codeMirror.dom, 'keydown')
      .pipe(
        filter(({ code }) => code === 'Escape'),
        filter(({ defaultPrevented }) => defaultPrevented),
        takeUntil(this.gc)
      )
      .subscribe((event) => event.stopImmediatePropagation());
  }
}

import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PasswordGeneratorService {
  private readonly LOWERCASE_CHARSET = 'abcdefghijklmnopqrstuvwxyz';
  private readonly UPPERCASE_CHARSET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  private readonly NUMBER_CHARSET = '0123456789';
  private readonly SPECIAL_CHARSET = '!@#$%^&*()';

  public generate(length: number): string {
    // Generate at least one character from each charset
    let password = [
      this.LOWERCASE_CHARSET,
      this.UPPERCASE_CHARSET,
      this.NUMBER_CHARSET,
      this.SPECIAL_CHARSET
    ]
      .map((charset) => this.getRandomCharacter(charset))
      .join('');

    for (let i = 4; i < length; i++) {
      const character = this.getRandomCharacter(
        this.getRandomCharset(
          this.LOWERCASE_CHARSET,
          this.UPPERCASE_CHARSET,
          this.NUMBER_CHARSET,
          this.SPECIAL_CHARSET
        )
      );
      password = `${password}${character}`;
    }

    return this.shuffleString(password);
  }

  private getRandomCharacter(value: string) {
    const randomIndex = Math.floor(Math.random() * value.length);
    return value[randomIndex];
  }

  private getRandomCharset(...charsets: string[]) {
    const randomIndex = Math.floor(Math.random() * charsets.length);
    return charsets[randomIndex];
  }

  private shuffleString(value: string) {
    const characters = value.split('');
    for (let i = characters.length - 1; i > 0; i--) {
      const randomIndex = Math.floor(Math.random() * (i + 1));
      [characters[i], characters[randomIndex]] = [characters[randomIndex], characters[i]];
    }
    return characters.join('');
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { JobStatus } from '@neuralegion/api';

@Pipe({
  name: 'jobStatusMap',
  pure: true
})
export class JobStatusMapPipe implements PipeTransform {
  public transform(status: JobStatus): JobStatus {
    if (status === JobStatus.SEARCHING || status === JobStatus.PENDING) {
      return JobStatus.RUNNING;
    }

    if (status === JobStatus.INCOMPLETE || status === JobStatus.DISRUPTED) {
      return JobStatus.FAILED;
    }

    return status;
  }
}

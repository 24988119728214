import { Action, createFeatureSelector, createSelector } from '@ngrx/store';
import { FeedActivity, FeedSubscription } from '../models';
import { FeedState } from './feed.state';

// eslint-disable-next-line @typescript-eslint/ban-types
type State = object;
const selectFeedState = createFeatureSelector<FeedState>('feed');

export const selectFeedOpened = createSelector<State, [FeedState], boolean>(
  selectFeedState,
  (state: FeedState): boolean => state && state.opened
);

export const selectUnseenCounter = createSelector<State, [FeedState], number>(
  selectFeedState,
  (state: FeedState): number => state && state.unseen
);

export const selectFeedActivities = createSelector<State, [FeedState], FeedActivity[]>(
  selectFeedState,
  (state: FeedState): FeedActivity[] => state && state.activities
);

export const selectFeedOffset = createSelector<State, [FeedState], number>(
  selectFeedState,
  (state: FeedState): number => state && state.offset
);

export const selectEndReached = createSelector<State, [FeedState], boolean>(
  selectFeedState,
  (state: FeedState) => state && state.endReached
);

export const selectSubscriptions = createSelector<State, [FeedState], FeedSubscription[]>(
  selectFeedState,
  (state: FeedState) => state && state.subscriptions
);

export const selectPending = createSelector<State, [FeedState], boolean>(
  selectFeedState,
  (state: FeedState) => state && state.pending && state.pending.length > 0
);

export const selectLastSuccess = createSelector<State, [FeedState], Action>(
  selectFeedState,
  (state: FeedState) => state && state.lastSuccess
);

import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subject, debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs';
import { TextFilterComponent } from '../components';

@Directive({
  selector: '[shareFilterDataSource]'
})
export class FilterDataSourceDirective implements OnInit, OnDestroy {
  public static readonly DEBOUNCE_TIME = 200;

  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public dataSource: MatTableDataSource<any>;

  @Input('shareFilterDataSource')
  public filter: TextFilterComponent;

  private readonly gc = new Subject<void>();

  public ngOnInit(): void {
    this.filter.valueChanges
      .pipe(
        debounceTime(FilterDataSourceDirective.DEBOUNCE_TIME),
        map((value: string) => value && value.trim().toLowerCase()),
        distinctUntilChanged(),
        takeUntil(this.gc)
      )
      .subscribe((value: string) => {
        this.dataSource.filter = value;
      });
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }
}

import { ErrorHandler, Injectable } from '@angular/core';
import { ErrorType } from '../sentry/sentry-error-reporter.service';
import { ExternalErrorReporterService } from './external-error-reporter.service';

@Injectable()
export class ExternalErrorHandler implements ErrorHandler {
  constructor(private readonly externalErrorReporterService: ExternalErrorReporterService) {}

  public handleError(error: ErrorType): void {
    this.externalErrorReporterService.report(error);
  }
}

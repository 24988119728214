<ng-container [formGroup]="form">
  <share-secret-field
    maxlength="128"
    minlength="7"
    required
    [autocomplete]="'new-password'"
    [formControlName]="'password'"
    [label]="'Password'"></share-secret-field>

  <div
    *ngIf="{ errors: passwordErrors$ | async } as sync"
    class="password-checks-container"
    role="list"
    aria-label="Password requirements">
    <div
      *ngFor="let rule of strongRulesList; trackBy: trackByType"
      class="password-check"
      role="listitem"
      fxLayout="row"
      fxLayoutAlign="start center"
      [ngClass]="sync.errors[rule.type] ? 'password-check-fail' : 'password-check-success'">
      <mat-icon>{{ sync.errors[rule.type] ? 'stop_circle' : 'check' }}</mat-icon>
      <span class="password-check-text">{{ rule.text }}</span>
    </div>
  </div>

  <share-secret-field
    required
    [autocomplete]="'new-password'"
    [formControlName]="'repeatPassword'"
    [label]="'Repeat Password'"></share-secret-field>

  <div *ngIf="repeatPasswordError$ | async" class="password-checks-container" role="alert" aria-live="polite">
    <div class="password-check password-check-fail" fxLayout="row" fxLayoutAlign="start center">
      <mat-icon>clear</mat-icon>
      <span class="password-check-text">Passwords should match</span>
    </div>
  </div>
</ng-container>

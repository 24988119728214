import { Renderer2 } from '@angular/core';
import { MatFormField } from '@angular/material/form-field';
import { BaseElementIdStrategy } from './base-element-id-strategy';

export class MatFormFieldElementIdStrategy extends BaseElementIdStrategy {
  private readonly elementPrefix = ['form', 'field'].join(this.SEPARATOR_INTERNAL);

  constructor(
    renderer: Renderer2,
    private readonly formField: MatFormField
  ) {
    super(renderer);
  }

  public override applyId(element: HTMLElement, dataId: string): void {
    super.applyId(element, dataId);

    (element.querySelectorAll('input,textarea') || []).forEach((innerInput, idx, list) => {
      super.applyId(
        innerInput,
        [dataId, 'input', ...(list.length > 1 ? [idx] : [])].join(this.SEPARATOR_INTERNAL)
      );
    });

    const innerSelect: HTMLElement = element.querySelector('mat-select');
    if (innerSelect) {
      super.applyId(innerSelect, [dataId, 'dropdown'].join(this.SEPARATOR_INTERNAL));
    }
  }

  protected override getSubId(element: HTMLElement): string {
    // eslint-disable-next-line no-underscore-dangle
    const control = this.formField?._control;
    return [
      this.elementPrefix,
      this.generateIdByText(
        control?.ngControl?.path?.at(-1) ||
          this.getElementText(element.querySelector('mat-label')) ||
          // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/no-explicit-any
          ((control as any)?._elementRef.nativeElement as HTMLElement).getAttribute('aria-label') ||
          this.UNKNOWN_ID
      )
    ].join(this.SEPARATOR_INTERNAL);
  }
}

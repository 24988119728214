<div>
  <button
    *ngFor="let provider of IDENTITY_PROVIDERS; trackBy: trackByIdentity"
    type="button"
    mat-raised-button
    [disabled]="disabled"
    (click)="onProviderClick(provider)">
    <mat-icon
      [class.icon-google-colored]="provider === 'google'"
      [svgIcon]="provider === 'google' ? 'google_colored' : provider">
    </mat-icon>
    {{ btnPrefix }} {{ provider | providerType }}
  </button>
</div>

import { Renderer2 } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { BaseElementIdStrategy } from './base-element-id-strategy';
import { ButtonElementIdStrategy } from './button-element-id-strategy';

export class MatMenuElementIdStrategy extends BaseElementIdStrategy {
  private readonly elementPrefix = ['menu', 'panel'].join(this.SEPARATOR_INTERNAL);

  constructor(
    renderer: Renderer2,
    private readonly matMenuTrigger: MatMenuTrigger
  ) {
    super(renderer);
  }

  public override applyId(element: HTMLElement, dataId: string): void {
    const strategy = new ButtonElementIdStrategy(this.renderer);
    const buttonId = strategy.calculateId(element, dataId);
    super.applyId(element, buttonId);

    this.matMenuTrigger.menuOpened.subscribe(() => {
      const menuPanelEl = element.ownerDocument.getElementById(this.matMenuTrigger.menu.panelId);

      super.applyId(
        menuPanelEl,
        [...(this.matMenuTrigger.menu.parentMenu ? [buttonId] : []), this.elementPrefix].join(
          this.SEPARATOR_EXTERNAL
        )
      );
    });
  }

  protected override getSubId(): string {
    return '';
  }
}

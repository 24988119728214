import { Injectable } from '@angular/core';
import { Observable, filter, map, withLatestFrom } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import {
  resetUser,
  setUser,
  trackEvent,
  trackLogin,
  trackSignup,
  trackSignupSuccess
} from '@neuralegion/analytics-api';
import { InviteDetails, SignedUpUser } from '@neuralegion/api';
import { MarketplaceCallbackStateStorageService } from '../services/marketplace-callback-state-storage.service';
import { createRequiredOrg, loadUserInfoSuccess, login, logout } from './auth.actions';
import { signup, signupSuccess } from './signup.actions';
import { selectInviteDetails } from './signup.selectors';

@Injectable()
export class AuthAnalyticsEffects {
  public readonly trackSignup$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(signup),
      withLatestFrom(this.store.select(selectInviteDetails)),
      map(([action, inviteDetails]: [ReturnType<typeof signup>, InviteDetails | null]) =>
        trackSignup({
          user: {
            email: action.payload.email,
            name: action.payload.name
          },
          invited: !!inviteDetails,
          type: this.marketplaceCallbackStateStorageService.get() ? 'aws' : 'local'
        })
      )
    )
  );

  public readonly trackSignupSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(signupSuccess),
      map(({ payload: { user } }) => user),
      filter(AuthAnalyticsEffects.isSignedUpUser),
      map((user) => trackSignupSuccess({ user }))
    )
  );

  public readonly trackLoginSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserInfoSuccess),
      map((action: ReturnType<typeof loadUserInfoSuccess>) =>
        setUser({ user: action.payload.userInfo })
      )
    )
  );

  public readonly trackLogin$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(login),
      map((action: ReturnType<typeof login>) =>
        trackLogin({
          user: {
            email: action.payload.email
          },
          type: 'local'
        })
      )
    )
  );

  public readonly trackCreateRequiredOrg$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(createRequiredOrg),
      map((action: ReturnType<typeof createRequiredOrg>) =>
        trackEvent({
          name: 'Create Organization Form Submitted',
          properties: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            org_name: action.payload.name
          }
        })
      )
    )
  );

  public readonly trackLogout$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(logout),
      map(() => resetUser())
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly marketplaceCallbackStateStorageService: MarketplaceCallbackStateStorageService
  ) {}

  private static isSignedUpUser(user: SignedUpUser | null): user is SignedUpUser {
    return !!user;
  }
}

import { Inject, Injectable, Optional } from '@angular/core';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_TOKEN } from '@neuralegion/api';
import {
  LocalStorageService,
  SessionStorageService,
  StorageKey
} from '@neuralegion/browser-storage';
import { STORAGE_ID } from '@neuralegion/core';
import { PaginationState } from '../../models';
import { PaginationStateSerializationService } from './pagination-state-serialization.service';

type StoredPaginationState = Omit<PaginationState, 'storageId' | 'activeAction'>;

@Injectable()
export class OffsetPaginationStateStorageSerializationService extends PaginationStateSerializationService<PaginationState> {
  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly sessionStorageService: SessionStorageService,
    @Inject(STORAGE_ID) private readonly storageId: string,
    @Inject(DEFAULT_PAGE_SIZE_TOKEN) @Optional() private readonly defaultPageSize: number
  ) {
    super();
  }

  public serializeState(state: PaginationState): void {
    this.localStorageService.set(
      this.generateId(StorageKey.PAGE_SIZE, this.storageId),
      state.pagination.pageSize
    );
    this.sessionStorageService.set(this.generateId(StorageKey.PAGINATION_STATE, this.storageId), {
      filters: state.filters,
      sort: state.sort,
      pagination: state.pagination,
      refreshParams: state.refreshParams
    } as StoredPaginationState);
  }

  public deserializeState(): PaginationState {
    const pageSize =
      this.localStorageService.get(this.generateId(StorageKey.PAGE_SIZE, this.storageId)) ??
      this.defaultPageSize ??
      DEFAULT_PAGE_SIZE;
    const storedState: StoredPaginationState = this.sessionStorageService.get(
      this.generateId(StorageKey.PAGINATION_STATE, this.storageId)
    ) ?? {
      pagination: {
        pageSize: this.defaultPageSize ?? DEFAULT_PAGE_SIZE,
        length: 0,
        pageIndex: 0
      },
      filters: null,
      sort: null,
      prevNextProps: null,
      refreshParams: null
    };

    return {
      ...storedState,
      pagination: {
        ...storedState.pagination,
        pageSize
      },
      activeAction: null
    };
  }

  private generateId(key: string, identifier: string): string {
    return `${identifier}_${key}`;
  }
}

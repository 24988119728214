import { Action, createFeatureSelector, createSelector } from '@ngrx/store';
import { Board, ServiceName } from '@neuralegion/api';
import { AzureOrg, Integration, SnykOrg } from '../models';
import { loadAzureOrgs, loadRepositories, loadSnykOrgs } from './integrations.actions';
import { IntegrationsState, integrationsEntityAdapter } from './integrations.state';
import { State } from './state';

export const selectIntegrationsState = createFeatureSelector<IntegrationsState>('integrations');

export const selectIntegrations = createSelector<State, [IntegrationsState], Integration[]>(
  selectIntegrationsState,
  integrationsEntityAdapter.getSelectors().selectAll
);

export const selectAzureOrgs = createSelector<State, [IntegrationsState], AzureOrg[]>(
  selectIntegrationsState,
  (state: IntegrationsState): AzureOrg[] => state?.azureOrgs ?? []
);

export const selectSnykOrgs = createSelector<State, [IntegrationsState], SnykOrg[]>(
  selectIntegrationsState,
  (state: IntegrationsState): SnykOrg[] => state?.snykOrgs ?? []
);

export const selectAllRepositories = (serviceName: ServiceName, integrationId: string) =>
  createSelector<State, [IntegrationsState], Board[]>(
    selectIntegrationsState,
    (state: IntegrationsState): Board[] =>
      state?.repositories.get(serviceName)?.[integrationId] ?? []
  );

const selectPendingActionTypes = createSelector<State, [IntegrationsState], string[]>(
  selectIntegrationsState,
  (state: IntegrationsState) =>
    !state || !state.pending || state.pending.length === 0
      ? []
      : [...new Set<string>(state.pending.map((pendedAction: Action) => pendedAction.type))]
);

const INNER_TYPES: string[] = [loadAzureOrgs.type, loadSnykOrgs.type, loadRepositories.type];

export const selectInnerPendingStatus = createSelector<State, [string[]], boolean>(
  selectPendingActionTypes,
  (pendedActionTypes: string[]) =>
    !!pendedActionTypes.find((pendedActionType: string) => INNER_TYPES.includes(pendedActionType))
);

export const selectPendingStatus = createSelector<State, [IntegrationsState], boolean>(
  selectIntegrationsState,
  (state: IntegrationsState) => state?.pending?.length > 0
);

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';

export interface PageRefreshOptions {
  pending$: Observable<boolean>;
}

@Injectable({
  providedIn: 'root'
})
export class PageRefreshService {
  private readonly attachedSubject = new BehaviorSubject<PageRefreshOptions | null>(null);
  public readonly attached$ = this.attachedSubject.asObservable();

  private readonly refreshSubject = new Subject<void>();
  public readonly refresh$ = this.refreshSubject.asObservable();

  public attach(options: PageRefreshOptions = { pending$: of(false) }): void {
    this.attachedSubject.next(options);
  }

  public detach(): void {
    this.attachedSubject.next(null);
  }

  public refresh(): void {
    this.refreshSubject.next();
  }
}

import { Action, createReducer, on } from '@ngrx/store';
import {
  disableMfa,
  disableMfaFail,
  disableMfaSuccess,
  enableMfa,
  enableMfaFail,
  enableMfaSuccess,
  loadMfaQrCode,
  loadMfaQrCodeFail,
  loadMfaQrCodeSuccess
} from './multi-factor.actions';
import { MultiFactorState, state as initialState } from './multi-factor.state';

export const multiFactorReducer = createReducer<MultiFactorState, Action>(
  initialState,
  on(
    disableMfa,
    enableMfa,
    (state: MultiFactorState): MultiFactorState => ({
      ...state,
      pending: true,
      error: null
    })
  ),
  on(
    loadMfaQrCode,
    (state: MultiFactorState): MultiFactorState => ({
      ...state,
      qrCode: null,
      pending: true,
      error: null
    })
  ),
  on(
    disableMfaFail,
    enableMfaFail,
    loadMfaQrCodeFail,
    (state: MultiFactorState, action): MultiFactorState => ({
      ...state,
      pending: false,
      error: action.payload
    })
  ),
  on(
    disableMfaSuccess,
    enableMfaSuccess,
    (state: MultiFactorState): MultiFactorState => ({
      ...state,
      pending: false,
      error: null
    })
  ),
  on(
    loadMfaQrCodeSuccess,
    (state: MultiFactorState, action): MultiFactorState => ({
      ...state,
      qrCode: action.payload,
      pending: false,
      error: null
    })
  )
);

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unique',
  pure: true
})
export class UniquePipe<T> implements PipeTransform {
  public transform(input: T[], key: keyof T): T[] {
    if (!input) {
      return [];
    }

    return [...new Map(input?.map((item: T) => [item[key], item])).values()];
  }
}

<ng-container *ngIf="data$ | async as data">
  <div *ngIf="data.title" role="heading" aria-level="1" mat-dialog-title>{{ data.title }}</div>

  <mat-dialog-content [element-data-id]="data.dataId">
    <ng-container *ngIf="data.contentTemplateRef; else tmplMessage">
      <ng-container
        *ngTemplateOutlet="data.contentTemplateRef; context: { $implicit: data.contentTemplateContext }"></ng-container>
    </ng-container>

    <ng-template #tmplMessage>
      <p *ngIf="data.message" class="message">
        {{ data.message }}
      </p>
    </ng-template>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button *ngIf="!data?.prohibitCancel" mat-stroked-button [mat-dialog-close]="false">
      {{ data.buttonCancel || 'Cancel' }}
    </button>
    <button class="btn-urgent" mat-raised-button [mat-dialog-close]="true">
      {{ data.buttonOk || 'YES' }}
    </button>
  </mat-dialog-actions>
</ng-container>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RepeaterNetworkPayload, RepeaterNetworkResult } from '../models';

@Injectable()
export class RepeaterNetworkService {
  constructor(private readonly http: HttpClient) {}

  public loadTest(repeaterId: string): Observable<RepeaterNetworkResult> {
    return this.http.get<RepeaterNetworkResult>(`/api/v1/repeaters/${repeaterId}/network`);
  }

  public updateTest(
    repeaterId: string,
    body: RepeaterNetworkPayload
  ): Observable<RepeaterNetworkResult> {
    return this.http.post<RepeaterNetworkResult>(`/api/v1/repeaters/${repeaterId}/network`, body);
  }

  public runTest(
    repeaterId: string,
    body: RepeaterNetworkPayload
  ): Observable<RepeaterNetworkResult> {
    return this.http.post<RepeaterNetworkResult>(
      `/api/v1/repeaters/${repeaterId}/network/test`,
      body
    );
  }
}

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, Subject, map, take, takeUntil } from 'rxjs';
import { Email, Name } from '@neuralegion/api';

type ConfirmSocialQueryParams = Partial<Email & Name> & { type: string; state: string };

// eslint-disable-next-line @angular-eslint/use-component-selector
@Component({
  templateUrl: './confirm-create-user.component.html',
  styleUrls: ['./confirm-create-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmCreateUserComponent implements OnInit, OnDestroy {
  public details$: Observable<ConfirmSocialQueryParams>;

  private readonly gc = new Subject<void>();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) {}

  public ngOnInit(): void {
    this.details$ = this.route.queryParams.pipe(
      map((query: Params) => ({ ...query }) as ConfirmSocialQueryParams)
    );
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }

  public confirm(): void {
    this.details$
      .pipe(take(1), takeUntil(this.gc))
      .subscribe(({ type, state }: ConfirmSocialQueryParams) =>
        this.router.navigate([`./${type}/callback`], {
          queryParams: {
            state
          }
        })
      );
  }
}

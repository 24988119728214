export class ValuesStack<T> {
  private readonly stack: T[] = [];

  constructor(
    initialValue?: T,
    private readonly size = 2
  ) {
    if (typeof initialValue !== 'undefined') {
      this.stack.push(initialValue);
    }
  }

  public push(value: T): void {
    this.stack.splice(0, this.stack.length, ...[...this.stack.splice(-(this.size - 1)), value]);
  }

  public pop(): T | null {
    if (this.stack.length < 2) {
      this.stack.splice(0, this.stack.length);
      return null;
    }

    this.stack.splice(-1);
    return this.stack[this.stack.length - 1];
  }

  public getPrev(): T {
    return this.stack[this.stack.length - 2];
  }
}

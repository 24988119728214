import { BaseElementIdStrategy } from './base-element-id-strategy';
import { ButtonElementIdStrategy } from './button-element-id-strategy';

export class MatChipElementIdStrategy extends BaseElementIdStrategy {
  private readonly elementPrefix = 'chip';

  protected override getSubId(element: HTMLElement): string {
    return [this.elementPrefix, this.getOptionIndex(element)].join(this.SEPARATOR_INTERNAL);
  }

  public override applyId(element: HTMLElement, dataId: string): void {
    super.applyId(element, dataId);

    // action button inside `mat-chip-option`; `remove` button is expected to have `element-data-id`
    const actionButton = element.querySelector<HTMLButtonElement>('button:not([element-data-id])');
    if (actionButton) {
      const strategy = new ButtonElementIdStrategy(this.renderer);
      const buttonId = strategy.calculateId(actionButton, dataId);
      super.applyId(actionButton, buttonId);
    }
  }

  private getOptionIndex(element: HTMLElement): string {
    return `${[...element.closest('.mat-mdc-chip-set').querySelectorAll('.mat-mdc-chip')].indexOf(
      element
    )}`;
  }
}

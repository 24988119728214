import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Host,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  SkipSelf
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { Subject, distinctUntilChanged, startWith, takeUntil } from 'rxjs';
import { extractTouchedChanges, updateNestedTreeTouchedState } from '../../form';

type Type = 'text' | 'password';

type Autocomplete = 'off' | 'on' | 'current-password' | 'new-password';

@Component({
  selector: 'share-secret-field',
  templateUrl: './secret-field.component.html',
  styleUrls: ['./secret-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: SecretFieldComponent,
      multi: true
    }
  ]
})
export class SecretFieldComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input()
  public formControlName?: string;

  @Input()
  public label: string;

  @Input()
  public labelTooltip?: string;

  @Input()
  public autocomplete: Autocomplete = 'off';

  public hidden = true;
  public onChange: (value: string) => void;
  public onTouched: () => void;
  public focused = false;
  public readonly formControl = new FormControl('');
  private readonly gc = new Subject<void>();

  get value(): string {
    return this.formControl.value;
  }

  set value(value: string | null) {
    this.formControl.patchValue(value ?? '');
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _required = false;

  @Input()
  get required(): boolean {
    // eslint-disable-next-line no-underscore-dangle
    return this._required;
  }

  set required(req: boolean | string) {
    // eslint-disable-next-line no-underscore-dangle
    this._required = coerceBooleanProperty(req);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _disabled = false;

  @Input()
  get disabled(): boolean {
    // eslint-disable-next-line no-underscore-dangle
    return this._disabled;
  }

  set disabled(value: boolean) {
    // eslint-disable-next-line no-underscore-dangle
    this._disabled = coerceBooleanProperty(value);
    if (value) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  get empty(): boolean {
    return !this.formControl.value;
  }

  get type(): Type {
    return this.hidden ? 'password' : 'text';
  }

  constructor(
    private readonly fm: FocusMonitor,
    private readonly elRef: ElementRef<HTMLElement>,
    private readonly cdr: ChangeDetectorRef,
    @Optional() @Host() @SkipSelf() private readonly controlContainer: ControlContainer
  ) {}

  public ngOnInit(): void {
    if (this.formControlName && this.controlContainer) {
      const parentControl = this.controlContainer.control.get(this.formControlName);
      const externalValidator = parentControl.validator;
      if (externalValidator) {
        this.formControl.setValidators([externalValidator]);
      }
      this.formControl.updateValueAndValidity();

      this.initTouchedStateListeners(parentControl);
    }

    this.fm
      .monitor(this.elRef.nativeElement, true)
      .pipe(takeUntil(this.gc))
      .subscribe((origin) => {
        if (this.focused && !origin) {
          this.onTouched();
        }
        this.focused = !!origin;
      });
  }

  public handleInput(): void {
    this.onChange(this.value);
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }

  public setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  public registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public writeValue(value: string): void {
    this.value = value;
  }

  public toggle(event: MouseEvent): void {
    event.stopPropagation();
    this.hidden = !this.hidden;
  }

  private initTouchedStateListeners(parentControl: AbstractControl): void {
    extractTouchedChanges(parentControl)
      .pipe(startWith(parentControl.touched), distinctUntilChanged(), takeUntil(this.gc))
      .subscribe((touched: boolean) => {
        updateNestedTreeTouchedState(this.formControl, touched);
        this.cdr.markForCheck();
      });
  }
}

import { SelectionModel } from '@angular/cdk/collections';
import { Directive, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, distinctUntilChanged, takeUntil } from 'rxjs';
import equal from 'fast-deep-equal/es6';

@Directive({
  selector: '[shareTableRowSelected]'
})
export class TableRowSelectedDirective implements OnInit, OnDestroy {
  @Input('shareTableRowSelected')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public selection: SelectionModel<any>;

  @Input()
  public row: unknown;

  @HostBinding('class.selected')
  get selectedRow(): boolean {
    return this.selected;
  }

  private selected: boolean;

  private readonly gc = new Subject<void>();

  public ngOnInit(): void {
    this.selected = this.selection.isSelected(this.row);
    this.selection.changed.pipe(distinctUntilChanged(equal), takeUntil(this.gc)).subscribe(() => {
      this.selected = this.selection.isSelected(this.row);
    });
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }
}

import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { Subject, distinctUntilChanged, filter, takeUntil } from 'rxjs';
import equal from 'fast-deep-equal/es6';
import { ComparisonOperator } from '@neuralegion/api';
import { trackByIdentity } from '@neuralegion/core';
import { extractTouchedChanges } from '../../form';

interface FormValue {
  operator: ComparisonOperator;
  value: number;
}

@Component({
  selector: 'share-number-comparator-control',
  templateUrl: './number-comparator-control.component.html',
  styleUrls: ['./number-comparator-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: NumberComparatorControlComponent, multi: true },
    { provide: NG_VALIDATORS, useExisting: NumberComparatorControlComponent, multi: true }
  ]
})
export class NumberComparatorControlComponent
  implements OnInit, OnDestroy, ControlValueAccessor, Validator
{
  @Input()
  public operators: ComparisonOperator[] = [
    ComparisonOperator.GT,
    ComparisonOperator.GTE,
    ComparisonOperator.LT,
    ComparisonOperator.LTE,
    ComparisonOperator.EQ
  ];

  @Input()
  set numberValidators(numberValidators: ValidatorFn[]) {
    this.form.controls.value.setValidators([Validators.required, ...numberValidators]);
  }

  public readonly form: FormGroup = new FormGroup(
    {
      operator: new FormControl(null, [Validators.required]),
      value: new FormControl(null, [Validators.required])
    } as Record<keyof FormValue, FormControl>,
    { updateOn: 'blur' }
  );

  public readonly trackByIdentity = trackByIdentity;

  private onChange: (value: FormValue) => void;
  private onTouched: () => void;

  private readonly valueSubject = new Subject<FormValue>();
  private readonly gc = new Subject<void>();

  constructor() {}

  public validate(_control: AbstractControl): ValidationErrors {
    return this.form.valid ? null : { compareToNumber: true };
  }

  public ngOnInit() {
    this.initValueSubjectListener();
    this.initFormListener();
    this.initTouchedListener();
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }

  public writeValue(value: FormValue): void {
    this.valueSubject.next(value);
  }

  public registerOnChange(fn: (value: FormValue) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  private initTouchedListener(): void {
    extractTouchedChanges(this.form)
      .pipe(
        filter((touched: boolean) => touched),
        takeUntil(this.gc)
      )
      .subscribe(() => {
        this.onTouched?.();
      });
  }

  private initValueSubjectListener(): void {
    this.valueSubject
      .pipe(
        filter((value: FormValue) => !equal(this.form.value, value)),
        takeUntil(this.gc)
      )
      .subscribe((value: FormValue) => this.form.patchValue(value ?? {}));
  }

  private initFormListener(): void {
    this.form.valueChanges
      .pipe(distinctUntilChanged(equal), takeUntil(this.gc))
      .subscribe((value: FormValue) => this.onChange?.(value));
  }
}

<ng-container
  *ngIf="{
    pending: pending$ | async,
    labels: labels$ | async,
    showCreateNewButton: showFooter$ | async
  } as sync">
  <share-server-filterable-select
    class="custom-hint"
    #labelsSelect
    [formControl]="formControl"
    [items$]="labelsItems$"
    [options]="{
      isOptionDisabled,
      itemsSortComparator,
      placeholder: 'Add labels',
      itemName: 'label',
      tmplLabel: options.label ? tmplLabel : null,
      compact: options.compact,
      filterFieldValidators: filterFieldValidators,
      tmplFooter: sync.showCreateNewButton && tmplFooter,
      tmplOptionsHint,
      tmplItemsFooter
    }"
    [pending]="sync.pending"
    (opened)="onDropdownToggle($event)"
    (queryChanged)="onQueryChange($event)">
    <ng-container field-hint>
      <ng-content select="[field-hint]"></ng-content>
    </ng-container>
    <ng-template #tmplLabel>{{ options.label }}</ng-template>
    <ng-template #tmplOptionsHint>
      {{ formControl?.value?.length || 0 }} / {{ options.maxLabelsListLength }} labels selected
    </ng-template>
    <ng-template #tmplFooter>
      <button mat-button (click)="createLabel(labelsSelect.textFilter.value)">
        “<span class="label-text">{{ labelsSelect.textFilter.value }}</span
        >” (Create new)
      </button>
    </ng-template>
    <ng-template #tmplItemsFooter>
      <ng-container *ngIf="sync.labels?.total > sync.labels?.items.length">
        Refine your request to see more results
      </ng-container>
    </ng-template>
  </share-server-filterable-select>
</ng-container>

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, exhaustMap, map, of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { UserConsentsService } from '../services';
import {
  updateConsent,
  updateConsents,
  updateConsentsFail,
  updateConsentsSuccess
} from './user-consents.actions';

@Injectable()
export class UserConsentsEffects {
  public readonly updateConsent$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(updateConsent),
      map((action: ReturnType<typeof updateConsent>) =>
        updateConsents({
          consents: {
            [action.payload.consent]: action.payload.value
          }
        })
      )
    )
  );

  public readonly updateConsents$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(updateConsents),
      exhaustMap((action: ReturnType<typeof updateConsents>): Observable<Action> => {
        return this.userConsentsService.updateConsents(action.payload.consents).pipe(
          map(() => updateConsentsSuccess(action.payload)),
          catchError((err: HttpErrorResponse) => of(updateConsentsFail(err.error)))
        );
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly userConsentsService: UserConsentsService
  ) {}
}

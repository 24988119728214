import {
  add as dateFnsAdd,
  addDays as dateFnsAddDays,
  differenceInCalendarDays as dateFnsDifferenceInCalendarDays,
  endOfWeek as dateFnsEndOfWeek,
  formatDistance as dateFnsFormatDistance,
  isWithinInterval as dateFnsIsWithinInterval,
  parse as dateFnsParse,
  startOfDay as dateFnsStartOfDay,
  startOfWeek as dateFnsStartOfWeek,
  subDays as dateFnsSubDays,
  subMonths as dateFnsSubMonths,
  subWeeks as dateFnsSubWeeks,
  subYears as dateFnsSubYears,
  isValid as datsFnsIsValid,
  parseISO
} from 'date-fns';
import { format as dateFnsFormat, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { TimeZone, Weekday } from '@neuralegion/api';

export const isValidDate = (date: Date): boolean => datsFnsIsValid(date);

export const subDays = (date: Date, sub: number, exclusive = false): Date => {
  const d = dateFnsSubDays(date, sub);
  return exclusive ? dateFnsAddDays(d, 1) : d;
};

export const subWeeks = (date: Date, sub: number, exclusive = false): Date => {
  const d = dateFnsSubWeeks(date, sub);
  return exclusive ? dateFnsAddDays(d, 1) : d;
};

export const subMonths = (date: Date, sub: number, exclusive = false): Date => {
  const d = dateFnsSubMonths(date, sub);
  return exclusive ? dateFnsAddDays(d, 1) : d;
};

export const subYears = (date: Date, sub: number, exclusive = false): Date => {
  const d = dateFnsSubYears(date, sub);
  return exclusive ? dateFnsAddDays(d, 1) : d;
};

export const addDuration = (date: Date, duration: Duration): Date => dateFnsAdd(date, duration);

export const differenceInCalendarDays = (startDate: Date, endDate: Date): number =>
  dateFnsDifferenceInCalendarDays(startDate, endDate);

export const formatDistance = (
  date: Date | number,
  baseDate: Date | number,
  options?: {
    includeSeconds?: boolean;
    addSuffix?: boolean;
  }
): string => dateFnsFormatDistance(date, baseDate, options);

export const isWithinInterval = (date: Date, interval: { start: Date; end: Date }): boolean =>
  dateFnsIsWithinInterval(date, interval);

export const startOfWeek = (date: Date, options?: { weekStartsOn?: Weekday }): Date =>
  dateFnsStartOfWeek(date, options);

export const endOfWeek = (date: Date, options?: { weekStartsOn?: Weekday }): Date =>
  dateFnsEndOfWeek(date, options);

export const startOfDay = (date: Date): Date => dateFnsStartOfDay(date);

export const formatTzDate = (
  _date: Date | number | string,
  format: string,
  tz: TimeZone
): string => {
  const date = typeof _date === 'string' ? parseISO(_date) : _date;
  const zonedDate = utcToZonedTime(date, tz);
  return dateFnsFormat(zonedDate, format, { timeZone: tz });
};

export const applyTz = (d: Date, tz: TimeZone): Date => utcToZonedTime(d, tz);

export const revertTz = (d: Date, tz: TimeZone): Date => zonedTimeToUtc(d, tz);

export const parseDate = (dateStr: string, format: string): Date | null => {
  const result = dateFnsParse(dateStr, format, new Date());
  return result instanceof Date ? result : null;
};

export const getZonedDatePartFromUTCDate = (utcDate: Date, timeZone: TimeZone): Date => {
  const zonedEndAtDate = applyTz(utcDate, timeZone);

  return revertTz(startOfDay(zonedEndAtDate), timeZone);
};

export const getZonedTimePartFromUTCDate = (utcDate: Date, timeZone: TimeZone): string => {
  const zonedDate = applyTz(utcDate, timeZone);

  return `${zonedDate.getHours().toString(10).padStart(2, '0')}:${zonedDate
    .getMinutes()
    .toString(10)
    .padStart(2, '0')}`;
};

export const buildUTCDateFromZonedDateTime = (
  date: Date,
  time: string,
  timeZone: TimeZone
): Date => {
  const zonedDate = applyTz(date, timeZone);

  const [hours, minutes] = time ? time.split(':').map((p: string): number => +p) : [0, 0];
  zonedDate.setHours(hours);
  zonedDate.setMinutes(minutes);

  return revertTz(zonedDate, timeZone);
};

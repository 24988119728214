import { Pipe, PipeTransform } from '@angular/core';
import { QuotaType } from '@neuralegion/api';

@Pipe({
  name: 'quotaTypeIcon',
  pure: true
})
export class QuotaTypeIconPipe implements PipeTransform {
  public transform(quotaType: QuotaType): string {
    switch (quotaType) {
      case QuotaType.USERS:
        return 'person';
      case QuotaType.SCAN_HOURS:
        return 'schedule';
      case QuotaType.FILE_STORAGE:
        return 'cloud_upload';
      case QuotaType.TICKETING_INTEGRATIONS:
        return 'integration_instructions';
      case QuotaType.ENGINES:
        return 'settings_applications';
      case QuotaType.PROJECTS:
        return 'folder';
      default:
        return 'help_outline';
    }
  }
}

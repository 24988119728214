import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'simplePlural',
  pure: true
})
export class SimplePluralPipe implements PipeTransform {
  private readonly VOWELS: ReadonlySet<string> = new Set<string>(['a', 'e', 'i', 'o', 'u']);

  public transform(quantity: number | '', word: string): string {
    if (quantity === 1) {
      return word;
    }

    let pluralWord;
    if (word.endsWith('y')) {
      pluralWord = this.VOWELS.has(word[word.length - 2])
        ? word + 's'
        : word.replace(word, word.slice(0, -1) + 'ies');
    } else if (word.endsWith('s')) {
      pluralWord = word + 'es';
    } else {
      pluralWord = word + 's';
    }

    return `${quantity} ${pluralWord}`;
  }
}

import { Injectable } from '@angular/core';
import { HexFormatPart, HexFormatResult } from './code-hex-lines.service';

@Injectable({
  providedIn: 'root'
})
export class CodeHexHtmlService {
  private formatBlock(
    lines: HexFormatResult[],
    blockType: HexFormatPart,
    separator: string = '\n'
  ): string {
    return lines.map((line: HexFormatResult) => line[blockType]).join(separator);
  }

  private joinLines(lines: HexFormatResult[]): HexFormatResult {
    return {
      [HexFormatPart.LABEL]: this.formatBlock(lines, HexFormatPart.LABEL),
      [HexFormatPart.CODES]: this.formatBlock(lines, HexFormatPart.CODES),
      [HexFormatPart.CONTENT]: this.formatBlock(lines, HexFormatPart.CONTENT)
    };
  }

  public toHexHtml(lines: HexFormatResult[]): string {
    const result = this.joinLines(lines);
    return `
      <pre class="hex-labels">${result[HexFormatPart.LABEL]}</pre>
      <pre class="hex-codes">${result[HexFormatPart.CODES]}</pre>
      <pre class="hex-content">${result[HexFormatPart.CONTENT]}</pre>
    `;
  }
}

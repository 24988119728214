<ng-container [ngSwitch]="type">
  <button
    *ngSwitchCase="'icon'"
    type="button"
    attr.aria-label="{{ titleLabel }}"
    mat-icon-button
    [color]="color"
    [matTooltip]="titleLabel"
    (click)="doCopy($event)">
    <mat-icon>content_copy</mat-icon>
  </button>
  <button
    *ngSwitchCase="'text'"
    type="button"
    class="text-button ghost-blur"
    mat-button
    [color]="color"
    (click)="doCopy($event)">
    <mat-icon>content_copy</mat-icon>
    <span>{{ titleLabel }}</span>
  </button>
</ng-container>

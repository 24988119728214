import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IndividualConfig } from 'ngx-toastr';
import {
  hideSnackbarByMessage,
  showSnackbar,
  showSnackbarWithNavigation,
  showSnackbarWithReload
} from '../store';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  constructor(private readonly store: Store) {}

  public open(
    message: string,
    error: boolean = false,
    config: Partial<IndividualConfig> = {},
    title: string = ''
  ): void {
    this.store.dispatch(showSnackbar({ message, error, title: error ? 'Close' : title, config }));
  }

  public openWithReloadAction(message: string): void {
    this.store.dispatch(showSnackbarWithReload({ message }));
  }

  public openWithNavigation(
    message: string,
    url: string,
    config: Partial<IndividualConfig> = {},
    title: string = 'Navigate'
  ): void {
    this.store.dispatch(showSnackbarWithNavigation({ message, url, config, title }));
  }

  public close(message: string): void {
    this.store.dispatch(hideSnackbarByMessage({ message }));
  }
}

import { OperatorFunction, filter, map, pipe } from 'rxjs';
import { ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED, RouterNavigationAction } from '@ngrx/router-store';
import { Action } from '@ngrx/store';
import { AppRouterState } from '../models';

/**
 * Intercepts all ngrx ROUTER_NAVIGATED events and filters only primary router events
 * (excluding navigating to auxiliary routes and returning from auxiliary routes to primary,
 * except initial start with auxiliary route)
 *
 * @param routeMatcher - AppRouterState filter callback fn. Default: () => true
 */
export function ofPrimaryRouterNavigated(
  routeMatcher: (state: AppRouterState) => boolean = () => true
): OperatorFunction<Action, AppRouterState> {
  let previousState: AppRouterState | undefined;

  return pipe(
    ofType(ROUTER_NAVIGATED),
    map((r: RouterNavigationAction<AppRouterState>) => r.payload.routerState),
    filter((state) => {
      const res =
        (!previousState || (state.primary && previousState.primary)) && routeMatcher(state);
      previousState = state;
      return res;
    })
  );
}

import { Pipe, PipeTransform } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { Filter, SelectItem } from '@neuralegion/api';

@Pipe({
  name: 'asyncSelectFilterItems',
  // eslint-disable-next-line @angular-eslint/no-pipe-impure
  pure: false
})
export class AsyncSelectFilterItemsPipe implements PipeTransform {
  public transform(
    value$: Observable<SelectItem[]>,
    filterValue: Filter[],
    itemsFilterFn: (filterValue: Filter[], items: SelectItem[]) => Observable<SelectItem[]>
  ): Observable<SelectItem[]> {
    if (!filterValue || !itemsFilterFn) {
      return value$;
    }

    return value$.pipe(switchMap((items: SelectItem[]) => itemsFilterFn(filterValue, items)));
  }
}

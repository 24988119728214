import { BaseElementIdStrategy } from './base-element-id-strategy';

export class PageHeaderElementIdStrategy extends BaseElementIdStrategy {
  private readonly elementPrefix = ['page', 'header'].join(this.SEPARATOR_INTERNAL);

  public override applyId(element: HTMLElement, dataId: string): void {
    if (element.nextElementSibling) {
      super.applyId(element.nextElementSibling as HTMLElement, dataId);
    }
  }

  protected override getSubId(element: HTMLElement): string {
    const id = this.generateIdByText(element.textContent);

    return id ? [this.elementPrefix, id].join(this.SEPARATOR_INTERNAL) : this.UNKNOWN_ID;
  }
}

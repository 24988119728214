import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pluck',
  pure: true
})
export class PluckPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public transform(input: Record<string, any> | Record<string, any>[], key: string): any {
    if (!input) {
      return input;
    }

    return Array.isArray(input)
      ? input.map((value: Record<string, unknown>) => value && value[key])
      : input[key];
  }
}

import { Action, createReducer, on } from '@ngrx/store';
import { cleanupPendingState } from '@neuralegion/core';
import {
  changePassword,
  changePasswordFail,
  changePasswordSuccess,
  loadProfile,
  loadProfileFail,
  loadProfileSuccess,
  setPassword,
  setPasswordFail,
  setPasswordSuccess,
  updateProfile,
  updateProfileFail,
  updateProfileSuccess
} from './profile.actions';
import { ProfileState, initialProfileState } from './profile.state';

export const profileReducer = createReducer<ProfileState, Action>(
  initialProfileState,
  on(
    changePassword,
    loadProfile,
    setPassword,
    updateProfile,
    (state: ProfileState, action): ProfileState => ({
      ...state,
      pending: [...state.pending, action],
      error: null
    })
  ),
  on(
    changePasswordSuccess,
    setPasswordSuccess,
    updateProfileSuccess,
    (state: ProfileState, action): ProfileState => cleanupPendingState(state, action)
  ),
  on(
    loadProfileSuccess,
    (state: ProfileState, action): ProfileState =>
      cleanupPendingState(
        {
          ...state,
          entity: { ...state.entity, ...action.payload }
        },
        action
      )
  ),
  on(
    changePasswordFail,
    loadProfileFail,
    updateProfileFail,
    setPasswordFail,
    (state: ProfileState, action): ProfileState =>
      cleanupPendingState(state, action, action.payload)
  )
);

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Email } from '@neuralegion/api';

@Component({
  selector: 'auth-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./forgot-password-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForgotPasswordFormComponent {
  @Input()
  set pending(isPending: boolean) {
    if (isPending) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Output()
  public readonly submitted: EventEmitter<Email> = new EventEmitter<Email>(true);

  public readonly form: FormGroup = new FormGroup({
    email: new FormControl('', Validators.required)
  });

  public submit(): void {
    this.submitted.emit(this.form.value);
  }
}

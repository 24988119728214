import { createAction } from '@ngrx/store';
import { UserConsent, UserConsents } from '@neuralegion/api';

export const updateConsent = createAction(
  '[UserConsents] UPDATE_CONSENT',
  (payload: { consent: UserConsent; value: boolean }) => ({ payload })
);

export const updateConsents = createAction(
  '[UserConsents] UPDATE_CONSENTS',
  (payload: { consents: Partial<UserConsents> }) => ({ payload })
);

export const updateConsentsSuccess = createAction(
  '[UserConsents] UPDATE_CONSENTS_SUCCESS',
  (payload: { consents: Partial<UserConsents> }) => ({
    payload,
    parentType: updateConsents.type
  })
);

export const updateConsentsFail = createAction(
  '[UserConsents] UPDATE_CONSENTS_FAIL',
  (payload: string) => ({
    payload,
    parentType: updateConsents.type
  })
);

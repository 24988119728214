import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { debounceTime, distinctUntilChanged, map } from 'rxjs';
import { Store } from '@ngrx/store';
import equal from 'fast-deep-equal/es6';
import { MqAlias } from '../models';
import { mediaQueryChanged } from '../store';

@Injectable({
  providedIn: 'root'
})
export class MediaQueryService {
  private readonly mqAliases: MqAlias[] = Object.values(MqAlias);

  private readonly mqAliasToSizesMap: Record<MqAlias, string> = {
    [MqAlias.XS]: '(max-width: 599.98px)',
    [MqAlias.SM]: '(min-width: 600px) and (max-width: 959.98px)',
    [MqAlias.MD]: '(min-width: 960px) and (max-width: 1279.98px)',
    [MqAlias.LG]: '(min-width: 1280px) and (max-width: 1919.98px)',
    [MqAlias.XL]: '(min-width: 1920px)',

    [MqAlias.LT_SM]: '(max-width: 599.98px)',
    [MqAlias.LT_MD]: '(max-width: 959.98px)',
    [MqAlias.LT_LG]: '(max-width: 1279.98px)',
    [MqAlias.LT_XL]: '(max-width: 1919.98px)',

    [MqAlias.GT_XS]: '(min-width: 600px)',
    [MqAlias.GT_SM]: '(min-width: 960px)',
    [MqAlias.GT_MD]: '(min-width: 1280px)',
    [MqAlias.GT_LG]: '(min-width: 1920px)'
  };

  private readonly sizeToMqAliasesMap: Record<string, MqAlias[]> = Object.entries(
    this.mqAliasToSizesMap
  ).reduce(
    (res, [alias, size]) =>
      ({
        ...res,
        [size]: [...(res[size] ?? []), alias]
      }) as Record<string, MqAlias[]>,
    {} as Record<string, MqAlias[]>
  );

  constructor(
    breakpointObserver: BreakpointObserver,
    store: Store,
    @Inject(DOCUMENT) document: Document
  ) {
    breakpointObserver
      .observe(Object.values(this.mqAliasToSizesMap))
      .pipe(
        debounceTime(200),
        distinctUntilChanged(equal),
        map(({ breakpoints }: BreakpointState) =>
          Object.entries(breakpoints)
            .filter(([, value]) => value)
            .flatMap(([key]) => this.sizeToMqAliasesMap[key])
        )
      )
      .subscribe((mqAliases: MqAlias[]) => {
        const body: HTMLElement = document.documentElement.querySelector('body');
        body.classList.remove(...this.mqAliases);
        body.classList.add(...mqAliases);
        store.dispatch(mediaQueryChanged({ mqAliases }));
      });
  }
}

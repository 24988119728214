import { Action, createReducer, on } from '@ngrx/store';
import {
  disableAuthProviderSuccess,
  loadAuthProviderIdPMetadataSuccess,
  loadAuthProviderSpMetadataSuccess,
  loadAuthProviderSuccess,
  startSetupAuthProviderSuccess
} from '../auth-providers.actions';
import { AuthProviderState, initialAuthProvidersState } from '../auth-providers.state';

export const authProviderReducer = createReducer<AuthProviderState, Action>(
  initialAuthProvidersState,
  on(
    loadAuthProviderSuccess,
    startSetupAuthProviderSuccess,
    (state: AuthProviderState, action): AuthProviderState => ({
      ...state,
      provider: action.payload,
      idpMetadata: null,
      spMetadata: null
    })
  ),
  on(
    disableAuthProviderSuccess,
    (state: AuthProviderState): AuthProviderState => ({ ...state, provider: null })
  ),
  on(
    loadAuthProviderIdPMetadataSuccess,
    (state: AuthProviderState, action): AuthProviderState => ({
      ...state,
      idpMetadata: action.payload
    })
  ),
  on(
    loadAuthProviderSpMetadataSuccess,
    (state: AuthProviderState, action): AuthProviderState => ({
      ...state,
      spMetadata: action.payload
    })
  )
);

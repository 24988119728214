import { CdkStepper } from '@angular/cdk/stepper';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { trackByIdentity } from '@neuralegion/core';

@Component({
  selector: 'share-custom-stepper',
  templateUrl: './custom-stepper.component.html',
  styleUrls: ['./custom-stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: CdkStepper, useExisting: CustomStepperComponent }]
})
export class CustomStepperComponent extends CdkStepper {
  public trackByIdentity = trackByIdentity;

  public selectStepByIndex(index: number): void {
    this.selectedIndex = index;
  }
}

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import { Observable, Subject, filter, map, startWith, takeUntil } from 'rxjs';
import { PasswordValidators, StrongRulesList } from '../../form';

@Component({
  selector: 'share-password-repeat',
  templateUrl: './password-repeat.component.html',
  styleUrls: ['./password-repeat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: PasswordRepeatComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: PasswordRepeatComponent,
      multi: true
    }
  ]
})
export class PasswordRepeatComponent implements ControlValueAccessor, OnInit, OnDestroy, Validator {
  public readonly strongRulesList = PasswordValidators.strongRulesList;
  public readonly trackByType = (_idx: number, item: StrongRulesList): string => item.type;

  public readonly form: FormGroup;

  public onChange: (value: string) => void;
  public onValidatorChange: () => void;

  private readonly gc = new Subject<void>();
  public passwordErrors$: Observable<ValidationErrors | null>;
  public repeatPasswordError$: Observable<boolean>;

  constructor() {
    this.form = new FormGroup(
      {
        password: new FormControl('', [
          Validators.required,
          Validators.maxLength(128),
          PasswordValidators.requireStrong
        ]),
        repeatPassword: new FormControl('', { validators: [Validators.required] })
      },
      PasswordValidators.matchPassword
    );
  }

  public ngOnInit(): void {
    this.passwordErrors$ = this.form.get('password').statusChanges.pipe(
      map(() => this.form.get('password').errors || {}),
      startWith(
        this.strongRulesList.reduce(
          (res: ValidationErrors, { type }) => ({ ...res, [type]: true }),
          {}
        )
      )
    );

    this.repeatPasswordError$ = this.form.valueChanges.pipe(
      map(() => this.form.get('repeatPassword').value && this.form.hasError('requireMatch'))
    );

    this.form.valueChanges
      .pipe(
        filter(() => !!this.onChange),
        takeUntil(this.gc)
      )
      .subscribe(({ password }: { password: string }) => this.onChange(password));

    this.form.statusChanges
      .pipe(
        filter(() => !!this.onValidatorChange),
        takeUntil(this.gc)
      )
      .subscribe(() => {
        this.onValidatorChange();
      });
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }

  public registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(_fn: () => void): void {
    // skip
  }

  public setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  public writeValue(value: string): void {
    this.form.patchValue({
      password: value
    });
  }

  public validate(_control: AbstractControl): ValidationErrors | null {
    const errors = {
      ...(this.form.get('password').errors || {}),
      ...(this.form.get('repeatPassword').errors || {})
    };

    return Object.keys(errors).length ? errors : null;
  }

  public registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChange = fn;
  }
}

import { DOCUMENT } from '@angular/common';
import { Directive, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Subject, filter, fromEvent, skip, switchMap, takeUntil } from 'rxjs';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input[matAutocomplete]'
})
export class CloseAutocompletePanelOnScrollDirective implements OnInit, OnDestroy {
  private readonly gc = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly matAutocompleteTrigger: MatAutocompleteTrigger,
    private readonly ngZone: NgZone
  ) {}

  public ngOnInit(): void {
    this.ngZone.runOutsideAngular(() =>
      this.matAutocompleteTrigger.autocomplete.opened
        .pipe(
          switchMap(() =>
            fromEvent(this.document.defaultView, 'scroll', {
              passive: true,
              capture: true
            }).pipe(
              filter(
                (event: Event) =>
                  event.target !== this.matAutocompleteTrigger.autocomplete.panel.nativeElement
              ),
              skip(1), // The first event can be from internal scrollIntoView() on element.focus()
              takeUntil(this.matAutocompleteTrigger.autocomplete.closed)
            )
          ),
          takeUntil(this.gc)
        )
        .subscribe(() => this.matAutocompleteTrigger.closePanel())
    );
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }
}

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ResetPasswordPayload } from '@neuralegion/api';
import { resetPassword, selectForgotPasswordPending } from '@neuralegion/auth-api';

// eslint-disable-next-line @angular-eslint/use-component-selector
@Component({
  templateUrl: './reset-password-page.component.html',
  styleUrls: ['./reset-password-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetPasswordPageComponent implements OnInit {
  public pending$: Observable<boolean>;
  private readonly token: string | null;

  constructor(
    private readonly store: Store,
    route: ActivatedRoute
  ) {
    this.token = route.snapshot.paramMap.get('token');
  }

  public ngOnInit(): void {
    this.pending$ = this.store.select(selectForgotPasswordPending);
  }

  public onSubmit(event: ResetPasswordPayload): void {
    this.store.dispatch(resetPassword({ ...event, token: this.token }));
  }
}

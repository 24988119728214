<p>
  Use your phone to scan the following QR code with
  <a
    href="https://support.google.com/accounts/answer/1066447"
    class="link link-external"
    rel="noreferrer noopener"
    target="_blank"
    >Google Authenticator</a
  >,
  <a
    href="https://www.microsoft.com/en-us/account/authenticator"
    class="link link-external"
    rel="noreferrer noopener"
    target="_blank"
    >Microsoft Authenticator</a
  >, <a href="https://authy.com/" class="link link-external" rel="noreferrer noopener" target="_blank">Authy</a>, or use
  any other authenticator apps. If you can’t use a barcode,
  <button class="link link-internal" aria-label="Copy barcode" (click)="doCopy($event)">enter this text code</button>
</p>
<form [formGroup]="form">
  <img alt="QR code" class="qrcode" [src]="url" />
  <p>After scanning the barcode image, the app will display a six-digit code that you can enter below.</p>
  <mat-form-field>
    <mat-label>Authentication Six-Digit Code</mat-label>
    <input type="tel" autocomplete="off" cdkFocusInitial matInput required [formControlName]="'otp'" />
    <mat-icon matSuffix>smartphone</mat-icon>
    <mat-error [matErrorOptions]="{ fieldName: 'Code' }"></mat-error>
  </mat-form-field>
</form>

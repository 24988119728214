import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  MarketplaceCallbackStateStorageService,
  createRequiredOrg,
  selectAuthPendingStatus
} from '@neuralegion/auth-api';

// eslint-disable-next-line @angular-eslint/use-component-selector
@Component({
  templateUrl: './create-org-page.component.html',
  styleUrls: ['./create-org-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateOrgPageComponent implements OnInit {
  public readonly form: FormGroup;

  public pending$: Observable<boolean>;

  public showAccountActivationNotice: boolean;

  constructor(
    private readonly store: Store,
    private readonly marketplaceCallbackStateStorageService: MarketplaceCallbackStateStorageService
  ) {
    this.form = new FormGroup({
      name: new FormControl(null, Validators.required)
    });
  }

  public ngOnInit(): void {
    this.pending$ = this.store.select(selectAuthPendingStatus);
    this.showAccountActivationNotice = !!this.marketplaceCallbackStateStorageService.get();
  }

  public createOrg(payload: { name: string }): void {
    this.store.dispatch(createRequiredOrg(payload));
  }
}

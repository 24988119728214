/* Based on https://github.com/fknop/angular-pipes */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace'
})
export class ReplacePipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public transform(input: any, pattern: any, replacement: any): any {
    if (
      typeof input !== 'string' ||
      typeof pattern === 'undefined' ||
      typeof replacement === 'undefined'
    ) {
      return input;
    }

    return input.replace(pattern, replacement);
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IDENTITY_PROVIDERS, ProviderType } from '@neuralegion/api';
import { trackByIdentity } from '@neuralegion/core';

@Component({
  selector: 'auth-oauth-providers-list',
  templateUrl: './oauth-providers-list.component.html',
  styleUrls: ['./oauth-providers-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OauthProvidersListComponent {
  @Input()
  public btnPrefix = 'Sign in with';

  @Input()
  public disabled = false;

  @Output()
  public readonly selected = new EventEmitter<ProviderType>();

  public readonly trackByIdentity = trackByIdentity;
  public readonly IDENTITY_PROVIDERS = IDENTITY_PROVIDERS;

  public onProviderClick(providerType: ProviderType): void {
    this.selected.emit(providerType);
  }
}

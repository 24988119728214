<nav *ngIf="{ breadcrumbsChanges: breadcrumbService.breadcrumbsChanges$ | async } as sync" aria-label="Breadcrumbs">
  <ol class="breadcrumb" fxShow="true" fxShow.lt-md="false">
    <li
      *ngFor="let breadcrumb of sync.breadcrumbsChanges; trackBy: trackByUrl; last as isActive; index as idx"
      class="breadcrumb-item"
      [data-id]="'breadcrumb_item-' + idx">
      <a
        *ngIf="!isActive; else activeItem"
        attr.aria-label="Navigate to {{ breadcrumb.label }}"
        routerLink="/{{ breadcrumb.url }}"
        [element-data-id]="'link'"
        >{{ breadcrumb.label }}
      </a>

      <ng-template #activeItem>
        <span
          class="active"
          role="heading"
          aria-level="1"
          attr.aria-label="{{ breadcrumb.label }} page"
          [element-data-id]="'active'">
          {{ breadcrumb.label }}
        </span>
      </ng-template>
    </li>
  </ol>

  <div *ngIf="(sync.breadcrumbsChanges | slice: -1)[0] as breadcrumb" fxShow="false" fxShow.lt-md="true">
    <button
      *ngIf="sync.breadcrumbsChanges.length > 1; else tmplOnlyOneBreadcrumb"
      class="breadcrumb-menu"
      aria-label="Navigate to…"
      mat-button
      [element-data-id]="'btn_breadcrumbs'"
      [matMenuTriggerFor]="menu">
      <span class="breadcrumb-menu-pointer" aria-hidden="true">▶</span>
      <span role="heading" aria-level="1" attr.aria-label="{{ breadcrumb.label }} page">
        {{ breadcrumb.label }}
      </span>
    </button>

    <ng-template #tmplOnlyOneBreadcrumb>
      <div
        class="breadcrumb-menu"
        role="heading"
        aria-level="1"
        [attr.data-id]="'breadcrumb'"
        attr.aria-label="{{ breadcrumb.label }} page"
        fxShow="false"
        fxShow.lt-md="true">
        {{ breadcrumb.label }}
      </div>
    </ng-template>
  </div>

  <mat-menu #menu="matMenu">
    <a
      *ngFor="let breadcrumb of sync.breadcrumbsChanges; let idx = index; trackBy: trackByUrl; last as isActive"
      attr.aria-label="Navigate to {{ breadcrumb.label }}"
      mat-menu-item
      routerLink="/{{ breadcrumb.url }}"
      [element-data-id]="'breadcrumb_menu_item-' + idx">
      <span *ngIf="isActive" class="breadcrumb-menu-pointer" aria-hidden="true">▶</span>
      {{ breadcrumb.label }}
    </a>
  </mat-menu>
</nav>

import { createAction } from '@ngrx/store';
import { Session } from '@neuralegion/api';

export const loadSessions = createAction('[Sessions] LOAD_SESSIONS');

export const loadSessionsSuccess = createAction(
  '[Sessions] LOAD_SESSIONS_SUCCESS',
  (payload: Session[]) => ({
    payload,
    parentType: loadSessions.type
  })
);

export const loadSessionsFail = createAction(
  '[Sessions] LOAD_SESSIONS_FAIL',
  (payload: string) => ({
    payload,
    parentType: loadSessions.type
  })
);

export enum DiscoveryType {
  ARCHIVE = 'archive',
  CRAWLER = 'crawler',
  GRAPHQL = 'graphql',
  OAS = 'oas',
  SNIFFER = 'sniffer'
}

export const archiveFileExtensions = ['har', 'zip'];
export const graphqlFileExtensions = ['gql', 'graphql', 'json'];
export const oasFileExtensions = ['yaml', 'yml', 'json'];

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Observable, filter, map, switchMap, take } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SnackbarService } from '@neuralegion/core';
import { ConfirmModalService } from '@neuralegion/share';
import { updateAvailable, updateRequired } from './updater.actions';

@Injectable()
export class UpdaterEffects {
  public readonly updateRequired$: Observable<void> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateRequired),
        switchMap(() =>
          this.confirmModalService
            .confirm({
              buttonOk: 'Reload page',
              message:
                'There is a new critical update for this page, to update please reload the page',
              prohibitCancel: true,
              dataId: 'dialog_critical_update'
            })
            .pipe(take(1), filter(Boolean))
        ),
        map(() => this.performReload())
      ),
    { dispatch: false }
  );

  public readonly updateAvailable$: Observable<void> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateAvailable),
        map(() =>
          this.snackbarService.openWithReloadAction('New version available. Load New Version?')
        )
      ),
    { dispatch: false }
  );

  private performReload(): void {
    this.document.defaultView.location.reload();
  }

  constructor(
    private readonly actions$: Actions,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly confirmModalService: ConfirmModalService,
    private readonly snackbarService: SnackbarService
  ) {}
}

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { InviteDetails } from '@neuralegion/api';
import {
  acceptInvite,
  loadInviteDetails,
  selectInviteDetails,
  selectSignupError
} from '@neuralegion/auth-api';

// eslint-disable-next-line @angular-eslint/use-component-selector
@Component({
  styleUrls: ['./invite-page.component.scss'],
  templateUrl: './invite-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvitePageComponent implements OnInit {
  private readonly memberId: string;
  public readonly token: string;
  public readonly flowFinished: boolean;

  public inviteDetails$: Observable<InviteDetails>;
  public error$: Observable<string>;

  constructor(
    private readonly store: Store,
    route: ActivatedRoute
  ) {
    this.memberId = route.snapshot.paramMap.get('memberId');
    this.token = route.snapshot.paramMap.get('token');
  }

  public ngOnInit(): void {
    this.inviteDetails$ = this.store.select(selectInviteDetails);
    this.error$ = this.store.select(selectSignupError);

    this.store.dispatch(loadInviteDetails({ memberId: this.memberId, token: this.token }));
  }

  public onJoinClick(): void {
    this.store.dispatch(acceptInvite({ memberId: this.memberId, token: this.token }));
  }
}

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponseBase
} from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  catchError,
  distinctUntilChanged,
  filter,
  finalize,
  of,
  switchMap,
  take,
  throwError
} from 'rxjs';
import { Store } from '@ngrx/store';
import { UserSessionStorageService } from '@neuralegion/browser-storage';
import { addHttpError } from '@neuralegion/error-handler';
import { loginRedirect } from '../store';
import { AuthService } from './auth.service';

export interface InterceptorOptions {
  // API prefixes
  readonly prefixes: string[];
  // An endpoint to refresh a token which will no logout user and to redirect it to login page
  readonly tokenEndpoint: RegExp;
  // A pattern for error messages which should not trigger to refresh a token.
  // By default, all 401 errors will lead to refresh a token.
  readonly excludePaths: readonly RegExp[];
  // A callback method deciding whether error should be captured by Store
  shouldTrackError?(err: HttpErrorResponse): unknown;
}

export const INTERCEPTOR_OPTIONS = new InjectionToken<InterceptorOptions>('InterceptorOptions');

@Injectable()
export class HttpInterceptorTokenService implements HttpInterceptor {
  private readonly refreshTokenInProgressSubject = new BehaviorSubject(false);
  private readonly refreshTokenInProgress$ = this.refreshTokenInProgressSubject.asObservable();

  constructor(
    @Inject(INTERCEPTOR_OPTIONS) private readonly options: InterceptorOptions,
    private readonly store: Store,
    private readonly authService: AuthService,
    private readonly userSessionStorageService: UserSessionStorageService
  ) {}

  public intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    return next.handle(this.addAuthenticationToken(request)).pipe(
      catchError((err: HttpErrorResponse) => {
        if (
          this.options.excludePaths.some((pattern: RegExp) =>
            this.checkIfErrorBelongsTo(pattern, err)
          )
        ) {
          return this.handleErrorOnMatchingRoute(err);
        }

        if (err.status !== 401) {
          return throwError(() => err);
        }

        if (this.refreshTokenInProgressSubject.getValue()) {
          return this.putUpcomingRequestToQueue(next, request);
        }

        this.refreshTokenInProgressSubject.next(true);

        return this.renewAccessToken(next, request).pipe(
          catchError(() => {
            this.store.dispatch(
              loginRedirect({ notification: 'Session expired, re-login required' })
            );
            return of(null);
          })
        );
      })
    );
  }

  private putUpcomingRequestToQueue<T>(
    next: HttpHandler,
    request: HttpRequest<T>
  ): Observable<HttpEvent<T>> {
    return this.refreshTokenInProgress$.pipe(
      filter((inProgress: boolean) => !inProgress),
      distinctUntilChanged(),
      take(1),
      switchMap(() => next.handle(this.addAuthenticationToken(request)))
    );
  }

  private handleErrorOnMatchingRoute(err: HttpErrorResponse): Observable<never> {
    if (this.checkIfErrorBelongsTo(this.options.tokenEndpoint, err)) {
      this.store.dispatch(loginRedirect());
    } else if (this.options.shouldTrackError && this.options.shouldTrackError(err)) {
      this.store.dispatch(addHttpError({ error: err }));
    }

    return throwError(() => err);
  }

  private renewAccessToken<T>(
    next: HttpHandler,
    request: HttpRequest<T>
  ): Observable<HttpEvent<T>> {
    return this.authService.renewAccessTokenSilently().pipe(
      switchMap(() => next.handle(this.addAuthenticationToken(request))),
      finalize(() => this.refreshTokenInProgressSubject.next(false))
    );
  }

  private addAuthenticationToken<T>(request: HttpRequest<T>): HttpRequest<T> {
    const { accessToken: token } = this.userSessionStorageService.get() ?? {};
    return token && this.canAttachToken(request)
      ? request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        })
      : request;
  }

  private canAttachToken<T>(request: HttpRequest<T>): boolean {
    if (request.headers.get('authorization')) {
      return false;
    }

    const pathname = this.extractPathname(request);

    return this.options.prefixes.some((prefix: string) => pathname.startsWith(prefix));
  }

  private checkIfErrorBelongsTo(pattern: RegExp, err: HttpErrorResponse): boolean {
    const pathname = this.extractPathname(err);

    return pattern.test(pathname);
  }

  private extractPathname(request: HttpRequest<unknown> | HttpResponseBase): string {
    let pathname: string;

    try {
      ({ pathname } = new URL(request.url));
    } catch {
      pathname = request.url;
    }

    return pathname;
  }
}

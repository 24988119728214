import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subject, first, map, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { ProviderType } from '@neuralegion/api';
import { loginRedirect, oauthFinalize, ssoInit } from '@neuralegion/auth-api';
import { BreadcrumbService } from '@neuralegion/core';

// eslint-disable-next-line @angular-eslint/use-component-selector
@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthCallbackComponent implements OnInit, OnDestroy {
  private readonly gc = new Subject<void>();

  constructor(
    private readonly store: Store,
    private readonly route: ActivatedRoute,
    breadcrumbService: BreadcrumbService
  ) {
    breadcrumbService.configure([]);
  }

  public ngOnInit(): void {
    this.route.queryParams
      .pipe(
        first(),
        map((params: Params) => params),
        takeUntil(this.gc)
      )
      .subscribe((params: Params) => this.exchangeParams(params));
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }

  private exchangeParams(params: Params): void {
    const provider: ProviderType = this.route.snapshot.paramMap.get('type') as ProviderType;

    if (params?.iss) {
      return this.initiateLogin(provider, params.iss);
    }

    this.store.dispatch(
      oauthFinalize({
        params,
        providerType: provider
      })
    );
  }

  private initiateLogin(initiatedBy: ProviderType, iss: string): void {
    const [, slug] = /^(?:http[s]:\/\/)?(?:([^.]+)\.)?okta\.com$/.exec(iss ?? '') ?? [];

    return slug
      ? this.store.dispatch(
          ssoInit({
            initiatedBy,
            slug
          })
        )
      : this.store.dispatch(loginRedirect());
  }
}

<fieldset attr.aria-label="{{ label }}" fxLayout="row wrap" fxLayoutAlign="start center">
  <mat-checkbox [formControl]="limitSwitcher" (change)="onLimitToggle($event)">
    {{ label }}
  </mat-checkbox>

  <mat-form-field>
    <input
      type="number"
      #limitEl
      attr.aria-label="{{ label }}"
      matInput
      required
      [formControl]="limitControl"
      [max]="max + ''"
      [min]="min + ''"
      [placeholder]="placeholder" />
    <span fxFlexOffset="3px" matSuffix>{{ suffix }}</span>
    <mat-hint fxLayout="row" fxLayoutAlign="start center" [class.mat-error]="limitControl.invalid">
      <ng-container *ngIf="max || max === 0; else tmplInfinity">Range {{ min }}...{{ max }}</ng-container>
      <ng-template #tmplInfinity>Range {{ min }}...<span class="infinity">∞</span></ng-template>
    </mat-hint>
  </mat-form-field>
</fieldset>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'host',
  pure: true
})
export class HostPipe implements PipeTransform {
  public transform(url: string): string {
    return new URL(url).host;
  }
}

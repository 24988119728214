import {
  CdkDragDrop,
  CdkDragSortEvent,
  CdkDragStart,
  moveItemInArray
} from '@angular/cdk/drag-drop';
import { Directive, ElementRef, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { ColumnConfig } from '@neuralegion/api';
import { TableSettingsService } from '@neuralegion/browser-storage';
import { ColumnWidthValue, ColumnsConfigMigration, ColumnsConfigStore } from '@neuralegion/core';
import { PaginatedTableComponent } from './paginated-table-component.directive';

@Directive()
export abstract class ResizableTableComponent extends PaginatedTableComponent {
  @ViewChild('headerRow', { read: ElementRef })
  private readonly headerRowRef: ElementRef<HTMLElement>;

  abstract get defaultColumnsConfig(): ColumnConfig[];

  public resizeActive = false;

  public readonly columnsConfig$: Observable<ColumnConfig[]> =
    this.columnsConfigStore.columnConfig$;
  public readonly activeColumnIds$: Observable<string[]> = this.columnsConfigStore.activeColumnIds$;
  public readonly activeColumnWidths$: Observable<ColumnWidthValue[]> =
    this.columnsConfigStore.activeColumnWidths$;

  protected constructor(
    private readonly columnsConfigStore: ColumnsConfigStore,
    tableSettingsService: TableSettingsService
  ) {
    super(tableSettingsService);
  }

  public columnsWidthUpdated(widthValues: ColumnWidthValue[]): void {
    this.columnsConfigStore.updateColumnWidths(widthValues);
  }

  public setColumnsConfig(columnsConfig: ColumnConfig[]): void {
    this.columnsConfigStore.updateColumnConfig(columnsConfig);
  }

  public override resetTableSettings(): void {
    super.resetTableSettings();
    this.columnsConfigStore.resetColumnConfig();
  }

  public onColumnDrop(e: CdkDragDrop<undefined>): void {
    this.headerRowRef.nativeElement.classList.remove('no-drop');
    this.headerRowRef.nativeElement.classList.remove('drag-cursor');

    const columnsConfigAux: (ColumnConfig & { realIndex: number })[] =
      this.getActiveColumnsAuxConfig();
    if (columnsConfigAux[e.currentIndex].fixed) {
      return;
    }

    const reordered = [...this.columnsConfigStore.configSnapshot];
    moveItemInArray(
      reordered,
      columnsConfigAux[e.previousIndex].realIndex,
      columnsConfigAux[e.currentIndex].realIndex
    );
    this.setColumnsConfig(reordered);
  }

  public onColumnMove(e: CdkDragSortEvent): void {
    const columnsConfigAux: (ColumnConfig & { realIndex: number })[] =
      this.getActiveColumnsAuxConfig();
    if (columnsConfigAux[e.currentIndex].fixed) {
      this.headerRowRef.nativeElement.classList.add('no-drop');
    } else {
      this.headerRowRef.nativeElement.classList.remove('no-drop');
    }
  }

  public onDragStart(_event: CdkDragStart) {
    this.headerRowRef.nativeElement.classList.add('drag-cursor');
  }

  protected override initTable(): void {
    super.initTable();
    this.columnsConfigStore.init(
      this.tableName,
      this.defaultColumnsConfig,
      this.createColumnsConfigMigration()
    );
  }

  protected createColumnsConfigMigration(): ColumnsConfigMigration | undefined {
    return undefined;
  }

  private getActiveColumnsAuxConfig(): (ColumnConfig & { realIndex: number })[] {
    return this.columnsConfigStore.configSnapshot
      .map((columnConfig: ColumnConfig, realIndex: number) => ({ ...columnConfig, realIndex }))
      .filter((c: ColumnConfig) => c.active);
  }
}

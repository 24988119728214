import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { IntegrationsAnalyticsEffects } from '../store/integrations-analytics.effects';
import { IntegrationsEffects } from '../store/integrations.effects';
import { integrationsReducer } from '../store/integrations.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature('integrations', integrationsReducer),
    EffectsModule.forFeature([IntegrationsAnalyticsEffects, IntegrationsEffects])
  ]
})
export class IntegrationsApiModule {}

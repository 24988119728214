import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { HeaderConfig } from '@neuralegion/api';
import {
  isValidHeaderName,
  isValidHeaderValue,
  isValidHost,
  isValidIp,
  isValidUrl
} from '@neuralegion/core';
import { getHashCode } from '@neuralegion/lang';

export class CustomValidators {
  public static personName = Validators.pattern(/^([\p{L}\- '`‛’‘]|(\. ))+$/u);

  public static integer(c: AbstractControl): ValidationErrors | null {
    return c.value == null || Number.isInteger(c.value) ? null : { integer: true };
  }

  public static byteSize(limit: number): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      const value: string = c.value ?? '';
      return !value || new Blob([value]).size <= limit ? null : { byteSize: limit };
    };
  }

  public static blockList(list: readonly string[]): ValidatorFn {
    return (c: AbstractControl<string | undefined>): ValidationErrors | null =>
      !list.includes(c.value?.trim())
        ? null
        : {
            blockList: true
          };
  }

  public static url(c: AbstractControl): ValidationErrors | null {
    return isValidUrl(c.value) ? null : { url: true };
  }

  public static optionalUrl(c: AbstractControl): ValidationErrors | null {
    return c.value ? CustomValidators.url(c) : null;
  }

  public static host(c: AbstractControl): ValidationErrors | null {
    return isValidHost(c.value) ? null : { host: true };
  }

  public static hostWoPort(c: AbstractControl): ValidationErrors | null {
    return isValidHost(c.value, false) ? null : { host: true };
  }

  public static ip(c: AbstractControl): ValidationErrors | null {
    return isValidIp(c.value) ? null : { ip: true };
  }

  private static isValidRegExp(value: string): boolean {
    try {
      new RegExp(value);
      return true;
    } catch {
      return false;
    }
  }

  public static regExp(c: AbstractControl): ValidationErrors | null {
    return !c.value || CustomValidators.isValidRegExp(c.value) ? null : { regExp: true };
  }

  public static regExps(c: AbstractControl<string | string[]>): ValidationErrors | null {
    return (Array.isArray(c.value) ? c.value : c.value?.split('\n') || []).every((v: string) =>
      CustomValidators.isValidRegExp(v)
    )
      ? null
      : { regExps: true };
  }

  public static xpath(c: AbstractControl): ValidationErrors | null {
    const xpath = c.value;
    if (!xpath) {
      return null;
    }

    try {
      const evaluator = new XPathEvaluator();
      evaluator.createExpression(xpath);

      return null;
    } catch {
      return { xpath: true };
    }
  }

  public static unique(form: AbstractControl): ValidationErrors | null {
    const controls: FormControl[] = ((form as FormArray).controls as FormControl[]) || [];

    const valuesMatrix: Map<number, number> = new Map<number, number>();

    controls.forEach((item: FormControl) => {
      CustomValidators.clearSetDuplicateError({ valuesMatrix, control: item });
    });

    return null;
  }

  public static uniqueValues(control: AbstractControl): ValidationErrors | null {
    if (!control || !Array.isArray(control.value)) {
      return null;
    }

    const valuesMatrix: Map<number, number> = new Map<number, number>();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (control.value || []).some((value: any) => {
      const hash: number = getHashCode(value);
      if (!valuesMatrix.has(hash)) {
        valuesMatrix.set(hash, 1);
        return false;
      }
      return true;
    })
      ? { duplicate: true }
      : null;
  }

  public static uniqueLines({ value }: AbstractControl): ValidationErrors | null {
    if (typeof value !== 'string') {
      return null;
    }

    const lines = value.split('\n');
    return new Set(lines).size !== lines.length ? { uniqueLines: true } : null;
  }

  public static uniqueCaseInsensitive(form: AbstractControl): ValidationErrors | null {
    if (!form || !Array.isArray(form.value)) {
      return null;
    }
    return new Set(form.value.map((v: string) => v?.toLowerCase())).size === form.value.length
      ? null
      : { duplicate: true };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static some(predicate: (value: any, i?: number, array?: any[]) => boolean): ValidatorFn {
    return (form: AbstractControl): ValidationErrors | null => {
      let controls: FormControl[];
      if (form instanceof FormGroup) {
        controls = Object.values(form.controls) as FormControl[];
      } else if ((form as FormArray).controls) {
        controls = (form as FormArray).controls as FormControl[];
      } else {
        return null;
      }

      const isValid: boolean = controls.some((item: FormControl, i: number) =>
        predicate(item.value, i, form.value)
      );

      if (!isValid) {
        return {
          some: true
        };
      }

      return null;
    };
  }

  public static urlsArray(control: AbstractControl): ValidationErrors | null {
    (((control as FormArray).controls as FormControl[]) || []).forEach((item: AbstractControl) => {
      if (isValidUrl(item.value)) {
        CustomValidators.clearError(item, 'url');
      } else {
        item.setErrors({ url: true });
      }
    });

    return null;
  }

  public static headerNamesString(delimiterRegExp: string): ValidatorFn {
    return (c: AbstractControl<string>): ValidationErrors | null => {
      if (!c.value) {
        return null;
      }

      const headerNames: string[] = c.value.split(RegExp(delimiterRegExp));
      return headerNames.every(isValidHeaderName) ? null : { headerNames: true };
    };
  }

  public static headerName(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    return isValidHeaderName(control.value) ? null : { headerName: true };
  }

  public static headerValue(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    return isValidHeaderValue(control.value) ? null : { headerValue: true };
  }

  public static httpStatuses(c: AbstractControl<string[]>): ValidationErrors | null {
    return c.value.some((value: string) => {
      const status = Number(value);
      return isNaN(status) || status < 100 || status > 999;
    })
      ? { httpStatuses: true }
      : null;
  }

  public static keyValueRequired(
    control: AbstractControl<(HeaderConfig & { key?: string })[]>
  ): ValidationErrors | null {
    let { value } = control;
    if (value?.some((v: HeaderConfig) => !!v.mergeStrategy)) {
      value = value.map((v: HeaderConfig) => ({ ...v, key: v.name }));
    }
    return value?.length && value[0].key && value[0].value ? null : { keyValueRequired: true };
  }

  public static clearSetDuplicateError(params: {
    valuesMatrix: Map<number, number>;
    control?: AbstractControl;
    valueMapper?: (value: unknown) => unknown;
    errorName?: string;
  }): boolean {
    if (!params.control) {
      return false;
    }

    const fullParams = {
      errorName: 'duplicate',
      valueMapper: (value: unknown) => value,
      ...params
    };

    const mappedValue = fullParams.valueMapper(fullParams.control.value);

    if (!mappedValue) {
      return false;
    }

    const hash: number = getHashCode(mappedValue);
    if (!fullParams.valuesMatrix.has(hash)) {
      fullParams.valuesMatrix.set(hash, 1);
      CustomValidators.clearError(fullParams.control, fullParams.errorName);
      return false;
    }

    fullParams.control.setErrors({
      ...(fullParams.control.errors || {}),
      [fullParams.errorName]: true
    });
    return true;
  }

  public static clearError(c: AbstractControl, errorCode: string): void {
    if (c.errors == null) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [errorCode]: tmp, ...rest } = c.errors;

    if (!Object.keys(rest).length) {
      c.setErrors(null);
    } else {
      c.setErrors(rest);
    }
  }

  public static entityId(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    return Validators.pattern(/^[0-9A-Za-z]{22}$/)(control) ? { entityId: true } : null;
  }
}

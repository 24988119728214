<mat-card>
  <mat-card-header>
    <mat-card-title role="heading" aria-level="1"> Create a Free Account </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div>
      <div class="highlighted">
        <p>It looks like you don't have an account with</p>
        <p>
          <b>{{ (details$ | async)?.email || 'N/A' }}</b>
        </p>
        <p>Creating an account is easy and free</p>
      </div>
      <button color="primary" mat-raised-button (click)="confirm()">Create a free account</button>
    </div>
    <auth-signup-policy></auth-signup-policy>
    <auth-link-signin [message]="'Want to use another account? Go to'"></auth-link-signin>
  </mat-card-content>
</mat-card>

export enum CardType {
  VISA = 'visa',
  MASTERCARD = 'mastercard',
  AMERICAN_EXPRESS = 'amex',
  DINERS_CLUB = 'diners',
  DISCOVER = 'discover',
  JCB = 'jcb',
  UNION_PAY = 'unionpay',
  UNKNOWN = 'unknown'
}

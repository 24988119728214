import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { DateRange, DefaultMatCalendarRangeStrategy } from '@angular/material/datepicker';

@Injectable()
export class BidirectionalSelectionRangeStrategy<D> extends DefaultMatCalendarRangeStrategy<D> {
  constructor(private readonly dateAdapter: DateAdapter<D>) {
    super(dateAdapter);
  }

  public override createPreview(activeDate: D | null, currentRange: DateRange<D>): DateRange<D> {
    let start: D | null = null;
    let end: D | null = null;

    if (!currentRange.start || currentRange.end || !activeDate) {
      return new DateRange<D>(start, end);
    }

    if (this.dateAdapter.compareDate(activeDate, currentRange.start) >= 0) {
      start = currentRange.start;
      end = activeDate;
    } else {
      start = activeDate;
      end = currentRange.start;
    }

    return new DateRange<D>(start, end);
  }
}

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <mat-form-field>
    <mat-label>Email</mat-label>
    <input
      type="email"
      #emailElement
      autocomplete="username"
      email
      matInput
      maxlength="64"
      required
      [formControlName]="'email'" />
    <mat-error [matErrorOptions]="{ fieldName: 'Email' }"></mat-error>
  </mat-form-field>

  <share-secret-field
    maxlength="128"
    required
    [autocomplete]="'current-password'"
    [formControlName]="'password'"
    [label]="'Password'">
  </share-secret-field>
  <span class="forgot-password" fxLayoutAlign="end center">
    <a class="link link-internal" [routerLink]="'/forgot-password'"> Forgot password? </a>
  </span>

  <ngx-recaptcha2
    *ngIf="enableCaptcha"
    #captchaElem
    fxLayoutAlign="space-around center"
    [formControlName]="'recaptcha'"
    [siteKey]="siteKey"
    [theme]="darkTheme ? 'dark' : 'light'"
    [type]="'image'"
    (expire)="handleExpire()"
    (load)="handleLoad()"
    (reset)="handleReset()"
    (success)="handleSuccess()">
  </ngx-recaptcha2>

  <button color="primary" mat-raised-button [disabled]="!form.valid || (enableCaptcha && !captchaSuccess)">
    Sign in
  </button>
</form>

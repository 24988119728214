import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[shareElementText]'
})
export class ElementTextDirective {
  public additionalItems: string[] = [];

  constructor(public readonly elementRef: ElementRef) {}

  public getText(): string {
    return [this.elementRef.nativeElement.textContent, ...this.additionalItems]
      .join(' ')
      .toLowerCase();
  }
}

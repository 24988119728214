import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { Payment, PaymentResult, UpcomingInvoice } from '../models';

@Injectable()
export class PaymentsService {
  constructor(private readonly http: HttpClient) {}

  public loadPaymentHistory(): Observable<Payment[]> {
    return this.http.get<Payment[]>('/api/v1/payments');
  }

  public removeSubscription(subscriptionId: string): Observable<void> {
    return this.http.delete<void>(`/api/v1/subscriptions/${subscriptionId}`);
  }

  public loadUpcomingInvoice(planId: string, coupon?: string): Observable<UpcomingInvoice> {
    return this.http.get<UpcomingInvoice>(`/api/v1/subscriptions/upcoming-invoice`, {
      params: {
        planId,
        ...(coupon ? { coupon } : {})
      }
    });
  }

  public repayInvoice(paymentId: string, paymentMethodId: string): Observable<PaymentResult> {
    return this.updatePaymentMethod(paymentId, paymentMethodId).pipe(
      switchMap(() => this.http.put<PaymentResult>(`/api/v1/payments/${paymentId}/repay`, {}))
    );
  }

  private updatePaymentMethod(paymentId: string, paymentMethodId: string): Observable<void> {
    return this.http.patch<void>(`/api/v1/payments/${paymentId}`, { paymentMethodId });
  }
}

import { NgModule } from '@angular/core';
import { ShareModule } from '@neuralegion/share';
import {
  AccessDeniedPageComponent,
  ClientErrorComponent,
  NotFoundPageComponent,
  NotImplementedPageComponent,
  ServerErrorPageComponent
} from '../components';
import { ErrorsPagesRoutingModule } from './error-pages-routing.module';

@NgModule({
  imports: [ShareModule, ErrorsPagesRoutingModule],
  declarations: [
    AccessDeniedPageComponent,
    ClientErrorComponent,
    NotFoundPageComponent,
    NotImplementedPageComponent,
    ServerErrorPageComponent
  ],
  providers: [],
  exports: []
})
export class ErrorPagesModule {}

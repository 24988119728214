import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class LocationStorageService {
  private readonly LOCATION_KEY = 'location';

  constructor(
    private readonly localStorage: LocalStorageService,
    private readonly router: Router
  ) {}

  public store(url: string): void {
    this.localStorage.set(this.LOCATION_KEY, url);
  }

  public get(): string | null {
    return this.localStorage.get(this.LOCATION_KEY) as string | null;
  }

  public async restore({ useRootFallback } = { useRootFallback: true }): Promise<void> {
    try {
      const url: string | null = this.get();
      if (useRootFallback || url) {
        await this.router.navigateByUrl(url || '/');
      }
    } finally {
      this.clear();
    }
  }

  public clear(): void {
    this.localStorage.delete(this.LOCATION_KEY);
  }
}

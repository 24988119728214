import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectAuthPendingStatus, ssoInit } from '@neuralegion/auth-api';

// eslint-disable-next-line @angular-eslint/use-component-selector
@Component({
  styleUrls: ['./sso-page.component.scss'],
  templateUrl: './sso-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SsoPageComponent implements OnInit {
  public pending$: Observable<boolean>;

  constructor(private readonly store: Store) {}

  public ngOnInit(): void {
    this.pending$ = this.store.select(selectAuthPendingStatus);
  }

  public onSubmit({ slug }: { slug: string }): void {
    return this.store.dispatch(
      ssoInit({
        slug
      })
    );
  }
}

export enum AttackParamLocation {
  ARTIFICAL_FRAGMENT = 'artifical-fragment',
  ARTIFICAL_QUERY = 'artifical-query',
  BODY = 'body',
  HEADER = 'header',
  URL_FRAGMENT = 'fragment',
  URL_PATH = 'path',
  URL_QUERY = 'query'
}

export const sortedAttackParamLocations = [
  AttackParamLocation.URL_PATH,
  AttackParamLocation.URL_QUERY,
  AttackParamLocation.URL_FRAGMENT,
  AttackParamLocation.HEADER,
  AttackParamLocation.BODY,
  AttackParamLocation.ARTIFICAL_QUERY,
  AttackParamLocation.ARTIFICAL_FRAGMENT
];

const sortedAttackParamLocationsMap = sortedAttackParamLocations.reduce(
  (res, item, idx) => res.set(item, idx),
  new Map<AttackParamLocation, number>()
);

export const attackParamLocationsComparator = (a: AttackParamLocation, b: AttackParamLocation) =>
  sortedAttackParamLocationsMap.get(a) - sortedAttackParamLocationsMap.get(b);

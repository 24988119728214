import { Pipe, PipeTransform } from '@angular/core';
import { TaskRepeatIntervalUnit } from '@neuralegion/api';

@Pipe({
  name: 'feedScheduleIntervalPipe',
  pure: true
})
export class FeedScheduleIntervalPipePipe implements PipeTransform {
  public transform(interval: number, intervalUnit: TaskRepeatIntervalUnit): string {
    if (interval === 1) {
      switch (intervalUnit) {
        case TaskRepeatIntervalUnit.HOURS:
          return 'hourly';
        case TaskRepeatIntervalUnit.DAYS:
          return 'daily';
        case TaskRepeatIntervalUnit.WEEKS:
          return 'weekly';
        case TaskRepeatIntervalUnit.MONTHS:
          return 'monthly';
        case TaskRepeatIntervalUnit.YEARS:
          return 'yearly';
      }
    } else {
      return `every ${interval} ${intervalUnit}`;
    }
  }
}

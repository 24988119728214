import { Action, createReducer, on } from '@ngrx/store';
import { cleanupPending, cleanupPendingState } from '@neuralegion/core';
import {
  loadRepeaterTest,
  loadRepeaterTestFail,
  loadRepeaterTestSuccess,
  pollRepeaterTest,
  pollRepeaterTestFail,
  pollRepeaterTestSuccess,
  runRepeaterTest,
  runRepeaterTestFail,
  runRepeaterTestSuccess,
  updateRepeaterTest,
  updateRepeaterTestFail,
  updateRepeaterTestSuccess
} from './repeater-network.actions';
import {
  RepeaterNetworkState,
  repeaterNetworkEntityAdapter,
  repeaterNetworkInitialState
} from './repeater-network.state';

export const repeaterNetworkReducer = createReducer<RepeaterNetworkState, Action>(
  repeaterNetworkInitialState,
  on(
    runRepeaterTest,
    loadRepeaterTest,
    pollRepeaterTest,
    updateRepeaterTest,
    (state: RepeaterNetworkState, action): RepeaterNetworkState => ({
      ...state,
      pending: [...state.pending, action]
    })
  ),
  on(
    runRepeaterTestSuccess,
    loadRepeaterTestSuccess,
    pollRepeaterTestSuccess,
    updateRepeaterTestSuccess,
    (state: RepeaterNetworkState, action): RepeaterNetworkState =>
      action.payload
        ? repeaterNetworkEntityAdapter.setOne(action.payload, {
            ...state,
            pending: cleanupPending(state.pending, action.parentType)
          })
        : cleanupPendingState(state, action)
  ),
  on(
    runRepeaterTestFail,
    loadRepeaterTestFail,
    pollRepeaterTestFail,
    updateRepeaterTestFail,
    (state: RepeaterNetworkState, action): RepeaterNetworkState => ({
      ...state,
      pending: cleanupPending(state.pending, action.parentType),
      error: action.payload
    })
  )
);

<img src="/assets/logo-theme-dark.svg" alt="Bright Security" class="logo" />
<div class="mat-headline-6">Our 30-day free trial includes:</div>
<ul role="none">
  <li><span class="bullet" aria-hidden="true">•</span>5 scan hours</li>
  <li><span class="bullet" aria-hidden="true">•</span>3 user accounts</li>
  <li><span class="bullet" aria-hidden="true">•</span>200 MB file storage</li>
  <li><span class="bullet" aria-hidden="true">•</span>One integration</li>
  <li><span class="bullet" aria-hidden="true">•</span>Unlimited CI/CD integrations</li>
  <li class="icons" aria-hidden="true">
    <div>
      <mat-icon [svgIcon]="'github'"></mat-icon>
      <mat-icon [svgIcon]="'azure'"></mat-icon>
      <mat-icon [svgIcon]="'jira'"></mat-icon>
      <mat-icon [svgIcon]="'gitlab'"></mat-icon>
      <mat-icon [svgIcon]="'circleci'"></mat-icon>
      <mat-icon [svgIcon]="'jenkins'"></mat-icon>
      <mat-icon [svgIcon]="'travisci'"></mat-icon>
      <mat-icon *ngIf="environment.mondayAppId" [svgIcon]="'monday'"></mat-icon>
      <mat-icon *ngIf="!environment.production" [svgIcon]="'servicenow'"></mat-icon>
      <mat-icon [svgIcon]="'slack'"></mat-icon>
      <mat-icon [svgIcon]="'jfrog'"></mat-icon>
    </div>
  </li>
</ul>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extraSign',
  pure: true
})
export class ExtraSignPipe implements PipeTransform {
  public transform(value: string, numericValue: number): string {
    if (numericValue === 0) {
      return `±${value}`;
    }

    if (numericValue > 0) {
      return `+${value}`;
    }

    return `${value}`;
  }
}

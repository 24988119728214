import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, exhaustMap, filter, map, of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { AppRouterState, ofPrimaryRouterNavigated } from '@neuralegion/core';
import { loadProjects } from '@neuralegion/projects-lookup-api';
import { StorageFile } from '../models';
import { StorageService } from '../services';
import {
  associateFileWithProject,
  associateFileWithProjectFail,
  associateFileWithProjectSuccess,
  loadFiles,
  loadFilesFail,
  loadFilesSuccess,
  removeFiles,
  removeFilesFail,
  removeFilesSuccess
} from './storage.actions';

@Injectable()
export class StorageEffects {
  public readonly loadFiles$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFiles),
      exhaustMap(
        (): Observable<Action> =>
          this.storageService.loadFiles().pipe(
            map((storageFiles: StorageFile[]) => loadFilesSuccess(storageFiles)),
            catchError((err: HttpErrorResponse) => of(loadFilesFail(err.error)))
          )
      )
    )
  );

  public readonly loadFilesSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFilesSuccess),
      filter(() => this.router.url === '/storage'),
      map(({ payload: items }) =>
        loadProjects([...new Set<string>(items.map((item) => item.projectId).filter(Boolean))])
      )
    )
  );

  public readonly removeFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeFiles),
      exhaustMap(
        (action: ReturnType<typeof removeFiles>): Observable<Action> =>
          this.storageService.deleteFiles(action.payload.ids).pipe(
            map(() => removeFilesSuccess()),
            catchError((err: HttpErrorResponse) => of(removeFilesFail(err.error)))
          )
      )
    )
  );

  public readonly removeFilesSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(removeFilesSuccess),
      exhaustMap(() => [loadFiles()])
    )
  );

  public readonly associateFileWithProject$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(associateFileWithProject),
      exhaustMap(({ payload: { fileId, projectId } }) =>
        this.storageService.associateFileWithProject(fileId, projectId).pipe(
          map(() => associateFileWithProjectSuccess({ fileId, projectId })),
          catchError((err: HttpErrorResponse) => of(associateFileWithProjectFail(err.error)))
        )
      )
    )
  );

  public readonly navigated$ = createEffect(() =>
    this.actions$.pipe(
      ofPrimaryRouterNavigated((state: AppRouterState) => state.pathname === '/storage'),
      map(() => loadFiles())
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly storageService: StorageService
  ) {}
}

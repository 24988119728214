import { Action, MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { Role, Scope } from '@neuralegion/api';
import { selectUserScopes } from '@neuralegion/auth-api';
import { RolesState, rolesEntityAdapter } from './roles.state';
import { State } from './state';

export const selectRolesState = createFeatureSelector<RolesState>('roles');

export const selectRoles = createSelector<State, [RolesState], Role[]>(
  selectRolesState,
  rolesEntityAdapter.getSelectors().selectAll
);

export const selectAllowedRoleIds = createSelector<
  State,
  [Role[], ReadonlySet<Scope>],
  Set<string>
>(
  selectRoles,
  selectUserScopes,
  (roles: Role[], scopes: ReadonlySet<Scope>) =>
    new Set<string>(
      roles
        .filter((role: Role) => role.scopes.every((scope: Scope) => scopes.has(scope)))
        .map((role) => role.id)
    )
);

export const selectRolesMap = createSelector<State, [Role[]], Map<string, Role>>(
  selectRoles,
  (roles: Role[]) => new Map<string, Role>(roles.map((role) => [role.id, role]))
);

export const selectRoleById = (roleId: string): MemoizedSelector<State, Role> =>
  createSelector<State, [RolesState], Role>(
    selectRolesState,
    (state: RolesState) => state.entities[roleId]
  );

export const selectRolesPendingActions = createSelector<State, [RolesState], Action[]>(
  selectRolesState,
  (state: RolesState) => state.pending
);

export const selectRolesPendingStatus = createSelector<State, [Action[]], boolean>(
  selectRolesPendingActions,
  (actions: Action[]) => actions?.length > 0
);

import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { trackSignup } from '@neuralegion/analytics-api';
import { InviteDetails, ProviderType } from '@neuralegion/api';
import {
  MarketplaceCallbackStateStorageService,
  loadInviteDetails,
  selectInviteDetails,
  selectSignupPendingStatus
} from '@neuralegion/auth-api';
import { deactivateLayout } from '@neuralegion/core';

// eslint-disable-next-line @angular-eslint/use-component-selector
@Component({
  styleUrls: ['./signup-page.component.scss'],
  templateUrl: './signup-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignupPageComponent implements OnInit {
  private readonly memberId: string;
  public readonly token: string;

  public pending$: Observable<boolean>;
  public inviteDetails$: Observable<InviteDetails>;
  public marketplaceCallbackState: string | null = null;

  protected acceptPolicy = false;

  constructor(
    private readonly store: Store,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly marketplaceCallbackStateStorageService: MarketplaceCallbackStateStorageService,
    route: ActivatedRoute
  ) {
    this.memberId = route.snapshot.paramMap.get('memberId');
    this.token = route.snapshot.paramMap.get('token');
  }

  public ngOnInit(): void {
    this.pending$ = this.store.select(selectSignupPendingStatus);
    this.inviteDetails$ = this.store.select(selectInviteDetails);
    this.marketplaceCallbackState = this.marketplaceCallbackStateStorageService.get();

    if (this.memberId && this.token) {
      this.store.dispatch(loadInviteDetails({ memberId: this.memberId, token: this.token }));
    }
  }

  public onOauthLogin(provider: ProviderType, inviteDetails: InviteDetails | null): void {
    this.store.dispatch(
      trackSignup({
        provider,
        type: this.marketplaceCallbackState ? 'aws' : 'social',
        invited: !!inviteDetails
      })
    );

    this.store.dispatch(deactivateLayout());
    setTimeout(
      () =>
        (this.document.location.href = `${this.document.location.origin}/api/v1/auth/login/${provider}`),
      0
    );
  }

  protected onAcceptPolicyChanged(accept: boolean): void {
    this.acceptPolicy = accept;
  }
}

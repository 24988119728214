import { NgModule } from '@angular/core';
import { NgxCaptchaModule } from 'ngx-captcha';
import { MultiFactorApiModule } from '@neuralegion/multi-factor-api';
import { ShareModule } from '@neuralegion/share';
import {
  AcceptSignupPolicyComponent,
  CreateOrgComponent,
  ForgotPasswordFormComponent,
  LoginFormComponent,
  MfaFormComponent,
  OauthProvidersListComponent,
  ResetPasswordFormComponent,
  SignupFormComponent,
  SignupPolicyComponent,
  SsoFormComponent,
  SsoProvidersListComponent
} from '../components';
import { AuthRootComponent, WelcomeBackComponent, WelcomeComponent } from '../components/layout';
import {
  AuthCallbackComponent,
  ConfirmCreateUserComponent,
  CreateOrgPageComponent,
  ForgotPasswordPageComponent,
  InvitePageComponent,
  LinkSigninComponent,
  LoginPageComponent,
  LogoutComponent,
  MarketplaceCallbackComponent,
  MfaPageComponent,
  MfaSetupPageComponent,
  ResetPasswordPageComponent,
  ResetPasswordSentPageComponent,
  SignupPageComponent,
  SignupRegisterPageComponent,
  SignupSuccessPageComponent,
  SsoPageComponent,
  SsoProvidersPageComponent
} from '../containers';
import { AuthRoutingModule } from './auth-routing.module';

@NgModule({
  imports: [ShareModule, MultiFactorApiModule, NgxCaptchaModule, AuthRoutingModule],
  declarations: [
    AuthRootComponent,
    WelcomeComponent,
    WelcomeBackComponent,

    AcceptSignupPolicyComponent,
    CreateOrgComponent,
    ConfirmCreateUserComponent,
    ForgotPasswordFormComponent,
    LoginFormComponent,
    MfaFormComponent,
    OauthProvidersListComponent,
    ResetPasswordFormComponent,
    SignupFormComponent,
    SignupPolicyComponent,
    SsoFormComponent,
    SsoProvidersListComponent,

    AuthCallbackComponent,
    MarketplaceCallbackComponent,
    CreateOrgPageComponent,
    ForgotPasswordPageComponent,
    InvitePageComponent,
    LinkSigninComponent,
    LoginPageComponent,
    LogoutComponent,
    MfaPageComponent,
    MfaSetupPageComponent,
    ResetPasswordPageComponent,
    ResetPasswordSentPageComponent,
    SignupPageComponent,
    SignupRegisterPageComponent,
    SignupSuccessPageComponent,
    SsoPageComponent,
    SsoProvidersPageComponent
  ]
})
export class AuthModule {}

import { createAction } from '@ngrx/store';
import { ID } from '@neuralegion/api';
import { PaymentMethod, PaymentMethodPayload } from '../models';

export const addPaymentMethod = createAction(
  '[BillingPaymentMethods] ADD_PAYMENT_METHOD',
  (payload: { paymentMethod: PaymentMethodPayload }) => ({ payload })
);

export const addPaymentMethodFail = createAction(
  '[BillingPaymentMethods] ADD_PAYMENT_METHOD_FAIL',
  (payload: string) => ({ payload, parentType: addPaymentMethod.type })
);

export const addPaymentMethodSuccess = createAction(
  '[BillingPaymentMethods] ADD_PAYMENT_METHOD_SUCCESS',
  (payload: { paymentMethod: PaymentMethod }) => ({ payload, parentType: addPaymentMethod.type })
);

export const changeDefaultPaymentMethod = createAction(
  '[BillingPaymentMethods] CHANGE_DEFAULT_PAYMENT_METHOD',
  (payload: { paymentMethodId: string }) => ({ payload })
);

export const changeDefaultPaymentMethodFail = createAction(
  '[BillingPaymentMethods] CHANGE_DEFAULT_PAYMENT_METHOD_FAIL',
  (payload: string) => ({ payload, parentType: changeDefaultPaymentMethod.type })
);

export const changeDefaultPaymentMethodSuccess = createAction(
  '[BillingPaymentMethods] CHANGE_DEFAULT_PAYMENT_METHOD_SUCCESS',
  (payload: { paymentMethodId: string }) => ({
    payload,
    parentType: changeDefaultPaymentMethod.type
  })
);

export const removePaymentMethod = createAction(
  '[BillingPaymentMethods] REMOVE_PAYMENT_METHOD',
  (payload: { paymentMethodId: string }) => ({ payload })
);

export const removePaymentMethodFail = createAction(
  '[BillingPaymentMethods] REMOVE_PAYMENT_METHOD_FAIL',
  (payload: string) => ({ payload, parentType: removePaymentMethod.type })
);

export const removePaymentMethodSuccess = createAction(
  '[BillingPaymentMethods] REMOVE_PAYMENT_METHOD_SUCCESS',
  (payload: { paymentMethodId: string }) => ({ payload, parentType: removePaymentMethod.type })
);

export const loadPaymentMethods = createAction('[BillingPaymentMethods] LOAD_PAYMENT_METHODS');

export const loadPaymentMethodsFail = createAction(
  '[BillingPaymentMethods] LOAD_PAYMENT_METHODS_FAIL',
  (payload: string) => ({ payload, parentType: loadPaymentMethods.type })
);

export const loadPaymentMethodsSuccess = createAction(
  '[BillingPaymentMethods] LOAD_PAYMENT_METHODS_SUCCESS',
  (payload: { paymentMethods: PaymentMethod[] }) => ({
    payload,
    parentType: loadPaymentMethods.type
  })
);

export const updatePaymentMethod = createAction(
  '[BillingPaymentMethods] UPDATE_PAYMENT_METHOD',
  (payload: { paymentMethod: PaymentMethodPayload & ID }) => ({ payload })
);

export const updatePaymentMethodFail = createAction(
  '[BillingPaymentMethods] UPDATE_PAYMENT_METHOD_FAIL',
  (payload: string) => ({ payload, parentType: updatePaymentMethod.type })
);

export const updatePaymentMethodSuccess = createAction(
  '[BillingPaymentMethods] UPDATE_PAYMENT_METHOD_SUCCESS',
  (payload: { paymentMethod: PaymentMethod }) => ({ payload, parentType: updatePaymentMethod.type })
);

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'share-text-filter',
  templateUrl: './text-filter.component.html',
  styleUrls: ['./text-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextFilterComponent implements OnInit {
  @Input()
  public placeholder = 'Find an item';

  @Input()
  public updateOn: 'change' | 'blur' | 'submit' | null;

  @Input()
  public updateOnEnter = false;

  @Input()
  set value(val: string) {
    this.textFilter.setValue(val);
  }

  public textFilter: FormControl<string>;
  public valueChanges: Observable<string>;

  public ngOnInit(): void {
    this.textFilter = new FormControl('', {
      ...(this.updateOn ? { updateOn: this.updateOn } : {})
    });

    this.valueChanges = this.textFilter.valueChanges.pipe(distinctUntilChanged());
  }
}

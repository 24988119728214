import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable, map } from 'rxjs';
import { trackByIdentity } from '@neuralegion/core';

@Component({
  selector: 'share-code-virtual-scroll',
  templateUrl: './code-virtual-scroll.component.html',
  styleUrls: ['./code-virtual-scroll.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CodeVirtualScrollComponent implements OnInit {
  @Input()
  public code$: Observable<string>;

  public readonly trackByIdentity = trackByIdentity;

  public codeLines$: Observable<string[]>;

  public ngOnInit(): void {
    this.codeLines$ = this.code$.pipe(map((code) => (code ? code.split('\n') : [])));
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, exhaustMap, map, mergeMap, of, tap, withLatestFrom } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Group, Scope } from '@neuralegion/api';
import { selectScopePermission } from '@neuralegion/auth-api';
import { SnackbarService } from '@neuralegion/core';
import { GroupsService } from '../services';
import {
  addGroup,
  addGroupFail,
  addGroupMember,
  addGroupMemberFail,
  addGroupMemberSuccess,
  addGroupSuccess,
  loadGroup,
  loadGroupFail,
  loadGroupSuccess,
  loadGroups,
  loadGroupsFail,
  loadGroupsSuccess,
  removeGroup,
  removeGroupFail,
  removeGroupMember,
  removeGroupMemberFail,
  removeGroupMemberSuccess,
  removeGroupSuccess,
  updateGroup,
  updateGroupFail,
  updateGroupSuccess
} from './groups.actions';

@Injectable()
export class GroupsEffects {
  public readonly loadGroup$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadGroup),
      exhaustMap((action: ReturnType<typeof loadGroup>) =>
        this.groupsService.loadGroup(action.payload.groupId).pipe(
          map((group: Group) => loadGroupSuccess({ group })),
          catchError((err: HttpErrorResponse) => of(loadGroupFail(err.error)))
        )
      )
    )
  );

  public readonly loadGroupFail$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadGroupFail),
        tap(() => this.router.navigate(['/']))
      ),
    { dispatch: false }
  );

  public readonly addGroup$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(addGroup),
      exhaustMap((action: ReturnType<typeof addGroup>) =>
        this.groupsService.addGroup(action.payload.group).pipe(
          map((group: Group) => addGroupSuccess({ group })),
          catchError((err: HttpErrorResponse) => of(addGroupFail(err.error)))
        )
      )
    )
  );

  public readonly updateGroup$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(updateGroup),
      exhaustMap((action: ReturnType<typeof updateGroup>) =>
        this.groupsService.updateGroup(action.payload.group).pipe(
          map((group: Group) => updateGroupSuccess({ group })),
          catchError((err: HttpErrorResponse) => of(updateGroupFail(err.error)))
        )
      )
    )
  );

  public readonly removeGroup$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(removeGroup),
      exhaustMap((action: ReturnType<typeof removeGroup>) =>
        this.groupsService.removeGroup(action.payload.groupId).pipe(
          map(() => removeGroupSuccess({ groupId: action.payload.groupId })),
          catchError((err: HttpErrorResponse) => of(removeGroupFail(err.error)))
        )
      )
    )
  );

  public readonly loadGroups$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadGroups),
      withLatestFrom(this.store.select(selectScopePermission(Scope.GROUPS_READ))),
      exhaustMap(([, groupsReadPermission]: [ReturnType<typeof loadGroups>, boolean]) =>
        groupsReadPermission
          ? this.groupsService.loadGroups().pipe(
              map((groups: Group[]) => loadGroupsSuccess({ groups })),
              catchError((err: HttpErrorResponse) => of(loadGroupsFail(err.error)))
            )
          : of(loadGroupsSuccess({ groups: [] }))
      )
    )
  );

  public readonly addGroupMember$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(addGroupMember),
      mergeMap((action: ReturnType<typeof addGroupMember>) =>
        this.groupsService.addGroupMember(action.payload.groupId, action.payload.memberId).pipe(
          map(() => addGroupMemberSuccess(action.payload)),
          catchError((err: HttpErrorResponse) => of(addGroupMemberFail(err.error)))
        )
      )
    )
  );

  public readonly removeGroupMember$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(removeGroupMember),
      mergeMap((action: ReturnType<typeof removeGroupMember>) =>
        this.groupsService.removeGroupMember(action.payload.groupId, action.payload.memberId).pipe(
          map(() => removeGroupMemberSuccess(action.payload)),
          catchError((err: HttpErrorResponse) => of(removeGroupMemberFail(err.error)))
        )
      )
    )
  );

  public readonly navigateToOrganization$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addGroupSuccess, removeGroupSuccess),
        tap(() => this.router.navigate(['organization']))
      ),
    { dispatch: false }
  );

  public readonly showMembershipChangeSnackbar$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addGroupMemberSuccess, removeGroupMemberSuccess),
        tap(() => this.snackbarService.open('Member group changed'))
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly store: Store,
    private readonly groupsService: GroupsService,
    private readonly snackbarService: SnackbarService
  ) {}
}

import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { Injectable, InjectionToken, OnDestroy } from '@angular/core';
import { Observable, Subject, map, shareReplay, takeUntil } from 'rxjs';
import { ID } from '@neuralegion/api';

export interface TableSelectionOptions<TEntity extends ID, TStored> {
  readonly entityMapperFn?: (entity: TEntity) => TStored;
  readonly entityFilterFn?: (entity: TEntity) => boolean;
  readonly limit?: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TABLE_SELECTION_OPTIONS = new InjectionToken<TableSelectionOptions<any, any>>(
  'TABLE_SELECTION_OPTIONS'
);

@Injectable()
export abstract class TableSelectionService<TEntity extends ID, TStored> implements OnDestroy {
  get selected(): TStored[] {
    return this.selection.selected;
  }

  public readonly selectionActive$: Observable<boolean>;
  public readonly selectionChange$: Observable<SelectionChange<TStored>>;
  public readonly hardLimit: number | null;
  public limit: number | null;

  protected readonly selection: SelectionModel<TStored>;

  private readonly entityMapperFn: (entity: TEntity) => TStored;
  private readonly entityFilterFn: (entity: TEntity) => boolean;
  private readonly gc = new Subject<void>();

  constructor(
    private readonly options: TableSelectionOptions<TEntity, TStored>,
    compareWith?: ((o1: TStored, o2: TStored) => boolean) | undefined
  ) {
    this.selection = new SelectionModel<TStored>(true, [], true, compareWith);
    this.selectionChange$ = this.selection.changed.pipe(takeUntil(this.gc), shareReplay(1));
    this.selectionActive$ = this.selectionChange$.pipe(map(() => this.selection.hasValue()));

    this.entityMapperFn =
      this.options.entityMapperFn ?? ((entity: TEntity) => entity as unknown as TStored);
    this.entityFilterFn = this.options.entityFilterFn ?? ((_entity: TEntity) => true);
    this.hardLimit = this.options.limit ?? null;
    this.limit = this.hardLimit;
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }

  public setLimit(limit: number): void {
    this.limit = Math.min(limit, this.hardLimit);
  }

  public selectItems(...items: TEntity[]): boolean | void {
    return this.addToSelection(...items.map(this.entityMapperFn));
  }

  public isSelected(item: TEntity): boolean {
    return this.selection.isSelected(this.entityMapperFn(item));
  }

  public toggle(item: TEntity): boolean | void {
    return this.selection.toggle(this.entityMapperFn(item));
  }

  public clear(): void {
    this.selection.clear();
  }

  public hasValue(): boolean {
    return this.selection.hasValue();
  }

  public canAddToSelection(): boolean {
    return this.limit ? this.selection.selected.length < this.limit : true;
  }

  public canBeSelected(item: TEntity): boolean {
    return this.entityFilterFn(item);
  }

  public addToSelection(...items: TStored[]): boolean | void {
    const itemsToAdd: TStored[] = items.filter((item: TStored) => !this.selection.isSelected(item));
    const itemsToAddCount = this.limit - this.selection.selected.length;

    if (itemsToAddCount <= 0) {
      return;
    }

    this.selection.select(...itemsToAdd.slice(0, itemsToAddCount));
  }

  public removeFromSelection(...items: TStored[]): boolean | void {
    this.selection.deselect(...items);
  }

  public mapEntityToStored(item: TEntity): TStored {
    return this.entityMapperFn(item);
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Severity } from '@neuralegion/api';

@Component({
  selector: 'share-issues-quantity',
  styleUrls: ['./issues-quantity.component.scss'],
  templateUrl: './issues-quantity.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IssuesQuantityComponent {
  @Input()
  public severity: Severity;

  @Input()
  public description?: string;

  @Input()
  public quantity?: number;
}

import { Injectable } from '@angular/core';
import { SimplePluralPipe } from '../../pipes/simple-plural.pipe';
import { CodeEscaperService } from './code-escaper.service';
import { CodeHexLinesService, HexFormatPart, HexFormatResult } from './code-hex-lines.service';
import { DiffResultItem } from './diff.service';

export enum DiffBlockType {
  REMOVED = -1,
  UNCHANGED = 0,
  ADDED = 1,
  CHANGED = 2
}

export interface DiffBlock {
  offset: number;
  length: number;
  type: DiffBlockType;
  value: string;
  index?: number;
}

interface HexDiffFormatOptions {
  charMapper(ch: string): string;
  skipEscape: boolean;
  onLineEnd?(parts: string[]): string[];
  onDiffBlock?(diffBlock: DiffBlock): void;
}

@Injectable({
  providedIn: 'root'
})
export class CodeHexDiffService {
  private readonly hexLineLength = 16;
  private readonly pluralPipe = new SimplePluralPipe();

  constructor(
    private readonly codeEscaperService: CodeEscaperService,
    private readonly codeHexLinesService: CodeHexLinesService
  ) {}

  private convertDiffResultToDiffBlocks(diffResult: DiffResultItem[]): DiffBlock[] {
    return diffResult.reduce((res: DiffBlock[], [type, value]: DiffResultItem): DiffBlock[] => {
      const prev: DiffBlock =
        res.length > 0 ? res[res.length - 1] : { offset: 0, length: 0, type: 0, value: null };
      res.push({
        type,
        value,
        offset: prev.offset + (prev.type === DiffBlockType.REMOVED ? 0 : prev.length),
        length: value.length
      });
      return res;
    }, []);
  }

  private mergeChangedBlocks(diffBlocks: DiffBlock[]): DiffBlock[] {
    // merging removed and added at same offset into changed
    return diffBlocks.reduce((res: DiffBlock[], diffBlock: DiffBlock): DiffBlock[] => {
      const prev = res.length > 0 ? res[res.length - 1] : null;
      if (prev && prev.offset === diffBlock.offset) {
        const [added, removed] =
          prev.type === DiffBlockType.ADDED
            ? [{ ...prev }, { ...diffBlock }]
            : [{ ...diffBlock }, { ...prev }];

        prev.type = DiffBlockType.CHANGED;
        prev.length = Math.min(removed.length, added.length);

        if (added.value.length > removed.value.length) {
          prev.value = removed.value;
          res.push({
            type: DiffBlockType.ADDED,
            offset: removed.offset + removed.value.length,
            length: added.value.length - removed.value.length,
            value: added.value.substring(removed.value.length)
          });
        } else if (added.value.length < removed.value.length) {
          prev.value = removed.value.substring(0, prev.length);
          res.push({
            type: DiffBlockType.REMOVED,
            offset: removed.offset + added.value.length,
            length: removed.value.length - added.value.length,
            value: removed.value.substring(added.value.length)
          });
        }
      } else {
        res.push(diffBlock);
      }
      return res;
    }, []);
  }

  public prepareDiffBlocks(diffResult: DiffResultItem[]): DiffBlock[] {
    return this.mergeChangedBlocks(
      this.convertDiffResultToDiffBlocks(diffResult).filter(
        (diffBlock: DiffBlock): boolean => diffBlock.type !== DiffBlockType.UNCHANGED
      )
    ).map((diffBlock: DiffBlock, index: number) => ({
      ...diffBlock,
      index
    }));
  }

  private formatBytesLabel(diffBlock: DiffBlock): string {
    const opName =
      diffBlock.type === DiffBlockType.ADDED
        ? 'added'
        : diffBlock.type === DiffBlockType.CHANGED
        ? 'changed'
        : 'removed';
    return `${this.pluralPipe.transform(diffBlock.length, 'byte')} ${opName}`;
  }

  private formatDiffBlockLabel(diffBlock: DiffBlock): string {
    return `Block ${diffBlock.index + 1} (${this.formatBytesLabel(diffBlock)})`;
  }

  private formatHexDiffLine(
    line: string,
    startOffset: number,
    diffBlocks: DiffBlock[],
    options: HexDiffFormatOptions
  ): string {
    let diffBlock: DiffBlock = diffBlocks.find((db: DiffBlock) =>
      db.type !== DiffBlockType.REMOVED
        ? db.offset < startOffset + this.hexLineLength && db.offset + db.length > startOffset
        : db.offset > startOffset && db.offset <= startOffset + this.hexLineLength
    );

    const getNextBlock = (db: DiffBlock): DiffBlock | null => {
      const idx = diffBlocks.indexOf(db);
      if (idx === -1 || idx === diffBlocks.length - 1) {
        return null;
      }

      const nextDb = diffBlocks[idx + 1];
      return (nextDb.type !== DiffBlockType.REMOVED &&
        nextDb.offset < startOffset + this.hexLineLength) ||
        (nextDb.type === DiffBlockType.REMOVED && nextDb.offset <= startOffset + this.hexLineLength)
        ? nextDb
        : null;
    };

    const isNotFinishedBlock = (db: DiffBlock): boolean =>
      db &&
      db.type !== DiffBlockType.REMOVED &&
      db.offset < startOffset + this.hexLineLength &&
      db.offset + db.length > startOffset + this.hexLineLength;

    // append aux char for handling tail blocks
    return [...line, '\u0000']
      .map((ch: string, idx: number): string => {
        const mappedCh = options.charMapper(ch);
        let mappingParts: string[] =
          idx === line.length
            ? []
            : [options.skipEscape ? mappedCh : this.codeEscaperService.escapeHtml(mappedCh)];

        const alreadyStartedBlock = idx === 0 && diffBlock && diffBlock.offset < startOffset;
        if ((diffBlock && diffBlock.offset === startOffset + idx) || alreadyStartedBlock) {
          if (options.onDiffBlock && !alreadyStartedBlock) {
            options.onDiffBlock(diffBlock);
          }

          switch (diffBlock.type) {
            case DiffBlockType.ADDED:
              mappingParts = [
                `<span class="diff-added" title="${this.formatDiffBlockLabel(diffBlock)}">`,
                ...mappingParts
              ];
              break;
            case DiffBlockType.CHANGED:
              mappingParts = [
                `<span class="diff-changed" title="${this.formatDiffBlockLabel(diffBlock)}">`,
                ...mappingParts
              ];
              break;
            case DiffBlockType.REMOVED:
              mappingParts = [
                `<span class="removed-anchor" title="${this.formatDiffBlockLabel(
                  diffBlock
                )}"></span>`,
                ...mappingParts
              ];
              if (!alreadyStartedBlock) {
                diffBlock = getNextBlock(diffBlock);
              }
              break;
            case DiffBlockType.UNCHANGED:
              break;
          }
        }

        if (
          diffBlock &&
          diffBlock.type !== DiffBlockType.REMOVED &&
          startOffset + idx + 1 === diffBlock.offset + diffBlock.length
        ) {
          mappingParts = [...mappingParts, '</span>'];
          diffBlock = getNextBlock(diffBlock);
        }

        if (idx === line.length) {
          if (isNotFinishedBlock(diffBlock)) {
            mappingParts = [...mappingParts, '</span>'];
          }

          if (options.onLineEnd) {
            mappingParts = options.onLineEnd(mappingParts);
          }
        }

        return mappingParts.join('');
      })
      .join('')
      .replace(/ (<\/span>)([^<])/g, '$1 $2');
  }

  private formatHexContentDiff(
    line: string,
    offset: number,
    diffBlocks: DiffBlock[]
  ): {
    content: string;
    extraBlocks: DiffBlock[];
  } {
    const extraBlocks: DiffBlock[] = [];
    const content = this.formatHexDiffLine(line, offset, diffBlocks, {
      charMapper: (ch: string) => (this.codeHexLinesService.isPrintable(ch) ? ch : '.'),
      skipEscape: false,
      onDiffBlock: (diffBlock: DiffBlock) => {
        if (diffBlock.type === DiffBlockType.REMOVED || diffBlock.type === DiffBlockType.CHANGED) {
          extraBlocks.push(diffBlock);
        }
      },
      onLineEnd: (parts: string[]): string[] =>
        line.length < this.hexLineLength
          ? [...parts, ''.padEnd(this.hexLineLength - line.length, ' ')]
          : parts
    });

    return {
      content,
      extraBlocks
    };
  }

  private formatHexCodesDiff(line: string, offset: number, diffBlocks: DiffBlock[]): string {
    return this.formatHexDiffLine(line, offset, diffBlocks, {
      charMapper: (ch: string) => `${this.codeHexLinesService.formatByte(ch)} `,
      skipEscape: true,
      onLineEnd: (parts: string[]) =>
        line.length < this.hexLineLength
          ? [...parts, ''.padEnd((this.hexLineLength - line.length) * 3, ' ')]
          : parts
    });
  }

  public formatHexDiff(code: string, diffBlocks: DiffBlock[]): HexFormatResult[] {
    const codeChunks: string[] = [];
    let offset = 0;
    while (code.length > offset) {
      codeChunks.push(code.substring(offset, offset + this.hexLineLength));
      offset += this.hexLineLength;
    }

    return codeChunks.map((chunk: string, idx: number): HexFormatResult => {
      const contentRes = this.formatHexContentDiff(chunk, idx * this.hexLineLength, diffBlocks);
      return {
        [HexFormatPart.LABEL]: this.codeHexLinesService.formatLabel(idx * this.hexLineLength),
        [HexFormatPart.CODES]: this.formatHexCodesDiff(chunk, idx * this.hexLineLength, diffBlocks),
        [HexFormatPart.CONTENT]: contentRes.content,
        extraBlocks: contentRes.extraBlocks
      };
    });
  }
}

import { BaseElementIdStrategy } from './base-element-id-strategy';

export class MatHintErrorElementIdStrategy extends BaseElementIdStrategy {
  protected override getSubId(element: HTMLElement): string {
    return [
      element.tagName === 'MAT-HINT' ? 'hint' : 'error',
      element.parentElement?.closest('[data-id]')?.tagName === 'MAT-FORM-FIELD'
        ? ''
        : this.UNKNOWN_ID
    ]
      .filter(Boolean)
      .join(this.SEPARATOR_INTERNAL);
  }
}

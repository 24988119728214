export const getElementText = (
  element: HTMLElement | null,
  skipNodeFn?: (node: Node) => boolean
): string => {
  if (!element) {
    return '';
  }
  const grabTextNodes = (node: Node): Node[] => {
    if (skipNodeFn?.(node)) {
      return [];
    }
    return node.nodeType === Node.TEXT_NODE
      ? [node]
      : [...node.childNodes].flatMap((childNode) => grabTextNodes(childNode));
  };
  const textNodes = grabTextNodes(element);

  return textNodes
    .map((node: Node) => node.textContent)
    .filter(Boolean)
    .join('')
    .trim();
};

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'share-health-indicator',
  templateUrl: './health-indicator.component.html',
  styleUrls: ['./health-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HealthIndicatorComponent {
  @Input()
  public health?: number | null = null;

  public readonly HEALTH_POOR_THRESHOLD = 50;
  public readonly HEALTH_GOOD_THRESHOLD = 89;
}

import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable, first, map } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectTempToken } from '../store';

@Injectable()
export class MfaNegateGuard {
  constructor(
    private readonly store: Store,
    private readonly router: Router
  ) {}

  public canActivate(): Observable<boolean | UrlTree> {
    return this.store.select(selectTempToken).pipe(
      first(),
      map((token: string) => (token ? true : this.router.parseUrl('/login')))
    );
  }
}

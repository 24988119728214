export enum QuotaType {
  ENGINES = 'engines',
  FILE_STORAGE = 'file_storage',
  PROJECTS = 'projects',
  SCAN_HOURS = 'scan_hours',
  TICKETING_INTEGRATIONS = 'ticketing_integrations',
  USERS = 'users'
}

export const quotaTypes: readonly QuotaType[] = [
  QuotaType.ENGINES,
  QuotaType.FILE_STORAGE,
  QuotaType.PROJECTS,
  QuotaType.SCAN_HOURS,
  QuotaType.TICKETING_INTEGRATIONS,
  QuotaType.USERS
];

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GitHubOrg } from '../models';

@Injectable()
export class GithubClientService {
  constructor(private readonly http: HttpClient) {}

  public loadOrgs(): Observable<GitHubOrg[]> {
    return this.http.get<GitHubOrg[]>('/api/v1/auth-providers/github/orgs');
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MqAlias } from '../models';
import { CoreState } from './core.state';
import { LayoutState } from './layout.state';
import { SidebarState } from './sidebar.state';

// eslint-disable-next-line @typescript-eslint/ban-types
type State = object;
export const selectCoreState = createFeatureSelector<CoreState>('core');

export const selectLayoutState = createSelector<State, [CoreState], LayoutState>(
  selectCoreState,
  (state: CoreState) => state.layout
);

export const selectSidebarState = createSelector<State, [CoreState], SidebarState>(
  selectCoreState,
  (state: CoreState) => state.sidebar
);

export const selectPendingStatus = createSelector<State, [LayoutState], boolean>(
  selectLayoutState,
  (state: LayoutState) => state.pending
);

export const selectIsSmallScreen = createSelector<State, [LayoutState], boolean>(
  selectLayoutState,
  (state: LayoutState) => state.mqAliases.includes(MqAlias.LT_MD)
);

export const selectSidebarOpenedStatus = createSelector<State, [SidebarState, boolean], boolean>(
  selectSidebarState,
  selectIsSmallScreen,
  (state: SidebarState, smallScreen: boolean) => (smallScreen ? state.opened : true)
);

export const selectSidebarExpandedStatus = createSelector<State, [SidebarState, boolean], boolean>(
  selectSidebarState,
  selectIsSmallScreen,
  (state: SidebarState, smallScreen: boolean) => (!smallScreen ? state.expanded : true)
);

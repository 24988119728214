import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';

@Injectable()
export class MarketplaceCallbackStateGuard {
  constructor(private readonly router: Router) {}

  public canActivate(route?: ActivatedRouteSnapshot): boolean | UrlTree {
    const { marketplaceCallbackState } = route?.queryParams ?? {};

    if (marketplaceCallbackState) {
      return true;
    }

    return this.router.parseUrl('/');
  }
}

import { BaseElementIdStrategy } from './base-element-id-strategy';

export class MatDialogElementIdStrategy extends BaseElementIdStrategy {
  protected elementPrefix = 'dialog';

  public override applyId(element: HTMLElement, dataId: string): void {
    super.applyId(this.getDialogContainerElement(element), dataId);
  }

  protected override getSubId(element: HTMLElement): string {
    return [this.elementPrefix, this.generateIdByText(this.getDialogTitle(element))].join(
      this.SEPARATOR_INTERNAL
    );
  }

  private getDialogTitle(element: HTMLElement): string {
    return (
      this.getElementText(
        this.getDialogContainerElement(element).querySelector<HTMLElement>('[mat-dialog-title]'),
        (node: Node) => ['BUTTON', 'MAT-ICON'].includes((node as HTMLElement).tagName)
      ) || 'confirmation'
    );
  }

  private getDialogContainerElement(element: HTMLElement): HTMLElement {
    return element.closest<HTMLElement>('mat-dialog-container');
  }
}

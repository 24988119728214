import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'findIndex',
  pure: true
})
export class FindIndexPipe<T> implements PipeTransform {
  public transform(value: string, items: T[], fieldName: keyof T = 'id' as keyof T): number {
    return items?.findIndex((item: T) => item[fieldName] === value) ?? -1;
  }
}

import { Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';
import { BaseElementIdStrategy } from './base-element-id-strategy';

export class MatSlideToggleElementIdStrategy extends BaseElementIdStrategy {
  private readonly elementPrefix = ['slide', 'toggle'].join(this.SEPARATOR_INTERNAL);

  constructor(
    renderer: Renderer2,
    private readonly ngControl: NgControl
  ) {
    super(renderer);
  }

  public override applyId(element: HTMLElement, dataId: string): void {
    super.applyId(element, dataId);
    super.applyId(
      element.querySelector('button'),
      [dataId, 'button'].join(this.SEPARATOR_INTERNAL)
    );
  }

  protected override getSubId(element: HTMLElement): string {
    return [
      this.elementPrefix,
      this.generateIdByText(
        this.ngControl?.name?.toString() || this.getElementText(element) || this.UNKNOWN_ID
      )
    ].join(this.SEPARATOR_INTERNAL);
  }
}

import { Inject, Injectable, Optional } from '@angular/core';
import { ID } from '@neuralegion/api';
import {
  TABLE_SELECTION_OPTIONS,
  TableSelectionOptions,
  TableSelectionService
} from './table-selection.service';

@Injectable()
export class IdTableSelectionService<T extends ID> extends TableSelectionService<T, string> {
  constructor(
    @Inject(TABLE_SELECTION_OPTIONS)
    @Optional()
    tableSelectionOptions: TableSelectionOptions<T, string>
  ) {
    super({
      limit: tableSelectionOptions?.limit,
      entityMapperFn: (entity: T) => entity.id,
      entityFilterFn: tableSelectionOptions?.entityFilterFn
    });
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { PageRefreshOptions, PageRefreshService } from './page-refresh.service';

@Injectable()
export class PageRefreshLocalService implements OnDestroy {
  public readonly refresh$: Observable<void>;

  constructor(private readonly pageRefreshService: PageRefreshService) {
    this.refresh$ = this.pageRefreshService.refresh$;
  }

  public attach(options?: PageRefreshOptions): void {
    this.pageRefreshService.attach(options);
  }

  public ngOnDestroy(): void {
    this.pageRefreshService.detach();
  }
}

import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  ValidationErrors,
  Validator
} from '@angular/forms';
import {
  Subject,
  delay,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  take,
  takeUntil
} from 'rxjs';
import equal from 'fast-deep-equal/es6';
import {
  extractTouchedChanges,
  updateNestedTreeTouchedState,
  updateTreeValidity
} from '../../form';
import { ListControlErrors } from '../../models';
import { ListControlAddEvent, ListControlComponent } from '../list-control/list-control.component';

@Directive()
export abstract class ListItemControl<EV, FV = EV>
  implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy, Validator
{
  @Input()
  set idx(value: number) {
    if (typeof this.index !== 'undefined' && this.index !== value) {
      this.onValidatorChange?.();
    }
    this.index = value;
  }

  get idx(): number {
    return this.index;
  }

  private index: number;

  @Input()
  public formControl: FormControl;

  @Input()
  public listControlComponent: ListControlComponent;

  @ViewChildren(CdkTextareaAutosize)
  public autosizeTextareas: QueryList<CdkTextareaAutosize>;

  public onTouched: () => void;
  public onChange: (value: EV) => void;
  public onValidatorChange: () => void;

  protected abstract innerControl: AbstractControl;

  protected readonly gc = new Subject<void>();

  private intersectionObserver: IntersectionObserver;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly elementRef: ElementRef<HTMLElement>
  ) {}

  public ngOnInit(): void {
    this.initTouchedStateListeners();
    this.initValidationStateListeners();

    this.initFocusOnControlAddedListener();

    this.innerControl.statusChanges
      .pipe(distinctUntilChanged(), takeUntil(this.gc))
      .subscribe(() => this.onValidatorChange?.());

    this.innerControl.valueChanges
      .pipe(distinctUntilChanged(equal), takeUntil(this.gc))
      .subscribe(() => this.onChange?.(this.mapFromFormValue(this.innerControl.getRawValue())));
  }

  public ngAfterViewInit(): void {
    // proper textarea autosize for usage in dynamic mat-accordion panels
    if (this.autosizeTextareas) {
      this.intersectionObserver = new IntersectionObserver(
        (entries, observer) => {
          if (entries[0].isIntersecting) {
            observer.disconnect();
            this.autosizeTextareas.forEach((item) => item.resizeToFitContent(true));
          }
        },
        {
          root: this.elementRef.nativeElement.getRootNode() as Element
        }
      );
      this.intersectionObserver.observe(this.elementRef.nativeElement);
    }
  }

  public ngOnDestroy(): void {
    this.intersectionObserver?.disconnect();
    this.gc.next();
    this.gc.unsubscribe();
  }

  public registerOnChange(fn: (value: EV) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  public registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  public writeValue(value: EV): void {
    const mappedValue = this.mapToFormValue(value);
    if (equal(mappedValue, this.innerControl.value)) {
      return;
    }
    this.innerControl.setValue(mappedValue, { emitEvent: false });
  }

  public setDisabledState(disabled: boolean): void {
    if (disabled && !this.innerControl.disabled) {
      this.innerControl.disable();
    } else if (!this.innerControl.enabled) {
      this.innerControl.enable();
    }
  }

  public validate(): ValidationErrors | null {
    updateTreeValidity(this.innerControl);
    return this.innerControl.invalid
      ? this.innerControl.errors || {
          [ListControlErrors.LIST_CONTROL_GENERIC_ERROR]: true
        }
      : null;
  }

  protected filterExternalErrors(errors: ValidationErrors | null): ValidationErrors | null {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      [ListControlErrors.LIST_LENGTH_EXCEEDED]: externalErr1,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      [ListControlErrors.DUPLICATED_LIST_ITEM]: externalErr2,
      ...rest
    } = errors || {};

    return Object.keys(rest).length === 0 ? null : rest;
  }

  protected mapToFormValue(value: EV): FV {
    return value as unknown as FV;
  }

  protected mapFromFormValue(value: FV): EV {
    return value as unknown as EV;
  }

  protected initTouchedStateListeners(): void {
    extractTouchedChanges(this.formControl)
      .pipe(
        startWith(this.formControl.touched),
        distinctUntilChanged(),
        filter((touched) => touched !== this.innerControl.touched),
        takeUntil(this.gc)
      )
      .subscribe((touched: boolean) => {
        updateNestedTreeTouchedState(this.innerControl, touched);
        this.cdr.markForCheck();
      });

    extractTouchedChanges(this.innerControl)
      .pipe(
        distinctUntilChanged(),
        filter((touched: boolean) => touched),
        takeUntil(this.gc)
      )
      .subscribe(() => this.onTouched?.());
  }

  protected initValidationStateListeners(): void {
    this.formControl.statusChanges
      .pipe(
        startWith(this.formControl.status),
        map(() =>
          Object.keys(this.formControl.errors || {})
            .filter((key) =>
              [
                ListControlErrors.LIST_LENGTH_EXCEEDED,
                ListControlErrors.DUPLICATED_LIST_ITEM
              ].includes(key as ListControlErrors)
            )
            .reduce(
              (acc: ValidationErrors, key: string) => ({
                ...acc,
                [key]: this.formControl.errors[key]
              }),
              {} as ValidationErrors
            )
        ),
        distinctUntilChanged(equal),
        // to avoid manual setErrors before native statusChanges event
        delay(0),
        takeUntil(this.gc)
      )
      .subscribe((parentControlErrors: ValidationErrors | null) => {
        this.setExternalErrors(parentControlErrors);
        this.cdr.markForCheck();
      });
  }

  protected setExternalErrors(parentControlErrors: ValidationErrors | null): void {
    const resultErrors = {
      ...this.filterExternalErrors(this.innerControl.errors || {}),
      ...(parentControlErrors || {})
    };
    this.innerControl.setErrors(Object.keys(resultErrors).length ? resultErrors : null, {
      // parent control already knows about his errors, do not emit
      emitEvent: false
    });
  }

  protected onControlAdded(event: ListControlAddEvent): void {
    this.focusOnElement(event);
  }

  protected focusOnElement(_event: ListControlAddEvent): void {
    // noop in base class
  }

  private initFocusOnControlAddedListener(): void {
    this.listControlComponent.controlAdded$
      .pipe(take(1), takeUntil(this.gc))
      .subscribe((event: ListControlAddEvent) => {
        if (event.idx === this.idx) {
          this.onControlAdded(event);
        }
      });
  }
}

<mat-form-field class="compact-field" fxFlex="1 1 auto">
  <ng-container [ngSwitch]="inputType">
    <input
      *ngSwitchCase="'text'"
      type="text"
      attr.aria-label="Input {{ filterConfig.name }}"
      blurOnEnter
      matInput
      [formControl]="formControl" />
    <input
      *ngSwitchCase="'number'"
      type="number"
      attr.aria-label="Input {{ filterConfig.name }}"
      blurOnEnter
      matInput
      [formControl]="formControl" />
  </ng-container>
</mat-form-field>

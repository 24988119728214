import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, filter, takeUntil } from 'rxjs';
import equal from 'fast-deep-equal/es6';
import { ALLOWED_SCOPES, Scope, ScopeGroup } from '@neuralegion/api';
import { groupScopes } from '@neuralegion/auth-api';
import { trackByIdentity } from '@neuralegion/core';

interface ScopeMeta {
  scope: Scope;
  editable: boolean;
}

@Component({
  selector: 'share-scopes-control',
  templateUrl: './scopes-control.component.html',
  styleUrls: ['./scopes-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    { provide: NG_VALUE_ACCESSOR, useExisting: ScopesControlComponent, multi: true }
  ]
})
export class ScopesControlComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input()
  public allScopes: ReadonlySet<Scope> = ALLOWED_SCOPES;

  @Input()
  public userScopes: ReadonlySet<Scope> | null;

  public readonly trackByIdentity = trackByIdentity;

  public readonly control = new FormControl([]);
  public scopesMetaGroups: ScopeMeta[][];

  private onChange: (value: Scope[]) => void;

  private readonly gc = new Subject<void>();

  public ngOnInit(): void {
    this.scopesMetaGroups = groupScopes(this.allScopes).map((scopeGroup: ScopeGroup): ScopeMeta[] =>
      [
        ...(!scopeGroup.artificialParent ? [scopeGroup.parentScope] : []),
        ...scopeGroup.subScopes
      ].map(
        (scope: Scope): ScopeMeta => ({
          scope,
          editable: !!this.userScopes?.has(scope)
        })
      )
    );

    this.control.valueChanges
      .pipe(
        filter(() => !!this.onChange),
        takeUntil(this.gc)
      )
      .subscribe((value: Scope[]) => {
        this.onChange(value);
      });
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }

  public registerOnChange(onChange: (value: Scope[]) => void): void {
    this.onChange = onChange;
  }

  public registerOnTouched(): void {
    // do nothing
  }

  public setDisabledState(disabled: boolean): void {
    if (disabled && !this.control.disabled) {
      this.control.disable();
    } else if (!disabled && !this.control.enabled) {
      this.control.enable();
    }
  }

  public writeValue(scopes: Scope[]): void {
    if (!equal(new Set<Scope>(this.control.value), new Set<Scope>(scopes))) {
      this.control.setValue(scopes);
    }
  }
}

import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ProviderType, ServiceName } from '@neuralegion/api';

@Injectable({
  providedIn: 'root'
})
export class IconsService {
  private readonly svgIcons = [
    ...Object.values(ServiceName),
    ...Object.values(ProviderType),
    'azure_pipeline',
    'circleci',
    'github_actions',
    'google_colored',
    'jenkins',
    'jfrog',
    'log',
    'log_file',
    'resize',
    'travisci'
  ];

  private readonly otpTypeIcons = ['hotp', 'totp', 'email', 'authenticator'];

  constructor(
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer
  ) {
    this.registerIcons();
  }

  private registerIcons(): void {
    this.svgIcons.forEach((name: string) =>
      this.matIconRegistry.addSvgIcon(
        name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${name}.svg`)
      )
    );

    this.otpTypeIcons.forEach((name: string) =>
      this.matIconRegistry.addSvgIcon(
        name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/otp/${name}.svg`)
      )
    );
  }
}

import { Renderer2 } from '@angular/core';
import { BaseElementIdStrategy } from './base-element-id-strategy';

export class MarkdownElementIdStrategy extends BaseElementIdStrategy {
  constructor(renderer: Renderer2) {
    super(renderer);
  }

  public override applyId(element: HTMLElement, dataId: string): void {
    super.applyId(element, dataId);

    element.querySelectorAll('a')?.forEach((el, idx) => {
      super.applyId(el, [dataId, 'link', idx].join(this.SEPARATOR_EXTERNAL));
    });
  }

  protected override getSubId(): string {
    return 'markdown';
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Request, Response } from '@neuralegion/api';
import { ClipboardService, CurlService, isBinary } from '@neuralegion/core';

@Component({
  selector: 'share-payload-copy-as-dropdown',
  templateUrl: './payload-copy-as-dropdown.component.html',
  styleUrls: ['./payload-copy-as-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PayloadCopyAsDropdownComponent {
  @Input()
  set entity(value: Request | Response) {
    // eslint-disable-next-line no-underscore-dangle
    this._entity = value;
    this.entityTypeRequest = this.isRequest(value);
    this.entityLiteral = this.entityTypeRequest ? 'Request' : 'Response';
    this.bodyBinary = isBinary(value.body);
    this.bodyTooLarge = value.body?.length > this.THRESHOLD_BODY_TOO_LARGE;
    this.emptyHeaders = !value.headers || Object.keys(value.headers).length === 0;
  }

  get entity(): Request | Response {
    // eslint-disable-next-line no-underscore-dangle
    return this._entity;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _entity: Request | Response;

  public entityLiteral: string;

  public emptyHeaders = false;

  public bodyBinary = false;

  public bodyTooLarge = false;

  public entityTypeRequest = false;

  private readonly THRESHOLD_BODY_TOO_LARGE = 1024 * 1024;

  constructor(
    private readonly curlService: CurlService,
    private readonly clipboardService: ClipboardService
  ) {}

  public isRequest(value: Request | Response): value is Request {
    return 'url' in value;
  }

  public copyAsCurl(): void {
    this.curlService.copyCommandToClipboard(this.entity as Request);
  }

  public copyURL(): void {
    this.clipboardService.copyToClipboard((this.entity as Request).url, 'Request URL copied');
  }

  public copyHeaders(): void {
    this.clipboardService.copyToClipboard(
      Object.entries(this.entity.headers)
        .map(([key, value]: [string, string]) => `${key}: ${value}`)
        .join('\n'),
      `${this.entityLiteral} headers copied`
    );
  }

  public copyBody(): void {
    this.clipboardService.copyToClipboard(this.entity.body, `${this.entityLiteral} body copied`);
  }

  public copyRaw(): void {
    const rawEntityParts: (string | null)[] = [
      this.getRawTitle(),
      this.getRawHeaders(),
      this.getRawBody()
    ].filter(Boolean);

    this.clipboardService.copyToClipboard(
      rawEntityParts.join('\n\n'),
      `${this.entityLiteral} copied`
    );
  }

  private getRawTitle(): string {
    if (this.isRequest(this.entity)) {
      return `${this.entity.protocol ? `${this.entity.protocol} ` : ''}${this.entity.method} ${
        this.entity.url
      }`;
    }
    return `${this.entity.protocol ? `${this.entity.protocol} ` : ''}${this.entity.status ?? ''}`;
  }

  private getRawHeaders(): string | null {
    if (this.emptyHeaders) {
      return null;
    }
    return Object.entries(this.entity.headers)
      .map(([key, value]: [string, string]) => `${key}: ${value}`)
      .join('\n');
  }

  private getRawBody(): string | null {
    if (!this.entity.body || this.bodyBinary) {
      return null;
    }
    return this.entity.body;
  }
}

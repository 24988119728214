import { Action } from '@ngrx/store';
import { InviteDetails } from '@neuralegion/api';

export interface SignupState {
  readonly inviteDetails: InviteDetails | null;
  readonly error: string | null;
  readonly pending: Action[];
}

export const signupInitialState: SignupState = {
  inviteDetails: null,
  error: null,
  pending: []
};

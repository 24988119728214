import { Action, createReducer, on } from '@ngrx/store';
import {
  activateOnboarding,
  confirmRequired,
  createRequiredOrg,
  createRequiredOrgFail,
  createRequiredOrgSuccess,
  deactivateOnboarding,
  loadUserInfoSuccess,
  login,
  loginFail,
  loginRedirect,
  loginSuccess,
  logout,
  mfaRequired,
  oauthFinalize,
  oauthFinalizeFail,
  oauthFinalizeSuccess,
  passwordChangeRequired,
  ssoInit,
  ssoInitFail,
  ssoInitSuccess,
  ssoRequired
} from './auth.actions';
import { AuthState, initialAuthState } from './auth.state';

export const authReducer = createReducer<AuthState, Action>(
  initialAuthState,
  on(
    createRequiredOrg,
    login,
    oauthFinalize,
    ssoInit,
    (state: AuthState): AuthState => ({
      ...state,
      pending: true
    })
  ),
  on(
    loginRedirect,
    loginSuccess,
    oauthFinalizeSuccess,
    (state: AuthState): AuthState => ({
      ...state,
      token: null,
      pending: false,
      accessFailedCount: 0
    })
  ),
  on(
    ssoInitSuccess,
    (state: AuthState, action): AuthState => ({
      ...state,
      ...action.payload.sso,
      pending: false
    })
  ),
  on(
    activateOnboarding,
    (state: AuthState): AuthState => ({
      ...state,
      userInfo: {
        ...state.userInfo
      },
      onboardingActive: true
    })
  ),
  on(
    deactivateOnboarding,
    (state: AuthState): AuthState => ({
      ...state,
      onboardingActive: false
    })
  ),
  on(
    loadUserInfoSuccess,
    (state: AuthState, action): AuthState => ({
      ...state,
      userInfo: action.payload.userInfo
    })
  ),
  on(
    createRequiredOrgSuccess,
    createRequiredOrgFail,
    oauthFinalizeFail,
    ssoInitFail,
    confirmRequired,
    (state: AuthState): AuthState => ({
      ...state,
      pending: false
    })
  ),
  on(
    mfaRequired,
    ssoRequired,
    passwordChangeRequired,
    (state: AuthState, action): AuthState => ({
      ...state,
      token: action.payload.token,
      pending: false
    })
  ),
  on(
    loginFail,
    (state: AuthState): AuthState => ({
      ...state,
      accessFailedCount: state.accessFailedCount + 1,
      pending: false
    })
  ),
  on(logout, (): AuthState => initialAuthState)
);

import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  EMPTY,
  Observable,
  catchError,
  combineLatest,
  distinctUntilChanged,
  expand,
  filter,
  finalize,
  last,
  map,
  of,
  scan,
  switchMap,
  take,
  takeWhile,
  tap,
  withLatestFrom
} from 'rxjs';
import { ComponentStore } from '@ngrx/component-store';
import equal from 'fast-deep-equal/es6';
import { FilterValueObject, ID, PaginationResponse, TableSortConfig } from '@neuralegion/api';
import { PaginationParamsSerializationService } from '@neuralegion/core';
import { PaginationStore } from '../pagination';
import { TableSelectionService } from './table-selection.service';

export type LoadPageFactory<T> = (
  params: HttpParams,
  response: PaginationResponse<T> | null
) => Observable<PaginationResponse<T>>;

export const seekPaginationNextPageFactory =
  <T extends ID & { createdAt: string }>(
    loaderFn: (params: HttpParams) => Observable<PaginationResponse<T>>
  ) =>
  (
    params: HttpParams,
    response: PaginationResponse<T> | null
  ): Observable<PaginationResponse<T>> => {
    if (!response) {
      return loaderFn(params);
    }

    const nextId: string = response.items[response.items.length - 1].id;
    const nextCreatedAt: string = response.items[response.items.length - 1].createdAt;

    return loaderFn(params.set('nextId', nextId).set('nextCreatedAt', nextCreatedAt));
  };

export interface SelectAllOptions<T> {
  readonly loadPageFactory: LoadPageFactory<T>;
  readonly limit: number;
}

interface SelectAllState<T> {
  lastLoadedItems: T[];
  lastUsedParams: HttpParams | null;
  pending: boolean;
  selected: boolean;
}

@Injectable()
export class SelectAllService<T extends ID, TStored> extends ComponentStore<
  SelectAllState<TStored>
> {
  public readonly pending$: Observable<boolean> = this.select(
    (state: SelectAllState<TStored>) => state.pending
  );

  public readonly selected$: Observable<boolean> = this.select(
    (state: SelectAllState<TStored>) => state.selected
  );

  private readonly MAX_PAGINATION_LIMIT = 100;

  private readonly currentViewFilterAndSort$: Observable<
    [FilterValueObject<unknown>, TableSortConfig]
  > = combineLatest([this.paginationStore.filter$, this.paginationStore.sort$]);

  private loadPageFactory: LoadPageFactory<T>;

  constructor(
    public readonly paginationParamsSerializationService: PaginationParamsSerializationService,
    public readonly paginationStore: PaginationStore<T>,
    private readonly tableSelectionService: TableSelectionService<T, TStored>
  ) {
    super({
      lastLoadedItems: [],
      lastUsedParams: null,
      pending: false,
      selected: false
    });
  }

  public init(options: SelectAllOptions<T>): void {
    this.loadPageFactory = options.loadPageFactory;
    this.tableSelectionService.setLimit(options.limit);

    this.effect(() =>
      this.currentViewFilterAndSort$.pipe(
        distinctUntilChanged(equal),
        tap(() =>
          this.patchState({
            lastLoadedItems: [],
            lastUsedParams: null,
            selected: false
          })
        )
      )
    );

    this.effect(() =>
      this.tableSelectionService.selectionActive$.pipe(
        filter((active) => !active),
        withLatestFrom(this.selected$.pipe(filter(Boolean))),
        tap(() => this.setSelected(false))
      )
    );
  }

  public readonly toggleSelection = this.effect((trigger$) =>
    trigger$.pipe(
      switchMap(() => {
        return this.select((state) => state.selected).pipe(
          take(1),
          tap((selected: boolean) => {
            if (selected) {
              this.deselectAll();
            } else {
              this.selectAll();
            }
          })
        );
      })
    )
  );

  public readonly clear = this.effect((trigger$) =>
    trigger$.pipe(
      tap(() => {
        this.tableSelectionService.clear();
        this.patchState({
          selected: false,
          lastUsedParams: null,
          lastLoadedItems: []
        });
      })
    )
  );

  private readonly setPending = this.updater(
    (state: SelectAllState<TStored>, pending: boolean): SelectAllState<TStored> => ({
      ...state,
      pending
    })
  );

  private readonly setSelected = this.updater(
    (state: SelectAllState<TStored>, selected: boolean): SelectAllState<TStored> => ({
      ...state,
      selected
    })
  );

  private readonly selectAll = this.effect((trigger$) =>
    trigger$.pipe(
      switchMap(() => {
        return this.currentViewFilterAndSort$.pipe(
          take(1),
          withLatestFrom(this.select((state) => state.lastUsedParams)),
          switchMap(
            ([[currentFilter, currentSort], lastUsedParams]: [
              [FilterValueObject<unknown>, TableSortConfig],
              HttpParams
            ]) => {
              const httpParams: HttpParams = this.paginationParamsSerializationService
                .convertToHttpParams({
                  pagination: null,
                  filter: currentFilter,
                  sort: currentSort
                })
                .set('limit', this.MAX_PAGINATION_LIMIT);

              if (equal(httpParams, lastUsedParams)) {
                return this.select((state) => ({
                  ids: state.lastLoadedItems,
                  params: state.lastUsedParams
                })).pipe(take(1));
              }

              return this.loadAllForFilter(
                this.loadPageFactory,
                httpParams,
                (entity: T) => this.tableSelectionService.canBeSelected(entity),
                (entity) => this.tableSelectionService.mapEntityToStored(entity),
                this.getAvailableNumberForSelection()
              );
            }
          ),
          tap(({ ids, params }: { ids: TStored[]; params: HttpParams }) => {
            this.tableSelectionService.addToSelection(...ids);
            this.patchState({
              lastLoadedItems: ids,
              lastUsedParams: params,
              selected: true
            });
          })
        );
      })
    )
  );

  private readonly deselectAll = this.effect((trigger$) =>
    trigger$.pipe(
      switchMap(() => {
        return this.select((state) => state.lastLoadedItems).pipe(
          take(1),
          tap((items: TStored[]) => {
            this.tableSelectionService.removeFromSelection(...items);
            this.setSelected(false);
          })
        );
      })
    )
  );

  private loadAllForFilter(
    nextPageFactory: LoadPageFactory<T>,
    params: HttpParams,
    entityFilterFn: (entity: T) => boolean,
    entityMapFn: (entity: T) => TStored,
    selectionLimit: number | null
  ): Observable<{ ids: TStored[]; params: HttpParams | null }> {
    this.setPending(true);
    return nextPageFactory(params, null).pipe(
      expand((response: PaginationResponse<T>) => {
        if (response.items.length === 0) {
          return EMPTY;
        }

        return nextPageFactory(params, response);
      }),
      map((response: PaginationResponse<T>) =>
        response.items
          .filter(entityFilterFn)
          .filter((item: T) => !this.tableSelectionService.isSelected(item))
          .map(entityMapFn)
      ),
      scan((acc: TStored[], value: TStored[]) => [...acc, ...value], []),
      takeWhile((ids: TStored[]) => (selectionLimit ? ids.length < selectionLimit : true), true),
      map((ids: TStored[]): TStored[] => (selectionLimit ? ids.slice(0, selectionLimit) : ids)),
      last(),
      map((ids: TStored[]) => ({
        ids,
        params
      })),
      finalize(() => {
        this.setPending(false);
      }),
      catchError(() => {
        return of({ ids: [], params: null });
      })
    );
  }

  private getAvailableNumberForSelection(): number | null {
    if (!this.tableSelectionService.limit) {
      return null;
    }

    return this.tableSelectionService.limit - this.tableSelectionService.selected.length;
  }
}

import { Action, createReducer, on } from '@ngrx/store';
import { cleanupPending } from '@neuralegion/core';
import {
  disableAuthProvider,
  disableAuthProviderFail,
  disableAuthProviderSuccess,
  loadAuthProvider,
  loadAuthProviderFail,
  loadAuthProviderSuccess,
  loadGitHubOrgs,
  loadGitHubOrgsFail,
  loadGitHubOrgsSuccess,
  startSetupAuthProvider,
  startSetupAuthProviderFail,
  startSetupAuthProviderSuccess,
  updateAuthProvider,
  updateAuthProviderFail,
  updateAuthProviderSuccess
} from '../auth-providers.actions';
import { UIState, initialUIState } from '../auth-providers.state';

export const uiReducer = createReducer<UIState, Action>(
  initialUIState,
  on(
    loadAuthProvider,
    loadGitHubOrgs,
    startSetupAuthProvider,
    updateAuthProvider,
    disableAuthProvider,
    (state: UIState, action): UIState => ({
      pending: [...state.pending, action],
      error: null
    })
  ),
  on(
    loadGitHubOrgsSuccess,
    disableAuthProviderSuccess,
    loadAuthProviderSuccess,
    updateAuthProviderSuccess,
    startSetupAuthProviderSuccess,
    (state: UIState, action): UIState => ({
      ...state,
      pending: cleanupPending(state.pending, action.parentType),
      error: null
    })
  ),
  on(
    loadGitHubOrgsFail,
    loadAuthProviderFail,
    updateAuthProviderFail,
    startSetupAuthProviderFail,
    disableAuthProviderFail,
    (state: UIState, action): UIState => ({
      ...state,
      pending: cleanupPending(state.pending, action.parentType),
      error: action.payload
    })
  )
);

import { BaseElementIdStrategy } from './base-element-id-strategy';

export class MatProgressBarElementIdStrategy extends BaseElementIdStrategy {
  protected elementPrefix = ['progress', 'bar'].join(this.SEPARATOR_INTERNAL);

  public override applyId(element: HTMLElement, dataId: string): void {
    super.applyId(element, dataId);

    const valueElement: HTMLElement = element.parentElement.querySelector('.progress-value');
    if (valueElement) {
      super.applyId(valueElement, [dataId, 'value'].join(this.SEPARATOR_INTERNAL));
    }

    const messageElement: HTMLElement = element.parentElement.querySelector('.progress-message');
    if (messageElement) {
      super.applyId(messageElement, [dataId, 'message'].join(this.SEPARATOR_INTERNAL));
    }
  }

  protected override getSubId(): string {
    return this.elementPrefix;
  }
}

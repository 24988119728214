import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { RepeaterNetworkResult } from '../models';

export interface RepeaterNetworkState extends EntityState<RepeaterNetworkResult> {
  error?: string;
  pending: Action[];
}

export const repeaterNetworkEntityAdapter: EntityAdapter<RepeaterNetworkResult> =
  createEntityAdapter<RepeaterNetworkResult>({ selectId: (model) => model.repeaterId });

export const repeaterNetworkInitialState: RepeaterNetworkState =
  repeaterNetworkEntityAdapter.getInitialState({
    error: null,
    pending: []
  });

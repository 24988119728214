<form
  role="search"
  aria-label="Find scans"
  fxLayout="row"
  fxLayoutAlign="start center"
  [class.ghost-blur]="!matInput.focused"
  [formGroup]="form">
  <mat-form-field *ngIf="options.config.length > 1" class="select-field compact-field">
    <mat-select [formControlName]="'filter'">
      <mat-option *ngFor="let filterConfig of options.config; trackBy: trackByIdentity" [value]="filterConfig">
        {{ filterConfig.name | filterLabel: filterLabelsMap }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="text-field compact-field" [class.multiple-filters]="options.config.length > 1">
    <input #matInput="matInput" matInput [formControlName]="'value'" [placeholder]="options.placeholder" />
    <button
      *ngIf="form.value.value"
      type="reset"
      aria-label="Clear search field"
      mat-icon-button
      matSuffix
      matTooltip="Clear search field"
      (click)="clearTextField()">
      <mat-icon>close</mat-icon>
    </button>
    <mat-icon *ngIf="!form.value.value" matSuffix>search</mat-icon>
  </mat-form-field>
</form>

import { Action, createReducer, on } from '@ngrx/store';
import {
  loadDateSettings,
  loadDateSettingsFail,
  loadDateSettingsSuccess,
  loadNotifications,
  loadNotificationsFail,
  loadNotificationsSuccess,
  updateDateSettings,
  updateDateSettingsFail,
  updateDateSettingsSuccess,
  updateNotifications,
  updateNotificationsFail,
  updateNotificationsSuccess
} from './profile-settings.actions';
import { ProfileSettingsState, initialProfileSettingsState } from './profile-settings.state';

export const profileSettingsReducer = createReducer<ProfileSettingsState, Action>(
  initialProfileSettingsState,
  on(
    loadNotifications,
    updateNotifications,
    loadDateSettings,
    updateDateSettings,
    (state: ProfileSettingsState): ProfileSettingsState => ({ ...state, pending: true })
  ),
  on(
    loadNotificationsSuccess,
    (state: ProfileSettingsState, action): ProfileSettingsState => ({
      ...state,
      pending: false,
      notifications: action.payload
    })
  ),
  on(
    updateNotificationsSuccess,
    (state: ProfileSettingsState): ProfileSettingsState => ({ ...state, pending: false })
  ),
  on(
    loadDateSettingsSuccess,
    (state: ProfileSettingsState, action): ProfileSettingsState => ({
      ...state,
      pending: false,
      dateSettings: action.payload
    })
  ),
  on(
    updateDateSettingsSuccess,
    (state: ProfileSettingsState): ProfileSettingsState => ({ ...state, pending: false })
  ),
  on(
    loadNotificationsFail,
    updateNotificationsFail,
    loadDateSettingsFail,
    updateDateSettingsFail,
    (state: ProfileSettingsState, action): ProfileSettingsState => ({
      ...state,
      pending: false,
      error: action.payload
    })
  )
);

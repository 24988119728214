import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Toast } from 'ngx-toastr';

@Component({
  selector: 'share-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SnackBarComponent extends Toast {
  public onTitleClick(): void {
    this.toastPackage.triggerAction();
    this.toastPackage.toastRef.manualClose();
  }
}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable, distinctUntilChanged, from, of, switchMap } from 'rxjs';
import equal from 'fast-deep-equal/es6';
import { ChangedValue } from '@neuralegion/api';
import { DiffResultItem, DiffService } from '../../services';

@Component({
  selector: 'share-diff-viewer',
  templateUrl: './diff-viewer.component.html',
  styleUrls: ['./diff-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiffViewerComponent implements OnInit {
  @Input()
  public value$: Observable<ChangedValue<string>>;

  public diff$: Observable<DiffResultItem[]>;

  constructor(private readonly diffService: DiffService) {}

  public ngOnInit(): void {
    this.diff$ = this.value$.pipe(
      distinctUntilChanged(equal),
      switchMap((value: ChangedValue<string>) =>
        typeof value.previous === 'string'
          ? from(this.diffService.diff(value))
          : of([[0, value.actual] as [number, string]])
      )
    );
  }

  public trackByFn(idx: number, _diff: [number, string]): number {
    return idx;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { DiscoveryType } from '@neuralegion/api';

@Pipe({
  name: 'discoveryTypesShort',
  pure: true
})
export class DiscoveryTypesShortPipe implements PipeTransform {
  public transform(values: DiscoveryType[]): string {
    return (values || [])
      .map((discoveryType) => this.format(discoveryType))
      .sort()
      .join('+');
  }

  private format(discoveryType: DiscoveryType): string {
    switch (discoveryType) {
      case DiscoveryType.ARCHIVE:
        return 'HAR';
      case DiscoveryType.CRAWLER:
        return 'Crawler';
      case DiscoveryType.OAS:
        return 'API';
      case DiscoveryType.SNIFFER:
        return 'Sniffer';
      default:
        return discoveryType;
    }
  }
}

/* Based on https://github.com/fknop/angular-pipes */
import { Pipe, PipeTransform } from '@angular/core';
import { isObject } from '@neuralegion/lang';

function isNil(value: unknown): value is null | undefined {
  return value === null || typeof value === 'undefined';
}

@Pipe({ name: 'defaults' })
export class DefaultsPipe implements PipeTransform {
  public transform(input: unknown, defaults: unknown): unknown {
    if (!isObject(defaults)) {
      return input;
    }

    if (isNil(input)) {
      return defaults;
    }

    if (Array.isArray(input)) {
      return input.map((item: unknown) => {
        if (isObject(item)) {
          return Object.assign({}, defaults, item);
        }

        if (isNil(item)) {
          return defaults;
        }

        return item;
      });
    }

    if (isObject(input)) {
      return Object.assign({}, defaults, input);
    }

    return input;
  }
}

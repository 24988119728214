import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import equal from 'fast-deep-equal/es6';
import { FilterValueObject, PaginationResponse, TableSortConfig } from '@neuralegion/api';
import { PaginationActionType, PaginationState, PartialPaginationState } from '../../models';
import { PaginationStateSerializationService } from './pagination-state-serialization.service';
import { PaginationStore } from './pagination-store.service';

@Injectable()
export class OffsetPaginationStore<
  T extends { id: string; createdAt?: string }
> extends PaginationStore<T> {
  protected readonly resetUpdaterFn = (state: PaginationState): PartialPaginationState => ({
    activeAction: {
      payload: {
        pagination: {
          limit: state.pagination.pageSize,
          offset: 0
        },
        filter: null,
        sort: null
      }
    },
    pagination: {
      pageIndex: 0,
      length: 0
    },
    filters: null,
    sort: null
  });
  protected readonly resetSuccessCallbackFn = (
    _state: PaginationState,
    payload: PaginationResponse<T>
  ): PartialPaginationState => ({
    activeAction: null,
    pagination: {
      length: payload.total
    }
  });

  protected readonly updatePageSizeUpdaterFn = (
    state: PaginationState,
    pageSize: number
  ): PartialPaginationState => ({
    activeAction: {
      payload: {
        pagination: {
          limit: pageSize,
          offset: 0
        },
        filter: state.filters,
        sort: state.sort
      }
    }
  });
  protected readonly updatePageSizeSuccessCallbackFn = (
    state: PaginationState,
    payload: PaginationResponse<T>
  ): PartialPaginationState => ({
    activeAction: null,
    pagination: {
      length: payload.total,
      pageSize: state.activeAction.payload.pagination.limit,
      pageIndex: 0
    }
  });

  protected readonly updateFilterUpdaterFn = (
    state: PaginationState,
    filters: FilterValueObject<unknown>
  ): PartialPaginationState => ({
    activeAction: {
      payload: {
        pagination: {
          limit: state.pagination?.pageSize,
          offset: 0
        },
        filter: filters,
        sort: state.sort
      }
    },
    filters
  });
  protected readonly updateFilterSuccessCallbackFn = (
    _state: PaginationState,
    payload: PaginationResponse<T>
  ): PartialPaginationState => ({
    activeAction: null,
    pagination: {
      pageIndex: 0,
      length: payload.total
    }
  });

  protected readonly updateSortUpdaterFn = (
    state: PaginationState,
    sort: TableSortConfig
  ): PartialPaginationState => ({
    activeAction: {
      payload: {
        sort,
        pagination: {
          limit: state.pagination?.pageSize,
          offset: 0
        },
        filter: state.filters
      }
    },
    sort
  });
  protected readonly updateSortSuccessCallbackFn = (
    _state: PaginationState,
    payload: PaginationResponse<T>
  ): PartialPaginationState => ({
    activeAction: null,
    pagination: {
      pageIndex: 0,
      length: payload.total
    }
  });

  protected readonly nextPageUpdaterFn = (state: PaginationState): PartialPaginationState => ({
    activeAction: {
      payload: {
        pagination: {
          limit: state.pagination.pageSize,
          offset: state.pagination.pageSize * (state.pagination.pageIndex + 1)
        },
        filter: state.filters,
        sort: state.sort
      }
    }
  });
  protected readonly nextPageSuccessCallbackFn = (
    state: PaginationState,
    payload: PaginationResponse<T>
  ): PartialPaginationState => {
    let pageIndex = 0;
    if (payload.items.length) {
      pageIndex = state.pagination.pageIndex + 1;
    } else if (payload.total !== 0) {
      pageIndex = Math.floor(payload.total / state.pagination.pageSize);
    }

    return {
      activeAction: null,
      pagination: {
        pageIndex,
        length: payload.total
      }
    };
  };

  protected readonly prevPageUpdaterFn = (state: PaginationState): PartialPaginationState => ({
    activeAction: {
      payload: {
        pagination: {
          limit: state.pagination.pageSize,
          offset:
            state.pagination.pageSize *
            (state.pagination.pageIndex > 1 ? state.pagination.pageIndex - 1 : 0)
        },
        filter: state.filters,
        sort: state.sort
      }
    }
  });
  protected readonly prevPageSuccessCallbackFn = (
    state: PaginationState,
    payload: PaginationResponse<T>
  ): PartialPaginationState => ({
    activeAction: null,
    pagination: {
      pageIndex: payload.items.length ? state.pagination.pageIndex - 1 : 0,
      length: payload.total
    }
  });

  protected readonly firstPageUpdaterFn = (state: PaginationState): PartialPaginationState => ({
    activeAction: {
      payload: {
        pagination: {
          limit: state.pagination.pageSize,
          offset: 0
        },
        filter: state.filters,
        sort: state.sort
      }
    }
  });
  protected readonly firstPageSuccessCallbackFn = (
    _state: PaginationState,
    payload: PaginationResponse<T>
  ): PartialPaginationState => ({
    activeAction: null,
    pagination: {
      pageIndex: 0,
      length: payload.total
    }
  });

  protected readonly reloadPageUpdaterFn = (state: PaginationState): PartialPaginationState =>
    this.getReloadPageUpdateState(state);
  protected readonly reloadPageSuccessCallbackFn = (
    state: PaginationState,
    payload: PaginationResponse<T>
  ): PartialPaginationState => ({
    activeAction: null,
    pagination: {
      pageSize: state.pagination.pageSize,
      length: payload.total
    }
  });

  public readonly lastPage = this.createActionWithSuccessCallback({
    actionType: PaginationActionType.LOAD_LAST_PAGE,
    updaterFn: (state: PaginationState): PartialPaginationState => ({
      activeAction: {
        payload: {
          pagination: {
            limit: state.pagination.pageSize,
            offset:
              state.pagination.pageSize *
              (Math.ceil(state.pagination.length / state.pagination.pageSize) - 1)
          },
          filter: state.filters,
          sort: state.sort
        }
      }
    }),
    successCallbackFn: (
      state: PaginationState,
      payload: PaginationResponse<T>
    ): PartialPaginationState => ({
      activeAction: null,
      pagination: {
        pageIndex: Math.ceil(payload.total / state.pagination.pageSize) - 1,
        length: payload.total
      }
    })
  });

  constructor(
    actions$: Actions,
    store: Store,
    paginationStateSerializationService: PaginationStateSerializationService<PaginationState>
  ) {
    super(actions$, store, paginationStateSerializationService, {
      pagination: null,
      filters: null,
      sort: null,
      activeAction: null,
      refreshParams: null
    });

    this.initActions();
  }

  private getReloadPageUpdateState(state: PaginationState): PartialPaginationState {
    const refreshParamsFilterEqualsStateFilter = equal(state.refreshParams?.filter, state.filters);

    return {
      activeAction: {
        payload: refreshParamsFilterEqualsStateFilter
          ? state.refreshParams ?? {
              pagination: {
                limit: state.pagination.pageSize,
                offset: 0
              },
              filter: null,
              sort: null
            }
          : {
              pagination: {
                limit: state.pagination.pageSize,
                offset: 0
              },
              filter: state.filters,
              sort: null
            }
      },
      ...(!refreshParamsFilterEqualsStateFilter ? { pagination: { pageIndex: 0 } } : {})
    };
  }
}

import { Directive, Host, Input } from '@angular/core';
import { ElementTextDirective } from './element-text.directive';

@Directive({
  selector: '[shareElementTextAdditionalItems]'
})
export class ElementTextAdditionalItemsDirective {
  constructor(@Host() private readonly elementTextDirective: ElementTextDirective) {}

  @Input()
  set items(items: readonly string[] | Record<string, string> | null | undefined) {
    if (!items) {
      this.elementTextDirective.additionalItems = [];
      return;
    }

    this.elementTextDirective.additionalItems = Array.isArray(items)
      ? items
      : Object.entries(items).map((entry: [string, string]) => entry.join(''));
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { SettingsError } from '../components';

@Pipe({
  name: 'sectionError',
  pure: true
})
export class SectionErrorPipe<S> implements PipeTransform {
  public transform(
    errors: SettingsError<S>[],
    sectionName: S,
    controlName?: string,
    errorName?: string
  ): SettingsError<S> | null {
    if (!errors?.length || !sectionName) {
      return null;
    }

    return errors.find((errorData: SettingsError<S>) => {
      const controlNameCondition = controlName ? errorData.control === controlName : true;
      const errorNameCondition = errorName ? errorData.control === errorName : true;

      return (
        (errorData.section === sectionName && controlNameCondition && errorNameCondition) || null
      );
    });
  }
}

<ng-container [formGroup]="form">
  <share-note class="hint" [type]="'notice'"> Toggle tabs visibility </share-note>

  <div
    class="tabs-list"
    element-data-id="list_tabs_config"
    [formArrayName]="'tabs'"
    (click)="$event.stopPropagation()"
    (keydown)="$event.stopPropagation()">
    <div
      *ngFor="let group of tabsFormGroups; index as idx; trackBy: trackByIdentity"
      class="tabs-list-item"
      element-data-id="list_item_{{ idx }}"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      [class.hidden-tab]="group.get('fixed').value"
      [formGroup]="group">
      <mat-slide-toggle fxFlex [class.toggle-disabled]="!group.value.active" [formControlName]="'active'">
        {{ group.value.label }}
      </mat-slide-toggle>
    </div>
  </div>
</ng-container>

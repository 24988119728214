import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, exhaustMap, map, of, tap } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { MfaQrCode, Token } from '@neuralegion/api';
import { UserSessionStorageService } from '@neuralegion/browser-storage';
import { activateLayout, deactivateLayout } from '@neuralegion/core';
import { MultiFactorService } from '../services';
import {
  disableMfa,
  disableMfaFail,
  disableMfaSuccess,
  enableMfa,
  enableMfaFail,
  enableMfaSuccess,
  loadMfaQrCode,
  loadMfaQrCodeFail,
  loadMfaQrCodeSuccess,
  verifyMfaOtp,
  verifyMfaOtpFail,
  verifyMfaOtpSuccess
} from './multi-factor.actions';

@Injectable()
export class MultiFactorEffects {
  public readonly loadMfaQrCode$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMfaQrCode),
      exhaustMap(() =>
        this.mfaService.loadMfaQrCode().pipe(
          map((options: MfaQrCode) => loadMfaQrCodeSuccess(options)),
          catchError((err: HttpErrorResponse) => of(loadMfaQrCodeFail(err.error)))
        )
      )
    )
  );

  public readonly disableMfa$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(disableMfa),
      exhaustMap(() =>
        this.mfaService.disableMfa().pipe(
          map(() => disableMfaSuccess()),
          catchError((err: HttpErrorResponse) => of(disableMfaFail(err.error)))
        )
      )
    )
  );

  public readonly enableMfa$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(enableMfa),
      exhaustMap((action: ReturnType<typeof enableMfa>) =>
        this.mfaService.enableMfa(action.payload.code, action.payload.otp).pipe(
          map(() => enableMfaSuccess({ forcedSetup: action.payload.forcedSetup })),
          catchError((err: HttpErrorResponse) => of(enableMfaFail(err.error)))
        )
      )
    )
  );

  public readonly verifyMfa$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(verifyMfaOtp),
      exhaustMap((action: ReturnType<typeof verifyMfaOtp>) =>
        this.mfaService.verifyMfaOtp(action.payload.token, action.payload.code).pipe(
          tap((token: Token) => this.userSessionStorageService.set(token)),
          map(() => verifyMfaOtpSuccess()),
          catchError((err: HttpErrorResponse) => of(verifyMfaOtpFail(err.error)))
        )
      )
    )
  );

  public readonly deactivateLayout$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<Action>(verifyMfaOtp, enableMfa),
      exhaustMap(() => [deactivateLayout()])
    )
  );

  public readonly activateLayout$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<Action>(verifyMfaOtpFail, verifyMfaOtpSuccess, enableMfaFail, enableMfaSuccess),
      exhaustMap(() => [activateLayout()])
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly userSessionStorageService: UserSessionStorageService,
    private readonly mfaService: MultiFactorService
  ) {}
}

<sat-popover #popover xAlign="start" yAlign="center">
  <div class="popover-container tabs-menu" [attr.data-id]="'overlay_table_settings'">
    <h2 class="header">Tab view settings</h2>

    <ng-container *ngIf="tabsConfig?.length">
      <share-tabs-config [tabsConfig]="tabsConfig" (tabsConfigChanged)="tabsConfigChanged.emit($event)">
      </share-tabs-config>

      <mat-divider></mat-divider>
    </ng-container>

    <div class="buttons" fxLayoutAlign="center center">
      <button class="text-button" mat-button (click)="resetClicked.emit()">Restore defaults</button>
    </div>
  </div>
</sat-popover>

<button
  class="trigger-button"
  aria-label="Tab view settings"
  mat-icon-button
  matTooltip="Tab view settings"
  [satPopoverAnchor]="popover"
  [sharePopoverTrigger]="popover">
  <mat-icon>settings</mat-icon>
</button>

import { Injectable } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { FormErrorHandler, ValidationErrorCode } from '../../models';
import { DEFAULT_FIELD_NAME, defaultErrorMapFactory } from './default-error-map-factory';

const DEFAULT_ERROR_PRIORITY = 100;

export type ErrorHandlerMap = Map<ValidationErrorCode, FormErrorHandler>;

type ErrorHandlersMapOverride = {
  [P in ValidationErrorCode]?: FormErrorHandler;
};

export interface FormErrorOptions {
  errorHandlers?: ErrorHandlersMapOverride;
  fieldName?: string;
}

@Injectable()
export class FormErrorService {
  protected defaultErrorFormattersMap: Map<ValidationErrorCode, FormErrorHandler> =
    defaultErrorMapFactory();

  public getError(
    controlErrors: ValidationErrors,
    options: FormErrorOptions = {}
  ): { errorCode: ValidationErrorCode; message: string } {
    const { errorHandlers = {}, fieldName = DEFAULT_FIELD_NAME } = options;
    if (!Object.keys(controlErrors).length) {
      return { errorCode: null, message: '' };
    }

    // Concatenate error handlers
    const activeErrorHandlersMap: ErrorHandlerMap = this.buildErrorHandlersMap(errorHandlers);

    // Find the topmost error and its handler according to error priority
    const { errorCode, handler } = Object.keys(controlErrors ?? {})
      .map((code: string) => ({
        errorCode: code,
        handler: activeErrorHandlersMap.get(code as ValidationErrorCode) ?? {}
      }))
      .sort(this.compareByPriority)
      .at(0);

    // Return error text
    return {
      errorCode: errorCode as ValidationErrorCode,
      message:
        handler?.message ||
        handler?.messageFn?.({ error: controlErrors[errorCode], fieldName }) ||
        `${fieldName} is invalid`
    };
  }

  private buildErrorHandlersMap(customErrorHandlers: ErrorHandlersMapOverride): ErrorHandlerMap {
    const result: ErrorHandlerMap = new Map([...this.defaultErrorFormattersMap]);

    Object.entries(customErrorHandlers).forEach(([key, value]: [string, FormErrorHandler]) => {
      result.set(
        key as ValidationErrorCode,
        {
          ...(result.get(key as ValidationErrorCode) ?? {}),
          ...value
        } as FormErrorHandler
      );
      return;
    });

    return result;
  }

  private compareByPriority(
    { handler: { priority: a = DEFAULT_ERROR_PRIORITY } }: { handler: FormErrorHandler },
    { handler: { priority: b = DEFAULT_ERROR_PRIORITY } }: { handler: FormErrorHandler }
  ): number {
    return b - a;
  }
}

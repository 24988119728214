import { ConfiguredProvider, LoggedInUser } from '@neuralegion/api';

export interface AuthState {
  readonly accessFailedCount: number;
  readonly token: string | null;
  readonly slug: string | null;
  readonly provider: ConfiguredProvider | null;
  readonly onboardingActive: boolean;
  readonly pending: boolean;
  readonly userInfo: LoggedInUser | null;
}

export const initialAuthState: AuthState = {
  accessFailedCount: 0,
  slug: null,
  token: null,
  provider: null,
  onboardingActive: false,
  pending: false,
  userInfo: null
};

import { AbstractControl, FormArray, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ListControlErrors } from '../models';
import { CustomValidators } from './custom-validators';

export class ListControlValidators {
  public static listItemsLength(max: number): ValidatorFn {
    return (formArray: AbstractControl): ValidationErrors | null => {
      if (!formArray || !Array.isArray(formArray.value)) {
        return null;
      }

      const controls = (formArray as FormArray).controls || [];

      for (let i = 0; i < controls.length; i++) {
        if (i < max) {
          CustomValidators.clearError(controls[i], ListControlErrors.LIST_LENGTH_EXCEEDED);
        } else {
          controls[i].setErrors({
            ...(controls[i].errors || {}),
            [ListControlErrors.LIST_LENGTH_EXCEEDED]: max
          });
        }
      }

      return controls.length > max ? { [ListControlErrors.LIST_LENGTH_EXCEEDED]: max } : null;
    };
  }

  public static uniqueListItems(
    formArray: AbstractControl,
    valueMapper?: (value: unknown) => unknown
  ): ValidationErrors | null {
    if (!formArray || !Array.isArray(formArray.value)) {
      return null;
    }
    const valuesMatrix: Map<number, number> = new Map<number, number>();

    const hasDuplicates = ((formArray as FormArray).controls || [])
      .map((control: AbstractControl) =>
        CustomValidators.clearSetDuplicateError({
          valuesMatrix,
          control,
          valueMapper,
          errorName: ListControlErrors.DUPLICATED_LIST_ITEM
        })
      )
      .some(Boolean);

    return hasDuplicates ? { [ListControlErrors.LIST_CONTAINS_DUPLICATES]: true } : null;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { SimplePluralPipe } from './simple-plural.pipe';

export enum FilterStatus {
  AVAILABLE = 'available',
  UNAVAILABLE = 'unavailable',
  ALREADY_SELECTED = 'already_selected',
  EMPTY = 'empty'
}

@Pipe({
  name: 'shareFilterStatusLabel',
  pure: true
})
export class FilterStatusLabelPipe implements PipeTransform {
  private readonly pluralPipe = new SimplePluralPipe();

  public transform(status: FilterStatus, entityName: string): string {
    switch (status) {
      case FilterStatus.ALREADY_SELECTED:
        return 'Already selected';
      case FilterStatus.EMPTY:
        return `No ${this.pluralPipe.transform('', entityName).toLowerCase()}`;
      default:
        return '';
    }
  }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class QuantityCounterClickService<T> {
  private readonly clickedSubject = new Subject<T>();

  public readonly clicked$ = this.clickedSubject.asObservable();

  public clicked(value: T): void {
    this.clickedSubject.next(value);
  }
}

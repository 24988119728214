import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SatPopoverAnchorDirective } from './popover-anchor.directive';
import { SatPopoverHoverDirective } from './popover-hover.directive';
import { SatPopoverComponent } from './popover.component';
import { SAT_POPOVER_DEFAULT_TRANSITION } from './tokens';

@NgModule({
  imports: [CommonModule, OverlayModule, A11yModule],
  declarations: [SatPopoverComponent, SatPopoverAnchorDirective, SatPopoverHoverDirective],
  providers: [
    // See http://cubic-bezier.com/#.25,.8,.25,1 for reference
    { provide: SAT_POPOVER_DEFAULT_TRANSITION, useValue: '200ms cubic-bezier(0.25, 0.8, 0.25, 1)' }
  ],
  exports: [SatPopoverComponent, SatPopoverAnchorDirective, SatPopoverHoverDirective]
})
export class SatPopoverModule {}

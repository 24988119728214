import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ID } from '@neuralegion/api';
import { PaymentMethod, PaymentMethodPayload } from '../models';

@Injectable()
export class PaymentMethodsService {
  constructor(private readonly http: HttpClient) {}

  public loadPaymentMethods(): Observable<PaymentMethod[]> {
    return this.http.get<PaymentMethod[]>(`/api/v1/payment-methods`);
  }

  public addPaymentMethod(paymentMethod: PaymentMethodPayload): Observable<PaymentMethod> {
    return this.http.post<PaymentMethod>('/api/v1/payment-methods', paymentMethod);
  }

  public updatePaymentMethod(paymentMethod: PaymentMethodPayload & ID): Observable<PaymentMethod> {
    return this.http.put<PaymentMethod>(
      `/api/v1/payment-methods/${paymentMethod.id}`,
      paymentMethod
    );
  }

  public deletePaymentMethod(paymentMethodId: string): Observable<void> {
    return this.http.delete<void>(`/api/v1/payment-methods/${paymentMethodId}`);
  }

  public changeDefaultPaymentMethod(paymentMethodId: string): Observable<void> {
    return this.http.patch<void>(`/api/v1/payment-methods/${paymentMethodId}`, {
      defaultMethod: true
    });
  }
}

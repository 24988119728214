import {
  Directive,
  ElementRef,
  Injector,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
  SkipSelf
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatRadioButton } from '@angular/material/radio';
import { MatTabGroup } from '@angular/material/tabs';
import { Subject } from 'rxjs';
import { DataIdQueueService } from '../services/data-id-queue.service';
import {
  ButtonElementIdStrategy,
  DatasetEmptyElementIdStrategy,
  ElementIdStrategy,
  EmptyIdStrategy,
  FormControlElementIdStrategy,
  LinkElementIdStrategy,
  MarkdownElementIdStrategy,
  MatButtonToggleElementIdStrategy,
  MatCheckboxElementIdStrategy,
  MatChipElementIdStrategy,
  MatChipListElementIdStrategy,
  MatDatePickerToggleElementIdStrategy,
  MatDialogElementIdStrategy,
  MatExpansionPanelElementIdStrategy,
  MatFormFieldElementIdStrategy,
  MatHintErrorElementIdStrategy,
  MatListElementIdStrategy,
  MatListItemElementIdStrategy,
  MatListOptionElementIdStrategy,
  MatMenuElementIdStrategy,
  MatOptionElementIdStrategy,
  MatPaginatorElementIdStrategy,
  MatProgressBarElementIdStrategy,
  MatRadioButtonElementIdStrategy,
  MatRadioGroupElementIdStrategy,
  MatSelectElementIdStrategy,
  MatSelectionListElementIdStrategy,
  MatSlideToggleElementIdStrategy,
  MatSpinnerElementDataIdStrategy,
  MatTabGroupElementIdStrategy,
  NoteElementIdStrategy,
  PageHeaderElementIdStrategy,
  SnackbarElementIdStrategy,
  StatusElementIdStrategy,
  TableColumnElementIdStrategy,
  TableElementIdStrategy,
  TableRowElementIdStrategy,
  TargetStatusElementIdStrategy,
  TooltipElementIdStrategy,
  UnknownElementIdStrategy,
  WidgetCardElementIdStrategy
} from './strategies';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: `
    .dataset-empty,
    .snack-bar,
    .viewer-header,
    [data-id],
    [element-data-id],
    [formControlName],
    [formControl],
    a,
    button,
    markdown,
    mat-button-toggle,
    mat-checkbox,
    mat-chip-grid,
    mat-chip-listbox,
    mat-chip-option,
    mat-chip-row,
    mat-datepicker-toggle,
    mat-dialog-content,
    mat-error,
    mat-expansion-panel,
    mat-form-field,
    mat-header-cell,
    mat-hint,
    mat-list,
    mat-list-item,
    mat-list-option,
    mat-option,
    mat-paginator,
    mat-progress-bar,
    mat-radio-button,
    mat-radio-group,
    mat-row,
    mat-selection-list,
    mat-slide-toggle,
    mat-spinner,
    mat-tab-group,
    mat-table,
    matMenuTriggerFor,
    discovery-settings-target-status,
    share-info-tooltip,
    share-note,
    span.header-label,
    span.status,
    table,
    th,
    tr
  `
})
export class DataIdDirective implements OnInit, OnDestroy {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('data-id')
  public dataId?: string;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('element-data-id')
  public elementDataId?: string | number;

  get strategy(): ElementIdStrategy {
    if (!this.elementIdStrategy) {
      this.elementIdStrategy = this.createStrategy(this.element);
    }

    return this.elementIdStrategy;
  }

  private readonly element: HTMLElement = this.elementRef.nativeElement;
  private readonly gc = new Subject<void>();

  private calculatedId: string | undefined;
  private elementIdStrategy: ElementIdStrategy | undefined;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly injector: Injector,
    private readonly ngZone: NgZone,
    private readonly renderer: Renderer2,
    private readonly dataIdQueueService: DataIdQueueService,
    @Optional() @SkipSelf() public readonly parentDirective: DataIdDirective
  ) {}

  public ngOnInit(): void {
    this.ngZone.runOutsideAngular(() => {
      this.applyId();
    });
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();

    this.dataIdQueueService.remove(this.element);
  }

  public getId(): string {
    if (this.element.nodeType !== Node.ELEMENT_NODE) {
      return '';
    }

    if (this.calculatedId === undefined) {
      if (this.dataId) {
        this.calculatedId = this.dataId;
      } else {
        this.calculatedId = this.strategy.calculateId(
          this.element,
          this.parentDirective?.getId(),
          this.elementDataId !== undefined ? `${this.elementDataId}` : undefined
        );
      }
    }

    return this.calculatedId;
  }

  private applyId(): void {
    if (this.element.nodeType !== Node.ELEMENT_NODE) {
      return;
    }

    this.dataIdQueueService.add(this.element, () =>
      this.strategy.applyId(this.element, this.getId())
    );
  }

  private createStrategy(element: HTMLElement): ElementIdStrategy {
    const ngControl = this.injector.get(NgControl, null, { self: true });

    switch (element.tagName) {
      case 'A':
        return new LinkElementIdStrategy(this.renderer);
      case 'BUTTON':
        if (element.classList.contains('mat-mdc-menu-trigger')) {
          return new MatMenuElementIdStrategy(this.renderer, this.injector.get(MatMenuTrigger));
        }
        return new ButtonElementIdStrategy(this.renderer);
      case 'DIV':
        if (element.classList.contains('snack-bar')) {
          return new SnackbarElementIdStrategy(this.renderer);
        }

        if (element.classList.contains('viewer-header')) {
          return new WidgetCardElementIdStrategy(this.renderer);
        }
        break;
      case 'MARKDOWN':
        return new MarkdownElementIdStrategy(this.renderer);
      case 'MAT-BUTTON-TOGGLE':
        return new MatButtonToggleElementIdStrategy(this.renderer);
      case 'MAT-CHECKBOX':
        return new MatCheckboxElementIdStrategy(this.renderer, ngControl);
      case 'MAT-CHIP-GRID':
      case 'MAT-CHIP-LISTBOX':
        return new MatChipListElementIdStrategy(this.renderer, ngControl);
      case 'MAT-CHIP-OPTION':
      case 'MAT-CHIP-ROW':
        return new MatChipElementIdStrategy(this.renderer);
      case 'MAT-DATEPICKER-TOGGLE':
        return new MatDatePickerToggleElementIdStrategy(this.renderer);
      case 'MAT-DIALOG-CONTENT':
        return new MatDialogElementIdStrategy(this.renderer);
      case 'MAT-EXPANSION-PANEL':
        return new MatExpansionPanelElementIdStrategy(this.renderer);
      case 'MAT-FORM-FIELD':
        return new MatFormFieldElementIdStrategy(
          this.renderer,
          this.injector.get(MatFormField, null, { self: true })
        );
      case 'MAT-HINT':
      case 'MAT-ERROR':
        return new MatHintErrorElementIdStrategy(this.renderer);
      case 'MAT-LIST':
        return new MatListElementIdStrategy(this.renderer);
      case 'MAT-LIST-ITEM':
        return new MatListItemElementIdStrategy(this.renderer);
      case 'MAT-SELECTION-LIST':
        return new MatSelectionListElementIdStrategy(this.renderer);
      case 'MAT-LIST-OPTION':
        return new MatListOptionElementIdStrategy(this.renderer);
      case 'MAT-OPTION':
        return new MatOptionElementIdStrategy(this.renderer);
      case 'MAT-PAGINATOR':
        return new MatPaginatorElementIdStrategy(this.renderer);
      case 'MAT-PROGRESS-BAR':
        return new MatProgressBarElementIdStrategy(this.renderer);
      case 'MAT-RADIO-BUTTON':
        return new MatRadioButtonElementIdStrategy(
          this.renderer,
          this.injector.get(MatRadioButton)
        );
      case 'MAT-RADIO-GROUP':
        return new MatRadioGroupElementIdStrategy(this.renderer, ngControl);
      case 'MAT-SELECT':
        return new MatSelectElementIdStrategy(this.renderer);
      case 'MAT-SLIDE-TOGGLE':
        return new MatSlideToggleElementIdStrategy(this.renderer, ngControl);
      case 'MAT-SPINNER':
        return new MatSpinnerElementDataIdStrategy(this.renderer);
      case 'MAT-TAB-GROUP':
        return new MatTabGroupElementIdStrategy(this.renderer, this.injector.get(MatTabGroup));
      case 'DISCOVERY-SETTINGS-TARGET-STATUS':
        return new TargetStatusElementIdStrategy(this.renderer);
      case 'SECTION':
        return new DatasetEmptyElementIdStrategy(this.renderer);
      case 'SHARE-INFO-TOOLTIP':
        return new TooltipElementIdStrategy(this.renderer);
      case 'SHARE-NOTE':
        return new NoteElementIdStrategy(this.renderer);
      case 'SPAN':
        if (element.classList.contains('status')) {
          return new StatusElementIdStrategy(this.renderer);
        } else if (element.classList.contains('header-label')) {
          return new PageHeaderElementIdStrategy(this.renderer);
        }
        break;
      case 'TABLE':
      case 'MAT-TABLE':
        return new TableElementIdStrategy(this.renderer);
      case 'TH':
      case 'MAT-HEADER-CELL':
        return new TableColumnElementIdStrategy(this.renderer);
      case 'TR':
      case 'MAT-ROW':
        return new TableRowElementIdStrategy(this.renderer);
    }

    if (ngControl) {
      if (
        (['INPUT', 'TEXTAREA'].includes(element.tagName) && !!element.closest('mat-form-field')) ||
        element.tagName?.split('-')[0] === 'MAT'
      ) {
        return new EmptyIdStrategy(this.renderer);
      }

      return new FormControlElementIdStrategy(this.renderer, ngControl);
    }

    return new UnknownElementIdStrategy(this.renderer);
  }
}

import { BaseElementIdStrategy } from './base-element-id-strategy';

export class WidgetCardElementIdStrategy extends BaseElementIdStrategy {
  private readonly elementPrefix = ['widget', 'card'].join(this.SEPARATOR_INTERNAL);

  public override applyId(element: HTMLElement, dataId: string): void {
    // in case where widget card contains multiple headers
    // only the first header should provide the data-id attribute
    // others should be skipped
    // ex. DiscoveryEntitiesTableComponent
    if (element.parentElement.querySelector('.viewer-header') !== element) {
      return;
    }

    super.applyId(element.parentElement, dataId);
  }

  protected override getSubId(element: HTMLElement): string {
    return [
      this.elementPrefix,
      this.generateIdByText(this.getElementText(element.querySelector('.viewer-header-title')))
    ].join(this.SEPARATOR_INTERNAL);
  }
}

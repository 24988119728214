import { BaseElementIdStrategy } from './base-element-id-strategy';

export class TableColumnElementIdStrategy extends BaseElementIdStrategy {
  private readonly elementPrefix = 'column';

  public override applyId(element: HTMLElement, dataId: string): void {
    if (element.classList.contains('mat-sort-header')) {
      super.applyId(element, [dataId, 'sort'].join(this.SEPARATOR_INTERNAL));
    }

    const dragIcon = [...element.querySelectorAll('mat-icon')].find((iconElement) =>
      iconElement.textContent.includes('drag_indicator')
    ) as HTMLElement;

    if (dragIcon) {
      super.applyId(dragIcon, [dataId, 'drag'].join(this.SEPARATOR_INTERNAL));
    }
  }

  protected override getSubId(element: HTMLElement): string {
    return [this.elementPrefix, this.generateIdByText(this.getColumnTitle(element))].join(
      this.SEPARATOR_INTERNAL
    );
  }

  private getColumnTitle(element: HTMLElement): string {
    return this.getElementText(element, (node: Node) =>
      ['MAT-ICON'].includes((node as HTMLElement).tagName)
    );
  }
}

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { logout } from '@neuralegion/auth-api';

@Component({
  selector: 'auth-logout',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoutComponent implements OnInit {
  constructor(private readonly store: Store) {}

  public ngOnInit(): void {
    this.store.dispatch(logout({ skipRouting: false }));
  }
}

import { Action, createReducer, on } from '@ngrx/store';
import { loadGitHubOrgsSuccess } from '../auth-providers.actions';
import { GitHubAuthState, initialGitHubAuthState } from '../auth-providers.state';

export const githubAuthReducer = createReducer<GitHubAuthState, Action>(
  initialGitHubAuthState,
  on(
    loadGitHubOrgsSuccess,
    (state: GitHubAuthState, action): GitHubAuthState => ({
      ...state,
      orgs: action.payload
    })
  )
);

import { AfterViewInit, Directive, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { TableSettingsService } from '@neuralegion/browser-storage';

@Directive()
export abstract class SortableTableComponent implements AfterViewInit {
  abstract get tableName(): string;

  @ViewChild(MatSort)
  protected readonly sort: MatSort;

  protected constructor(protected readonly tableSettingsService: TableSettingsService) {}

  public ngAfterViewInit(): void {
    this.tableSettingsService.applySortParams(this.tableName, this.sort);
  }

  public sortQuery(sort: Sort): void {
    this.tableSettingsService.saveSortParams(this.tableName, sort);
  }
}

import { createAction } from '@ngrx/store';
import { DateSettings, MemberNotificationSettings } from '@neuralegion/api';

export const loadNotifications = createAction('[ProfileSettings] LOAD_NOTIFICATIONS');

export const loadNotificationsSuccess = createAction(
  '[ProfileSettings] LOAD_NOTIFICATIONS_SUCCESS',
  (payload: MemberNotificationSettings) => ({ payload })
);

export const loadNotificationsFail = createAction(
  '[ProfileSettings] LOAD_NOTIFICATIONS_FAIL',
  (payload: string) => ({
    payload
  })
);

export const updateNotifications = createAction(
  '[ProfileSettings] UPDATE_NOTIFICATIONS',
  (payload: { settings: MemberNotificationSettings }) => ({ payload })
);

export const updateNotificationsSuccess = createAction(
  '[ProfileSettings] UPDATE_NOTIFICATIONS_SUCCESS'
);

export const updateNotificationsFail = createAction(
  '[ProfileSettings] UPDATE_NOTIFICATIONS_FAIL',
  (payload: string) => ({ payload })
);

export const loadDateSettings = createAction('[ProfileSettings] LOAD_DATE_SETTINGS');

export const loadDateSettingsSuccess = createAction(
  '[ProfileSettings] LOAD_DATE_SETTINGS_SUCCESS',
  (payload: DateSettings) => ({ payload })
);

export const loadDateSettingsFail = createAction(
  '[ProfileSettings] LOAD_DATE_SETTINGS_FAIL',
  (payload: string) => ({
    payload
  })
);

export const updateDateSettings = createAction(
  '[ProfileSettings] UPDATE_DATE_SETTINGS',
  (payload: { settings: DateSettings }) => ({ payload })
);

export const updateDateSettingsSuccess = createAction(
  '[ProfileSettings] UPDATE_DATE_SETTINGS_SUCCESS'
);

export const updateDateSettingsFail = createAction(
  '[ProfileSettings] UPDATE_DATE_SETTINGS_FAIL',
  (payload: string) => ({ payload })
);

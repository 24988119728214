import { Pipe, PipeTransform } from '@angular/core';
import { FeedActivityObjectType, FeedVerb } from '@neuralegion/feed-api';

@Pipe({
  name: 'feedObjectType',
  pure: true
})
export class FeedObjectTypePipe implements PipeTransform {
  public transform(type: FeedActivityObjectType, verb: FeedVerb = null): string {
    if (type === null) {
      return '';
    }

    if (verb) {
      if (type === FeedActivityObjectType.SCAN) {
        switch (verb) {
          case FeedVerb.START:
          case FeedVerb.SCHEDULE:
            return 'a new scan';
          case FeedVerb.STOP:
            return 'a running scan';
          default:
            return 'a scan';
        }
      }

      if (type === FeedActivityObjectType.USER) {
        switch (verb) {
          case FeedVerb.CREATE:
            return 'a new user';
          case FeedVerb.REMOVE:
            return 'an existing user';
          default:
            return 'a user';
        }
      }

      if (type === FeedActivityObjectType.FILE) {
        return 'a file';
      }
    }

    return type.replace('_', ' ');
  }
}

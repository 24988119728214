import { Pipe, PipeTransform } from '@angular/core';
import { ID } from '@neuralegion/api';

@Pipe({
  name: 'mapById',
  pure: true
})
export class MapByIdPipe<T extends ID> implements PipeTransform {
  public transform(ids: string[], items: T[]): T[] {
    return ids.map((id) => items.find((item) => item.id === id));
  }
}

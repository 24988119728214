<mat-card
  *ngIf="{ mfaQrCode: mfaQrCode$ | async, pending: pending$ | async } as sync"
  cdkTrapFocus
  [cdkTrapFocusAutoCapture]="!!sync.mfaQrCode">
  <mat-card-header>
    <mat-card-title role="heading" aria-level="1"> Setup two-factor authentication </mat-card-title>
  </mat-card-header>

  <mat-card-content [formGroup]="form">
    <p>Your organization requires 2FA.</p>

    <div class="qr-code">
      <share-mfa-qr-code *ngIf="sync.mfaQrCode" [form]="form" [url]="sync.mfaQrCode.url"> </share-mfa-qr-code>
      <share-spinner *ngIf="!sync.mfaQrCode" [inner]="true"></share-spinner>
    </div>

    <button color="primary" mat-raised-button [disabled]="!form.valid" (click)="onVerifyClick()">Verify</button>
  </mat-card-content>
</mat-card>

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DateFormatAlias } from '@neuralegion/core';
import {
  FeedActivity,
  FeedActivityActor,
  FeedActivityObjectType,
  FeedVerb
} from '@neuralegion/feed-api';

@Component({
  selector: 'feed-item-text',
  templateUrl: './feed-item-text.component.html',
  styleUrls: ['./feed-item-text.component.scss'],
  preserveWhitespaces: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedItemTextComponent {
  @Input()
  public item: FeedActivity;

  @Input()
  public extra = false;

  public readonly FeedActivityObjectType = FeedActivityObjectType;
  public readonly FeedVerb = FeedVerb;
  public readonly DateFormatAlias = DateFormatAlias;
  public readonly FeedActivityActor = FeedActivityActor;
}

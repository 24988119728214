import { ContentChild, Directive, HostListener } from '@angular/core';
import { CopyMenuItemComponent } from '../components/copy-menu-item/copy-menu-item.component';

@Directive({
  selector: '[shareCopyMenuItem]'
})
export class CopyMenuItemDirective {
  @ContentChild(CopyMenuItemComponent)
  private readonly child: CopyMenuItemComponent;

  @HostListener('click')
  public onHostClick(): void {
    this.child?.onCopyClick();
  }
}

import { BaseElementIdStrategy } from './base-element-id-strategy';

export class MatDatePickerToggleElementIdStrategy extends BaseElementIdStrategy {
  private readonly elementPrefix = ['btn', 'date', 'picker', 'toggle'].join(
    this.SEPARATOR_INTERNAL
  );

  public override applyId(element: HTMLElement, dataId: string): void {
    super.applyId(element.querySelector('button'), dataId);
  }

  protected override getSubId(_element: HTMLElement): string {
    return this.elementPrefix;
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'find',
  pure: true
})
export class FindPipe<T> implements PipeTransform {
  public transform(value: string, items: T[], fieldName: keyof T = 'id' as keyof T): T | null {
    return items?.find((item: T) => item[fieldName] === value) || null;
  }
}

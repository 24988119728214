import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ProjectsLookupService } from '../services';
import { ProjectsLookupEffects } from '../store/projects-lookup.effects';
import { projectsLookupReducer } from '../store/projects-lookup.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature('projectsLookup', projectsLookupReducer),
    EffectsModule.forFeature([ProjectsLookupEffects])
  ],
  providers: [ProjectsLookupService],
  exports: []
})
export class ProjectsLookupApiModule {}

import { Params } from '@angular/router';
import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppRouterState } from '../models';

const selectRouterReducerState =
  createFeatureSelector<RouterReducerState<AppRouterState>>('router');

const selectRouterState = createSelector(
  selectRouterReducerState,
  (router: RouterReducerState<AppRouterState>) => router && router.state
);

export const selectRouteParams = createSelector(
  selectRouterState,
  (route: AppRouterState) => route && route.params
);

export const selectRouteParam = <T = string>(param: string) =>
  createSelector(selectRouteParams, (params: Params): T | undefined => params?.[param] as T);

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BasicStorageService } from './basic-storage.service';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService extends BasicStorageService {
  constructor(@Inject(DOCUMENT) doc: Document) {
    super(doc.defaultView.localStorage);
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'share-file-uploading',
  templateUrl: './file-uploading.component.html',
  styleUrls: ['./file-uploading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploadingComponent {
  @Input()
  public fileName: string;

  @Input()
  public progress$: Observable<number>;

  @Input()
  public actionLabel = 'Uploading';

  @Output()
  public readonly cancelUpload = new EventEmitter<void>();

  public onCancelUpload(): void {
    this.cancelUpload.emit();
  }
}

import { AfterViewInit, Directive, ElementRef, Input, ViewContainerRef } from '@angular/core';
import { SatPopoverComponent } from './popover.component';
import { getInvalidPopoverError, getInvalidSatPopoverAnchorError } from './popover.errors';

/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle */

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[satPopoverAnchor]',
  exportAs: 'satPopoverAnchor'
})
export class SatPopoverAnchorDirective implements AfterViewInit {
  @Input('satPopoverAnchor')
  get popover() {
    return this._popover;
  }
  set popover(val: SatPopoverComponent) {
    if (val instanceof SatPopoverComponent) {
      val.anchor = this;
    } else {
      // when a directive is added with no arguments,
      // angular assigns `''` as the argument
      if (val !== '') {
        throw getInvalidPopoverError();
      }
    }
  }

  public _popover: SatPopoverComponent;

  constructor(
    public elementRef: ElementRef,
    public viewContainerRef: ViewContainerRef
  ) {}

  public ngAfterViewInit(): void {
    if (!this.popover) {
      throw getInvalidSatPopoverAnchorError();
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'name',
  pure: true
})
export class NamePipe implements PipeTransform {
  public transform(item: { name: string }): string {
    return item?.name || 'N/A';
  }
}

import { createAction } from '@ngrx/store';
import { Email, User } from '@neuralegion/api';
import { ChangePasswordPayload } from '../models';

export const changePassword = createAction(
  '[Profile] CHANGE_PASSWORD',
  (payload: ChangePasswordPayload) => ({
    payload
  })
);

export const changePasswordFail = createAction(
  '[Profile] CHANGE_PASSWORD_FAIL',
  (payload: string) => ({
    payload,
    parentType: changePassword.type
  })
);

export const changePasswordSuccess = createAction('[Profile] CHANGE_PASSWORD_SUCCESS', () => ({
  parentType: changePassword.type
}));

export const loadProfile = createAction('[Profile] LOAD');

export const loadProfileFail = createAction('[Profile] LOAD_FAIL', (payload: string) => ({
  payload,
  parentType: loadProfile.type
}));

export const loadProfileSuccess = createAction('[Profile] LOAD_SUCCESS', (payload: User) => ({
  payload,
  parentType: loadProfile.type
}));

export const setPassword = createAction('[Profile] SET_PASSWORD', (payload: Email) => ({
  payload
}));

export const setPasswordFail = createAction('[Profile] SET_PASSWORD_FAIL', (payload: string) => ({
  payload,
  parentType: setPassword.type
}));

export const setPasswordSuccess = createAction('[Profile] SET_PASSWORD_SUCCESS', () => ({
  parentType: setPassword.type
}));

export const updateProfile = createAction('[Profile] UPDATE', (payload: Partial<User>) => ({
  payload
}));

export const updateProfileFail = createAction('[Profile] UPDATE_FAIL', (payload: string) => ({
  payload,
  parentType: updateProfile.type
}));

export const updateProfileSuccess = createAction('[Profile] UPDATE_SUCCESS', () => ({
  parentType: updateProfile.type
}));

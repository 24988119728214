import { ListRange } from '@angular/cdk/collections';
import { CdkScrollable, CdkVirtualScrollViewport, ScrollDispatcher } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  Observable,
  Subject,
  debounceTime,
  filter,
  first,
  map,
  merge,
  startWith,
  takeUntil,
  withLatestFrom
} from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { SnackbarService, trackById } from '@neuralegion/core';
import {
  FeedActivity,
  clear,
  clearAll,
  clearSuccess,
  loadFeedNext,
  markAllAsSeen,
  markAllAsSeenSuccess,
  markAsSeen,
  selectEndReached,
  selectFeedActivities,
  selectLastSuccess,
  selectPending
} from '@neuralegion/feed-api';
import { ConfirmModalService } from '@neuralegion/share';

@Component({
  selector: 'feed-panel',
  templateUrl: './feed-panel.component.html',
  styleUrls: ['./feed-panel.component.scss'],
  preserveWhitespaces: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedPanelComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(CdkVirtualScrollViewport, { static: true })
  private readonly virtualScroll: CdkVirtualScrollViewport;

  @Output()
  private readonly closeMenu = new EventEmitter<void>();

  private readonly BOTTOM_THRESHOLD = 100;
  private readonly SCROLL_DEBOUNCE_TIME = 250;
  private readonly SEEN_DEBOUNCE_TIME = 2000;
  private readonly INTERSECTION_THRESHOLD = 0.8;

  private readonly gc = new Subject<void>();
  public feedActivities$: Observable<FeedActivity[]>;
  public pending$: Observable<boolean>;
  private endReached$: Observable<boolean>;

  public debugEnabled = false;

  public readonly trackById = trackById;

  constructor(
    private readonly ngZone: NgZone,
    private readonly store: Store,
    private readonly confirmModalService: ConfirmModalService,
    private readonly scrollDispatcher: ScrollDispatcher,
    private readonly snackbarService: SnackbarService
  ) {}

  public ngOnInit(): void {
    this.pending$ = this.store.select(selectPending);

    // copy is required for cdk-virtual-scroll-viewport
    this.feedActivities$ = this.store
      .select(selectFeedActivities)
      .pipe(
        map(
          (activities: FeedActivity[]) =>
            activities && activities.map((activity: FeedActivity) => ({ ...activity }))
        )
      );

    this.endReached$ = this.store.select(selectEndReached);

    this.store
      .select(selectLastSuccess)
      .pipe(
        filter((action: Action): boolean => action && action.type === markAllAsSeenSuccess.type),
        takeUntil(this.gc)
      )
      .subscribe(() => {
        this.snackbarService.open('All feed items have been marked as seen');
      });
  }

  public ngAfterViewInit(): void {
    const scrolled$: Observable<CdkScrollable | void> = this.scrollDispatcher.scrolled(200);

    merge(
      scrolled$,
      this.store
        .select(selectLastSuccess)
        .pipe(filter((action: Action): boolean => action && action.type === clearSuccess.type))
    )
      .pipe(
        debounceTime(this.SCROLL_DEBOUNCE_TIME),
        filter(() => this.virtualScroll.measureScrollOffset('bottom') < this.BOTTOM_THRESHOLD),
        map(() => true),
        withLatestFrom(this.endReached$),
        filter(([, endReached]: [boolean, boolean]) => !endReached),
        takeUntil(this.gc)
      )
      .subscribe(() => {
        // https://material.angular.io/cdk/scrolling/api | scrolled
        this.ngZone.run(() => {
          this.store.dispatch(loadFeedNext());
        });
      });

    const container = this.virtualScroll.getElementRef().nativeElement;
    scrolled$
      .pipe(
        startWith(null as CdkScrollable),
        debounceTime(this.SEEN_DEBOUNCE_TIME),
        withLatestFrom(this.virtualScroll.renderedRangeStream, this.feedActivities$),
        takeUntil(this.gc)
      )
      .subscribe(([, listRange, activities]: [CdkScrollable | void, ListRange, FeedActivity[]]) => {
        const newlyVisibleActivityIds: string[] = Array.from(
          container.querySelectorAll('mat-card')
        ).reduce((indices: string[], el: Element, idx: number): string[] => {
          if (this.isElementFullyVisible(el, container)) {
            const activity: FeedActivity = activities[listRange.start + idx];
            if (!activity.isSeen) {
              indices.push(activity.id);
            }
          }
          return indices;
        }, []);
        if (newlyVisibleActivityIds.length) {
          // https://material.angular.io/cdk/scrolling/api | scrolled
          this.ngZone.run(() => {
            this.store.dispatch(markAsSeen(newlyVisibleActivityIds));
          });
        }
      });
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }

  private isElementFullyVisible(
    el: Element,
    container: Element,
    intersectionThreshold = this.INTERSECTION_THRESHOLD
  ): boolean {
    const elRect = el.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();
    return (
      containerRect.bottom >= elRect.bottom - elRect.height * (1 - intersectionThreshold) &&
      containerRect.top <= elRect.top
    );
  }

  public markAllAsSeen(): void {
    this.store.dispatch(markAllAsSeen());
  }

  public clear(id: string): void {
    this.store.dispatch(clear(id));
  }

  public clearAll(): void {
    this.confirmModalService
      .confirm('Do you really want to clear all feed items?')
      .pipe(first(), filter(Boolean), takeUntil(this.gc))
      .subscribe(() => this.store.dispatch(clearAll()));
  }

  public onCloseClick(): void {
    this.closeMenu.emit();
  }

  public onFeedItemClick(event: MouseEvent): void {
    if (event.target instanceof HTMLAnchorElement) {
      this.closeMenu.emit();
    }
  }
}

<button *ngIf="entity" class="text-button ghost-blur" mat-button [matMenuTriggerFor]="menu">
  <span class="multi-icons-button">
    <mat-icon>content_copy</mat-icon>
    <span>Copy {{ entityLiteral | lowercase }} as</span>
    <mat-icon>arrow_drop_down</mat-icon>
  </span>
</button>
<mat-menu #menu="matMenu">
  <button *ngIf="entityTypeRequest" mat-menu-item (click)="copyAsCurl()">Copy as сURL</button>
  <button mat-menu-item (click)="copyRaw()">Copy as raw {{ entityLiteral }}</button>
  <button *ngIf="entityTypeRequest" mat-menu-item (click)="copyURL()">Copy URL</button>
  <button *ngIf="entity.headers && !emptyHeaders" mat-menu-item (click)="copyHeaders()">Copy Headers</button>
  <button *ngIf="entity.body && !bodyBinary" mat-menu-item (click)="copyBody()">Copy Body</button>
</mat-menu>

import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { Repeater } from '../models';

export interface RepeatersState extends EntityState<Repeater> {
  error?: string;
  pending: Action[];
}

export const repeatersEntityAdapter: EntityAdapter<Repeater> = createEntityAdapter<Repeater>({
  selectId: (model) => model.id
});

export const repeatersInitialState: RepeatersState = repeatersEntityAdapter.getInitialState({
  details: null,
  error: null,
  pending: []
});

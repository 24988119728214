import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ComponentStore } from '@ngrx/component-store';
import equal from 'fast-deep-equal/es6';
import { ColumnConfig } from '@neuralegion/api';
import { TableSettingsService } from '@neuralegion/browser-storage';
import { toIdsMap } from '@neuralegion/lang';
import { ColumnWidthValue } from './column-width-value';
import { ColumnsConfigMigration } from './columns-config-migration';

interface ColumnsConfigState {
  config: ColumnConfig[];
}

@Injectable()
export class ColumnsConfigStore extends ComponentStore<ColumnsConfigState> {
  public readonly activeColumnWidths$: Observable<ColumnWidthValue[]> = this.select(
    (state: ColumnsConfigState) => this.getColumnWidthValuesForActiveColumns(state.config)
  );

  public readonly activeColumnIds$: Observable<string[]> = this.activeColumnWidths$.pipe(
    map((columnWidthValues: ColumnWidthValue[]) =>
      columnWidthValues.map((c: ColumnWidthValue) => c.id)
    )
  );

  public readonly columnConfig$: Observable<ColumnConfig[]> = this.select(
    (state: ColumnsConfigState) => state.config
  );

  get configSnapshot(): ColumnConfig[] {
    return this.get().config;
  }

  private defaultColumnConfig: ColumnConfig[];
  private storageId: string;

  constructor(private readonly tableSettingsService: TableSettingsService) {
    super();
  }

  public init(
    storageId: string,
    defaultColumnConfig: ColumnConfig[],
    migration?: ColumnsConfigMigration
  ): void {
    this.defaultColumnConfig = defaultColumnConfig;
    this.storageId = storageId;
    this.loadConfigFromStorage(migration);
  }

  public updateColumnWidths(updatedWidths: ColumnWidthValue[]): void {
    const columnsConfig: ColumnConfig[] = this.get((state: ColumnsConfigState) => state.config).map(
      (columnConfig: ColumnConfig) => ({
        ...columnConfig,
        width: updatedWidths.find((v) => columnConfig.id === v.id)?.width || 0
      })
    );

    if (
      !equal(
        columnsConfig,
        this.get((state: ColumnsConfigState) => state.config)
      )
    ) {
      this.updateColumnConfig(columnsConfig);
    }
  }

  public resetColumnConfig(): void {
    this.updateColumnConfig(this.defaultColumnConfig);
  }

  public updateColumnConfig(config: ColumnConfig[]): void {
    this.setState({ config });
    this.saveConfigToStorage(config);
  }

  private saveConfigToStorage(config: ColumnConfig[]): void {
    this.tableSettingsService.saveColumnsConfig(this.storageId, config);
  }

  private loadConfigFromStorage(migration?: ColumnsConfigMigration): void {
    let columnsConfig: ColumnConfig[] = this.tableSettingsService.getColumnsConfig(this.storageId);

    if (columnsConfig && migration) {
      columnsConfig = migration.migrate(columnsConfig);
    }

    this.setState({
      config: this.isConfigValid(columnsConfig)
        ? this.mergeConfigs(columnsConfig, this.defaultColumnConfig)
        : this.defaultColumnConfig
    });
  }

  private isConfigValid(columnsConfig?: ColumnConfig[]): boolean {
    if (!columnsConfig) {
      return false;
    }

    const columnsConfigHashMap = toIdsMap(columnsConfig);
    return (
      this.defaultColumnConfig?.length === columnsConfig.length &&
      this.defaultColumnConfig.every((defaultConfig: ColumnConfig, index: number) => {
        return (
          columnsConfigHashMap.has(defaultConfig.id) &&
          (!defaultConfig.fixed ||
            columnsConfig.findIndex((config: ColumnConfig) => config.id === defaultConfig.id) ===
              index)
        );
      })
    );
  }

  private getColumnWidthValuesForActiveColumns(config: ColumnConfig[]): ColumnWidthValue[] {
    return config
      .filter((c: ColumnConfig) => c.active)
      .map((c: ColumnConfig) => ({ id: c.id, width: c.width }));
  }

  private mergeConfigs(
    loadedConfig: ColumnConfig[],
    defaultConfig: ColumnConfig[]
  ): ColumnConfig[] {
    const columnsMap = toIdsMap(defaultConfig);
    return loadedConfig.map((column: ColumnConfig) => ({
      ...column,
      label: (columnsMap.get(column.id) || {}).label,
      fixed: (columnsMap.get(column.id) || {}).fixed
    }));
  }
}

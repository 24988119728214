import { BaseElementIdStrategy } from './base-element-id-strategy';

export class MatListOptionElementIdStrategy extends BaseElementIdStrategy {
  protected elementPrefix = ['list', 'option'].join(this.SEPARATOR_INTERNAL);
  protected containerElementPrefix = ['list', 'option', 'container'].join(this.SEPARATOR_INTERNAL);

  public override applyId(element: HTMLElement, dataId: string): void {
    super.applyId(element, dataId);

    // cases when mat-list-option is wrapped into container with extra elements,
    // that need option index too for own inner elements
    if (element.parentElement.tagName !== 'MAT-SELECTION-LIST') {
      super.applyId(
        element.parentElement,
        [dataId, this.containerElementPrefix, this.getOptionIndex(element)].join(
          this.SEPARATOR_INTERNAL
        )
      );
    }
  }

  protected override getSubId(element: HTMLElement): string {
    return [this.elementPrefix, this.getOptionIndex(element)].join(this.SEPARATOR_INTERNAL);
  }

  private getOptionIndex(element: HTMLElement): string {
    return `${[
      ...element.closest('mat-selection-list').querySelectorAll('mat-list-option')
    ].indexOf(element)}`;
  }
}

import { BaseElementIdStrategy } from './base-element-id-strategy';

export class MatExpansionPanelElementIdStrategy extends BaseElementIdStrategy {
  private readonly elementPrefix = ['expansion', 'panel'].join(this.SEPARATOR_INTERNAL);
  private readonly elementHeaderPrefix = ['expansion', 'panel', 'header'].join(
    this.SEPARATOR_INTERNAL
  );

  public override applyId(element: HTMLElement, dataId: string): void {
    super.applyId(element, dataId);
    super.applyId(this.getHeaderElement(element), this.getHeaderElementId(dataId));
  }

  protected override getSubId(element: HTMLElement): string {
    return [this.elementPrefix, this.getInnerText(this.getHeaderElement(element))].join(
      this.SEPARATOR_INTERNAL
    );
  }

  private getInnerText(element: HTMLElement): string {
    return this.generateIdByText(
      this.getElementText(
        element,
        (node: Node) =>
          node instanceof HTMLElement &&
          (node.tagName === 'MAT-ICON' ||
            ['timestamp', 'status'].some((className) => node.classList.contains(className)))
      )
    );
  }

  private getHeaderElement(element: HTMLElement): HTMLElement {
    return element.querySelector('mat-expansion-panel-header');
  }

  private getHeaderElementId(elementId: string): string {
    const pattern = new RegExp(this.elementPrefix, 'y');
    pattern.lastIndex = elementId.lastIndexOf(this.elementPrefix);
    return elementId.replace(pattern, this.elementHeaderPrefix);
  }
}

import { Directive, HostListener, Input } from '@angular/core';
import { SatPopoverComponent } from '../sat-popover';

@Directive({
  selector: '[sharePopoverTrigger]'
})
export class PopoverTriggerDirective {
  @Input('sharePopoverTrigger')
  public popover: SatPopoverComponent;

  @HostListener('click')
  public openPopoverWithoutAutofocus(): void {
    this.popover.open({ autoFocus: false, restoreFocus: false });
  }

  @HostListener('keydown.space', ['$event'])
  @HostListener('keydown.enter', ['$event'])
  public openPopoverWithAutofocus(event: KeyboardEvent): void {
    event.preventDefault();
    this.popover.open();
  }
}

<ng-container *ngIf="{ namedErrors: namedErrors$ | async } as sync">
  <share-note *ngIf="sync.namedErrors?.length > 0" [type]="'error'" [withHeader]="true">
    Some of the settings in
    <ng-container
      *ngFor="
        let error of sync.namedErrors;
        trackBy: trackByIdentity;
        let first = first;
        let last = last;
        let count = count
      ">
      <ng-container *ngIf="count > 1 && !last && !first">, </ng-container>
      <ng-container *ngIf="count > 1 && last"> and </ng-container>

      <button class="link link-internal" (click)="onClick(error)">
        {{ error.name }}
      </button>
    </ng-container>
    are incorrect.
  </share-note>
</ng-container>

import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { Store } from '@ngrx/store';
import { updateRequired } from '../store';

@Injectable()
export class LoadChunkErrorHandler implements ErrorHandler {
  constructor(private readonly injector: Injector) {}

  public handleError(error: Error): void {
    if (/Loading chunk .+ failed/.test(error.message)) {
      this.injector.get(NgZone).run(() => {
        this.injector.get(Store).dispatch(updateRequired());
      });
    }
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, exhaustMap, map, of, tap } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { InviteDetails } from '@neuralegion/api';
import { SnackbarService, activateLayout, deactivateLayout } from '@neuralegion/core';
import { SignupService } from '../services';
import {
  acceptInvite,
  acceptInviteFail,
  acceptInviteSuccess,
  loadInviteDetails,
  loadInviteDetailsFail,
  loadInviteDetailsSuccess,
  signup,
  signupFail,
  signupSuccess
} from './signup.actions';

@Injectable()
export class SignupEffects {
  public readonly acceptInvite$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(acceptInvite),
      exhaustMap((action: ReturnType<typeof acceptInvite>) =>
        this.signupService.acceptInvite(action.payload.memberId, action.payload.token).pipe(
          map(() => acceptInviteSuccess()),
          catchError((err: HttpErrorResponse) => of(acceptInviteFail(err.error)))
        )
      )
    )
  );

  public readonly signupSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(signupSuccess),
        tap((action: ReturnType<typeof signupSuccess>) => {
          const { email, emailConfirmed } = action.payload.user ?? {};

          return !email || emailConfirmed
            ? this.router.navigate(['/login'])
            : this.router.navigate(['/signup/success', action.payload.registrationData.email]);
        })
      ),
    { dispatch: false }
  );

  public readonly signupFail$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(signupFail),
        tap((action: ReturnType<typeof signupFail>) => {
          this.snackbarService.open(action.payload, true, { timeOut: 10000 });
        })
      ),
    { dispatch: false }
  );

  public readonly loadInviteDetails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadInviteDetails),
      exhaustMap((action: ReturnType<typeof loadInviteDetails>) =>
        this.signupService.loadInviteDetails(action.payload.memberId, action.payload.token).pipe(
          map((inviteDetails: InviteDetails) => loadInviteDetailsSuccess(inviteDetails)),
          catchError((err: HttpErrorResponse) => of(loadInviteDetailsFail(err.error)))
        )
      )
    )
  );

  public readonly signup$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(signup),
      exhaustMap((action: ReturnType<typeof signup>) =>
        this.signupService.register(action.payload).pipe(
          map((res) => signupSuccess({ registrationData: action.payload, user: res })),
          catchError((err: HttpErrorResponse) => of(signupFail(err.error)))
        )
      )
    )
  );

  public readonly deactivateLayout$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<Action>(signup, loadInviteDetails, acceptInvite),
      exhaustMap(() => [deactivateLayout()])
    )
  );

  public readonly activateLayout$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<Action>(
        signupFail,
        signupSuccess,
        loadInviteDetailsFail,
        loadInviteDetailsSuccess,
        acceptInviteFail,
        acceptInviteSuccess
      ),
      exhaustMap(() => [activateLayout()])
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly snackbarService: SnackbarService,
    private readonly signupService: SignupService,
    private readonly router: Router
  ) {}
}

import { createAction } from '@ngrx/store';
import { FeedData, FeedSubscription } from '../models';

export const openFeed = createAction('[Feed] OPEN_FEED');

export const closeFeed = createAction('[Feed] CLOSE_FEED');

export const toggleFeed = createAction('[Feed] TOGGLE_FEED');

export const loadFeedStart = createAction('[Feed] LOAD_FEED_START');

export const loadFeedNext = createAction('[Feed] LOAD_FEED_NEXT');

export const loadFeedNextSuccess = createAction(
  '[Feed] LOAD_FEED_NEXT_SUCCESS',
  (payload: FeedData) => ({
    payload,
    parentType: loadFeedNext.type
  })
);

export const loadFeedNextFail = createAction('[Feed] LOAD_FEED_NEXT_FAIL', (payload: string) => ({
  payload,
  parentType: loadFeedNext.type
}));

export const markAsSeen = createAction('[Feed] MARK_AS_SEEN', (payload: string[]) => ({ payload }));

export const markAsSeenSuccess = createAction(
  '[Feed] MARK_AS_SEEN_SUCCESS',
  (payload: string[]) => ({
    payload,
    parentType: markAsSeen.type
  })
);

export const markAsSeenFail = createAction('[Feed] MARK_AS_SEEN_FAIL', (payload: string) => ({
  payload,
  parentType: markAsSeen.type
}));

export const markAllAsSeen = createAction('[Feed] MARK_ALL_AS_SEEN');

export const markAllAsSeenSuccess = createAction('[Feed] MARK_ALL_AS_SEEN_SUCCESS', () => ({
  parentType: markAllAsSeen.type
}));

export const markAllAsSeenFail = createAction(
  '[Feed] MARK_ALL_AS_SEEN_FAIL',
  (payload: string) => ({
    payload,
    parentType: markAllAsSeen.type
  })
);

export const clear = createAction('[Feed] CLEAR', (payload: string) => ({ payload }));

export const clearSuccess = createAction('[Feed] CLEAR_SUCCESS', (payload: string) => ({
  payload,
  parentType: clear.type
}));

export const clearFail = createAction('[Feed] CLEAR_FAIL', (payload: string) => ({
  payload,
  parentType: clear.type
}));

export const clearAll = createAction('[Feed] CLEAR_ALL');

export const clearAllSuccess = createAction('[Feed] CLEAR_ALL_SUCCESS', () => ({
  parentType: clearAll.type
}));

export const clearAllFail = createAction('[Feed] CLEAR_ALL_FAIL', (payload: string) => ({
  payload,
  parentType: clearAll.type
}));

export const loadSubscriptions = createAction('[Feed] LOAD_SUBSCRIPTIONS');

export const loadSubscriptionsSuccess = createAction(
  '[Feed] LOAD_SUBSCRIPTIONS_SUCCESS',
  (payload: FeedSubscription[]) => ({ payload, parentType: loadSubscriptions.type })
);

export const loadSubscriptionsFail = createAction(
  '[Feed] LOAD_SUBSCRIPTIONS_FAIL',
  (payload: string) => ({
    payload,
    parentType: loadSubscriptions.type
  })
);

export const updateSubscriptions = createAction(
  '[Feed] UPDATE_SUBSCRIPTIONS',
  (payload: FeedSubscription[]) => ({
    payload
  })
);

export const updateSubscriptionsSuccess = createAction(
  '[Feed] UPDATE_SUBSCRIPTIONS_SUCCESS',
  (payload: FeedSubscription[]) => ({ payload, parentType: updateSubscriptions.type })
);

export const updateSubscriptionsFail = createAction(
  '[Feed] UPDATE_SUBSCRIPTIONS_FAIL',
  (payload: string) => ({
    payload,
    parentType: updateSubscriptions.type
  })
);

import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TableSortConfig } from '@neuralegion/api';

@Injectable({
  providedIn: 'root'
})
export class SortSerializationService {
  public convertToHttpParams(sort: TableSortConfig | null): HttpParams | null {
    if (!sort) {
      return null;
    }

    return new HttpParams().set('sortBy', sort.column).set('orderBy', sort.direction);
  }
}

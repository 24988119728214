import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ThemeName, ThemeService } from '@neuralegion/core';

@Component({
  selector: 'share-theme-switcher',
  templateUrl: './theme-switcher.component.html',
  styleUrls: ['./theme-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThemeSwitcherComponent implements OnInit {
  public readonly ThemeName = ThemeName;

  public currentTheme: ThemeName;

  constructor(
    private readonly themeService: ThemeService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.currentTheme = this.themeService.getCurrentTheme();
  }

  public switch(theme: ThemeName): void {
    this.themeService.switchTheme(theme);
    this.currentTheme = theme;
    this.cdr.detectChanges();
  }

  public toggle(): void {
    this.switch(this.currentTheme === ThemeName.DEFAULT ? ThemeName.DARK : ThemeName.DEFAULT);
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ProviderType } from '@neuralegion/api';
import { trackByIdentity } from '@neuralegion/core';

@Component({
  selector: 'auth-sso-providers-list',
  templateUrl: './sso-providers-list.component.html',
  styleUrls: ['./sso-providers-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SsoProvidersListComponent {
  @Input()
  public providers: ProviderType[];

  @Input()
  public btnPrefix = '';

  @Output()
  public readonly selected = new EventEmitter<ProviderType>(true);

  public readonly trackByIdentity = trackByIdentity;

  public onProviderClick(provider: ProviderType): void {
    this.selected.emit(provider);
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SectionRequiredCounters } from '../../services';
import { SettingsError } from '../settings-error-bar/settings-error-bar.component';

export enum SectionStatus {
  SUCCESS = 'success',
  WARNING = 'warning',
  PRISTINE_WARNING = 'pristine-warning'
}

@Component({
  selector: 'share-settings-tab-label',
  templateUrl: './settings-tab-label.component.html',
  styleUrls: ['./settings-tab-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsTabLabelComponent<S> {
  @Input()
  public section: S;

  @Input()
  public name: string;

  @Input()
  public counters: SectionRequiredCounters;

  @Input()
  public error: SettingsError<S>;

  @Input()
  public visited: boolean;

  public readonly SectionStatus = SectionStatus;
}

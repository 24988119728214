import { Pipe, PipeTransform } from '@angular/core';
import { Board, ServiceName } from '@neuralegion/api';

@Pipe({
  name: 'repositoryName',
  pure: true
})
export class RepositoryNamePipe implements PipeTransform {
  public transform(value: Board): string {
    if (!value) {
      return null;
    }
    switch (value.service) {
      case ServiceName.GITHUB:
        return `${value.name} (default branch)`;
      default:
        return value.name;
    }
  }
}

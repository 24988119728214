import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ResetPasswordPayload } from '@neuralegion/api';

@Component({
  selector: 'auth-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetPasswordFormComponent {
  @Output()
  public readonly submitted = new EventEmitter<ResetPasswordPayload>();

  public readonly form: FormGroup = new FormGroup({
    password: new FormControl('', [Validators.required])
  });

  public submit(): void {
    this.submitted.emit(this.form.value);
  }
}

import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AuthenticationCredentials,
  LoggedInUser,
  LogoutResponse,
  ProviderType,
  SsoConfig,
  Token
} from '@neuralegion/api';
import { IGNORE_ERROR_INTERCEPTOR_TOKEN } from '@neuralegion/error-handler';

@Injectable()
export class BaseService {
  constructor(private readonly http: HttpClient) {}

  public login(data: AuthenticationCredentials): Observable<Token> {
    return this.http.post<Token>('/api/v1/auth/login', data, {
      withCredentials: true,
      context: new HttpContext().set(IGNORE_ERROR_INTERCEPTOR_TOKEN, true)
    });
  }

  public refreshToken(): Observable<Token> {
    return this.http.post<Token>(
      '/api/v1/auth/token',
      {},
      {
        withCredentials: true,
        context: new HttpContext().set(IGNORE_ERROR_INTERCEPTOR_TOKEN, true)
      }
    );
  }

  public loadSSOProviders({
    slug,
    token
  }: { slug: string; token?: string } | { token: string; slug?: string }): Observable<SsoConfig> {
    return slug
      ? this.http.get<SsoConfig>(`/api/v1/sso/idps/${slug}`)
      : this.http.post<SsoConfig>('/api/v1/sso/idps', { token });
  }

  public oauthLogin(providerType: ProviderType, params: HttpParams): Observable<Token> {
    return this.http.get<Token>(`/api/v1/auth/login/${providerType}/callback`, {
      params,
      withCredentials: true,
      context: new HttpContext().set(IGNORE_ERROR_INTERCEPTOR_TOKEN, true)
    });
  }

  public oauthDisconnect(providerType: ProviderType): Observable<void> {
    return this.http.delete<void>(`/api/v1/auth/logout/${providerType}`);
  }

  public createRequiredOrg(organization: {
    name: string;
    marketplaceCallbackState?: string;
  }): Observable<void> {
    return this.http.post<void>('/api/v1/me/org', organization);
  }

  public loadUserInfo(): Observable<LoggedInUser> {
    return this.http.get<LoggedInUser>('/api/v1/me', {
      withCredentials: true
    });
  }

  public logout(): Observable<LogoutResponse> {
    return this.http.post<LogoutResponse>(
      '/api/v1/auth/logout',
      {},
      {
        withCredentials: true,
        context: new HttpContext().set(IGNORE_ERROR_INTERCEPTOR_TOKEN, true)
      }
    );
  }
}

<ng-container *ngIf="health | isNumber; else tmplNotAvailable">
  <span
    [ngClass]="{
      'health-poor': health <= HEALTH_POOR_THRESHOLD,
      'health-fair': health > HEALTH_POOR_THRESHOLD && health <= HEALTH_GOOD_THRESHOLD,
      'health-good': health > HEALTH_GOOD_THRESHOLD
    }">
    {{ health }}%
  </span>
</ng-container>

<ng-template #tmplNotAvailable>N/A</ng-template>

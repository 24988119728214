import { Action } from '@ngrx/store';
import { BillingSummary, Product } from '../models';

export interface BillingState {
  pending: Action[];
  error: string;
  products: Product[];
  summary: BillingSummary;
}

export const initialBillingState: BillingState = {
  pending: [],
  error: null,
  products: null,
  summary: null
};

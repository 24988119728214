import { AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';

/*
 * Extract a touched changed observable from an abstract control
 * Based on: https://github.com/angular/angular/issues/10887#issuecomment-547392548
 * Relates to https://github.com/angular/angular/pull/32806
 *
 * @param control AbstractControl
 */
export const extractTouchedChanges = (control: AbstractControl): Observable<boolean> => {
  return new Observable<boolean>((observer) => {
    const originalMarkAsTouched = control.markAsTouched;
    const originalMarkAsUntouched = control.markAsUntouched;

    control.markAsTouched = (...args) => {
      observer.next(true);
      originalMarkAsTouched.call(control, ...args);
    };

    control.markAsUntouched = (...args) => {
      observer.next(false);
      originalMarkAsUntouched.call(control, ...args);
    };

    observer.next(control.touched);

    return () => {
      control.markAsTouched = originalMarkAsTouched;
      control.markAsUntouched = originalMarkAsUntouched;
    };
  });
};

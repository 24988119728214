import { Action, createReducer, on } from '@ngrx/store';
import { cleanupPendingState } from '@neuralegion/core';
import {
  loadOrganization,
  loadOrganizationFail,
  loadOrganizationSuccess,
  loadQuotas,
  loadQuotasFail,
  loadQuotasSuccess,
  updateOrganization,
  updateOrganizationFail,
  updateOrganizationSuccess
} from './organization.actions';
import { UserOrganizationState, initialUserOrganizationState } from './organization.state';

export const organizationReducer = createReducer<UserOrganizationState, Action>(
  initialUserOrganizationState,
  on(
    loadOrganization,
    loadQuotas,
    updateOrganization,
    (state: UserOrganizationState, action): UserOrganizationState => ({
      ...state,
      pending: [...state.pending, action],
      error: null
    })
  ),
  on(
    loadOrganizationSuccess,
    (state: UserOrganizationState, action): UserOrganizationState =>
      cleanupPendingState(
        {
          ...state,
          entity: action.payload.organization
        },
        action
      )
  ),
  on(
    loadQuotasSuccess,
    (state: UserOrganizationState, action): UserOrganizationState =>
      cleanupPendingState(
        {
          ...state,
          quotas: action.payload.quotas
        },
        action
      )
  ),
  on(
    updateOrganizationSuccess,
    (state: UserOrganizationState, action): UserOrganizationState =>
      cleanupPendingState(state, action)
  ),

  on(
    loadOrganizationFail,
    loadQuotasFail,
    updateOrganizationFail,
    (state: UserOrganizationState, action): UserOrganizationState =>
      cleanupPendingState(state, action, action.payload)
  )
);

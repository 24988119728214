import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserConsents } from '@neuralegion/api';

@Injectable()
export class UserConsentsService {
  constructor(private readonly http: HttpClient) {}

  public updateConsents(consent: Partial<UserConsents>): Observable<void> {
    return this.http.patch<void>('/api/v1/me/consents', consent);
  }
}

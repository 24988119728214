import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MfaQrCode } from '@neuralegion/api';
import { MultiFactorState } from './multi-factor.state';

// eslint-disable-next-line @typescript-eslint/ban-types
type State = object;
const selectMfaState = createFeatureSelector<MultiFactorState>('mfa');

export const selectMfaQrCode = createSelector<State, [MultiFactorState], MfaQrCode>(
  selectMfaState,
  (state: MultiFactorState) => state.qrCode
);

export const selectMfaPendingStatus = createSelector<State, [MultiFactorState], boolean>(
  selectMfaState,
  (state: MultiFactorState) => state.pending
);

<ng-container *ngIf="filterConfig.type === FilterType.SELECT">
  <mat-form-field class="compact-field" fxFlex="1 1 auto">
    <mat-select
      *ngIf="{
        available: selectFilter?.availableItems,
        all: selectFilterSettings.items
      } as items"
      [aria-label]="'Select ' + filterConfig.name"
      [formControl]="formControl">
      <mat-option *ngFor="let item of items.available || items.all; trackBy: trackByValue" [value]="item.value">
        {{ item.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-container>

<ng-container *ngIf="filterConfig.type === FilterType.ASYNC_SELECT">
  <mat-form-field class="compact-field" fxFlex="1 1 auto">
    <mat-select
      *ngIf="{
        available: selectFilter?.availableItems$,
        all: selectFilterSettings.items$
      } as items"
      [aria-label]="'Select ' + filterConfig.name"
      [formControl]="formControl">
      <mat-option
        *ngFor="
          let item of items.available || items.all
            | asyncSelectFilterItems: filters : selectFilterSettings.itemsFilterFn
            | async;
          trackBy: trackByValue
        "
        [value]="item.value">
        {{ item.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-container>

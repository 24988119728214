import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, exhaustMap, map, of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Session } from '@neuralegion/api';
import { ProfileService } from '../services';
import { loadSessions, loadSessionsFail, loadSessionsSuccess } from './sessions.actions';

@Injectable()
export class SessionsEffects {
  public readonly loadSessions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSessions),
      exhaustMap(() =>
        this.profileService.loadSessions().pipe(
          map((res: Session[]) => loadSessionsSuccess(res)),
          catchError((err: HttpErrorResponse) => of(loadSessionsFail(err.error)))
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly profileService: ProfileService
  ) {}
}

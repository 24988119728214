import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'share-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoteComponent {
  @Input()
  public type: 'notice' | 'error' = 'notice';

  @Input()
  public withHeader = false;

  @Input()
  public withIcon = true;

  @Input()
  public title: string;
}

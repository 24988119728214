interface ElapsedTimeDiff {
  ms?: number;
  s?: number;
  m?: number;
  h?: number;
  d?: number;
}

export class ElapsedTime {
  private readonly millisecondsRaw: number;

  private readonly seconds: number;
  private readonly minutes: number;
  private readonly hours: number;
  private readonly days: number;

  constructor(value: string | number) {
    this.preValidateElapsedTime(value);

    this.millisecondsRaw = +value;
    let deltaSeconds = Math.round(this.millisecondsRaw / 1000);

    this.days = Math.floor(deltaSeconds / 86400);
    deltaSeconds -= this.days * 86400;

    this.hours = Math.floor(deltaSeconds / 3600) % 24;
    deltaSeconds -= this.hours * 3600;

    this.minutes = Math.floor(deltaSeconds / 60) % 60;
    deltaSeconds -= this.minutes * 60;

    this.seconds = deltaSeconds;
  }

  public asMilliseconds(): number {
    return this.millisecondsRaw;
  }

  public add(diff: ElapsedTimeDiff): ElapsedTime {
    return new ElapsedTime(
      this.millisecondsRaw +
        (diff.ms || 0) +
        (diff.s || 0) * 1000 +
        (diff.m || 0) * 1000 * 60 +
        (diff.h || 0) * 1000 * 3600 +
        (diff.d || 0) * 1000 * 86400
    );
  }

  public getSeconds(): number {
    return this.seconds;
  }

  public getMinutes(): number {
    return this.minutes;
  }

  public getHours(): number {
    return this.hours;
  }

  public getDays(): number {
    return this.days;
  }

  private preValidateElapsedTime(value: string | number): never | void {
    if (Number.isNaN(+value)) {
      throw new Error(`Elapsed time expected to be number, received: ${value}`);
    }
  }
}

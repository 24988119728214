<div [className]="'mode-' + mode" [ngSwitch]="mode">
  <span class="container" [class.preserve-spaces]="separator === '\n'">
    <ng-container *ngFor="let item of visibleItems; last as last; trackBy: trackByIdentity">
      <ng-container *ngTemplateOutlet="itemTmpl || noItemTmpl; context: { $implicit: item }" />
      <ng-container *ngIf="!last">{{ separator }}</ng-container>
    </ng-container>
  </span>

  <ng-container *ngSwitchCase="'expand'">
    <button
      *ngIf="!expanded && allItems.length > limit"
      class="link link-internal btn-more"
      [attr.aria-label]="'Show more'"
      (click)="expand($event)"
      (mousedown)="$event.stopPropagation()">
      +{{ moreItems.length }} more
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="'overlay'">
    <button
      *ngIf="moreItems.length > 0"
      class="btn-more"
      [attr.aria-label]="'Show more'"
      color="primary"
      mat-button
      [satPopoverAnchor]="popover"
      [sharePopoverTrigger]="popover">
      <span class="multi-icons-button">+{{ moreItems.length }} more<mat-icon>arrow_drop_down</mat-icon></span>
    </button>

    <sat-popover #popover xAlign="center" yAlign="below">
      <div class="popover-container focus-ring" tabindex="0" [attr.data-id]="'overlay_show_more_array'">
        <div
          *ngFor="let item of moreItems; let idx = index; trackBy: trackByIdentity"
          class="item"
          [element-data-id]="'item-' + idx">
          <ng-container *ngTemplateOutlet="itemTmpl || noItemTmpl; context: { $implicit: item }" />
        </div>
      </div>
    </sat-popover>
  </ng-container>
</div>

<ng-template #noItemTmpl let-item>{{ item }}</ng-template>

import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable, filter, map } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectCodeInstrumentationPermission } from '../store';

@Injectable()
export class CodeInstrumentationGuard {
  constructor(
    private readonly router: Router,
    private readonly store: Store
  ) {}

  public canActivate(): Observable<boolean | UrlTree> {
    return this.store.select(selectCodeInstrumentationPermission).pipe(
      filter((allowed) => typeof allowed === 'boolean'),
      map((allowed) => (!allowed ? this.router.parseUrl('/') : true))
    );
  }

  public canMatch(): Observable<boolean | UrlTree> {
    return this.canActivate();
  }
}

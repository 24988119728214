import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn
} from '@angular/forms';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger
} from '@angular/material/autocomplete';
import { Subject, distinctUntilChanged, filter, first, map, takeUntil } from 'rxjs';
import equal from 'fast-deep-equal/es6';
import { trackByIdentity } from '@neuralegion/core';
import { extractTouchedChanges } from '../../form';
import { ConfirmModalService } from '../../services';

export interface EditableSelectSettings<T> {
  readonly label: string;
  readonly placeholder: string;
  readonly validators: ValidatorFn[];
  readonly itemsRemovable: boolean;
  readonly removeItemMessage: string;
  readonly tmplItem: TemplateRef<unknown>;
  readonly createItem: (value: string) => T;
  readonly itemsEqual: (a: T, b: T) => boolean;
  readonly displayFn: (item: T) => string;
}

export interface EditableSelectValue<T> {
  readonly selected: T;
  readonly items: T[];
}

@Component({
  selector: 'share-editable-select',
  templateUrl: './editable-select.component.html',
  styleUrls: ['./editable-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: EditableSelectComponent, multi: true },
    { provide: NG_VALIDATORS, useExisting: EditableSelectComponent, multi: true }
  ]
})
export class EditableSelectComponent<T>
  implements OnInit, OnDestroy, ControlValueAccessor, Validator
{
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('options')
  public userOptions: Partial<EditableSelectSettings<T>>;

  @ViewChild('matAutocompleteTrigger')
  public readonly matAutocompleteTrigger: MatAutocompleteTrigger;

  public options: Partial<EditableSelectSettings<T>> = {
    placeholder: 'Select an item',
    validators: [],
    itemsRemovable: false,
    removeItemMessage: 'Are you sure you want to delete this item?',
    createItem: (value: string) => value as unknown as T,
    itemsEqual: (a: T, b: T) => a === b,
    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    displayFn: (item: T) => item?.toString() ?? ''
  };

  public readonly inputControl = new FormControl(null, { updateOn: 'blur' });
  public items: T[] = [];

  public readonly trackByIdentity = trackByIdentity;

  private readonly valueSubject = new Subject<EditableSelectValue<T>>();
  private readonly gc = new Subject<void>();

  private onChange: (value: EditableSelectValue<T>) => void;
  private onTouched: () => void;

  constructor(private readonly confirmModalService: ConfirmModalService) {}

  public ngOnInit() {
    this.options = {
      ...this.options,
      ...this.userOptions
    };

    this.inputControl.addValidators(this.options.validators);

    this.initValueSubjectListener();
    this.initControlListener();
    this.initTouchedListener();
  }

  public ngOnDestroy() {
    this.gc.next();
    this.gc.complete();
  }

  public registerOnChange(fn: (value: EditableSelectValue<T>) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.inputControl.disable();
    } else {
      this.inputControl.enable();
    }
  }

  public writeValue(value: EditableSelectValue<T>): void {
    this.valueSubject.next(value);
  }

  public validate(_control: AbstractControl): ValidationErrors | null {
    return this.inputControl.valid ? null : { editableSelect: true };
  }

  public onItemSelected(event: MatAutocompleteSelectedEvent): void {
    this.inputControl.setValue(event.option.value);
  }

  public onEnterPressed(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      (event.target as HTMLInputElement).blur();
      this.matAutocompleteTrigger.closePanel();
    }
  }

  public onDeleteClick(event: Event, option: T): void {
    event.stopPropagation();

    this.confirmModalService
      .confirm(this.options.removeItemMessage)
      .pipe(first(), filter(Boolean), takeUntil(this.gc))
      .subscribe(() => {
        this.deleteOption(option);
      });
  }

  public onClearClick(): void {
    this.inputControl.setValue(this.options.createItem(''));

    if (!this.matAutocompleteTrigger.panelOpen) {
      this.matAutocompleteTrigger.openPanel();
    }
  }

  private initControlListener(): void {
    this.inputControl.valueChanges
      .pipe(
        map((value: string | T): T => {
          const item: T = this.getSelectedItem(value);
          return this.items.find((option: T) => this.options.itemsEqual(option, item)) ?? item;
        }),
        distinctUntilChanged(equal),
        takeUntil(this.gc)
      )
      .subscribe((value: T) => {
        this.onChange?.({ selected: value, items: this.items });
      });
  }

  private initTouchedListener(): void {
    extractTouchedChanges(this.inputControl)
      .pipe(
        filter((touched) => touched),
        takeUntil(this.gc)
      )
      .subscribe(() => {
        this.onTouched?.();
      });
  }

  private initValueSubjectListener(): void {
    this.valueSubject
      .pipe(
        filter(
          (value: EditableSelectValue<T>) =>
            !this.options.itemsEqual(
              this.getSelectedItem(this.inputControl.value),
              value?.selected
            ) || !equal(this.items, value?.items)
        ),
        distinctUntilChanged(equal),
        takeUntil(this.gc)
      )
      .subscribe((value: EditableSelectValue<T>) => {
        this.inputControl.setValue(value.selected);
        this.items = value.items;
      });
  }

  private deleteOption(option: T): void {
    const items: T[] = this.items.filter((o: T) => !equal(o, option));
    const selected: T = this.getSelectedItem(this.inputControl.value);

    this.items = items;

    if (this.options.itemsEqual(selected, option)) {
      this.onClearClick();
    } else {
      this.onChange({ selected, items });
    }
  }

  private getSelectedItem(value: string | T): T {
    return typeof value === 'string' ? this.options.createItem(value) : value;
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import {
  BehaviorSubject,
  Observable,
  distinct,
  filter,
  map,
  pairwise,
  scan,
  withLatestFrom
} from 'rxjs';

export interface SectionRequiredCounters {
  required: number;
  validRequired: number;
}

export type SettingsSectionsRequiredCounters<S extends number | string> = Record<
  S,
  SectionRequiredCounters
>;
export type SettingsFormServiceSection<S extends string | number> = S | `${S}${string}`;

@Injectable()
export class SettingsFormService<T extends string | number> implements OnDestroy {
  public readonly activeSection$: Observable<T>;
  public readonly sectionRequiredCounters$: Observable<SettingsSectionsRequiredCounters<T>>;
  public readonly visitedSections$: Observable<ReadonlySet<T>>;
  public readonly revisitedSection$: Observable<T>;

  private readonly activeSectionSubject = new BehaviorSubject<T>(null);
  private readonly sectionCountersSubject = new BehaviorSubject<
    SettingsSectionsRequiredCounters<T>
  >(null);
  private readonly sectionsControlsMap = new Map<SettingsFormServiceSection<T>, AbstractControl>();

  constructor() {
    this.visitedSections$ = this.activeSectionSubject.pipe(
      pairwise(),
      map(([prev]) => prev),
      filter((prev) => prev != null),
      scan((visitedSections, section) => new Set([...visitedSections, section]), new Set<T>())
    );

    this.revisitedSection$ = this.activeSectionSubject.pipe(
      withLatestFrom(this.visitedSections$),
      filter(([section, sections]) => sections.has(section)),
      map(([section]) => section),
      distinct()
    );

    this.sectionRequiredCounters$ = this.sectionCountersSubject.asObservable();
    this.activeSection$ = this.activeSectionSubject.asObservable();
  }

  public ngOnDestroy(): void {
    this.sectionsControlsMap.clear();
  }

  public attachControl(section: SettingsFormServiceSection<T>, control: AbstractControl): void {
    this.sectionsControlsMap.set(section, control);
  }

  public detachNestedSections(section: SettingsFormServiceSection<T>): void {
    this.sectionsControlsMap.forEach(
      (_: AbstractControl, nestedSection: SettingsFormServiceSection<T>) => {
        if (this.isSubsection(section, nestedSection)) {
          this.sectionsControlsMap.delete(nestedSection);
        }
      }
    );
  }

  public onSectionChange(activeSection: T): void {
    this.activeSectionSubject.next(activeSection);
  }

  public updateSectionCountersState(section: SettingsFormServiceSection<T>): void {
    this.sectionCountersSubject.next({
      ...this.sectionCountersSubject.value,
      [section]: this.calculateSectionCounters(section)
    });
  }

  private calculateSectionCounters(
    section: SettingsFormServiceSection<T>
  ): SectionRequiredCounters {
    return [...this.sectionsControlsMap]
      .filter(([key, _]: [SettingsFormServiceSection<T>, AbstractControl]) =>
        this.isSubsection(section, key)
      )
      .map(([_, control]: [SettingsFormServiceSection<T>, AbstractControl]) => control)
      .reduce(
        (counters: SectionRequiredCounters, control: AbstractControl): SectionRequiredCounters =>
          control.hasValidator(Validators.required)
            ? {
                required: counters.required + 1,
                validRequired: counters.validRequired + (control.invalid ? 0 : 1)
              }
            : counters,
        { required: 0, validRequired: 0 }
      );
  }

  private isSubsection(
    section: SettingsFormServiceSection<T>,
    nestedSection: SettingsFormServiceSection<T>
  ): boolean {
    return nestedSection === section || String(nestedSection).startsWith(`${section}.`);
  }
}

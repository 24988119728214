import { Pipe, PipeTransform } from '@angular/core';
import { AttackParamLocation, attackParamLocationsComparator } from '@neuralegion/api';

@Pipe({
  name: 'attackParamLocation',
  pure: true
})
export class AttackParamLocationPipe implements PipeTransform {
  public transform(
    value: AttackParamLocation | AttackParamLocation[],
    customHeadersExist: boolean = false
  ): string | string[] {
    if (Array.isArray(value)) {
      const sortedValue = [...value].sort(attackParamLocationsComparator);

      return sortedValue.reduce((res: string[], item: AttackParamLocation) => {
        const formatted = this.format(item, customHeadersExist);
        return [
          ...res,
          ...(item === AttackParamLocation.HEADER && customHeadersExist
            ? [formatted, 'Headers (custom)']
            : [formatted])
        ];
      }, []);
    }

    return this.format(value, customHeadersExist);
  }

  private format(attackParamLocation: AttackParamLocation, customHeaders: boolean): string {
    switch (attackParamLocation) {
      case AttackParamLocation.ARTIFICAL_FRAGMENT:
        return 'Artificial URL Fragment';
      case AttackParamLocation.ARTIFICAL_QUERY:
        return 'Artificial URL Query';
      case AttackParamLocation.BODY:
        return 'Body';
      case AttackParamLocation.HEADER:
        return customHeaders ? 'Headers (standard)' : 'Headers';
      case AttackParamLocation.URL_FRAGMENT:
        return 'URL Fragment';
      case AttackParamLocation.URL_PATH:
        return 'URL Path';
      case AttackParamLocation.URL_QUERY:
        return 'URL Query';
      default:
        return attackParamLocation;
    }
  }
}

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CookieAttributes, get, remove, set } from 'es-cookie';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PlainObject = any;

export interface ClientStorageOptions {
  expiresIn: number;
}

@Injectable({
  providedIn: 'root'
})
export class CookieStorageService {
  public readonly [Symbol.toStringTag]: 'CookieStorageService';
  private readonly changesSubject: Subject<string> = new Subject<string>();

  private readonly cookieAttributes?: CookieAttributes;

  constructor(@Inject(DOCUMENT) doc: Document) {
    if (doc.defaultView.location.protocol === 'https:') {
      this.cookieAttributes = {
        secure: true,
        sameSite: 'none'
      };
    }
  }

  public delete(key: string): boolean {
    const cookieAttributes: CookieAttributes = {
      ...(this.cookieAttributes ?? {}),
      expires: new Date(0)
    };
    remove(key, cookieAttributes);
    return this.has(key);
  }

  public get(key: string): PlainObject {
    return this.parseJSON(get(key));
  }

  public has(key: string): boolean {
    return this.get(key) != null;
  }

  public set(key: string, value: PlainObject, options?: ClientStorageOptions): this {
    const cookieAttributes: CookieAttributes = {
      ...(this.cookieAttributes ?? {}),
      expires: options.expiresIn
    };
    set(key, this.serializeObject(value), cookieAttributes);
    this.changesSubject.next(key);
    return this;
  }

  private parseJSON(json: string): PlainObject {
    try {
      return JSON.parse(json);
    } catch (e) {
      return undefined;
    }
  }

  private serializeObject(obj: PlainObject): string | undefined {
    try {
      return JSON.stringify(obj);
    } catch (e) {
      return undefined;
    }
  }
}

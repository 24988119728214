import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BillingContactInfo } from '../models';

@Injectable()
export class BillingContactInfoService {
  constructor(private readonly http: HttpClient) {}

  public saveBillingContactInfo(contactInfo: BillingContactInfo): Observable<void> {
    return this.http.put<void>('/api/v1/billing/contact', contactInfo);
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UploaderService } from '@neuralegion/core';
import { StorageFile } from '../models';
import { StorageService } from './storage.service';

@Injectable()
export class StorageFileUploaderService extends UploaderService<StorageFile> {
  constructor(protected readonly storageService: StorageService) {
    super();
  }

  public revert(fileId: string, keepAsNew: boolean): Observable<StorageFile> {
    return this.storageService.revertRevision(fileId, keepAsNew);
  }
}

import { Injectable } from '@angular/core';
import { isBinary } from '@neuralegion/core';
import { Code } from './code';
import { CodeEscaperService } from './code-escaper.service';
import { HexFormatResult } from './code-hex-lines.service';
import { CodeHexPrettifyService } from './code-hex-prettify.service';
import { CodeHighlightService, HighlightOptions } from './code-highlight.service';
import { CodeReformatService, ReformatOptions } from './code-reformat.service';
import { DiffService } from './diff.service';

export interface PrettifyResultFlags {
  binary: boolean;
  reformatEnabled: boolean;
  diffEnabled: boolean;
  highlightEnabled: boolean;
}

export interface PrettifyResult extends PrettifyResultFlags {
  code: string;
  hexLines?: HexFormatResult[];
}

export interface PrettifyOptions {
  reformat?: ReformatOptions;
  highlight?: HighlightOptions;
  thresholdReformat?: number;
  thresholdDiff?: number;
  thresholdDiffEntries?: number;
  thresholdHighlight?: number;
  binaryDetection?: boolean;
  hexForBinary?: boolean;
  hexHtml?: boolean;
}

const defaultOptions = {
  reformat: {},
  highlight: {},
  thresholdReformat: 1024 * 1024, // reformat will be skipped for code larger than 1MB
  thresholdDiff: 1024 * 1024, // diff detection will be skipped for code larger than 1MB
  thresholdDiffEntries: 1024, // diff markup displaying will be skipped if there are more than 1024 differences
  thresholdHighlight: 300 * 1024, // highlighting will be skipped for code larger than 300KB
  binaryDetection: true, // reformat and highlight will be disabled for detected binary data
  hexForBinary: true, // hex presentation will be used for detected binary data; binaryDetection must be true
  hexHtml: false // produce html for binary data instead of hexLines objects (pdf report vs virtual scroll)
};

/**
 * Reformats, diffs and highlights code
 */
@Injectable({
  providedIn: 'root'
})
export class CodePrettifyService {
  constructor(
    private readonly codeReformatService: CodeReformatService,
    private readonly codeHighlightService: CodeHighlightService,
    private readonly codeHexPrettifyService: CodeHexPrettifyService,
    private readonly codeEscaperService: CodeEscaperService,
    private readonly diffService: DiffService
  ) {}

  /**
   * Format code snippet as HTML
   *
   * @param code - the code snippet to format with optional diff base; should be not HTML encoded yet
   * @param [options] - options for reformat, highlight and prettify
   * @returns Observable<string> - Observable of prettified code:
   *  with escaping of initial HTML entities, reformatted and augmented with highlighting and diffing results markup
   */
  public async prettify(code: Code, options?: PrettifyOptions): Promise<PrettifyResult> {
    const opts: PrettifyOptions = {
      ...defaultOptions,
      ...(options || {})
    };

    const binary =
      opts.binaryDetection && (isBinary(code.actual || '') || isBinary(code.previous || ''));
    const flags: PrettifyResultFlags = {
      binary: binary && opts.hexForBinary,
      reformatEnabled:
        !binary &&
        code.actual &&
        code.actual.length < opts.thresholdReformat &&
        (!code.previous || code.previous.length < opts.thresholdReformat),
      highlightEnabled: !binary && code.actual && code.actual.length < opts.thresholdHighlight,
      diffEnabled:
        code.actual &&
        code.actual.length < opts.thresholdDiff &&
        typeof code.previous === 'string' &&
        code.previous.length < opts.thresholdDiff
    };

    if (flags.binary) {
      return this.codeHexPrettifyService.prettify(code, opts, flags);
    }

    const reformattedCode: Code = this.escape(
      flags.reformatEnabled ? await this.reformat(code, opts.reformat) : code
    );

    const res: PrettifyResult = {
      ...flags,
      diffEnabled: flags.diffEnabled,
      code: flags.diffEnabled
        ? await this.diff(reformattedCode, opts.thresholdDiffEntries)
        : reformattedCode.actual
    };

    const highlightEnabled =
      flags.highlightEnabled && res.code && code.actual.length < opts.thresholdHighlight;
    return {
      ...res,
      highlightEnabled,
      ...(highlightEnabled
        ? { code: await this.codeHighlightService.highlight(res.code, opts.highlight) }
        : {})
    };
  }

  private async reformat(code: Code, reformatOptions: ReformatOptions): Promise<Code> {
    return {
      actual: await this.codeReformatService.reformat(code.actual, reformatOptions),
      previous: await this.codeReformatService.reformat(code.previous, reformatOptions)
    };
  }

  private escape(code: Code): Code {
    return {
      actual: this.codeEscaperService.escapeHtml(code.actual),
      previous: this.codeEscaperService.escapeHtml(code.previous)
    };
  }

  private async diff(code: Code, thresholdDiffEntries: number): Promise<string> {
    const diffResult: [number, string][] = await this.diffService.diff(code);
    const diffSuccess = !!diffResult && diffResult.length <= thresholdDiffEntries;
    return diffSuccess ? this.diffService.diffResultToHtml(diffResult) : code.actual;
  }
}

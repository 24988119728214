import { BaseElementIdStrategy } from './base-element-id-strategy';

export class EmptyIdStrategy extends BaseElementIdStrategy {
  public override calculateId(): string {
    return '';
  }

  protected override getSubId(): string {
    return '';
  }
}

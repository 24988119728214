import { Pipe, PipeTransform, Type } from '@angular/core';
import { FilterType } from '@neuralegion/api';
import {
  DateRangeFilterControlComponent,
  ExternalFiltrationSelectComponent,
  NumberCompareFilterControlComponent,
  SelectFilterControlComponent,
  TableTextFilterControlComponent
} from '../components';
import { FilterControl } from '../models';

@Pipe({
  name: 'filterControl',
  pure: true
})
export class FilterControlPipe implements PipeTransform {
  public transform(filterType: FilterType): Type<FilterControl> {
    switch (filterType) {
      case FilterType.TEXT:
        return TableTextFilterControlComponent;
      case FilterType.DATE_RANGE:
        return DateRangeFilterControlComponent;
      case FilterType.SELECT:
      case FilterType.ASYNC_SELECT:
        return SelectFilterControlComponent;
      case FilterType.NUMBER_COMPARE:
        return NumberCompareFilterControlComponent;
      case FilterType.EXTERNAL_FILTRATION_SELECT:
        return ExternalFiltrationSelectComponent;
      default:
        return null;
    }
  }
}

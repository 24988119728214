import { createAction } from '@ngrx/store';
import { InviteDetails, SignedUpUser } from '@neuralegion/api';
import { RegistrationForm } from '../models';

export const acceptInvite = createAction(
  '[Signup] ACCEPT_INVITE',
  (payload: { memberId: string; token: string }) => ({
    payload
  })
);

export const acceptInviteFail = createAction('[Signup] ACCEPT_INVITE_FAIL', (payload: string) => ({
  payload,
  parentType: acceptInvite.type
}));

export const acceptInviteSuccess = createAction('[Signup] ACCEPT_INVITE_SUCCESS', () => ({
  parentType: acceptInvite.type
}));

export const loadInviteDetails = createAction(
  '[Signup] LOAD_INVITE_DETAILS',
  (payload: { memberId: string; token: string }) => ({ payload })
);

export const loadInviteDetailsFail = createAction(
  '[Signup] LOAD_INVITE_DETAILS_FAIL',
  (payload: string) => ({
    payload,
    parentType: loadInviteDetails.type
  })
);

export const loadInviteDetailsSuccess = createAction(
  '[Signup] LOAD_INVITE_DETAILS_SUCCESS',
  (payload: InviteDetails) => ({ payload, parentType: loadInviteDetails.type })
);

export const signup = createAction('[Signup] SIGNUP', (payload: RegistrationForm) => ({ payload }));

export const signupFail = createAction('[Signup] SIGNUP_FAIL', (payload: string) => ({
  payload,
  parentType: signup.type
}));

export const signupSuccess = createAction(
  '[Signup] SIGNUP_SUCCESS',
  (payload: { registrationData: RegistrationForm; user: SignedUpUser | null }) => ({
    payload,
    parentType: signup.type
  })
);

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <p>
    The organization is your main working space, where you can perform scans, set up projects and more. You can also
    invite new users to your own organization for easy collaboration.
  </p>
  <p><b>Note:</b> You will be able to change the organization name later if you want.</p>
  <mat-form-field>
    <mat-label>Organization name</mat-label>
    <input matInput required shareAutofocus [formControlName]="'name'" />
    <mat-error [matErrorOptions]="{ fieldName: 'Organization name' }"></mat-error>
  </mat-form-field>

  <button color="primary" mat-raised-button [disabled]="!form.valid">Create</button>
</form>

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { PageRefreshOptions, PageRefreshService } from '@neuralegion/core';

@Component({
  selector: 'share-page-refresh-button',
  templateUrl: './page-refresh-button.component.html',
  styleUrls: ['./page-refresh-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageRefreshButtonComponent implements OnInit {
  public attached$: Observable<PageRefreshOptions | null>;
  public pending$: Observable<boolean>;

  constructor(private readonly pageRefreshService: PageRefreshService) {}

  public ngOnInit(): void {
    this.attached$ = this.pageRefreshService.attached$;

    this.pending$ = this.pageRefreshService.attached$.pipe(
      switchMap((options: PageRefreshOptions | null) =>
        options?.pending$ != null ? options.pending$ : of(false)
      )
    );
  }

  public refresh(): void {
    this.pageRefreshService.refresh();
  }
}

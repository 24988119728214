import { Action, createReducer, on } from '@ngrx/store';
import { cleanupPending, cleanupPendingState } from '@neuralegion/core';
import {
  loadPredefinedProject,
  loadPredefinedProjectFail,
  loadPredefinedProjectSuccess,
  loadProjects,
  loadProjectsFail,
  loadProjectsSuccess,
  resetProjects
} from './projects-lookup.actions';
import {
  ProjectsLookupState,
  projectsLookupEntityAdapter,
  projectsLookupInitialState
} from './projects-lookup.state';

export const projectsLookupReducer = createReducer<ProjectsLookupState, Action>(
  projectsLookupInitialState,
  on(
    loadProjects,
    loadPredefinedProject,
    (state: ProjectsLookupState, action): ProjectsLookupState => ({
      ...state,
      pending: [...state.pending, action],
      error: null
    })
  ),
  on(
    loadProjectsSuccess,
    (state: ProjectsLookupState, action): ProjectsLookupState =>
      projectsLookupEntityAdapter.upsertMany(action.payload, {
        ...state,
        error: null,
        pending: cleanupPending(state.pending, action.parentType, false)
      })
  ),
  on(
    loadPredefinedProjectSuccess,
    (state: ProjectsLookupState, action): ProjectsLookupState =>
      action.payload
        ? projectsLookupEntityAdapter.addOne(action.payload, cleanupPendingState(state, action))
        : cleanupPendingState(state, action)
  ),
  on(
    loadProjectsFail,
    loadPredefinedProjectFail,
    (state: ProjectsLookupState, action): ProjectsLookupState =>
      cleanupPendingState(state, action, action.payload)
  ),
  on(resetProjects, (): ProjectsLookupState => projectsLookupInitialState)
);

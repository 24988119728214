import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ComponentStore } from '@ngrx/component-store';
import { TabConfig } from '@neuralegion/api';
import { TabViewSettingsService } from '@neuralegion/browser-storage';
import { toIdsMap } from '@neuralegion/lang';
import { STORAGE_ID_PREFIX } from '../../models';

interface TabsConfigState {
  config: TabConfig[];
}

@Injectable()
export class TabsConfigStore extends ComponentStore<TabsConfigState> {
  public readonly tabConfig$: Observable<TabConfig[]> = this.select(
    (state: TabsConfigState) => state.config
  );

  public readonly activeTabIds$: Observable<string[]> = this.select((state: TabsConfigState) =>
    state.config.filter(({ active }) => !!active).map(({ id }) => id)
  );

  get configSnapshot(): TabConfig[] {
    return this.get().config;
  }

  private defaultTabConfig: TabConfig[];

  constructor(
    private readonly tabGroupSettingsService: TabViewSettingsService,
    @Inject(STORAGE_ID_PREFIX) private readonly storageIdPrefix: string
  ) {
    super();
  }

  public init(defaultTabConfig: TabConfig[]): void {
    this.defaultTabConfig = defaultTabConfig;
    this.loadConfigFromStorage();
  }

  public resetTabConfig(): void {
    this.updateTabConfig(this.defaultTabConfig);
  }

  public updateTabConfig(config: TabConfig[]): void {
    this.setState({ config });
    this.saveConfigToStorage(config);
  }

  private saveConfigToStorage(config: TabConfig[]): void {
    this.tabGroupSettingsService.saveTabsConfig(this.storageIdPrefix, config);
  }

  private loadConfigFromStorage(): void {
    const tabsConfig: TabConfig[] = this.tabGroupSettingsService.getTabsConfig(
      this.storageIdPrefix
    );

    this.setState({
      config: this.isConfigValid(tabsConfig)
        ? this.mergeConfigs(tabsConfig, this.defaultTabConfig)
        : this.defaultTabConfig
    });
  }

  private isConfigValid(tabsConfig?: TabConfig[]): boolean {
    if (!tabsConfig) {
      return false;
    }

    const tabsConfigHashMap = toIdsMap(tabsConfig);
    return (
      this.defaultTabConfig?.length === tabsConfig.length &&
      this.defaultTabConfig.every((defaultConfig: TabConfig, index: number) => {
        return (
          tabsConfigHashMap.has(defaultConfig.id) &&
          (!defaultConfig.fixed ||
            tabsConfig.findIndex((config: TabConfig) => config.id === defaultConfig.id) === index)
        );
      })
    );
  }

  private mergeConfigs(loadedConfig: TabConfig[], defaultConfig: TabConfig[]): TabConfig[] {
    const tabsMap = toIdsMap(defaultConfig);
    return loadedConfig.map((tab: TabConfig) => ({
      ...tab,
      label: (tabsMap.get(tab.id) || {}).label,
      fixed: (tabsMap.get(tab.id) || {}).fixed
    }));
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { DateRange } from '@neuralegion/api';
import { SatPopoverComponent } from '../../sat-popover';

@Component({
  selector: 'share-date-range-filter',
  templateUrl: './date-range-filter.component.html',
  styleUrls: ['./date-range-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateRangeFilterComponent {
  @Input()
  public minDate: Date = null;

  @Input()
  public maxDate: Date = null;

  @Input()
  public placeholder = 'Choose date range...';

  @Input()
  public dateRange: DateRange;

  @Output()
  public readonly dateRangeChanged: EventEmitter<DateRange> = new EventEmitter<DateRange>();

  @ViewChild(SatPopoverComponent, { static: true })
  public popover: SatPopoverComponent;

  public onDateRangeChange(range: DateRange): void {
    this.dateRangeChanged.emit(range);
    this.popover.close();
  }

  public reset(): void {
    this.dateRangeChanged.emit(null);
    this.popover.close();
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AccessDeniedPageComponent,
  ClientErrorComponent,
  NotFoundPageComponent,
  NotImplementedPageComponent,
  ServerErrorPageComponent
} from '../components';

const routes: Routes = [
  { path: 'error', component: ClientErrorComponent },
  { path: '404', component: NotFoundPageComponent },
  { path: '403', component: AccessDeniedPageComponent },
  { path: '500', component: ServerErrorPageComponent },
  { path: '501', component: NotImplementedPageComponent },
  { path: '**', redirectTo: '/404', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ErrorsPagesRoutingModule {}

import { Pipe, PipeTransform } from '@angular/core';
import { formatDistance } from '@neuralegion/core';

@Pipe({
  name: 'duration',
  pure: true
})
export class DurationPipe implements PipeTransform {
  public transform(ms: number): string {
    const d = new Date();
    return formatDistance(d, new Date(d.getTime() + ms));
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserConsents } from '@neuralegion/api';
import { UserConsentsState } from './user-consents.state';

type State = object;

const selectUserConsentsState = createFeatureSelector<UserConsentsState>('user-consents');

export const selectConsents = createSelector<State, [UserConsentsState], UserConsents>(
  selectUserConsentsState,
  (state: UserConsentsState) => state.consents
);

export const selectUserConsentsPendingStatus = createSelector<State, [UserConsentsState], boolean>(
  selectUserConsentsState,
  (state: UserConsentsState) => state.pending.length > 0
);

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ClipboardService } from '@neuralegion/core';

@Component({
  selector: 'share-copy-menu-item',
  templateUrl: './copy-menu-item.component.html',
  styleUrls: ['./copy-menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CopyMenuItemComponent {
  @Input()
  public data: string;

  @Input()
  public itemName: string;

  @Input()
  public itemNameSuffix = 'ID';

  constructor(private readonly clipboardService: ClipboardService) {}

  public onCopyClick(): void {
    this.clipboardService.copyToClipboard(
      this.data,
      `${this.itemName.replace(/./, (a) => a.toUpperCase())} ${this.itemNameSuffix} copied`
    );
  }
}

export enum TaskRepeatIntervalUnit {
  HOURS = 'hours',
  DAYS = 'days',
  WEEKS = 'weeks',
  MONTHS = 'months',
  YEARS = 'years'
}

export const taskRepeatIntervalUnitSingle: Record<TaskRepeatIntervalUnit, string> = {
  [TaskRepeatIntervalUnit.HOURS]: 'hour',
  [TaskRepeatIntervalUnit.DAYS]: 'day',
  [TaskRepeatIntervalUnit.WEEKS]: 'week',
  [TaskRepeatIntervalUnit.MONTHS]: 'month',
  [TaskRepeatIntervalUnit.YEARS]: 'year'
};

import { BaseElementIdStrategy } from './base-element-id-strategy';

export class MatListItemElementIdStrategy extends BaseElementIdStrategy {
  protected elementPrefix = ['list', 'item'].join(this.SEPARATOR_INTERNAL);

  public override applyId(element: HTMLElement, dataId: string): void {
    super.applyId(element, dataId);

    const headerElement = this.getItemHeaderElement(element);
    const valueElement = headerElement?.nextElementSibling;
    if (valueElement?.tagName === 'SPAN') {
      super.applyId(valueElement as HTMLElement, [dataId, 'value'].join(this.SEPARATOR_EXTERNAL));
    }
  }

  protected override getSubId(element: HTMLElement): string {
    return [
      this.elementPrefix,
      this.generateIdByText(this.getItemHeaderElement(element)?.textContent || this.UNKNOWN_ID)
    ]
      .filter(Boolean)
      .join(this.SEPARATOR_INTERNAL);
  }

  private getItemHeaderElement(element: HTMLElement): HTMLElement {
    return element.querySelector('h3, h4, [role="heading"]');
  }
}

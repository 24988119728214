import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { PaymentMethod } from '../models';
import { PaymentMethodsState, paymentMethodsEntityAdapter } from './payment-methods.state';

// eslint-disable-next-line @typescript-eslint/ban-types
type State = object;
const selectPaymentMethodState = createFeatureSelector<PaymentMethodsState>('paymentMethods');

export const selectPaymentMethodById = (
  paymentMethodId: string
): MemoizedSelector<State, PaymentMethod> =>
  createSelector<State, [PaymentMethodsState], PaymentMethod>(
    selectPaymentMethodState,
    (state) => state.entities[paymentMethodId]
  );

export const selectPaymentMethods = createSelector<State, [PaymentMethodsState], PaymentMethod[]>(
  selectPaymentMethodState,
  paymentMethodsEntityAdapter.getSelectors().selectAll
);

export const selectDefaultPaymentMethodId = createSelector<State, [PaymentMethod[]], string>(
  selectPaymentMethods,
  (paymentMethods: PaymentMethod[]) =>
    paymentMethods.find((paymentMethod) => paymentMethod.defaultMethod)?.id
);

export const selectPaymentMethodsPending = createSelector<State, [PaymentMethodsState], boolean>(
  selectPaymentMethodState,
  (state: PaymentMethodsState) => state.pending?.length > 0
);

<ng-template>
  <div
    [@transformPopover]="{ value: state, params: params }"
    class="sat-popover-container"
    #focusTrapElement
    [ngClass]="_classList"
    (@transformPopover.done)="_onAnimationDone($event)">
    <ng-content></ng-content>
  </div>
</ng-template>

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ClipboardService } from '@neuralegion/core';

@Component({
  selector: 'share-copy-button',
  templateUrl: './copy-button.component.html',
  styleUrls: ['./copy-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CopyButtonComponent implements OnInit {
  @Input()
  public data: string;

  @Input()
  public itemName: string;

  @Input()
  public color?: ThemePalette;

  @Input()
  public title?: string;

  @Input()
  public copiedMessage?: string;

  @Input()
  public type?: 'icon' | 'text' = 'icon';

  @Output()
  public readonly copied = new EventEmitter<void>();

  public titleLabel: string;

  constructor(private readonly clipboardService: ClipboardService) {}

  public ngOnInit(): void {
    this.titleLabel = this.title ?? `Copy ${this.itemName}`;
  }

  public doCopy(e: Event): void {
    e.stopPropagation();
    this.clipboardService.copyToClipboard(
      this.data,
      this.copiedMessage ?? `${this.itemName.replace(/./, (a) => a.toUpperCase())} copied`
    );
    this.copied.emit();
  }
}

import { Action, MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { Group } from '@neuralegion/api';
import { GroupsState, groupsEntityAdapter } from './groups.state';
import { State } from './state';

export const selectGroupsState = createFeatureSelector<GroupsState>('groups');

export const selectGroups = createSelector<State, [GroupsState], Group[]>(
  selectGroupsState,
  groupsEntityAdapter.getSelectors().selectAll
);

export const selectMemberGroups = (memberId: string): MemoizedSelector<State, Group[]> =>
  createSelector<State, [Group[]], Group[]>(selectGroups, (groups: Group[]) =>
    groups.filter((item: Group) => item.members.includes(memberId))
  );

export const selectGroupById = (groupId: string): MemoizedSelector<State, Group> =>
  createSelector<State, [GroupsState], Group>(
    selectGroupsState,
    (state: GroupsState) => state.entities[groupId]
  );

export const selectGroupsPendingActions = createSelector<State, [GroupsState], Action[]>(
  selectGroupsState,
  (state: GroupsState) => state.pending
);

export const selectGroupsPendingStatus = createSelector<State, [Action[]], boolean>(
  selectGroupsPendingActions,
  (actions: Action[]) => actions?.length > 0
);

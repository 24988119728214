import { Pipe, PipeTransform } from '@angular/core';
import { ElapsedTime } from '@neuralegion/api';

@Pipe({
  name: 'elapsedTime',
  pure: true
})
export class ElapsedTimePipe implements PipeTransform {
  public transform(value: string | number, format: 'long' | 'short' | 'short_ms' = 'long'): string {
    return this.format(
      new ElapsedTime(value || 0),
      ['short', 'short_ms'].includes(format),
      format === 'short_ms'
    );
  }

  public format(elapsedTime: ElapsedTime, shorten = false, displayMs = false): string {
    const d = elapsedTime.getDays();
    const h = elapsedTime.getHours();
    const m = elapsedTime.getMinutes();
    const s = elapsedTime.getSeconds();
    const ms = elapsedTime.asMilliseconds();

    if (ms > 0 && ms < 1000 && displayMs) {
      return `${ms}ms`;
    }

    return shorten
      ? `${d ? `${d}d ` : ''}${d || h ? `${h}h ` : ''}${d || h || m ? `${m}m ` : ''}${s}s`
      : `${d}d ${h}h ${m}m ${s}s`;
  }
}

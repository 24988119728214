import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ReCaptcha2Component } from 'ngx-captcha';
import { AuthenticationCredentials } from '@neuralegion/api';
import { ThemeService } from '@neuralegion/core';

@Component({
  selector: 'auth-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginFormComponent implements AfterViewInit {
  @Input()
  public siteKey: string;

  @Input()
  public enableCaptcha = false;

  @Input()
  set pending(isPending: boolean) {
    if (isPending) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Output()
  public readonly submitted = new EventEmitter<AuthenticationCredentials>();

  @ViewChild('captchaElem')
  public captchaElem: ReCaptcha2Component;

  @ViewChild('emailElement', { read: ElementRef })
  public emailElementRef: ElementRef<HTMLInputElement>;

  public readonly darkTheme: boolean;

  public form: FormGroup;
  public captchaIsLoaded = false;
  public captchaIsExpired = false;
  public captchaSuccess = false;

  constructor(private readonly themeService: ThemeService) {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(64)]),
      password: new FormControl('', [Validators.required, Validators.maxLength(128)]),
      recaptcha: new FormControl('')
    });
    this.darkTheme = this.themeService.isDark();
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      const email = this.emailElementRef?.nativeElement?.value;
      if (this.form.getRawValue().email !== email) {
        this.form.get('email').setValue(email);
      }
    }, 0);
  }

  public handleExpire(): void {
    this.captchaIsExpired = true;
    this.captchaSuccess = false;
  }

  public handleSuccess(): void {
    this.captchaSuccess = true;
    this.captchaIsExpired = false;
  }

  public handleLoad(): void {
    this.captchaIsLoaded = true;
  }

  public handleReset(): void {
    this.captchaSuccess = false;
    this.form.patchValue({ recaptcha: '' });
  }

  public onSubmit(): void {
    this.submitted.emit(this.form.value);
    this.captchaElem?.resetCaptcha();
  }
}

import { Injectable } from '@angular/core';
import responseStatuses from './response-statuses.json';

export interface ResponseStatusMeta {
  code: string;
  message: string;
  description: string;
}

@Injectable({
  providedIn: 'root'
})
export class ResponseStatusesService {
  private readonly responseStatusesMetaMap = new Map(
    responseStatuses.map((status: ResponseStatusMeta) => [status.code, status])
  );

  public getStatusMetaByCode(code: string): ResponseStatusMeta | undefined {
    return this.responseStatusesMetaMap.get(code);
  }
}

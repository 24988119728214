import { createAction } from '@ngrx/store';
import {
  AuthenticationCredentials,
  Email,
  LoggedInUser,
  Name,
  ProviderType,
  SsoConfig
} from '@neuralegion/api';
import { AuthError } from '../models';

export const login = createAction('[Auth] LOGIN', (payload: AuthenticationCredentials) => ({
  payload
}));

export const loginSuccess = createAction('[Auth] LOGIN_SUCCESS');

export const loginFail = createAction('[Auth] LOGIN_FAIL', (payload: AuthError) => ({ payload }));

export const loginRedirect = createAction(
  '[Auth] LOGIN_REDIRECT',
  (payload?: { location?: string; notification?: string }) => ({
    payload: {
      location: '',
      ...(payload ?? {})
    }
  })
);

export const logout = createAction('[Auth] LOGOUT', (payload: { skipRouting: boolean }) => ({
  payload
}));

export const oauthFinalize = createAction(
  '[Auth] OAUTH_FINALIZE',
  (payload: {
    providerType: ProviderType;
    params: Record<string, string | readonly string[]>;
  }) => ({ payload })
);

export const oauthFinalizeSuccess = createAction('[Auth] OAUTH_FINALIZE_SUCCESS');

export const oauthFinalizeFail = createAction(
  '[Auth] OAUTH_FINALIZE_FAIL',
  (payload: AuthError) => ({ payload })
);

export const oauthDisconnect = createAction('[Auth] OAUTH_DISCONNECT', (payload: ProviderType) => ({
  payload
}));

export const oauthDisconnectSuccess = createAction('[Auth] OAUTH_DISCONNECT_SUCCESS');

export const oauthDisconnectFail = createAction(
  '[Auth] OAUTH_DISCONNECT_FAIL',
  (payload: string) => ({ payload })
);

export const ssoInit = createAction(
  '[Auth] SSO_INIT',
  (payload: { initiatedBy?: ProviderType } & ({ slug: string } | { token: string })) => ({
    payload
  })
);

export const ssoInitSuccess = createAction(
  '[Auth] SSO_INIT_SUCCESS',
  (payload: { sso: SsoConfig; initiatedBy?: ProviderType }) => ({ payload })
);

export const ssoInitFail = createAction(
  '[Auth] SSO_INIT_FAIL',
  (payload: { message: string; redirectToLogin: boolean }) => ({ payload })
);

export const createRequiredOrg = createAction(
  '[Auth] CREATE_REQUIRED_ORG',
  (payload: { name: string }) => ({
    payload
  })
);

export const createRequiredOrgSuccess = createAction('[Auth] CREATE_REQUIRED_ORG_SUCCESS', () => ({
  parentType: createRequiredOrg.type
}));

export const createRequiredOrgFail = createAction(
  '[Auth] CREATE_REQUIRED_ORG_FAIL',
  (payload: string) => ({
    payload,
    parentType: createRequiredOrg.type
  })
);

export const loadUserInfo = createAction(
  '[Auth] LOAD_USER_INFO',
  (payload: { skipNavigation: boolean; welcomeWizard?: boolean }) => ({ payload })
);

export const loadUserInfoSuccess = createAction(
  '[Auth] LOAD_USER_INFO_SUCCESS',
  (payload: { skipNavigation: boolean; userInfo: LoggedInUser; welcomeWizard?: boolean }) => ({
    payload,
    parentType: loadUserInfo.type
  })
);

export const loadUserInfoFail = createAction('[Auth] LOAD_USER_INFO_FAIL', (payload: string) => ({
  payload,
  parentType: loadUserInfo.type
}));

export const mfaRequired = createAction('[Auth] MFA_REQUIRED', (payload: { token: string }) => ({
  payload
}));

export const invalidOAuthEmail = createAction(
  '[Auth] INVALID_OAUTH_SIGNUP_EMAIL',
  (payload: { state: string }) => ({
    payload
  })
);

export const ssoRequired = createAction('[Auth] SSO_REQUIRED', (payload: { token: string }) => ({
  payload
}));

export const confirmRequired = createAction(
  '[Auth] CONFIRM_REQUIRED',
  (payload: { state: string; type: ProviderType } & Name & Email) => ({ payload })
);

export const activateOnboarding = createAction('[Auth] ACTIVATE_ONBOARDING');

export const deactivateOnboarding = createAction('[Auth] DEACTIVATE_ONBOARDING');

export const passwordChangeRequired = createAction(
  '[Auth] PASSWORD_CHANGE_REQUIRED',
  (payload: { token: string }) => ({
    payload
  })
);

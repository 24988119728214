import { createAction } from '@ngrx/store';
import { RepeaterNetworkPayload, RepeaterNetworkResult } from '../models';

export const runRepeaterTest = createAction(
  '[RepeaterTest] RUN',
  (payload: { repeaterId: string; test: RepeaterNetworkPayload }) => ({ payload })
);

export const runRepeaterTestSuccess = createAction(
  '[RepeaterTest] RUN_SUCCESS',
  (payload: RepeaterNetworkResult) => ({
    payload,
    parentType: runRepeaterTest.type
  })
);

export const runRepeaterTestFail = createAction('[RepeaterTest] RUN_FAIL', (payload: string) => ({
  payload,
  parentType: runRepeaterTest.type
}));

export const loadRepeaterTest = createAction(
  '[RepeaterTest] LOAD',
  (payload: { repeaterId: string }) => ({ payload })
);

export const loadRepeaterTestSuccess = createAction(
  '[RepeaterTest] LOAD_SUCCESS',
  (payload: RepeaterNetworkResult) => ({ payload, parentType: loadRepeaterTest.type })
);

export const loadRepeaterTestFail = createAction('[RepeaterTest] LOAD_FAIL', (payload: string) => ({
  payload,
  parentType: loadRepeaterTest.type
}));

export const pollRepeaterTest = createAction(
  '[RepeaterTest] POLL',
  (payload: { repeaterId: string }) => ({ payload })
);

export const pollRepeaterTestSuccess = createAction(
  '[RepeaterTest] POLL_SUCCESS',
  (payload: RepeaterNetworkResult) => ({ payload, parentType: pollRepeaterTest.type })
);

export const pollRepeaterTestFail = createAction('[RepeaterTest] POLL_FAIL', (payload: string) => ({
  payload,
  parentType: pollRepeaterTest.type
}));

export const updateRepeaterTest = createAction(
  '[RepeaterTest] UPDATE',
  (payload: { repeaterId: string; test: RepeaterNetworkPayload }) => ({ payload })
);

export const updateRepeaterTestSuccess = createAction(
  '[RepeaterTest] UPDATE_SUCCESS',
  (payload: RepeaterNetworkResult) => ({ payload, parentType: updateRepeaterTest.type })
);

export const updateRepeaterTestFail = createAction(
  '[RepeaterTest] UPDATE_FAIL',
  (payload: string) => ({
    payload,
    parentType: updateRepeaterTest.type
  })
);

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { JobStatus } from '@neuralegion/api';

@Component({
  selector: 'share-job-status-chip',
  templateUrl: './job-status-chip.component.html',
  styleUrls: ['./job-status-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobStatusChipComponent {
  @Input()
  public status: JobStatus;
}

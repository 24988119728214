import {
  AfterContentInit,
  ContentChildren,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { NgControl, ValidationErrors } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';
import { Subject, filter, map, startWith, takeUntil } from 'rxjs';
import { ValidationErrorCode } from '../models';
import { FormErrorOptions, FormErrorService } from '../services';
import { MatErrorOverrideDirective } from './mat-error-override.directive';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'mat-error',
  providers: [FormErrorService]
})
export class MatErrorTextDirective implements OnInit, OnDestroy, AfterContentInit {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('matErrorOptions')
  public externalOptions: FormErrorOptions;

  @ContentChildren(MatErrorOverrideDirective)
  public overrides: QueryList<MatErrorOverrideDirective>;

  private readonly gc = new Subject<void>();
  private readonly overridesTmplMap: Map<ValidationErrorCode, TemplateRef<{ $implicit: unknown }>> =
    new Map();

  private viewContainerRef: ViewContainerRef;
  private options: FormErrorOptions;

  constructor(
    @Optional() public formField: MatFormField,
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    private readonly formErrorService: FormErrorService
  ) {}

  public ngOnInit(): void {
    this.options = {
      ...(this.externalOptions ?? {}),
      errorHandlers: this.externalOptions?.errorHandlers ?? {}
    };
  }

  public ngAfterContentInit(): void {
    if (!this.formField || this.elementRef.nativeElement.innerText) {
      return;
    }

    this.initTemplateOverrides();
    this.initControlValueStatusListener();
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }

  private initTemplateOverrides(): void {
    if (this.overrides.length) {
      this.viewContainerRef = this.overrides.first.viewContainerRef;

      this.overrides.forEach((dir: MatErrorOverrideDirective) => {
        this.overridesTmplMap.set(dir.errorCode, dir.template);

        if (Number.isFinite(dir.priority)) {
          this.options.errorHandlers[dir.errorCode] = {
            ...(this.options.errorHandlers[dir.errorCode] ?? {}),
            priority: dir.priority
          };
        }
      });
    }
  }

  private initControlValueStatusListener(): void {
    // eslint-disable-next-line no-underscore-dangle
    const control = this.formField._control?.ngControl as NgControl;

    control?.statusChanges
      .pipe(startWith(null))
      .pipe(
        map(() => control.errors),
        filter(Boolean),
        takeUntil(this.gc)
      )
      .subscribe((errors: ValidationErrors) => {
        const { errorCode, message } = this.formErrorService.getError(errors, this.options);

        this.viewContainerRef?.clear();
        this.clearTextNodes(this.elementRef.nativeElement);

        if (this.overridesTmplMap.has(errorCode)) {
          this.viewContainerRef.createEmbeddedView(this.overridesTmplMap.get(errorCode), {
            $implicit: errors[errorCode]
          });
          return;
        }

        this.renderer.appendChild(this.elementRef.nativeElement, this.renderer.createText(message));
      });
  }

  private clearTextNodes(element: HTMLElement): void {
    [...(element?.childNodes ?? [])]
      .filter((childNode: ChildNode) => childNode.nodeType !== Node.COMMENT_NODE)
      .forEach((node: Node) => this.renderer.removeChild(this.elementRef.nativeElement, node));
  }
}

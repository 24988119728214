<ng-container
  *ngIf="{
    active: active$ | async,
    adminApp: adminApp$ | async,
    sidebarExpanded: sidebarExpanded$ | async,
    sidebarOpened: sidebarOpened$ | async,
    smallMedia: smallMedia$ | async
  } as sync">
  <div class="skip-to-main mat-elevation-z4">
    <button type="button" class="link link-internal" (click)="focusOnFirstElementInWrapper(wrapper)">
      Skip to main content
    </button>
  </div>

  <div class="page-container">
    <ng-content select="billing-trial-top-toolbar"></ng-content>

    <mat-sidenav-container class="container" [class.expanded]="sync.sidebarExpanded">
      <mat-sidenav
        class="mat-elevation-z3 sidenav"
        disableClose
        [mode]="sync.smallMedia ? 'over' : 'side'"
        [opened]="sync.sidebarOpened">
        <share-sidebar
          [expanded$]="sidebarExpanded$"
          [opened]="sync.sidebarOpened"
          [smallMedia]="sync.smallMedia"
          (closed)="closeSidebar()"
          (expand)="sync.smallMedia ? closeSidebar() : expandSidebar()">
          <div class="logo"></div>
          <ng-content select="share-nav-item"></ng-content>
        </share-sidebar>
      </mat-sidenav>
      <mat-sidenav-content class="content">
        <share-app-header [opened]="sync.sidebarOpened" (openMenu)="openSidebar()">
          <span
            *ngIf="sync.active"
            class="header-icons-aux"
            fxFlexOffset="auto"
            fxLayout="row"
            fxLayoutAlign="center center">
            <share-page-refresh-button></share-page-refresh-button>
            <ng-content select=".header-icons"></ng-content>
            <button aria-label="Help" mat-icon-button matTooltip="Help" [matMenuTriggerFor]="helpMenu">
              <mat-icon class="help-icon">help</mat-icon>
            </button>
            <button aria-label="Account" mat-icon-button matTooltip="Account" [matMenuTriggerFor]="accountMenu">
              <mat-icon class="account-icon">account_circle</mat-icon>
            </button>
          </span>
        </share-app-header>
        <main class="wrapper" #wrapper>
          <router-outlet></router-outlet>
        </main>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>

  <mat-menu #accountMenu="matMenu">
    <div
      class="theme-switcher-container focus-ring"
      tabindex="0"
      mat-menu-item
      (click)="$event.stopPropagation()"
      (keyup.space)="themeSwitcher.toggle()">
      <share-theme-switcher #themeSwitcher></share-theme-switcher>
    </div>
    <button mat-menu-item routerLink="/profile"><mat-icon>settings</mat-icon> User settings</button>
    <button mat-menu-item (click)="logout()"><mat-icon class="icon-urgent">exit_to_app</mat-icon> Sign out</button>
  </mat-menu>

  <mat-menu #helpMenu="matMenu">
    <a
      *ngIf="!sync.adminApp"
      mat-menu-item
      queryParamsHandling="merge"
      [routerLink]="[{ outlets: { dialog: 'wizard' } }]">
      Quick-start wizard
    </a>
    <a href="https://docs.brightsec.com" mat-menu-item target="_blank">Knowledge base</a>
    <a href="https://support.brightsec.com" mat-menu-item target="_blank">Create support ticket</a>
    <a href="https://app.brightsec.com/api/v1/docs/" mat-menu-item target="_blank">API docs</a>
  </mat-menu>

  <router-outlet name="dialog"></router-outlet>
</ng-container>

import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OffsetPaginationConfig, SeekPaginationConfig } from '@neuralegion/api';

@Injectable({
  providedIn: 'root'
})
export class PaginationSerializationService {
  public convertToHttpParams(
    pagination?: SeekPaginationConfig | OffsetPaginationConfig
  ): HttpParams | null {
    if (!pagination) {
      return null;
    }
    let params = new HttpParams().set('limit', pagination.limit);

    if ('direction' in pagination) {
      params = params.set('moveTo', pagination.direction);

      if (pagination.entityId) {
        params = params.set('nextId', pagination.entityId);
      }
      if (pagination.entityCreatedAt) {
        params = params.set('nextCreatedAt', pagination.entityCreatedAt);
      }
    } else {
      if (pagination.offset) {
        params = params.set('offset', pagination.offset);
      }
    }

    return params;
  }
}

import { Renderer2 } from '@angular/core';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { delay } from 'rxjs';
import { BaseElementIdStrategy } from './base-element-id-strategy';

export class MatTabGroupElementIdStrategy extends BaseElementIdStrategy {
  private readonly elementPrefix = 'tab';
  private readonly nextButtonElementPrefix = ['tab', 'group', 'btn', 'next'].join(
    this.SEPARATOR_INTERNAL
  );
  private readonly prevButtonElementPrefix = ['tab', 'group', 'btn', 'prev'].join(
    this.SEPARATOR_INTERNAL
  );

  constructor(
    renderer: Renderer2,
    private readonly matTabGroup: MatTabGroup
  ) {
    super(renderer);
  }

  public override applyId(element: HTMLElement, dataId: string): void {
    if (!this.isApplied(element)) {
      // eslint-disable-next-line no-underscore-dangle
      this.matTabGroup._tabs.changes
        .pipe(delay(0))
        .subscribe(() => this.applyDataIdToTabs(element, dataId));
    }

    this.applyDataIdToButtons(element, dataId);
    this.applyDataIdToTabs(element, dataId);
  }

  protected override getSubId(): string {
    return '';
  }

  private isApplied(element: HTMLElement): boolean {
    return !!element.querySelector<HTMLElement>('.mat-mdc-tab-header > button[data-id]');
  }

  private applyDataIdToButtons(element: HTMLElement, dataId: string): void {
    const buttons = [...element.querySelectorAll<HTMLElement>('.mat-mdc-tab-header > button')];
    super.applyId(
      buttons[0],
      [...(dataId ? [dataId] : []), this.prevButtonElementPrefix].join(this.SEPARATOR_EXTERNAL)
    );
    super.applyId(
      buttons[1],
      [...(dataId ? [dataId] : []), this.nextButtonElementPrefix].join(this.SEPARATOR_EXTERNAL)
    );
  }

  private applyDataIdToTabs(element: HTMLElement, dataId: string): void {
    const prefix = [...(dataId ? [dataId] : []), this.elementPrefix].join(this.SEPARATOR_EXTERNAL);

    // eslint-disable-next-line no-underscore-dangle
    const tabIds = this.matTabGroup._tabs.map(
      (item: MatTab) => this.generateIdByText(item.textLabel) || this.UNKNOWN_ID
    );

    [...element.querySelectorAll<HTMLElement>('.mat-mdc-tab')].forEach((el, index) =>
      super.applyId(el, [prefix, 'label', tabIds[index]].join(this.SEPARATOR_INTERNAL))
    );

    [...element.querySelectorAll<HTMLElement>('.mat-mdc-tab-body')].forEach((el, index) =>
      super.applyId(el, [prefix, tabIds[index]].join(this.SEPARATOR_INTERNAL))
    );
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { JobStatus } from '@neuralegion/api';

@Pipe({
  name: 'jobStatus',
  pure: true
})
export class JobStatusPipe implements PipeTransform {
  public transform(value: JobStatus): string {
    switch (value) {
      case JobStatus.STOPPED:
        return 'Stopped';
      case JobStatus.SCHEDULED:
        return 'Scheduled';
      case JobStatus.QUEUED:
        return 'Queued';
      case JobStatus.PENDING:
      case JobStatus.SEARCHING:
      case JobStatus.RUNNING:
        return 'Running';
      case JobStatus.INCOMPLETE:
      case JobStatus.FAILED:
      case JobStatus.DISRUPTED:
        return 'Failed';
      case JobStatus.DONE:
      case JobStatus.COMPLETE:
        return 'Completed';
      default:
        return value;
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { ComparisonOperator } from '@neuralegion/api';

@Pipe({
  name: 'comparisonOperatorName',
  pure: true
})
export class ComparisonOperatorNamePipe implements PipeTransform {
  public transform(value: ComparisonOperator): string {
    switch (value) {
      case ComparisonOperator.EQ:
        return '=';
      case ComparisonOperator.GT:
        return '>';
      case ComparisonOperator.GTE:
        return '≥';
      case ComparisonOperator.LT:
        return '<';
      case ComparisonOperator.LTE:
        return '≤';
    }
  }
}

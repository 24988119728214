import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Optional, SkipSelf } from '@angular/core';
import { Observable, filter, map, startWith } from 'rxjs';
import { TinyColor } from '@ctrl/tinycolor';
import { RGB } from '@ctrl/tinycolor/dist/interfaces';
import { LocalStorageService } from '@neuralegion/browser-storage';
import {
  Palette,
  PaletteColor,
  PaletteType,
  PaletteWithContrast,
  Theme,
  ThemeName,
  themesDefinitions
} from './themes';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private readonly THEME_KEY = 'theme';

  public readonly themeChange$: Observable<ThemeName>;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly localStorageService: LocalStorageService,
    @Optional() @SkipSelf() themeService: ThemeService
  ) {
    if (themeService) {
      throw new Error('ThemeService should be singleton');
    }

    this.themeChange$ = this.localStorageService.changes$.pipe(
      filter((key: string) => key === this.THEME_KEY),
      map(() => this.getCurrentTheme()),
      startWith(ThemeName.DEFAULT)
    );
  }

  public init(): void {
    const storedTheme = this.localStorageService.get(this.THEME_KEY);
    const matchMedia = this.document.defaultView.matchMedia;
    this.switchTheme(
      storedTheme ||
        (matchMedia && matchMedia('(prefers-color-scheme: dark)').matches
          ? ThemeName.DARK
          : ThemeName.DEFAULT)
    );
  }

  public getCurrentTheme(): ThemeName {
    return (this.localStorageService.get(this.THEME_KEY) as ThemeName) || ThemeName.DEFAULT;
  }

  public isDark(): boolean {
    return this.getCurrentTheme() === ThemeName.DARK;
  }

  public switchTheme(themeName: ThemeName): void {
    const theme: Theme = {
      [PaletteType.PRIMARY]: this.generateColorPalette(
        themesDefinitions[themeName][PaletteType.PRIMARY]
      ),
      [PaletteType.ACCENT]: this.generateColorPalette(
        themesDefinitions[themeName][PaletteType.ACCENT]
      ),
      [PaletteType.WARN]: this.generateColorPalette(themesDefinitions[themeName][PaletteType.WARN])
    };

    this.setThemeCssProperties(theme);

    this.document.body.className = this.document.body.className.replace(
      /theme-[\w-]+/i,
      `theme-${themeName.toLowerCase()}`
    );

    this.localStorageService.set(this.THEME_KEY, themeName);
  }

  private setThemeCssProperties(theme: Theme): void {
    Object.values(PaletteType).forEach((paletteType: PaletteType) => {
      const palette = theme[paletteType];
      Object.values(PaletteColor).forEach((colorName: PaletteColor) => {
        this.document.documentElement.style.setProperty(
          `--theme-${paletteType}-${colorName}`,
          palette[colorName]
        );
        this.document.documentElement.style.setProperty(
          `--theme-${paletteType}-contrast-${colorName}`,
          palette.contrast[colorName]
        );
      });
    });
  }

  private generateColorPalette(baseColor: string): PaletteWithContrast {
    const palette: PaletteWithContrast = Object.values(PaletteColor).reduce(
      (res: PaletteWithContrast, colorName: PaletteColor): PaletteWithContrast => ({
        ...res,
        [colorName]: this.getPaletteColor(baseColor, colorName).toHexString()
      }),
      {
        contrast: {}
      }
    );

    palette.contrast = Object.values(PaletteColor).reduce(
      (res: Palette, colorName: PaletteColor): Palette => ({
        ...res,
        [colorName]: this.getContrastColor(palette[colorName])
      }),
      {}
    );

    return palette;
  }

  private getContrastColor(color: string): string {
    return new TinyColor(color).isLight() ? '#000000' : '#ffffff';
  }

  private getPaletteColor(baseColor: string, colorName: PaletteColor): TinyColor {
    const baseTiny = new TinyColor(baseColor).toRgb();
    const baseLight = '#ffffff';
    const baseDark = this.multiplyColors(baseTiny, baseTiny);
    const baseTriad = new TinyColor(baseColor).tetrad();

    switch (colorName) {
      case PaletteColor.COLOR_50:
        return new TinyColor(baseLight).mix(baseColor, 12);
      case PaletteColor.COLOR_100:
        return new TinyColor(baseLight).mix(baseColor, 30);
      case PaletteColor.COLOR_200:
        return new TinyColor(baseLight).mix(baseColor, 50);
      case PaletteColor.COLOR_300:
        return new TinyColor(baseLight).mix(baseColor, 70);
      case PaletteColor.COLOR_400:
        return new TinyColor(baseLight).mix(baseColor, 85);
      case PaletteColor.COLOR_500:
        return new TinyColor(baseLight).mix(baseColor, 100);
      case PaletteColor.COLOR_600:
        return new TinyColor(baseDark).mix(baseColor, 87);
      case PaletteColor.COLOR_700:
        return new TinyColor(baseDark).mix(baseColor, 70);
      case PaletteColor.COLOR_800:
        return new TinyColor(baseDark).mix(baseColor, 54);
      case PaletteColor.COLOR_900:
        return new TinyColor(baseDark).mix(baseColor, 25);
      case PaletteColor.COLOR_A100:
        return new TinyColor(baseDark).mix(baseTriad[4], 15).saturate(80).lighten(65);
      case PaletteColor.COLOR_A200:
        return new TinyColor(baseDark).mix(baseTriad[4], 15).saturate(80).lighten(55);
      case PaletteColor.COLOR_A400:
        return new TinyColor(baseDark).mix(baseTriad[4], 15).saturate(100).lighten(45);
      case PaletteColor.COLOR_A700:
        return new TinyColor(baseDark).mix(baseTriad[4], 15).saturate(100).lighten(40);
    }
  }

  private multiplyColors(rgb1: RGB, rgb2: RGB): TinyColor {
    rgb1.b = Math.floor((+rgb1.b * +rgb2.b) / 255);
    rgb1.g = Math.floor((+rgb1.g * +rgb2.g) / 255);
    rgb1.r = Math.floor((+rgb1.r * +rgb2.r) / 255);
    return new TinyColor(rgb1);
  }
}

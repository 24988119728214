import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {
  ActivityLogGuard,
  AuthGuard,
  AuthNegateGuard,
  AuthObjectsGuard,
  BillingManagerGuard,
  DiscoveriesGuard,
  EmailConfirmationNegateGuard,
  EntrypointsGuard,
  EntrypointsManageGuard,
  IssuesGuard,
  MarketplaceCallbackStateGuard,
  MfaNegateGuard,
  OAuthGuard,
  OrganizationGuard,
  ProjectsGuard,
  ProjectsManageGuard,
  RepeaterScriptsGuard,
  RepeatersGuard,
  ScanGuard,
  ScanTemplatesGuard,
  SignupGuard,
  StorageGuard,
  SuperAdminGuard,
  WizardGuard
} from '../guards';
import { TokenInterceptorOptions } from '../models';
import {
  AuthService,
  BaseService,
  ForgotPasswordService,
  HttpInterceptorTokenService,
  INTERCEPTOR_OPTIONS,
  SignupService
} from '../services';
import { AuthAnalyticsEffects } from '../store/auth-analytics.effects';
import { AuthEffects } from '../store/auth.effects';
import { authReducer } from '../store/auth.reducer';
import { ForgotPasswordEffects } from '../store/forgot-password.effects';
import { forgotPasswordReducer } from '../store/forgot-password.reducer';
import { SignupEffects } from '../store/signup.effects';
import { signupReducer } from '../store/signup.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature('auth', authReducer),
    StoreModule.forFeature('forgotPassword', forgotPasswordReducer),
    StoreModule.forFeature('signup', signupReducer),
    EffectsModule.forFeature([AuthAnalyticsEffects, AuthEffects]),
    EffectsModule.forFeature([ForgotPasswordEffects]),
    EffectsModule.forFeature([SignupEffects])
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorTokenService,
      multi: true
    },
    {
      provide: INTERCEPTOR_OPTIONS,
      useClass: TokenInterceptorOptions
    },
    AuthService,
    BaseService,
    ForgotPasswordService,
    SignupService,

    ActivityLogGuard,
    AuthGuard,
    AuthNegateGuard,
    AuthObjectsGuard,
    BillingManagerGuard,
    DiscoveriesGuard,
    EmailConfirmationNegateGuard,
    EntrypointsGuard,
    EntrypointsManageGuard,
    IssuesGuard,
    MfaNegateGuard,
    MarketplaceCallbackStateGuard,
    OAuthGuard,
    OrganizationGuard,
    ProjectsGuard,
    ProjectsManageGuard,
    RepeatersGuard,
    RepeaterScriptsGuard,
    ScanGuard,
    ScanTemplatesGuard,
    SignupGuard,
    StorageGuard,
    SuperAdminGuard,
    WizardGuard
  ]
})
export class AuthApiModule {}

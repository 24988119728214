import { Action, createReducer, on } from '@ngrx/store';
import { cleanupPendingState } from '@neuralegion/core';
import {
  addGroup,
  addGroupFail,
  addGroupMember,
  addGroupMemberFail,
  addGroupMemberSuccess,
  addGroupSuccess,
  loadGroup,
  loadGroupFail,
  loadGroupSuccess,
  loadGroups,
  loadGroupsFail,
  loadGroupsSuccess,
  removeGroup,
  removeGroupFail,
  removeGroupMember,
  removeGroupMemberFail,
  removeGroupMemberSuccess,
  removeGroupSuccess,
  updateGroup,
  updateGroupFail,
  updateGroupSuccess
} from './groups.actions';
import { GroupsState, groupsEntityAdapter, groupsInitialState } from './groups.state';

export const groupsReducer = createReducer<GroupsState, Action>(
  groupsInitialState,
  on(
    loadGroup,
    addGroup,
    updateGroup,
    removeGroup,
    loadGroups,
    addGroupMember,
    removeGroupMember,
    (state: GroupsState, action): GroupsState => ({
      ...state,
      pending: [...state.pending, action]
    })
  ),
  on(
    loadGroupFail,
    addGroupFail,
    updateGroupFail,
    removeGroupFail,
    loadGroupsFail,
    addGroupMemberFail,
    removeGroupMemberFail,
    (state: GroupsState, action): GroupsState => cleanupPendingState(state, action, action.payload)
  ),
  on(
    loadGroupSuccess,
    (state: GroupsState, action): GroupsState =>
      groupsEntityAdapter.upsertOne(action.payload.group, cleanupPendingState(state, action))
  ),
  on(
    addGroupSuccess,
    (state: GroupsState, action): GroupsState =>
      groupsEntityAdapter.addOne(action.payload.group, cleanupPendingState(state, action))
  ),
  on(updateGroupSuccess, (state: GroupsState, action): GroupsState => {
    const newState = cleanupPendingState(state, action);
    return groupsEntityAdapter.addOne(
      action.payload.group,
      groupsEntityAdapter.removeOne(action.payload.group.id, newState)
    );
  }),
  on(
    removeGroupSuccess,
    (state: GroupsState, action): GroupsState =>
      groupsEntityAdapter.removeOne(action.payload.groupId, cleanupPendingState(state, action))
  ),
  on(
    loadGroupsSuccess,
    (state: GroupsState, action): GroupsState =>
      groupsEntityAdapter.setAll(action.payload.groups, cleanupPendingState(state, action))
  ),
  on(addGroupMemberSuccess, (state: GroupsState, action): GroupsState => {
    const { groupId, memberId } = action.payload;
    return groupsEntityAdapter.updateOne(
      {
        id: groupId,
        changes: {
          members: [
            ...state.entities[groupId].members.filter((id: string) => id !== memberId),
            memberId
          ]
        }
      },
      cleanupPendingState(state, action)
    );
  }),
  on(removeGroupMemberSuccess, (state: GroupsState, action): GroupsState => {
    const { groupId, memberId } = action.payload;
    return groupsEntityAdapter.updateOne(
      {
        id: groupId,
        changes: {
          members: state.entities[groupId].members.filter((id: string) => id !== memberId)
        }
      },
      cleanupPendingState(state, action)
    );
  })
);

import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { Member } from '@neuralegion/api';

export interface MembersState extends EntityState<Member> {
  error: string;
  pending: Action[];
}

export const membersEntityAdapter: EntityAdapter<Member> = createEntityAdapter<Member>({
  selectId: (model) => model.id
});

export const initialMembersState: MembersState = membersEntityAdapter.getInitialState({
  error: null,
  pending: []
});

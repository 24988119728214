import { Action, MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { StorageFile } from '../models';
import { loadFiles } from './storage.actions';
import { StorageState } from './storage.state';

// eslint-disable-next-line @typescript-eslint/ban-types
export type State = object;
export const selectStorageState = createFeatureSelector<StorageState>('storage');

export const selectFiles = createSelector<State, [StorageState], StorageFile[]>(
  selectStorageState,
  (state: StorageState) => state && state.files
);

export const selectFile = (id: string): MemoizedSelector<State, StorageFile> =>
  createSelector<State, [StorageFile[]], StorageFile>(selectFiles, (files: StorageFile[]) =>
    files.find((file: StorageFile) => file.id === id)
  );

export const selectPending = createSelector<State, [StorageState], boolean>(
  selectStorageState,
  (state: StorageState) => state && state.pending && state.pending.length > 0
);

export const selectFilesPending = createSelector<State, [StorageState], boolean>(
  selectStorageState,
  (state: StorageState) =>
    state &&
    state.pending &&
    !!state.pending.find((action: Action) => action.type === loadFiles.type)
);

export const selectError = createSelector<State, [StorageState], string>(
  selectStorageState,
  (state: StorageState) => state && state.error
);

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'quotes',
  pure: true
})
export class QuotesPipe implements PipeTransform {
  public transform(str: string, quoteStart: string = '"', quoteEnd: string = '"'): string {
    return str && `${quoteStart}${str}${quoteEnd}`;
  }
}

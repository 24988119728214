import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgControl,
  ValidationErrors,
  Validator
} from '@angular/forms';
import {
  Observable,
  ReplaySubject,
  Subject,
  delay,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  takeUntil
} from 'rxjs';
import equal from 'fast-deep-equal/es6';
import { ClipboardService, trackByIdx } from '@neuralegion/core';
import {
  ListControlValidators,
  extractTouchedChanges,
  updateNestedTreeTouchedState,
  updateTreeValidity
} from '../../form';
import { ListControlErrors } from '../../models';

export interface ListControlSettings {
  readonly keepOne?: boolean;
  readonly copyAll?: boolean;
  readonly uniqueItems?: boolean;
  readonly maxItems?: number;
  readonly validationTrigger$?: Observable<unknown>;
  readonly labelAddItem?: string;
  readonly labelCopyAll?: string;
  readonly emptyPlaceholder?: string;
  readonly itemsDelimiter?: string;
  readonly defaultValueFactory?: () => unknown;
}

export interface ListControlAddEvent {
  control: FormControl;
  idx: number;
}

@Component({
  selector: 'share-list-control',
  templateUrl: './list-control.component.html',
  styleUrls: ['./list-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ListControlComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: ListControlComponent,
      multi: true
    }
  ]
})
export class ListControlComponent
  implements ControlValueAccessor, OnInit, OnDestroy, Validator, AfterViewInit
{
  @Input()
  public settings: Partial<ListControlSettings> = {};

  @Input()
  public tmplItem: TemplateRef<unknown>;

  public readonly trackByIdx = trackByIdx;

  public readonly formArray: FormArray = new FormArray([]);

  public controlAdded$: Observable<ListControlAddEvent>;

  private readonly defaultSettings: ListControlSettings = {
    emptyPlaceholder: 'Empty list',
    labelAddItem: 'Add item',
    labelCopyAll: 'Copy all items',
    itemsDelimiter: '\n',
    defaultValueFactory: () => null
  };

  public emptyValueCheckerFn?: (itemData: unknown) => boolean = (itemData: unknown) => !itemData;
  public mapItemForCopy?: (itemData: unknown) => string = (itemData: unknown) =>
    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    itemData?.toString() ?? '';
  public mapItemForUniqueCompare?: (itemData: unknown) => unknown = (itemData: unknown) => itemData;

  private onChange: (value: unknown[]) => void;
  private onTouched: () => void;
  private onValidatorChange: () => void;

  private readonly controlAddedSubject = new ReplaySubject<ListControlAddEvent>(1);
  private readonly gc = new Subject<void>();

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly clipboardService: ClipboardService,
    private readonly injector: Injector
  ) {
    this.controlAdded$ = this.controlAddedSubject.asObservable();
  }

  public ngOnInit(): void {
    this.settings = {
      ...this.defaultSettings,
      ...this.settings
    };

    this.formArray.valueChanges
      .pipe(
        map(() => this.formArray.getRawValue() as unknown[]),
        distinctUntilChanged(equal),
        takeUntil(this.gc)
      )
      .subscribe((value: unknown[]) => this.onChange?.(value));

    this.formArray.statusChanges
      .pipe(distinctUntilChanged(), takeUntil(this.gc))
      .subscribe(() => this.onValidatorChange());

    this.settings.validationTrigger$
      ?.pipe(delay(0), takeUntil(this.gc))
      .subscribe(() => updateTreeValidity(this.formArray));
  }
  public ngAfterViewInit(): void {
    this.initArrayValidators();

    this.initTouchedStateListeners();
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }

  public registerOnChange(fn: (value: unknown[]) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  public setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.formArray.disable();
    } else {
      this.formArray.enable();
    }
    this.cdr.detectChanges();
  }

  public writeValue(rawValue: unknown[]): void {
    const value = this.settings.keepOne && (rawValue?.length || 0) < 1 ? [null] : rawValue;

    if (equal(value, this.formArray.getRawValue())) {
      return;
    }

    if (!value || !value?.length) {
      this.formArray.clear();
      this.cdr.markForCheck();
      return;
    }
    if (this.formArray.controls?.length > value.length) {
      for (let i = this.formArray.controls.length - 1; i > value.length - 1; i--) {
        this.formArray.removeAt(i, { emitEvent: false });
      }
    }

    if (this.formArray.controls?.length < value.length) {
      for (let i = this.formArray.controls.length; i < value.length; i++) {
        this.formArray.push(new FormControl(this.settings.defaultValueFactory()), {
          emitEvent: false
        });
      }
    }

    // We want to set value when inner controls are already initialized.
    // Trigger change detection cycle for this.
    this.cdr.detectChanges();

    this.formArray.setValue(value);
  }

  public validate(_control: AbstractControl): ValidationErrors | null {
    if (this.formArray.disabled || this.formArray.valid) {
      return null;
    }

    const listErrors = [
      ListControlErrors.LIST_LENGTH_EXCEEDED,
      ListControlErrors.DUPLICATED_LIST_ITEM
    ]
      .filter((errors: ListControlErrors) => this.formArray.hasError(errors))
      .reduce(
        (result: ValidationErrors, err: ListControlErrors) => ({
          ...result,
          [err]: this.formArray.getError(err)
        }),
        {} as ValidationErrors
      );

    const childrenErrors = this.formArray.controls.reduce(
      (errors: ValidationErrors, control: AbstractControl) => {
        return {
          ...errors,
          ...(control.errors || {})
        };
      },
      {}
    );

    return {
      ...listErrors,
      ...childrenErrors
    };
  }

  public registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  public onAddButtonClicked(): void {
    const control = this.addControl();
    this.controlAddedSubject.next({ control, idx: this.formArray.length - 1 });
  }

  public remove(idx: number): void {
    this.formArray.removeAt(idx);
  }

  public onCopyButtonClick(): void {
    const data = (this.formArray.value as unknown[])
      .map((itemData: unknown) => (this.mapItemForCopy ? this.mapItemForCopy(itemData) : itemData))
      .filter((itemData: unknown) =>
        this.emptyValueCheckerFn ? !this.emptyValueCheckerFn(itemData) : true
      )
      .join(this.settings.itemsDelimiter);

    this.clipboardService.copyToClipboard(data, 'Items copied!');
  }

  /* Method called by child components to handle paste event */
  public onPaste(data: unknown[], idx: number): void {
    const currentValue = this.formArray.getRawValue();
    const updatedValue = [
      ...currentValue.slice(0, idx),
      ...data,
      ...currentValue.slice(idx + data.length)
    ];
    this.writeValue(updatedValue);
    this.formArray.markAllAsTouched();
  }

  private addControl(): FormControl {
    const control = new FormControl(this.settings.defaultValueFactory());
    this.formArray.push(control);
    if (this.formArray.disabled) {
      control.disable();
    }

    return control;
  }

  private initArrayValidators(): void {
    const validators = [];

    if (this.settings.maxItems) {
      validators.push(ListControlValidators.listItemsLength(this.settings.maxItems));
    }

    if (this.settings.uniqueItems) {
      validators.push((control: AbstractControl) =>
        ListControlValidators.uniqueListItems(control, this.mapItemForUniqueCompare)
      );
    }

    this.formArray.setValidators(validators);
  }

  private initTouchedStateListeners(): void {
    const parentControl = this.injector.get(NgControl).control;

    extractTouchedChanges(parentControl)
      .pipe(startWith(parentControl.touched), distinctUntilChanged(), takeUntil(this.gc))
      .subscribe((touched: boolean) => {
        updateNestedTreeTouchedState(this.formArray, touched);
        this.cdr.markForCheck();
      });

    extractTouchedChanges(this.formArray)
      .pipe(
        distinctUntilChanged(),
        filter((touched: boolean) => touched),
        takeUntil(this.gc)
      )
      .subscribe(() => this.onTouched?.());
  }
}

import { createAction } from '@ngrx/store';
import { BillingContactInfo, BillingSummary, Product } from '../models';

export const billingRefresh = createAction('[Billing] BILLING_REFRESH');

export const loadProducts = createAction('[Billing] LOAD_PRODUCTS');

export const loadProductsFail = createAction('[Billing] LOAD_PRODUCTS_FAIL', (payload: string) => ({
  payload,
  parentType: loadProducts.type
}));

export const loadProductsSuccess = createAction(
  '[Billing] LOAD_PRODUCTS_SUCCESS',
  (payload: { products: Product[] }) => ({ payload, parentType: loadProducts.type })
);

export const loadSummary = createAction('[Billing] LOAD_SUMMARY');

export const loadSummaryFail = createAction('[Billing] LOAD_SUMMARY_FAIL', (payload: string) => ({
  payload,
  parentType: loadSummary.type
}));

export const loadSummarySuccess = createAction(
  '[Billing] LOAD_SUMMARY_SUCCESS',
  (payload: { summary: BillingSummary }) => ({ payload, parentType: loadSummary.type })
);

export const saveContactInfo = createAction(
  '[Billing] SAVE_CONTACT_INFO',
  (payload: { contactInfo: BillingContactInfo }) => ({ payload })
);

export const saveContactInfoFail = createAction(
  '[Billing] SAVE_CONTACT_INFO_FAIL',
  (payload: string) => ({
    payload,
    parentType: saveContactInfo.type
  })
);

export const saveContactInfoSuccess = createAction('[Billing] SAVE_CONTACT_INFO_SUCCESS', () => ({
  parentType: saveContactInfo.type
}));

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Scope, ScopePermissions } from '@neuralegion/api';
import { selectOrgPagePermission, selectScopePermissions } from '@neuralegion/auth-api';
import { selectCodeInstrumentationPermission } from '@neuralegion/organization-api';

@Component({
  selector: 'consumer-app',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  public readonly Scope = Scope;

  public scopePermissions$: Observable<ScopePermissions>;
  public orgPagePermission$: Observable<boolean>;
  public codeInstrumentationPermission$: Observable<boolean>;

  constructor(private readonly store: Store) {}

  public ngOnInit(): void {
    this.scopePermissions$ = this.store.select(selectScopePermissions);
    this.orgPagePermission$ = this.store.select(selectOrgPagePermission);
    this.codeInstrumentationPermission$ = this.store.select(selectCodeInstrumentationPermission);
  }
}

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, filter, map, takeUntil, tap } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { IndividualConfig } from 'ngx-toastr/toastr/toastr-config';
import { ActiveToast } from 'ngx-toastr/toastr/toastr.service';
import {
  closeAllDialogs,
  hideSnackbarByMessage,
  openInNewTab,
  showSnackbar,
  showSnackbarWithNavigation,
  showSnackbarWithReload
} from '@neuralegion/core';
import { SnackBarComponent } from '../components';

@Injectable()
export class ShareEffects {
  private readonly duration = 3000;
  private readonly durationOnError = 5000;

  public readonly closeAllDialogs$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(closeAllDialogs),
        tap(() => this.dialog.closeAll())
      ),
    { dispatch: false }
  );

  public readonly openInNewTab$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(openInNewTab),
      tap((action: ReturnType<typeof openInNewTab>) =>
        this.document.defaultView.window.open(action.payload.url)
      ),
      filter((action: ReturnType<typeof openInNewTab>) => action.payload.closeAllDialogs),
      map(() => closeAllDialogs())
    )
  );

  public readonly showSnackbar$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showSnackbar),
        tap((action: ReturnType<typeof showSnackbar>) => {
          const { message, error, config, title } = action.payload;
          this.openSnackbar(message, error, config, title);
        })
      ),
    { dispatch: false }
  );

  public readonly showSnackbarWithReload$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showSnackbarWithReload),
        tap((action: ReturnType<typeof showSnackbarWithReload>) => {
          const toastsRef = this.openSnackbar(
            action.payload.message,
            true,
            { disableTimeOut: true },
            'Reload'
          );
          toastsRef.onAction.pipe().subscribe(() => this.document.defaultView.location.reload());
        })
      ),
    { dispatch: false }
  );

  public readonly showSnackbarWithNavigation$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showSnackbarWithNavigation),
        tap((action: ReturnType<typeof showSnackbarWithNavigation>) => {
          const { message, url, config, title } = action.payload;
          const toastsRef = this.openSnackbar(message, true, config, title);
          toastsRef.onAction
            .pipe(takeUntil(toastsRef.onHidden))
            .subscribe(() => this.router.navigateByUrl(url));
        })
      ),
    { dispatch: false }
  );

  public readonly hideSnackbarByMessage$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(hideSnackbarByMessage),
        tap((action: ReturnType<typeof hideSnackbarByMessage>) => {
          const toast = this.toastrService.findDuplicate('', action.payload.message, false, false);
          if (toast) {
            this.toastrService.remove(toast.toastId);
          }
        })
      ),
    { dispatch: false }
  );

  private openSnackbar(
    message: string,
    error: boolean = false,
    config: Partial<IndividualConfig> = {},
    title: string = ''
  ): ActiveToast<unknown> {
    return this.toastrService.show(
      message,
      title,
      {
        tapToDismiss: false,
        toastComponent: SnackBarComponent,
        onActivateTick: true,
        timeOut: error ? this.durationOnError : this.duration,
        ...config
      },
      'snack-bar-container'
    );
  }

  constructor(
    private readonly actions$: Actions,
    private readonly dialog: MatDialog,
    private readonly toastrService: ToastrService,
    private readonly router: Router,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}
}

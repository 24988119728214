import { Injectable } from '@angular/core';

import { Observable, filter, first, map, of, switchMap } from 'rxjs';
import { Actions } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { DateSettings } from '@neuralegion/api';
import { loadDateSettings, loadDateSettingsSuccess } from '../store/profile-settings.actions';
import { selectDateSettings } from '../store/profile-settings.selectors';

@Injectable()
export class DateSettingsGuard {
  constructor(
    private readonly store: Store,
    private readonly actions$: Actions
  ) {}

  public canActivate(): Observable<boolean> {
    return this.store.select(selectDateSettings).pipe(
      first(),
      switchMap((dateSettings: DateSettings): Observable<boolean> => {
        if (dateSettings) {
          return of(true);
        }

        this.store.dispatch(loadDateSettings());
        return this.actions$.pipe(
          filter((action: Action) => action.type === loadDateSettingsSuccess.type),
          first(),
          map(() => true)
        );
      }),
      first()
    );
  }

  public canMatch(): Observable<boolean> {
    return this.canActivate();
  }
}

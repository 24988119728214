import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import {
  Invitation,
  Member,
  Organization,
  PaginationResponse,
  Quota,
  quotasComparator
} from '@neuralegion/api';

@Injectable()
export class OrganizationService {
  constructor(private readonly http: HttpClient) {}

  public loadUserOrganization(): Observable<Organization> {
    return this.http.get<Organization>('/api/v1/me/org');
  }

  public loadQuotas(orgId: string): Observable<Quota[]> {
    return this.http
      .get<Quota[]>(`/api/v1/orgs/${orgId}/quotas`)
      .pipe(map((quotas: Quota[]) => [...quotas].sort(quotasComparator)));
  }

  public updateUserOrganization(payload: Partial<Organization>): Observable<void> {
    return this.http.patch<void>('/api/v1/me/org', payload);
  }

  /**
   * @deprecated Use `OrganizationService.loadUserOrganizationMembers` instead
   */
  public getUserOrganizationMembersV1(): Observable<Member[]> {
    return this.http.get<Member[]>('/api/v1/me/org/memberships');
  }

  public loadUserOrganizationMembers(
    params: HttpParams | null
  ): Observable<PaginationResponse<Member>> {
    return this.http.get<PaginationResponse<Member>>('/api/v2/me/org/memberships', {
      ...(params ? { params } : {})
    });
  }

  public getUserOrganizationMember(memberId: string): Observable<Member> {
    return this.http.get<Member>(`/api/v1/me/org/memberships/${memberId}`);
  }

  public addMemberToUserOrganization(payload: Invitation): Observable<void> {
    return this.http.post<void>('/api/v1/me/org/memberships', payload);
  }

  public updateMemberOfUserOrganization(member: Member): Observable<void> {
    return this.http.patch<void>(`/api/v1/me/org/memberships/${member.id}`, member);
  }

  public removeMemberFromUserOrganization(memberId: string): Observable<void> {
    return this.http.delete<void>(`/api/v1/me/org/memberships/${memberId}`);
  }
}

<ng-container [formGroup]="innerControl">
  <div
    class="full-width"
    fxLayout="row"
    fxLayout.lt-sm="column"
    fxLayoutAlign="space-between start"
    fxLayoutAlign.lt-sm="start start"
    fxLayoutGap="0"
    fxLayoutGap.gt-sm="16px">
    <mat-form-field class="key-field full-width">
      <mat-label *ngIf="settings.labelKey">{{ settings.labelKey }}</mat-label>
      <input type="text" #keyField matInput [formControlName]="'key'" (paste)="onPaste($event)" />

      <mat-error *ngIf="prioritizedKeyErrorText as errorText; else tmplNoPrioritizedErrors">{{ errorText }}</mat-error>
      <ng-template #tmplNoPrioritizedErrors>
        <mat-error>
          <ng-container
            *ngTemplateOutlet="
              tmplKeyErrors || tmplDefaultErrors;
              context: { $implicit: innerControl.get('key') }
            "></ng-container>
        </mat-error>
      </ng-template>
    </mat-form-field>

    <mat-form-field class="value-field full-width">
      <mat-label *ngIf="settings.labelValue">{{ settings.labelValue }}</mat-label>
      <input
        *ngIf="!settings.multilineValue; else tmplMultilineValueInput"
        autocomplete="off"
        matInput
        [formControlName]="'value'" />
      <ng-template #tmplMultilineValueInput>
        <textarea
          class="custom-resizable"
          cdkTextareaAutosize
          matInput
          [cdkAutosizeMinRows]="1"
          [formControlName]="'value'"></textarea>
      </ng-template>
      <mat-error>
        <ng-container
          *ngTemplateOutlet="
            tmplValueErrors || tmplDefaultErrors;
            context: { $implicit: innerControl.get('value') }
          "></ng-container>
      </mat-error>
    </mat-form-field>
  </div>
</ng-container>

<ng-template #tmplDefaultErrors let-control>
  <ng-container *ngIf="control.getError('maxlength') as maxlength" class="text-ellipsis">
    Too many characters, the maximum is {{ maxlength.requiredLength }}
  </ng-container>
</ng-template>

import { Injectable } from '@angular/core';
import { ClipboardService } from './clipboard.service';
import { ShellService } from './shell.service';

interface CurlRequest {
  method: string;
  url: string;
  headers?: Record<string, string>;
  body?: string;
}

@Injectable()
export class CurlService {
  constructor(
    private readonly clipboardService: ClipboardService,
    private readonly shellService: ShellService
  ) {}

  public convertToShellCommand(request: CurlRequest): string {
    const output = ['curl', '-i']; /* -i stands for displaying response headers */

    if (request.method !== 'GET') {
      output.push('-X', request.method);
    }

    if (request.headers) {
      output.push(
        ...Object.entries(request.headers).reduce(
          (res: string[], [key, value]) => [...res, '-H', `${key}:${value}`],
          []
        )
      );
    }

    output.push(request.url);

    if (request.body) {
      output.push('-d', request.body);
    }

    return this.shellService.escapeCommand(output);
  }

  public copyCommandToClipboard(request: CurlRequest): void {
    if (!request) {
      return;
    }

    this.clipboardService.copyToClipboard(
      this.convertToShellCommand(request),
      'cURL command copied'
    );
  }
}

import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable()
export class ShellService {
  constructor(private readonly deviceDetectorService: DeviceDetectorService) {}

  public escapeArg(arg: string, cmdShim: boolean = false): string {
    return this.deviceDetectorService.os?.includes('Win')
      ? this.escapeWin(arg, cmdShim)
      : this.escapePosix(arg);
  }

  public escapeCommand(command: string[], cmdShim: boolean = false): string {
    return command.map((arg: string) => this.escapeArg(arg, cmdShim)).join(' ');
  }

  private escapePosix(arg: string): string {
    return !/^[A-Za-z0-9_/:-]+$/.test(arg)
      ? `'${arg.replace(/'/g, "'\"'\"'")}'`.replace(/''/g, '')
      : arg;
  }

  // Based on https://qntm.org/cmd and https://github.com/moxystudio/node-cross-spawn
  private escapeWin(arg: string, cmdShim: boolean): string {
    if (/^[A-Za-z0-9_/:-]+$/.test(arg)) {
      return arg;
    }

    const metaCharsRegExp = /([()\][%!^"`<>&|;, *?])/g;

    // Sequence of backslashes followed by a double quote:
    // double up all the backslashes and escape the double quote
    let res = arg.replace(/(\\*)"/g, '$1$1\\"');

    // Sequence of backslashes followed by the end of the string (which will become a double quote later):
    // double up all the backslashes
    res = res.replace(/(\\*)$/, '$1$1');

    // Quote the whole thing:
    res = `"${res}"`;

    // Escape meta chars
    res = res.replace(metaCharsRegExp, '^$1');

    // Need to double escape meta chars if the command is a cmd-shim (like bright-cli)
    if (cmdShim) {
      res = res.replace(metaCharsRegExp, '^$1');
    }

    return res;
  }
}

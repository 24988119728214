import { Pipe, PipeTransform } from '@angular/core';

interface TestBucket {
  readonly tag: string;
  readonly title: string;
  readonly tests: unknown[];
}

type Item = { title: string; tests?: number };

@Pipe({
  name: 'summaryBucketsDetailsPipe'
})
export class SummaryBucketsDetailsPipe implements PipeTransform {
  public transform(testBuckets?: readonly TestBucket[]): string[];
  public transform(tags?: string[], availableTestBuckets?: readonly TestBucket[]): string[];
  public transform(
    tagsOrTestBuckets?: readonly TestBucket[] | string[],
    availableTestBuckets?: readonly TestBucket[]
  ): string[] {
    if (!Array.isArray(tagsOrTestBuckets) || !tagsOrTestBuckets?.length) {
      return [];
    }

    const isTagsPassed = tagsOrTestBuckets.every(
      (tagOrTestBucket): tagOrTestBucket is string => typeof tagOrTestBucket === 'string'
    );

    if (isTagsPassed) {
      const items: Item[] = tagsOrTestBuckets.map((tagToFound: string): Item => {
        const testBucket = availableTestBuckets?.find(({ tag }) => tag === tagToFound);
        return {
          title: testBucket?.title ?? tagToFound,
          tests: testBucket?.tests.length
        };
      });
      return this.getOutput(items);
    }

    const bucketsPassed: TestBucket[] = tagsOrTestBuckets;

    const items: Item[] = bucketsPassed.map(
      (testBucket: TestBucket): Item => ({
        title: testBucket.title,
        tests: testBucket.tests.length
      })
    );

    return this.getOutput(items);
  }

  private getOutput(items: Item[]): string[] {
    return items
      .sort((bucket1: Item, bucket2: Item) => {
        const result = bucket1.title.localeCompare(bucket2.title, undefined, {
          sensitivity: 'base'
        });
        if (result === 0) {
          return bucket2.tests - bucket1.tests;
        }
        return result;
      })
      .map(({ title, tests }: Item): string => `${title} (${tests ?? 'unknown'} tests)`);
  }
}

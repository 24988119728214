export enum MqAlias {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  LT_SM = 'lt-sm',
  LT_MD = 'lt-md',
  LT_LG = 'lt-lg',
  LT_XL = 'lt-xl',
  GT_XS = 'gt-xs',
  GT_SM = 'gt-sm',
  GT_MD = 'gt-md',
  GT_LG = 'gt-lg'
}

import { Pipe, PipeTransform } from '@angular/core';
import { Session } from '@neuralegion/api';

@Pipe({
  name: 'session',
  pure: true
})
export class SessionPipe implements PipeTransform {
  public transform(value: Session | Session[] | null): string[] | string {
    return Array.isArray(value) ? value.map(this.format) : this.format(value);
  }

  private format(value: Session | null): string {
    return value
      ? [value.deviceName, value.browserName, value.ip].filter(Boolean).join(', ')
      : 'N/A';
  }
}

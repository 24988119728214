import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { subMonths } from '@neuralegion/core';
import { FeedData, FeedSubscription } from '../models';

@Injectable()
export class FeedService {
  constructor(private readonly http: HttpClient) {}

  public loadFeed(offset: number, limit: number): Observable<FeedData> {
    return this.http.get<FeedData>('/api/v1/me/feed', {
      params: {
        offset: offset.toString(10),
        limit: limit.toString(10),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'filter[gt]': subMonths(new Date(), 1).toISOString()
      }
    });
  }

  public markAsSeen(ids: string[]): Observable<void> {
    return this.http.patch<void>('/api/v1/me/feed', { markSeen: ids });
  }

  public markAllAsSeen(): Observable<void> {
    return this.http.patch<void>('/api/v1/me/feed', { markSeenAll: true });
  }

  public clear(id: string): Observable<void> {
    return this.http.delete<void>(`/api/v1/me/feed/activities/${id}`);
  }

  public clearAll(): Observable<void> {
    return this.http.delete<void>('/api/v1/me/feed/activities');
  }

  public loadSubscriptions(): Observable<FeedSubscription[]> {
    return this.http.get<FeedSubscription[]>('/api/v1/me/feed/subscriptions');
  }

  public updateSubscriptions(subscriptions: FeedSubscription[]): Observable<void> {
    return this.http.put<void>('/api/v1/me/feed/subscriptions', subscriptions);
  }
}

import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationParams } from '@neuralegion/api';
import { FilterSerializationService } from './filter-serialization.service';
import { PaginationSerializationService } from './pagination-serialization.service';
import { SortSerializationService } from './sort-serialization.service';

@Injectable({
  providedIn: 'root'
})
export class PaginationParamsSerializationService {
  constructor(
    private readonly filterSerializationService: FilterSerializationService,
    private readonly paginationSerializationService: PaginationSerializationService,
    private readonly sortSerializationService: SortSerializationService
  ) {}

  public convertToHttpParams(params?: PaginationParams): HttpParams | null {
    if (!params) {
      return null;
    }

    return new HttpParams({
      fromString: [
        this.paginationSerializationService.convertToHttpParams(params?.pagination),
        this.filterSerializationService.convertToHttpParams(params?.filter),
        this.sortSerializationService.convertToHttpParams(params?.sort)
      ]
        .filter((item) => !!item?.keys().length)
        .join('&')
    });
  }
}

import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable, map } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectOrgPagePermission } from '../../store';

@Injectable()
export class OrganizationGuard {
  constructor(
    private readonly router: Router,
    private readonly store: Store
  ) {}

  public canActivate(): Observable<boolean | UrlTree> {
    return this.store
      .select(selectOrgPagePermission)
      .pipe(
        map((orgPagePermission: boolean) => (orgPagePermission ? true : this.router.parseUrl('/')))
      );
  }

  public canMatch(): Observable<boolean | UrlTree> {
    return this.canActivate();
  }
}

<div class="feed-panel">
  <div class="close-container">
    <button mat-button (click)="onCloseClick()">Close</button>
  </div>

  <div
    class="header"
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="space-between end"
    fxLayoutAlign.xs="space-between start">
    <div fxFlex="1 0" fxLayout="row" fxLayoutAlign="space-between center">
      <h2>Activity feed</h2>
    </div>

    <div class="header-actions" fxLayout="row" fxLayoutAlign="start center">
      <button class="text-button" mat-button (click)="markAllAsSeen()">
        <mat-icon aria-label="Mark all as seen" matTooltip="Mark all as seen">drafts</mat-icon>
        Mark as seen
      </button>
      <button class="clear-button" mat-button (click)="clearAll()">
        <mat-icon aria-label="Clear all" matTooltip="Clear all">delete_forever</mat-icon>
        Clear
      </button>
    </div>
  </div>

  <!-- using `autosize` from `cdk-experimental` -->
  <cdk-virtual-scroll-viewport autosize element-data-id="list_feed">
    <feed-item
      *cdkVirtualFor="let item of feedActivities$ | async; index as idx; trackBy: trackById"
      element-data-id="list-item_{{ idx }}"
      [debugEnabled]="debugEnabled"
      [item]="item"
      (clear)="clear($event)"
      (click)="onFeedItemClick($event)">
    </feed-item>

    <div *ngIf="pending$ | async" class="spinner" fxLayout="row" fxLayoutAlign="center center">
      <mat-progress-spinner [diameter]="42" [mode]="'indeterminate'"></mat-progress-spinner>
    </div>
  </cdk-virtual-scroll-viewport>
</div>

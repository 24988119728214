import { Action, ActionReducerMap } from '@ngrx/store';
import { AuthProviderAPIState } from '../auth-providers.state';
import { authProviderReducer } from './auth-provider.reducer';
import { githubAuthReducer } from './github-auth.reducer';
import { uiReducer } from './ui.reducer';

export const authProvidersAPIReducer: ActionReducerMap<AuthProviderAPIState, Action> = {
  provider: authProviderReducer,
  github: githubAuthReducer,
  ui: uiReducer
};

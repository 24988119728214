import { Pipe, PipeTransform } from '@angular/core';
import { TableSortDirection } from '@neuralegion/api';

@Pipe({
  name: 'tableSortDirection',
  pure: true
})
export class TableSortDirectionPipe implements PipeTransform {
  public transform(direction: TableSortDirection): string {
    switch (direction) {
      case TableSortDirection.ASC:
        return 'Asc (A → Z)';
      case TableSortDirection.DESC:
        return 'Desc (Z → A)';
      default:
        return direction;
    }
  }
}

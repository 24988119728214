import { HttpClient, HttpContext, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { getFileNameFromResponseContentDisposition } from '@neuralegion/api';
import {
  CLEAR_EMPTY_VALUES_TOKEN,
  FileService,
  UploadAPIService,
  UploadFilePayload
} from '@neuralegion/core';
import { StorageFile } from '../models';

@Injectable()
export class StorageService implements UploadAPIService<UploadFilePayload, StorageFile> {
  constructor(
    private readonly http: HttpClient,
    private readonly fileService: FileService
  ) {}

  public downloadFile(fileId: string): void {
    this.http
      .get(`/api/v1/files/${fileId}`, {
        responseType: 'blob',
        observe: 'response'
      })
      .subscribe((res: HttpResponse<Blob>) => {
        const fileName: string = getFileNameFromResponseContentDisposition(res);
        this.fileService.saveBlob(res.body, fileName);
      });
  }

  public loadFiles(): Observable<StorageFile[]> {
    return this.http.get<StorageFile[]>('/api/v1/files');
  }

  public deleteFiles(ids: string[]): Observable<void> {
    return this.http.delete<void>('/api/v1/files', { body: { ids } });
  }

  public upload(payload: UploadFilePayload): Observable<HttpEvent<StorageFile>> {
    const formData: FormData = new FormData();

    if (payload.file) {
      formData.append('file', payload.file, payload.file.name);
    } else {
      formData.append('url', payload.url);
    }

    if (payload.projectId) {
      formData.append('projectId', payload.projectId);
    }

    return this.http.post<StorageFile>('/api/v1/files', formData, {
      reportProgress: true,
      context: new HttpContext().set(CLEAR_EMPTY_VALUES_TOKEN, false),
      observe: 'events',
      params: payload.discard ? { discard: 'true' } : {}
    });
  }

  public revertRevision(fileId: string, keepAsNew: boolean): Observable<StorageFile> {
    return this.http.delete<StorageFile>(
      `/api/v1/files/${fileId}/revisions/last?keepAsNew=${keepAsNew}`
    );
  }

  public associateFileWithProject(fileId: string, projectId: string): Observable<void> {
    return this.http.patch<void>(`/api/v1/files/${fileId}`, { projectId });
  }
}

import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FeedService } from '../services';
import { FeedEffects } from '../store/feed.effects';
import { feedReducer } from '../store/feed.reducer';

@NgModule({
  imports: [StoreModule.forFeature('feed', feedReducer), EffectsModule.forFeature([FeedEffects])],
  providers: [FeedService]
})
export class FeedApiModule {}

import { Pipe, PipeTransform } from '@angular/core';
import { formatDistance } from '@neuralegion/core';

@Pipe({
  name: 'timeAgo',
  pure: true
})
export class TimeAgoPipe implements PipeTransform {
  public transform(value: number | Date | string): string {
    return !value
      ? ''
      : formatDistance(value instanceof Date ? value : new Date(value), new Date(), {
          addSuffix: true
        });
  }
}

import { Injectable } from '@angular/core';
import { Subject, debounceTime } from 'rxjs';
import { addBreadcrumb, captureException, setUser } from '@sentry/browser';
import { LoggedInUser } from '@neuralegion/api';
import { ExternalErrorReporterService } from '../services';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ErrorType = any;

@Injectable()
export class SentryErrorReporterService extends ExternalErrorReporterService {
  private readonly errorsSubject = new Subject<ErrorType>();

  constructor() {
    super();

    this.errorsSubject.pipe(debounceTime(500)).subscribe((error: ErrorType) => {
      if (error.status && typeof error.error === 'string') {
        addBreadcrumb({
          data: error
        });
        captureException(new Error(`Error ${error.status}`));
      } else {
        captureException(error.originalError || error);
      }
    });
  }

  public setUser(user: LoggedInUser): void {
    setUser(user ? { email: user.email, id: user.id } : null);
  }

  public report(error: ErrorType): void {
    this.errorsSubject.next(error);
  }
}

import { Injectable, TemplateRef } from '@angular/core';
import { Observable, filter, switchMap, take } from 'rxjs';
import { Store } from '@ngrx/store';
import { UserConsent, UserConsents } from '@neuralegion/api';
import { ConfirmModalService } from '@neuralegion/share';
import { selectConsents } from '../store/user-consents.selectors';

@Injectable()
export class ConsentConfirmationService {
  constructor(
    private readonly store: Store,
    private readonly confirmModalService: ConfirmModalService
  ) {}

  public confirmConsent<T = unknown>(
    consent: UserConsent,
    confirmTmpl: TemplateRef<T>,
    confirmTmplContext?: T
  ): Observable<boolean> {
    return this.store.select(selectConsents).pipe(
      take(1),
      filter((consents: UserConsents) => !consents[consent]),
      switchMap(() =>
        this.confirmModalService.confirm({
          contentTemplateRef: confirmTmpl,
          contentTemplateContext: confirmTmplContext,
          buttonOk: 'Proceed anyway'
        })
      ),
      take(1),
      filter((res: boolean) => !res)
    );
  }
}

<mat-card>
  <mat-card-title role="heading" aria-level="1">Reset password</mat-card-title>
  <mat-card-content>
    <div>Enter your new password below</div>

    <auth-reset-password-form (submitted)="onSubmit($event)"> </auth-reset-password-form>

    <auth-link-signin [message]="'Go back to'"></auth-link-signin>
  </mat-card-content>
</mat-card>

import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { Project } from '../models';

export interface ProjectsLookupState extends EntityState<Project> {
  readonly pending: Action[];
  readonly error: string | null;
}

export const projectsLookupEntityAdapter: EntityAdapter<Project> = createEntityAdapter<Project>({
  selectId: (model) => model.id
});

export const projectsLookupInitialState: ProjectsLookupState =
  projectsLookupEntityAdapter.getInitialState({
    pending: [],
    error: null
  });

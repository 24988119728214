import { coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import { Subject, distinctUntilChanged, filter, map, takeUntil } from 'rxjs';
import equal from 'fast-deep-equal/es6';
import { trackByIdentity } from '@neuralegion/core';
import { CustomValidators, extractTouchedChanges } from '../../form';

interface FormValue {
  predefined: number | null;
  custom: number | null;
}

@Component({
  selector: 'share-custom-number-select',
  templateUrl: './custom-number-select.component.html',
  styleUrls: ['./custom-number-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: CustomNumberSelectComponent, multi: true },
    { provide: NG_VALIDATORS, useExisting: CustomNumberSelectComponent, multi: true }
  ]
})
export class CustomNumberSelectComponent
  implements OnInit, OnDestroy, ControlValueAccessor, Validator
{
  @Input()
  public required = false;

  @Input()
  public options: number[] = [1, 5, 10, 20, 50];

  @Input()
  set min(min: number) {
    // eslint-disable-next-line no-underscore-dangle
    this._min = min;
  }

  @Input()
  set max(min: number) {
    // eslint-disable-next-line no-underscore-dangle
    this._max = min;
  }

  get min(): number {
    // eslint-disable-next-line no-underscore-dangle
    return Math.min(...this.options, coerceNumberProperty(this._min));
  }

  get max(): number {
    // eslint-disable-next-line no-underscore-dangle
    return Math.max(...this.options, coerceNumberProperty(this._max));
  }

  public readonly trackByIdentity = trackByIdentity;

  public form: FormGroup;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _min: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _max: number;

  private onChange: (value: number) => void;
  private onTouched: () => void;

  private readonly valueSubject = new Subject<number>();
  private readonly gc = new Subject<void>();

  public ngOnInit() {
    this.form = new FormGroup({
      predefined: new FormControl(null),
      custom: new FormControl(null, [
        CustomValidators.integer,
        Validators.min(this.min),
        Validators.max(this.max)
      ])
    });

    if (coerceBooleanProperty(this.required)) {
      this.form.addValidators(this.atLeastOneDefined);
    }

    this.initValueSubjectListener();
    this.initTouchedListener();
    this.initFormListener();
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }

  public validate(): ValidationErrors | null {
    return this.form.invalid ? { poolSize: true } : null;
  }

  public registerOnChange(fn: (value: number) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  public writeValue(value: number): void {
    this.valueSubject.next(value);
  }

  private initValueSubjectListener(): void {
    this.valueSubject
      .pipe(
        map(
          (value?: number | null): FormValue =>
            this.options.includes(value)
              ? { custom: null, predefined: value }
              : { custom: value ?? null, predefined: null }
        ),
        filter((value: FormValue) => !equal(value, this.form.value)),
        takeUntil(this.gc)
      )
      .subscribe((value: FormValue) => {
        this.form.setValue(value);
      });
  }

  private initTouchedListener(): void {
    extractTouchedChanges(this.form)
      .pipe(
        filter((touched: boolean) => touched),
        takeUntil(this.gc)
      )
      .subscribe(() => {
        this.onTouched?.();
      });
  }

  private initFormListener(): void {
    this.form.controls.predefined.valueChanges
      .pipe(
        filter((value) => value !== null),
        takeUntil(this.gc)
      )
      .subscribe(() => {
        this.form.controls.custom.removeValidators(Validators.required);
        this.form.controls.custom.setValue(null);
      });

    this.form.controls.custom.valueChanges
      .pipe(
        filter((value) => value !== null),
        takeUntil(this.gc)
      )
      .subscribe(() => {
        this.form.controls.custom.addValidators(Validators.required);
        this.form.controls.predefined.setValue(null);
      });

    this.form.valueChanges.pipe(distinctUntilChanged(equal), takeUntil(this.gc)).subscribe(() => {
      const rawValue: { predefined: number; custom: number } = this.form.getRawValue();
      this.onChange?.(rawValue.predefined ?? rawValue?.custom);
    });
  }

  private atLeastOneDefined(control: AbstractControl): ValidationErrors | null {
    const value: FormValue = control.value;
    return value.predefined === null && value.custom === null ? { required: true } : null;
  }
}

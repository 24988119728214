import { Action } from '@ngrx/store';
import { StorageFile } from '../models';

export interface StorageState {
  files: StorageFile[];
  pending: Action[];
  error: string;
}

export const initialStorageState: StorageState = {
  files: [],
  pending: [],
  error: null
};

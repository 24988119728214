// eslint-disable-next-line max-len
export const REGEX_IP =
  '^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$';

// eslint-disable-next-line max-len
const REGEX_WILDCARD_IP =
  '^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]|\\*)\\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]|\\*)(:[0-9]+)?$';

const ALLOWED_SCHEMAS: string[] = ['http:', 'https:'];

export const parseUrl = (value: string): URL | null => {
  try {
    return new URL(value);
  } catch {
    return null;
  }
};

export const isValidHost = (value: string, allowPort = true): boolean => {
  if (!value) {
    return true;
  }

  // ADHOC: when the input comes with the https port number (443) specified explicitly: "example.com:443"
  // then URL parser omits the port number from "host" and "port" entries probably considering it
  // as default one for the "https://" schema. To prevent this side effect an extra check enforced.
  const lowerCaseValue = value?.toLowerCase();

  const url = parseUrl(`https://${value}`);
  return !!(
    url &&
    url.pathname === '/' &&
    (!url.port || allowPort) &&
    (url.host === lowerCaseValue ||
      (allowPort && !url.port && `${url.host}:443` === lowerCaseValue)) &&
    [url.search, url.username, url.password, url.hash].every((item) => item === '')
  );
};

export const isValidIp = (value: string): boolean => {
  if (!value) {
    return true;
  }

  return RegExp(REGEX_IP).test(value);
};

export const isValidWildcardHost = (value: string): boolean => {
  if (!value) {
    return true;
  }

  return (
    isValidHost(value.replace(/\*/g, 'x')) &&
    value.split('.').every((part: string) => part === '*' || !part.includes('*'))
  );
};

export const isValidWildcardIp = (value: string): boolean => RegExp(REGEX_WILDCARD_IP).test(value);

export const isValidUrl = (value: string, strict: boolean = true): boolean => {
  if (!value) {
    return true;
  }

  const url: URL = parseUrl(strict ? value : value.includes('://') ? value : `https://${value}`);
  return !!(url && ALLOWED_SCHEMAS.includes(url.protocol));
};

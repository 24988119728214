import { createFeatureSelector, createSelector } from '@ngrx/store';
import { User } from '@neuralegion/api';
import { ProfileState } from './profile.state';

// eslint-disable-next-line @typescript-eslint/ban-types
type State = object;
export const selectProfileState = createFeatureSelector<ProfileState>('profile');

export const selectProfile = createSelector<State, [ProfileState], User>(
  selectProfileState,
  (state: ProfileState) => state.entity
);

export const selectProfilePendingStatus = createSelector<State, [ProfileState], boolean>(
  selectProfileState,
  (state: ProfileState) => state.pending.length > 0
);

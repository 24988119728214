<mat-form-field>
  <mat-label>{{ options.label }}</mat-label>

  <mat-error [matErrorOptions]="{ fieldName: options.label }"> </mat-error>

  <input
    type="text"
    #matAutocompleteTrigger="matAutocompleteTrigger"
    matInput
    [formControl]="inputControl"
    [matAutocomplete]="matAutocomplete"
    [placeholder]="options.placeholder"
    (keydown)="onEnterPressed($event)" />
  <mat-autocomplete
    class="autocomplete-panel"
    #matAutocomplete="matAutocomplete"
    [displayWith]="options.displayFn"
    (optionSelected)="onItemSelected($event)">
    <mat-option *ngFor="let item of items; trackBy: trackByIdentity" [value]="item">
      <div fxLayoutAlign="space-between center" fxLayoutGap="8px">
        <ng-container *ngTemplateOutlet="options.tmplItem; context: { $implicit: item }"></ng-container>
        <span *ngIf="!options.tmplItem" class="text-item">{{ item }}</span>
        <button *ngIf="options.itemsRemovable" mat-icon-button (click)="onDeleteClick($event, item)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </mat-option>
  </mat-autocomplete>

  <button
    *ngIf="inputControl.value && inputControl.valid"
    aria-label="Clear"
    mat-icon-button
    matSuffix
    matTooltip="Clear"
    [disabled]="inputControl.disabled"
    (click)="onClearClick()">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>

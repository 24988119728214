import { HttpClient, HttpContext, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, interval, map, of, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { ApiVersion } from '@neuralegion/api';
import { SessionStorageService } from '@neuralegion/browser-storage';
import { HTTP_ERROR_HANDLER_OPTIONS } from '@neuralegion/error-handler';
import { updateAvailable } from '../store';

@Injectable()
export class ApiVersionService {
  private readonly VERSION_KEY = 'apiVersionNumber';
  private readonly CORS_OR_CONNECTION_FAILED_STATUS = 0;
  private readonly VERSION_CHECK_INTERVAL_MS = 1000 * 60 * 30;

  private versionInternal: string;

  get version(): string {
    return this.versionInternal;
  }

  set version(value: string) {
    if (!this.versionInternal || this.versionInternal !== value) {
      this.writeVersion(value);
    }

    this.versionInternal = value;
  }

  constructor(
    private readonly sessionStorageService: SessionStorageService,
    private readonly http: HttpClient,
    private readonly store: Store
  ) {
    this.versionInternal = this.readVersion();
    this.initPeriodicCheck();
  }

  public clearApiVersion(): void {
    this.versionInternal = null;
    this.sessionStorageService.delete(this.VERSION_KEY);
  }

  private readVersion(): string {
    return this.sessionStorageService.get(this.VERSION_KEY) as string;
  }

  private writeVersion(value: string) {
    this.sessionStorageService.set(this.VERSION_KEY, value);
  }

  private loadVersion(): Observable<string> {
    return this.http
      .get<ApiVersion>('/api/v1/version', {
        context: new HttpContext().set(HTTP_ERROR_HANDLER_OPTIONS, (error: HttpErrorResponse) => ({
          skipNotificationPredicate: () => true,
          skipReport: error.status === this.CORS_OR_CONNECTION_FAILED_STATUS
        }))
      })
      .pipe(map((res: ApiVersion) => res.version));
  }

  private initPeriodicCheck() {
    interval(this.VERSION_CHECK_INTERVAL_MS)
      .pipe(switchMap(() => this.loadVersion().pipe(catchError(() => of('')))))
      .subscribe((version: string) => {
        if (version && this.version !== version) {
          this.store.dispatch(updateAvailable());
        }
      });
  }
}

import { Clipboard } from '@angular/cdk/clipboard';
import { Injectable } from '@angular/core';
import { SnackbarService } from './snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class ClipboardService {
  constructor(
    private readonly clipboard: Clipboard,
    private readonly snackbarService: SnackbarService
  ) {}

  public copyToClipboard(str: string, successMessage: string): boolean {
    if (this.clipboard.copy(str)) {
      if (successMessage) {
        this.snackbarService.open(successMessage);
      }
      return true;
    }
    this.snackbarService.open('Copy failed. Please try again.', true);
    return false;
  }
}

import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'share-open-new-tab-link',
  templateUrl: './open-new-tab-link.component.html',
  styleUrls: ['./open-new-tab-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpenNewTabLinkComponent {
  @Input('aria-label')
  public ariaLabel = 'Open in a new tab';

  @Input()
  public href: string;

  @Input()
  public routerLink: string | string[];

  @Input()
  public color: ThemePalette;

  @HostBinding('attr.tabindex')
  public tabIndex = -1;
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Role } from '@neuralegion/api';

@Injectable()
export class RolesService {
  constructor(private readonly http: HttpClient) {}

  public loadRoles(orgId: string): Observable<Role[]> {
    return this.http.get<Role[]>(`/api/v1/orgs/${orgId}/roles`);
  }

  public addRole(orgId: string, role: Omit<Role, 'id'>): Observable<Role> {
    return this.http.post<Role>(`/api/v1/orgs/${orgId}/roles`, role);
  }

  public updateRole(orgId: string, role: Role): Observable<Role> {
    return this.http.put<Role>(`/api/v1/orgs/${orgId}/roles/${role.id}`, role);
  }

  public removeRole(orgId: string, roleId: string): Observable<void> {
    return this.http.delete<void>(`/api/v1/orgs/${orgId}/roles/${roleId}`);
  }
}

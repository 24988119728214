import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, exhaustMap, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Scope } from '@neuralegion/api';
import { selectScopePermission } from '@neuralegion/auth-api';
import { AppRouterState, ofPrimaryRouterNavigated } from '@neuralegion/core';
import { PaymentMethod } from '../models';
import { PaymentMethodsService } from '../services';
import { billingRefresh } from './billing.actions';
import {
  addPaymentMethod,
  addPaymentMethodFail,
  addPaymentMethodSuccess,
  changeDefaultPaymentMethod,
  changeDefaultPaymentMethodFail,
  changeDefaultPaymentMethodSuccess,
  loadPaymentMethods,
  loadPaymentMethodsFail,
  loadPaymentMethodsSuccess,
  removePaymentMethod,
  removePaymentMethodFail,
  removePaymentMethodSuccess,
  updatePaymentMethod,
  updatePaymentMethodFail,
  updatePaymentMethodSuccess
} from './payment-methods.actions';

@Injectable()
export class PaymentMethodsEffects {
  public readonly addPaymentMethod$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(addPaymentMethod),
      switchMap(
        (action: ReturnType<typeof addPaymentMethod>): Observable<Action> =>
          this.paymentMethodsService.addPaymentMethod(action.payload.paymentMethod).pipe(
            map((paymentMethod: PaymentMethod) => addPaymentMethodSuccess({ paymentMethod })),
            catchError((err: HttpErrorResponse) => of(addPaymentMethodFail(err.error)))
          )
      )
    )
  );

  public readonly changeActivePaymentMethod$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(changeDefaultPaymentMethod),
      switchMap(
        (action: ReturnType<typeof changeDefaultPaymentMethod>): Observable<Action> =>
          this.paymentMethodsService
            .changeDefaultPaymentMethod(action.payload.paymentMethodId)
            .pipe(
              map(() => changeDefaultPaymentMethodSuccess(action.payload)),
              catchError((err: HttpErrorResponse) => of(changeDefaultPaymentMethodFail(err.error)))
            )
      )
    )
  );

  public readonly deletePaymentMethod$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(removePaymentMethod),
      switchMap(
        (action: ReturnType<typeof removePaymentMethod>): Observable<Action> =>
          this.paymentMethodsService.deletePaymentMethod(action.payload.paymentMethodId).pipe(
            map(() => removePaymentMethodSuccess(action.payload)),
            catchError((err: HttpErrorResponse) => of(removePaymentMethodFail(err.error)))
          )
      )
    )
  );

  public readonly loadPaymentMethod$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPaymentMethods),
      withLatestFrom(this.store.select(selectScopePermission(Scope.PAYMENT_METHODS))),
      exhaustMap(
        ([, paymentMethodsPermission]: [
          ReturnType<typeof loadPaymentMethods>,
          boolean
        ]): Observable<Action> => {
          if (!paymentMethodsPermission) {
            return of(loadPaymentMethodsFail('Forbidden'));
          }

          return this.paymentMethodsService.loadPaymentMethods().pipe(
            map((paymentMethods: PaymentMethod[]) => loadPaymentMethodsSuccess({ paymentMethods })),
            catchError((err: HttpErrorResponse) => of(loadPaymentMethodsFail(err.error)))
          );
        }
      )
    )
  );

  public readonly updatePaymentMethod$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePaymentMethod),
      switchMap(
        (action: ReturnType<typeof updatePaymentMethod>): Observable<Action> =>
          this.paymentMethodsService.updatePaymentMethod(action.payload.paymentMethod).pipe(
            map((paymentMethod: PaymentMethod) => updatePaymentMethodSuccess({ paymentMethod })),
            catchError((err: HttpErrorResponse) => of(updatePaymentMethodFail(err.error)))
          )
      )
    )
  );

  public readonly refresh$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(billingRefresh),
      switchMap(() => [loadPaymentMethods()])
    )
  );

  public readonly redirectOnSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addPaymentMethodSuccess, removePaymentMethodSuccess, updatePaymentMethodSuccess),
        tap(() => {
          void this.router.navigate(['/billing']);
        })
      ),
    { dispatch: false }
  );

  public readonly navigated$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofPrimaryRouterNavigated((state: AppRouterState) => state.pathname.startsWith('/billing')),
      switchMap(() => [loadPaymentMethods()])
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly store: Store,
    private readonly paymentMethodsService: PaymentMethodsService
  ) {}
}

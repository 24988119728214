import { Pipe, PipeTransform } from '@angular/core';
import { RequestExclusion } from '@neuralegion/api';

@Pipe({
  name: 'requestExclusions',
  pure: true
})
export class RequestExclusionsPipe implements PipeTransform {
  public transform(exclusions: RequestExclusion[]): string[] {
    return exclusions.flatMap(({ methods, patterns }) =>
      patterns.map(
        (pattern) =>
          `• ${pattern} (included methods: ${methods.length ? methods.join(', ') : 'all'})`
      )
    );
  }
}

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

interface FontProxy {
  family: string;
  style: string;
  weight: string | string[];
  unicodeRange?: string;
  source: string;
  preload?: boolean;
}

interface FontFaceSetExt extends FontFaceSet {
  values: () => IterableIterator<FontFace>;
}

@Injectable({
  providedIn: 'root'
})
export class FontProxyService {
  private readonly fontProxies: FontProxy[] = [
    {
      family: 'Material Icons',
      style: 'normal',
      weight: ['400', 'normal'], // Safari uses "normal" as Material Icons font weight
      source: '/assets/fonts/material-icons.css',
      preload: true
    }
  ];

  constructor(@Inject(DOCUMENT) private readonly document: Document) {
    this.fontProxies.forEach((proxy: FontProxy) => this.registerFontProxy(proxy));
  }

  private async registerFontProxy(proxy: FontProxy): Promise<void> {
    const fonts: FontFace[] = [];
    ((await this.document.fonts.ready) as FontFaceSetExt).forEach((font: FontFace) =>
      fonts.push(font)
    );

    const font: FontFace = this.findFontFace(fonts, proxy);

    if (font?.status === 'loaded') {
      return;
    }

    try {
      if (font.status === 'unloaded' && proxy.preload) {
        await font.load();
      } else {
        await font.loaded;
      }
    } catch (err) {
      this.addFont(proxy.source);
    }
  }

  private findFontFace(fonts: FontFace[], proxy: FontProxy): FontFace | undefined {
    return fonts.find((font: FontFace) =>
      // Firefox has font family quoted with "", i.e { family: '"Material Icons"' }
      font.family?.replaceAll('"', '') === proxy.family &&
      font.style === proxy.style &&
      Array.isArray(proxy.weight)
        ? proxy.weight.includes(font.weight)
        : font.weight === proxy.weight &&
          (proxy.unicodeRange ? font.unicodeRange === proxy.unicodeRange : true)
    );
  }

  private addFont(source: string): void {
    const link: HTMLLinkElement = this.document.createElement('link');
    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('href', source);
    link.setAttribute('referrerpolicy', 'no-referrer');
    this.document.head.appendChild(link);
  }
}

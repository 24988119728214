import { Pipe, PipeTransform } from '@angular/core';
import { FeedActivityObjectType, FeedVerb } from '@neuralegion/feed-api';
import { VerbPastTensePipe } from '@neuralegion/share';

@Pipe({
  name: 'feedVerbPastTense',
  pure: true
})
export class FeedVerbPastTensePipe implements PipeTransform {
  private readonly verbPastTensePipe = new VerbPastTensePipe();

  public transform(verb: FeedVerb, type: FeedActivityObjectType, short: boolean = false): string {
    if (verb === FeedVerb.REMOVE || verb === FeedVerb.UNSUBSCRIBE) {
      return 'deleted';
    }

    if (verb === FeedVerb.FINISH && type === FeedActivityObjectType.SCAN) {
      return 'completed';
    }

    if (type) {
      if (type === FeedActivityObjectType.SCAN) {
        // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
        switch (verb) {
          case FeedVerb.START:
          case FeedVerb.SCHEDULE:
            return 'initiated';
          case FeedVerb.FAIL:
            return short ? 'failed' : 'failed to initiate';
        }
      }

      if (type === FeedActivityObjectType.ISSUE) {
        if (verb === FeedVerb.CLOSE) {
          return 'resolved';
        }
        if (verb === FeedVerb.OPEN) {
          return 'found';
        }
      }

      if (type === FeedActivityObjectType.SUBSCRIPTION) {
        if (verb === FeedVerb.SUBSCRIBE) {
          return 'added';
        }
      }

      if (short && type === FeedActivityObjectType.COMMENT) {
        return 'commented';
      }

      if (type === FeedActivityObjectType.REPEATER) {
        if (verb === FeedVerb.STOP) {
          return 'disconnected';
        }
        if (verb === FeedVerb.FAIL) {
          return 'timeout';
        }
      }
    }

    return this.verbPastTensePipe.transform(verb);
  }
}

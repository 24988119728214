import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Subject, distinctUntilChanged, takeUntil } from 'rxjs';
import equal from 'fast-deep-equal/es6';
import { ColumnConfig } from '@neuralegion/api';
import { trackByIdentity } from '@neuralegion/core';

@Component({
  selector: 'share-columns-config',
  templateUrl: './columns-config.component.html',
  styleUrls: ['./columns-config.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnsConfigComponent implements OnInit, OnDestroy {
  @Input()
  set columnsConfig(value: ColumnConfig[]) {
    this.valueSubject.next(value);
  }

  @Output()
  public readonly columnsConfigChanged = new EventEmitter<ColumnConfig[]>();

  public readonly trackByIdentity = trackByIdentity;

  public form: FormGroup = new FormGroup({ columns: new FormArray([]) });

  get columnsFormGroups(): FormGroup[] {
    return (this.form.get('columns') as FormArray).controls as FormGroup[];
  }

  public valueSubject = new BehaviorSubject<ColumnConfig[]>([]);

  private readonly gc = new Subject<void>();

  public ngOnInit(): void {
    this.form.setControl(
      'columns',
      new FormArray(
        this.valueSubject.value.map(
          () =>
            new FormGroup({
              id: new FormControl(''),
              active: new FormControl(false),
              width: new FormControl(0),
              label: new FormControl(''),
              fixed: new FormControl(false)
            })
        )
      )
    );

    this.form.valueChanges
      .pipe(distinctUntilChanged(equal), takeUntil(this.gc))
      .subscribe(({ columns }: { columns: { id: string; active: boolean; width: number }[] }) => {
        const columnsConfig = this.valueSubject.getValue();
        this.columnsConfigChanged.emit(
          columnsConfig.map((columnConfig: ColumnConfig) => {
            const column = columns.find(
              (c: { id: string; active: boolean; width: number }) => c.id === columnConfig.id
            );
            return column
              ? {
                  ...columnConfig,
                  active: column.active,
                  width: column.width || 0
                }
              : { ...columnConfig };
          })
        );
      });

    this.valueSubject
      .pipe(distinctUntilChanged(equal), takeUntil(this.gc))
      .subscribe((columnsConfig: ColumnConfig[]) => {
        this.form.setValue(
          {
            columns: columnsConfig.map((c: ColumnConfig) => ({
              id: c.id,
              active: c.active,
              width: c.width || 0,
              label: c.label,
              fixed: c.fixed || false
            }))
          },
          { emitEvent: false }
        );

        this.setDisabledStateForFixed(columnsConfig);

        this.updateWidthControlsStatuses();
      });
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }

  public onDrop(e: CdkDragDrop<undefined>): void {
    const reordered = [...this.valueSubject.getValue()];
    moveItemInArray(reordered, e.previousIndex, e.currentIndex);
    moveItemInArray(
      (this.form.get('columns') as FormArray).controls,
      e.previousIndex,
      e.currentIndex
    );
    this.valueSubject.next(reordered);
  }

  private setDisabledStateForFixed(value: ColumnConfig[]): void {
    value.forEach((c: ColumnConfig, idx: number) => {
      if (c.fixed) {
        (this.form.get('columns') as FormArray).controls[idx].disable({ emitEvent: false });
      } else {
        (this.form.get('columns') as FormArray).controls[idx].enable({ emitEvent: false });
      }
    });
  }

  private updateWidthControlsStatuses(): void {
    (this.form.get('columns') as FormArray).controls.forEach((formGroup: AbstractControl) => {
      if (!formGroup.value.active) {
        if (formGroup.get('width').enabled) {
          formGroup.get('width').disable({ emitEvent: false });
        }
      } else {
        if (formGroup.get('width').disabled) {
          formGroup.get('width').enable({ emitEvent: false });
        }
      }
    });
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { DateRange, Filter, FilterType, NumberCompareFilter, SelectItem } from '@neuralegion/api';
import { ComparisonOperatorNamePipe } from './comparison-operator-name.pipe';
import { DateRangePipe } from './date-range.pipe';

@Pipe({
  name: 'filterValue',
  pure: true
})
export class FilterValuePipe implements PipeTransform {
  constructor(
    private readonly comparisonOperatorNamePipe: ComparisonOperatorNamePipe,
    private readonly dateRangePipe: DateRangePipe
  ) {}

  public transform(filter: Filter): string {
    switch (filter.type) {
      case FilterType.DATE_RANGE:
        return this.dateRangePipe.transform(filter.value as DateRange);
      case FilterType.NUMBER_COMPARE: {
        const filterValue = (filter as NumberCompareFilter).value;
        return `${filterValue.item.label} ${this.comparisonOperatorNamePipe.transform(
          filterValue.operator
        )} ${filterValue.value}`;
      }
      case FilterType.SELECT:
      case FilterType.ASYNC_SELECT:
        return (filter.value as SelectItem).label;
      default:
        // eslint-disable-next-line @typescript-eslint/no-base-to-string
        return filter.value.toString();
    }
  }
}

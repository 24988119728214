<ng-container *ngIf="{ code: code$ | async, prettifyResult: prettifyResult$ | async } as sync">
  <div class="prettyprint">
    <p *ngIf="!sync.prettifyResult?.code && !sync.prettifyResult?.hexLines; else tmplCode" class="code-missing">
      Nothing to display
    </p>

    <ng-template #tmplCode>
      <share-code-virtual-scroll *ngIf="sync.prettifyResult?.binary === false" [code$]="prettifiedCode$">
      </share-code-virtual-scroll>

      <share-code-hex-virtual-scroll *ngIf="sync.prettifyResult?.binary === true" [prettifyResult$]="prettifyResult$">
      </share-code-hex-virtual-scroll>
    </ng-template>
  </div>
</ng-container>

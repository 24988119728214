import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import {
  MAT_DATE_RANGE_SELECTION_STRATEGY,
  MatCalendar,
  DateRange as MatDateRange
} from '@angular/material/datepicker';
import { DateRange } from '@neuralegion/api';
import { DateAdapterMatCalendar, UserDateService } from '@neuralegion/core';
import { BidirectionalSelectionRangeStrategy } from '../../services';

@Component({
  selector: 'share-calendar-range-picker',
  templateUrl: './calendar-range-picker.component.html',
  styleUrls: ['./calendar-range-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: BidirectionalSelectionRangeStrategy
    },
    { provide: DateAdapter, useClass: DateAdapterMatCalendar }
  ]
})
export class CalendarRangePickerComponent {
  @Input()
  public minDate: Date = null;

  @Input()
  public maxDate: Date = null;

  @Input()
  set dateRange(value: DateRange) {
    this.range = value ? new MatDateRange(value.startDate, value.endDate) : null;
  }

  @Output()
  public readonly dateRangeChanged: EventEmitter<DateRange> = new EventEmitter<DateRange>();

  @ViewChild(MatCalendar)
  private readonly matCalendar: MatCalendar<Date>;

  public range: MatDateRange<Date> | null = null;

  constructor(private readonly userDateService: UserDateService) {}

  public onDateRangeChange(date: Date): void {
    if (!this.range || (this.range.start && this.range.end)) {
      this.range = new MatDateRange(date, null);
      return;
    }

    this.range =
      date >= this.range.start
        ? new MatDateRange(this.range.start, date)
        : new MatDateRange(date, this.range.start);

    this.emitChangedRange(this.range);
  }

  public onPredefinedRangeChanged(range: MatDateRange<Date>): void {
    this.range = range;
    this.moveActiveDateInView();
    this.emitChangedRange(range);
  }

  private emitChangedRange(range: MatDateRange<Date>): void {
    this.dateRangeChanged.emit(
      this.userDateService.clearDateRangeTime({ startDate: range.start, endDate: range.end }, true)
    );
  }

  private moveActiveDateInView(): void {
    const { activeDate } = this.matCalendar;
    const { start, end } = this.range;
    if (activeDate.getMonth() !== start.getMonth() && activeDate.getMonth() !== end.getMonth()) {
      this.matCalendar.activeDate = this.userDateService.applyTz(new Date(end));
    }
  }
}

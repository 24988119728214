import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ERROR_HANDLERS_TOKEN } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerWrapperService implements ErrorHandler {
  constructor(private readonly injector: Injector) {}

  public handleError(error: Error): void {
    console.error(error);

    const handlers = this.injector.get<ErrorHandler[]>(ERROR_HANDLERS_TOKEN, []);
    handlers?.forEach((errorHandler: ErrorHandler) => {
      errorHandler.handleError(error);
    });
  }
}

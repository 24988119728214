import { createAction } from '@ngrx/store';
import { Organization, Quota } from '@neuralegion/api';

export const loadOrganization = createAction(
  '[Organization] LOAD',
  (payload: { skipRelated: boolean }) => ({
    payload
  })
);

export const loadOrganizationSuccess = createAction(
  '[Organization] LOAD_SUCCESS',
  (payload: { organization: Organization; skipRelated: boolean }) => ({
    payload,
    parentType: loadOrganization.type
  })
);

export const loadOrganizationFail = createAction('[Organization] LOAD_FAIL', (payload: string) => ({
  payload,
  parentType: loadOrganization.type
}));

export const loadQuotas = createAction(
  '[Organization] LOAD_QUOTAS',
  (payload: { orgId: string }) => ({ payload })
);

export const loadQuotasSuccess = createAction(
  '[Organization] LOAD_QUOTAS_SUCCESS',
  (payload: { quotas: Quota[] }) => ({
    payload,
    parentType: loadQuotas.type
  })
);

export const loadQuotasFail = createAction(
  '[Organization] LOAD_QUOTAS_FAIL',
  (payload: string) => ({
    payload,
    parentType: loadQuotas.type
  })
);

export const updateOrganization = createAction(
  '[Organization] UPDATE',
  (payload: { organization: Partial<Organization> }) => ({ payload })
);

export const updateOrganizationSuccess = createAction('[Organization] UPDATE_SUCCESS', () => ({
  parentType: updateOrganization.type
}));

export const updateOrganizationFail = createAction(
  '[Organization] UPDATE_FAIL',
  (payload: string) => ({
    payload,
    parentType: updateOrganization.type
  })
);

<share-list-control
  #parentControl
  [formControl]="listControl"
  [settings]="{
    copyAll,
    labelAddItem: 'Add header',
    labelCopyAll: 'Copy all headers',
    itemsDelimiter: '\n',
    validationTrigger$: settings.validationTrigger$,
    emptyPlaceholder: settings.emptyPlaceholder,
    defaultValueFactory: defaultValueFactory
  }"
  [tmplItem]="tmplItem">
  <ng-container list-control-header>
    <ng-content select=".headers-editor-label"></ng-content>
  </ng-container>
  <ng-container list-control-subheader>
    <ng-content></ng-content>
  </ng-container>
</share-list-control>

<ng-template #tmplItem let-control let-idx="idx">
  <share-header-list-item-control
    class="full-width"
    [autocompleteOptions]="autocompleteOptions"
    [formControl]="control"
    [idx]="idx"
    [listControlComponent]="parentControl"
    [settings]="settings"
    [strategyMode]="strategyMode"></share-header-list-item-control>
</ng-template>

import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {
  BillingContactInfoService,
  BillingService,
  CountriesService,
  PaymentMethodsService,
  PaymentsService,
  StatesService
} from '../services';
import { BillingEffects } from '../store/billing.effects';
import { billingReducer } from '../store/billing.reducer';
import { PaymentMethodsEffects } from '../store/payment-methods.effects';
import { paymentMethodsReducer } from '../store/payment-methods.reducer';
import { PaymentsEffects } from '../store/payments.effects';
import { paymentsReducer } from '../store/payments.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature('billing', billingReducer),
    StoreModule.forFeature('paymentMethods', paymentMethodsReducer),
    StoreModule.forFeature('payments', paymentsReducer),
    EffectsModule.forFeature([BillingEffects, PaymentMethodsEffects, PaymentsEffects])
  ],
  providers: [
    BillingService,
    BillingContactInfoService,
    CountriesService,
    PaymentMethodsService,
    PaymentsService,
    StatesService
  ]
})
export class BillingApiModule {}

import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { Subject, filter, takeUntil } from 'rxjs';
import equal from 'fast-deep-equal/es6';
import {
  DateRange,
  DateRangeFilter,
  DateRangeFilterSettings,
  Filter,
  FilterConfig
} from '@neuralegion/api';
import { extractTouchedChanges } from '../../../form';
import { FilterControl } from '../../../models';

// eslint-disable-next-line @angular-eslint/use-component-selector
@Component({
  templateUrl: './date-range-filter-control.component.html',
  styleUrls: ['./date-range-filter-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: DateRangeFilterControlComponent, multi: true },
    { provide: NG_VALIDATORS, useExisting: DateRangeFilterControlComponent, multi: true }
  ]
})
export class DateRangeFilterControlComponent implements FilterControl, OnInit, OnDestroy {
  @Input()
  public filterConfig: FilterConfig;

  @Input()
  public filters: Filter[];

  @Input()
  public filterLabelsMap: ReadonlyMap<string, string>;

  get filterConfigSettings(): DateRangeFilterSettings {
    return this.filterConfig.settings as DateRangeFilterSettings;
  }

  get minDateFilter(): DateRangeFilter {
    return this.filters.find(
      (f) => f.name === this.filterConfigSettings.minDateFilterName
    ) as DateRangeFilter;
  }

  get maxDateFilter(): DateRangeFilter {
    return this.filters.find(
      (f) => f.name === this.filterConfigSettings.maxDateFilterName
    ) as DateRangeFilter;
  }

  public readonly formControl = new FormControl(null, [Validators.required]);

  private onChange: (value: DateRange) => void;
  private onTouched: () => void;

  private readonly valueSubject = new Subject<DateRange>();
  private readonly gc = new Subject<void>();

  public ngOnInit() {
    this.initTouchedListener();
    this.initValueSubjectListener();
    this.initFormControlListener();
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.complete();
  }

  public registerOnChange(fn: (value: DateRange) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  public validate(): ValidationErrors | null {
    return this.formControl.valid ? null : { dateRangeFilterControl: true };
  }

  public writeValue(value: DateRange): void {
    this.valueSubject.next(value);
  }

  public updateValue(value: DateRange): void {
    this.formControl.setValue(value);
    this.formControl.markAsTouched();
  }

  private initTouchedListener(): void {
    extractTouchedChanges(this.formControl)
      .pipe(
        filter((touched) => touched),
        takeUntil(this.gc)
      )
      .subscribe(() => this.onTouched?.());
  }

  private initValueSubjectListener(): void {
    this.valueSubject
      .pipe(
        filter((value: DateRange) => !equal(this.formControl.value, value)),
        takeUntil(this.gc)
      )
      .subscribe((value: DateRange) => this.formControl.setValue(value));
  }

  private initFormControlListener(): void {
    this.formControl.valueChanges
      .pipe(takeUntil(this.gc))
      .subscribe((value: DateRange) => this.onChange?.(value));
  }
}

<a
  *ngIf="href; else internal"
  class="btn-open-new-tab"
  [attr.aria-label]="ariaLabel"
  element-data-id="link_open_in_new_tab"
  mat-icon-button
  matTooltip="Open in a new tab"
  target="_blank"
  [href]="href"
  (click)="$event.stopPropagation()">
  <mat-icon [color]="color">open_in_new</mat-icon>
</a>
<ng-template #internal>
  <a
    class="btn-open-new-tab"
    [attr.aria-label]="ariaLabel"
    element-data-id="link_open_in_new_tab"
    mat-icon-button
    matTooltip="Open in a new tab"
    target="_blank"
    [routerLink]="routerLink"
    (click)="$event.stopPropagation()">
    <mat-icon [color]="color">open_in_new</mat-icon>
  </a>
</ng-template>

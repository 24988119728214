import { Action, createReducer, on } from '@ngrx/store';
import { cleanupPendingState } from '@neuralegion/core';
import {
  acceptInvite,
  acceptInviteFail,
  acceptInviteSuccess,
  loadInviteDetails,
  loadInviteDetailsFail,
  loadInviteDetailsSuccess,
  signup,
  signupFail,
  signupSuccess
} from './signup.actions';
import { SignupState, signupInitialState } from './signup.state';

export const signupReducer = createReducer<SignupState, Action>(
  signupInitialState,
  on(
    acceptInvite,
    (state: SignupState, action): SignupState => ({
      ...state,
      pending: [...state.pending, action]
    })
  ),
  on(
    loadInviteDetails,
    (state: SignupState, action): SignupState => ({
      ...state,
      inviteDetails: null,
      pending: [...state.pending, action]
    })
  ),
  on(
    signup,
    (state: SignupState, action): SignupState => ({
      ...state,
      error: null,
      pending: [...state.pending, action]
    })
  ),
  on(
    acceptInviteSuccess,
    signupSuccess,
    (state: SignupState, action): SignupState => cleanupPendingState(state, action)
  ),
  on(
    loadInviteDetailsSuccess,
    (state: SignupState, action): SignupState =>
      cleanupPendingState(
        {
          ...state,
          inviteDetails: action.payload
        },
        action
      )
  ),
  on(
    acceptInviteFail,
    loadInviteDetailsFail,
    signupFail,
    (state: SignupState, action): SignupState => cleanupPendingState(state, action, action.payload)
  )
);

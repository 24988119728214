/* eslint @typescript-eslint/no-explicit-any: 0 */
/* eslint @typescript-eslint/no-use-before-define: 0 */

export type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };
export type XOR<T, U> = T | U extends object ? (Without<T, U> & U) | (Without<U, T> & T) : T | U;
export type MultiXOR<T extends any[]> = T extends [infer Only]
  ? Only
  : T extends [infer A, infer B, ...infer Rest]
  ? MultiXOR<[XOR<A, B>, ...Rest]>
  : never;

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export type DeepKeyof<T> = T extends object
  ? {
      [P in keyof T & (string | number)]: T[P] extends object ? P | DeepKeyof<T[P]> : P;
    }[keyof T & (string | number)]
  : never;

export type SnakeCase<S extends string> = S extends `${infer T}${infer U}`
  ? `${T extends Capitalize<T> ? (T extends '_' ? '' : '_') : ''}${Lowercase<T>}${SnakeCase<U>}`
  : S;

function isBlank(obj: any): obj is undefined | null | void {
  return obj === undefined || obj === null;
}

function isPrimitive(obj: any): obj is boolean | string | number | symbol {
  return !isObject(obj) && !isFunction(obj) && !Array.isArray(obj);
}

function isPresent<T>(obj: T): obj is T {
  return obj !== undefined && obj !== null;
}

export function isObject(obj: any): boolean {
  return (
    obj !== null &&
    (typeof obj === 'function' || typeof obj === 'object') &&
    !isFunction(obj) &&
    !Array.isArray(obj)
  );
}

export function isNumberFinite(value: unknown): boolean {
  return typeof value === 'number' && isFinite(value);
}

function isFunction(obj: any): boolean {
  return obj !== null && typeof obj === 'function' && obj instanceof Function;
}

export function hashCode(value: string): number {
  let hash = 0;

  for (let i = 0; i < value.length; i++) {
    const char: number = value.charCodeAt(i);
    hash = hash * 32 - hash + char;
    // eslint-disable-next-line no-bitwise
    hash &= hash;
  }

  return hash;
}

export function getHashCode(value: unknown): number {
  return (
    getAtomicValues(value)
      .map((token: string) => hashCode(token))
      // eslint-disable-next-line no-bitwise
      .reduce((acc: number, next: number) => acc ^ next, 0)
  );
}

function getAtomicValues(value: unknown): readonly string[] {
  if (isBlank(value)) {
    return [''];
  }

  if (isPrimitive(value)) {
    return [value.toString()];
  }

  return Object.values(value).map((propValue: any): string =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    isPresent(propValue) ? propValue.toString() : ''
  );
}

export function escapeRegExp(str: string): string {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export const deepMerge = <A extends Record<string, any>, B extends Record<string, any>>(
  target: A,
  source: B
): A & B => {
  const isDeep = (prop: string): boolean =>
    isObject(source[prop]) &&
    Object.prototype.hasOwnProperty.call(target, prop) &&
    isObject(target[prop]);
  const replaced = Object.getOwnPropertyNames(source)
    .map((prop) => ({
      [prop]: isDeep(prop) ? deepMerge(target[prop], source[prop]) : source[prop]
    }))
    .reduce((a, b) => ({ ...a, ...b }), {});

  return {
    // eslint-disable-next-line @typescript-eslint/ban-types
    ...(target as Object),
    // eslint-disable-next-line @typescript-eslint/ban-types
    ...(replaced as Object)
  } as A & B;
};

export const toIdsMap = <T extends { id: string }>(items: T[]): Map<string, T> =>
  new Map(items.map((item) => [item.id, item]));

export const uuid = (): string =>
  crypto.getRandomValues(new Uint32Array(4)).reduce((res, item) => res + item.toString(16), '');

export const keyValueToObject = (items: { key: string; value: string }[]): Record<string, string> =>
  (items || [])
    .map((item: { key: string; value: string }) => ({
      key: (item.key || '').trim(),
      value: (item.value || '').trim()
    }))
    .reduce(
      (res: Record<string, string>, item: { key: string; value: string }) => ({
        ...res,
        [item.key]: item.value
      }),
      {}
    );

export function unfreezeValue<T>(value: T): T {
  if (!Object.isFrozen(value)) {
    return value;
  }

  if (Array.isArray(value)) {
    return [...value] as T;
  }

  if (isObject(value)) {
    return { ...value };
  }
  return value;
}

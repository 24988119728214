import { Injectable } from '@angular/core';
import countries from './countries.json';

@Injectable()
export class CountriesService {
  public getCodes(): string[] {
    return Object.keys(countries);
  }

  public getNames(): string[] {
    return Object.values(countries);
  }

  public getNameByCode(countryCode: string): string {
    return (countries as Record<string, string>)[countryCode];
  }
}

import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { trackEvent } from '@neuralegion/analytics-api';
import {
  addGitlabIntegration,
  addIntegration,
  removeIntegration,
  updateIntegration
} from './integrations.actions';

@Injectable()
export class IntegrationsAnalyticsEffects {
  public readonly trackConfigureIntegration$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(addIntegration, addGitlabIntegration, updateIntegration),
      map(
        (
          action:
            | ReturnType<typeof addIntegration>
            | ReturnType<typeof addGitlabIntegration>
            | ReturnType<typeof updateIntegration>
        ) =>
          trackEvent({
            name: 'Configure Integration Form Submitted',
            properties: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              integration_type: action.payload.integration.service
            }
          })
      )
    )
  );

  public readonly trackRemoveIntegration$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(removeIntegration),
      map((action: ReturnType<typeof removeIntegration>) =>
        trackEvent({
          name: 'Integration Remove Submitted',
          properties: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            integration_type: action.payload.service
          }
        })
      )
    )
  );

  constructor(private readonly actions$: Actions) {}
}

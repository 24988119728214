import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { trackByIdentity } from '@neuralegion/core';

type KeyValueObject = Record<string, string | number>;

@Component({
  selector: 'share-show-more-key-value',
  templateUrl: './show-more-key-value.component.html',
  styleUrls: ['./show-more-key-value.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShowMoreKeyValueComponent {
  @Input()
  set items(items: KeyValueObject) {
    this.allItems = items || {};
    const diff = Object.keys(this.allItems).length - this.LIMIT;
    if (diff <= 0) {
      this.expand();
    } else {
      this.expanded = false;
      this.collapsedCounter = diff;
      this.visibleItems = Object.entries(items)
        .slice(0, this.LIMIT)
        .reduce(
          (res: KeyValueObject, s: [string, string | number]) => ({ ...res, [s[0]]: s[1] }),
          {}
        );
    }
  }

  public readonly LIMIT = 5;
  public readonly trackByIdentity = trackByIdentity;

  public visibleItems: KeyValueObject;
  private allItems: KeyValueObject;
  public expanded = false;
  public collapsedCounter = 0;

  public expand(): void {
    this.expanded = true;
    this.collapsedCounter = 0;
    this.visibleItems = this.allItems;
  }
}

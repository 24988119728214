import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

// eslint-disable-next-line @angular-eslint/use-component-selector
@Component({
  templateUrl: './client-error.component.html',
  styleUrls: ['./error-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientErrorComponent implements OnInit {
  public routeParams: Params;

  constructor(private readonly activatedRoute: ActivatedRoute) {}

  public ngOnInit(): void {
    this.routeParams = this.activatedRoute.snapshot.queryParams;
  }
}

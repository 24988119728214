import { Action, createReducer, on } from '@ngrx/store';
import { loadUserInfoSuccess } from '@neuralegion/auth-api';
import { cleanupPendingState } from '@neuralegion/core';
import { updateConsents, updateConsentsFail, updateConsentsSuccess } from './user-consents.actions';
import { UserConsentsState, initialUserConsentsState } from './user-consents.state';

export const userConsentsReducer = createReducer<UserConsentsState, Action>(
  initialUserConsentsState,
  on(
    updateConsents,
    (state: UserConsentsState, action): UserConsentsState => ({
      ...state,
      pending: [...state.pending, action]
    })
  ),
  on(
    loadUserInfoSuccess,
    (state: UserConsentsState, action): UserConsentsState =>
      cleanupPendingState({ ...state, consents: action.payload.userInfo.consents }, action)
  ),
  on(
    updateConsentsSuccess,
    (state: UserConsentsState, action): UserConsentsState =>
      cleanupPendingState(
        { ...state, consents: { ...state.consents, ...action.payload.consents } },
        action
      )
  ),
  on(
    updateConsentsFail,
    (state: UserConsentsState, action): UserConsentsState =>
      cleanupPendingState(state, action, action.payload)
  )
);

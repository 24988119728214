import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { AnalyticsService } from '../services';
import {
  resetUser,
  setUser,
  trackEvent,
  trackLogin,
  trackSignup,
  trackSignupSuccess
} from './analytics.actions';

@Injectable()
export class AnalyticsEffects {
  public readonly trackEvent$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(trackEvent),
        tap((action: ReturnType<typeof trackEvent>) =>
          this.analyticsService.track(action.payload.name, action.payload.properties)
        )
      ),
    {
      dispatch: false
    }
  );

  public readonly trackSignup$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(trackSignup),
        tap((action: ReturnType<typeof trackSignup>) =>
          this.analyticsService.trackSignup(action.payload)
        )
      ),
    {
      dispatch: false
    }
  );

  public readonly trackSignupSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(trackSignupSuccess),
        tap((action: ReturnType<typeof trackSignupSuccess>) =>
          this.analyticsService.trackSuccessSignup(action.payload.user)
        )
      ),
    {
      dispatch: false
    }
  );

  public readonly trackLogin$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(trackLogin),
        tap((action: ReturnType<typeof trackLogin>) =>
          this.analyticsService.trackLogin(action.payload)
        )
      ),
    {
      dispatch: false
    }
  );

  public readonly setUser$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setUser),
        tap((action: ReturnType<typeof setUser>) =>
          this.analyticsService.setUser(action.payload.user)
        )
      ),
    {
      dispatch: false
    }
  );

  public readonly resetUser$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(resetUser),
        tap(() => this.analyticsService.setUser(null))
      ),
    {
      dispatch: false
    }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly analyticsService: AnalyticsService
  ) {}
}

import { ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef } from '@angular/core';
import { trackByIdentity } from '@neuralegion/core';

@Component({
  selector: 'share-show-more-array',
  templateUrl: './show-more-array.component.html',
  styleUrls: ['./show-more-array.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShowMoreArrayComponent<T> implements OnInit {
  @Input()
  set items(items: readonly T[]) {
    this.computeItemsState(items);
  }

  @Input()
  public separator = ', ';

  @Input()
  public limit = 5;

  @Input()
  public mode: 'expand' | 'overlay' = 'expand';

  @Input()
  public itemTmpl?: TemplateRef<unknown>;

  public readonly trackByIdentity = trackByIdentity;

  public expanded = false;
  public allItems: readonly T[] = [];
  public visibleItems: readonly T[] = [];
  public moreItems: readonly T[] = [];

  public ngOnInit(): void {
    this.computeItemsState(this.allItems);
  }

  public expand(e?: Event): void {
    e?.stopPropagation();
    this.expanded = true;
    this.visibleItems = this.allItems;
  }

  private computeItemsState(items: readonly T[]): void {
    this.allItems = items;
    if (this.expanded || items.length <= this.limit) {
      this.visibleItems = items;
      this.moreItems = [];
      return;
    }

    this.visibleItems = items.slice(0, this.limit);
    this.moreItems = items.slice(this.limit);
  }
}

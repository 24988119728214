<mat-form-field *ngIf="{ progress: progress$ | async } as sync" class="full-width compact-field">
  <input aria-label="Choose file" fxHide matInput />

  <div class="progress-label">
    <span class="progress-message">{{ actionLabel }} {{ fileName }}...</span>
    <span class="progress-value">{{ sync.progress | percent }}</span>
  </div>
  <mat-progress-bar [mode]="'buffer'" [value]="sync.progress * 100"></mat-progress-bar>
  <div class="cancel-container" fxLayout="row" fxLayoutAlign="end center">
    <span
      role="button"
      aria-label="Abort uploading"
      tabindex="0"
      matTooltip="Abort uploading"
      [element-data-id]="'btn_cancel'"
      (click)="onCancelUpload()"
      (keydown.enter)="onCancelUpload()"
      (keydown.space)="onCancelUpload()">
      <ng-container *ngIf="progress$ && sync.progress >= 0 && sync.progress < 1">Abort</ng-container>
      <span *ngIf="progress$ && sync.progress === 1" class="clear">Clear</span>
    </span>
  </div>
</mat-form-field>

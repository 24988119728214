import { BaseElementIdStrategy } from './base-element-id-strategy';

export class DatasetEmptyElementIdStrategy extends BaseElementIdStrategy {
  private readonly elementPrefix = ['dataset', 'empty'].join(this.SEPARATOR_INTERNAL);

  public override applyId(element: HTMLElement, dataId: string): void {
    const targetElement = element.querySelector('span') || element;
    super.applyId(targetElement, dataId);
  }

  protected override getSubId(): string {
    return this.elementPrefix;
  }
}

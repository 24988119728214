import { MqAlias } from '../models';

export interface LayoutState {
  readonly pending: boolean;
  readonly mqAliases: MqAlias[];
}

export const initialLayoutState: LayoutState = {
  pending: false,
  mqAliases: []
};

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { logout } from '@neuralegion/auth-api';

@Component({
  selector: 'auth-link-signin',
  templateUrl: './link-signin.component.html',
  styleUrls: ['./link-signin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkSigninComponent {
  @Input()
  public message = 'Have an account?';

  constructor(private readonly store: Store) {}

  public logout(): void {
    this.store.dispatch(logout({ skipRouting: true }));
  }
}

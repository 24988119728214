export interface RepeaterVersion {
  value: string;
  status: RepeaterVersionStatus;
}

export enum RepeaterVersionStatus {
  LATEST = 'latest',
  OBSOLETE = 'obsolete',
  DEPRECATED = 'deprecated'
}

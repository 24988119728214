import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Email } from '@neuralegion/api';
import { forgotPassword, selectForgotPasswordPending } from '@neuralegion/auth-api';

// eslint-disable-next-line @angular-eslint/use-component-selector
@Component({
  templateUrl: './forgot-password-page.component.html',
  styleUrls: ['./forgot-password-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForgotPasswordPageComponent implements OnInit {
  public pending$: Observable<boolean>;

  constructor(private readonly store: Store) {}

  public ngOnInit(): void {
    this.pending$ = this.store.select(selectForgotPasswordPending);
  }

  public onSubmit(event: Email): void {
    this.store.dispatch(forgotPassword(event));
  }
}

import { AbstractControl, FormArray, FormGroup } from '@angular/forms';

export function updateTreeValidity(
  abstractControl: FormGroup | FormArray | AbstractControl,
  opts?: { emitEvent: boolean }
): void {
  if (!(abstractControl instanceof FormGroup || abstractControl instanceof FormArray)) {
    abstractControl.updateValueAndValidity(opts);
    return;
  }

  Object.values(abstractControl.controls).forEach((control: AbstractControl) =>
    updateTreeValidity(control, opts)
  );
  abstractControl.updateValueAndValidity(opts);
}

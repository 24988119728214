import { Pipe, PipeTransform } from '@angular/core';
import { QuotaType } from '@neuralegion/api';

@Pipe({
  name: 'quotaType',
  pure: true
})
export class QuotaTypePipe implements PipeTransform {
  public transform(type: QuotaType, short: boolean = false): string {
    return short ? this.getQuotaTypeShortString(type) : this.getQuotaTypeString(type);
  }

  private getQuotaTypeString(type: QuotaType): string {
    switch (type) {
      case QuotaType.ENGINES:
        return 'Number of engines';
      case QuotaType.FILE_STORAGE:
        return 'Storage size (MB)';
      case QuotaType.USERS:
        return 'Number of users';
      case QuotaType.SCAN_HOURS:
        return 'Number of scan hours';
      case QuotaType.TICKETING_INTEGRATIONS:
        return 'Number of integrations';
      case QuotaType.PROJECTS:
        return 'Number of allowed projects';
      default:
        return type;
    }
  }

  private getQuotaTypeShortString(type: QuotaType): string {
    switch (type) {
      case QuotaType.ENGINES:
        return 'Engines';
      case QuotaType.FILE_STORAGE:
        return 'Storage (MB)';
      case QuotaType.USERS:
        return 'Users';
      case QuotaType.SCAN_HOURS:
        return 'Scan hours';
      case QuotaType.TICKETING_INTEGRATIONS:
        return 'Integrations';
      case QuotaType.PROJECTS:
        return 'Projects';
      default:
        return type;
    }
  }
}

import { Renderer2 } from '@angular/core';
import { getElementText } from '@neuralegion/core';
import { ElementIdStrategy } from './element-id-strategy';

export abstract class BaseElementIdStrategy implements ElementIdStrategy {
  protected readonly replacementMap = new Map<string, string>([
    ['−', 'minus'],
    ['+', 'plus'],
    ['…', ''],
    ['...', ''],
    ['&', 'and']
  ]);

  protected readonly UNKNOWN_ID = 'unknown_id';
  protected readonly SEPARATOR_EXTERNAL = '-';
  protected readonly SEPARATOR_INTERNAL = '_';

  private readonly ATTRIBUTE_NAME = 'data-id';

  constructor(protected readonly renderer: Renderer2) {}

  public calculateId(element: HTMLElement, parentId?: string, subId?: string): string {
    return [
      element.parentElement?.closest('[data-id]')?.getAttribute('data-id') ?? parentId,
      subId ? this.generateIdByText(subId, true) : this.getSubId(element)
    ]
      .filter(Boolean)
      .join(this.SEPARATOR_EXTERNAL);
  }

  public applyId(element: HTMLElement, dataId: string): void {
    if (dataId) {
      this.renderer.setAttribute(element, this.ATTRIBUTE_NAME, dataId);
    }
  }

  protected abstract getSubId(element: HTMLElement): string;

  protected generateIdByText(text: string, preserveSeparator: boolean = false): string {
    return (text || '')
      .trim()
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .toLowerCase()
      .split(' ')
      .map((el) => this.replaceSpecialChars(el, preserveSeparator))
      .join(this.SEPARATOR_INTERNAL)
      .replace(new RegExp(`[${this.SEPARATOR_INTERNAL}]+`, 'g'), this.SEPARATOR_INTERNAL)
      .replace(new RegExp(`[${this.SEPARATOR_INTERNAL}]+$`), '');
  }

  protected getElementText(
    element: HTMLElement | null,
    skipNodeFn?: (node: Node) => boolean
  ): string {
    return getElementText(element, skipNodeFn);
  }

  private replaceSpecialChars(idString: string, preserveSeparator: boolean = false): string {
    return [...this.replacementMap.entries()]
      .reduce((res, [key, value]) => res.split(key).join(value).trim(), idString)
      .replace(
        new RegExp(preserveSeparator ? `[^\\w${this.SEPARATOR_EXTERNAL}]+` : '[^\\w]+', 'g'),
        this.SEPARATOR_INTERNAL
      );
  }
}

<mat-toolbar role="banner" [class.pending]="pending">
  <mat-toolbar-row>
    <div
      *ngIf="positionedItems$ | async as positionedItems"
      class="ghost-blur"
      fxFlex="1 1 100%"
      fxLayout="row"
      fxLayoutAlign="space-between center">
      <div class="left" fxLayout="row">
        <div *ngFor="let item of positionedItems?.left; trackBy: trackByIdentity">
          <ng-container *ngTemplateOutlet="tmplItem; context: { item: item }"> </ng-container>
        </div>
      </div>

      <div fxLayout="row">
        <div *ngFor="let item of positionedItems?.right; trackBy: trackByIdentity">
          <ng-container *ngTemplateOutlet="tmplItem; context: { item: item }"> </ng-container>
        </div>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<ng-template #tmplItem let-item="item">
  <ng-container [ngSwitch]="item.type">
    <ng-container *ngSwitchCase="ToolbarItemType.BUTTON">
      <ng-container *ngIf="item.visible !== false">
        <ng-container *ngIf="item.raised; else tmplTextButton">
          <button
            [attr.aria-haspopup]="item.ariaHaspopup"
            [attr.aria-label]="item.ariaLabel"
            [attr.data-target]="item.dataTarget"
            [attr.title]="item.title"
            mat-raised-button
            [color]="item.color"
            [disabled]="!!item.disabled"
            [routerLink]="item.routerLink"
            (click)="item.action ? item.action() : null">
            <mat-icon *ngIf="item.icon" [class.icon-only]="!item.label">{{ item.icon }}</mat-icon
            >{{ item.label }}
          </button>
        </ng-container>
        <ng-template #tmplTextButton>
          <button
            class="text-button"
            [attr.aria-haspopup]="item.ariaHaspopup"
            [attr.aria-label]="item.ariaLabel"
            [attr.data-target]="item.dataTarget"
            [attr.title]="item.title"
            mat-button
            [class.button-secondary]="item.color === 'secondary'"
            [disabled]="!!item.disabled"
            [routerLink]="item.routerLink"
            (click)="item.action ? item.action() : null">
            <mat-icon *ngIf="item.icon" [class.icon-only]="!item.label">{{ item.icon }}</mat-icon
            >{{ item.label }}
          </button>
        </ng-template>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="ToolbarItemType.LINK">
      <a
        *ngIf="item.visible !== false"
        class="link link-internal"
        [attr.aria-haspopup]="item.ariaHaspopup"
        [attr.aria-label]="item.ariaLabel"
        [attr.data-target]="item.dataTarget"
        [routerLink]="item.routerLink"
        (click)="item.action ? item.action() : null"
        >{{ item.label }}</a
      >
    </ng-container>
  </ng-container>
</ng-template>

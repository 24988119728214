import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Optional, SkipSelf } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookiePolicyService {
  private readonly supportedDomain = 'brightsec.com';

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Optional() @SkipSelf() cookiePolicyServiceService?: CookiePolicyService
  ) {
    if (cookiePolicyServiceService) {
      throw new Error('"CookiePolicyService" should be singleton');
    }
  }

  public load(): void {
    if (!this.isDomainSupported()) {
      return;
    }

    const script = this.document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'cookieyes';
    script.src = 'https://cdn-cookieyes.com/client_data/bec1de16b620cda15601cbca/script.js';

    this.document.head.appendChild(script);
  }

  private isDomainSupported(): boolean {
    return this.document.defaultView.location.hostname.endsWith(this.supportedDomain);
  }
}

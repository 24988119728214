import { Inject, Injectable, Optional } from '@angular/core';
import { ID } from '@neuralegion/api';
import {
  TABLE_SELECTION_OPTIONS,
  TableSelectionOptions,
  TableSelectionService
} from './table-selection.service';

@Injectable()
export class EntityTableSelectionService<
  T extends ID,
  TStored extends ID = T
> extends TableSelectionService<T, TStored> {
  constructor(
    @Inject(TABLE_SELECTION_OPTIONS)
    @Optional()
    tableSelectionOptions: TableSelectionOptions<T, TStored>
  ) {
    super(
      {
        limit: tableSelectionOptions?.limit,
        entityMapperFn:
          tableSelectionOptions?.entityMapperFn ?? ((entity: T) => entity as unknown as TStored),
        entityFilterFn: tableSelectionOptions?.entityFilterFn
      },
      (o1: TStored, o2: TStored) => o1.id === o2.id
    );
  }
}

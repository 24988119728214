import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'auth-mfa-form',
  templateUrl: './mfa-form.component.html',
  styleUrls: ['./mfa-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MfaFormComponent {
  @Input()
  set pending(pending: boolean) {
    if (pending) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Output()
  public readonly submitted = new EventEmitter<{ code: string }>(true);

  public form = new FormGroup({
    code: new FormControl('', Validators.required)
  });

  public onSubmit(): void {
    this.submitted.emit({ code: this.form.value?.code });
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Repeater } from '../models';

@Injectable()
export class RepeatersService {
  constructor(private readonly http: HttpClient) {}

  public loadRepeaters(): Observable<Repeater[]> {
    return this.http.get<Repeater[]>('/api/v1/repeaters');
  }

  public loadRepeater(id: string): Observable<Repeater> {
    return this.http.get<Repeater>(`/api/v1/repeaters/${id}`);
  }

  public deleteRepeater(id: string): Observable<void> {
    return this.http.delete<void>(`/api/v1/repeaters/${id}`);
  }

  public updateRepeater(repeater: Repeater): Observable<Repeater> {
    return this.http.put<Repeater>(`/api/v1/repeaters/${repeater.id}`, this.mapToPayload(repeater));
  }

  public createRepeater(repeater: Omit<Repeater, 'id'>): Observable<Repeater> {
    return this.http.post<Repeater>('/api/v1/repeaters', this.mapToPayload(repeater));
  }

  private mapToPayload(
    repeater: Partial<Repeater>
  ): Omit<Partial<Repeater>, 'scripts'> & { scripts: { scriptId: string; host: string }[] } {
    return {
      ...repeater,
      scripts:
        repeater.scripts?.map(({ id: scriptId, host }) => ({
          scriptId,
          host
        })) ?? []
    };
  }
}

<mat-selection-list aria-label="Scopes" dense [formControl]="control">
  <ng-container *ngFor="let group of scopesMetaGroups; trackBy: trackByIdentity">
    <div fxLayout="row wrap">
      <span
        *ngFor="let scopeMeta of group; trackBy: trackByIdentity"
        class="item"
        attr.aria-label="{{ !scopeMeta.editable ? 'You do not have an access to this scope' : scopeMeta.scope }}"
        [matTooltip]="!scopeMeta.editable ? 'You do not have an access to this scope' : scopeMeta.scope">
        <mat-list-option checkboxPosition="before" [disabled]="!scopeMeta.editable" [value]="scopeMeta.scope">
          {{ scopeMeta.scope }}
        </mat-list-option>
      </span>
    </div>
    <mat-divider></mat-divider>
  </ng-container>
</mat-selection-list>

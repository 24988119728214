import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, map } from 'rxjs';

// eslint-disable-next-line @angular-eslint/use-component-selector
@Component({
  templateUrl: './signup-success-page.component.html',
  styleUrls: ['./signup-success-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignupSuccessPageComponent implements OnInit {
  public email$: Observable<string>;

  constructor(private readonly activatedRoute: ActivatedRoute) {}

  public ngOnInit(): void {
    this.email$ = this.activatedRoute.params.pipe(
      map((params: { email?: string }) => params.email)
    );
  }
}

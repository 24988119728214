import { Action, createReducer, on } from '@ngrx/store';
import { cleanupPendingState } from '@neuralegion/core';
import {
  loadPaymentHistory,
  loadPaymentHistoryFail,
  loadPaymentHistorySuccess,
  loadUpcomingInvoice,
  loadUpcomingInvoiceFail,
  loadUpcomingInvoiceSuccess,
  repay,
  repayFail,
  repaySuccess,
  unsubscribe,
  unsubscribeFail,
  unsubscribeSuccess
} from './payments.actions';
import { PaymentsState, initialPaymentsState, paymentsEntityAdapter } from './payments.state';

export const paymentsReducer = createReducer<PaymentsState, Action>(
  initialPaymentsState,
  on(
    loadPaymentHistory,
    repay,
    unsubscribe,
    (state: PaymentsState, action): PaymentsState => ({
      ...state,
      pending: [...state.pending, action],
      error: null
    })
  ),
  on(
    loadUpcomingInvoice,
    (state: PaymentsState, action): PaymentsState => ({
      ...state,
      coupon: action.payload.coupon,
      upcomingInvoice: null,
      pending: [...state.pending, action],
      error: null
    })
  ),
  on(
    loadPaymentHistorySuccess,
    (state: PaymentsState, action): PaymentsState =>
      paymentsEntityAdapter.setAll(
        action.payload.paymentHistory,
        cleanupPendingState(state, action)
      )
  ),
  on(
    loadUpcomingInvoiceSuccess,
    (state: PaymentsState, action): PaymentsState =>
      cleanupPendingState({ ...state, upcomingInvoice: action.payload.upcomingInvoice }, action)
  ),
  on(
    repaySuccess,
    (state: PaymentsState, action): PaymentsState =>
      paymentsEntityAdapter.updateOne(
        {
          id: action.payload.paymentId,
          changes: {
            paymentMethodId: action.payload.paymentMethodId,
            paid: true
          }
        },
        cleanupPendingState(state, action)
      )
  ),
  on(
    unsubscribeSuccess,
    (state: PaymentsState, action): PaymentsState => cleanupPendingState(state, action)
  ),
  on(
    loadUpcomingInvoiceFail,
    (state: PaymentsState, action): PaymentsState =>
      cleanupPendingState({ ...state, coupon: '' }, action, action.payload)
  ),
  on(
    loadPaymentHistoryFail,
    repayFail,
    unsubscribeFail,
    (state: PaymentsState, action): PaymentsState =>
      cleanupPendingState(state, action, action.payload)
  )
);

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Scope } from '@neuralegion/api';
import { SbacGuard } from './sbac.guard';

@Injectable()
export class AuthObjectsGuard extends SbacGuard {
  constructor(router: Router, store: Store) {
    super(router, store, Scope.AUTH_OBJECTS_READ);
  }
}

<mat-card>
  <mat-card-title role="heading" aria-level="1">
    <img src="assets/signup-success.svg" alt="Signup success" />
    Confirm Your Email Address
  </mat-card-title>
  <mat-card-content>
    <div>Congratulations! You're well on your way to securing your application.</div>

    <div class="confirmation-sent">
      We sent a confirmation email to:
      <div class="email">{{ email$ | async }}</div>
      Check your email and click on the confirmation link to continue.
    </div>

    <auth-link-signin [message]="'Want to use another account? Go to'"></auth-link-signin>
  </mat-card-content>
</mat-card>

import { Pipe, PipeTransform } from '@angular/core';
import { DateFormatAlias, DateFormatOptions, UserDateService } from '@neuralegion/core';

@Pipe({
  name: 'customDate',
  // eslint-disable-next-line @angular-eslint/no-pipe-impure
  pure: false
})
export class CustomDatePipe implements PipeTransform {
  constructor(private readonly userDateService: UserDateService) {}

  public transform(
    date: Date | number | string,
    format: DateFormatAlias | Partial<DateFormatOptions> = DateFormatAlias.DATE_TIME
  ): string {
    return !date
      ? ''
      : this.userDateService.formatDate(
          date,
          Object.values(DateFormatAlias).includes(format as DateFormatAlias)
            ? { format: format as DateFormatAlias }
            : (format as DateFormatOptions)
        );
  }
}

import { Injectable } from '@angular/core';
import jsonPointer from 'json-pointer';

@Injectable()
export class EmptyValuesPreprocessorService {
  public isObjectLike(value: unknown): boolean {
    return typeof value === 'object' && !!value;
  }

  public isNotEmptyValue(value: unknown): boolean {
    return (
      value === 0 ||
      value === false ||
      (this.isObjectLike(value) && !this.isObjectEmpty(value)) ||
      (!this.isObjectLike(value) && !!value)
    );
  }

  public isObjectEmpty(value: unknown): boolean {
    return (
      value === undefined ||
      value === null ||
      !Object.values(value).some((v) => this.isNotEmptyValue(v))
    );
  }

  public clearEmptyValues(
    obj: object,
    exclusions: string[] | undefined = [],
    path: string[] = []
  ): object {
    return Object.entries(obj).reduce(
      (acc, [key, value]) => {
        const pointer = jsonPointer.compile([...path, key]);
        if (exclusions.includes(pointer)) {
          acc[key] = value;
        } else if (
          this.isObjectLike(value) &&
          (!this.isObjectEmpty(value) || exclusions.some((x) => x.startsWith(pointer)))
        ) {
          acc[key] = this.clearEmptyValues(value, exclusions, [...path, key]);
        } else if (this.isNotEmptyValue(value)) {
          acc[key] = value;
        }

        return acc;
      },
      (Array.isArray(obj) ? [] : {}) as Record<string, unknown>
    );
  }
}

import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subject, distinctUntilChanged, map, takeUntil } from 'rxjs';
import equal from 'fast-deep-equal/es6';
import { Code, CodePrettifyService, PrettifyResult } from '../../services';

@Component({
  selector: 'share-code-viewer',
  templateUrl: './code-viewer.component.html',
  styleUrls: ['./code-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CodeViewerComponent implements OnInit, OnDestroy {
  @Input()
  public code$: Observable<Code>;

  public prettifyResult$: Observable<PrettifyResult>;
  public prettifiedCode$: Observable<string>;

  private readonly gc = new Subject<void>();
  private readonly prettifyResultSubject = new BehaviorSubject<PrettifyResult>(null);

  constructor(private readonly codePrettifyService: CodePrettifyService) {}

  public ngOnInit(): void {
    this.code$
      .pipe(distinctUntilChanged(equal), takeUntil(this.gc))
      // eslint-disable-next-line rxjs/no-async-subscribe
      .subscribe(async (code: Code) => {
        if (!code) {
          this.prettifyResultSubject.next(null);
          return;
        }

        try {
          const prettifyResult = await this.codePrettifyService.prettify(code, {
            reformat: { language: code.language }
          });
          this.prettifyResultSubject.next(prettifyResult);
        } catch (e) {
          this.prettifyResultSubject.next(null);
        }
      });

    this.prettifyResult$ = this.prettifyResultSubject.asObservable();
    this.prettifiedCode$ = this.prettifyResult$.pipe(
      map((prettifyResult: PrettifyResult) => prettifyResult?.code)
    );
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }
}

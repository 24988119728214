import { Pipe, PipeTransform } from '@angular/core';
import { Filter } from '@neuralegion/api';
import { FindPipe } from './find.pipe';

@Pipe({
  name: 'findFilter',
  pure: true
})
export class FindFilterPipe implements PipeTransform {
  public transform<T extends Filter>(
    value: string,
    filters: Filter[],
    fieldName: keyof Filter = 'name'
  ): T {
    return new FindPipe<T>().transform(value, (filters as T[]) ?? [], fieldName);
  }
}

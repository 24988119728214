import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import { parseUrl } from '../helpers';
import { AppRouterState } from '../models';

@Injectable()
export class AppRouterStateSerializer implements RouterStateSerializer<AppRouterState> {
  constructor(@Inject(DOCUMENT) private readonly document: Document) {}

  public serialize(routerState: RouterStateSnapshot): AppRouterState {
    let state: ActivatedRouteSnapshot = routerState.root;
    const primary = !this.isAuxRoute(state);

    while (state.firstChild) {
      state = state.firstChild;
    }

    const { url } = routerState;
    const { queryParams } = routerState.root;
    const { params } = state;
    const pathname = parseUrl(`${this.document.location.origin}${url}`)?.pathname.replace(
      /\(.+\)$/,
      ''
    );

    return { url, queryParams, params, pathname, primary };
  }

  private isAuxRoute(x: ActivatedRouteSnapshot): boolean {
    return x.outlet !== 'primary' || x.children.some((child) => this.isAuxRoute(child));
  }
}

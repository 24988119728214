import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'some',
  pure: true
})
export class SomePipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public transform(input: any[], value: any): boolean {
    if (!input) {
      return false;
    }

    return Array.isArray(input) ? input.some((el) => el === value) : false;
  }
}

export enum JobStatus {
  FAILED = 'failed',
  PENDING = 'pending',
  QUEUED = 'queued',
  SCHEDULED = 'scheduled',
  STOPPED = 'stopped',

  // Scan job
  DISRUPTED = 'disrupted',
  DONE = 'done',
  RUNNING = 'running',

  // Discovery job
  SEARCHING = 'searching',
  COMPLETE = 'complete',
  INCOMPLETE = 'incomplete'
}

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Observable,
  catchError,
  exhaustMap,
  filter,
  first,
  map,
  mergeMap,
  of,
  withLatestFrom
} from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Scope } from '@neuralegion/api';
import { selectAuthActiveStatus, selectScopePermission } from '@neuralegion/auth-api';
import { AppRouterState, ofPrimaryRouterNavigated } from '@neuralegion/core';
import { FeedData, FeedSubscription } from '../models';
import { FeedService } from '../services';
import {
  clear,
  clearAll,
  clearAllFail,
  clearAllSuccess,
  clearFail,
  clearSuccess,
  loadFeedNext,
  loadFeedNextFail,
  loadFeedNextSuccess,
  loadFeedStart,
  loadSubscriptions,
  loadSubscriptionsFail,
  loadSubscriptionsSuccess,
  markAllAsSeen,
  markAllAsSeenFail,
  markAllAsSeenSuccess,
  markAsSeen,
  markAsSeenFail,
  markAsSeenSuccess,
  openFeed,
  updateSubscriptions,
  updateSubscriptionsFail,
  updateSubscriptionsSuccess
} from './feed.actions';
import { selectFeedOffset, selectFeedOpened } from './feed.selectors';
import { FEED_LOAD_LIMIT } from './feed.state';

@Injectable()
export class FeedEffects {
  public readonly reloadOnOpen$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(openFeed),
      map(() => loadFeedStart())
    )
  );

  public readonly loadFeed$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFeedStart),
      map(() => loadFeedNext())
    )
  );

  public readonly loadFeedNext$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFeedNext),
      withLatestFrom(
        this.store.select(selectFeedOffset),
        this.store.select(selectScopePermission(Scope.ACTIVITIES))
      ),
      exhaustMap(
        ([, offset, feedPermission]: [
          ReturnType<typeof loadFeedNext>,
          number,
          boolean
        ]): Observable<Action> =>
          feedPermission
            ? this.feedService.loadFeed(offset, FEED_LOAD_LIMIT).pipe(
                map((feedData: FeedData) => loadFeedNextSuccess(feedData)),
                catchError((err: HttpErrorResponse) => of(loadFeedNextFail(err.error)))
              )
            : of(loadFeedNextSuccess({ unseen: 0, activities: [] }))
      )
    )
  );

  public readonly markAsSeen$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(markAsSeen),
      mergeMap(
        (action: ReturnType<typeof markAsSeen>): Observable<Action> =>
          this.feedService.markAsSeen(action.payload).pipe(
            map(() => markAsSeenSuccess(action.payload)),
            catchError((err: HttpErrorResponse) => of(markAsSeenFail(err.error)))
          )
      )
    )
  );

  public readonly markAllAsSeen$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(markAllAsSeen),
      exhaustMap(
        (): Observable<Action> =>
          this.feedService.markAllAsSeen().pipe(
            map(() => markAllAsSeenSuccess()),
            catchError((err: HttpErrorResponse) => of(markAllAsSeenFail(err.error)))
          )
      )
    )
  );

  public readonly clear$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(clear),
      mergeMap(
        (action: ReturnType<typeof clear>): Observable<Action> =>
          this.feedService.clear(action.payload).pipe(
            map(() => clearSuccess(action.payload)),
            catchError((err: HttpErrorResponse) => of(clearFail(err.error)))
          )
      )
    )
  );

  public readonly clearAll$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(clearAll),
      exhaustMap(
        (): Observable<Action> =>
          this.feedService.clearAll().pipe(
            map(() => clearAllSuccess()),
            catchError((err: HttpErrorResponse) => of(clearAllFail(err.error)))
          )
      )
    )
  );

  public readonly loadSubscriptions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSubscriptions),
      withLatestFrom(this.store.select(selectScopePermission(Scope.ACTIVITIES))),
      exhaustMap(
        ([, activitiesPermission]: [
          ReturnType<typeof loadSubscriptions>,
          boolean
        ]): Observable<Action> =>
          activitiesPermission
            ? this.feedService.loadSubscriptions().pipe(
                map((subscriptions: FeedSubscription[]) => loadSubscriptionsSuccess(subscriptions)),
                catchError((err: HttpErrorResponse) => of(loadSubscriptionsFail(err.error)))
              )
            : of(loadSubscriptionsSuccess([]))
      )
    )
  );

  public readonly updateSubscriptions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSubscriptions),
      exhaustMap(
        (action: ReturnType<typeof updateSubscriptions>): Observable<Action> =>
          this.feedService.updateSubscriptions(action.payload).pipe(
            map(() => updateSubscriptionsSuccess(action.payload)),
            catchError((err: HttpErrorResponse) => of(updateSubscriptionsFail(err.error)))
          )
      )
    )
  );

  public readonly subscriptionsInitialLoad$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofPrimaryRouterNavigated(),
      withLatestFrom(this.store.select(selectAuthActiveStatus)),
      filter(([, active]: [AppRouterState, boolean]) => active),
      first(),
      map(() => loadSubscriptions())
    )
  );

  public readonly navigatedToProfile$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofPrimaryRouterNavigated((state: AppRouterState) => state.pathname === '/profile'),
      map(() => loadSubscriptions())
    )
  );

  public readonly navigated$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofPrimaryRouterNavigated(),
      withLatestFrom(
        this.store.select(selectFeedOpened),
        this.store.select(selectAuthActiveStatus)
      ),
      filter(([, opened, active]: [AppRouterState, boolean, boolean]) => active && !opened),
      map(() => loadFeedStart())
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly feedService: FeedService
  ) {}
}

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  AuthNegateGuard,
  EmailConfirmationNegateGuard,
  MarketplaceCallbackStateGuard,
  MfaNegateGuard,
  OAuthGuard,
  SignupGuard
} from '@neuralegion/auth-api';
import { AuthRootComponent } from '../components/layout';
import {
  AuthCallbackComponent,
  ConfirmCreateUserComponent,
  CreateOrgPageComponent,
  ForgotPasswordPageComponent,
  InvitePageComponent,
  LoginPageComponent,
  LogoutComponent,
  MarketplaceCallbackComponent,
  MfaPageComponent,
  MfaSetupPageComponent,
  ResetPasswordPageComponent,
  ResetPasswordSentPageComponent,
  SignupPageComponent,
  SignupRegisterPageComponent,
  SignupSuccessPageComponent,
  SsoPageComponent,
  SsoProvidersPageComponent
} from '../containers';
import { WelcomeType } from '../models';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '',
        component: AuthRootComponent,
        children: [
          {
            path: 'login',
            component: LoginPageComponent,
            canActivate: [AuthNegateGuard],
            data: { welcomeType: WelcomeType.WELCOME_BACK }
          },
          {
            path: 'sso',
            component: SsoPageComponent,
            canActivate: [AuthNegateGuard],
            data: { welcomeType: WelcomeType.WELCOME_BACK }
          },
          {
            path: 'sso/providers',
            component: SsoProvidersPageComponent,
            canActivate: [AuthNegateGuard],
            data: { welcomeType: WelcomeType.WELCOME_BACK }
          },
          {
            path: 'logout',
            component: LogoutComponent
          },
          {
            path: 'mfa',
            component: MfaPageComponent,
            canActivate: [MfaNegateGuard, AuthNegateGuard],
            data: { welcomeType: WelcomeType.WELCOME_BACK }
          },
          {
            path: 'mfa/setup',
            component: MfaSetupPageComponent,
            canActivate: [AuthNegateGuard],
            data: { welcomeType: WelcomeType.WELCOME_MAIN }
          },
          {
            path: 'create-organization',
            component: CreateOrgPageComponent,
            canActivate: [AuthNegateGuard],
            data: { welcomeType: WelcomeType.WELCOME_MAIN }
          },
          {
            path: 'confirm-create-user',
            component: ConfirmCreateUserComponent,
            canActivate: [EmailConfirmationNegateGuard, AuthNegateGuard],
            data: { welcomeType: WelcomeType.WELCOME_BACK }
          },
          {
            path: 'aws/callback',
            component: MarketplaceCallbackComponent,
            canActivate: [MarketplaceCallbackStateGuard]
          },
          {
            path: ':type/callback',
            component: AuthCallbackComponent,
            canActivate: [OAuthGuard]
          },
          {
            path: 'signup',
            component: SignupPageComponent,
            canActivate: [AuthNegateGuard, SignupGuard],
            data: { welcomeType: WelcomeType.WELCOME_MAIN }
          },
          {
            path: 'signup/:memberId/:token/create',
            component: SignupPageComponent,
            canActivate: [AuthNegateGuard],
            data: { welcomeType: WelcomeType.WELCOME_MAIN }
          },
          {
            path: 'register',
            component: SignupRegisterPageComponent,
            canActivate: [AuthNegateGuard, SignupGuard],
            data: { welcomeType: WelcomeType.WELCOME_MAIN }
          },
          {
            path: 'signup/:memberId/:token/register',
            component: SignupRegisterPageComponent,
            canActivate: [AuthNegateGuard],
            data: { welcomeType: WelcomeType.WELCOME_MAIN }
          },
          {
            path: 'signup/success/:email',
            component: SignupSuccessPageComponent,
            canActivate: [AuthNegateGuard],
            data: { welcomeType: WelcomeType.WELCOME_MAIN }
          },
          {
            path: 'signup/:memberId/:token',
            component: InvitePageComponent,
            canActivate: [AuthNegateGuard],
            data: { welcomeType: WelcomeType.WELCOME_MAIN }
          },
          {
            path: 'forgot-password',
            component: ForgotPasswordPageComponent,
            canActivate: [AuthNegateGuard],
            data: { welcomeType: WelcomeType.WELCOME_BACK }
          },
          {
            path: 'forgot-password/info/:email',
            component: ResetPasswordSentPageComponent,
            canActivate: [AuthNegateGuard],
            data: { welcomeType: WelcomeType.WELCOME_BACK }
          },
          {
            path: 'forgot-password/:token',
            component: ResetPasswordPageComponent,
            data: { welcomeType: WelcomeType.WELCOME_BACK }
          }
        ]
      }
    ])
  ],
  exports: [RouterModule]
})
export class AuthRoutingModule {}

import { BaseElementIdStrategy } from './base-element-id-strategy';

export class TooltipElementIdStrategy extends BaseElementIdStrategy {
  private readonly elementPrefix = ['info', 'tooltip', 'trigger'].join(this.SEPARATOR_INTERNAL);

  public override applyId(element: HTMLElement, dataId: string): void {
    super.applyId(element.querySelector('mat-icon'), dataId);
  }

  protected override getSubId(element: HTMLElement): string {
    let suffix = '';
    if (element.previousElementSibling?.tagName === 'MAT-CHECKBOX') {
      suffix = this.generateIdByText(
        this.getElementText(element.previousElementSibling as HTMLElement)
      );
    }
    return [this.elementPrefix, ...(suffix ? [suffix] : [])].join(this.SEPARATOR_INTERNAL);
  }
}

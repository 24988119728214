import { Action, createReducer, on } from '@ngrx/store';
import {
  forgotPassword,
  forgotPasswordFail,
  forgotPasswordSuccess,
  resetPassword,
  resetPasswordFail,
  resetPasswordSuccess
} from './forgot-password.actions';
import { ForgotPasswordState, initialForgotPasswordState } from './forgot-password.state';

export const forgotPasswordReducer = createReducer<ForgotPasswordState, Action>(
  initialForgotPasswordState,
  on(
    forgotPassword,
    resetPassword,
    (state: ForgotPasswordState): ForgotPasswordState => ({
      ...state,
      error: null,
      pending: true
    })
  ),
  on(
    forgotPasswordSuccess,
    resetPasswordSuccess,
    (state: ForgotPasswordState): ForgotPasswordState => ({
      ...state,
      error: null,
      pending: false
    })
  ),
  on(
    forgotPasswordFail,
    resetPasswordFail,
    (state: ForgotPasswordState, action): ForgotPasswordState => ({
      ...state,
      error: action.payload,
      pending: false
    })
  )
);

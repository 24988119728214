import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paginatorLabel',
  pure: true
})
export class PaginatorLabelPipe implements PipeTransform {
  public transform(pageIndex: number, length: number, pageSize: number): string {
    if (!length || !pageSize) {
      return '0 – 0 of 0';
    }
    const rangeStart = length < 1 ? 0 : pageIndex * pageSize + 1;
    const rangeEnd = (pageIndex + 1) * pageSize;
    return `${rangeStart} – ${Math.min(rangeEnd, length)} of ${length}`;
  }
}

import { Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';
import { BaseElementIdStrategy } from './base-element-id-strategy';

export class MatChipListElementIdStrategy extends BaseElementIdStrategy {
  private readonly elementPrefix = ['chip', 'list'].join(this.SEPARATOR_INTERNAL);

  constructor(
    renderer: Renderer2,
    private readonly ngControl: NgControl
  ) {
    super(renderer);
  }

  public override applyId(element: HTMLElement, dataId: string): void {
    super.applyId(element, dataId);

    const innerInput = element.querySelector('input');
    if (innerInput) {
      super.applyId(innerInput, [dataId, 'input'].join(this.SEPARATOR_INTERNAL));
    }
  }

  protected override getSubId(element: HTMLElement): string {
    if (element.parentElement?.closest('[data-id]')?.tagName === 'MAT-FORM-FIELD') {
      return this.elementPrefix;
    }

    return [
      this.elementPrefix,
      this.generateIdByText(
        this.ngControl?.name?.toString() || element.getAttribute('aria-label') || this.UNKNOWN_ID
      )
    ].join(this.SEPARATOR_INTERNAL);
  }
}

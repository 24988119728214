import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AsyncSelectFilter,
  ComparisonOperator,
  CompositeFilter,
  DateRange,
  Filter,
  FilterConfig,
  FilterFactory,
  FilterType,
  FilterValueObject,
  NumberCompareValue,
  SelectFilter,
  TextFilter
} from '@neuralegion/api';
import { isISODate } from '../../helpers';

@Injectable({
  providedIn: 'root'
})
export class FilterSerializationService {
  public convertToHttpParams(object: FilterValueObject<unknown>): HttpParams | null {
    if (!object) {
      return null;
    }
    switch (object.type) {
      case FilterType.TEXT:
        return (object as FilterValueObject<string | number>).value
          ? new HttpParams().set(
              `${(object as FilterValueObject<string | number>).name}${
                object.singular ? '' : '[]'
              }`,
              (object as FilterValueObject<string | number>).value
            )
          : new HttpParams();
      case FilterType.DATE_RANGE:
        return new HttpParams()
          .set(
            `${(object as FilterValueObject<DateRange>).name}[${ComparisonOperator.GTE}]`,
            new Date((object as FilterValueObject<DateRange>).value.startDate).toISOString()
          )
          .set(
            `${(object as FilterValueObject<DateRange>).name}[${ComparisonOperator.LTE}]`,
            new Date((object as FilterValueObject<DateRange>).value.endDate).toISOString()
          );
      case FilterType.NUMBER_COMPARE: {
        const numberCompareValue: NumberCompareValue = (
          object as FilterValueObject<NumberCompareValue>
        ).value;

        if (numberCompareValue.operator === ComparisonOperator.EQ) {
          return new HttpParams()
            .set(
              `${numberCompareValue.item.value}[${ComparisonOperator.GTE}]`,
              numberCompareValue.value
            )
            .set(
              `${numberCompareValue.item.value}[${ComparisonOperator.LTE}]`,
              numberCompareValue.value
            );
        }

        return new HttpParams().set(
          `${numberCompareValue.item.value}[${numberCompareValue.operator}]`,
          numberCompareValue.value
        );
      }
      case FilterType.SELECT:
      case FilterType.ASYNC_SELECT:
      case FilterType.EXTERNAL_FILTRATION_SELECT:
      case FilterType.COMPOSITE: {
        const stringParams: string = (object as FilterValueObject<Filter[]>).value
          .map((item: Filter | FilterValueObject<Filter[]>) => this.convertToHttpParams(item))
          .filter((params: HttpParams | null) => params?.keys().length > 0)
          .join('&');

        return new HttpParams({ fromString: stringParams });
      }
    }
  }

  public deserializeFromObject(
    object: FilterValueObject<unknown>,
    filterConfigs: readonly FilterConfig[]
  ): Filter | null {
    if (!object) {
      return null;
    }

    const filterConfig: FilterConfig = this.findConfig(object.type, object.name, filterConfigs);

    let filter: Filter = FilterFactory.createFilter(filterConfig);
    // Don't accept factory-created filter of type different than we have in the serialized object
    if (filter.type !== object.type) {
      return null;
    }
    switch (object.type) {
      case FilterType.TEXT:
        if ((object as FilterValueObject<string | number>).value) {
          filter.value = (object as FilterValueObject<string | number>).value;
        }
        break;
      case FilterType.DATE_RANGE:
        if ((object as FilterValueObject<DateRange>).value) {
          filter.value = {
            startDate: new Date((object as FilterValueObject<DateRange>).value.startDate),
            endDate: new Date((object as FilterValueObject<DateRange>).value.endDate)
          };
        }
        break;
      case FilterType.SELECT:
      case FilterType.ASYNC_SELECT:
      case FilterType.EXTERNAL_FILTRATION_SELECT:
        if ((object as FilterValueObject<TextFilter[]>).value?.length) {
          (object as FilterValueObject<TextFilter[]>).value.forEach((filterObject: TextFilter) => {
            (filter as SelectFilter | AsyncSelectFilter).add(
              this.deserializeFromObject(filterObject, [
                { type: FilterType.TEXT, name: object.name, settings: filterConfig.settings }
              ]) as TextFilter
            );
          });
        }
        break;
      case FilterType.NUMBER_COMPARE:
        filter.value = object.value;
        break;
      case FilterType.COMPOSITE:
        filter = new CompositeFilter();
        if ((object as FilterValueObject<Filter[]>).value?.length) {
          (object as FilterValueObject<Filter[]>).value.forEach(
            (filterObject: Filter | FilterValueObject<unknown>) => {
              const childFilter: Filter | null = this.deserializeFromObject(
                filterObject,
                filterConfigs
              );
              if (childFilter) {
                (filter as CompositeFilter).add(childFilter);
              }
            }
          );
        }
        break;
    }
    return filter;
  }

  public serializeToObject<T = unknown>(filter: Filter): FilterValueObject<T> {
    return JSON.parse(
      JSON.stringify(filter),
      this.serializeDateRangeFilter
    ) as FilterValueObject<T>;
  }

  private serializeDateRangeFilter(key: string, value: unknown): unknown {
    const dateParams: string[] = ['startDate', 'endDate'] as (keyof DateRange)[];

    return dateParams.includes(key) && typeof value === 'string' && isISODate(value)
      ? new Date(value)
      : value;
  }

  private findConfig(
    type: FilterType,
    name: string,
    filterConfigs: readonly FilterConfig[]
  ): FilterConfig {
    return filterConfigs.find(
      (config: FilterConfig) => config.type === type && config.name === name
    );
  }
}

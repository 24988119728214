import { KeyValue } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { ListControlErrors } from '../../models';
import { ListItemControl } from '../list-item-control/list-item-control.component';

export const DEFAULT_KEY_VALUE_ITEM_FACTORY = (): KeyValue<string, string> => ({
  key: '',
  value: ''
});

export interface KeyValueListItemSettings {
  readonly multilineValue?: boolean;
  readonly preserveValueNewLines?: boolean;
  readonly keyValidators?: ValidatorFn[];
  readonly valueValidators?: ValidatorFn[];
  readonly labelKey?: string;
  readonly labelValue?: string;
  readonly uniqueError?: string;
  readonly maxItemsError?: string;
}

interface KeyValueListItemForm {
  key: FormControl<string>;
  value: FormControl<string>;
}

@Component({
  selector: 'share-key-value-list-item-control',
  templateUrl: './key-value-list-item-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: KeyValueListItemControlComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: KeyValueListItemControlComponent,
      multi: true
    },
    {
      provide: ListItemControl,
      useExisting: KeyValueListItemControlComponent
    }
  ]
})
export class KeyValueListItemControlComponent
  extends ListItemControl<KeyValue<string, string>>
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input()
  public settings: Partial<KeyValueListItemSettings> = {};

  @Input()
  public tmplKeyErrors: TemplateRef<unknown>;

  @Input()
  public tmplValueErrors: TemplateRef<unknown>;

  @ViewChild('keyField', { read: ElementRef })
  public keyField: ElementRef<HTMLInputElement>;

  public readonly innerControl = new FormGroup<KeyValueListItemForm>({
    key: new FormControl(''),
    value: new FormControl('')
  });

  private readonly defaultSettings: KeyValueListItemSettings = {
    keyValidators: [Validators.maxLength(4096)],
    valueValidators: [Validators.maxLength(4096)],
    labelKey: 'Name',
    labelValue: 'Value',
    maxItemsError: 'Maximum items limit exceeded',
    uniqueError: 'Each key must be unique'
  };
  private readonly KEY_VALUE_DELIMITER = ': ';

  get prioritizedKeyErrorText(): string {
    if (this.innerControl.controls.key.hasError(ListControlErrors.LIST_LENGTH_EXCEEDED)) {
      return this.settings.maxItemsError;
    }
    if (this.innerControl.controls.key.hasError(ListControlErrors.DUPLICATED_LIST_ITEM)) {
      return this.settings.uniqueError;
    }

    return '';
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.listControlComponent.mapItemForUniqueCompare = (itemData: unknown) =>
      (itemData as KeyValue<string, string>).key.trim();

    this.listControlComponent.mapItemForCopy = (itemData: unknown): string => {
      const item = itemData as KeyValue<string, string>;
      if (!item.key && !item.value) {
        return '';
      }
      return `${item.key}${this.KEY_VALUE_DELIMITER}${item.value.replace(/[\n\r]/g, '')}`;
    };

    this.settings = {
      ...this.defaultSettings,
      ...this.settings
    };

    this.setupValidators();
  }

  public onPaste(event: ClipboardEvent): void {
    const pastedText = event.clipboardData.getData('text');
    if (!pastedText.includes(this.KEY_VALUE_DELIMITER)) {
      return;
    }

    event.preventDefault();

    const pastedData: KeyValue<string, string>[] = pastedText
      .split(/[\r]?\n/)
      .map((str) => str.split(this.KEY_VALUE_DELIMITER, 2))
      .map(
        ([key, value]: string[]): KeyValue<string, string> => ({
          ...(this.listControlComponent.settings.defaultValueFactory() as KeyValue<string, string>),
          key,
          value
        })
      );

    this.listControlComponent.onPaste(pastedData, this.idx);
  }

  protected override focusOnElement(): void {
    setTimeout(() => this.keyField.nativeElement.focus());
  }

  protected override mapFromFormValue(item: KeyValue<string, string>): KeyValue<string, string> {
    if (!this.settings.multilineValue || this.settings.preserveValueNewLines) {
      return item;
    }

    return {
      ...item,
      value: item.value.replace(/[\n\r]/g, '')
    };
  }

  private setupValidators(): void {
    if (this.settings.keyValidators?.length) {
      this.innerControl.controls.key.setValidators(this.settings.keyValidators);
    }
    if (this.settings.valueValidators?.length) {
      this.innerControl.controls.value.setValidators(this.settings.valueValidators);
    }
  }

  protected override setExternalErrors(parentControlErrors: ValidationErrors | null): void {
    const setExternalErrorsFn = (
      control: AbstractControl,
      parentErrors: ValidationErrors | null
    ) => {
      const resultErrors = {
        ...this.filterExternalErrors(control.errors || {}),
        ...(parentErrors || {})
      };
      control.setErrors(Object.keys(resultErrors).length ? resultErrors : null, {
        // parent control already knows about his errors, do not emit
        emitEvent: false
      });
    };

    setExternalErrorsFn(this.innerControl.controls.key, parentControlErrors);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [ListControlErrors.DUPLICATED_LIST_ITEM]: duplicated, ...rest } = parentControlErrors;
    setExternalErrorsFn(this.innerControl.controls.value, rest);
  }
}

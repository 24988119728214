import { Injectable } from '@angular/core';

import { Observable, map } from 'rxjs';
import { Store } from '@ngrx/store';
import { LoggedInUser } from '@neuralegion/api';
import { selectUserInfo } from '../store';

@Injectable()
export class SuperAdminGuard {
  constructor(private readonly store: Store) {}

  public canActivate(): Observable<boolean> {
    return this.store.select(selectUserInfo).pipe(map((user: LoggedInUser) => user?.isStaff));
  }

  public canMatch(): Observable<boolean> {
    return this.canActivate();
  }
}

<nav aria-label="Pagination" element-data-id="paginator" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
  <div class="range-label" element-data-id="range_label">{{ pageIndex | paginatorLabel: length : pageSize }}</div>
  <div class="action-buttons" fxLayout="row">
    <button
      *ngIf="showFirstButton"
      aria-label="First page"
      mat-icon-button
      matTooltip="First page"
      [disabled]="pageIndex === 0 || disabled"
      (click)="onPageButtonClick(0)">
      <mat-icon>first_page</mat-icon>
    </button>
    <button
      aria-label="Previous page"
      mat-icon-button
      matTooltip="Previous page"
      [disabled]="pageIndex === 0 || disabled"
      (click)="onPageButtonClick(pageIndex - 1)">
      <mat-icon>chevron_left</mat-icon>
    </button>
    <button
      aria-label="Next page"
      mat-icon-button
      matTooltip="Next page"
      [disabled]="pageIndex === maxPageIndex || disabled"
      (click)="onPageButtonClick(pageIndex + 1)">
      <mat-icon>chevron_right</mat-icon>
    </button>
    <button
      *ngIf="showLastButton"
      aria-label="Last page"
      mat-icon-button
      matTooltip="Last page"
      [disabled]="pageIndex === maxPageIndex || disabled"
      (click)="onPageButtonClick(maxPageIndex)">
      <mat-icon>last_page</mat-icon>
    </button>
  </div>
</nav>

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataIdQueueService {
  private readonly readyQueue = new Map<Element, () => void>();
  private readonly waitQueue = new Map<Element, () => void>();

  private mutationObserver: MutationObserver;
  private timeout: unknown;

  public add(element: Element, callback: () => void): void {
    if (element.isConnected) {
      this.addToReadyQueue(element, callback);
      return;
    }

    this.waitQueue.set(element, callback);

    if (!this.mutationObserver) {
      this.mutationObserver = new MutationObserver(this.onMutationCallback.bind(this));
      this.mutationObserver.observe(element.ownerDocument.body, { childList: true, subtree: true });
    }
  }

  public remove(element: Element): void {
    this.waitQueue.delete(element);
    this.readyQueue.delete(element);
  }

  private onMutationCallback(): void {
    const elements = [...this.waitQueue.keys()];

    elements.forEach((element: Element) => {
      if (element.isConnected) {
        this.addToReadyQueue(element, this.waitQueue.get(element));
        this.waitQueue.delete(element);
      }
    });
  }

  private addToReadyQueue(element: Element, callback: () => void): void {
    this.readyQueue.set(element, callback);

    if (!this.timeout) {
      this.timeout = setTimeout(this.onTimeoutCallback.bind(this), 0);
    }
  }

  private onTimeoutCallback(): void {
    this.readyQueue.forEach((callback: () => void) => {
      callback();
    });
    this.readyQueue.clear();

    this.timeout = undefined;
  }
}

import { Action, MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { Repeater } from '../models';
import {
  addRepeater,
  loadRepeater,
  loadRepeaters,
  removeRepeater,
  updateRepeater
} from './repeaters.actions';
import { RepeatersState, repeatersEntityAdapter } from './repeaters.state';

declare type AppState = object;

const selectRepeatersState = createFeatureSelector<RepeatersState>('repeaters');

export const selectAllRepeaters = createSelector<AppState, [RepeatersState], Repeater[]>(
  selectRepeatersState,
  repeatersEntityAdapter.getSelectors().selectAll
);

export const selectRepeatersForProject = (
  projectId: string
): MemoizedSelector<AppState, Repeater[]> =>
  createSelector<AppState, [Repeater[]], Repeater[]>(selectAllRepeaters, (repeaters: Repeater[]) =>
    repeaters.filter(
      (repeater: Repeater) =>
        !repeater.projectIds?.length || repeater.projectIds.includes(projectId)
    )
  );

export const selectRepeater = (
  repeaterId: string
): MemoizedSelector<AppState, Repeater | undefined> =>
  createSelector<AppState, [RepeatersState], Repeater | undefined>(
    selectRepeatersState,
    (state: RepeatersState) => state.entities[repeaterId]
  );

const selectPendingActionTypes = createSelector<AppState, [RepeatersState], string[]>(
  selectRepeatersState,
  (state: RepeatersState) =>
    !state || !state.pending || state.pending.length === 0
      ? []
      : [...new Set<string>(state.pending.map((pendedAction: Action) => pendedAction.type))]
);

export const selectRepeatersPendingStatus = createSelector<AppState, [string[]], boolean>(
  selectPendingActionTypes,
  (pendedActionTypes: string[]) =>
    !!pendedActionTypes.find(
      (pendedActionType: string) =>
        pendedActionType === addRepeater.type ||
        pendedActionType === loadRepeaters.type ||
        pendedActionType === removeRepeater.type ||
        pendedActionType === updateRepeater.type
    )
);

export const selectRepeaterDetailsPendingStatus = createSelector<AppState, [string[]], boolean>(
  selectPendingActionTypes,
  (pendedActionTypes: string[]) =>
    !!pendedActionTypes.find((pendedActionType: string) => pendedActionType === loadRepeater.type)
);

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  QueryList,
  ViewChildren
} from '@angular/core';
import { Observable } from 'rxjs';
import {
  CodeHexLinesService,
  DiffBlock,
  DiffBlockType,
  HexFormatPart,
  HexFormatResult,
  PrettifyResult
} from '../../services';

@Component({
  selector: 'share-code-hex-virtual-scroll',
  templateUrl: './code-hex-virtual-scroll.component.html',
  styleUrls: ['./code-hex-virtual-scroll.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CodeHexVirtualScrollComponent {
  @Input()
  public prettifyResult$: Observable<PrettifyResult>;

  @ViewChildren('details')
  private readonly detailsElRefs: QueryList<ElementRef<HTMLDetailsElement>>;

  public readonly HexFormatPart = HexFormatPart;
  public readonly DiffBlockType = DiffBlockType;

  public openedExtraBlock = -1;

  constructor(private readonly codeHexLinesService: CodeHexLinesService) {}

  public trackByOffsetLabel(_idx: number, item: HexFormatResult): string {
    return item.LABEL;
  }

  public trackByOffset(_idx: number, item: DiffBlock): number {
    return item.offset;
  }

  public onDetailsClick(e: Event, offset: number): void {
    const detailsEl = (e.target as HTMLElement).parentElement as HTMLDetailsElement;
    if (detailsEl.open) {
      this.openedExtraBlock = -1;
      return;
    }

    this.openedExtraBlock = offset;
    this.detailsElRefs
      .map((item) => item.nativeElement)
      .filter((el) => el !== detailsEl)
      .forEach((el) => (el.open = false));
  }

  public format(code: string): HexFormatResult[] {
    return this.codeHexLinesService.toHexLines(code);
  }
}

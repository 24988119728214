import { Injectable } from '@angular/core';
import { Observable, filter, map, withLatestFrom } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { trackEvent } from '@neuralegion/analytics-api';
import { Repeater } from '../models';
import { addRepeater, updateRepeater } from './repeaters.actions';
import { selectAllRepeaters } from './repeaters.selectors';

@Injectable()
export class RepeatersAnalyticsEffects {
  public readonly trackAddRepeater$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(addRepeater),
      map((action: ReturnType<typeof addRepeater>) =>
        trackEvent({
          name: 'Create Repeater Form Submitted',
          properties: {
            /* eslint-disable @typescript-eslint/naming-convention */
            repeater_name: action.payload.name,
            repeater_description: action.payload.description,
            repeater_projects: action.payload.projectIds
            /* eslint-enable @typescript-eslint/naming-convention */
          }
        })
      )
    )
  );

  public readonly trackUpdateRepeater$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(updateRepeater),
      withLatestFrom(this.store.select(selectAllRepeaters)),
      map(
        ([action, repeaters]: [ReturnType<typeof updateRepeater>, Repeater[]]): [
          ReturnType<typeof updateRepeater>,
          Repeater
        ] => [
          action,
          repeaters.find((repeater: Repeater) => repeater.id === action.payload.repeater.id)
        ]
      ),
      // exclude tracking filter activation/deactivation
      filter(
        ([action, repeater]: [ReturnType<typeof updateRepeater>, Repeater]) =>
          repeater.status === action.payload.repeater.status
      ),
      map(([action]: [ReturnType<typeof updateRepeater>, Repeater]) => action),
      map((action: ReturnType<typeof updateRepeater>) =>
        trackEvent({
          name: 'Update Repeater Form Submitted',
          properties: {
            /* eslint-disable @typescript-eslint/naming-convention */
            repeater_id: action.payload.repeater.id,
            repeater_name: action.payload.repeater.name,
            repeater_description: action.payload.repeater.description,
            repeater_projects: action.payload.repeater.projectIds
            /* eslint-enable @typescript-eslint/naming-convention */
          }
        })
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store
  ) {}
}

import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, exhaustMap, map, of, switchMap, tap } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { DateSettings, MemberNotificationSettings } from '@neuralegion/api';
import { DATE_SETTINGS_TOKEN } from '@neuralegion/core';
import { ProfileService } from '../services';
import {
  loadDateSettings,
  loadDateSettingsFail,
  loadDateSettingsSuccess,
  loadNotifications,
  loadNotificationsFail,
  loadNotificationsSuccess,
  updateDateSettings,
  updateDateSettingsFail,
  updateDateSettingsSuccess,
  updateNotifications,
  updateNotificationsFail,
  updateNotificationsSuccess
} from './profile-settings.actions';

@Injectable()
export class ProfileSettingsEffects {
  public readonly loadNotifications$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadNotifications),
      exhaustMap(() =>
        this.profileService.loadNotifications().pipe(
          map((res: MemberNotificationSettings) => loadNotificationsSuccess(res)),
          catchError((err: HttpErrorResponse) => of(loadNotificationsFail(err.error)))
        )
      )
    )
  );

  public readonly updateNotifications$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(updateNotifications),
      exhaustMap((action: ReturnType<typeof updateNotifications>) =>
        this.profileService.updateNotifications(action.payload.settings).pipe(
          map(() => updateNotificationsSuccess()),
          catchError((err: HttpErrorResponse) => of(updateNotificationsFail(err.error)))
        )
      )
    )
  );

  public readonly reloadNotifications$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<Action>(updateNotificationsSuccess),
      switchMap(() => of(loadNotifications()))
    )
  );

  public readonly loadDateSettings$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadDateSettings),
      exhaustMap(() =>
        this.profileService.loadDateSettings().pipe(
          map((res: DateSettings) => loadDateSettingsSuccess(res)),
          catchError((err: HttpErrorResponse) => of(loadDateSettingsFail(err.error)))
        )
      )
    )
  );

  public readonly updateDateSettings$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(updateDateSettings),
      exhaustMap((action: ReturnType<typeof updateDateSettings>) =>
        this.profileService.updateDateSettings(action.payload.settings).pipe(
          map(() => updateDateSettingsSuccess()),
          catchError((err: HttpErrorResponse) => of(updateDateSettingsFail(err.error)))
        )
      )
    )
  );

  public readonly reloadDateSettings$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<Action>(updateDateSettingsSuccess),
      switchMap(() => of(loadDateSettings()))
    )
  );

  public readonly onDateSettingsChange$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadDateSettingsSuccess),
        tap((action: ReturnType<typeof loadDateSettingsSuccess>) =>
          this.dateSettingsSubject.next(action.payload)
        )
      ),
    {
      dispatch: false
    }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly profileService: ProfileService,
    @Inject(DATE_SETTINGS_TOKEN) private readonly dateSettingsSubject: BehaviorSubject<DateSettings>
  ) {}
}

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Store } from '@ngrx/store';
import { API_VERSION_HEADER } from '../models';
import { updateAvailable } from '../store';
import { ApiVersionService } from './api-version.service';

@Injectable()
export class HttpInterceptorVersionHandlerService implements HttpInterceptor {
  constructor(
    private readonly store: Store,
    private readonly apiVersionService: ApiVersionService
  ) {}

  public intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    return next.handle(req.clone()).pipe(
      map((httpEvent: HttpEvent<T>) => {
        if (httpEvent instanceof HttpResponse && httpEvent.headers.has(API_VERSION_HEADER)) {
          const version = httpEvent.headers.get(API_VERSION_HEADER);
          if (!this.apiVersionService.version) {
            this.apiVersionService.version = version;
          } else if (this.apiVersionService.version !== version) {
            this.store.dispatch(updateAvailable());
          }
        }

        return httpEvent;
      })
    );
  }
}

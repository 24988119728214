import { Pipe, PipeTransform } from '@angular/core';
import { TimeZone } from '@neuralegion/api';

@Pipe({
  name: 'timeZoneLabel',
  pure: true
})
export class TimeZoneLabelPipe implements PipeTransform {
  public transform(timeZone: TimeZone): string {
    const str = new Intl.DateTimeFormat('en-US', { timeZoneName: 'short', timeZone }).format(
      new Date()
    );
    const idx = str.indexOf(', ');
    return `${timeZone}${idx === -1 ? '' : ' (' + str.substring(idx + 2) + ')'}`;
  }
}

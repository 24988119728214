import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Observable,
  catchError,
  distinctUntilChanged,
  exhaustMap,
  filter,
  map,
  of,
  withLatestFrom
} from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_REQUEST, RouterRequestAction } from '@ngrx/router-store';
import { Action, Store } from '@ngrx/store';
import { Organization, Quota, Scope, ScopePermissions } from '@neuralegion/api';
import { selectAuthActiveStatus, selectScopePermissions } from '@neuralegion/auth-api';
import { loadAuthProvider } from '@neuralegion/auth-providers-api';
import { loadProducts, loadSummary } from '@neuralegion/billing-api';
import { ofPrimaryRouterNavigated } from '@neuralegion/core';
import { loadIntegrations } from '@neuralegion/integrations-api';
import { OrganizationService } from '../services';
import { loadGroups } from './groups.actions';
import {
  loadOrganization,
  loadOrganizationFail,
  loadOrganizationSuccess,
  loadQuotas,
  loadQuotasFail,
  loadQuotasSuccess,
  updateOrganization,
  updateOrganizationFail,
  updateOrganizationSuccess
} from './organization.actions';
import { loadRoles } from './roles.actions';

@Injectable()
export class OrganizationEffects {
  public readonly loadOrganization$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOrganization),
      withLatestFrom(this.store.select(selectScopePermissions)),
      exhaustMap(
        ([action, scopePermissions]: [ReturnType<typeof loadOrganization>, ScopePermissions]) =>
          scopePermissions[Scope.ORG] || scopePermissions[Scope.ORG_READ]
            ? this.organizationService.loadUserOrganization().pipe(
                map((organization: Organization) =>
                  loadOrganizationSuccess({
                    organization,
                    skipRelated: action.payload.skipRelated
                  })
                ),
                catchError((err: HttpErrorResponse) => of(loadOrganizationFail(err.error)))
              )
            : of(loadOrganizationFail('Forbidden'))
      )
    )
  );

  public readonly loadQuotas$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadQuotas),
      exhaustMap((action: ReturnType<typeof loadQuotas>) =>
        this.organizationService.loadQuotas(action.payload.orgId).pipe(
          map((quotas: Quota[]) =>
            loadQuotasSuccess({
              quotas
            })
          ),
          catchError((err: HttpErrorResponse) => of(loadQuotasFail(err.error)))
        )
      )
    )
  );

  public readonly updateOrganization$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(updateOrganization),
      exhaustMap((action: ReturnType<typeof updateOrganization>) =>
        this.organizationService.updateUserOrganization(action.payload.organization).pipe(
          map(() => updateOrganizationSuccess()),
          catchError((err: HttpErrorResponse) => of(updateOrganizationFail(err.error)))
        )
      )
    )
  );

  public readonly reloadOnSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<Action>(updateOrganizationSuccess, updateOrganizationFail),
      map(() => loadOrganization({ skipRelated: false }))
    )
  );

  public readonly loadInitially$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_REQUEST),
      map((r: RouterRequestAction): string => r.payload.event.url),
      distinctUntilChanged(),
      filter((url) => !url.startsWith('/organization')),
      withLatestFrom(this.store.select(selectAuthActiveStatus)),
      map(([, active]: [string, boolean]): boolean => active),
      distinctUntilChanged(),
      filter((active: boolean) => active),
      map(() => loadOrganization({ skipRelated: true }))
    )
  );

  public readonly navigated$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofPrimaryRouterNavigated((state) => state.pathname.startsWith('/organization')),
      map(() => loadOrganization({ skipRelated: false }))
    )
  );

  public readonly loadRelated$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOrganizationSuccess),
      exhaustMap((action: ReturnType<typeof loadOrganizationSuccess>) =>
        action.payload.skipRelated
          ? [loadQuotas({ orgId: action.payload.organization.id })]
          : [
              loadQuotas({ orgId: action.payload.organization.id }),
              loadGroups(),
              loadRoles(),
              loadAuthProvider(),
              loadIntegrations(),
              loadSummary(),
              loadProducts()
            ]
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly organizationService: OrganizationService
  ) {}
}

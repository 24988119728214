import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Token } from '@neuralegion/api';
import { CookieStorageService } from './cookie-storage.service';

@Injectable({
  providedIn: 'root'
})
export class UserSessionStorageService {
  private readonly SESSION_COOKIE_KEY = 'user_authenticated';
  public readonly [Symbol.toStringTag]: 'UserSessionStorage';

  private readonly changesSubject = new BehaviorSubject<Token | null>(null);

  constructor(private readonly cookieStorageService: CookieStorageService) {}

  public hasLoggedIn(): boolean {
    return this.cookieStorageService.get(this.SESSION_COOKIE_KEY) as boolean;
  }

  public clear(): void {
    this.cookieStorageService.delete(this.SESSION_COOKIE_KEY);
    this.changesSubject.next(null);
  }

  public get(): Token | null {
    return this.hasLoggedIn() ? this.changesSubject.getValue() : null;
  }

  public set(value: Token): void {
    this.cookieStorageService.set(this.SESSION_COOKIE_KEY, true, {
      expiresIn: 2 // expires in 2 days
    });
    this.changesSubject.next(value);
  }
}

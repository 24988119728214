import { Action, createFeatureSelector, createSelector } from '@ngrx/store';
import { InviteDetails } from '@neuralegion/api';
import { SignupState } from './signup.state';

// eslint-disable-next-line @typescript-eslint/ban-types
type State = object;
const selectSignupState = createFeatureSelector<SignupState>('signup');

export const selectInviteDetails = createSelector<State, [SignupState], InviteDetails>(
  selectSignupState,
  (state: SignupState) => state.inviteDetails
);

const selectSignupPendingActions = createSelector<State, [SignupState], Action[]>(
  selectSignupState,
  (state: SignupState) => state.pending
);

export const selectSignupError = createSelector<State, [SignupState], string>(
  selectSignupState,
  (state: SignupState) => state.error
);

export const selectSignupPendingStatus = createSelector<State, [Action[]], boolean>(
  selectSignupPendingActions,
  (actions: Action[]) => actions.length > 0
);

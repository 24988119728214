import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, exhaustMap, map, of, withLatestFrom } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Scope } from '@neuralegion/api';
import { selectScopePermission } from '@neuralegion/auth-api';
import { AppRouterState, ofPrimaryRouterNavigated } from '@neuralegion/core';
import { Repeater } from '../models';
import { RepeatersService } from '../services';
import {
  addRepeater,
  addRepeaterFail,
  addRepeaterSuccess,
  loadRepeater,
  loadRepeaterFail,
  loadRepeaterSuccess,
  loadRepeaters,
  loadRepeatersFail,
  loadRepeatersSuccess,
  removeRepeater,
  removeRepeaterFail,
  removeRepeaterSuccess,
  updateRepeater,
  updateRepeaterFail,
  updateRepeaterSuccess
} from './repeaters.actions';

@Injectable()
export class RepeatersEffects {
  public readonly reloadRepeaters$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<Action>(addRepeaterSuccess, updateRepeaterSuccess, removeRepeaterSuccess),
      map(() => loadRepeaters())
    )
  );

  public readonly loadRepeaters$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRepeaters),
      withLatestFrom(this.store.select(selectScopePermission(Scope.REPEATERS_READ))),
      exhaustMap(([, repeatersReadPermission]: [ReturnType<typeof loadRepeaters>, boolean]) =>
        repeatersReadPermission
          ? this.repeatersService.loadRepeaters().pipe(
              map((res: Repeater[]) => loadRepeatersSuccess(res)),
              catchError((err: HttpErrorResponse) => of(loadRepeatersFail(err.error)))
            )
          : of(loadRepeatersSuccess([]))
      )
    )
  );

  public readonly loadRepeater$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRepeater),
      exhaustMap((action: ReturnType<typeof loadRepeater>) =>
        this.repeatersService.loadRepeater(action.payload).pipe(
          map((res: Repeater) => loadRepeaterSuccess(res)),
          catchError((err: HttpErrorResponse) => of(loadRepeaterFail(err.error)))
        )
      )
    )
  );

  public readonly updateRepeater$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(updateRepeater),
      exhaustMap((action: ReturnType<typeof updateRepeater>) =>
        this.repeatersService.updateRepeater(action.payload.repeater).pipe(
          map(() => updateRepeaterSuccess()),
          catchError((err: HttpErrorResponse) => of(updateRepeaterFail(err.error)))
        )
      )
    )
  );

  public readonly removeRepeater$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(removeRepeater),
      exhaustMap((action: ReturnType<typeof removeRepeater>) =>
        this.repeatersService.deleteRepeater(action.payload.repeaterId).pipe(
          map(() => removeRepeaterSuccess()),
          catchError((err: HttpErrorResponse) => of(removeRepeaterFail(err.error)))
        )
      )
    )
  );

  public readonly addRepeater$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(addRepeater),
      exhaustMap((action: ReturnType<typeof addRepeater>) =>
        this.repeatersService.createRepeater(action.payload).pipe(
          map(() => addRepeaterSuccess()),
          catchError((err: HttpErrorResponse) => of(addRepeaterFail(err.error)))
        )
      )
    )
  );

  public readonly navigation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofPrimaryRouterNavigated((state: AppRouterState) => state.pathname === '/repeaters'),
      map(() => loadRepeaters())
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly repeatersService: RepeatersService
  ) {}
}

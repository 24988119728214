import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FeedApiModule } from '@neuralegion/feed-api';
import { ShareModule } from '@neuralegion/share';
import { FeedItemComponent, FeedItemTextComponent } from '../components';
import { FeedIconComponent, FeedPanelComponent } from '../containers';
import {
  FeedExtraWordingPipe,
  FeedObjectTypePipe,
  FeedScheduleIntervalPipePipe,
  FeedVerbPastTensePipe
} from '../pipes';

@NgModule({
  imports: [RouterModule, ShareModule, FeedApiModule],
  declarations: [
    FeedIconComponent,
    FeedItemComponent,
    FeedItemTextComponent,
    FeedPanelComponent,
    FeedExtraWordingPipe,
    FeedObjectTypePipe,
    FeedScheduleIntervalPipePipe,
    FeedVerbPastTensePipe
  ],
  exports: [
    FeedIconComponent,
    FeedItemComponent,
    FeedItemTextComponent,
    FeedVerbPastTensePipe,
    FeedObjectTypePipe
  ]
})
export class FeedModule {}

import { BaseElementIdStrategy } from './base-element-id-strategy';

export class TableRowElementIdStrategy extends BaseElementIdStrategy {
  private readonly elementPrefix = 'row';

  protected override getSubId(element: HTMLElement): string {
    return [this.elementPrefix, this.getRowIndex(element)].join(this.SEPARATOR_INTERNAL);
  }

  private getRowIndex(element: HTMLElement): string {
    if (element.tagName === 'TR') {
      return `${(element as HTMLTableRowElement).rowIndex}`;
    } else if (element.tagName === 'MAT-ROW') {
      return `${
        [...element.closest('mat-table').querySelectorAll('mat-row')].indexOf(element) + 1
      }`;
    }
    return this.UNKNOWN_ID;
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Subject, filter, merge, takeUntil } from 'rxjs';
import { extractTouchedChanges } from '../../form';

@Component({
  selector: 'share-toggleable-limit',
  templateUrl: './toggleable-limit.component.html',
  styleUrls: ['./toggleable-limit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: ToggleableLimitComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: ToggleableLimitComponent,
      multi: true
    }
  ]
})
export class ToggleableLimitComponent
  implements ControlValueAccessor, OnInit, OnDestroy, Validator
{
  @Input()
  public label: string;

  @Input()
  public suffix: string;

  @Input()
  public min = 1;

  @Input()
  public max: number;

  @Input()
  public placeholder?: string;

  @ViewChild('limitEl', { static: false })
  public readonly limitEl: ElementRef<HTMLInputElement>;

  public readonly limitSwitcher = new FormControl(false);
  public readonly limitControl = new FormControl();

  private onChange: (value: number | null) => void;
  private onTouched: () => void;
  private readonly gc = new Subject<void>();

  public ngOnInit(): void {
    this.limitControl.setValidators([
      ...(this.min || this.min === 0 ? [Validators.min(this.min)] : []),
      ...(this.max || this.max === 0 ? [Validators.max(this.max)] : []),
      Validators.required
    ]);

    this.limitControl.valueChanges.pipe(takeUntil(this.gc)).subscribe((rawValue: number) => {
      const value = this.limitControl.disabled ? null : rawValue;
      if (this.onChange && this.limitSwitcher.enabled) {
        this.onChange(value);
      }

      if (value && !this.limitSwitcher.value) {
        this.limitSwitcher.setValue(true);
      }
    });

    this.limitSwitcher.valueChanges.pipe(takeUntil(this.gc)).subscribe((enabled: boolean) => {
      if (!enabled) {
        this.limitControl.disable();
      } else if (!this.limitSwitcher.disabled) {
        this.limitControl.enable();
      }
    });

    this.limitSwitcher.setValue(false);

    this.initTouchedListener();
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }

  public onLimitToggle(e: MatCheckboxChange): void {
    if (e.checked) {
      this.limitEl.nativeElement.focus();
    }
    this.limitControl.markAsDirty();
  }

  public registerOnChange(onChange: (value: number | null) => void): void {
    this.onChange = onChange;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.limitSwitcher.disable();
      this.limitControl.disable();
    } else {
      this.limitSwitcher.enable();
    }
  }

  public writeValue(value: number | null): void {
    this.limitControl.setValue(value);
    this.limitSwitcher.setValue(!!value);
  }

  public validate(_control: AbstractControl): ValidationErrors | null {
    return this.limitControl.disabled || this.limitControl.valid
      ? null
      : { toggleableLimitError: true };
  }

  private initTouchedListener(): void {
    merge(extractTouchedChanges(this.limitSwitcher), extractTouchedChanges(this.limitControl))
      .pipe(
        filter((touched) => touched),
        takeUntil(this.gc)
      )
      .subscribe(() => this.onTouched?.());
  }
}

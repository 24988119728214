<ng-container [formGroup]="innerControl">
  <div class="full-width">
    <div
      class="name-section"
      fxLayout="row"
      fxLayout.lt-sm="column"
      fxLayoutAlign="space-between center"
      fxLayoutAlign.lt-sm="start start"
      fxLayoutGap="16px"
      fxLayoutGap.lt-sm="0">
      <mat-form-field *ngIf="strategyMode" class="merge-strategy">
        <mat-label>Merge strategy</mat-label>
        <mat-select [formControlName]="'mergeStrategy'">
          <mat-option [value]="MergeStrategy.REPLACE">Replace</mat-option>
          <mat-option [value]="MergeStrategy.APPEND">Append</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Name</mat-label>
        <input
          type="text"
          #nameField
          matInput
          [formControlName]="'name'"
          [matAutocomplete]="auto"
          (focus)="onNameFocused()"
          (paste)="onPaste($event)" />
        <mat-error class="text-ellipsis" [matErrorOptions]="{ fieldName: 'Name' }"></mat-error>
      </mat-form-field>
    </div>
  </div>

  <mat-form-field class="full-width">
    <mat-label>Value</mat-label>
    <textarea
      class="custom-resizable"
      cdkTextareaAutosize
      matInput
      [cdkAutosizeMinRows]="1"
      [formControlName]="'value'"></textarea>
    <mat-error class="text-ellipsis" [matErrorOptions]="{ fieldName: 'Value' }"></mat-error>
  </mat-form-field>
</ng-container>

<mat-autocomplete class="header-list-item-autocomplete-panel" #auto>
  <mat-option *ngFor="let option of autocompleteOptions$ | async; trackBy: trackAutocomplete" [value]="option.name">
    <span [innerHTML]="option.highlighted"></span>
    <div *ngIf="option.description" class="description">{{ option.description }}</div>
  </mat-option>
</mat-autocomplete>

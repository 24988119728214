import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { Observable, filter, first, map } from 'rxjs';
import { Store } from '@ngrx/store';
import { trackLogin } from '@neuralegion/analytics-api';
import { ConfiguredProvider, ProviderType } from '@neuralegion/api';
import { selectSsoProvider } from '@neuralegion/auth-api';
import { deactivateLayout } from '@neuralegion/core';

// eslint-disable-next-line @angular-eslint/use-component-selector
@Component({
  styleUrls: ['./sso-providers-page.component.scss'],
  templateUrl: './sso-providers-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SsoProvidersPageComponent implements OnInit {
  public providerTypes$: Observable<ProviderType[]>;

  private provider$: Observable<ConfiguredProvider>;

  constructor(
    private readonly store: Store,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  public ngOnInit(): void {
    this.provider$ = this.store.select(selectSsoProvider);
    this.providerTypes$ = this.provider$.pipe(
      filter(Boolean),
      map((provider: ConfiguredProvider) => [provider.providerType])
    );
  }

  public ssoLogin(): void {
    this.provider$.pipe(first()).subscribe((provider: ConfiguredProvider) => {
      this.store.dispatch(trackLogin({ type: 'sso', provider: provider.providerType }));
      this.store.dispatch(deactivateLayout());
      setTimeout(() => (this.document.location.href = provider.authUrl), 0);
    });
  }
}

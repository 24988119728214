import { Action } from '@ngrx/store';

export function cleanupPending<T extends Action>(
  pending: T[],
  type: string,
  clearAll: boolean = true
): T[] {
  if (clearAll) {
    return pending.filter((pendedAction: T) => type !== pendedAction.type);
  }
  const pendedIdx = pending.findIndex((pendedAction: T) => type === pendedAction.type);
  return pendedIdx === -1
    ? pending
    : pending.filter((_pendedAction: T, idx: number) => idx !== pendedIdx);
}

export function cleanupPendingState<T extends { pending: Action[] }>(
  state: T,
  action: { parentType: string },
  error: string = null
): T {
  return {
    ...state,
    error,
    pending: cleanupPending(state.pending, action.parentType)
  };
}

import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, map, shareReplay, takeUntil } from 'rxjs';
import { ID } from '@neuralegion/api';

@Directive()
export abstract class TableBulkActionsComponent<T extends ID> implements OnInit, OnDestroy {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('data')
  set dataSetter(data: T[]) {
    this.data = data;
    this.selection.clear();
  }

  public readonly selectionActive$: Observable<boolean>;
  public readonly selection = new SelectionModel<string>(true, []);

  protected readonly gc = new Subject<void>();
  protected selectionChange$: Observable<SelectionChange<string>>;

  private data: T[];

  constructor(private readonly cdr: ChangeDetectorRef) {
    this.selectionChange$ = this.selection.changed.pipe(takeUntil(this.gc), shareReplay(1));
    this.selectionActive$ = this.selectionChange$.pipe(map(() => this.selection.hasValue()));
  }

  public ngOnInit(): void {
    this.selectionChange$.pipe(takeUntil(this.gc)).subscribe(() => {
      this.cdr.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }

  public isAllSelected(): boolean {
    return this.selection.hasValue() && this.selection.selected.length === this.data.length;
  }

  public isSomeSelected(): boolean {
    return this.selection.hasValue() && this.selection.selected.length !== this.data.length;
  }

  public toggleAll(): void {
    if (this.selection.hasValue()) {
      this.selection.clear();
    } else {
      this.selection.select(...this.data.map(({ id }) => id));
    }
  }

  protected getFilteredMarkedData(): T[] {
    return this.data.filter(({ id }) => this.selection.isSelected(id));
  }
}

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { ENVIRONMENT_TOKEN, Environment } from '@neuralegion/api';

@Component({
  selector: 'auth-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WelcomeComponent {
  constructor(@Inject(ENVIRONMENT_TOKEN) public readonly environment: Environment) {}
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterLabel',
  pure: true
})
export class FilterLabelPipe implements PipeTransform {
  public transform(value: string, filterLabels: ReadonlyMap<string, string>): string {
    return filterLabels.get(value) ?? 'N/A';
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  CompositeFilter,
  FilterConfig,
  FilterType,
  FilterValueObject,
  TextFilter
} from '@neuralegion/api';
import { FilterSerializationService } from '@neuralegion/core';
import { AllFiltersValue, GroupedFiltersValue, SearchFilterValue } from '../../models';

@Injectable()
export abstract class TableFiltersService {
  public groupedFiltersConfig: readonly FilterConfig[];
  public searchFilterConfig: readonly FilterConfig[];

  public searchFilterDefault: SearchFilterValue;

  protected constructor(
    protected readonly filterSerializationService: FilterSerializationService
  ) {}

  public getGroupedFilters(filters: AllFiltersValue): CompositeFilter | null {
    return (
      (this.filterSerializationService.deserializeFromObject(
        filters?.value.find(
          (filterValue: FilterValueObject<unknown>) => filterValue.type === FilterType.COMPOSITE
        ),
        this.groupedFiltersConfig
      ) as CompositeFilter) ?? null
    );
  }

  public getSearchFilter(filters: AllFiltersValue): TextFilter | null {
    const searchFilter: TextFilter =
      (this.filterSerializationService.deserializeFromObject(
        filters?.value.find(
          (filterValue: FilterValueObject<unknown>) => filterValue.type === FilterType.TEXT
        ),
        this.searchFilterConfig
      ) as TextFilter) ?? null;

    if (searchFilter) {
      searchFilter.value = null;
    }

    return searchFilter;
  }

  public getAllFilters(
    groupedFiltersValue: GroupedFiltersValue | null,
    searchFilterValue: SearchFilterValue | null
  ): CompositeFilter {
    const compositeFilter: CompositeFilter = new CompositeFilter();

    if (groupedFiltersValue?.value.length) {
      const groupedFilters: CompositeFilter =
        (this.filterSerializationService.deserializeFromObject(
          groupedFiltersValue,
          this.groupedFiltersConfig
        ) as CompositeFilter) ?? new CompositeFilter();

      compositeFilter.add(groupedFilters);
    }

    if (searchFilterValue) {
      const searchFilter: TextFilter = this.filterSerializationService.deserializeFromObject(
        searchFilterValue,
        this.searchFilterConfig
      ) as TextFilter;

      searchFilter.value = searchFilterValue.value;
      compositeFilter.add(searchFilter);
    }

    return compositeFilter;
  }

  public filtersExists(allFiltersValue: AllFiltersValue): boolean {
    const groupedFiltersValue = this.getGroupedFilters(allFiltersValue);
    const searchFiltersValue = this.getSearchFilter(allFiltersValue);

    return groupedFiltersValue?.value.length > 0 || !!searchFiltersValue?.value;
  }

  public abstract getValidFilters(allFiltersValue: AllFiltersValue): Observable<AllFiltersValue>;

  protected abstract getGroupedFiltersConfig(): FilterConfig[];

  protected abstract getSearchFilterConfig(): FilterConfig[];
}

import { Injectable } from '@angular/core';
import type {
  historyKeymap as historyKeymapType,
  history as historyType
} from '@codemirror/commands';
import type { HighlightStyle, LanguageSupport, syntaxHighlighting } from '@codemirror/language';
import type {
  Compartment as CompartmentType,
  EditorState as EditorStateType
} from '@codemirror/state';
import type { EditorView, keymap, lineNumbers } from '@codemirror/view';
import type { tags } from '@lezer/highlight';
import type { basicSetup } from 'codemirror';

export interface CodeMirrorDependencies {
  history: typeof historyType;
  historyKeymap: typeof historyKeymapType;
  basicSetup: typeof basicSetup;
  EditorState: typeof EditorStateType;
  Compartment: typeof CompartmentType;
  EditorView: typeof EditorView;
  lineNumbers: typeof lineNumbers;
  keymap: typeof keymap;
  languages: Record<string, () => LanguageSupport>;
  HighlightStyle: typeof HighlightStyle;
  syntaxHighlighting: typeof syntaxHighlighting;
  tags: typeof tags;
}

@Injectable({
  providedIn: 'root'
})
export class CodeMirrorLoaderService {
  private codeMirrorDeps: CodeMirrorDependencies;

  public async importCodeMirrorDependencies(): Promise<CodeMirrorDependencies> {
    if (!this.codeMirrorDeps) {
      const { history, historyKeymap } = await import(
        /* webpackChunkName: "code-editor" */ '@codemirror/commands'
      );
      const { basicSetup } = await import(/* webpackChunkName: "code-editor" */ 'codemirror');
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { EditorState, Compartment } = await import(
        /* webpackChunkName: "code-editor" */ '@codemirror/state'
      );
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { EditorView, lineNumbers, keymap } = await import(
        /* webpackChunkName: "code-editor" */ '@codemirror/view'
      );
      const { javascript } = await import(
        /* webpackChunkName: "code-editor" */ '@codemirror/lang-javascript'
      );
      const { json } = await import(/* webpackChunkName: "code-editor" */ '@codemirror/lang-json');
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { HighlightStyle, syntaxHighlighting, LanguageSupport, StreamLanguage } = await import(
        /* webpackChunkName: "code-editor" */ '@codemirror/language'
      );
      const { yaml } = await import(
        /* webpackChunkName: "code-editor" */ '@codemirror/legacy-modes/mode/yaml'
      );
      const { tags } = await import(/* webpackChunkName: "code-editor" */ '@lezer/highlight');

      this.codeMirrorDeps = {
        history,
        historyKeymap,
        basicSetup,
        EditorState,
        Compartment,
        EditorView,
        lineNumbers,
        keymap,
        languages: {
          javascript,
          json,
          yaml: () => new LanguageSupport(StreamLanguage.define(yaml))
        },
        HighlightStyle,
        syntaxHighlighting,
        tags
      };
    }

    return this.codeMirrorDeps;
  }
}

import { Action, createReducer, on } from '@ngrx/store';
import { activateLayout, deactivateLayout, mediaQueryChanged } from './core.actions';
import { LayoutState, initialLayoutState } from './layout.state';

export const layoutReducer = createReducer<LayoutState, Action>(
  initialLayoutState,
  on(
    deactivateLayout,
    (state: LayoutState): LayoutState => ({
      ...state,
      pending: true
    })
  ),
  on(
    activateLayout,
    (state: LayoutState): LayoutState => ({
      ...state,
      pending: false
    })
  ),
  on(
    mediaQueryChanged,
    (state: LayoutState, action): LayoutState => ({
      ...state,
      mqAliases: action.payload.mqAliases
    })
  )
);

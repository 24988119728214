import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { Group } from '@neuralegion/api';

export interface GroupsState extends EntityState<Group> {
  error: string;
  pending: Action[];
}

export const groupsEntityAdapter: EntityAdapter<Group> = createEntityAdapter<Group>({
  selectId: (model) => model.id
});

export const groupsInitialState: GroupsState = groupsEntityAdapter.getInitialState({
  error: null,
  pending: []
});

import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StorageFileUploaderService, StorageService } from '../services';
import { StorageEffects } from '../store/storage.effects';
import { storageReducer } from '../store/storage.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature('storage', storageReducer),
    EffectsModule.forFeature([StorageEffects])
  ],
  providers: [StorageService, StorageFileUploaderService]
})
export class StorageApiModule {}

<fieldset>
  <div fxLayout="column">
    <div class="full-width list-control-header" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="8px">
      <div>
        <ng-content select="[list-control-header]"></ng-content>
      </div>
      <button
        *ngIf="formArray.length && settings.copyAll"
        class="text-button btn-copy"
        attr.aria-label="{{ settings.labelCopyAll }}"
        mat-button
        [matTooltip]="settings.labelCopyAll"
        (click)="onCopyButtonClick()">
        <mat-icon>content_copy</mat-icon>
        <span>{{ settings.labelCopyAll }}</span>
      </button>
    </div>
    <div>
      <ng-content select="[list-control-subheader]"></ng-content>
    </div>
    <div
      *ngFor="let control of formArray.controls; let idx = index; trackBy: trackByIdx"
      role="group"
      attr.aria-label="List item {{ idx + 1 }}">
      <ng-container [formGroup]="control">
        <div
          class="container-row"
          fxLayout="row"
          fxLayoutAlign="stretch center"
          fxLayoutAlign.lt-sm="start start"
          [element-data-id]="idx">
          <div
            class="container-pair full-width"
            fxLayout="row"
            fxLayout.lt-sm="column"
            fxLayoutAlign="stretch center"
            fxLayoutAlign.lt-sm="start start"
            fxLayoutGap="0"
            fxLayoutGap.gt-sm="16px">
            <ng-container *ngTemplateOutlet="tmplItem; context: { $implicit: control, idx }"> </ng-container>
          </div>

          <button
            class="btn-remove"
            attr.aria-label="Remove list item {{ idx + 1 }}"
            mat-icon-button
            matTooltip="Remove"
            [disabled]="formArray.disabled || (settings.keepOne && formArray.value?.length === 1)"
            (click)="remove(idx)">
            <mat-icon>clear</mat-icon>
          </button>
        </div>
      </ng-container>
    </div>
  </div>

  <div>
    <span *ngIf="!formArray.length" class="empty" [element-data-id]="'dataset_empty'">
      {{ settings.emptyPlaceholder }}
    </span>
    <button
      *ngIf="formArray.enabled"
      class="text-button btn-add"
      attr.aria-label="{{ settings.labelAddItem }}"
      mat-button
      [matTooltip]="settings.labelAddItem"
      (click)="onAddButtonClicked()">
      <mat-icon>add</mat-icon>
      <span>{{ settings.labelAddItem }}</span>
    </button>
  </div>
</fieldset>

<a
  *ngIf="{ sidebarExpanded: sidebarExpanded$ | async } as sync"
  attr.aria-label="{{ label }}"
  mat-list-item
  [class.disabled]="disabled"
  [matTooltip]="disabled ? 'Not available in current plan' : sync.sidebarExpanded ? '' : tooltip"
  [matTooltipPosition]="'right'"
  [routerLink]="disabled ? null : link"
  [routerLinkActive]="disabled ? '' : 'active'">
  <mat-icon matListItemIcon>{{ icon }}</mat-icon>
  <span matListItemTitle>
    <ng-content *ngIf="sync.sidebarExpanded"></ng-content>
  </span>
</a>

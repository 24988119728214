import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, mergeMap, of, switchMap, take, withLatestFrom } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Dictionary } from '@ngrx/entity';
import { Action, Store } from '@ngrx/store';
import { Scope } from '@neuralegion/api';
import { selectScopePermission } from '@neuralegion/auth-api';
import { Project } from '../models';
import { ProjectsLookupService } from '../services';
import {
  loadPredefinedProject,
  loadPredefinedProjectFail,
  loadPredefinedProjectSuccess,
  loadProjects,
  loadProjectsFail,
  loadProjectsSuccess
} from './projects-lookup.actions';
import { selectPredefinedProject, selectProjectEntities } from './projects-lookup.selectors';

@Injectable()
export class ProjectsLookupEffects {
  public readonly loadProjects$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadProjects),
      mergeMap((action: ReturnType<typeof loadProjects>) => {
        const ids = Array.from(new Set(action.payload));

        return action.force
          ? of(ids)
          : this.store.select(selectProjectEntities).pipe(
              take(1),
              map((dictionary: Dictionary<Project>) => ids.filter((id) => !dictionary[id]))
            );
      }),
      withLatestFrom(this.store.select(selectScopePermission(Scope.PROJECTS_READ))),
      mergeMap(([ids, projectsReadPermission]: [string[], boolean]) => {
        return projectsReadPermission && ids.length
          ? this.projectsLookupService.loadProjectsByIds(ids).pipe(
              map((res: Project[]) => loadProjectsSuccess(res)),
              catchError((err: HttpErrorResponse) => of(loadProjectsFail(err.error)))
            )
          : of(loadProjectsSuccess([]));
      })
    )
  );

  public readonly loadPredefinedProject$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPredefinedProject),
      withLatestFrom(
        this.store.select(selectPredefinedProject),
        this.store.select(selectScopePermission(Scope.PROJECTS_READ))
      ),
      switchMap(
        ([_, project, projectsReadPermission]: [
          ReturnType<typeof loadPredefinedProject>,
          Project,
          boolean
        ]) => {
          return projectsReadPermission && !project
            ? this.projectsLookupService.loadPredefinedProject().pipe(
                map((res: Project) => loadPredefinedProjectSuccess(res)),
                catchError((err: HttpErrorResponse) => of(loadPredefinedProjectFail(err.error)))
              )
            : of(loadPredefinedProjectSuccess(null));
        }
      )
    )
  );

  constructor(
    private readonly store: Store,
    private readonly actions$: Actions,
    private readonly projectsLookupService: ProjectsLookupService
  ) {}
}

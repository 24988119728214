import { Injectable } from '@angular/core';
import { StorageCleanerService } from './storage-cleaner.service';
import { TableSettingsService } from './table-settings.service';

@Injectable()
export abstract class TableSettingsCleanerService extends StorageCleanerService {
  protected constructor(protected readonly tableSettingsService: TableSettingsService) {
    super();
  }

  protected clearKey(key: string): void {
    this.tableSettingsService.clearPageIndex(key);
    this.tableSettingsService.clearFilters(key);
    this.tableSettingsService.clearPaginationState(key);
  }
}

<mat-icon
  *ngIf="trigger === 'click'; else tmplHoverTrigger"
  class="focus-ring"
  role="button"
  tabindex="0"
  [satPopoverAnchor]="popover"
  (click)="onTooltipToggle($event, popover)"
  (keydown.enter)="onTooltipToggle($event, popover)"
  (keydown.space)="onTooltipToggle($event, popover)">
  info
</mat-icon>

<ng-template #tmplHoverTrigger>
  <mat-icon
    class="focus-ring"
    tabindex="0"
    satPopoverHover
    [satPopoverAnchor]="popover"
    (keydown.enter)="onTooltipToggle($event, popover)"
    (keydown.space)="onTooltipToggle($event, popover)"
    >info</mat-icon
  >
</ng-template>

<sat-popover #popover xAlign="after" yAlign="center">
  <div class="custom-tooltip" [attr.data-id]="'info_tooltip'">
    <ng-content></ng-content>

    <a *ngIf="link" class="link-more" attr.aria-label="{{ label }}" target="_blank" [href]="link | safeUrl">
      {{ linkText }}
    </a>
  </div>
</sat-popover>

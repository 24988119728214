import { InjectionToken } from '@angular/core';

export interface Environment {
  readonly production: boolean;
  readonly version: string;
  readonly commit: string;

  readonly archiveMaxFileSize?: number;
  readonly mondayAppId?: string;
  readonly reCaptchaSiteKey: string;
  readonly segmentWriteKey: string;
  readonly sentryDsn: string;

  readonly invalidDataIdReporterEnabled?: boolean;
  readonly pagePatternLogoutIndicatorEnabled?: boolean;
  readonly projectForAuthObjectRequired?: boolean;
  readonly projectLevelEntrypointsEnabled?: boolean;
  readonly projectExternalScanConfigSourcesEnabled?: boolean;
  readonly snykAppsEnabled?: boolean;
  readonly rdpBasedAuthRecorderEnabled?: boolean;
  readonly rtcBasedAuthRecorderEnabled?: boolean;
  readonly ipRestrictionsEnabled?: boolean;
}

export const ENVIRONMENT_TOKEN: InjectionToken<Environment> = new InjectionToken(
  'EnvironmentToken'
);

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'verbPastTense',
  pure: true
})
export class VerbPastTensePipe implements PipeTransform {
  // based on https://gist.github.com/letsgetrandy/1e05a68ea74ba6736eb5
  private getPastTense(verb: string): string {
    if (verb.endsWith('e')) {
      return `${verb}d`;
    }

    if (/[aeiou]c/i.test(verb)) {
      return `${verb}ked`;
    }

    if (/[aeio][aeiou][dlmnprst]$/.test(verb)) {
      return `${verb}ed`;
    }

    if (/[aeiou][bdglmprst]$/i.test(verb)) {
      return verb.replace(/(.+[aeiou])([bdglmprst])/, '$1$2$2ed');
    }

    return `${verb}ed`;
  }

  public transform(verb: string): string {
    return verb && this.getPastTense(verb);
  }
}

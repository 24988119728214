import { Injectable } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { ColumnConfig, TableSortConfig } from '@neuralegion/api';
import { LocalStorageService } from './local-storage.service';
import { SessionStorageService } from './session-storage.service';
import { TableSettings } from './table-settings';

export enum StorageKey {
  SORT_PARAMS = 'SORT_PARAMS',
  PAGE_INDEX = 'PAGE_INDEX',
  PAGE_SIZE = 'PAGE_SIZE',
  COLUMNS_CONFIG = 'COLUMNS_CONFIG',
  PAGINATION_STATE = 'PAGINATION_STATE',
  FILTERS = 'FILTERS'
}

@Injectable({
  providedIn: 'root'
})
export class TableSettingsService implements TableSettings {
  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly sessionStorageService: SessionStorageService
  ) {}

  private getSortParams(tableId: string): TableSortConfig {
    return this.sessionStorageService.get(
      this.generateId(StorageKey.SORT_PARAMS, tableId)
    ) as TableSortConfig;
  }

  public saveSortParams(tableId: string, sort: Sort): void {
    this.sessionStorageService.set(this.generateId(StorageKey.SORT_PARAMS, tableId), {
      direction: sort.direction,
      column: sort.active
    });
  }

  public applySortParams(tableId: string, sort: MatSort): void {
    const sortParams: TableSortConfig = this.getSortParams(tableId);
    if (sortParams?.column && sortParams?.direction) {
      sort.sort({
        id: sortParams.column,
        start: sortParams.direction,
        disableClear: false
      });
    }
  }

  private getPageIndex(tableId: string): number {
    return this.sessionStorageService.get(
      this.generateId(StorageKey.PAGE_INDEX, tableId)
    ) as number;
  }

  private setPageIndex(tableId: string, page: number): void {
    this.sessionStorageService.set(this.generateId(StorageKey.PAGE_INDEX, tableId), page);
  }

  public clearPageIndex(tableId: string): void {
    this.sessionStorageService.delete(this.generateId(StorageKey.PAGE_INDEX, tableId));
  }

  public applyPageIndex(tableId: string, paginator: MatPaginator): void {
    const pageIndex = this.getPageIndex(tableId);
    if (Number.isFinite(pageIndex)) {
      paginator.pageIndex = pageIndex;
    }
  }

  private getPageSize(tableId: string): number {
    return this.localStorageService.get(this.generateId(StorageKey.PAGE_SIZE, tableId)) as number;
  }

  private setPageSize(tableId: string, pageSize: number): void {
    this.localStorageService.set(this.generateId(StorageKey.PAGE_SIZE, tableId), pageSize);
  }

  public applyPageSize(tableId: string, paginator: MatPaginator): void {
    const pageSize = this.getPageSize(tableId);
    if (pageSize && Number.isFinite(pageSize)) {
      paginator.pageSize = pageSize;
    }
  }

  public savePageParams(tableId: string, pageParams: PageEvent): void {
    this.setPageSize(tableId, pageParams?.pageSize);
    this.setPageIndex(tableId, pageParams?.pageIndex);
  }

  public getColumnsConfig(tableId: string): ColumnConfig[] {
    return this.localStorageService.get(
      this.generateId(StorageKey.COLUMNS_CONFIG, tableId)
    ) as ColumnConfig[];
  }

  public saveColumnsConfig(tableId: string, columnConfig: ColumnConfig[]): void {
    this.localStorageService.set(this.generateId(StorageKey.COLUMNS_CONFIG, tableId), columnConfig);
  }

  public getFilters(tableId: string): unknown {
    return this.sessionStorageService.get(this.generateId(StorageKey.FILTERS, tableId));
  }

  public saveFilters(tableId: string, filters: unknown): void {
    this.sessionStorageService.set(this.generateId(StorageKey.FILTERS, tableId), filters);
  }

  public clearFilters(tableId: string): void {
    this.sessionStorageService.delete(this.generateId(StorageKey.FILTERS, tableId));
  }

  public clearPaginationState(tableId: string): void {
    this.sessionStorageService.delete(this.generateId(StorageKey.PAGINATION_STATE, tableId));
  }

  private generateId(key: string, table: string): string {
    return `${table}_${key}`;
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MfaQrCode, Token } from '@neuralegion/api';

@Injectable()
export class MultiFactorService {
  constructor(private readonly http: HttpClient) {}

  public enableMfa(code: string, otp: string): Observable<void> {
    return this.http.post<void>(
      '/api/v1/auth/mfa/setup',
      { code, otp },
      {
        withCredentials: true
      }
    );
  }

  public disableMfa(): Observable<void> {
    return this.http.delete<void>('/api/v1/auth/mfa/setup');
  }

  public loadMfaQrCode(): Observable<MfaQrCode> {
    return this.http.get<MfaQrCode>('/api/v1/auth/mfa/setup');
  }

  public verifyMfaOtp(token: string, otp: string): Observable<Token> {
    return this.http.post<Token>(
      '/api/v1/auth/mfa',
      { token, otp },
      {
        withCredentials: true
      }
    );
  }
}

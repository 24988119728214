import { Pipe, PipeTransform } from '@angular/core';
import { DiscoveryType } from '@neuralegion/api';

@Pipe({
  name: 'discoveryType',
  pure: true
})
export class DiscoveryTypePipe implements PipeTransform {
  public transform(
    value: DiscoveryType | DiscoveryType[],
    simplify: boolean = false
  ): string | string[] {
    return Array.isArray(value)
      ? value.map((discoveryType) => this.format(discoveryType, simplify))
      : this.format(value, simplify);
  }

  private format(discoveryType: DiscoveryType, simplify: boolean): string {
    switch (discoveryType) {
      case DiscoveryType.ARCHIVE:
        return simplify ? 'Website via recorded session (HAR file)' : 'Recording (HAR)';
      case DiscoveryType.CRAWLER:
        return simplify ? 'Website via automatic crawling' : 'Crawler';
      case DiscoveryType.OAS:
        return simplify ? 'API endpoints via schema' : 'API';
      case DiscoveryType.SNIFFER:
        return simplify ? 'API endpoints via automatic sniffing' : 'Sniffer';
      default:
        return 'Unknown';
    }
  }
}

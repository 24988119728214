import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Optional, SkipSelf } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Email, Hash, ID, LoggedInUser, ProviderType, SignedUpUser } from '@neuralegion/api';
import { SnakeCase } from '@neuralegion/lang';

/* eslint-disable @typescript-eslint/no-unsafe-call */

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    analytics: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Intercom: any;
  }
}

interface User extends ID, Email {
  readonly organizationId: string;
}

interface IdentityUser extends Omit<User, 'organizationId'>, Hash {
  readonly organizationId?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private readonly window: Window;
  // A list of the methods in Analytics.js to stub.
  private readonly STUB_METHOD_NAMES: readonly string[] = [
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'debug',
    'page',
    'once',
    'off',
    'on',
    'addSourceMiddleware',
    'addIntegrationMiddleware',
    'setAnonymousId',
    'addDestinationMiddleware'
  ];

  private user?: User;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly router: Router,
    @Optional() @SkipSelf() analyticsService?: AnalyticsService
  ) {
    if (analyticsService) {
      throw new Error('"AnalyticsService" should be singleton');
    }

    this.window = this.document.defaultView;

    this.init();

    this.subscribe();
  }

  // Loads Analytics.js from CDN
  public load(key: string): void {
    const script = this.document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`;

    this.document.head.appendChild(script);
  }

  public track<T extends Record<string, unknown>>(
    eventName: string,
    eventProperties?: T & Record<SnakeCase<keyof T & string>, unknown>
  ): void {
    this.window.analytics.track(eventName, {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      user_id: this.user?.id,
      email: this.user?.email,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      org_id: this.user?.organizationId,
      hostname: this.window.location.hostname,
      ...(eventProperties || {})
    });
  }

  public trackSignup(props: {
    user?: Email;
    type: 'local' | 'social' | 'aws';
    invited: boolean;
    provider?: ProviderType;
  }): void {
    this.window.analytics.track('Signup Form Submitted', {
      hostname: this.window.location.hostname,
      ...(props.user ? { ...props.user } : {}),
      /* eslint-disable @typescript-eslint/naming-convention */
      signup_type: props.type,
      invited: props.invited,
      signup_provider: props.provider ?? null,
      user_role: 'unknown'
      /* eslint-enable @typescript-eslint/naming-convention */
    });
  }

  public trackSuccessSignup(user: SignedUpUser): void {
    this.identity(user);
  }

  public trackLogin(props: {
    user?: Email;
    type: 'local' | 'social' | 'sso';
    provider?: ProviderType;
  }): void {
    this.window.analytics.track('Login Form Submitted', {
      ...(props.user ? props.user : {}),
      hostname: this.window.location.hostname,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      login_type: props.type,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      login_provider: props.provider
    });
  }

  public setUser(user: LoggedInUser | null): void {
    if (user) {
      this.user = {
        id: user.id,
        email: user.email,
        organizationId: user.organizationId
      };
      this.identity(user);
      this.trackUser('Login Attempt Succeeded', this.user);
    } else {
      this.reset();
    }
  }

  private trackUser(eventName: string, { id, email, organizationId }: User): void {
    this.window.analytics.track(eventName, {
      email,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      org_id: organizationId,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      user_id: id || this.user?.id,
      hostname: this.window.location.hostname
    });
  }

  private identity(
    user: IdentityUser,
    type?: 'local' | 'social' | 'sso',
    provider?: ProviderType,
    userRole: string = 'unknown'
  ): void {
    this.window.analytics.identify(
      user.id,
      {
        email: user.email,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        user_id: user.id,
        ...(this.isUserLoggedIn(user)
          ? // eslint-disable-next-line @typescript-eslint/naming-convention
            { org_id: user.organizationId }
          : {
              /* eslint-disable @typescript-eslint/naming-convention */
              signup_type: type,
              signup_provider: provider,
              user_role: userRole
            })
      },
      {
        Intercom: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          user_hash: user.hash
        }
      }
    );
  }

  private isUserLoggedIn(user: User | (Email & ID)): user is User {
    return 'organizationId' in user;
  }

  private page(category?: string): void {
    this.window.analytics.page(category);
  }

  private reset(): void {
    this.window.analytics.reset();
    this.window.Intercom?.('shutdown');
  }

  private subscribe(): void {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.page();
      }
    });
  }

  // Creates stubs for methods in Analytics.js so that you never have to wait
  // for it to load to actually record data. The `method` is
  // stored as the first argument, so we can replay the data.
  private stub(method: string): void {
    const { analytics } = this.window;

    const stub: (...args: unknown[]) => unknown = (...args: unknown[]): unknown => {
      analytics.push([method, ...args]);

      return analytics;
    };

    analytics[method] = stub;
  }

  private init(): void {
    const analytics = (this.window.analytics = this.window.analytics || []);

    if (analytics.initialize) {
      return;
    }

    // If the snippet was invoked already show an error.
    if (analytics.invoked) {
      console.error('Segment snippet included twice.');
      return;
    }

    analytics.invoked = true;

    // For each of our methods, generate a queueing stub.
    this.STUB_METHOD_NAMES.forEach((method: string) => this.stub(method));
  }
}

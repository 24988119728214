import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { map } from 'rxjs';
import { Breadcrumb, BreadcrumbService } from './breadcrumb.service';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbPageTitleService {
  private readonly DEFAULT_TITLE = 'Bright Security';
  private readonly SECTIONS_DELIMITER = ' · ';

  constructor(
    private readonly titleService: Title,
    private readonly breadcrumbService: BreadcrumbService
  ) {}

  public init(): void {
    this.breadcrumbService.breadcrumbsChanges$
      .pipe(
        map((breadcrumbs: Breadcrumb[]) =>
          [...breadcrumbs]
            .reverse()
            .map((breadcrumb: Breadcrumb) => breadcrumb.label)
            .concat(this.DEFAULT_TITLE)
            .join(this.SECTIONS_DELIMITER)
        )
      )
      .subscribe((title: string) => this.titleService.setTitle(title));
  }
}

import {
  AfterViewInit,
  Directive,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  shareReplay,
  takeUntil
} from 'rxjs';
import equal from 'fast-deep-equal/es6';
import { SettingsError, SettingsTouchedState } from '../components';
import { SettingsFormService, SettingsSectionsRequiredCounters } from '../services';
import { SubFormTouchedState } from './settings-sub-form-component.directive';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class SettingsFormComponent<T extends number | string>
  implements AfterViewInit, OnDestroy
{
  @ViewChild(MatTabGroup)
  public readonly matTabGroup: MatTabGroup;

  @ViewChildren(MatTab)
  public readonly matTabs: QueryList<MatTab>;
  public selectedTabIndex = 0;

  public form: FormGroup;

  public visitedSections$: Observable<ReadonlySet<T>> = this.settingsFormService.visitedSections$;
  public sectionsRequiredCounters$: Observable<Record<T, SettingsSectionsRequiredCounters<T>>>;
  public rawErrors$: Observable<SettingsError<T>[]>;
  public errors$: Observable<SettingsError<T>[]>;

  protected readonly gc = new Subject<void>();

  protected readonly touchedStatesSubject = new BehaviorSubject<SettingsTouchedState<T>[]>([]);

  protected constructor(protected readonly settingsFormService: SettingsFormService<T>) {}

  public ngAfterViewInit(): void {
    this.onTabChange(this.matTabs.get(this.selectedTabIndex));
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }

  public onTouchedChange(section: T, states: SubFormTouchedState[]): void {
    this.touchedStatesSubject.next([
      ...this.touchedStatesSubject.value.filter((item) => item.section !== section),
      ...states.map((state: SubFormTouchedState) => ({ section, ...state }))
    ]);
  }

  public selectTab(section: T): void {
    const tabIndex = [...this.matTabs].findIndex((tab: MatTab) => tab.textLabel === section);
    if (tabIndex !== -1) {
      this.selectedTabIndex = tabIndex;
      this.matTabGroup.focusTab(tabIndex);
    }
  }

  public onTabChange(tab: MatTab): void {
    this.settingsFormService.onSectionChange(tab.textLabel as T);
  }

  protected initErrorsProcessing(): void {
    this.sectionsRequiredCounters$ = this.settingsFormService.sectionRequiredCounters$.pipe(
      debounceTime(100),
      distinctUntilChanged(equal)
    );

    this.rawErrors$ = this.form.statusChanges.pipe(
      debounceTime(300),
      map(() => this.getErrors()),
      takeUntil(this.gc),
      shareReplay(1)
    );

    this.errors$ = combineLatest([this.touchedStatesSubject, this.rawErrors$]).pipe(
      distinctUntilChanged(equal),
      map(([touchedStates, errors]) => this.filterErrorsByTouchedStates(errors, touchedStates))
    );
  }

  /**
   * we can't use this.form.errors because
   * https://github.com/angular/angular/issues/10530
   */
  protected abstract getErrors(): SettingsError<T>[];

  protected mapSectionErrors(section: T, errorsData: ValidationErrors | null): SettingsError<T>[] {
    const collectedErrors: SettingsError<T>[] = [];
    Object.keys(errorsData).forEach((control) => {
      Object.keys(errorsData[control]).forEach((error) =>
        collectedErrors.push({ section, control, error })
      );
    });
    return collectedErrors;
  }

  private filterErrorsByTouchedStates(
    errors: SettingsError<T>[],
    touchedStates: SettingsTouchedState<T>[]
  ): SettingsError<T>[] {
    return errors.filter((error: SettingsError<T>) =>
      touchedStates.find(
        (state: SettingsTouchedState<T>) =>
          error.section === state.section && error.control === state.control && state.touched
      )
    );
  }
}

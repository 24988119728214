import { ChangeDetectionStrategy, Component, Input, SecurityContext } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ClipboardService } from '@neuralegion/core';

@Component({
  selector: 'share-mfa-qr-code',
  templateUrl: './mfa-qr-code.component.html',
  styleUrls: ['./mfa-qr-code.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MfaQrCodeComponent {
  @Input()
  public form: FormGroup;

  @Input()
  public url: string;

  get sanitizedUrl(): string {
    return this.sanitizer.sanitize(
      SecurityContext.RESOURCE_URL,
      this.sanitizer.bypassSecurityTrustResourceUrl(this.url)
    );
  }

  constructor(
    private readonly clipboardService: ClipboardService,
    private readonly sanitizer: DomSanitizer
  ) {}

  public doCopy(event: Event): void {
    event.preventDefault();
    this.clipboardService.copyToClipboard(this.form.get('code')?.value, 'Shared code copied');
  }
}

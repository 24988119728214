import { createAction } from '@ngrx/store';
import { StorageFile } from '../models';

export const removeFiles = createAction('[Storage] REMOVE_FILES', (payload: { ids: string[] }) => ({
  payload
}));

export const removeFilesFail = createAction('[Storage] REMOVE_FILES_FAIL', (payload: string) => ({
  payload,
  parentType: removeFiles.type
}));

export const removeFilesSuccess = createAction('[Storage] REMOVE_FILES_SUCCESS', () => ({
  parentType: removeFiles.type
}));

export const loadFiles = createAction('[Storage] LOAD_FILES');

export const loadFilesFail = createAction('[Storage] LOAD_FILES_FAIL', (payload: string) => ({
  payload,
  parentType: loadFiles.type
}));

export const loadFilesSuccess = createAction(
  '[Storage] LOAD_FILES_SUCCESS',
  (payload: StorageFile[]) => ({
    payload,
    parentType: loadFiles.type
  })
);

export const associateFileWithProject = createAction(
  '[Storage] ASSOCIATE_FILE_WITH_PROJECT',
  (payload: { fileId: string; projectId: string }) => ({ payload })
);

export const associateFileWithProjectSuccess = createAction(
  '[Storage] ASSOCIATE_FILE_WITH_PROJECT_SUCCESS',
  (payload: { fileId: string; projectId: string }) => ({
    parentType: associateFileWithProject.type,
    payload
  })
);

export const associateFileWithProjectFail = createAction(
  '[Storage] ASSOCIATE_FILE_WITH_PROJECT_FAIL',
  (payload: string) => ({ payload, parentType: associateFileWithProject.type })
);

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TabConfig } from '@neuralegion/api';
import { trackByIdentity } from '@neuralegion/core';

@Component({
  selector: 'share-tab-view-settings',
  templateUrl: './tab-view-settings.component.html',
  styleUrls: ['./tab-view-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabViewSettingsComponent {
  @Input()
  public tabsConfig: TabConfig[] = [];

  @Output()
  public readonly tabsConfigChanged = new EventEmitter<TabConfig[]>();

  @Output()
  public readonly resetClicked = new EventEmitter<void>();

  public readonly trackByIdentity = trackByIdentity;
}

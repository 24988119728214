<div class="select-header" fxLayout="row" fxLayoutAlign="space-between center">
  <mat-checkbox [formControl]="selectAllCheckbox">All</mat-checkbox>
  <share-text-filter [placeholder]="filterPlaceholder"></share-text-filter>
</div>

<ng-container *ngIf="{ items: items$ | async, filteredItems: filteredItems$ | async } as sync">
  <mat-selection-list attr.aria-label="{{ itemName }} list" fxLayout="column" [formControl]="selectControl">
    <mat-list-option
      *ngFor="let item of sync.filteredItems; trackBy: trackById"
      checkboxPosition="before"
      [hidden]="item.hidden"
      [value]="item.id">
      {{ item.label }}
    </mat-list-option>
  </mat-selection-list>

  <mat-error *ngIf="selectControl.hasError('required')" [element-data-id]="'error'">
    At least one {{ itemName | lowercase }} must be selected
  </mat-error>

  <div class="select-footer" [element-data-id]="'selected'">
    <span [element-data-id]="'counter'">{{ selectControl.value?.length || 0 | simplePlural: itemName }} selected</span>
    <ng-container *ngIf="selectControl.value?.length">
      <span>:&nbsp;</span>
      <span [element-data-id]="'names'">{{
        selectControl.value | mapById: sync.items | pluck: 'label' | join: ', '
      }}</span>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="{ pending: pending$ | async } as sync">
  <button
    mat-button
    [class.active]="sync.pending"
    [class.fullWidth]="options.fullWidth"
    [class.mat-mdc-fab]="options.fab"
    [class.mat-mdc-outlined-button]="options.stroked"
    [class.mat-mdc-raised-button]="options.raised"
    [class.mat-mdc-unelevated-button]="options.flat"
    [color]="options.buttonColor"
    [disabled]="sync.pending || options.disabled"
    [matTooltip]="sync.pending || options.disabled ? options.disabledTooltip : null"
    [matTooltipClass]="'share-spinner-button-disabled-tooltip'"
    [ngClass]="options.customClass"
    [type]="options.type"
    (click)="onClick($event, sync.pending)">
    <ng-container *ngIf="options.buttonIcon">
      <mat-icon
        class="mat-button-icon"
        [class.active]="sync.pending && !options.disabled"
        [class.is-mat-icon]="!options.buttonIcon.fontSet"
        [color]="options.buttonIcon.color"
        [fontIcon]="options.buttonIcon.fontIcon"
        [fontSet]="options.buttonIcon.fontSet"
        [inline]="options.buttonIcon.inline"
        [ngClass]="options.buttonIcon.customClass"
        [svgIcon]="options.buttonIcon.svgIcon">
        {{ options.buttonIcon.fontSet ? '' : options.buttonIcon.fontIcon }}
      </mat-icon>
    </ng-container>

    <span *ngIf="!options.fab" class="button-text" [class.active]="sync.pending && !options.disabled">
      <ng-content></ng-content>
    </span>

    <ng-container *ngIf="options.fab && options.icon">
      <mat-icon
        [color]="options.icon.color"
        [fontIcon]="options.icon.fontIcon"
        [fontSet]="options.icon.fontSet"
        [inline]="options.icon.inline"
        [svgIcon]="options.icon.svgIcon">
        {{ options.icon.fontSet ? '' : options.icon.fontIcon }}
      </mat-icon>
    </ng-container>

    <mat-spinner
      *ngIf="sync.pending && !options.disabled"
      class="spinner"
      [class.active]="sync.pending && !options.disabled"
      [color]="options.spinnerColor"
      [diameter]="options.fab ? 58 : options.spinnerSize"
      [mode]="options.mode"
      [value]="options.value">
    </mat-spinner>
  </button>
</ng-container>

import { InjectionToken } from '@angular/core';

export interface ClientInfo {
  info: {
    source: string;
    client?: { name: string; version: string };
  };
}

export const CLIENT_INFO_TOKEN: InjectionToken<ClientInfo> = new InjectionToken(
  'CLIENT_INFO_TOKEN'
);

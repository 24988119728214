import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { Subject, filter, takeUntil } from 'rxjs';
import equal from 'fast-deep-equal/es6';
import {
  AsyncSelectFilter,
  AsyncSelectFilterSettings,
  Filter,
  FilterConfig,
  FilterType,
  SelectFilter,
  SelectFilterSettings,
  SelectItem
} from '@neuralegion/api';
import { trackByProperty } from '@neuralegion/core';
import { XOR } from '@neuralegion/lang';
import { extractTouchedChanges } from '../../../form';
import { FilterControl } from '../../../models';

// eslint-disable-next-line @angular-eslint/use-component-selector
@Component({
  templateUrl: './select-filter-control.component.html',
  styleUrls: ['./select-filter-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: SelectFilterControlComponent, multi: true },
    { provide: NG_VALIDATORS, useExisting: SelectFilterControlComponent, multi: true }
  ]
})
export class SelectFilterControlComponent implements FilterControl, OnInit, OnDestroy {
  @Input()
  public filterConfig: FilterConfig;

  @Input()
  public filters: Filter[];

  get selectFilter(): XOR<SelectFilter, AsyncSelectFilter> {
    return this.filters.find((f) => f.name === this.filterConfig.name) as XOR<
      SelectFilter,
      AsyncSelectFilter
    >;
  }

  get selectFilterSettings(): XOR<SelectFilterSettings, AsyncSelectFilterSettings> {
    return this.filterConfig.settings as XOR<SelectFilterSettings, AsyncSelectFilterSettings>;
  }

  public readonly formControl = new FormControl(null, [Validators.required]);

  public readonly FilterType = FilterType;
  public readonly trackByValue = trackByProperty<SelectItem>('value');

  private onChange: (value: SelectItem) => void;
  private onTouched: () => void;

  private readonly valueSubject = new Subject<SelectItem>();
  private readonly gc = new Subject<void>();

  public ngOnInit() {
    this.initTouchedListener();
    this.initValueSubjectListener();
    this.initFormControlListener();
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.complete();
  }

  public registerOnChange(fn: (value: SelectItem) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  public validate(): ValidationErrors | null {
    return this.formControl.valid ? null : { dateRangeFilterControl: true };
  }

  public writeValue(value: SelectItem): void {
    this.valueSubject.next(value);
  }

  private initTouchedListener(): void {
    extractTouchedChanges(this.formControl)
      .pipe(
        filter((touched) => touched),
        takeUntil(this.gc)
      )
      .subscribe(() => this.onTouched?.());
  }

  private initValueSubjectListener(): void {
    this.valueSubject
      .pipe(
        filter((value: SelectItem) => !equal(this.formControl.value, value)),
        takeUntil(this.gc)
      )
      .subscribe((value: SelectItem) => this.formControl.setValue(value));
  }

  private initFormControlListener(): void {
    this.formControl.valueChanges
      .pipe(takeUntil(this.gc))
      .subscribe((value: SelectItem) => this.onChange?.(value));
  }
}

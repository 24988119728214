import { DOCUMENT } from '@angular/common';
import {
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2
} from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  delay,
  distinctUntilChanged,
  filter,
  map,
  of,
  switchMap,
  takeUntil,
  withLatestFrom
} from 'rxjs';
import equal from 'fast-deep-equal/es6';
import { ColumnWidthValue } from '@neuralegion/core';
import { uuid } from '@neuralegion/lang';

interface ResizeMeta {
  index: number;
  startX: number;
  startWidth: number;
}

@Directive({
  selector: '[shareResizeColumns]'
})
export class ResizeColumnsDirective implements OnInit, OnDestroy {
  @Input()
  public disabledColumnIds: string[] = [];

  @Input()
  public widths$: Observable<ColumnWidthValue[]>;

  @Output()
  public readonly resizeActive = new EventEmitter<boolean>();

  @Output()
  public readonly widthsChanged = new EventEmitter<ColumnWidthValue[]>();

  private readonly MIN_WIDTH = 50;

  private readonly resizeElementStylesText = `
    .mat-mdc-header-cell {
      position: relative;
      user-select: none;
    }
    .mat-mdc-header-cell > .resize {
        position: absolute;
        width: 5px;
        top: 0;
        height: 100%;
        z-index: 1;
        right: 0;
        cursor: col-resize;
      }
    }
  `;

  private readonly hostId = uuid();
  private readonly columnsStyleId = `columns-${this.hostId}`;
  private readonly resizeElementsStyleId = `columns-resize-${this.hostId}`;

  private readonly releaseFns: (() => void)[] = [];
  private readonly gc = new Subject<void>();
  private readonly activeResizeSubject = new BehaviorSubject<ResizeMeta>(null);
  private readonly columnWidthsSubject = new BehaviorSubject<ColumnWidthValue[]>(null);

  private get headerCells(): HTMLElement[] {
    return Array.from(
      ([...this.elementRef.nativeElement.children] as HTMLElement[]).find(
        (item: HTMLElement) => item.tagName === 'MAT-HEADER-ROW'
      )?.children || []
    ) as HTMLElement[];
  }

  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly renderer: Renderer2,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  public ngOnInit(): void {
    this.setupResizeElementsStyles();

    this.widths$
      .pipe(
        distinctUntilChanged(equal),
        // project only last event in task
        switchMap((columnWidths: ColumnWidthValue[]) => of(columnWidths).pipe(delay(0))),
        takeUntil(this.gc)
      )
      .subscribe((columnWidths: ColumnWidthValue[]) => {
        if (columnWidths.every((c) => !c.width)) {
          // 'auto' to pixel conversion
          this.updateColumnWidthStyles(columnWidths);
        }

        this.setupResizeElements();
        const calculatedWidths = this.headerCells.map((el, i) => ({
          id: columnWidths[i].id,
          width: columnWidths[i].width || el.clientWidth
        }));

        this.columnWidthsSubject.next(calculatedWidths);

        // initial pixel values; not connected to columnWidthsSubject as emitted only on resize end
        this.widthsChanged.emit(calculatedWidths);
      });

    this.activeResizeSubject
      .pipe(filter(Boolean), takeUntil(this.gc))
      .subscribe((resizeMeta: ResizeMeta) => {
        this.addResizeActiveListeners(resizeMeta.index);
      });

    this.activeResizeSubject.pipe(map(Boolean), takeUntil(this.gc)).subscribe((active: boolean) => {
      if (!active) {
        // workaround for table header cell sorting trigger
        setTimeout(() => this.resizeActive.emit(active), 0);
      } else {
        this.resizeActive.emit(active);
      }
    });

    const columnWidths$ = this.columnWidthsSubject.pipe(
      filter(Boolean),
      distinctUntilChanged(equal)
    );

    this.activeResizeSubject
      .pipe(
        filter((resizeMeta) => !resizeMeta),
        withLatestFrom(columnWidths$),
        takeUntil(this.gc)
      )
      .subscribe(([, columnWidths]: [ResizeMeta, ColumnWidthValue[]]) => {
        this.widthsChanged.emit(columnWidths);
      });

    columnWidths$.pipe(takeUntil(this.gc)).subscribe((columnWidths: ColumnWidthValue[]) => {
      this.updateColumnWidthStyles(columnWidths);
    });

    this.activeResizeSubject
      .pipe(distinctUntilChanged(equal), takeUntil(this.gc))
      .subscribe((resizeMeta: ResizeMeta) => {
        if (resizeMeta) {
          const columnId = this.columnWidthsSubject.getValue()[resizeMeta.index].id;
          this.elementRef.nativeElement
            .querySelectorAll(`.mat-column-${columnId}`)
            .forEach((element: Element) => this.renderer.addClass(element, 'cell-resize-active'));
        } else {
          this.elementRef.nativeElement
            .querySelectorAll('.cell-resize-active')
            .forEach((element: Element) =>
              this.renderer.removeClass(element, 'cell-resize-active')
            );
        }
      });
  }

  public ngOnDestroy(): void {
    this.cleanupStyles();
    this.releaseFns.forEach((l) => l());
    this.gc.next();
    this.gc.unsubscribe();
  }

  private setupResizeElementsStyles(): void {
    this.elementRef.nativeElement.setAttribute('data-host-id', this.hostId);

    const style = this.renderer.createElement('style');
    this.renderer.setAttribute(style, 'type', 'text/css');
    this.renderer.setAttribute(style, 'id', this.resizeElementsStyleId);

    const css = this.renderer.createText(this.resizeElementStylesText);
    this.renderer.appendChild(style, css);

    const head = this.document.querySelector('head');
    this.renderer.appendChild(head, style);
  }

  private setupResizeElements(): void {
    this.releaseFns.forEach((l) => l());

    this.headerCells.forEach((el, i) => {
      const ignoredColumn = this.disabledColumnIds.some((id) =>
        el.classList.contains(`mat-column-${id}`)
      );
      if (ignoredColumn) {
        return;
      }

      this.appendAuxResizeElement(el, i);
    });
  }

  private updateColumnWidthStyles(columnWidths: ColumnWidthValue[]): void {
    const stylesText = columnWidths
      .map((column) => {
        const width = column.width;
        return !width
          ? ''
          : `.viewer-container:not(.pending) [data-host-id="${this.hostId}"] .mat-column-${column.id} {
           min-width: ${width}px !important;
           width: ${width}px !important;
           flex-grow: 0 !important;
        }`;
      })
      .join('\n');

    let style = this.document.getElementById(this.columnsStyleId);
    if (style) {
      this.renderer.removeChild(style, style.childNodes[0]);
    } else {
      const headEl = this.document.querySelector('head');
      style = this.renderer.createElement('style');
      this.renderer.setAttribute(style, 'type', 'text/css');
      this.renderer.setAttribute(style, 'id', this.columnsStyleId);
      this.renderer.appendChild(headEl, style);
    }

    const css = this.renderer.createText(stylesText);
    this.renderer.appendChild(style, css);
  }

  private appendAuxResizeElement(parent: HTMLElement, index: number): void {
    const oldResizeEl = parent.querySelector(`.resize`);
    if (oldResizeEl) {
      this.renderer.removeChild(parent, oldResizeEl);
    }

    const resizeEl = this.renderer.createElement('div');
    this.renderer.addClass(resizeEl, 'resize');
    this.renderer.appendChild(parent, resizeEl);

    this.releaseFns.push(
      this.renderer.listen(resizeEl, 'mousedown', (e: MouseEvent) => {
        this.activeResizeSubject.next({
          index,
          startX: e.pageX,
          startWidth: parent.clientWidth
        });
      }),
      this.renderer.listen(resizeEl, 'click', (e: MouseEvent) => {
        e.stopPropagation();
      })
    );
  }

  private addResizeActiveListeners(targetIndex: number): void {
    const { index, startX, startWidth } = this.activeResizeSubject.getValue();

    const releaseMouseMove = this.renderer.listen('document', 'mousemove', (e: MouseEvent) => {
      const dx = e.pageX - startX;
      const width = startWidth + dx;
      if (dx && index === targetIndex && width >= this.MIN_WIDTH) {
        this.applyWidthChange(targetIndex, width);
      }
    });

    const releaseMouseUp = this.renderer.listen('document', 'mouseup', (_e: MouseEvent) => {
      releaseMouseMove();
      releaseMouseUp();
      this.activeResizeSubject.next(null);
    });
  }

  private applyWidthChange(columnIndex: number, width: number): void {
    this.columnWidthsSubject.next(
      this.columnWidthsSubject.getValue().map((column: ColumnWidthValue, idx: number) => ({
        ...column,
        ...(idx === columnIndex ? { width: Math.max(this.MIN_WIDTH, width) } : {})
      }))
    );
  }

  private cleanupStyles(): void {
    const head = this.document.querySelector('head');

    const columnsStyle = this.document.getElementById(this.columnsStyleId);
    if (columnsStyle) {
      this.renderer.removeChild(head, columnsStyle);
    }

    const resizeStyle = this.document.getElementById(this.resizeElementsStyleId);
    if (resizeStyle) {
      this.renderer.removeChild(head, resizeStyle);
    }
  }
}

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { HTTP_ERROR_HANDLER_OPTIONS, IGNORE_ERROR_INTERCEPTOR_TOKEN } from '../models';
import { addHttpError } from '../store';

@Injectable()
export class HttpInterceptorErrorHandlerService implements HttpInterceptor {
  constructor(private readonly store: Store) {}

  public intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    if (req.context.get(IGNORE_ERROR_INTERCEPTOR_TOKEN)) {
      return next.handle(req);
    }

    return next.handle(req.clone()).pipe(
      catchError((error: HttpErrorResponse) => {
        this.store.dispatch(
          addHttpError({ error, options: req.context.get(HTTP_ERROR_HANDLER_OPTIONS)?.(error) })
        );
        return throwError(() => error);
      })
    );
  }
}

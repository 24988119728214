import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output
} from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'share-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
  animations: [
    trigger('sidenavAnimationIsExpanded', [
      state(
        'true',
        style({
          width: '256px'
        })
      ),
      state(
        'false',
        style({
          width: '64px'
        })
      ),
      transition('false <=> true', animate('.3s cubic-bezier(0.4,0.0,0.2,1)'))
    ])
  ]
})
export class SidebarComponent {
  @Input()
  public opened: boolean;

  @Input()
  public expanded$: Observable<boolean>;

  @Input()
  public smallMedia: boolean;

  @Output()
  public readonly expand: EventEmitter<void> = new EventEmitter<void>(true);

  @Output()
  public readonly closed: EventEmitter<void> = new EventEmitter<void>(true);

  constructor(private readonly node: ElementRef<HTMLElement>) {}

  @HostListener('document:click', ['$event'])
  public listenOutsideMouseEvent(event: MouseEvent): void {
    if (!this.smallMedia || !this.opened) {
      return;
    }

    const target: HTMLBaseElement = event.target as HTMLBaseElement;
    const isInside: boolean = this.getNodeElement().contains(target);
    if (!isInside) {
      event.stopPropagation();
      this.closed.emit();
    }
  }

  public toggleExpand(): void {
    this.expand.emit();
  }

  private getNodeElement(): HTMLElement {
    return this.node.nativeElement;
  }
}

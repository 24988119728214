import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ServiceName } from '@neuralegion/api';

@Component({
  selector: 'share-config-source-chip',
  templateUrl: './config-source-chip.component.html',
  styleUrls: ['./config-source-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigSourceChipComponent {
  @Input()
  public serviceName: ServiceName;
}

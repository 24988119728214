import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { DateRange } from '@angular/material/datepicker';
import {
  UserDateService,
  differenceInCalendarDays,
  subMonths,
  subWeeks,
  subYears,
  trackByIdentity
} from '@neuralegion/core';

type RangeStep = '1d' | '1w' | '1m' | '3m' | '6m' | '1y';

@Component({
  selector: 'share-date-ranges-predefined',
  templateUrl: './date-ranges-predefined.component.html',
  styleUrls: ['./date-ranges-predefined.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateRangesPredefinedComponent {
  @Input()
  set range(value: DateRange<Date> | null) {
    const newValue = value ? this.getRangeLabelFromDateRange(value) : null;
    if (newValue !== this.value) {
      this.value = newValue;
    }
  }

  @Output()
  public readonly rangeChanged: EventEmitter<DateRange<Date>> = new EventEmitter<DateRange<Date>>();

  public ranges: { key: RangeStep; label: string }[] = [
    { key: '1w', label: '1 Week' },
    { key: '1m', label: '1 Month' },
    { key: '3m', label: '3 Month' },
    { key: '6m', label: '6 Month' },
    { key: '1y', label: '1 Year' }
  ];

  public value: string | null;

  public readonly trackByIdentity = trackByIdentity;

  constructor(private readonly userDateService: UserDateService) {}

  public onRangeChange(e: MatButtonToggleChange): void {
    const endDate = this.userDateService.applyTz(new Date());
    const startDate = this.userDateService.applyTz(
      this.calculateBeginDateForRange(e.value as string, endDate)
    );
    this.rangeChanged.emit(new DateRange<Date>(startDate, endDate));
  }

  private calculateBeginDateForRange(rangeStr: string, endDate: Date = new Date()): Date {
    const value = +rangeStr.replace(/[^\d]+/g, '') || 1;
    const period = rangeStr.replace(/^\d+/, '');

    switch (period) {
      case 'w':
        return subWeeks(endDate, value, true);
      case 'm':
        return subMonths(endDate, value, true);
      case 'y':
        return subYears(endDate, value, true);
    }

    return endDate;
  }

  private getRangeLabelFromDateRange(value: DateRange<Date>): string | null {
    const currentDate = this.userDateService.applyTz(new Date());

    if (!this.calendarDatesEqual(value.end, currentDate)) {
      return null;
    }

    return (
      this.ranges.find((range: { key: RangeStep; label: string }) =>
        this.calendarDatesEqual(
          value.start,
          this.calculateBeginDateForRange(range.key, currentDate)
        )
      )?.key ?? null
    );
  }

  private calendarDatesEqual(dateA: Date, dateB: Date): boolean {
    return differenceInCalendarDays(dateA, dateB) === 0;
  }
}

import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { RepeaterNetworkResult } from '../models';
import { pollRepeaterTest } from './repeater-network.actions';
import { RepeaterNetworkState } from './repeater-network.state';

declare type AppState = object;

const selectRepeaterNetworkState = createFeatureSelector<RepeaterNetworkState>('repeaterNetwork');

export const selectRepeaterNetworkPendingStatus = createSelector<
  AppState,
  [RepeaterNetworkState],
  boolean
>(
  selectRepeaterNetworkState,
  (state: RepeaterNetworkState) =>
    state.pending.filter((action) => action.type !== pollRepeaterTest.type).length > 0
);

export const selectRepeaterNetworkResult = (
  repeaterId: string
): MemoizedSelector<AppState, RepeaterNetworkResult> =>
  createSelector<AppState, [RepeaterNetworkState], RepeaterNetworkResult>(
    selectRepeaterNetworkState,
    (state: RepeaterNetworkState) => state.entities[repeaterId]
  );

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'auth-sso-form',
  templateUrl: './sso-form.component.html',
  styleUrls: ['./sso-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SsoFormComponent {
  @Input()
  set pending(isPending: boolean) {
    if (isPending) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Output()
  public readonly submitted = new EventEmitter<{ slug: string }>();

  @ViewChild('inputField', { read: ElementRef })
  public inputField: ElementRef<HTMLElement>;

  public readonly form = new FormGroup({
    slug: new FormControl('', Validators.required)
  });

  public onSso(): void {
    if (this.form.valid) {
      this.submitted.emit({ slug: this.form.value?.slug });
    } else {
      this.inputField.nativeElement.focus();
    }
  }
}

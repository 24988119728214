<ng-container
  *ngIf="{
    pending: pending$ | async,
    inviteDetails: inviteDetails$ | async
  } as sync">
  <share-spinner *ngIf="sync.pending" [inner]="true"></share-spinner>
  <mat-card *ngIf="!sync.pending">
    <mat-card-header>
      <mat-card-title role="heading" aria-level="1"
        >{{ sync.inviteDetails ? 'Create an Account' : 'Create a Free Account' }}
      </mat-card-title>
      <mat-card-subtitle>Please use your company email address</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <auth-oauth-providers-list
        *ngIf="!marketplaceCallbackState"
        [btnPrefix]="'Sign up with'"
        [disabled]="!acceptPolicy"
        (selected)="onOauthLogin($event, sync.inviteDetails)" />

      <button
        type="button"
        class="email"
        mat-raised-button
        [disabled]="!acceptPolicy"
        [routerLink]="sync.inviteDetails ? '../register' : '/register'">
        <mat-icon>email</mat-icon>
        Sign up with email
      </button>

      <auth-accept-signup-policy (acceptChanged)="onAcceptPolicyChanged($event)" />

      <auth-link-signin></auth-link-signin>
    </mat-card-content>
  </mat-card>
</ng-container>

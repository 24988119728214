<mat-form-field class="ghost-blur search compact-field" role="search" [attr.aria-label]="options.placeholder">
  <input
    [attr.aria-label]="options.placeholder"
    #search
    matInput
    [blurOnEnter]="options.updateOnEnter"
    [formControl]="control"
    [placeholder]="options.placeholder" />

  <mat-error></mat-error>

  <mat-spinner *ngIf="options.pending; else tmplSearchButton" diameter="20" matSuffix></mat-spinner>

  <ng-template #tmplSearchButton>
    <button
      *ngIf="control.value"
      aria-label="Clear search field"
      mat-icon-button
      matSuffix
      matTooltip="Clear search field"
      (click)="control.setValue('')">
      <mat-icon>close</mat-icon>
    </button>
    <mat-icon *ngIf="!control.value" matSuffix>search</mat-icon>
  </ng-template>
</mat-form-field>

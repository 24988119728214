import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthProvider, IdPMetadata, SpMetadata } from '@neuralegion/api';
import { GitHubOrg } from '../models';
import {
  AuthProviderAPIState,
  AuthProviderState,
  GitHubAuthState,
  UIState
} from './auth-providers.state';

// eslint-disable-next-line @typescript-eslint/ban-types
type State = object;
const selectAuthProviderAPIState = createFeatureSelector<AuthProviderAPIState>('auth-providers');

export const selectAuthProviderState = createSelector<
  State,
  [AuthProviderAPIState],
  AuthProviderState
>(selectAuthProviderAPIState, (state: AuthProviderAPIState): AuthProviderState => state.provider);

export const selectAuthProvider = createSelector<State, [AuthProviderState], AuthProvider>(
  selectAuthProviderState,
  (state: AuthProviderState) => state.provider
);

export const selectGitHubAuthState = createSelector<State, [AuthProviderAPIState], GitHubAuthState>(
  selectAuthProviderAPIState,
  (state: AuthProviderAPIState): GitHubAuthState => state?.github
);

export const selectGitHubOrgs = createSelector<State, [GitHubAuthState], GitHubOrg[]>(
  selectGitHubAuthState,
  (state: GitHubAuthState): GitHubOrg[] => state?.orgs
);

export const selectAuthProviderUIState = createSelector<State, [AuthProviderAPIState], UIState>(
  selectAuthProviderAPIState,
  (state: AuthProviderAPIState): UIState => state?.ui
);

export const selectAuthProviderIdpMetadata = createSelector<
  State,
  [AuthProviderAPIState],
  IdPMetadata
>(
  selectAuthProviderAPIState,
  (state: AuthProviderAPIState): IdPMetadata => state.provider?.idpMetadata
);

export const selectAuthProviderSpMetadata = createSelector<
  State,
  [AuthProviderAPIState],
  SpMetadata
>(
  selectAuthProviderAPIState,
  (state: AuthProviderAPIState): SpMetadata => state.provider?.spMetadata
);

export const selectPending = createSelector<State, [UIState], boolean>(
  selectAuthProviderUIState,
  (state: UIState) => state.pending.length > 0
);

import { Severity } from './Severity';

export interface SeverityIssues {
  // eslint-disable-next-line id-blacklist
  readonly number: number;
  readonly type: Severity;
}

export function getTotalIssuesBySeverity(
  issues: SeverityIssues[] = [],
  severity: Severity
): number {
  return issues.find((issue: SeverityIssues) => issue.type === severity)?.number;
}

import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, exhaustMap, map, of, tap, withLatestFrom } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { AuthProvider, IdPMetadata, Scope, SpMetadata } from '@neuralegion/api';
import { selectScopePermission } from '@neuralegion/auth-api';
import { LocationStorageService } from '@neuralegion/browser-storage';
import { SnackbarService } from '@neuralegion/core';
import { GitHubOrg } from '../models';
import { AuthProvidersService, GithubClientService } from '../services';
import {
  disableAuthProvider,
  disableAuthProviderFail,
  disableAuthProviderSuccess,
  loadAuthProvider,
  loadAuthProviderFail,
  loadAuthProviderIdPMetadata,
  loadAuthProviderIdPMetadataFail,
  loadAuthProviderIdPMetadataSuccess,
  loadAuthProviderSpMetadata,
  loadAuthProviderSpMetadataFail,
  loadAuthProviderSpMetadataSuccess,
  loadAuthProviderSuccess,
  loadGitHubOrgs,
  loadGitHubOrgsFail,
  loadGitHubOrgsSuccess,
  sendReminders,
  sendRemindersFail,
  sendRemindersSuccess,
  startSetupAuthProvider,
  startSetupAuthProviderFail,
  startSetupAuthProviderSuccess,
  updateAuthProvider,
  updateAuthProviderFail,
  updateAuthProviderSuccess
} from './auth-providers.actions';

@Injectable()
export class AuthProvidersEffects {
  public readonly loadAuthProvider$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAuthProvider),
      withLatestFrom(this.store.select(selectScopePermission(Scope.AUTH_PROVIDERS))),
      exhaustMap(
        ([, authProvidersPermission]: [
          ReturnType<typeof loadAuthProvider>,
          boolean
        ]): Observable<Action> =>
          authProvidersPermission
            ? this.authProviders.load().pipe(
                map((item: AuthProvider) => loadAuthProviderSuccess(item)),
                catchError((err: HttpErrorResponse) => of(loadAuthProviderFail(err.error)))
              )
            : of(loadAuthProviderFail('Forbidden'))
      )
    )
  );

  public readonly loadGithubOrgs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadGitHubOrgs),
      exhaustMap(
        (): Observable<Action> =>
          this.githubClient.loadOrgs().pipe(
            map((items: GitHubOrg[]) => loadGitHubOrgsSuccess(items)),
            catchError((err: HttpErrorResponse) => of(loadGitHubOrgsFail(err.error)))
          )
      )
    )
  );

  public readonly loadIdPMetadata$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAuthProviderIdPMetadata),
      exhaustMap(
        (action: ReturnType<typeof loadAuthProviderIdPMetadata>): Observable<Action> =>
          this.authProviders.loadIdpMetadata(action.payload.file).pipe(
            map((item: IdPMetadata) => loadAuthProviderIdPMetadataSuccess(item)),
            catchError((err: HttpErrorResponse) => of(loadAuthProviderIdPMetadataFail(err.error)))
          )
      )
    )
  );

  public readonly loadSpMetadata$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAuthProviderSpMetadata),
      exhaustMap(
        (): Observable<Action> =>
          this.authProviders.loadSpMetadata().pipe(
            map((item: SpMetadata) => loadAuthProviderSpMetadataSuccess(item)),
            catchError((err: HttpErrorResponse) => of(loadAuthProviderSpMetadataFail(err.error)))
          )
      )
    )
  );

  public readonly loadIdPMetadataSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadAuthProviderIdPMetadataSuccess),
        tap(() => this.snackbarService.open('Settings have been applied'))
      ),
    { dispatch: false }
  );

  public readonly startSetupAuthProvider$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startSetupAuthProvider),
      exhaustMap(
        (action: ReturnType<typeof startSetupAuthProvider>): Observable<Action> =>
          this.authProviders.startSetup(action.payload).pipe(
            tap((authProvider: AuthProvider) =>
              this.locationStorage.store(
                !authProvider.installed
                  ? this.router.url
                  : new URL('..', this.document.location.href).pathname?.replace(/\/$/, '')
              )
            ),
            map((provider: AuthProvider) => startSetupAuthProviderSuccess(provider)),
            catchError((err: HttpErrorResponse) => of(startSetupAuthProviderFail(err.error)))
          )
      )
    )
  );

  public readonly editAuthProvider$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAuthProvider),
      exhaustMap(
        (action: ReturnType<typeof updateAuthProvider>): Observable<Action> =>
          this.authProviders.edit(action.payload).pipe(
            tap((authProvider: AuthProvider) =>
              this.locationStorage.store(
                !authProvider.installed
                  ? this.router.url
                  : new URL('..', this.document.location.href).pathname?.replace(/\/$/, '')
              )
            ),
            map((provider: AuthProvider) => updateAuthProviderSuccess(provider)),
            catchError((err: HttpErrorResponse) => of(updateAuthProviderFail(err.error)))
          )
      )
    )
  );

  public readonly sendReminders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendReminders),
      exhaustMap(
        (): Observable<Action> =>
          this.authProviders.sendReminders().pipe(
            map(() => sendRemindersSuccess()),
            catchError((err: HttpErrorResponse) => of(sendRemindersFail(err.error)))
          )
      )
    )
  );

  public readonly addMemberSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(sendRemindersSuccess),
        tap(() => this.snackbarService.open('Reminders have been sent'))
      ),
    { dispatch: false }
  );

  public readonly reloadAuthProvider$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAuthProviderSuccess),
      map(() => loadAuthProvider())
    )
  );

  public readonly disableAuthProvider$ = createEffect(() =>
    this.actions$.pipe(
      ofType(disableAuthProvider),
      exhaustMap(
        (action: ReturnType<typeof disableAuthProvider>): Observable<Action> =>
          this.authProviders.disable().pipe(
            map(() => disableAuthProviderSuccess(action.payload)),
            catchError((err: HttpErrorResponse) => of(disableAuthProviderFail(err.error)))
          )
      )
    )
  );

  public readonly reSetupProvider$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateAuthProviderSuccess),
        tap((action: ReturnType<typeof updateAuthProviderSuccess>): void => {
          if (!action.payload.installed) {
            this.document.location.href = action.payload.authUrl;
          }
        })
      ),
    { dispatch: false }
  );

  public readonly continueSetupProvider$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(startSetupAuthProviderSuccess),
        tap((action: ReturnType<typeof startSetupAuthProviderSuccess>): void => {
          this.document.location.href = action.payload.authUrl;
        })
      ),
    { dispatch: false }
  );

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly store: Store,
    private readonly authProviders: AuthProvidersService,
    private readonly githubClient: GithubClientService,
    private readonly locationStorage: LocationStorageService,
    private readonly snackbarService: SnackbarService
  ) {}
}

import { Action, createReducer, on } from '@ngrx/store';
import { cleanupPendingState } from '@neuralegion/core';
import {
  addPaymentMethod,
  addPaymentMethodFail,
  addPaymentMethodSuccess,
  changeDefaultPaymentMethod,
  changeDefaultPaymentMethodFail,
  changeDefaultPaymentMethodSuccess,
  loadPaymentMethods,
  loadPaymentMethodsFail,
  loadPaymentMethodsSuccess,
  removePaymentMethod,
  removePaymentMethodFail,
  removePaymentMethodSuccess,
  updatePaymentMethod,
  updatePaymentMethodFail,
  updatePaymentMethodSuccess
} from './payment-methods.actions';
import {
  PaymentMethodsState,
  initialPaymentMethodsState,
  paymentMethodsEntityAdapter
} from './payment-methods.state';

export const paymentMethodsReducer = createReducer<PaymentMethodsState, Action>(
  initialPaymentMethodsState,
  on(
    addPaymentMethod,
    changeDefaultPaymentMethod,
    removePaymentMethod,
    loadPaymentMethods,
    updatePaymentMethod,
    (state: PaymentMethodsState, action): PaymentMethodsState => ({
      ...state,
      pending: [...state.pending, action],
      error: null
    })
  ),
  on(
    loadPaymentMethodsSuccess,
    (state: PaymentMethodsState, action): PaymentMethodsState =>
      paymentMethodsEntityAdapter.setAll(
        action.payload.paymentMethods,
        cleanupPendingState(state, action)
      )
  ),
  on(
    removePaymentMethodSuccess,
    (state: PaymentMethodsState, action): PaymentMethodsState =>
      paymentMethodsEntityAdapter.removeOne(
        action.payload.paymentMethodId,
        cleanupPendingState(state, action)
      )
  ),
  on(
    addPaymentMethodSuccess,
    updatePaymentMethodSuccess,
    (state: PaymentMethodsState, action): PaymentMethodsState =>
      paymentMethodsEntityAdapter.setOne(
        action.payload.paymentMethod,
        cleanupPendingState(state, action)
      )
  ),
  on(
    changeDefaultPaymentMethodSuccess,
    (state: PaymentMethodsState, action): PaymentMethodsState => {
      const updatedState = paymentMethodsEntityAdapter.map(
        (paymentMethod) => ({ ...paymentMethod, defaultMethod: false }),
        state
      );

      return cleanupPendingState(
        paymentMethodsEntityAdapter.updateOne(
          { id: action.payload.paymentMethodId, changes: { defaultMethod: true } },
          updatedState
        ),
        action
      );
    }
  ),
  on(
    addPaymentMethodFail,
    changeDefaultPaymentMethodFail,
    removePaymentMethodFail,
    loadPaymentMethodsFail,
    updatePaymentMethodFail,
    (state: PaymentMethodsState, action): PaymentMethodsState =>
      cleanupPendingState(state, action, action.payload)
  )
);

import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

export interface StrongRulesList {
  type: string;
  validator: ValidatorFn;
  text: string;
}

function preparePassword(password: string): string | null {
  return typeof password === 'string' && password ? password.trim() : null;
}

function getPasswordFromControl(control: AbstractControl): string | null {
  return preparePassword(control.value);
}

function getPasswordFromGroup(
  control: AbstractControl,
  formKey: string = 'password'
): string | null {
  return preparePassword(control.get(formKey).value);
}

export class PasswordValidators {
  public static strongRulesList: StrongRulesList[] = [
    {
      type: 'minlength',
      validator: Validators.minLength(7),
      text: 'Minimum 7 characters'
    },
    {
      type: 'requireCapital',
      validator: PasswordValidators.requireCapital,
      text: 'A capital (uppercase) letter'
    },
    {
      type: 'requireLower',
      validator: PasswordValidators.requireLower,
      text: 'A lowercase letter'
    },
    {
      type: 'requireNumber',
      validator: PasswordValidators.requireNumber,
      text: 'A number'
    },
    {
      type: 'requireSpecial',
      validator: PasswordValidators.requireSpecial,
      text: 'A special'
    }
  ];

  private static readonly strong = Validators.compose(
    PasswordValidators.strongRulesList.map(({ validator }) => validator)
  );

  public static matchPassword(control: AbstractControl): ValidationErrors | null {
    const repeatPasswordFormKey = 'repeatPassword';
    const password: string = getPasswordFromGroup(control);
    const confirmPassword: string = getPasswordFromGroup(control, repeatPasswordFormKey);
    const confirmPasswordControl = control.get(repeatPasswordFormKey);

    if (password && confirmPassword && password !== confirmPassword) {
      const errors = { requireMatch: true };
      confirmPasswordControl.setErrors(errors);
      return errors;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { requireMatch, ...errors } = confirmPasswordControl.errors ?? {};

    confirmPasswordControl.setErrors(errors);

    return null;
  }

  public static requireStrong(control: AbstractControl): ValidationErrors | null {
    const res = PasswordValidators.strong(control);
    return res ? { ...res, requireStrong: true } : null;
  }

  private static requireCapital(control: AbstractControl): ValidationErrors | null {
    return !getPasswordFromControl(control)?.match(/[A-Z]/g) ? { requireCapital: true } : null;
  }

  private static requireLower(control: AbstractControl): ValidationErrors | null {
    return !getPasswordFromControl(control)?.match(/[a-z]/g) ? { requireLower: true } : null;
  }

  private static requireNumber(control: AbstractControl): ValidationErrors | null {
    return !getPasswordFromControl(control)?.match(/\d/g) ? { requireNumber: true } : null;
  }

  private static requireSpecial(control: AbstractControl): ValidationErrors | null {
    return !getPasswordFromControl(control)?.match(/\W+/g) ? { requireSpecial: true } : null;
  }
}

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <mat-form-field>
    <mat-label>Authentication code</mat-label>
    <input type="tel" autocomplete="off" matInput required shareAutofocus [formControlName]="'code'" />
    <mat-icon matSuffix>smartphone</mat-icon>
    <mat-error [matErrorOptions]="{ fieldName: 'Authentication code' }"></mat-error>
  </mat-form-field>

  <div class="hint">
    Open the two-factor authentication app on your device to view your authentication code and verify your identity.
  </div>

  <button color="primary" mat-raised-button [disabled]="!form.valid">Verify</button>
</form>

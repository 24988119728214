import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Breadcrumb, BreadcrumbService } from '@neuralegion/core';

@Component({
  selector: 'share-breadcrumb',
  styleUrls: ['./breadcrumb.component.scss'],
  templateUrl: './breadcrumb.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbComponent {
  constructor(public readonly breadcrumbService: BreadcrumbService) {}

  public trackByUrl(_idx: number, breadcrumb: Breadcrumb): string {
    return breadcrumb.url;
  }
}

import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { Subject, filter, takeUntil } from 'rxjs';
import { Filter, FilterConfig, TextFilterInputType, TextFilterSettings } from '@neuralegion/api';
import { extractTouchedChanges } from '../../../form';
import { FilterControl } from '../../../models';

// eslint-disable-next-line @angular-eslint/use-component-selector
@Component({
  templateUrl: './table-text-filter-control.component.html',
  styleUrls: ['./table-text-filter-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: TableTextFilterControlComponent, multi: true },
    { provide: NG_VALIDATORS, useExisting: TableTextFilterControlComponent, multi: true }
  ]
})
export class TableTextFilterControlComponent implements FilterControl, OnInit, OnDestroy {
  @Input()
  public filterConfig: FilterConfig;

  @Input()
  public filters: Filter[];

  get inputType(): TextFilterInputType {
    return (this.filterConfig.settings as TextFilterSettings).inputType || 'text';
  }

  public readonly formControl = new FormControl<string | number>(null, [Validators.required]);

  private onChange: (value: string | number) => void;
  private onTouched: () => void;

  private readonly valueSubject = new Subject<string | number>();
  private readonly gc = new Subject<void>();

  public ngOnInit() {
    this.formControl.addValidators([
      ...(this.filterConfig.settings?.validators ?? []),
      ...((this.filterConfig.settings as TextFilterSettings).unique
        ? [
            this.uniqueValidator([
              ...this.filters
                .filter((item: Filter) => item.name === this.filterConfig.name)
                .map((item: Filter) => item.value as string | number)
            ])
          ]
        : [])
    ]);

    this.initTouchedListener();
    this.initValueSubjectListener();
    this.initFormControlListener();
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.complete();
  }

  public registerOnChange(fn: (value: string | number) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  public validate(): ValidationErrors | null {
    return this.formControl.valid ? null : { textFilterControl: true };
  }

  public writeValue(value: string | number): void {
    this.valueSubject.next(value);
  }

  private initTouchedListener(): void {
    extractTouchedChanges(this.formControl)
      .pipe(
        filter((touched) => touched),
        takeUntil(this.gc)
      )
      .subscribe(() => this.onTouched?.());
  }

  private initValueSubjectListener(): void {
    this.valueSubject
      .pipe(
        filter((value: string | number) => this.formControl.value !== value),
        takeUntil(this.gc)
      )
      .subscribe((value: string | number) => this.formControl.setValue(value));
  }

  private initFormControlListener(): void {
    this.formControl.valueChanges
      .pipe(takeUntil(this.gc))
      .subscribe((value: string | number) => this.onChange?.(value));
  }

  private uniqueValidator(values: (string | number)[]): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      return values.some((value: string | number) => value === c.value)
        ? {
            duplicate: true
          }
        : null;
    };
  }
}

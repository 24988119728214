import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from, last, map, mergeMap, scan } from 'rxjs';
import { OffsetPaginationConfig, PaginationResponse } from '@neuralegion/api';
import { Project } from '../models';

type ProjectsLookupParams = Partial<
  OffsetPaginationConfig & {
    id: string[];
    predefined: boolean;
    q: string;
  }
>;

@Injectable()
export class ProjectsLookupService {
  private readonly MAX_PAGINATION_LIMIT = 100;
  private readonly MAX_PARALLEL = 5;

  constructor(protected readonly http: HttpClient) {}

  public loadProjectsByIds(ids: string[], loadParallel = false): Observable<Project[]> {
    const requestParams: ProjectsLookupParams[] = [];
    const idsAux = [...ids];

    while (idsAux.length > 0) {
      requestParams.push({
        id: idsAux.splice(0, this.MAX_PAGINATION_LIMIT),
        limit: this.MAX_PAGINATION_LIMIT
      });
    }

    return from(requestParams).pipe(
      mergeMap((param) => this.loadProjectsAux(param), loadParallel ? this.MAX_PARALLEL : 1),
      scan((acc: Project[], { items }: PaginationResponse<Project>) => [...acc, ...items], []),
      last()
    );
  }

  public loadPredefinedProject(): Observable<Project> {
    return this.loadProjectsAux({ predefined: true }).pipe(
      map(({ items }: PaginationResponse<Project>) => {
        return items[0];
      })
    );
  }

  public loadProjectsByQuery(query: string, limit = 50): Observable<PaginationResponse<Project>> {
    return this.loadProjectsAux({
      limit,
      ...(query.length ? { q: query } : {})
    });
  }

  private loadProjectsAux(params: ProjectsLookupParams): Observable<PaginationResponse<Project>> {
    const { id: ids, ...rest } = params;
    const httpParams = new HttpParams({
      fromObject: { ...rest, ...(ids?.length ? { ['id[]']: ids } : {}) }
    });

    return this.http
      .get<PaginationResponse<Project>>('/api/v2/projects', {
        params: httpParams
      })
      .pipe(
        map((res: PaginationResponse<Project>) => ({
          total: res.total,
          items: res.items.map(({ id, name, groupIds, predefined }) => ({
            id,
            name,
            groupIds,
            predefined
          }))
        }))
      );
  }
}

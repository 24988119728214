import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Subject, asyncScheduler, filter, map, takeUntil, throttleTime } from 'rxjs';
import { CustomValidators } from '../../form';

enum BlacklistKeysEnum {
  UP = 'ArrowUp',
  DOWN = 'ArrowDown'
}

@Component({
  selector: 'share-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    { provide: NG_VALUE_ACCESSOR, useExisting: InputNumberComponent, multi: true }
  ]
})
export class InputNumberComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input()
  public max: number;

  @Input()
  public min: number;

  private readonly blacklistKeys: string[] = [BlacklistKeysEnum.UP, BlacklistKeysEnum.DOWN];

  private readonly WINDOW_SIZE = 100;

  private lastValidValue = 0;

  public formControl: FormControl;

  public readonly disabledSubject = new Subject<boolean>();

  private readonly gc = new Subject<void>();
  private readonly writeValueSubject = new Subject<number>();

  private onChange: (value: number | string) => void;

  public ngOnInit(): void {
    this.formControl = new FormControl('', [
      CustomValidators.integer,
      Validators.max(this.max),
      Validators.min(this.min)
    ]);

    this.formControl.valueChanges
      .pipe(
        throttleTime(this.WINDOW_SIZE, asyncScheduler, { leading: false, trailing: true }),
        filter((val: string | number) => this.isValidValue(+val)),
        map((val) => +val),
        takeUntil(this.gc)
      )
      .subscribe((val: number) => {
        this.lastValidValue = val;
        this.onChange(val);
      });

    this.writeValueSubject
      .pipe(
        filter((val: number) => !isNaN(+val)),
        takeUntil(this.gc)
      )
      .subscribe((val: number) => {
        this.lastValidValue = val;
        this.formControl.patchValue(val, { emitEvent: false });
      });
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }

  public registerOnChange(fn: (value: number | string) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(_fn: () => void): void {
    // do nothing
  }

  public setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
    this.disabledSubject.next(disabled);
  }

  public writeValue(value: string | number): void {
    this.writeValueSubject.next(+value);
  }

  public onKeyPress(e: KeyboardEvent): void {
    if (!(Number.isFinite(this.min) && this.min >= 0 ? /\d/ : /\d|-/).test(e.key)) {
      e.preventDefault();
    }
  }

  public onKeyDown(e: KeyboardEvent): void {
    if (this.blacklistKeys.includes(e.key)) {
      e.stopPropagation();
      e.preventDefault();
    }

    switch (e.key) {
      case BlacklistKeysEnum.UP:
        this.inc();
        break;
      case BlacklistKeysEnum.DOWN:
        this.dec();
        break;
      default:
        break;
    }
  }

  public onBlur(): void {
    if (!this.isValidValue(this.formControl.value)) {
      this.formControl.patchValue(this.lastValidValue, { emitEvent: false });
    }
  }

  public inc(): void {
    const value = this.isValidMin(+this.formControl.value + 1)
      ? +this.formControl.value + 1
      : this.min;
    if (this.isValidMax(value)) {
      this.formControl.patchValue(value);
    }
  }

  public dec(): void {
    const value = +this.formControl.value - 1;
    if (this.isValidMin(value)) {
      this.formControl.patchValue(value);
    }
  }

  private isValidValue(val: number): boolean {
    return Number.isFinite(val) && this.isValidMin(val) && this.isValidMax(val);
  }

  private isValidMin(val: number): boolean {
    return !Number.isFinite(this.min) || val >= this.min;
  }

  private isValidMax(val: number): boolean {
    return !Number.isFinite(this.max) || val <= this.max;
  }
}

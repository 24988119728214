import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, exhaustMap, from, map, of, tap, withLatestFrom } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { SnackbarService } from '@neuralegion/core';
import { Repeater, RepeaterNetworkResult } from '../models';
import { RepeaterNetworkService } from '../services/reapeater-network.service';
import {
  loadRepeaterTest,
  loadRepeaterTestFail,
  loadRepeaterTestSuccess,
  pollRepeaterTest,
  runRepeaterTest,
  runRepeaterTestFail,
  runRepeaterTestSuccess,
  updateRepeaterTest,
  updateRepeaterTestFail,
  updateRepeaterTestSuccess
} from './repeater-network.actions';
import { selectAllRepeaters } from './repeaters.selectors';

@Injectable()
export class RepeaterNetworkEffects {
  public readonly loadRepeaterTest$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRepeaterTest, pollRepeaterTest),
      exhaustMap(
        (action: ReturnType<typeof loadRepeaterTest> | ReturnType<typeof pollRepeaterTest>) =>
          this.repeaterNetworkService.loadTest(action.payload.repeaterId).pipe(
            map((res: RepeaterNetworkResult) => loadRepeaterTestSuccess(res)),
            catchError((err: HttpErrorResponse) => of(loadRepeaterTestFail(err.error)))
          )
      )
    )
  );

  public readonly updateRepeaterTest$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(updateRepeaterTest),
      exhaustMap((action: ReturnType<typeof updateRepeaterTest>) =>
        this.repeaterNetworkService.updateTest(action.payload.repeaterId, action.payload.test).pipe(
          map((res: RepeaterNetworkResult) => updateRepeaterTestSuccess(res)),
          catchError((err: HttpErrorResponse) => of(updateRepeaterTestFail(err.error)))
        )
      )
    )
  );

  public readonly runRepeaterTest$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(runRepeaterTest),
      exhaustMap((action: ReturnType<typeof runRepeaterTest>) =>
        this.repeaterNetworkService.runTest(action.payload.repeaterId, action.payload.test).pipe(
          map((res: RepeaterNetworkResult) => runRepeaterTestSuccess(res)),
          catchError((err: HttpErrorResponse) =>
            from([
              runRepeaterTestFail(err.error),
              loadRepeaterTest({ repeaterId: action.payload.repeaterId })
            ])
          )
        )
      )
    )
  );

  public readonly showSnackbarOnSuccessfulUpdate$: Observable<Repeater> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateRepeaterTestSuccess),
        withLatestFrom(this.store.select(selectAllRepeaters)),
        map(([action, repeaters]: [ReturnType<typeof updateRepeaterTestSuccess>, Repeater[]]) =>
          repeaters.find((repeater: Repeater) => repeater.id === action.payload.repeaterId)
        ),
        tap((repeater: Repeater) =>
          this.snackbarService.open(
            `Repeater remote network diagnostics for ${repeater.name} was successfully saved`
          )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly repeaterNetworkService: RepeaterNetworkService,
    private readonly snackbarService: SnackbarService
  ) {}
}

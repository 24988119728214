import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  AsyncValidatorFn,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { ListControlErrors } from '../../models';
import { ListItemControl } from '../list-item-control/list-item-control.component';

export interface SingleInputListItemControlSettings {
  readonly label?: string;
  readonly hint?: string;
  readonly hintPosition?: 'top' | 'bottom';
  readonly validators?: ValidatorFn[];
  readonly asyncValidators?: AsyncValidatorFn[];
  readonly uniqueError?: string;
  readonly maxItemsError?: string;
}

@Component({
  selector: 'share-single-input-list-item-control',
  templateUrl: './single-input-list-item-control.component.html',
  styleUrls: ['./single-input-list-item-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SingleInputListItemControlComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: SingleInputListItemControlComponent,
      multi: true
    },
    {
      provide: ListItemControl,
      useExisting: SingleInputListItemControlComponent
    }
  ]
})
export class SingleInputListItemControlComponent extends ListItemControl<string> implements OnInit {
  @Input()
  public settings: Partial<SingleInputListItemControlSettings> = {};

  @Input()
  public tmplErrors: TemplateRef<unknown>;

  @ViewChild('inputField', { read: ElementRef })
  public inputField: ElementRef<HTMLInputElement>;

  public readonly innerControl = new FormControl('', Validators.required);

  private readonly defaultSettings: SingleInputListItemControlSettings = {
    label: '',
    hint: '',
    hintPosition: 'bottom',
    validators: [],
    maxItemsError: 'Maximum items limit exceeded',
    uniqueError: 'Each item must be unique'
  };

  public hasRequiredValidator: boolean;

  get prioritizedErrorText(): string {
    if (this.innerControl.hasError(ListControlErrors.LIST_LENGTH_EXCEEDED)) {
      return this.settings.maxItemsError;
    }
    if (this.innerControl.hasError(ListControlErrors.DUPLICATED_LIST_ITEM)) {
      return this.settings.uniqueError;
    }

    return '';
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.settings = {
      ...this.defaultSettings,
      ...this.settings
    };

    this.hasRequiredValidator = this.settings.validators.includes(Validators.required);

    this.setupValidators();
  }

  protected override focusOnElement(): void {
    setTimeout(() => this.inputField.nativeElement.focus());
  }

  private setupValidators(): void {
    if (this.settings.validators?.length) {
      this.innerControl.setValidators(this.settings.validators);
    }
    if (this.settings.asyncValidators?.length) {
      this.innerControl.setAsyncValidators(this.settings.asyncValidators);
    }
  }

  protected onPaste(event: ClipboardEvent): void {
    const pastedText = event.clipboardData.getData('text');

    if (pastedText) {
      const pastedData = pastedText.split(/[\r]?\n/);
      if (pastedData.length > 1) {
        event.preventDefault();
        this.listControlComponent.onPaste(pastedData, this.idx);
      }
    }
  }
}

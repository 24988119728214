import { Directive, ElementRef, EventEmitter, NgZone, OnDestroy, Output } from '@angular/core';
import { Subject, filter, fromEvent, merge, takeUntil } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';

enum MouseButton {
  LEFT = 0,
  MIDDLE = 1,
  RIGHT = 2
}

@Directive({
  selector: '[shareLinkNavigate]'
})
export class LinkNavigateDirective implements OnDestroy {
  @Output('shareLinkNavigate')
  public navigation = new EventEmitter<void>();

  private readonly gc = new Subject<void>();

  constructor(
    private readonly zone: NgZone,
    private readonly ref: ElementRef<HTMLElement>,
    private readonly deviceDetectorService: DeviceDetectorService
  ) {
    this.zone.runOutsideAngular(() => {
      this.initEventsListener();
    });
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }

  private initEventsListener(): void {
    const { nativeElement: rootEl } = this.ref;

    merge(
      fromEvent<MouseEvent>(rootEl, 'mousedown').pipe(
        filter((event) => !this.isContextMenuShowClick(event))
      ),
      fromEvent<KeyboardEvent>(rootEl, 'keydown').pipe(filter(({ code }) => code === 'Enter'))
    )
      .pipe(takeUntil(this.gc))
      .subscribe(() => this.dispatchEvent());
  }

  private isContextMenuShowClick(event: MouseEvent) {
    const button = event.button as MouseButton;
    if (
      button === MouseButton.RIGHT ||
      (button === MouseButton.LEFT &&
        event.ctrlKey &&
        this.deviceDetectorService.os?.includes('Mac'))
    ) {
      return true;
    }
    return false;
  }

  private dispatchEvent(): void {
    this.zone.run(() => {
      this.navigation.emit();
    });
  }
}

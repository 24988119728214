import { AfterViewInit, Directive, OnDestroy, Renderer2 } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { Subject, takeUntil } from 'rxjs';

@Directive({
  selector: '[shareFilterableSelectRoleDialog]'
})
export class FilterableSelectRoleDialogDirective implements AfterViewInit, OnDestroy {
  private readonly gc = new Subject<void>();

  constructor(
    private readonly renderer: Renderer2,
    private readonly matSelect: MatSelect
  ) {}

  public ngAfterViewInit(): void {
    this.matSelect.openedChange.pipe(takeUntil(this.gc)).subscribe((opened) => {
      if (opened) {
        this.renderer.setAttribute(this.matSelect.panel.nativeElement, 'role', 'dialog');
        this.renderer.removeAttribute(this.matSelect.panel.nativeElement, 'aria-multiselectable');
      }
    });
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }
}

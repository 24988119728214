<mat-form-field>
  <mat-label>
    <span>{{ label }}</span>
    <share-info-tooltip *ngIf="labelTooltip">{{ labelTooltip }}</share-info-tooltip>
  </mat-label>
  <input
    [attr.autocomplete]="autocomplete"
    [attr.type]="type"
    matInput
    [formControl]="formControl"
    [required]="required"
    (input)="handleInput()" />
  <button
    type="button"
    class="mat-icon-button cdk-focused cdk-mouse-focused"
    attr.aria-label="{{ hidden ? 'Show' : 'Hide' }} {{ label }}"
    attr.aria-pressed="{{ hidden }}"
    mat-icon-button
    matRipple
    matSuffix
    matTooltip="{{ hidden ? 'Show' : 'Hide' }} {{ label }}"
    [matRippleCentered]="true"
    (click)="toggle($event)">
    <mat-icon>{{ hidden ? 'visibility' : 'visibility_off' }}</mat-icon>
  </button>
  <mat-error [matErrorOptions]="{ fieldName: label }"></mat-error>
</mat-form-field>

import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { RepeaterNetworkService, RepeatersService } from '../services';
import { RepeaterNetworkEffects } from '../store/repeater-network.effects';
import { repeaterNetworkReducer } from '../store/repeater-network.reducer';
import { RepeatersAnalyticsEffects } from '../store/repeaters-analytics.effects';
import { RepeatersEffects } from '../store/repeaters.effects';
import { repeatersReducer } from '../store/repeaters.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature('repeaters', repeatersReducer),
    StoreModule.forFeature('repeaterNetwork', repeaterNetworkReducer),
    EffectsModule.forFeature([RepeatersAnalyticsEffects, RepeatersEffects, RepeaterNetworkEffects])
  ],
  declarations: [],
  exports: [],
  providers: [RepeatersService, RepeaterNetworkService]
})
export class RepeatersApiModule {}

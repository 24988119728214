import { ChangeDetectionStrategy, Component, HostListener, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ID } from '@neuralegion/api';

@Component({
  selector: 'share-copyable-id',
  templateUrl: './copyable-id.component.html',
  styleUrls: ['./copyable-id.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CopyableIdComponent<T extends ID> {
  @Input()
  public item: T;

  @Input()
  public itemTitle = 'ID';

  @Input()
  public color?: ThemePalette = 'primary';

  @HostListener('click', ['$event'])
  public onClick(e: Event): void {
    e.stopPropagation();
  }
}

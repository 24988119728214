<form fxLayout="row" fxLayoutGap="16px" [formGroup]="form">
  <ng-container *ngIf="{ availableItems: availableItemsSubject | async } as sync">
    <mat-form-field *ngIf="sync.availableItems.length > 1" class="compact-field item-field" fxFlex="1 1 auto">
      <mat-select [formControlName]="'item'">
        <mat-option
          *ngFor="let availableItem of sync.availableItems; trackBy: trackByIdentity"
          [disabled]="availableItem.filterStatus !== FilterStatus.AVAILABLE"
          [value]="availableItem.item">
          {{ availableItem.item.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <share-number-comparator-control
    *ngIf="form.value.item"
    fxFlex="1 1 auto"
    [formControlName]="'comparator'"
    [numberValidators]="numberValidators"
    [operators]="availableOperators">
  </share-number-comparator-control>
</form>

import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import equal from 'fast-deep-equal/es6';
import {
  FilterValueObject,
  PaginationDirection,
  PaginationResponse,
  TableSortConfig
} from '@neuralegion/api';
import { PrevNextProps, SeekPaginationState, SeekPartialPaginationState } from '../../models';
import { PaginationStateSerializationService } from './pagination-state-serialization.service';
import { PaginationStore } from './pagination-store.service';

@Injectable()
export class SeekPaginationStore<
  T extends { id: string; createdAt?: string }
> extends PaginationStore<T, SeekPaginationState, SeekPartialPaginationState> {
  protected readonly resetUpdaterFn = (state: SeekPaginationState): SeekPartialPaginationState => ({
    activeAction: {
      payload: {
        pagination: {
          limit: state.pagination.pageSize,
          direction: PaginationDirection.NEXT
        },
        filter: null,
        sort: null
      }
    },
    pagination: {
      pageIndex: 0,
      length: 0
    },
    filters: null,
    sort: null
  });
  protected readonly resetSuccessCallbackFn = (
    _state: SeekPaginationState,
    payload: PaginationResponse<T>
  ): SeekPartialPaginationState => ({
    activeAction: null,
    pagination: {
      length: payload.total
    },
    prevNextProps: { ...this.getPrevNextProps(payload.items) }
  });

  protected readonly updatePageSizeUpdaterFn = (
    state: SeekPaginationState,
    pageSize: number
  ): SeekPartialPaginationState => ({
    activeAction: {
      payload: {
        pagination: {
          limit: pageSize,
          direction: PaginationDirection.NEXT
        },
        filter: state.filters,
        sort: state.sort
      }
    }
  });
  protected readonly updatePageSizeSuccessCallbackFn = (
    state: SeekPaginationState,
    payload: PaginationResponse<T>
  ): SeekPartialPaginationState => ({
    activeAction: null,
    pagination: {
      length: payload.total,
      pageSize: state.activeAction.payload.pagination.limit,
      pageIndex: 0
    },
    prevNextProps: { ...this.getPrevNextProps(payload.items) }
  });

  protected readonly updateFilterUpdaterFn = (
    state: SeekPaginationState,
    filters: FilterValueObject<unknown>
  ): SeekPartialPaginationState => ({
    activeAction: {
      payload: {
        pagination: {
          limit: state.pagination?.pageSize,
          direction: PaginationDirection.NEXT
        },
        filter: filters,
        sort: state.sort
      }
    },
    filters
  });
  protected readonly updateFilterSuccessCallbackFn = (
    _state: SeekPaginationState,
    payload: PaginationResponse<T>
  ): SeekPartialPaginationState => ({
    activeAction: null,
    pagination: {
      pageIndex: 0,
      length: payload.total
    },
    prevNextProps: { ...this.getPrevNextProps(payload.items) }
  });

  protected readonly updateSortUpdaterFn = (
    state: SeekPaginationState,
    sort: TableSortConfig
  ): SeekPartialPaginationState => ({
    activeAction: {
      payload: {
        sort,
        pagination: {
          limit: state.pagination?.pageSize,
          direction: PaginationDirection.NEXT
        },
        filter: state.filters
      }
    },
    sort
  });
  protected readonly updateSortSuccessCallbackFn = (
    _state: SeekPaginationState,
    payload: PaginationResponse<T>
  ): SeekPartialPaginationState => ({
    activeAction: null,
    pagination: {
      pageIndex: 0,
      length: payload.total
    },
    prevNextProps: { ...this.getPrevNextProps(payload.items) }
  });

  protected readonly nextPageUpdaterFn = (
    state: SeekPaginationState
  ): SeekPartialPaginationState => ({
    activeAction: {
      payload: {
        pagination: {
          limit: state.pagination.pageSize,
          direction: PaginationDirection.NEXT,
          entityId: state.prevNextProps.nextId,
          entityCreatedAt: state.prevNextProps.nextCreatedAt
        },
        filter: state.filters,
        sort: state.sort
      }
    }
  });
  protected readonly nextPageSuccessCallbackFn = (
    state: SeekPaginationState,
    payload: PaginationResponse<T>
  ): SeekPartialPaginationState => {
    let pageIndex = 0;
    if (payload.items.length) {
      pageIndex = state.pagination.pageIndex + 1;
    } else if (payload.total !== 0) {
      pageIndex = Math.floor(payload.total / state.pagination.pageSize);
    }

    return {
      activeAction: null,
      pagination: {
        pageIndex,
        length: payload.total
      },
      prevNextProps: { ...this.getPrevNextProps(payload.items) }
    };
  };

  protected readonly prevPageUpdaterFn = (
    state: SeekPaginationState
  ): SeekPartialPaginationState => ({
    activeAction: {
      payload:
        !state.prevNextProps.prevId || !state.prevNextProps.prevCreatedAt
          ? {
              pagination: {
                limit: state.pagination.pageSize,
                direction: PaginationDirection.NEXT
              },
              filter: null,
              sort: null
            }
          : {
              pagination: {
                entityId: state.prevNextProps.prevId,
                entityCreatedAt: state.prevNextProps.prevCreatedAt,
                limit: state.pagination.pageSize,
                direction: PaginationDirection.PREV
              },
              filter: state.filters,
              sort: state.sort
            }
    }
  });
  protected readonly prevPageSuccessCallbackFn = (
    state: SeekPaginationState,
    payload: PaginationResponse<T>
  ): SeekPartialPaginationState => ({
    activeAction: null,
    pagination: {
      pageIndex: payload.items.length ? state.pagination.pageIndex - 1 : 0,
      length: payload.total
    },
    prevNextProps: { ...this.getPrevNextProps(payload.items) }
  });

  protected readonly firstPageUpdaterFn = (
    state: SeekPaginationState
  ): SeekPartialPaginationState => ({
    activeAction: {
      payload: {
        pagination: {
          limit: state.pagination.pageSize,
          direction: PaginationDirection.NEXT
        },
        filter: state.filters,
        sort: state.sort
      }
    }
  });
  protected readonly firstPageSuccessCallbackFn = (
    _state: SeekPaginationState,
    payload: PaginationResponse<T>
  ): SeekPartialPaginationState => ({
    activeAction: null,
    pagination: {
      pageIndex: 0,
      length: payload.total
    },
    prevNextProps: { ...this.getPrevNextProps(payload.items) }
  });

  protected readonly reloadPageUpdaterFn = (
    state: SeekPaginationState
  ): SeekPartialPaginationState => this.getReloadPageUpdateState(state);
  protected readonly reloadPageSuccessCallbackFn = (
    state: SeekPaginationState,
    payload: PaginationResponse<T>
  ): SeekPartialPaginationState => ({
    activeAction: null,
    pagination: {
      pageSize: state.pagination.pageSize,
      length: payload.total
    },
    prevNextProps: { ...this.getPrevNextProps(payload.items) }
  });

  constructor(
    actions$: Actions,
    store: Store,
    paginationStateSerializationService: PaginationStateSerializationService<SeekPaginationState>
  ) {
    super(actions$, store, paginationStateSerializationService, {
      pagination: null,
      filters: null,
      sort: null,
      activeAction: null,
      prevNextProps: null,
      refreshParams: null
    });

    this.initActions();
  }

  private getPrevNextProps(data: T[]): PrevNextProps {
    return {
      nextId: data[data.length - 1]?.id,
      nextCreatedAt: data[data.length - 1]?.createdAt,
      prevId: data[0]?.id,
      prevCreatedAt: data[0]?.createdAt
    };
  }

  private getReloadPageUpdateState(state: SeekPaginationState): SeekPartialPaginationState {
    const refreshParamsFilterEqualsStateFilter = equal(state.refreshParams?.filter, state.filters);

    return {
      activeAction: {
        payload: refreshParamsFilterEqualsStateFilter
          ? state.refreshParams ?? {
              pagination: {
                limit: state.pagination.pageSize,
                direction: PaginationDirection.NEXT
              },
              filter: null,
              sort: null
            }
          : {
              pagination: {
                limit: state.pagination.pageSize,
                direction: PaginationDirection.NEXT
              },
              filter: state.filters,
              sort: null
            }
      },
      ...(!refreshParamsFilterEqualsStateFilter ? { pagination: { pageIndex: 0 } } : {})
    };
  }
}

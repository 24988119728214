<ng-container *ngIf="{ prettifyResult: prettifyResult$ | async } as sync">
  <div
    class="legend"
    fxHide.lt-lg
    fxLayout="column"
    fxLayoutAlign="start start"
    fxLayoutGap="5px"
    [fxShow]="sync.prettifyResult?.binary && sync.prettifyResult?.diffEnabled">
    <div>Block types</div>
    <div class="legend-item legend-added" fxLayout="row" fxLayoutAlign="start center">
      <span class="diff-added legend-box"></span>
      <span>Added</span>
    </div>
    <div class="legend-item legend-changed" fxLayout="row" fxLayoutAlign="start center">
      <span class="diff-changed legend-box"></span>
      <span>Changed</span>
    </div>
    <div class="legend-item legend-removed" fxLayout="row" fxLayoutAlign="start center">
      <span class="diff-removed legend-box" fxLayout="row" fxLayoutAlign="center center">I</span>
      <span>Removed</span>
    </div>
  </div>

  <cdk-virtual-scroll-viewport class="focus-ring" tabindex="0" fxFlex="100%" fxLayout="column" [itemSize]="20">
    <div *cdkVirtualFor="let hexLine of sync.prettifyResult?.hexLines; trackBy: trackByOffsetLabel" class="hex-line">
      <div class="hex-labels">{{ hexLine[HexFormatPart.LABEL] }}</div>
      <div class="hex-codes" [innerHTML]="hexLine[HexFormatPart.CODES]"></div>
      <div class="hex-content" [innerHTML]="hexLine[HexFormatPart.CONTENT]"></div>
      <div class="hex-extra">
        <div class="prev-aux">
          <details
            *ngFor="let extraBlock of hexLine.extraBlocks; trackBy: trackByOffset"
            class="prev-{{ extraBlock.type === DiffBlockType.CHANGED ? 'changed' : 'removed' }}"
            #details
            (click)="onDetailsClick($event, extraBlock.offset)"
            (keydown)="onDetailsClick($event, extraBlock.offset)">
            <summary>
              {{ extraBlock.length }} bytes {{ extraBlock.type === DiffBlockType.CHANGED ? 'changed' : 'removed' }}
            </summary>
            <ng-container *ngIf="openedExtraBlock === extraBlock.offset">
              <ng-container *ngTemplateOutlet="tmplDetails; context: { $implicit: format(extraBlock.value) }">
              </ng-container>
            </ng-container>
          </details>
        </div>
      </div>
    </div>
  </cdk-virtual-scroll-viewport>

  <ng-template #tmplDetails let-lines>
    <cdk-virtual-scroll-viewport fxFlex="100%" fxLayout="column" [itemSize]="20">
      <div *cdkVirtualFor="let hexLine of lines; trackBy: trackByOffsetLabel" class="hex-line">
        <div class="hex-labels">{{ hexLine[HexFormatPart.LABEL] }}</div>
        <div class="hex-codes" [innerHTML]="hexLine[HexFormatPart.CODES]"></div>
        <div class="hex-content" [innerHTML]="hexLine[HexFormatPart.CONTENT]"></div>
      </div>
    </cdk-virtual-scroll-viewport>
  </ng-template>
</ng-container>

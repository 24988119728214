import { Pipe, PipeTransform } from '@angular/core';
import { isNumberFinite } from '@neuralegion/lang';

@Pipe({
  name: 'isNumber',
  pure: true
})
export class IsNumberPipe implements PipeTransform {
  public transform(value: unknown): boolean {
    return isNumberFinite(value);
  }
}

<form [formGroup]="form" (ngSubmit)="submit()">
  <p>
    <mat-form-field>
      <mat-label>Email</mat-label>
      <input type="email" autocomplete="email" email matInput required [formControlName]="'email'" />
      <mat-error [matErrorOptions]="{ fieldName: 'Email' }"></mat-error>
    </mat-form-field>
  </p>
  <p>
    <button color="primary" mat-raised-button [disabled]="!form.valid">Send password reset email</button>
  </p>
</form>

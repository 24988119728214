import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { Session } from '@neuralegion/api';

export interface SessionsState extends EntityState<Session> {
  error: string | null;
  pending: Action[];
}

export const sessionsEntityAdapter: EntityAdapter<Session> = createEntityAdapter<Session>({
  selectId: (model) => model.tokenId
});

export const initialSessionsState: SessionsState = sessionsEntityAdapter.getInitialState({
  error: null,
  pending: []
});

import { Pipe, PipeTransform } from '@angular/core';
import { ProviderType } from '@neuralegion/api';

@Pipe({
  name: 'providerType',
  pure: true
})
export class ProviderTypePipe implements PipeTransform {
  public transform(type: ProviderType): string {
    switch (type) {
      case ProviderType.GITHUB:
        return 'GitHub';
      case ProviderType.GOOGLE:
        return 'Google';
      case ProviderType.OKTA:
        return 'Okta';
      case ProviderType.ADFS:
        return 'AD FS';
      case ProviderType.SAML:
        return 'SAML';
    }
  }
}

import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'share-spinner',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {
  @Input()
  @HostBinding('class.inner-spinner')
  public inner = false;

  @Input()
  @HostBinding('class.transparent')
  public transparent = false;

  @Input()
  public diameter = 56;
}

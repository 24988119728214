import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthProvider, IdPMetadata, SpMetadata } from '@neuralegion/api';
import { CLEAR_EMPTY_VALUES_TOKEN } from '@neuralegion/core';

@Injectable()
export class AuthProvidersService {
  constructor(private readonly http: HttpClient) {}

  public load(): Observable<AuthProvider> {
    return this.http.get<AuthProvider>('/api/v1/auth-provider');
  }

  public loadIdpMetadata(file: File): Observable<IdPMetadata> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post<IdPMetadata>('/api/v1/auth-provider/idp/metadata', formData, {
      context: new HttpContext().set(CLEAR_EMPTY_VALUES_TOKEN, false)
    });
  }

  public loadSpMetadata(): Observable<SpMetadata> {
    return this.http.get<SpMetadata>('/api/v1/auth-provider/sp/metadata');
  }

  public edit(authProvider: AuthProvider): Observable<AuthProvider> {
    return this.http.patch<AuthProvider>('/api/v1/auth-provider', authProvider);
  }

  public sendReminders(): Observable<void> {
    return this.http.put<void>(`/api/v1/auth-provider/reminders`, {});
  }

  public startSetup(authProvider: AuthProvider): Observable<AuthProvider> {
    return this.http.post<AuthProvider>('/api/v1/auth-provider', authProvider);
  }

  public disable(): Observable<void> {
    return this.http.delete<void>(`/api/v1/auth-provider`);
  }
}

import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable, catchError, filter, from, map, of, switchMap, take, tap } from 'rxjs';
import { Actions } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import Lock from 'browser-tabs-lock';
import { AuthenticationCredentials, LogoutResponse, ProviderType, Token } from '@neuralegion/api';
import { UserSessionStorageService } from '@neuralegion/browser-storage';
import { loadUserInfo, loadUserInfoFail, loadUserInfoSuccess } from '../store';
import { BaseService } from './base.service';

@Injectable()
export class AuthService {
  private readonly LOCK_KEY = 'nexploit:locks:refresh_token';
  private readonly lock = new Lock();

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly baseService: BaseService,
    private readonly userSessionStorageService: UserSessionStorageService
  ) {}

  public checkSession(): Observable<void> {
    // Prevents calling API unnecessarily. If the cookie is not present because
    // there was no previous login (or it has expired) then tokens will not be refreshed.
    return this.userSessionStorageService.hasLoggedIn()
      ? this.renewAccessTokenSilently().pipe(catchError(() => of(null)))
      : of(null);
  }

  public renewAccessTokenSilently(): Observable<void> {
    return from(this.acquireTabLock()).pipe(
      filter((locked) => locked),
      take(1),
      switchMap(() => this.refreshTokenAndReloadIdentity()),
      switchMap((err: Error | null) => this.releaseLockOrRaiseError(err))
    );
  }

  private loadUserInfo(): Observable<null> {
    this.store.dispatch(loadUserInfo({ skipNavigation: true }));
    return this.actions$.pipe(
      filter(
        (action: Action): boolean =>
          action?.type === loadUserInfoFail.type || action?.type === loadUserInfoSuccess.type
      ),
      map(() => null)
    ) as Observable<null>;
  }

  public login(data: AuthenticationCredentials): Observable<void> {
    return this.baseService
      .login(data)
      .pipe(map((token: Token) => this.userSessionStorageService.set(token)));
  }

  public socialLogin(providerType: ProviderType, params: Params): Observable<void> {
    const queryParams: HttpParams = new HttpParams({ fromObject: params });

    return this.baseService
      .oauthLogin(providerType, queryParams)
      .pipe(map((token: Token) => this.userSessionStorageService.set(token)));
  }

  public logout(): Observable<LogoutResponse> {
    return this.userSessionStorageService.hasLoggedIn()
      ? this.baseService.logout().pipe(
          catchError(() => of(null)),
          tap(() => this.userSessionStorageService.clear())
        )
      : of(null);
  }

  private refreshTokenAndReloadIdentity(): Observable<Error | null> {
    return this.refreshToken().pipe(
      switchMap(() => this.loadUserInfo()),
      take(1),
      catchError((err: Error) => of(err))
    );
  }

  private releaseLockOrRaiseError(err?: Error): Observable<void> {
    return from(this.lock.releaseLock(this.LOCK_KEY)).pipe(
      map(() => {
        if (err) {
          throw err;
        }
      })
    );
  }

  private refreshToken(): Observable<void> {
    return this.baseService
      .refreshToken()
      .pipe(map((token: Token) => this.userSessionStorageService.set(token)));
  }

  private async acquireTabLock(): Promise<boolean> {
    for (let i = 0; i < 10; i++) {
      if (await this.lock.acquireLock(this.LOCK_KEY, 5000)) {
        return true;
      }
    }

    return false;
  }
}

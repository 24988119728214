/* Based on https://github.com/fknop/angular-pipes */
import { Pipe, PipeTransform } from '@angular/core';
import { isNumberFinite } from '@neuralegion/lang';

export type ByteUnit = 'B' | 'kB' | 'KB' | 'MB' | 'GB' | 'TB';

// Not strict positive
function isPositive(value: number): boolean {
  return value >= 0;
}

function isInteger(value: number): boolean {
  // No rest, is an integer
  return value % 1 === 0;
}

function toDecimal(value: number, decimal: number): number {
  return Math.round(value * Math.pow(10, decimal)) / Math.pow(10, decimal);
}

@Pipe({
  name: 'bytes'
})
export class BytesPipe implements PipeTransform {
  private static readonly formats: Record<string, { max: number; prev?: ByteUnit }> = {
    B: { max: 1024 },
    kB: { max: Math.pow(1024, 2), prev: 'B' },
    KB: { max: Math.pow(1024, 2), prev: 'B' }, // Backward compatible
    MB: { max: Math.pow(1024, 3), prev: 'kB' },
    GB: { max: Math.pow(1024, 4), prev: 'MB' },
    TB: { max: Number.MAX_SAFE_INTEGER, prev: 'GB' }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public transform(input: any, decimal: number = 0, from: ByteUnit = 'B', to?: ByteUnit): any {
    if (
      !(
        isNumberFinite(input) &&
        isNumberFinite(decimal) &&
        isInteger(decimal) &&
        isPositive(decimal)
      )
    ) {
      return input;
    }

    let bytes = input;
    let unit = from;
    while (unit !== 'B') {
      bytes *= 1024;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      unit = BytesPipe.formats[unit].prev!;
    }

    if (to) {
      const format = BytesPipe.formats[to];

      const result = toDecimal(BytesPipe.calculateResult(format, bytes), decimal);

      return BytesPipe.formatResult(result, to);
    }

    for (const key in BytesPipe.formats) {
      if (Object.prototype.hasOwnProperty.call(BytesPipe.formats, key)) {
        const format = BytesPipe.formats[key];
        if (bytes < format.max) {
          const result = toDecimal(BytesPipe.calculateResult(format, bytes), decimal);

          return BytesPipe.formatResult(result, key);
        }
      }
    }
  }

  public static formatResult(result: number, unit: string): string {
    return `${result} ${unit}`;
  }

  public static calculateResult(format: { max: number; prev?: ByteUnit }, bytes: number) {
    const prev = format.prev ? BytesPipe.formats[format.prev] : undefined;
    return prev ? bytes / prev.max : bytes;
  }
}

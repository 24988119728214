import {
  HttpContextToken,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpMethod } from '@neuralegion/api';
import { EmptyValuesPreprocessorService } from './empty-values-preprocessor.service';

export const CLEAR_EMPTY_VALUES_TOKEN = new HttpContextToken<boolean>(() => true);
export const CLEAR_EMPTY_VALUES_EXCLUSIONS_TOKEN = new HttpContextToken<string[] | undefined>(
  () => undefined
);

@Injectable()
export class HttpInterceptorEmptyValuesService implements HttpInterceptor {
  constructor(private readonly payloadPreprocessorService: EmptyValuesPreprocessorService) {}

  public intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    if (
      !req.context.get(CLEAR_EMPTY_VALUES_TOKEN) ||
      ((req.method as HttpMethod) !== HttpMethod.PUT &&
        (req.method as HttpMethod) !== HttpMethod.POST) ||
      req.headers.get('Content-Type') === 'multipart/form-data'
    ) {
      return next.handle(req);
    }

    return next.handle(
      req.clone({
        ...(this.payloadPreprocessorService.isObjectLike(req.body)
          ? {
              body: this.payloadPreprocessorService.clearEmptyValues(
                req.body as unknown as object,
                req.context.get(CLEAR_EMPTY_VALUES_EXCLUSIONS_TOKEN)
              )
            }
          : {})
      })
    );
  }
}

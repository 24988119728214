import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { MarketplaceCallbackStateStorageService } from '../services';

@Injectable()
export class SignupGuard {
  constructor(
    private readonly router: Router,
    private readonly marketplaceCallbackStateStorageService: MarketplaceCallbackStateStorageService
  ) {}

  public canActivate(route?: ActivatedRouteSnapshot): boolean | UrlTree {
    const memberId: string | null = route.paramMap.get('memberId');
    const token: string | null = route.paramMap.get('token');
    const storedMarketplaceCallbackState: string | null =
      this.marketplaceCallbackStateStorageService.get();

    if ((memberId && token) || storedMarketplaceCallbackState) {
      return true;
    }

    return this.router.parseUrl('/login');
  }
}

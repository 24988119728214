import { FunctionWithParametersType } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { PaginationParams, PaginationResponse } from '@neuralegion/api';

export interface PaginationAction {
  type: PaginationActionType;
  payload?: PaginationParams;
}

export enum PaginationActionType {
  LOAD_NEXT_PAGE = 'LOAD_NEXT_PAGE',
  LOAD_PREV_PAGE = 'LOAD_PREV_PAGE',
  LOAD_FIRST_PAGE = 'LOAD_FIRST_PAGE',
  LOAD_LAST_PAGE = 'LOAD_LAST_PAGE',
  UPDATE_PAGE_SIZE = 'UPDATE_PAGE_SIZE',
  UPDATE_FILTER = 'UPDATE_FILTER',
  UPDATE_SORT = 'UPDATE_SORT',
  RESET = 'RESET',
  RELOAD_PAGE = 'RELOAD_PAGE'
}

export type LoadActionFactory = (payload: {
  params: PaginationParams;
}) => { payload: { params: PaginationParams } } & TypedAction<string>;

export type LoadActionType = FunctionWithParametersType<
  [payload: { params: PaginationParams }],
  { payload: { params: PaginationParams } } & TypedAction<string>
> &
  TypedAction<string>;

export type LoadSuccessActionType<T> = FunctionWithParametersType<
  [payload: PaginationResponse<T>],
  { payload: PaginationResponse<T> } & TypedAction<string>
> &
  TypedAction<string>;

export type LoadFailureActionType = FunctionWithParametersType<
  [payload: string],
  { payload: string } & TypedAction<string>
> &
  TypedAction<string>;

<mat-card>
  <mat-card-title role="heading" aria-level="1"> Create a New Organization </mat-card-title>

  <mat-card-content>
    <share-note *ngIf="showAccountActivationNotice" class="account-activation-notice" [type]="'notice'">
      Your account activation is pending, it may take up to three business days. As activation is complete, your plan
      and quota information will be available in Organization → Quotas.
    </share-note>

    <auth-create-org (created)="createOrg($event)"></auth-create-org>

    <auth-link-signin [message]="'Want to use another account? Go to'"></auth-link-signin>
  </mat-card-content>
</mat-card>

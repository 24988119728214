import { Pipe, PipeTransform } from '@angular/core';
import { DateFormat } from '@neuralegion/api';

@Pipe({
  name: 'dateFormatLabel',
  pure: true
})
export class DateFormatLabelPipe implements PipeTransform {
  public transform(dateFormat: DateFormat): string {
    switch (dateFormat) {
      case DateFormat.DMY_SLASH:
        return 'd/m/yyyy';
      case DateFormat.DMY_DOT:
        return 'd.m.yyyy';
      case DateFormat.DDMMY_SLASH:
        return 'dd/mm/yyyy';
      case DateFormat.DDMMY_DOT:
        return 'dd.mm.yyyy';
      case DateFormat.MDY_SLASH:
        return 'm/d/yyyy';
      case DateFormat.YMMDD_SLASH:
        return 'yyyy/mm/dd';
      case DateFormat.ISO:
        return 'yyyy-mm-dd';
    }
  }
}

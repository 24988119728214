import { createAction } from '@ngrx/store';
import { Repeater } from '../models';

export const addRepeater = createAction('[Repeaters] ADD', (payload: Omit<Repeater, 'id'>) => ({
  payload
}));

export const addRepeaterSuccess = createAction('[Repeaters] ADD_SUCCESS', () => ({
  parentType: addRepeater.type
}));

export const addRepeaterFail = createAction('[Repeaters] ADD_FAIL', (payload: string) => ({
  payload,
  parentType: addRepeater.type
}));

export const loadRepeaters = createAction('[Repeaters] LOAD_ALL');

export const loadRepeatersSuccess = createAction(
  '[Repeaters] LOAD_ALL_SUCCESS',
  (payload: Repeater[]) => ({
    payload,
    parentType: loadRepeaters.type
  })
);

export const loadRepeatersFail = createAction('[Repeaters] LOAD_ALL_FAIL', (payload: string) => ({
  payload,
  parentType: loadRepeaters.type
}));

export const loadRepeater = createAction('[Repeaters] LOAD', (payload: string) => ({
  payload
}));

export const loadRepeaterSuccess = createAction(
  '[Repeaters] LOAD_SUCCESS',
  (payload: Repeater) => ({ payload, parentType: loadRepeater.type })
);

export const loadRepeaterFail = createAction('[Repeaters] LOAD_FAIL', (payload: string) => ({
  payload,
  parentType: loadRepeater.type
}));

export const removeRepeater = createAction(
  '[Repeaters] REMOVE',
  (payload: { repeaterId: string }) => ({ payload })
);

export const removeRepeaterSuccess = createAction('[Repeaters] REMOVE_SUCCESS', () => ({
  parentType: removeRepeater.type
}));

export const removeRepeaterFail = createAction('[Repeaters] REMOVE_FAIL', (payload: string) => ({
  payload,
  parentType: removeRepeater.type
}));

export const updateRepeater = createAction(
  '[Repeaters] UPDATE',
  (payload: { repeater: Repeater }) => ({ payload })
);

export const updateRepeaterSuccess = createAction('[Repeaters] UPDATE_SUCCESS', () => ({
  parentType: updateRepeater.type
}));

export const updateRepeaterFail = createAction('[Repeaters] UPDATE_FAIL', (payload: string) => ({
  payload,
  parentType: updateRepeater.type
}));

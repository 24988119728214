import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { BehaviorSubject, Subject, distinctUntilChanged, filter, takeUntil } from 'rxjs';
import equal from 'fast-deep-equal/es6';
import {
  ComparisonOperator,
  Filter,
  FilterConfig,
  NumberCompareFilter,
  NumberCompareFilterSettings,
  NumberCompareValue,
  SelectItem
} from '@neuralegion/api';
import { trackByIdentity } from '@neuralegion/core';
import { extractTouchedChanges } from '../../../form';
import { FilterControl } from '../../../models';
import { FilterStatus } from '../../../pipes';
import { AvailableItem, NumberCompareFilterService } from '../../../services';

interface FormValue {
  item: SelectItem;
  comparator: {
    operator: ComparisonOperator;
    value: number;
  };
}

// eslint-disable-next-line @angular-eslint/use-component-selector
@Component({
  templateUrl: './number-compare-filter-control.component.html',
  styleUrls: ['./number-compare-filter-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: NumberCompareFilterControlComponent, multi: true },
    { provide: NG_VALIDATORS, useExisting: NumberCompareFilterControlComponent, multi: true },
    NumberCompareFilterService
  ]
})
export class NumberCompareFilterControlComponent implements FilterControl, OnInit, OnDestroy {
  @Input()
  public filterConfig: FilterConfig;

  @Input()
  public filters: Filter[];

  public numberValidators: ValidatorFn[] = [];

  public availableOperators: ComparisonOperator[] = [
    ...this.numberCompareFilterService.ALL_OPERATORS
  ];

  public readonly form = new FormGroup({
    item: new FormControl(null, [Validators.required]),
    comparator: new FormControl(null, [Validators.required])
  } as Record<keyof FormValue, FormControl>);

  public availableItemsSubject: BehaviorSubject<AvailableItem[]> = new BehaviorSubject<
    AvailableItem[]
  >([]);

  public readonly trackByIdentity = trackByIdentity;
  public readonly FilterStatus = FilterStatus;

  private onChange: (value: NumberCompareValue) => void;
  private onTouched: () => void;

  private readonly valueSubject = new Subject<NumberCompareValue>();
  private readonly gc = new Subject<void>();

  constructor(private readonly numberCompareFilterService: NumberCompareFilterService) {}

  public ngOnInit(): void {
    this.initValueSubjectListener();
    this.initTouchedListener();
    this.initItemControlListener();
    this.initCompareToNumberControlListener();
    this.initFormListener();

    this.availableItemsSubject.next(
      this.numberCompareFilterService.getAvailableItems(
        this.getNumberCompareFilters(),
        this.getNumberCompareFilterSettings().items
      )
    );

    if (this.getNumberCompareFilterSettings().items.length === 1) {
      this.form.controls.item.patchValue(this.getNumberCompareFilterSettings().items[0]);
    }
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }

  public writeValue(value: NumberCompareValue): void {
    this.valueSubject.next(value);
  }

  public validate(): ValidationErrors | null {
    return this.form.valid ? null : { numberCompareFilter: true };
  }

  public registerOnChange(fn: (value: NumberCompareValue) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  private initValueSubjectListener(): void {
    this.valueSubject
      .pipe(
        filter(
          (numberCompareValue: NumberCompareValue) =>
            !equal(this.form.value, {
              item: numberCompareValue?.item,
              comparator: {
                operator: numberCompareValue?.operator,
                value: numberCompareValue?.value
              }
            })
        ),
        takeUntil(this.gc)
      )
      .subscribe((numberCompareValue: NumberCompareValue) =>
        this.form.patchValue(numberCompareValue ?? {})
      );
  }

  private initTouchedListener(): void {
    extractTouchedChanges(this.form)
      .pipe(
        filter((touched: boolean) => touched),
        takeUntil(this.gc)
      )
      .subscribe(() => {
        this.onTouched?.();
      });
  }

  private initCompareToNumberControlListener(): void {
    this.form.controls.comparator.valueChanges
      .pipe(
        filter(
          (comparator: { operator: ComparisonOperator; value: number }) => !!comparator?.operator
        ),
        takeUntil(this.gc)
      )
      .subscribe(({ operator }: { operator: ComparisonOperator; value: number }) => {
        this.numberValidators = [
          ...this.getNumberCompareFilterSettings().numberValidators,
          ...this.numberCompareFilterService.getMinMaxValidators(
            this.getNumberCompareFilters(),
            this.form.value.item,
            operator
          )
        ];
      });
  }

  private initItemControlListener(): void {
    this.form.controls.item.valueChanges.pipe(takeUntil(this.gc)).subscribe((item: SelectItem) => {
      if (!item) {
        this.form.controls.comparator.setValue(null);
        return;
      }

      this.numberValidators = this.getNumberCompareFilterSettings().numberValidators;
      this.availableOperators = this.numberCompareFilterService.getAvailableOperators(
        this.getNumberCompareFilters(),
        item
      );
    });
  }

  private initFormListener(): void {
    this.form.valueChanges.pipe(distinctUntilChanged(equal), takeUntil(this.gc)).subscribe(
      (formValue: FormValue) =>
        this.onChange?.({
          item: formValue.item,
          operator: formValue.comparator?.operator ?? null,
          value: formValue.comparator?.value ?? null
        })
    );
  }

  private getNumberCompareFilterSettings(): NumberCompareFilterSettings {
    return this.filterConfig.settings as NumberCompareFilterSettings;
  }

  private getNumberCompareFilters(): NumberCompareFilter[] {
    return this.filters.filter(
      (f: Filter) => f.name === this.filterConfig.name
    ) as NumberCompareFilter[];
  }
}

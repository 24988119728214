import { Injectable } from '@angular/core';
import { LocalStorageService } from '@neuralegion/browser-storage';

@Injectable({
  providedIn: 'root'
})
export class MarketplaceCallbackStateStorageService {
  private readonly STATE_KEY = 'marketplace_callback_state';

  constructor(private readonly storageService: LocalStorageService) {}

  public set(state: string): void {
    this.storageService.set(this.STATE_KEY, state);
  }

  public get(): string | null {
    return this.storageService.get(this.STATE_KEY) as string | null;
  }

  public clear(): void {
    this.storageService.delete(this.STATE_KEY);
  }
}

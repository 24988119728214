/* Based on https://github.com/fknop/angular-pipes */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'max'
})
export class MaxPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public transform(input: any): any {
    if (!Array.isArray(input)) {
      return input;
    }

    if (input.length === 0) {
      return undefined;
    }

    let max = input[0];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    input.forEach((value: any) => {
      if (max < value) {
        max = value;
      }
    });

    return max;
  }
}

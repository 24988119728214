<fieldset>
  <legend class="mat-form-field-label custom-label">
    <h4 [class.error-some]="form.touched && form.invalid">
      <span *ngIf="required" class="custom-required-marker" aria-label="Required">*</span>
      <ng-content></ng-content>
    </h4>
  </legend>

  <div
    fxLayout="row"
    fxLayout.lt-sm="column"
    fxLayoutAlign="start center"
    fxLayoutGap="24px"
    fxLayoutGap.lt-sm="10px"
    [formGroup]="form">
    <mat-button-toggle-group [formControlName]="'predefined'">
      <mat-button-toggle
        *ngFor="let numberOption of options; trackBy: trackByIdentity"
        fxFlex="1 1 auto"
        [value]="numberOption">
        {{ numberOption }}
      </mat-button-toggle>
    </mat-button-toggle-group>

    <mat-form-field class="compact-field">
      <input
        type="number"
        class="custom-number-input"
        attr.aria-label="Custom value between {{ min }} and {{ max }}"
        matInput
        placeholder="Custom"
        [formControlName]="'custom'" />
    </mat-form-field>
  </div>
  <mat-error *ngIf="form.touched && form.invalid" class="error" element-data-id="error">
    Value must be between {{ min }} and {{ max }}
  </mat-error>
</fieldset>

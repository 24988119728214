<form [formGroup]="form" (ngSubmit)="onSignup()">
  <mat-form-field>
    <mat-label>Full name</mat-label>
    <input autocomplete="given-name" matInput maxlength="65" minlength="2" required [formControlName]="'name'" />
    <mat-error [matErrorOptions]="{ fieldName: 'Full name' }"></mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Company email</mat-label>
    <input
      type="email"
      autocomplete="email"
      email
      matInput
      maxlength="64"
      minlength="3"
      required
      [formControlName]="'email'" />
    <mat-hint align="start"> Please use your company email address. </mat-hint>
    <mat-error [matErrorOptions]="{ fieldName: 'Email' }">
      <ng-template [matErrorTmpl]="'companyEmail'"></ng-template>
    </mat-error>
  </mat-form-field>
  <share-password-repeat [formControlName]="'password'"></share-password-repeat>

  <button color="primary" mat-raised-button [disabled]="!form.valid">
    <ng-container *ngIf="inviteDetails$ | async; else tmplSelfSignupLabel">Sign up</ng-container>
    <ng-template #tmplSelfSignupLabel>Create a free account</ng-template>
  </button>
</form>

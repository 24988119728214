import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';

@Injectable()
export class EmailConfirmationNegateGuard {
  constructor(private readonly router: Router) {}

  public canActivate(route?: ActivatedRouteSnapshot): boolean | UrlTree {
    const { email, state, type } = route?.queryParams ?? {};

    if (state && type && email) {
      return true;
    }

    return this.router.parseUrl('/login');
  }
}

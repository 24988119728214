import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectSidebarExpandedStatus } from '@neuralegion/core';

@Component({
  selector: 'share-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavItemComponent implements OnInit {
  @Input()
  public icon = '';

  @Input()
  public tooltip = '';

  @Input()
  public disabled = false;

  @Input()
  public link: string | string[] = '/';

  @Input()
  public label = '';

  public sidebarExpanded$: Observable<boolean>;

  constructor(private readonly store: Store) {}

  public ngOnInit(): void {
    this.sidebarExpanded$ = this.store.select(selectSidebarExpandedStatus);
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Session } from '@neuralegion/api';
import { SessionsState, sessionsEntityAdapter } from './sessions.state';

// eslint-disable-next-line @typescript-eslint/ban-types
type State = object;

export const selectSessionsState = createFeatureSelector<SessionsState>('sessions');

const selectAllSessions = createSelector<State, [SessionsState], Session[]>(
  selectSessionsState,
  sessionsEntityAdapter.getSelectors().selectAll
);

export const selectRecentSession = createSelector<State, [Session[]], Session | null>(
  selectAllSessions,
  (state: Session[]) =>
    state.reduce(
      (a: Session | null, b: Session) => (a?.lastLoggedInAt > b.lastLoggedInAt ? a : b),
      null
    )
);

export const selectSessionsPendingStatus = createSelector<State, [SessionsState], boolean>(
  selectSessionsState,
  (state: SessionsState) => state.pending && state.pending.length > 0
);

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FeedActivity, FeedActivityObjectType, FeedVerb } from '@neuralegion/feed-api';

@Component({
  selector: 'feed-item',
  templateUrl: './feed-item.component.html',
  styleUrls: ['./feed-item.component.scss'],
  preserveWhitespaces: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedItemComponent {
  @Input()
  public item: FeedActivity;

  @Input()
  public debugEnabled = false;

  @Output()
  public readonly clear: EventEmitter<string> = new EventEmitter<string>();

  public readonly FeedActivityObjectType = FeedActivityObjectType;
  public readonly FeedVerb = FeedVerb;
}

<ng-container *ngIf="{ pending: pending$ | async, inviteDetails: inviteDetails$ | async } as sync">
  <share-spinner *ngIf="sync.pending" [inner]="true"></share-spinner>
  <mat-card>
    <mat-card-header>
      <mat-card-title role="heading" aria-level="1"
        >{{ sync.inviteDetails ? 'Create an Account' : 'Create a Free Account' }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <auth-signup-form
        [inviteDetails$]="inviteDetails$"
        [signUpError$]="signUpError$"
        (signup)="onSignup($event)"></auth-signup-form>

      <auth-signup-policy></auth-signup-policy>

      <auth-link-signin></auth-link-signin>
    </mat-card-content>
  </mat-card>
</ng-container>

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  Observable,
  catchError,
  exhaustMap,
  from,
  map,
  of,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Invitation, Member, PaginationResponse, Scope } from '@neuralegion/api';
import { selectScopePermission } from '@neuralegion/auth-api';
import {
  AppRouterState,
  PaginationParamsSerializationService,
  SnackbarService,
  ofPrimaryRouterNavigated
} from '@neuralegion/core';
import { OrganizationService } from '../services';
import { loadGroups } from './groups.actions';
import {
  addMember,
  addMemberFail,
  addMemberSuccess,
  loadMember,
  loadMemberFail,
  loadMemberSuccess,
  loadMembers,
  loadMembersFail,
  loadMembersSuccess,
  loadMembersV1,
  loadMembersV1Fail,
  loadMembersV1Success,
  removeMember,
  removeMemberFail,
  removeMemberSuccess,
  updateMember,
  updateMemberFail,
  updateMemberSuccess
} from './members.actions';

@Injectable()
export class MembersEffects {
  public readonly loadMembers$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMembers),
      withLatestFrom(this.store.select(selectScopePermission(Scope.ORG_MEMBERSHIPS_READ))),
      switchMap(([action, membersReadPermission]: [ReturnType<typeof loadMembers>, boolean]) => {
        return membersReadPermission
          ? this.organizationsService
              .loadUserOrganizationMembers(
                this.paginationParamsSerializationService.convertToHttpParams(action.payload.params)
              )
              .pipe(
                map((res: PaginationResponse<Member>) => loadMembersSuccess(res)),
                catchError((err: HttpErrorResponse) => of(loadMembersFail(err.error)))
              )
          : of(loadMembersSuccess({ items: [], total: 0 }));
      })
    )
  );

  public readonly loadMember$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMember),
      exhaustMap((action: ReturnType<typeof loadMember>) =>
        this.organizationsService.getUserOrganizationMember(action.payload.memberId).pipe(
          map((member: Member) => loadMemberSuccess(member)),
          catchError((err: HttpErrorResponse) => of(loadMemberFail(err.error)))
        )
      )
    )
  );

  public readonly loadMemberFail$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadMemberFail),
        tap(() => this.router.navigate(['/']))
      ),
    { dispatch: false }
  );

  public readonly addMember$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(addMember),
      exhaustMap((action: ReturnType<typeof addMember>) =>
        this.organizationsService.addMemberToUserOrganization(action.payload.invitation).pipe(
          map(() => addMemberSuccess()),
          catchError((err: HttpErrorResponse) => of(addMemberFail(err.error)))
        )
      )
    )
  );

  public readonly addMemberSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addMemberSuccess),
        tap(() => this.snackbarService.open('Invitation has been sent'))
      ),
    { dispatch: false }
  );

  public readonly updateMember$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMember),
      exhaustMap((action: ReturnType<typeof updateMember>) =>
        this.organizationsService.updateMemberOfUserOrganization(action.payload.member).pipe(
          map(() => updateMemberSuccess(action.payload)),
          catchError((err: HttpErrorResponse) => of(updateMemberFail(err.error)))
        )
      )
    )
  );

  public readonly removeMember$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(removeMember),
      exhaustMap((action: ReturnType<typeof removeMember>) =>
        this.organizationsService.removeMemberFromUserOrganization(action.payload.memberId).pipe(
          map(() => removeMemberSuccess({ memberId: action.payload.memberId })),
          catchError((err: HttpErrorResponse) => of(removeMemberFail(err.error)))
        )
      )
    )
  );

  public readonly redirectToOrganizationPage$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(removeMemberSuccess),
        tap(() => this.router.navigate(['/organization']))
      ),
    { dispatch: false }
  );

  public readonly reloadMemberOnSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMemberSuccess),
      exhaustMap((action: ReturnType<typeof updateMemberSuccess>) =>
        from([
          loadMember({ memberId: action.payload.member.id }),
          ...((action.payload.member as Invitation).groups ? [loadGroups()] : [])
        ])
      )
    )
  );

  public readonly closeDialogWindows$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(addMember),
        tap(() => this.dialog.closeAll())
      ),
    { dispatch: false }
  );

  public readonly loadMembersV1$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMembersV1),
      withLatestFrom(this.store.select(selectScopePermission(Scope.ORG_MEMBERSHIPS_READ))),
      exhaustMap(([, membersReadPermission]: [ReturnType<typeof loadMembersV1>, boolean]) =>
        membersReadPermission
          ? this.organizationsService.getUserOrganizationMembersV1().pipe(
              map((members: Member[]) => loadMembersV1Success(members)),
              catchError((err: HttpErrorResponse) => of(loadMembersV1Fail(err.error)))
            )
          : of(loadMembersV1Success([]))
      )
    )
  );

  public readonly navigated$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofPrimaryRouterNavigated(
        (state: AppRouterState) =>
          !state.params.organizationId && state.pathname.includes('/members/')
      ),
      map((state: AppRouterState) => loadMember({ memberId: state.params.id }))
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly store: Store,
    private readonly organizationsService: OrganizationService,
    private readonly snackbarService: SnackbarService,
    private readonly paginationParamsSerializationService: PaginationParamsSerializationService
  ) {}
}

import { AbstractControl, FormArray, FormGroup } from '@angular/forms';

export function updateNestedTreeTouchedState(
  abstractControl: FormGroup | FormArray | AbstractControl,
  touched: boolean = false
): void {
  if (!(abstractControl instanceof FormGroup || abstractControl instanceof FormArray)) {
    if (touched) {
      abstractControl.markAsTouched({ onlySelf: true });
    } else {
      abstractControl.markAsUntouched({ onlySelf: true });
    }
    return;
  }

  Object.values(abstractControl.controls).forEach((control: AbstractControl) =>
    updateNestedTreeTouchedState(control, touched)
  );
}

import { Action } from '@ngrx/store';
import { AuthProvider, IdPMetadata, SpMetadata } from '@neuralegion/api';
import { GitHubOrg } from '../models';

export interface AuthProviderAPIState {
  readonly provider: AuthProviderState;
  readonly github: GitHubAuthState;
  readonly ui: UIState;
}

export interface UIState {
  readonly pending: Action[];
  readonly error: string;
}

export const initialUIState: UIState = {
  pending: [],
  error: null
};

export type AuthProviderState = {
  provider: AuthProvider;
  idpMetadata: IdPMetadata;
  spMetadata: SpMetadata;
};

export const initialAuthProvidersState: AuthProviderState = {
  provider: null,
  idpMetadata: null,
  spMetadata: null
};

export interface GitHubAuthState {
  readonly orgs: GitHubOrg[];
}

export const initialGitHubAuthState: GitHubAuthState = {
  orgs: []
};

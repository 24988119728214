import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, take, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectTempToken } from '@neuralegion/auth-api';
import { selectMfaPendingStatus, verifyMfaOtp } from '@neuralegion/multi-factor-api';

// eslint-disable-next-line @angular-eslint/use-component-selector
@Component({
  styleUrls: ['./mfa-page.component.scss'],
  templateUrl: './mfa-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MfaPageComponent implements OnInit, OnDestroy {
  public pending$: Observable<boolean>;
  private mfaToken$: Observable<string>;
  private readonly gc = new Subject<void>();

  constructor(private readonly store: Store) {}

  public ngOnInit(): void {
    this.pending$ = this.store.select(selectMfaPendingStatus);
    this.mfaToken$ = this.store.select(selectTempToken);
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }

  public onSubmit(event: { code: string }): void {
    this.mfaToken$.pipe(take(1), takeUntil(this.gc)).subscribe((token: string) =>
      this.store.dispatch(
        verifyMfaOtp({
          token,
          ...event
        })
      )
    );
  }
}

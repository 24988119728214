<ng-container [formGroup]="form">
  <share-note class="hint" [type]="'notice'">
    Toggle columns visibility, drag to reorder and set the column width
  </share-note>

  <div class="column-width-label">Width</div>

  <div
    class="columns-list"
    cdkDropList
    element-data-id="list_columns_config"
    [formArrayName]="'columns'"
    (cdkDropListDropped)="onDrop($event)"
    (click)="$event.stopPropagation()"
    (keydown)="$event.stopPropagation()">
    <div
      *ngFor="let group of columnsFormGroups; index as idx; trackBy: trackByIdentity"
      class="columns-list-item"
      cdkDrag
      element-data-id="list_item_{{ idx }}"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      [class.hidden-column]="group.get('fixed').value"
      [formGroup]="group">
      <mat-icon class="drag-handle" cdkDragHandle>drag_indicator</mat-icon>

      <mat-slide-toggle fxFlex [class.toggle-disabled]="!group.value.active" [formControlName]="'active'">
        {{ group.value.label }}
      </mat-slide-toggle>
      <share-input-number class="input-number" [formControlName]="'width'" [max]="1000" [min]="50">
      </share-input-number>

      <span class="units-label"> px</span>
    </div>
  </div>
</ng-container>

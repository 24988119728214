<main class="root-container" fxLayout="row" fxLayout.lt-md="column">
  <div class="left" fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="center start">
    <ng-container [ngSwitch]="welcomeType$ | async">
      <auth-welcome-back *ngSwitchCase="WelcomeType.WELCOME_BACK"></auth-welcome-back>
      <auth-welcome *ngSwitchDefault></auth-welcome>
    </ng-container>
  </div>

  <div class="warn-message" fxHide.gt-sm="true" fxLayout="row" fxLayoutAlign="center center">
    <mat-icon class="warn-message-icon">report_problem</mat-icon>

    <div class="warn-message-content">
      We recommend you to use a laptop to complete the wizard process successfully!
    </div>
  </div>

  <div class="right" fxFlex="1 1 100%" fxLayoutAlign="center start">
    <share-spinner *ngIf="pending$ | async" class="auth-spinner" [inner]="true"></share-spinner>
    <router-outlet></router-outlet>
  </div>
</main>

import { Injectable } from '@angular/core';
import { ID } from '@neuralegion/api';
import states from './states.json';

@Injectable()
export class StatesService {
  public hasStates(country: string): boolean {
    return Object.keys(states).includes(country);
  }

  public getStates(country: string): (ID & { label: string })[] {
    return Object.entries(this.getStatesForCountry(country)).map(
      ([id, label]: [string, string]) => ({ id, label })
    );
  }

  public getNameByCode(country: string, stateCode: string): string {
    return this.hasStates(country)
      ? (states as Record<string, Record<string, string>>)[country][stateCode]
      : '';
  }

  private getStatesForCountry(country: string): Record<string, string> {
    return this.hasStates(country)
      ? (states as Record<string, Record<string, string>>)[country]
      : {};
  }
}

import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { Payment, UpcomingInvoice } from '../models';

export interface PaymentsState extends EntityState<Payment> {
  upcomingInvoice: UpcomingInvoice;
  coupon: string;
  pending: Action[];
  error: string;
}

export const paymentsEntityAdapter: EntityAdapter<Payment> = createEntityAdapter<Payment>({
  selectId: (model) => model.id
});

export const initialPaymentsState: PaymentsState = paymentsEntityAdapter.getInitialState({
  upcomingInvoice: null,
  coupon: '',
  pending: [],
  error: null
});

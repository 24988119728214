import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Weekday } from '@neuralegion/api';

@Pipe({
  name: 'weekday',
  pure: true
})
export class WeekdayPipe implements PipeTransform {
  public transform(
    weekday: Weekday,
    format: 'min' | 'short' | 'medium' | 'full' = 'short'
  ): string {
    const weekdayDate = new Date();
    weekdayDate.setDate(weekdayDate.getDate() + weekday - weekdayDate.getDay());
    return formatDate(weekdayDate, this.getFormatString(format), 'en');
  }

  private getFormatString(format: 'min' | 'short' | 'medium' | 'full'): string {
    switch (format) {
      case 'min':
        return 'EEEEE';
      case 'short':
        return 'EEEEEE';
      case 'medium':
        return 'E';
      case 'full':
        return 'EEEE';
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join',
  pure: true
})
export class JoinPipe implements PipeTransform {
  public transform(input: unknown, separator: string = '', lastSeparator?: string): string {
    if (!Array.isArray(input) || input.length < 2) {
      return input as string;
    }

    return typeof lastSeparator === 'undefined'
      ? input.join(separator)
      : [input.slice(0, input.length - 1).join(separator), input[input.length - 1]].join(
          lastSeparator
        );
  }
}

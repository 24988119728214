import { Injectable } from '@angular/core';
import { diff_match_patch as DiffMatchPatch } from 'diff-match-patch';
import { Code } from './code';

export type DiffResultItem = [number, string];

@Injectable({
  providedIn: 'root'
})
export class DiffService {
  private readonly diffMatchPatchPromise: Promise<DiffMatchPatch>;

  constructor() {
    this.diffMatchPatchPromise = this.loadDiffMatchPatch();
  }

  public async diff({ actual, previous }: Code): Promise<DiffResultItem[]> {
    if (typeof previous !== 'string') {
      return [[0, actual] as DiffResultItem];
    }

    if (typeof actual !== 'string') {
      return [[-1, previous] as DiffResultItem];
    }

    const diffMatchPatch: DiffMatchPatch = await this.diffMatchPatchPromise;
    const diffs = diffMatchPatch.diff_main(previous, actual);
    diffMatchPatch.diff_cleanupSemantic(diffs);
    return diffs;
  }

  private async loadDiffMatchPatch(): Promise<DiffMatchPatch> {
    const diffMatchPatchCtor: typeof DiffMatchPatch =
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (await import(/* webpackChunkName: "code-format" */ 'diff-match-patch')).default as any;
    if (!diffMatchPatchCtor) {
      throw new Error('Cannot load diff-match-patch');
    }

    return new diffMatchPatchCtor();
  }

  public diffResultToHtml(diffResult: DiffResultItem[]): string {
    return diffResult
      .map(([type, value]: [number, string]): string => {
        switch (type) {
          case -1:
            return `<span class="diff-removed">${value}</span>`;
          case 0:
            return value;
          case 1:
            return `<span class="diff-added">${value}</span>`;
          default:
            return '';
        }
      })
      .join('');
  }
}

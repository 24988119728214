import { Injectable } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import equal from 'fast-deep-equal/es6';
import {
  ComparisonOperator,
  NumberCompareFilter,
  NumberCompareValue,
  SelectItem
} from '@neuralegion/api';
import { FilterStatus } from '../pipes';

export interface AvailableItem {
  item: SelectItem;
  filterStatus: FilterStatus;
}

@Injectable()
export class NumberCompareFilterService {
  public readonly ALL_OPERATORS: ReadonlySet<ComparisonOperator> = new Set([
    ComparisonOperator.GT,
    ComparisonOperator.GTE,
    ComparisonOperator.LT,
    ComparisonOperator.LTE,
    ComparisonOperator.EQ
  ]);

  private readonly GT_OPERATORS: ReadonlySet<ComparisonOperator> = new Set([
    ComparisonOperator.GT,
    ComparisonOperator.GTE
  ]);
  private readonly LT_OPERATORS: ReadonlySet<ComparisonOperator> = new Set([
    ComparisonOperator.LT,
    ComparisonOperator.LTE
  ]);

  public getAvailableItems(filters: NumberCompareFilter[], items: SelectItem[]): AvailableItem[] {
    return items.map((item: SelectItem) => {
      const appliedOperators: Set<ComparisonOperator> = this.getUniqueOperatorsByItem(
        filters,
        item
      );

      let filterStatus: FilterStatus = FilterStatus.AVAILABLE;

      if (
        appliedOperators.has(ComparisonOperator.EQ) ||
        (this.isIntersected(this.GT_OPERATORS, appliedOperators) &&
          this.isIntersected(this.LT_OPERATORS, appliedOperators))
      ) {
        filterStatus = FilterStatus.ALREADY_SELECTED;
      }
      return {
        item,
        filterStatus
      };
    });
  }

  public getAvailableOperators(
    filters: NumberCompareFilter[],
    item: SelectItem
  ): ComparisonOperator[] {
    const appliedOperators: Set<ComparisonOperator> = this.getUniqueOperatorsByItem(filters, item);

    if (this.isIntersected(this.GT_OPERATORS, appliedOperators)) {
      return [...this.LT_OPERATORS];
    } else if (this.isIntersected(this.LT_OPERATORS, appliedOperators)) {
      return [...this.GT_OPERATORS];
    }

    return [...this.ALL_OPERATORS];
  }

  public getMinMaxValidators(
    filters: NumberCompareFilter[],
    item: SelectItem,
    operator: ComparisonOperator
  ): ValidatorFn[] {
    const numberCompareFilters: NumberCompareFilter[] = filters.filter(
      (numberCompareFilter: NumberCompareFilter) => equal(numberCompareFilter.value.item, item)
    );

    return [
      this.getMinNumberValidator(numberCompareFilters, operator),
      this.getMaxNumberValidator(numberCompareFilters, operator)
    ].filter(Boolean);
  }

  private getMinNumberValidator(
    filters: NumberCompareFilter[],
    operator: ComparisonOperator
  ): ValidatorFn | null {
    if (!this.LT_OPERATORS.has(operator)) {
      return null;
    }

    const appliedMinFilterValue: NumberCompareValue = this.findNumberCompareFilterValue(
      filters,
      this.GT_OPERATORS
    );

    if (!appliedMinFilterValue) {
      return null;
    }

    let min: number = appliedMinFilterValue.value;

    // increase min value for making integer range not empty
    if (appliedMinFilterValue.operator === ComparisonOperator.GT) {
      min++;
    }

    if (operator === ComparisonOperator.LT) {
      min++;
    }

    return Validators.min(min);
  }

  private getMaxNumberValidator(
    filters: NumberCompareFilter[],
    operator: ComparisonOperator
  ): ValidatorFn | null {
    if (!this.GT_OPERATORS.has(operator)) {
      return null;
    }

    const appliedMaxFilterValue: NumberCompareValue = this.findNumberCompareFilterValue(
      filters,
      this.LT_OPERATORS
    );

    if (!appliedMaxFilterValue) {
      return null;
    }

    let max: number = appliedMaxFilterValue.value;

    // decrease max value for making integer range not empty
    if (appliedMaxFilterValue.operator === ComparisonOperator.LT) {
      max--;
    }

    if (operator === ComparisonOperator.GT) {
      max--;
    }

    return Validators.max(max);
  }

  private getUniqueOperatorsByItem(
    numberCompareFilters: NumberCompareFilter[],
    item: SelectItem
  ): Set<ComparisonOperator> {
    return new Set(
      numberCompareFilters
        .filter((numberCompareFilter: NumberCompareFilter) =>
          equal(numberCompareFilter.value.item, item)
        )
        .map((numberCompareFilter: NumberCompareFilter) => numberCompareFilter.value.operator)
    );
  }

  private findNumberCompareFilterValue(
    filters: NumberCompareFilter[],
    operators: ReadonlySet<ComparisonOperator>
  ): NumberCompareValue {
    return filters.find((numberCompareFilter: NumberCompareFilter) =>
      operators.has(numberCompareFilter.value.operator)
    )?.value;
  }

  private isIntersected<T>(setA: ReadonlySet<T>, setB: ReadonlySet<T>): boolean {
    return new Set([...setA].filter((element: T) => setB.has(element))).size > 0;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { Severity } from '@neuralegion/api';
import { FeedActivity, FeedActivityObjectType, FeedVerb } from '@neuralegion/feed-api';
import { BytesPipe } from '@neuralegion/share';

interface FeedExtra {
  severity?: Severity;
  admin?: boolean;
  size?: number;
  mimeType?: string;
}

@Pipe({
  name: 'feedExtraWording',
  pure: true
})
export class FeedExtraWordingPipe implements PipeTransform {
  private readonly bytesPipe = new BytesPipe();

  public transform(item: FeedActivity): string {
    const type = item.object?.type;
    const verb = item.verb;
    const extra: FeedExtra = item.object?.extra || item.target?.extra;

    if (extra) {
      if (type === FeedActivityObjectType.FILE && extra.mimeType) {
        return `size: ${this.bytesPipe.transform(extra.size)}, mimeType: ${extra.mimeType}`;
      }

      if (
        (type === FeedActivityObjectType.ISSUE ||
          (type === FeedActivityObjectType.USER &&
            (verb === FeedVerb.ASSIGN || verb === FeedVerb.UNASSIGN))) &&
        extra.severity
      ) {
        return `severity: ${extra.severity}`;
      }

      if (
        type === FeedActivityObjectType.USER &&
        (verb === FeedVerb.CREATE || verb === FeedVerb.REMOVE)
      ) {
        return `admin: ${extra.admin}`;
      }
    }

    return '';
  }
}

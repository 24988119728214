import { Action, createReducer, on } from '@ngrx/store';
import { closeSidebar, expandSidebar, openSidebar } from './core.actions';
import { SidebarState, initialSidebarState } from './sidebar.state';

export const sidebarReducer = createReducer<SidebarState, Action>(
  initialSidebarState,
  on(
    closeSidebar,
    (state: SidebarState): SidebarState => ({
      ...state,
      opened: false
    })
  ),
  on(
    openSidebar,
    (state: SidebarState): SidebarState => ({
      ...state,
      opened: true
    })
  ),
  on(
    expandSidebar,
    (state: SidebarState): SidebarState => ({
      ...state,
      expanded: !state.expanded
    })
  )
);

import { Pipe, PipeTransform } from '@angular/core';
import { Name } from '@neuralegion/api';

@Pipe({
  name: 'fullName',
  pure: true
})
export class FullNamePipe implements PipeTransform {
  public transform(value: Name): string;
  // eslint-disable-next-line no-dupe-class-members
  public transform(value: Name[]): string[];
  // eslint-disable-next-line no-dupe-class-members
  public transform(value: Name | Name[]): string[] | string {
    return Array.isArray(value) ? value.map(this.format) : this.format(value);
  }

  private format(value: Name): string {
    return value ? value.name : 'N/A';
  }
}

<mat-card>
  <mat-card-header>
    <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title" fxLayout="row" fxLayoutAlign="space-between center">
        <ng-container [ngSwitch]="item.object.type">
          <ng-container *ngSwitchCase="FeedActivityObjectType.COMMENT">
            {{ item.target.type | feedObjectType | titlecase }}
            {{ item.verb | feedVerbPastTense: item.object.type : true }}
          </ng-container>
          <ng-container *ngSwitchDefault>
            {{ item.object.type | feedObjectType | titlecase }}
            {{ item.verb | feedVerbPastTense: item.object.type : true }}
          </ng-container>
        </ng-container>
        <mat-icon [class.transparent]="item.isSeen">fiber_new</mat-icon>
      </span>
      <span
        class="time-ago"
        attr.aria-label="{{ item.createdAt | customDate }}"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        matTooltip="{{ item.createdAt | customDate }}">
        <mat-icon>access_time</mat-icon>
        {{ item.createdAt | timeAgo }}
      </span>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <feed-item-text [item]="item"></feed-item-text>

    <details *ngIf="debugEnabled">
      <summary>Details (JSON)</summary>
      <pre>{{ item | json }}</pre>
    </details>
  </mat-card-content>

  <button
    class="item-action-icons"
    aria-label="Remove from feed"
    mat-icon-button
    matTooltip="Remove from feed"
    (click)="clear.emit(item.id)">
    <mat-icon>delete</mat-icon>
  </button>
</mat-card>

import { HttpErrorResponse, HttpParamsOptions } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { ComponentStore } from '@ngrx/component-store';
import { EntityLabels } from '@neuralegion/api';

export type QueryParams = HttpParamsOptions['fromObject'];

interface LabelsState {
  readonly labels: EntityLabels;
  readonly pending: boolean;
  readonly error?: string;
  readonly queryParams?: QueryParams | null;
}

@Injectable()
export abstract class LabelsStore extends ComponentStore<LabelsState> {
  public readonly pending$: Observable<boolean> = this.select(
    (state: LabelsState) => state.pending
  );

  public readonly labels$: Observable<EntityLabels> = this.select(
    (state: LabelsState) => state.labels
  );

  public readonly clearLabels = this.updater((state) => ({
    ...state,
    labels: null,
    error: null,
    queryParams: null
  }));

  public readonly loadLabels = this.effect((labelName$: Observable<string>) => {
    return labelName$.pipe(
      tap(() => this.loadLabelsStart()),
      withLatestFrom(this.select((state: LabelsState) => state.queryParams)),
      switchMap(([labelName, queryParams]) =>
        this.load(labelName, queryParams).pipe(
          map((res: EntityLabels) => this.loadLabelsSuccess(res)),
          catchError((err: HttpErrorResponse) => of(this.loadLabelsFail(err.error)))
        )
      )
    );
  });

  protected constructor() {
    super({
      labels: null,
      pending: false,
      error: null,
      queryParams: null
    });
  }

  public setQueryParams(queryParams: QueryParams): void {
    this.patchState({ queryParams });
  }

  protected abstract load(
    labelName: string,
    queryParams: QueryParams | null
  ): Observable<EntityLabels>;

  private loadLabelsStart(): void {
    this.patchState(() => ({
      pending: true
    }));
  }

  private loadLabelsSuccess(labels: EntityLabels): void {
    this.patchState(() => ({
      labels,
      pending: false
    }));
  }

  private loadLabelsFail(error: string): void {
    this.patchState(() => ({
      error,
      pending: false
    }));
  }
}

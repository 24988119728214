<code class="headers animated fadeIn">
  <table class="focus-ring" tabindex="0">
    <caption class="cdk-visually-hidden">
      Headers
    </caption>
    <tr *ngFor="let header of headersMeta$ | async; trackBy: trackByIdentity">
      <td class="headers-linenum"></td>
      <td class="headers-key" [ngClass]="{ 'diff-added': header.added, 'diff-removed': header.removed }">
        {{ header.key }}
      </td>
      <td class="headers-colon" [ngClass]="{ 'diff-added': header.added, 'diff-removed': header.removed }">:</td>
      <td class="headers-value" [ngClass]="{ 'diff-added': header.added, 'diff-removed': header.removed }">
        <share-diff-viewer *ngIf="header.existing; else noDiff" [value$]="header.value$"></share-diff-viewer>
        <ng-template #noDiff>{{ header.value }}</ng-template>
      </td>
    </tr>
  </table>
  <ng-template #codeMissing>
    <p class="code-missing">No headers</p>
  </ng-template>
</code>

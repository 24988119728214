import { Renderer2 } from '@angular/core';
import { MatRadioButton } from '@angular/material/radio';
import { BaseElementIdStrategy } from './base-element-id-strategy';

export class MatRadioButtonElementIdStrategy extends BaseElementIdStrategy {
  private readonly elementPrefix = ['radio', 'btn'].join(this.SEPARATOR_INTERNAL);

  constructor(
    renderer: Renderer2,
    private readonly matRadioButton: MatRadioButton
  ) {
    super(renderer);
  }

  public override applyId(element: HTMLElement, dataId: string): void {
    super.applyId(element, dataId);
    super.applyId(element.querySelector('input'), [dataId, 'input'].join(this.SEPARATOR_INTERNAL));
  }

  protected override getSubId(): string {
    return [
      this.elementPrefix,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      this.generateIdByText((this.matRadioButton?.value?.toString() ?? 'null') || this.UNKNOWN_ID)
    ].join(this.SEPARATOR_INTERNAL);
  }
}

<ul class="container" [class.expanded]="expanded">
  <li *ngFor="let item of visibleItems | keyvalue; trackBy: trackByIdentity" class="custom-key-value">
    <span class="custom-key">{{ item.key }}</span>
    <span>:&nbsp;</span>
    <span class="custom-value">{{ item.value }}</span>
  </li>
</ul>
<button *ngIf="!expanded" class="link link-internal more" [attr.aria-label]="'Show more'" (click)="expand()">
  +{{ collapsedCounter }} more
</button>

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject, filter, takeUntil } from 'rxjs';
import { InviteDetails } from '@neuralegion/api';
import { RegistrationForm } from '@neuralegion/auth-api';
import { CustomValidators } from '@neuralegion/share';

@Component({
  selector: 'auth-signup-form',
  templateUrl: './signup-form.component.html',
  styleUrls: ['./signup-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignupFormComponent implements OnInit, OnDestroy {
  @Input()
  public inviteDetails$: Observable<InviteDetails>;

  @Input()
  public signUpError$: Observable<string>;

  @Output()
  public readonly signup: EventEmitter<RegistrationForm> = new EventEmitter<RegistrationForm>();

  public readonly form = new FormGroup({
    name: new FormControl('', CustomValidators.personName),
    email: new FormControl(''),
    password: new FormControl()
  });

  private readonly gc = new Subject<void>();

  constructor(private readonly cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.inviteDetails$
      .pipe(filter(Boolean), takeUntil(this.gc))
      .subscribe(({ email, name }: InviteDetails) => {
        this.form.patchValue({
          email,
          name
        });
        this.form.get('email').disable();
      });

    this.signUpError$
      .pipe(
        filter((msg: string | null) => msg?.toLowerCase().includes('email')),
        takeUntil(this.gc)
      )
      .subscribe(() => {
        this.form.controls.email.setErrors({ companyEmail: true });
        this.cdr.markForCheck();
      });
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }

  public onSignup(): void {
    this.signup.emit(this.form.getRawValue());
  }
}

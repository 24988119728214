import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn
} from '@angular/forms';
import { Observable, Subject, filter, takeUntil } from 'rxjs';
import { extractTouchedChanges } from '../../form';

export interface TextFilterControlOptions {
  readonly placeholder: string;
  readonly updateOnEnter: boolean;
  readonly pending: boolean;
  readonly validators: ValidatorFn[];
}

@Component({
  selector: 'share-text-filter-control',
  templateUrl: './text-filter-control.component.html',
  styleUrls: ['./text-filter-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TextFilterControlComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: TextFilterControlComponent,
      multi: true
    }
  ]
})
export class TextFilterControlComponent
  implements ControlValueAccessor, Validator, OnInit, OnDestroy
{
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('options')
  set userOptions(value: Partial<TextFilterControlOptions>) {
    this.options = {
      ...this.options,
      ...value
    };
  }

  @ViewChild('search', { read: ElementRef })
  private readonly searchInputRef: ElementRef<HTMLInputElement>;

  public readonly control = new FormControl<string>('');

  public valueChanges: Observable<string>;

  public options: Partial<TextFilterControlOptions> = {
    placeholder: 'Find an item',
    updateOnEnter: false,
    pending: false,
    validators: []
  };

  private onChange: (text: string) => void;
  private onTouched: () => void;

  private readonly valueSubject = new Subject<string>();
  private readonly gc = new Subject<void>();

  public ngOnInit(): void {
    if (this.options.validators) {
      this.control.addValidators(this.options.validators);
    }

    this.valueSubject
      .pipe(
        filter((value: string) => this.control.value !== value),
        takeUntil(this.gc)
      )
      .subscribe((value: string) => this.control.setValue(value));

    this.control.valueChanges
      .pipe(takeUntil(this.gc))
      .subscribe((value: string) => this.onChange?.(value?.trim().toLowerCase()));

    extractTouchedChanges(this.control)
      .pipe(
        filter((touched) => touched),
        takeUntil(this.gc)
      )
      .subscribe(() => this.onTouched?.());
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }

  public writeValue(value: string): void {
    this.valueSubject.next(value);
  }

  public registerOnChange(onChange: (query: string) => void): void {
    this.onChange = onChange;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  public validate(): ValidationErrors | null {
    return this.control.errors;
  }

  public focus(): void {
    this.searchInputRef.nativeElement.focus();
  }
}

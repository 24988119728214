<div
  *ngIf="{ section: (counters | settingsTabSectionStatus: error : visited) } as status"
  class="full-width section section-{{ section }}"
  fxLayout="row"
  fxLayoutAlign="space-between center">
  <span class="title">
    <span>{{ name }}</span>
    <span class="asterisk" aria-hidden="true">{{ counters?.required > 0 ? ' *' : '' }}</span>
  </span>

  <div class="counters status-{{ status.section }}" aria-hidden="true" fxLayout="row" fxLayoutAlign="end center">
    <span class="counters-label" element-data-id="label_counter">
      <ng-container *ngIf="counters?.required">
        {{ counters?.validRequired || 0 }}/{{ counters.required }}
      </ng-container>
    </span>

    <div class="status" fxLayout="row" [ngSwitch]="status.section">
      <mat-icon *ngSwitchCase="SectionStatus.PRISTINE_WARNING" class="icon-status"></mat-icon>
      <mat-icon *ngSwitchCase="SectionStatus.WARNING" class="icon-status">error</mat-icon>
      <mat-icon *ngSwitchCase="SectionStatus.SUCCESS" class="icon-status">check_circle</mat-icon>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import {
  ConfirmModalComponent,
  ConfirmModalData
} from '../components/confirm-modal/confirm-modal.component';

export const CONFIRM_DIALOG_PANEL_CLASS = 'confirm-dialog-panel';

@Injectable({
  providedIn: 'root'
})
export class ConfirmModalService {
  constructor(private readonly dialog: MatDialog) {}

  public confirm(data: ConfirmModalData | string): Observable<boolean> {
    return this.dialog
      .open<ConfirmModalComponent, Observable<ConfirmModalData>, boolean>(ConfirmModalComponent, {
        data: of(
          typeof data !== 'string'
            ? data
            : {
                message: data
              }
        ),
        disableClose: typeof data !== 'string' && data.prohibitCancel,
        role: 'alertdialog',
        panelClass: CONFIRM_DIALOG_PANEL_CLASS
      })
      .afterClosed();
  }
}

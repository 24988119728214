import { APP_INITIALIZER, NgModule } from '@angular/core';
import { ENVIRONMENT_TOKEN, Environment } from '@neuralegion/api';
import { SessionStorageService } from '@neuralegion/browser-storage';
import { DataIdDirective } from '../directives';
import {
  CssBasedLocation,
  ELEMENT_LOCATION_TOKEN,
  InvalidDataIdReporterService
} from '../services';

@NgModule({
  imports: [],
  declarations: [DataIdDirective],
  exports: [DataIdDirective],
  providers: [
    {
      provide: APP_INITIALIZER,
      deps: [ENVIRONMENT_TOKEN, InvalidDataIdReporterService, SessionStorageService],
      useFactory:
        (
          environment: Environment,
          invalidDataIdReporterService: InvalidDataIdReporterService,
          sessionStorageService: SessionStorageService
        ) =>
        (): void => {
          if (
            environment.invalidDataIdReporterEnabled ||
            sessionStorageService.get('invalidDataIdReporterEnabled')
          ) {
            invalidDataIdReporterService.init();
          }
        },

      multi: true
    },
    {
      provide: ELEMENT_LOCATION_TOKEN,
      useClass: CssBasedLocation
    }
  ]
})
export class DataIdsModule {}

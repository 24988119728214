import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'share-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginatorComponent {
  @Input()
  public disabled: boolean;

  @Input()
  public showFirstButton = true;

  @Input()
  public showLastButton = true;

  @Input()
  public pageIndex = 0;

  @Input()
  set pageSize(value: number) {
    // eslint-disable-next-line no-underscore-dangle
    this._pageSize = value ?? 0;
    this.calculateMaxPageIndex();
  }

  get pageSize(): number {
    // eslint-disable-next-line no-underscore-dangle
    return this._pageSize;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _pageSize = 0;

  @Input()
  set length(value: number) {
    // eslint-disable-next-line no-underscore-dangle
    this._length = value ?? 0;
    this.calculateMaxPageIndex();
  }

  get length(): number {
    // eslint-disable-next-line no-underscore-dangle
    return this._length;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _length = 0;

  @Output()
  public readonly pageChanged: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();

  public maxPageIndex = 0;

  public onPageButtonClick(pageIndex: number): void {
    this.pageChanged.emit({
      pageIndex,
      previousPageIndex: this.pageIndex,
      pageSize: this.pageSize,
      length: this.length
    });
  }

  private calculateMaxPageIndex(): void {
    if (this.length < 1 || this.pageSize < 1) {
      this.maxPageIndex = 0;
      return;
    }

    this.maxPageIndex = Math.ceil(this.length / this.pageSize) - 1;
  }
}

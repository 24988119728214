export enum Scope {
  ACTIVITIES = 'activities', // feed

  ADMIN_BILLINGS = 'admin.billings',
  ADMIN_ISSUE_TYPES = 'admin.issue-types',
  ADMIN_LOGS = 'admin.logs', // unused in ui
  ADMIN_ORGS = 'admin.orgs',
  ADMIN_ROLES = 'admin.roles', // unused in ui
  ADMIN_STATS = 'admin.stats',
  ADMIN_TAGS = 'admin.tags',
  ADMIN_TEMPLATES = 'admin.templates', // mail templates, unused in UI
  ADMIN_USERS = 'admin.users',

  API_KEYS = 'api-keys',

  AUTH_OBJECTS = 'auth-objects',
  AUTH_OBJECTS_READ = 'auth-objects:read',
  AUTH_OBJECTS_TEST = 'auth-objects:test',
  AUTH_OBJECTS_WRITE = 'auth-objects:write', // also for delete

  AUTH_PROVIDERS = 'auth-providers',

  BILLING = 'billing',

  BOT = 'bot', // not used

  COMMENTS = 'comments',
  COMMENTS_READ = 'comments:read',
  COMMENTS_WRITE = 'comments:write',

  DISCOVERIES = 'discoveries',
  DISCOVERIES_DELETE = 'discoveries:delete',
  DISCOVERIES_MANAGE = 'discoveries:manage',
  DISCOVERIES_READ = 'discoveries:read',
  DISCOVERIES_RUN = 'discoveries:run',
  DISCOVERIES_STOP = 'discoveries:stop',

  ENTRYPOINTS = 'entry-points',
  ENTRYPOINTS_MANAGE = 'entry-points:manage',
  ENTRYPOINTS_READ = 'entry-points:read',

  // FEEDS = 'feeds', // not used, legacy

  FILES_READ = 'files:read',
  FILES_WRITE = 'files:write',

  GROUPS_ADMIN = 'groups:admin',
  GROUPS_DELETE = 'groups:delete',
  GROUPS_MANAGE = 'groups:manage',
  GROUPS_READ = 'groups:read',

  INTEGRATIONS_READ = 'integrations:read',
  INTEGRATIONS_WRITE = 'integrations:write',
  INTEGRATION_REPOS_READ = 'integration.repos:read',
  INTEGRATION_REPOS_MANAGE = 'integration.repos:manage',

  ISSUES_MANAGE = 'issues:manage',
  ISSUES_READ = 'issues:read',

  LOGS = 'logs', // activity log

  ORG = 'org',
  ORG_READ = 'org:read',
  ORG_WRITE = 'org:write',

  ORG_API_KEYS = 'org.api-keys',

  ORG_LOGS = 'org.logs',

  ORG_MEMBERSHIPS_MANAGE = 'org.memberships:manage',
  ORG_MEMBERSHIPS_READ = 'org.memberships:read',

  ORG_SCANS_TEMPLATES = 'org.scans-templates',

  PAYMENTS = 'payments',
  PAYMENT_METHODS = 'payment-methods',
  PLANS = 'plans', // unused
  PRODUCTS = 'products',

  PROJECTS_ADMIN = 'projects:admin',
  PROJECT_API_KEYS = 'project.api-keys',
  PROJECTS_DELETE = 'projects:delete',
  PROJECTS_CREATE = 'projects:create',
  PROJECTS_EDIT = 'projects:edit',
  PROJECTS_READ = 'projects:read',
  PROJECT_ISSUES_WRITE = 'project-issues:write',

  REPEATERS_READ = 'repeaters:read',
  REPEATERS_WRITE = 'repeaters:write',

  REPORTS_READ = 'reports:read', // pdf report configuration read
  REPORTS_WRITE = 'reports:write', // pdf report configuration write

  ROLES_WRITE = 'roles:write',
  ROLES_READ = 'roles:read',

  SCANS = 'scans',
  SCANS_DELETE = 'scans:delete',
  SCANS_MANAGE = 'scans:manage',
  SCANS_READ = 'scans:read',
  SCANS_RUN = 'scans:run',
  SCANS_STOP = 'scans:stop',

  SCAN_LABELS_MANAGE = 'scan-labels:manage',

  SCANS_TEMPLATES = 'scans-templates',
  SCANS_TEMPLATES_READ = 'scans-templates:read',
  SCANS_TEMPLATES_WRITE = 'scans-templates:write',

  SCIM = 'scim', // not used

  SCRIPTS_READ = 'scripts:read',
  SCRIPTS_WRITE = 'scripts:write',

  SUBSCRIPTIONS = 'subscriptions', // unused billing module functionality

  USER = 'user', // basic, skipped
  USER_READ = 'user:read', // basic, skipped
  USER_WRITE = 'user:write' // basic, skipped
}

export const SUPER_USER_SCOPES: ReadonlySet<Scope> = new Set<Scope>([
  Scope.ADMIN_BILLINGS,
  Scope.ADMIN_ISSUE_TYPES,
  Scope.ADMIN_LOGS,
  Scope.ADMIN_ORGS,
  Scope.ADMIN_ROLES,
  Scope.ADMIN_STATS,
  Scope.ADMIN_TAGS,
  Scope.ADMIN_TEMPLATES,
  Scope.ADMIN_USERS
]);

export const ALLOWED_SCOPES: ReadonlySet<Scope> = new Set<Scope>(
  Object.values(Scope).filter(
    (x: Scope) => !SUPER_USER_SCOPES.has(x) && x !== Scope.BOT && x !== Scope.SCIM
  )
);

const API_KEY_SCOPES: ReadonlySet<Scope> = new Set<Scope>([
  Scope.BOT,
  Scope.DISCOVERIES,
  Scope.DISCOVERIES_DELETE,
  Scope.DISCOVERIES_MANAGE,
  Scope.DISCOVERIES_READ,
  Scope.DISCOVERIES_RUN,
  Scope.DISCOVERIES_STOP,
  Scope.ENTRYPOINTS,
  Scope.ENTRYPOINTS_MANAGE,
  Scope.ENTRYPOINTS_READ,
  Scope.FILES_READ,
  Scope.FILES_WRITE,
  Scope.GROUPS_DELETE,
  Scope.GROUPS_MANAGE,
  Scope.GROUPS_READ,
  Scope.INTEGRATION_REPOS_READ,
  Scope.ISSUES_MANAGE,
  Scope.ISSUES_READ,
  Scope.ORG_MEMBERSHIPS_MANAGE,
  Scope.ORG_MEMBERSHIPS_READ,
  Scope.ORG_READ,
  Scope.ORG_WRITE,
  Scope.PROJECTS_DELETE,
  Scope.PROJECTS_CREATE,
  Scope.PROJECTS_EDIT,
  Scope.PROJECTS_READ,
  Scope.PROJECT_ISSUES_WRITE,
  Scope.REPEATERS_READ,
  Scope.REPEATERS_WRITE,
  Scope.ROLES_READ,
  Scope.ROLES_WRITE,
  Scope.SCANS,
  Scope.SCANS_DELETE,
  Scope.SCANS_MANAGE,
  Scope.SCANS_READ,
  Scope.SCANS_RUN,
  Scope.SCANS_STOP,
  Scope.SCAN_LABELS_MANAGE,
  Scope.SCRIPTS_READ,
  Scope.SCRIPTS_WRITE
]);

export const ORG_API_KEY_SCOPES: ReadonlySet<Scope> = new Set<Scope>([
  ...API_KEY_SCOPES,
  Scope.SCIM,
  Scope.GROUPS_ADMIN,
  Scope.SCANS_TEMPLATES,
  Scope.SCANS_TEMPLATES_READ,
  Scope.SCANS_TEMPLATES_WRITE,
  Scope.ORG_SCANS_TEMPLATES,
  Scope.ORG_LOGS
]);

export const USER_API_KEY_SCOPES: ReadonlySet<Scope> = new Set<Scope>([
  ...API_KEY_SCOPES,
  Scope.AUTH_OBJECTS_TEST,
  Scope.AUTH_OBJECTS_READ,
  Scope.AUTH_OBJECTS_WRITE,
  Scope.COMMENTS,
  Scope.COMMENTS_READ,
  Scope.COMMENTS_WRITE,
  Scope.USER,
  Scope.USER_READ,
  Scope.USER_WRITE,
  Scope.LOGS,
  Scope.ORG_LOGS,
  Scope.SCANS_TEMPLATES,
  Scope.SCANS_TEMPLATES_READ,
  Scope.SCANS_TEMPLATES_WRITE
]);

export const PROJECT_API_KEY_SCOPES: ReadonlySet<Scope> = new Set<Scope>([
  Scope.AUTH_OBJECTS_READ,
  Scope.AUTH_OBJECTS_TEST,
  Scope.AUTH_OBJECTS_WRITE,
  Scope.BOT,
  Scope.DISCOVERIES,
  Scope.DISCOVERIES_READ,
  Scope.ENTRYPOINTS,
  Scope.ENTRYPOINTS_MANAGE,
  Scope.ENTRYPOINTS_READ,
  Scope.FILES_READ,
  Scope.FILES_WRITE,
  Scope.INTEGRATION_REPOS_READ,
  Scope.ISSUES_MANAGE,
  Scope.ISSUES_READ,
  Scope.REPEATERS_READ,
  Scope.REPEATERS_WRITE,
  Scope.SCANS,
  Scope.SCANS_DELETE,
  Scope.SCANS_MANAGE,
  Scope.SCANS_READ,
  Scope.SCANS_RUN,
  Scope.SCANS_STOP,
  Scope.SCAN_LABELS_MANAGE,
  Scope.SCRIPTS_READ,
  Scope.SCRIPTS_WRITE,
  Scope.PROJECTS_EDIT,
  Scope.PROJECTS_READ,
  Scope.PROJECT_ISSUES_WRITE
]);

export const DEV_CENTRIC_SCOPES: ReadonlySet<Scope> = new Set<Scope>([
  Scope.BOT,
  Scope.AUTH_OBJECTS_READ,
  Scope.AUTH_OBJECTS_TEST,
  Scope.ENTRYPOINTS_READ,
  Scope.ENTRYPOINTS_MANAGE,
  Scope.FILES_READ,
  Scope.FILES_WRITE,
  Scope.ISSUES_READ,
  Scope.PROJECTS_READ,
  Scope.REPEATERS_READ,
  Scope.REPEATERS_WRITE,
  Scope.SCAN_LABELS_MANAGE,
  Scope.SCANS_READ,
  Scope.SCANS_MANAGE,
  Scope.SCANS_RUN,
  Scope.SCANS_STOP
]);

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { FILE_UPLOAD_CONFIG_TOKEN, FileUploadConfig } from '@neuralegion/api';
import { FileService } from '@neuralegion/core';

@Component({
  selector: 'share-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: InputFileComponent, multi: true },
    { provide: NG_VALIDATORS, useExisting: InputFileComponent, multi: true }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputFileComponent implements ControlValueAccessor, Validator {
  @Input()
  set acceptedExtensions(value: string[]) {
    this.acceptedExts = value;
    this.accept = this.fileService.formatAcceptString(this.acceptedExts);
  }

  @Input()
  public multiple = false;

  public accept: string;

  @ViewChild('fileInput', { static: true })
  private readonly fileInputRef: ElementRef<HTMLInputElement>;

  private acceptedExts: string[] = [];
  private readonly maxUploadSize: number;

  private onChange: (value: File | File[]) => void;
  private onTouched: () => void;

  get fileInputElement(): HTMLInputElement {
    return this.fileInputRef?.nativeElement;
  }

  constructor(
    private readonly fileService: FileService,
    @Inject(FILE_UPLOAD_CONFIG_TOKEN) private readonly fileUploadConfig: FileUploadConfig
  ) {
    this.maxUploadSize = this.fileUploadConfig.maxUploadSize;
  }

  public registerOnChange(fn: (value: File | File[]) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(disabled: boolean): void {
    this.fileInputElement.disabled = disabled;
  }

  public writeValue(): void {
    this.fileInputElement.value = '';
  }

  public validate(_control: AbstractControl): ValidationErrors | null {
    return [...this.fileInputElement.files].every((file) => file.size <= this.maxUploadSize)
      ? null
      : { maxFileSize: true };
  }

  @HostListener('click')
  public openFileSelectionDialog(): void {
    this.onTouched();
    if (this.fileInputElement && !this.fileInputElement.disabled) {
      this.fileInputElement.focus();
      this.fileInputElement.click();
    }
  }

  public onFileSelect(): void {
    const fileList: FileList = this.fileInputElement.files;

    const acceptedFiles = this.fileService.filterAccepted([...fileList], this.acceptedExts);
    if (acceptedFiles.length) {
      this.onChange(this.multiple ? acceptedFiles : acceptedFiles[0]);
    }
    this.fileInputElement.value = '';
  }
}

import { Action } from '@ngrx/store';
import { User } from '@neuralegion/api';

export interface ProfileState {
  error: string | null;
  pending: Action[];
  entity: User;
}

export const initialProfileState: ProfileState = {
  error: null,
  pending: [],
  entity: null
};

import { Directive, Inject, OnInit, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { STATE_VALUE_ACCESSOR, StateValueAccessor } from '../models';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[formControlName], [formControl]'
})
export class FormStatusDirective implements OnInit {
  constructor(
    @Self() private readonly control: NgControl,
    @Self()
    @Optional()
    @Inject(STATE_VALUE_ACCESSOR)
    private readonly stateValueAccessor: StateValueAccessor
  ) {}

  public ngOnInit(): void {
    if (this.stateValueAccessor?.setTouchedState && this.control?.control) {
      const prevMarkAsTouched = this.control.control.markAsTouched;
      const prevMarkAsUntouched = this.control.control.markAsUntouched;

      this.control.control.markAsTouched = (...args) => {
        this.stateValueAccessor.setTouchedState(true);
        prevMarkAsTouched.apply(this.control.control, args);
      };

      this.control.control.markAsUntouched = (...args) => {
        this.stateValueAccessor.setTouchedState(false);
        prevMarkAsUntouched.apply(this.control.control, args);
      };
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { TimeFormat } from '@neuralegion/api';

@Pipe({
  name: 'timeFormatLabel',
  pure: true
})
export class TimeFormatLabelPipe implements PipeTransform {
  public transform(timeFormat: TimeFormat): string {
    switch (timeFormat) {
      case TimeFormat.HOUR12:
        return '12-hour';
      case TimeFormat.HOUR24:
        return '24-hour';
    }
  }
}

<mat-card>
  <mat-card-header>
    <mat-card-title role="heading" aria-level="1">Sign in</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <auth-oauth-providers-list (selected)="onOauthLogin($event)"></auth-oauth-providers-list>

    <div class="signin-option">Or use your email</div>

    <auth-login-form
      [enableCaptcha]="enableCaptcha$ | async"
      [pending]="pending$ | async"
      [siteKey]="siteKey"
      (submitted)="login($event)">
    </auth-login-form>

    <div class="signin-option">Or use your organization's</div>
    <button type="button" mat-raised-button (click)="onSso()">Single sign-on (SSO)</button>

    <div class="sso-icons" fxLayout="row" fxLayoutAlign="space-around center">
      <mat-icon *ngFor="let provider of ENTERPRISE_PROVIDERS; trackBy: trackByIdentity" [svgIcon]="provider">
      </mat-icon>
    </div>
  </mat-card-content>
</mat-card>

import { DOCUMENT } from '@angular/common';
import {
  Directive,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  NgZone,
  OnDestroy
} from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { Subject, filter, fromEvent, takeUntil, tap } from 'rxjs';

enum MouseButton {
  LEFT = 0,
  MIDDLE = 1
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'tr[routerLink], mat-row[routerLink]'
})
export class TableRowLinkDirective implements OnDestroy {
  @Input()
  public forceOpenInNewTab = false;

  @HostBinding('class.focus-ring')
  public focusable = true;

  private readonly window = this.document.defaultView;

  private readonly gc = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly router: Router,
    private readonly ref: ElementRef<HTMLElement>,
    private readonly zone: NgZone,
    private readonly routerLink: RouterLink
  ) {
    this.zone.runOutsideAngular(() => {
      this.initMouseEventsListener();
      this.initKeyboardEventsListener();
    });
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }

  private initMouseEventsListener(): void {
    const { nativeElement: rootEl } = this.ref;

    fromEvent<MouseEvent>(rootEl, 'click')
      .pipe(
        tap((event) => event.stopImmediatePropagation()),
        filter((event) => !this.shouldOpenInNewWindow(event)),
        takeUntil(this.gc)
      )
      .subscribe(() => this.openInCurrentWindow());

    fromEvent<MouseEvent>(rootEl, 'mousedown')
      .pipe(
        filter((event) => this.shouldOpenInNewWindow(event)),
        takeUntil(this.gc)
      )
      .subscribe((event) => {
        event.stopImmediatePropagation();
        this.openInNewWindow();
      });
  }

  private initKeyboardEventsListener(): void {
    const { nativeElement: rootEl } = this.ref;

    fromEvent<KeyboardEvent>(rootEl, 'keydown')
      .pipe(
        filter(({ code }) => code === 'Space' || code === 'Enter'),
        filter(({ target }) => target === rootEl),
        takeUntil(this.gc)
      )
      .subscribe((event) => {
        event.preventDefault();
        if (this.isCommandButtonPressed(event) || this.forceOpenInNewTab) {
          this.openInNewWindow();
        } else {
          this.openInCurrentWindow();
        }
      });
  }

  private openInCurrentWindow(): void {
    this.zone.run(() => {
      const extras = {
        skipLocationChange: this.routerLink.skipLocationChange,
        replaceUrl: this.routerLink.replaceUrl,
        state: this.routerLink.state
      };
      void this.router.navigateByUrl(this.routerLink.urlTree, extras);
    });
  }

  private openInNewWindow(): void {
    const urlTree = this.routerLink.urlTree;
    if (urlTree !== null) {
      this.window.open(this.router.serializeUrl(urlTree), '_blank');
    }
  }

  private shouldOpenInNewWindow(event: MouseEvent): boolean {
    return (
      (event.button as MouseButton) === MouseButton.MIDDLE ||
      ((event.button as MouseButton) === MouseButton.LEFT && this.isCommandButtonPressed(event)) ||
      this.forceOpenInNewTab
    );
  }

  private isCommandButtonPressed(event: MouseEvent | KeyboardEvent): boolean {
    return event.metaKey || event.ctrlKey;
  }
}

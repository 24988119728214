import { AbstractControl, FormArray } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { uuid } from '@neuralegion/lang';

export class SettingsSectionFormArrayProxy {
  public readonly controlIds$: Observable<string[]>;

  private readonly controlIdsSubject = new BehaviorSubject<string[]>([]);
  private readonly formArray: FormArray;

  constructor(formArray: FormArray) {
    this.formArray = formArray;
    this.controlIds$ = this.controlIdsSubject.asObservable();
  }

  public push(pushControlFn: (newControlId: string) => AbstractControl): void {
    const newControlId = uuid().slice(0, 5);
    this.controlIdsSubject.next([...this.controlIdsSubject.value, newControlId]);

    this.formArray.push(pushControlFn(newControlId));
  }

  public at(index: number): string {
    return this.controlIdsSubject.value[index];
  }

  public clear(): void {
    this.formArray.clear();
    this.controlIdsSubject.next([]);
  }

  public removeAt(index: number): void {
    this.formArray.removeAt(index);

    const nextControlIds = [...this.controlIdsSubject.value];
    nextControlIds.splice(index, 1);
    this.controlIdsSubject.next(nextControlIds);
  }

  public move(fromIndex: number, toIndex: number): void {
    const nextControlIds = [...this.controlIdsSubject.value];
    const controlIdToMove = nextControlIds[fromIndex];
    const controlToMove = this.formArray.at(fromIndex);

    const direction = toIndex > fromIndex ? 1 : -1;
    for (let i = fromIndex; i * direction < toIndex * direction; i = i + direction) {
      this.formArray.setControl(i, this.formArray.at(i + direction));
      nextControlIds[i] = nextControlIds[i + direction];
    }

    this.formArray.setControl(toIndex, controlToMove);
    nextControlIds[toIndex] = controlIdToMove;

    this.controlIdsSubject.next(nextControlIds);
  }
}

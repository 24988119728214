import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { InviteDetails } from '@neuralegion/api';
import {
  RegistrationForm,
  loadInviteDetails,
  selectInviteDetails,
  selectSignupError,
  selectSignupPendingStatus,
  signup
} from '@neuralegion/auth-api';

// eslint-disable-next-line @angular-eslint/use-component-selector
@Component({
  styleUrls: ['./signup-register-page.component.scss'],
  templateUrl: './signup-register-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignupRegisterPageComponent implements OnInit {
  private readonly memberId: string;
  public readonly token: string;

  public pending$: Observable<boolean>;
  public signUpError$: Observable<string | null>;
  public inviteDetails$: Observable<InviteDetails>;

  constructor(
    private readonly store: Store,
    route: ActivatedRoute
  ) {
    this.memberId = route.snapshot.paramMap.get('memberId');
    this.token = route.snapshot.paramMap.get('token');
  }

  public ngOnInit(): void {
    this.pending$ = this.store.select(selectSignupPendingStatus);
    this.signUpError$ = this.store.select(selectSignupError);
    this.inviteDetails$ = this.store.select(selectInviteDetails);

    if (this.memberId && this.token) {
      this.store.dispatch(loadInviteDetails({ memberId: this.memberId, token: this.token }));
    }
  }

  public onSignup(formData: RegistrationForm): void {
    this.store.dispatch(signup(formData));
  }
}

import { BaseElementIdStrategy } from './base-element-id-strategy';

export class NoteElementIdStrategy extends BaseElementIdStrategy {
  private readonly elementPrefix = 'note';

  protected override getSubId(element: HTMLElement): string {
    return [this.elementPrefix, element.querySelector('[role="alert"]') ? 'error' : 'notice'].join(
      this.SEPARATOR_INTERNAL
    );
  }
}

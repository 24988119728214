export enum FeedVerb {
  ASSIGN = 'assign',
  CLOSE = 'close',
  CREATE = 'create',
  DEACTIVATE = 'deactivate',
  DISABLE = 'disable',
  DISRUPT = 'disrupt',
  ENABLE = 'enable',
  FAIL = 'fail',
  FINISH = 'finish',
  OPEN = 'open',
  REMOVE = 'remove',
  REOPEN = 'reopen',
  SCHEDULE = 'schedule',
  START = 'start',
  STOP = 'stop',
  SUBSCRIBE = 'subscribe',
  UNASSIGN = 'unassign',
  UNSUBSCRIBE = 'unsubscribe',
  UPDATE = 'update',
  UPLOAD = 'upload'
}

export const sortedFeedVerbs = Object.values(FeedVerb).sort(
  (verb1: FeedVerb, verb2: FeedVerb): number => verb1.localeCompare(verb2)
);

import { Directive, OnDestroy, ViewChild, inject } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import {
  BehaviorSubject,
  Observable,
  Subject,
  distinctUntilChanged,
  filter,
  takeUntil
} from 'rxjs';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_TOKEN } from '@neuralegion/api';
import { TableSettingsService } from '@neuralegion/browser-storage';
import { SortableTableComponent } from './sortable-table-component.directive';

@Directive()
export abstract class PaginatedTableComponent extends SortableTableComponent implements OnDestroy {
  private readonly defaultPageSize =
    inject(DEFAULT_PAGE_SIZE_TOKEN, { optional: true }) ?? DEFAULT_PAGE_SIZE;

  protected readonly pageSizeSubject = new BehaviorSubject(this.defaultPageSize);
  public readonly pageSize$: Observable<number> = this.pageSizeSubject.asObservable();

  @ViewChild(MatPaginator)
  protected readonly paginator: MatPaginator;

  private onPageSizeChange: (pageSize?: number) => void = this.updatePaginatorPageSize;

  protected readonly gc = new Subject<void>();

  protected constructor(tableSettingsService: TableSettingsService) {
    super(tableSettingsService);
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }

  public pageQuery(e: PageEvent): void {
    this.tableSettingsService.savePageParams(this.tableName, e);
  }

  public setPageSize(pageSize: number): void {
    this.pageSizeSubject.next(pageSize);
  }

  public resetTableSettings(): void {
    this.setPageSize(this.defaultPageSize);
  }

  protected setOnPageSizeChange(fn: (pageSize?: number) => void): void {
    this.onPageSizeChange = fn;
  }

  protected initTable(): void {
    this.initPageSizeSettingsListener();
  }

  protected applyPageIndex(): void {
    this.tableSettingsService.applyPageIndex(this.tableName, this.paginator);
  }

  protected applySessionTableParams(withPaginator = true): void {
    if (withPaginator) {
      this.tableSettingsService.applyPageSize(this.tableName, this.paginator);
    }

    this.setPageSize(this.paginator?.pageSize || this.defaultPageSize);
  }

  private initPageSizeSettingsListener(): void {
    this.pageSizeSubject
      .pipe(filter(Boolean), distinctUntilChanged(), takeUntil(this.gc))
      .subscribe((pageSize: number) => {
        this.onPageSizeChange(pageSize);
      });
  }

  private updatePaginatorPageSize(pageSize?: number): void {
    if (pageSize == null || !this.paginator || this.paginator.pageSize === pageSize) {
      return;
    }

    // Current page needs to be updated to reflect the new page size. Navigate to the page
    // containing the previous page's first item.
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    const previousPageIndex = this.paginator.pageIndex;

    this.paginator.pageIndex = Math.floor(startIndex / pageSize) || 0;
    this.paginator.pageSize = pageSize;

    this.paginator.page.emit({
      previousPageIndex,
      pageSize: this.paginator.pageSize,
      length: this.paginator.length,
      pageIndex: this.paginator.pageIndex
    });
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, exhaustMap, filter, map, of, tap } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { User } from '@neuralegion/api';
import { oauthDisconnectSuccess } from '@neuralegion/auth-api';
import { disableMfaSuccess, enableMfaSuccess } from '@neuralegion/multi-factor-api';
import { ProfileService } from '../services';
import {
  changePassword,
  changePasswordFail,
  changePasswordSuccess,
  loadProfile,
  loadProfileFail,
  loadProfileSuccess,
  setPassword,
  setPasswordFail,
  setPasswordSuccess,
  updateProfile,
  updateProfileFail,
  updateProfileSuccess
} from './profile.actions';
import { loadSessions } from './sessions.actions';

@Injectable()
export class ProfileEffects {
  public readonly changePassword$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(changePassword),
      exhaustMap((action: ReturnType<typeof changePassword>) =>
        this.profileService.changePassword(action.payload).pipe(
          map(() => changePasswordSuccess()),
          catchError((err: HttpErrorResponse) => of(changePasswordFail(err.error)))
        )
      )
    )
  );

  public readonly loadProfile$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadProfile),
      exhaustMap(() =>
        this.profileService.loadProfile().pipe(
          map((profile: User) => loadProfileSuccess(profile)),
          catchError((err: HttpErrorResponse) => of(loadProfileFail(err.error)))
        )
      )
    )
  );

  public readonly setPassword$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(setPassword),
      exhaustMap((action: ReturnType<typeof setPassword>) =>
        this.profileService.setPassword(action.payload).pipe(
          map(() => setPasswordSuccess()),
          catchError((err: HttpErrorResponse) => of(setPasswordFail(err.error)))
        )
      )
    )
  );

  public readonly updateProfile$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(updateProfile),
      exhaustMap((action: ReturnType<typeof updateProfile>) =>
        this.profileService.updateProfile(action.payload).pipe(
          map(() => updateProfileSuccess()),
          catchError((err: HttpErrorResponse) => of(updateProfileFail(err.error)))
        )
      )
    )
  );

  public readonly reloadProfilePage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<Action>(
        updateProfileSuccess,
        enableMfaSuccess,
        disableMfaSuccess,
        oauthDisconnectSuccess
      ),
      filter(() => this.router.url === '/profile'),
      map(() => loadProfile())
    )
  );

  public readonly loadRelated$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadProfileSuccess),
      exhaustMap(() => [loadSessions()])
    )
  );

  public readonly changePasswordSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(changePasswordSuccess),
        tap(() => this.router.navigate(['/profile']))
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly profileService: ProfileService
  ) {}
}

import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { PaymentMethod } from '../models';

export interface PaymentMethodsState extends EntityState<PaymentMethod> {
  pending: Action[];
  error: string;
}

export const paymentMethodsEntityAdapter: EntityAdapter<PaymentMethod> =
  createEntityAdapter<PaymentMethod>({
    selectId: (model) => model.id
  });

export const initialPaymentMethodsState: PaymentMethodsState =
  paymentMethodsEntityAdapter.getInitialState({
    pending: [],
    error: null
  });

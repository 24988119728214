import { ElementLocation } from './element-location';

// based on https://github.com/mozilla/gecko-dev/blob/master/devtools/shared/inspector/css-logic.js

export class CssBasedLocation implements ElementLocation {
  public getElementLocation(el: Element): string {
    const doc = el.ownerDocument;

    const cssEscape = CSS.escape;

    // document.querySelectorAll("#id") returns multiple if elements share an ID
    if (el.id && doc.querySelectorAll(`#${cssEscape(el.id)}`).length === 1) {
      return `#${cssEscape(el.id)}`;
    }

    // Inherently unique by tag name
    const tagName = el.localName;
    if (tagName === 'html') {
      return 'html';
    }
    if (tagName === 'head') {
      return 'head';
    }
    if (tagName === 'body') {
      return 'body';
    }

    // We might be able to find a unique class name
    for (let i = 0; i < el.classList.length; i++) {
      // Is this className unique by itself?
      let selector = `.${cssEscape(el.classList.item(i))}`;
      let matches = doc.querySelectorAll(selector);
      if (matches.length === 1) {
        return selector;
      }

      // Maybe it's unique with a tag name?
      selector = `${cssEscape(tagName)}${selector}`;
      matches = doc.querySelectorAll(selector);
      if (matches.length === 1) {
        return selector;
      }

      // Maybe it's unique using a tag name and nth-child
      const index = this.positionInNodeList(el, el.parentNode.children) + 1;
      selector = `${selector}:nth-child(${index})`;
      matches = doc.querySelectorAll(selector);
      if (matches.length === 1) {
        return selector;
      }
    }

    // Not unique enough yet
    const index = this.positionInNodeList(el, el.parentNode.children) + 1;
    let selector = `${cssEscape(tagName)}:nth-child(${index})`;
    if (el.parentNode !== doc) {
      selector = `${this.getElementLocation(el.parentNode as Element)} > ${selector}`;
    }
    return selector;
  }

  private positionInNodeList(element: Element, nodeList: HTMLCollection) {
    for (let i = 0; i < nodeList.length; i++) {
      if (element === nodeList[i]) {
        return i;
      }
    }
    return -1;
  }
}

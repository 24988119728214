import { Injectable } from '@angular/core';
import { CodeEscaperService } from './code-escaper.service';
import { DiffBlock } from './code-hex-diff.service';

export enum HexFormatPart {
  LABEL = 'LABEL',
  CODES = 'CODES',
  CONTENT = 'CONTENT'
}

export type HexFormatResult = {
  [HexFormatPart.LABEL]: string;
  [HexFormatPart.CODES]: string;
  [HexFormatPart.CONTENT]: string;
  extraBlocks?: DiffBlock[];
};

@Injectable({
  providedIn: 'root'
})
export class CodeHexLinesService {
  private readonly hexLineLength = 16;

  constructor(private readonly codeEscaperService: CodeEscaperService) {}

  public isPrintable(code: string): boolean {
    // eslint-disable-next-line no-control-regex
    return code.charCodeAt(0) < 255 && !/[\x00-\x20\x7F\x80-\x9F]/g.test(code);
  }

  public formatLabel(offset: number): string {
    return `${offset.toString(16).padStart(8, '0')}:`;
  }

  public formatByte(byte: string): string {
    return Math.min(byte.charCodeAt(0), 255).toString(16).toUpperCase().padStart(2, '0');
  }

  private *linesGenerator(code: string): Generator<HexFormatResult> {
    let idx = 0;
    const contentMapper = (ch: string): string =>
      !this.isPrintable(ch) ? '.' : this.codeEscaperService.escapeHtml(ch);

    if (!code) {
      return;
    }

    while (idx < code.length) {
      const startIdx = idx;
      const len = Math.min(code.length - startIdx, this.hexLineLength);
      idx += this.hexLineLength;

      yield {
        [HexFormatPart.LABEL]: this.formatLabel(startIdx),
        [HexFormatPart.CODES]: [...code.substring(startIdx, startIdx + len)]
          .map(this.formatByte)
          .join(' ')
          .padEnd(this.hexLineLength * 3, ' '),
        [HexFormatPart.CONTENT]: [...code.substring(startIdx, startIdx + len)]
          .map(contentMapper)
          .join('')
          .padEnd(this.hexLineLength, ' ')
      };
    }
  }

  public toHexLines(code: string): HexFormatResult[] {
    const lines: HexFormatResult[] = [];
    for (const line of this.linesGenerator(code)) {
      lines.push(line);
    }
    return lines;
  }
}

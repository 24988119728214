import { InteractivityChecker } from '@angular/cdk/a11y';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AccessibilityService {
  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly interactivityChecker: InteractivityChecker
  ) {}

  public getFirstTabbableElement(root: HTMLElement): HTMLElement | null {
    if (this.interactivityChecker.isFocusable(root) && this.interactivityChecker.isTabbable(root)) {
      return root;
    }

    const children = root.children;

    for (let i = 0; i < children.length; i++) {
      const tabbableChild =
        children[i].nodeType === this.document.ELEMENT_NODE
          ? this.getFirstTabbableElement(children[i] as HTMLElement)
          : null;

      if (tabbableChild) {
        return tabbableChild;
      }
    }

    return null;
  }
}

import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, map } from 'rxjs';
import { Store } from '@ngrx/store';
import { trackLogin } from '@neuralegion/analytics-api';
import {
  AuthenticationCredentials,
  CAPTCHA_CONFIG_TOKEN,
  CaptchaConfig,
  ENTERPRISE_PROVIDERS,
  ProviderType
} from '@neuralegion/api';
import { login, selectAccessFailedCount, selectAuthPendingStatus } from '@neuralegion/auth-api';
import { SnackbarService, deactivateLayout, trackByIdentity } from '@neuralegion/core';

// eslint-disable-next-line @angular-eslint/use-component-selector
@Component({
  styleUrls: ['./login-page.component.scss'],
  templateUrl: './login-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginPageComponent implements OnInit {
  public readonly trackByIdentity = trackByIdentity;
  public readonly ENTERPRISE_PROVIDERS = ENTERPRISE_PROVIDERS;

  public pending$: Observable<boolean>;
  public enableCaptcha$: Observable<boolean>;

  get siteKey(): string {
    return this.captchaConfig.reCaptchaSiteKey;
  }

  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly snackbarService: SnackbarService,
    private readonly sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(CAPTCHA_CONFIG_TOKEN) private readonly captchaConfig: CaptchaConfig
  ) {}

  public ngOnInit(): void {
    this.enableCaptcha$ = this.store
      .select(selectAccessFailedCount)
      .pipe(
        map(
          (accessFailedCount: number) =>
            accessFailedCount >= this.captchaConfig.maxFailedAccessAttempts
        )
      );

    this.pending$ = this.store.select(selectAuthPendingStatus);

    const slo = this.activatedRoute.snapshot.queryParamMap.get('slo');
    if (slo === 'true') {
      this.snackbarService.open('You have been successfully logged out');
    } else if (slo?.length) {
      this.snackbarService.open(
        `An error occurred during single logout: ${this.sanitizer.sanitize(
          SecurityContext.HTML,
          slo
        )}`
      );
    }
  }

  public onSso(): void {
    void this.router.navigate(['/sso']);
  }

  public onOauthLogin(providerType: ProviderType): void {
    this.store.dispatch(trackLogin({ type: 'social', provider: providerType }));

    this.store.dispatch(deactivateLayout());
    setTimeout(
      () =>
        (this.document.location.href = `${this.document.location.origin}/api/v1/auth/login/${providerType}`),
      0
    );
  }

  public login(event: AuthenticationCredentials): void {
    return this.store.dispatch(login(event));
  }
}

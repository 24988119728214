import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';
import { TreeComponent } from '@ali-hm/angular-tree-component';

@Directive({
  selector: '[shareTreeSizeUpdate]'
})
export class TreeSizeUpdateDirective implements AfterViewInit, OnDestroy {
  private intersectionObserver: IntersectionObserver;

  constructor(
    private readonly treeElement: ElementRef,
    private readonly treeComponent: TreeComponent
  ) {}

  public ngAfterViewInit(): void {
    this.intersectionObserver = new IntersectionObserver(
      (entries, observer) => {
        if (entries[0].isIntersecting) {
          observer.disconnect();
          this.treeComponent.sizeChanged();
        }
      },
      {
        root: this.treeElement.nativeElement.parentElement
      }
    );
    this.intersectionObserver.observe(this.treeElement.nativeElement);
  }

  public ngOnDestroy(): void {
    this.intersectionObserver.disconnect();
  }
}

import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { Role } from '@neuralegion/api';

export interface RolesState extends EntityState<Role> {
  error: string;
  pending: Action[];
}

export const rolesEntityAdapter: EntityAdapter<Role> = createEntityAdapter<Role>({
  selectId: (model) => model.id
});

export const rolesInitialState: RolesState = rolesEntityAdapter.getInitialState({
  error: null,
  pending: []
});

import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable, map } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectOwnMemberId } from '../store';

@Injectable()
export class WizardGuard {
  constructor(
    private readonly router: Router,
    private readonly store: Store
  ) {}

  public canActivate(): Observable<boolean | UrlTree> {
    return this.store
      .select(selectOwnMemberId)
      .pipe(map((ownMemberId: string) => (ownMemberId ? true : this.router.parseUrl('/'))));
  }
}

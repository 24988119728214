import { Action, createReducer, on } from '@ngrx/store';
import { cleanupPendingState } from '@neuralegion/core';
import {
  billingRefresh,
  loadProducts,
  loadProductsFail,
  loadProductsSuccess,
  loadSummary,
  loadSummaryFail,
  loadSummarySuccess,
  saveContactInfo,
  saveContactInfoFail,
  saveContactInfoSuccess
} from './billing.actions';
import { BillingState, initialBillingState } from './billing.state';

export const billingReducer = createReducer<BillingState, Action>(
  initialBillingState,
  on(billingRefresh, (state: BillingState): BillingState => state),
  on(
    loadProducts,
    loadSummary,
    saveContactInfo,
    (state: BillingState, action): BillingState => ({
      ...state,
      pending: [...state.pending, action],
      error: null
    })
  ),
  on(
    loadProductsSuccess,
    (state: BillingState, action): BillingState =>
      cleanupPendingState({ ...state, products: action.payload.products }, action)
  ),
  on(
    loadSummarySuccess,
    (state: BillingState, action): BillingState =>
      cleanupPendingState({ ...state, summary: action.payload.summary }, action)
  ),
  on(
    saveContactInfoSuccess,
    (state: BillingState, action): BillingState => cleanupPendingState(state, action)
  ),
  on(
    loadProductsFail,
    loadSummaryFail,
    saveContactInfoFail,
    (state: BillingState, action): BillingState =>
      cleanupPendingState(state, action, action.payload)
  )
);

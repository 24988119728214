<div
  [attr.aria-atomic]="type === 'error' ? 'true' : null"
  [attr.aria-live]="type === 'error' ? 'polite' : null"
  [attr.aria-relevant]="type === 'error' ? 'all' : null"
  [attr.role]="type === 'error' ? 'alert' : null"
  fxLayout="column"
  fxLayout.lt-md="row"
  fxLayoutAlign.lt-md="start center"
  fxLayoutGap="4px"
  [ngClass]="['content-wrapper', type]">
  <div *ngIf="withHeader" class="header mat-subtitle-2" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
    <ng-container [ngSwitch]="type">
      <ng-container *ngSwitchCase="'notice'">
        <mat-icon *ngIf="withIcon" class="hint-icon" color="primary">info</mat-icon>
        <span fxHide.lt-md>{{ title ?? 'NOTICE' }}</span>
      </ng-container>
      <ng-container *ngSwitchCase="'error'">
        <mat-icon *ngIf="withIcon" class="hint-icon" color="warn">error</mat-icon>
        <span fxHide.lt-md>{{ title ?? 'ERROR' }}</span>
      </ng-container>
    </ng-container>
  </div>
  <div class="content">
    <ng-content></ng-content>
  </div>
</div>

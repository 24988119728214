import { Directive, OnDestroy } from '@angular/core';
import { Observable, Subject, delay, distinctUntilChanged, takeUntil, withLatestFrom } from 'rxjs';
import { TabConfig } from '@neuralegion/api';
import { TabsConfigStore } from '@neuralegion/core';

@Directive()
export abstract class UnconfirmedIssuesTabComponent implements OnDestroy {
  protected abstract defaultTabsConfig: TabConfig[];
  protected abstract unconfirmedProjectIssuesExist$: Observable<boolean>;

  protected readonly activeTabIds$: Observable<string[]> = this.tabsConfigStore.activeTabIds$;
  protected readonly tabsConfig$: Observable<TabConfig[]> = this.tabsConfigStore.tabConfig$;
  protected readonly gc = new Subject<void>();

  constructor(protected readonly tabsConfigStore: TabsConfigStore) {}

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }

  protected initTabsConfig(): void {
    this.tabsConfigStore.init(this.defaultTabsConfig);
    this.initUnconfirmedIssuesExistListener();
  }

  private initUnconfirmedIssuesExistListener(): void {
    this.unconfirmedProjectIssuesExist$
      .pipe(distinctUntilChanged(), delay(0), withLatestFrom(this.tabsConfig$), takeUntil(this.gc))
      .subscribe(([unconfirmedProjectIssuesExist, tabsConfig]) => {
        const tabIndex = tabsConfig.findIndex(({ id }) => id === 'unconfirmedIssues');

        if (tabIndex === -1) {
          return;
        }

        const config = [...tabsConfig];
        config[tabIndex] = {
          ...config[tabIndex],
          fixed: !unconfirmedProjectIssuesExist
        };
        this.tabsConfigStore.updateTabConfig([...config]);
      });
  }
}

import { Injectable } from '@angular/core';
import { ExternalErrorReporterService } from './external-error-reporter.service';

@Injectable()
export class DefaultErrorReporterService extends ExternalErrorReporterService {
  public setUser(): void {
    // noop
  }

  public report(): void {
    // noop
  }
}

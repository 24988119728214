import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject, filter, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { MfaQrCode } from '@neuralegion/api';
import {
  enableMfa,
  loadMfaQrCode,
  selectMfaPendingStatus,
  selectMfaQrCode
} from '@neuralegion/multi-factor-api';

// eslint-disable-next-line @angular-eslint/use-component-selector
@Component({
  templateUrl: './mfa-setup-page.component.html',
  styleUrls: ['./mfa-setup-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MfaSetupPageComponent implements OnInit {
  public pending$: Observable<boolean>;
  public mfaQrCode$: Observable<MfaQrCode>;

  public readonly form: FormGroup;
  private readonly gc = new Subject<void>();

  constructor(private readonly store: Store) {
    this.form = new FormGroup({
      otp: new FormControl('', Validators.required),
      code: new FormControl('', Validators.required)
    });
  }

  public ngOnInit(): void {
    this.pending$ = this.store.select(selectMfaPendingStatus);
    this.mfaQrCode$ = this.store.select(selectMfaQrCode);

    this.mfaQrCode$.pipe(filter(Boolean), takeUntil(this.gc)).subscribe((mfaQrCode: MfaQrCode) => {
      this.form.patchValue({
        code: mfaQrCode.code
      });
    });

    this.store.dispatch(loadMfaQrCode());
  }

  public onVerifyClick(): void {
    const { otp, code } = this.form.value;
    this.store.dispatch(enableMfa({ otp, code, forcedSetup: true }));
  }
}

import { MfaQrCode } from '@neuralegion/api';

export interface MultiFactorState {
  error: string | null;
  pending: boolean;
  qrCode: MfaQrCode;
}

export const state: MultiFactorState = {
  error: null,
  pending: false,
  qrCode: null
};

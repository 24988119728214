import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[shareFilterableSelectControlsKeydown]'
})
export class FilterableSelectControlsKeydownDirective {
  @HostListener('keydown', ['$event'])
  public onKeydown(event: KeyboardEvent) {
    const { code } = event;
    const isArrowNavigationKey = code === 'ArrowDown' || code === 'ArrowUp';
    const isSelectionKey = code === 'Space' || code === 'Enter';

    if ((isArrowNavigationKey && !event.altKey) || isSelectionKey) {
      event.stopImmediatePropagation();
    }
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import {
  Observable,
  Subject,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
  takeUntil
} from 'rxjs';
import { HeaderConfig, MergeStrategy } from '@neuralegion/api';
import { HighlightPipe } from '../../pipes';
import { HeaderMeta } from '../../services';
import { HeadersEditorSettings } from '../headers-editor/headers-editor.component';
import { ListItemControl } from '../list-item-control/list-item-control.component';

interface HighlightedAutocompleteOption extends HeaderMeta {
  readonly highlighted: string;
}

interface HeaderListItemForm {
  name: FormControl<string>;
  value: FormControl<string>;
  mergeStrategy?: FormControl<MergeStrategy>;
}

@Component({
  selector: 'share-header-list-item-control',
  templateUrl: './header-list-item-control.component.html',
  styleUrls: ['./header-list-item-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: HeaderListItemControlComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: HeaderListItemControlComponent,
      multi: true
    },
    {
      provide: ListItemControl,
      useExisting: HeaderListItemControlComponent
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderListItemControlComponent
  extends ListItemControl<HeaderConfig>
  implements ControlValueAccessor, OnInit
{
  @Input()
  public strategyMode = true;

  @Input()
  public autocompleteOptions?: HeaderMeta[];

  @Input()
  public settings: Partial<HeadersEditorSettings> = {};

  @ViewChild('nameField', { read: ElementRef })
  public readonly nameField: ElementRef<HTMLInputElement>;

  public readonly MergeStrategy = MergeStrategy;

  public autocompleteOptions$: Observable<HighlightedAutocompleteOption[]>;

  public readonly innerControl = new FormGroup<HeaderListItemForm>({
    name: new FormControl(''),
    value: new FormControl('')
  });

  private readonly nameFocusedSubject = new Subject<void>();

  private readonly highlightPipe = new HighlightPipe();

  private readonly replaceOnlyHeaders = new Set<string>(
    [
      'Content-Type',
      'Content-Disposition',
      'Content-Length',
      'User-Agent',
      'Referer',
      'Host',
      'Authorization',
      'Proxy-Authorization',
      'If-Modified-Since',
      'If-Unmodified-Since',
      'From',
      'Location',
      'Max-Forwards'
    ].map((headerName) => headerName.toLowerCase())
  );

  private readonly KEY_VALUE_DELIMITER = ': ';

  public override ngOnInit(): void {
    super.ngOnInit();

    this.listControlComponent.mapItemForCopy = (itemData: unknown): string => {
      const item = itemData as HeaderConfig;
      if (!item.name && !item.value) {
        return '';
      }
      return `${item.name}${this.KEY_VALUE_DELIMITER}${item.value.replace(/[\n\r]/g, '')}`;
    };

    this.initInnerControl();

    this.initKeyAutocomplete();
    this.initHeaderNameListener();
  }

  protected override focusOnElement(): void {
    setTimeout(() => this.nameField.nativeElement.focus());
  }

  protected override mapToFormValue(value: HeaderConfig): HeaderConfig {
    return this.trimValue(value);
  }
  protected override mapFromFormValue(value: HeaderConfig): HeaderConfig {
    return this.trimValue(value);
  }

  public onPaste(event: ClipboardEvent): void {
    const pastedText = event.clipboardData.getData('text');
    if (!pastedText.includes(this.KEY_VALUE_DELIMITER)) {
      return;
    }

    event.preventDefault();

    const pastedHeaders: HeaderConfig[] = pastedText
      .split(/[\r]?\n/)
      .map((str) => str.split(this.KEY_VALUE_DELIMITER))
      .map(
        ([name, ...rest]: string[]): HeaderConfig => ({
          ...(this.listControlComponent.settings.defaultValueFactory() as HeaderConfig),
          name,
          value: rest.join(this.KEY_VALUE_DELIMITER)
        })
      );

    this.listControlComponent.onPaste(pastedHeaders, this.idx);
  }

  public onNameFocused(): void {
    this.nameFocusedSubject.next();
  }

  public trackAutocomplete(_idx: number, value: HighlightedAutocompleteOption): string {
    return value.highlighted;
  }

  private trimValue(formValue: HeaderConfig): HeaderConfig {
    return {
      ...formValue,
      name: formValue.name?.trim() || '',
      value: formValue.value?.trim() || ''
    };
  }

  private initInnerControl(): void {
    if (this.strategyMode) {
      this.innerControl.addControl('mergeStrategy', new FormControl(MergeStrategy.REPLACE));
    }

    this.innerControl.controls.name.setValidators(this.settings.nameValidators);
    this.innerControl.controls.value.setValidators(this.settings.valueValidators);
  }

  private initKeyAutocomplete(): void {
    if (this.autocompleteOptions?.length) {
      this.autocompleteOptions$ = this.nameFocusedSubject.pipe(
        switchMap(() =>
          this.innerControl.controls.name.valueChanges.pipe(startWith(this.innerControl.value.name))
        ),
        distinctUntilChanged(),
        map((key: string) =>
          this.autocompleteOptions
            .filter(
              (item) => !key || item.name.toLocaleLowerCase().includes(key.toLocaleLowerCase())
            )
            .map((item) => ({
              ...item,
              highlighted: this.highlightPipe.transform(item.name, key)
            }))
        )
      );
    }
  }

  private initHeaderNameListener(): void {
    if (this.strategyMode) {
      this.innerControl.controls.name.valueChanges
        .pipe(
          filter(() => this.innerControl.enabled),
          takeUntil(this.gc)
        )
        .subscribe((value: string) => {
          const mergeStrategyCtrl = this.innerControl.controls.mergeStrategy;
          if (this.replaceOnlyHeaders.has(value?.trim().toLowerCase())) {
            if (mergeStrategyCtrl.value !== MergeStrategy.REPLACE) {
              mergeStrategyCtrl.setValue(MergeStrategy.REPLACE);
            }
            if (!mergeStrategyCtrl.disabled) {
              mergeStrategyCtrl.disable();
            }
          } else {
            if (!mergeStrategyCtrl.enabled) {
              mergeStrategyCtrl.enable();
            }
          }
        });
    }
  }
}

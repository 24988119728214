import { Directive, ElementRef, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[formControl], [formControlName]'
})
export class FormNativeElementDirective implements OnInit {
  constructor(
    private readonly el: ElementRef,
    private readonly control: NgControl
  ) {}

  public ngOnInit(): void {
    if (this.control.control) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.control.control as any).nativeElement = this.el.nativeElement;
    }
  }
}

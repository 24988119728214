import { AnimationEvent } from '@angular/animations';
import { ConfigurableFocusTrap, ConfigurableFocusTrapFactory } from '@angular/cdk/a11y';
import {
  BooleanInput,
  NumberInput,
  coerceBooleanProperty,
  coerceNumberProperty
} from '@angular/cdk/coercion';
import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import { SatPopoverAnchorDirective } from './popover-anchor.directive';
import { SatPopoverAnchoringService } from './popover-anchoring.service';
import { transformPopover } from './popover.animations';
import {
  getInvalidHorizontalAlignError,
  getInvalidPopoverAnchorError,
  getInvalidScrollStrategyError,
  getInvalidVerticalAlignError,
  getUnanchoredPopoverError
} from './popover.errors';
import { SAT_POPOVER_DEFAULT_TRANSITION } from './tokens';
import {
  SatPopoverHorizontalAlign,
  SatPopoverOpenOptions,
  SatPopoverScrollStrategy,
  SatPopoverVerticalAlign,
  VALID_HORIZ_ALIGN,
  VALID_SCROLL,
  VALID_VERT_ALIGN
} from './types';

const DEFAULT_OPEN_ANIMATION_START_SCALE = 0.3;
const DEFAULT_CLOSE_ANIMATION_END_SCALE = 0.5;

/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle */

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sat-popover',
  // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None,
  animations: [transformPopover],
  styleUrls: ['./popover.component.scss'],
  templateUrl: './popover.component.html',
  providers: [SatPopoverAnchoringService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SatPopoverComponent implements OnInit {
  /** Anchor element. */
  @Input()
  get anchor(): SatPopoverAnchorDirective | ElementRef<HTMLElement> | HTMLElement {
    return this._anchor;
  }
  set anchor(val: SatPopoverAnchorDirective | ElementRef<HTMLElement> | HTMLElement) {
    if (val instanceof SatPopoverAnchorDirective) {
      val._popover = this;
      this._anchoringService.anchor(this, val.viewContainerRef, val.elementRef);
      this._anchor = val;
    } else if (val instanceof ElementRef || val instanceof HTMLElement) {
      this._anchoringService.anchor(this, this._viewContainerRef, val);
      this._anchor = val;
    } else if (val) {
      throw getInvalidPopoverAnchorError();
    }
  }

  private _anchor: SatPopoverAnchorDirective | ElementRef<HTMLElement> | HTMLElement;

  /** Alignment of the popover on the horizontal axis. */
  @Input()
  get horizontalAlign(): SatPopoverHorizontalAlign {
    return this._horizontalAlign;
  }
  set horizontalAlign(val: SatPopoverHorizontalAlign) {
    this._validateHorizontalAlign(val);
    if (this._horizontalAlign !== val) {
      this._horizontalAlign = val;
      this._anchoringService.repositionPopover();
    }
  }
  private _horizontalAlign: SatPopoverHorizontalAlign = 'center';

  /** Alignment of the popover on the x-axis. Alias for `horizontalAlign`. */
  @Input()
  get xAlign(): SatPopoverHorizontalAlign {
    return this.horizontalAlign;
  }
  set xAlign(val: SatPopoverHorizontalAlign) {
    this.horizontalAlign = val;
  }

  /** Alignment of the popover on the vertical axis. */
  @Input()
  get verticalAlign(): SatPopoverVerticalAlign {
    return this._verticalAlign;
  }
  set verticalAlign(val: SatPopoverVerticalAlign) {
    this._validateVerticalAlign(val);
    if (this._verticalAlign !== val) {
      this._verticalAlign = val;
      this._anchoringService.repositionPopover();
    }
  }
  private _verticalAlign: SatPopoverVerticalAlign = 'center';

  /** Alignment of the popover on the y-axis. Alias for `verticalAlign`. */
  @Input()
  get yAlign(): SatPopoverVerticalAlign {
    return this.verticalAlign;
  }
  set yAlign(val: SatPopoverVerticalAlign) {
    this.verticalAlign = val;
  }

  /** Whether the popover always opens with the specified alignment. */
  @Input()
  get forceAlignment(): boolean {
    return this._forceAlignment;
  }
  set forceAlignment(val: BooleanInput) {
    const coercedVal = coerceBooleanProperty(val);
    if (this._forceAlignment !== coercedVal) {
      this._forceAlignment = coercedVal;
      this._anchoringService.repositionPopover();
    }
  }
  private _forceAlignment = false;

  /**
   * Whether the popover's alignment is locked after opening. This prevents the popover
   * from changing its alignment when scrolling or changing the size of the viewport.
   */
  @Input()
  get lockAlignment(): boolean {
    return this._lockAlignment;
  }
  set lockAlignment(val: BooleanInput) {
    const coercedVal = coerceBooleanProperty(val);
    if (this._lockAlignment !== coercedVal) {
      this._lockAlignment = coerceBooleanProperty(val);
      this._anchoringService.repositionPopover();
    }
  }
  private _lockAlignment = false;

  /** Whether the first focusable element should be focused on open. */
  @Input()
  get autoFocus(): boolean {
    return this._autoFocus && this._autoFocusOverride;
  }
  set autoFocus(val: BooleanInput) {
    this._autoFocus = coerceBooleanProperty(val);
  }
  private _autoFocus = true;
  public _autoFocusOverride = true;

  /** Whether the popover should return focus to the previously focused element after closing. */
  @Input()
  get restoreFocus(): boolean {
    return this._restoreFocus && this._restoreFocusOverride;
  }
  set restoreFocus(val: BooleanInput) {
    this._restoreFocus = coerceBooleanProperty(val);
  }
  private _restoreFocus = true;
  public _restoreFocusOverride = true;

  /** How the popover should handle scrolling. */
  @Input()
  get scrollStrategy(): SatPopoverScrollStrategy {
    return this._scrollStrategy;
  }
  set scrollStrategy(val: SatPopoverScrollStrategy) {
    this._validateScrollStrategy(val);
    if (this._scrollStrategy !== val) {
      this._scrollStrategy = val;
      this._anchoringService.updatePopoverConfig();
    }
  }
  private _scrollStrategy: SatPopoverScrollStrategy = 'reposition';

  /** Whether the popover should have a backdrop (includes closing on click). */
  @Input()
  get hasBackdrop(): boolean {
    return this._hasBackdrop;
  }
  set hasBackdrop(val: BooleanInput) {
    this._hasBackdrop = coerceBooleanProperty(val);
  }
  private _hasBackdrop = false;

  /** Whether the popover should close when the user clicks the backdrop or presses ESC. */
  @Input()
  get interactiveClose(): boolean {
    return this._interactiveClose;
  }
  set interactiveClose(val: BooleanInput) {
    this._interactiveClose = coerceBooleanProperty(val);
  }
  private _interactiveClose = true;

  /** Custom transition to use while opening. */
  @Input()
  get openTransition(): string {
    return this._openTransition;
  }
  set openTransition(val: string) {
    if (val) {
      this._openTransition = val;
    }
  }
  private _openTransition = this._defaultTransition;

  /** Custom transition to use while closing. */
  @Input()
  get closeTransition(): string {
    return this._closeTransition;
  }
  set closeTransition(val: string) {
    if (val) {
      this._closeTransition = val;
    }
  }
  private _closeTransition = this._defaultTransition;

  /** Scale value at the start of the :enter animation. */
  @Input()
  get openAnimationStartAtScale(): number {
    return this._openAnimationStartAtScale;
  }
  set openAnimationStartAtScale(val: NumberInput) {
    const coercedVal = coerceNumberProperty(val);
    if (!isNaN(coercedVal)) {
      this._openAnimationStartAtScale = coercedVal;
    }
  }
  private _openAnimationStartAtScale = DEFAULT_OPEN_ANIMATION_START_SCALE;

  /** Scale value at the end of the :leave animation */
  @Input()
  get closeAnimationEndAtScale(): number {
    return this._closeAnimationEndAtScale;
  }
  set closeAnimationEndAtScale(val: NumberInput) {
    const coercedVal = coerceNumberProperty(val);
    if (!isNaN(coercedVal)) {
      this._closeAnimationEndAtScale = coercedVal;
    }
  }
  private _closeAnimationEndAtScale = DEFAULT_CLOSE_ANIMATION_END_SCALE;

  /** Optional backdrop class. */
  @Input()
  public backdropClass = '';

  /** Optional custom class to add to the overlay pane. */
  @Input()
  public panelClass: string | string[] = '';

  /** Emits when the popover is opened. */
  @Output()
  public readonly opened = new EventEmitter<void>();

  /** Emits when the popover is closed. */
  @Output()
  public readonly closed = new EventEmitter<void>();

  /** Emits when the popover has finished opening. */
  @Output()
  public readonly afterOpen = new EventEmitter<void>();

  /** Emits when the popover has finished closing. */
  @Output()
  public readonly afterClose = new EventEmitter<void>();

  /** Emits when the backdrop is clicked. */
  @Output()
  public readonly backdropClicked = new EventEmitter<void>();

  /** Emits when a keydown event is targeted to this popover's overlay. */
  @Output()
  public readonly overlayKeydown = new EventEmitter<KeyboardEvent>();

  /** Reference to template, so it can be placed within a portal. */
  @ViewChild(TemplateRef, { static: true })
  public readonly _templateRef: TemplateRef<never>;

  /** Classes to be added to the popover for setting the correct transform origin. */
  public _classList: Record<string, string | boolean> = {};

  /** Whether the popover is presently open. */
  public _open = false;

  public _state: 'enter' | 'void' | 'exit' = 'enter';

  /** Reference to the element to build a focus trap around. */
  @ViewChild('focusTrapElement')
  private readonly _focusTrapElement: ElementRef;

  /** Reference to the element that was focused before opening. */
  private _previouslyFocusedElement: HTMLElement;

  /** Reference to a focus trap around the popover. */
  private _focusTrap: ConfigurableFocusTrap;

  constructor(
    private readonly _focusTrapFactory: ConfigurableFocusTrapFactory,
    private readonly _anchoringService: SatPopoverAnchoringService,
    private readonly _viewContainerRef: ViewContainerRef,
    @Inject(SAT_POPOVER_DEFAULT_TRANSITION) private readonly _defaultTransition: string,
    @Optional() @Inject(DOCUMENT) private readonly _document: Document
  ) {}

  public ngOnInit(): void {
    this._setAlignmentClasses();
  }

  /** Open this popover. */
  public open(options: SatPopoverOpenOptions = {}): void {
    if (this._anchor) {
      this._anchoringService.openPopover(options);
      return;
    }

    throw getUnanchoredPopoverError();
  }

  /** Close this popover. */
  public close(): void {
    this._anchoringService.closePopover();
  }

  /** Toggle this popover open or closed. */
  public toggle(): void {
    this._anchoringService.togglePopover();
  }

  /** Gets an animation config with customized (or default) transition values. */
  get state(): 'enter' | 'void' | 'exit' {
    return this._state;
  }
  get params() {
    return {
      openTransition: this.openTransition,
      closeTransition: this.closeTransition,
      startAtScale: this.openAnimationStartAtScale,
      endAtScale: this.closeAnimationEndAtScale
    };
  }

  /** Callback for when the popover is finished animating in or out. */
  public _onAnimationDone({ toState }: AnimationEvent): void {
    if (toState === 'enter') {
      this._trapFocus();
      this.afterOpen.emit();
    } else if (toState === 'exit' || toState === 'void') {
      this._restoreFocusAndDestroyTrap();
      this.afterClose.emit();
    }
  }

  /** Starts the dialog exit animation. */
  public _startExitAnimation(): void {
    this._state = 'exit';
  }

  /** Apply alignment classes based on alignment inputs. */
  public _setAlignmentClasses(
    horizAlign = this.horizontalAlign,
    vertAlign = this.verticalAlign
  ): void {
    this._classList['sat-popover-before'] = horizAlign === 'before' || horizAlign === 'end';
    this._classList['sat-popover-after'] = horizAlign === 'after' || horizAlign === 'start';

    this._classList['sat-popover-above'] = vertAlign === 'above' || vertAlign === 'end';
    this._classList['sat-popover-below'] = vertAlign === 'below' || vertAlign === 'start';

    this._classList['sat-popover-center'] = horizAlign === 'center' || vertAlign === 'center';
  }

  /** Move the focus inside the focus trap and remember where to return later. */
  private _trapFocus(): void {
    this._savePreviouslyFocusedElement();

    // There won't be a focus trap element if the close animation starts before open finishes
    if (!this._focusTrapElement) {
      return;
    }

    if (!this._focusTrap && this._focusTrapElement) {
      this._focusTrap = this._focusTrapFactory.create(this._focusTrapElement.nativeElement);
    }

    if (this.autoFocus) {
      void this._focusTrap.focusInitialElementWhenReady();
    }
  }

  /** Restore focus to the element focused before the popover opened. Also destroy trap. */
  private _restoreFocusAndDestroyTrap(): void {
    const toFocus = this._previouslyFocusedElement;

    // Must check active element is focusable for IE’s sake
    if (toFocus && 'focus' in toFocus && this.restoreFocus) {
      this._previouslyFocusedElement.focus();
    }

    this._previouslyFocusedElement = null;

    if (this._focusTrap) {
      this._focusTrap.destroy();
      this._focusTrap = undefined;
    }
  }

  /** Save a reference to the element focused before the popover was opened. */
  private _savePreviouslyFocusedElement(): void {
    if (this._document) {
      this._previouslyFocusedElement = this._document.activeElement as HTMLElement;
    }
  }

  /** Throws an error if the alignment is not a valid horizontalAlign. */
  private _validateHorizontalAlign(pos: SatPopoverHorizontalAlign): void {
    if (!VALID_HORIZ_ALIGN.includes(pos)) {
      throw getInvalidHorizontalAlignError(pos);
    }
  }

  /** Throws an error if the alignment is not a valid verticalAlign. */
  private _validateVerticalAlign(pos: SatPopoverVerticalAlign): void {
    if (!VALID_VERT_ALIGN.includes(pos)) {
      throw getInvalidVerticalAlignError(pos);
    }
  }

  /** Throws an error if the scroll strategy is not a valid strategy. */
  private _validateScrollStrategy(strategy: SatPopoverScrollStrategy): void {
    if (!VALID_SCROLL.includes(strategy)) {
      throw getInvalidScrollStrategyError(strategy);
    }
  }
}

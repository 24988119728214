import { QuotaType, quotaTypes } from './QuotaType';

export interface Quota {
  readonly limit: number;
  readonly type: QuotaType;
  readonly used?: number;
}

export const quotasComparator = (a: Quota, b: Quota) =>
  quotaTypes.indexOf(a.type) - quotaTypes.indexOf(b.type);

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Observable, Subject, filter, takeUntil } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { SnackbarService } from '@neuralegion/core';
import {
  clearAllSuccess,
  closeFeed,
  openFeed,
  selectFeedOpened,
  selectLastSuccess,
  selectUnseenCounter
} from '@neuralegion/feed-api';
import { SatPopoverComponent } from '@neuralegion/share';

@Component({
  selector: 'feed-icon',
  templateUrl: './feed-icon.component.html',
  styleUrls: ['./feed-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedIconComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(SatPopoverComponent, { static: true })
  public popover: SatPopoverComponent;

  private readonly gc = new Subject<void>();
  public unseenCounter$: Observable<number>;
  public opened$: Observable<boolean>;

  constructor(
    private readonly store: Store,
    private readonly snackbarService: SnackbarService
  ) {}

  public ngOnInit(): void {
    this.unseenCounter$ = this.store.select(selectUnseenCounter);
    this.opened$ = this.store.select(selectFeedOpened);
  }

  public ngAfterViewInit(): void {
    this.store
      .select(selectLastSuccess)
      .pipe(
        filter((action: Action): boolean => action && action.type === clearAllSuccess.type),
        takeUntil(this.gc)
      )
      .subscribe(() => {
        this.popover.close();
        this.snackbarService.open('Feed cleared');
      });
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }

  public onFeedOpened(): void {
    this.store.dispatch(openFeed());
  }

  public onFeedClosed(): void {
    this.store.dispatch(closeFeed());
  }
}

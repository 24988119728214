<div fxLayout="row" fxLayoutAlign="space-between center">
  <span class="copyable" [element-data-id]="itemTitle" (dblclick)="copyButton.doCopy($event)">{{ item.id }}</span>
  <share-copy-button
    #copyButton
    fxFlexOffset="6px"
    [color]="color"
    [copiedMessage]="itemTitle + ' copied'"
    [data]="item.id"
    [title]="'Copy ' + itemTitle"></share-copy-button>
</div>

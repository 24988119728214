import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, distinctUntilChanged, map, takeUntil } from 'rxjs';
import { UploadFilePayload, UploadStatus, UploaderService } from '@neuralegion/core';

@Injectable()
export class FileUploadingService<T, P = UploadFilePayload> implements OnDestroy {
  public readonly uploadActive$: Observable<boolean>;
  public readonly uploadProgress$: Observable<number>;
  public readonly uploadResult$: Observable<UploadStatus<T>>;

  private readonly uploadProgressSubject = new Subject<number>();
  private readonly uploadCancelSubject = new Subject<void>();
  private readonly uploadResultSubject = new Subject<UploadStatus<T>>();

  private readonly gc = new Subject<void>();

  constructor(private readonly fileUploaderService: UploaderService<T, P>) {
    this.uploadProgress$ = this.uploadProgressSubject.asObservable();
    this.uploadResult$ = this.uploadResultSubject.asObservable();
    this.uploadActive$ = this.uploadProgress$.pipe(
      map((progress: number) => progress >= 0),
      distinctUntilChanged()
    );
  }

  public ngOnDestroy(): void {
    this.cancelUpload();

    this.gc.next();
    this.gc.unsubscribe();
  }

  public upload(payload: P): void {
    this.uploadProgressSubject.next(0);
    this.fileUploaderService
      .upload(payload)
      .pipe(takeUntil(this.uploadCancelSubject), takeUntil(this.gc))
      .subscribe((status: UploadStatus<T>) => {
        if (status.error) {
          this.reset();
          return;
        }

        if (status.file) {
          this.uploadProgressSubject.next(1);

          this.uploadResultSubject.next(status);
        } else {
          this.uploadProgressSubject.next(status.progress);
        }
      });
  }

  public reset(): void {
    this.uploadProgressSubject.next(-1);
  }

  public cancelUpload(): void {
    this.uploadCancelSubject.next();
    this.reset();
  }
}

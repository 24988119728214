import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable, map } from 'rxjs';
import { trackByIdentity } from '@neuralegion/core';

export enum ToolbarItemType {
  BUTTON = 'Button',
  LINK = 'Link'
}

export type ToolbarItem = ToolbarLinkItem | ToolbarButtonItem;

interface ToolbarBaseItem {
  type: ToolbarItemType;
  label: string;
  position?: 'left' | 'right';
  visible?: boolean;
  ariaHaspopup?: string;
  ariaLabel?: string;
  dataTarget?: string;
}

interface ToolbarLinkItem extends ToolbarBaseItem {
  type: ToolbarItemType.LINK;
  routerLink: string;
}

interface ToolbarButtonItem extends ToolbarBaseItem {
  type: ToolbarItemType.BUTTON;
  raised?: boolean;
  routerLink?: string;
  disabled?: boolean;
  action?: () => void;
  icon?: string;
  color?: 'primary' | 'secondary';
}

@Component({
  selector: 'share-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarComponent implements OnInit {
  @Input()
  public items$: Observable<ToolbarItem[]>;

  @Input()
  public pending: boolean;

  public positionedItems$: Observable<{ left: ToolbarItem[]; right: ToolbarItem[] }>;

  public readonly ToolbarItemType = ToolbarItemType;
  public readonly trackByIdentity = trackByIdentity;

  public ngOnInit(): void {
    this.positionedItems$ = this.items$.pipe(
      map((options: ToolbarItem[]) => ({
        left: options.filter(
          (option: ToolbarItem) => !option.position || option.position === 'left'
        ),
        right: options.filter((option: ToolbarItem) => option.position === 'right')
      }))
    );
  }
}

import { createAction } from '@ngrx/store';
import { Email, LoggedInUser, Name, ProviderType, SignedUpUser } from '@neuralegion/api';
import { SnakeCase } from '@neuralegion/lang';
import { AnalyticsEvent } from '../models';

export const trackEvent = createAction(
  '[Analytics] TRACK_EVENT',
  <T extends Record<SnakeCase<keyof T & string>, unknown>>(payload: AnalyticsEvent<T>) => ({
    payload
  })
);

export const trackSignup = createAction(
  '[Analytics] TRACK_SIGNUP',
  (payload: {
    user?: Email & Name;
    type: 'local' | 'social' | 'aws';
    invited: boolean;
    provider?: ProviderType;
  }) => ({
    payload
  })
);

export const trackSignupSuccess = createAction(
  '[Analytics] TRACK_SIGNUP_SUCCESS',
  (payload: { user: SignedUpUser }) => ({
    payload
  })
);

export const trackLogin = createAction(
  '[Analytics] TRACK_LOGIN',
  (payload: { user?: Email; type: 'local' | 'social' | 'sso'; provider?: ProviderType }) => ({
    payload
  })
);

export const resetUser = createAction('[Analytics] TRACK_LOGOUT');

export const setUser = createAction('[Analytics] SET_USER', (payload: { user: LoggedInUser }) => ({
  payload
}));

import { Injectable } from '@angular/core';
import { TabConfig } from '@neuralegion/api';
import { LocalStorageService } from './local-storage.service';

enum StorageKey {
  TABS_CONFIG = 'TABS_CONFIG'
}

@Injectable({
  providedIn: 'root'
})
export class TabViewSettingsService {
  constructor(private readonly localStorageService: LocalStorageService) {}

  public getTabsConfig(tabGroupId: string): TabConfig[] {
    return this.localStorageService.get(
      this.generateId(StorageKey.TABS_CONFIG, tabGroupId)
    ) as TabConfig[];
  }

  public saveTabsConfig(tabGroupId: string, tabConfig: TabConfig[]): void {
    this.localStorageService.set(this.generateId(StorageKey.TABS_CONFIG, tabGroupId), tabConfig);
  }

  private generateId(key: string, tabGroup: string): string {
    return `${tabGroup}_${key}`;
  }
}

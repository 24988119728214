import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { Observable, map } from 'rxjs';
import { Store } from '@ngrx/store';
import { LoggedInUser } from '@neuralegion/api';
import { loginRedirect, selectUserInfo } from '../store';

@Injectable()
export class AuthGuard {
  constructor(
    private readonly router: Router,
    private readonly store: Store
  ) {}

  public canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.canNavigate(state.url);
  }

  public canMatch(_: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> {
    const url = segments.map((segment: UrlSegment): string => segment.path).join('/');
    return this.canNavigate(`/${url}${this.getQueryParams()}`);
  }

  private canNavigate(url: string): Observable<boolean | UrlTree> {
    return this.store.select(selectUserInfo).pipe(
      map((user: LoggedInUser) => {
        const nextUrl = user?.nextUrl;
        if (nextUrl && nextUrl !== '/') {
          return this.router.parseUrl(nextUrl);
        } else if (!nextUrl) {
          this.store.dispatch(loginRedirect({ location: url }));
          return false;
        }
        return true;
      })
    );
  }

  private getQueryParams(): string {
    const queryParams = new URLSearchParams(
      this.router.getCurrentNavigation()?.extractedUrl.queryParams ?? {}
    ).toString();
    return queryParams.length ? `?${queryParams}` : '';
  }
}

import { AfterViewInit, Directive, HostListener } from '@angular/core';
import { _countGroupLabelsBeforeOption, _getOptionScrollPosition } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';

@Directive({
  selector: '[shareFilterableSelectOptionsFocus]'
})
export class FilterableSelectOptionsFocusDirective implements AfterViewInit {
  constructor(private readonly matSelect: MatSelect) {}

  public ngAfterViewInit(): void {
    this.patchScrollOptionIntoView();
  }

  @HostListener('focus')
  public onFocus() {
    setTimeout(() => {
      // skip focus event after option click just before close
      if (!this.matSelect.panelOpen) {
        return;
      }

      // force ActiveDescendantKeyManager to select activeItem once more to set styles and bring it to the viewport
      const targetIndex =
        // eslint-disable-next-line no-underscore-dangle
        this.matSelect._keyManager.activeItemIndex < this.matSelect.options.length
          ? // eslint-disable-next-line no-underscore-dangle
            Math.max(0, this.matSelect._keyManager.activeItemIndex) // can be `-1`
          : 0;
      // eslint-disable-next-line no-underscore-dangle
      this.matSelect._keyManager.setActiveItem(targetIndex);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any,no-underscore-dangle,@typescript-eslint/no-unsafe-call
      (this.matSelect as any)._scrollOptionIntoView(targetIndex);
    }, 150);
  }

  /**
   * Patch `_scrollOptionIntoView` to fix scroll position calculations
   * because we have `controls panel` in `filterable selects`
   * @see angular/components/src/material/select/select.ts
   */
  private patchScrollOptionIntoView(): void {
    /* eslint-disable @typescript-eslint/no-unsafe-call */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any,no-underscore-dangle,@typescript-eslint/naming-convention
    (this.matSelect as any)._scrollOptionIntoView = function (index: number): void {
      const option = this.options.toArray()[index];

      if (option) {
        const panel: HTMLElement = this.panel.nativeElement;
        const labelCount = _countGroupLabelsBeforeOption(index, this.options, this.optionGroups);
        // eslint-disable-next-line no-underscore-dangle
        const element = option._getHostElement();

        // First element offset is a height of the `controls-panel`
        const firstOption = this.options.toArray()[0];
        // eslint-disable-next-line no-underscore-dangle
        const controlsPanelHeight = firstOption._getHostElement().offsetTop;

        if (index === 0 && labelCount === 1) {
          panel.scrollTop = 0;
        } else {
          const elementOffset = element.offsetTop - controlsPanelHeight;
          const offset = elementOffset < panel.scrollTop ? elementOffset : element.offsetTop;

          panel.scrollTop = _getOptionScrollPosition(
            offset,
            element.offsetHeight,
            panel.scrollTop,
            panel.offsetHeight
          );
        }
      }
    };
    /* eslint-enable @typescript-eslint/no-unsafe-call */
  }
}

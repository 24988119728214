<div
  *ngIf="{ disabled: disabledSubject | async } as sync"
  class="container"
  fxLayout="row"
  [class.disabled]="sync.disabled"
  [class.error]="formControl.invalid && formControl.dirty">
  <button type="button" [disabled]="sync.disabled" (click)="dec()">−</button>
  <input
    type="text"
    [element-data-id]="'input'"
    [formControl]="formControl"
    (blur)="onBlur()"
    (keydown)="onKeyDown($event)"
    (keypress)="onKeyPress($event)" />
  <button type="button" [disabled]="sync.disabled" (click)="inc()">+</button>
</div>

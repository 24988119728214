import { Action, createReducer, on } from '@ngrx/store';
import { cleanupPending } from '@neuralegion/core';
import {
  addRepeater,
  addRepeaterFail,
  addRepeaterSuccess,
  loadRepeater,
  loadRepeaterFail,
  loadRepeaterSuccess,
  loadRepeaters,
  loadRepeatersFail,
  loadRepeatersSuccess,
  removeRepeater,
  removeRepeaterFail,
  removeRepeaterSuccess,
  updateRepeater,
  updateRepeaterFail,
  updateRepeaterSuccess
} from './repeaters.actions';
import { RepeatersState, repeatersEntityAdapter, repeatersInitialState } from './repeaters.state';

export const repeatersReducer = createReducer<RepeatersState, Action>(
  repeatersInitialState,
  on(
    addRepeater,
    loadRepeaters,
    updateRepeater,
    removeRepeater,
    loadRepeater,
    (state: RepeatersState, action): RepeatersState => ({
      ...state,
      pending: [...state.pending, action]
    })
  ),
  on(
    addRepeaterSuccess,
    updateRepeaterSuccess,
    removeRepeaterSuccess,
    (state: RepeatersState, action): RepeatersState => ({
      ...state,
      pending: cleanupPending(state.pending, action.parentType)
    })
  ),
  on(
    addRepeaterFail,
    updateRepeaterFail,
    removeRepeaterFail,
    loadRepeatersFail,
    loadRepeaterFail,
    (state: RepeatersState, action): RepeatersState => ({
      ...state,
      pending: cleanupPending(state.pending, action.parentType),
      error: action.payload
    })
  ),
  on(
    loadRepeatersSuccess,
    (state: RepeatersState, action): RepeatersState =>
      repeatersEntityAdapter.setAll(action.payload, {
        ...state,
        pending: cleanupPending(state.pending, action.parentType)
      })
  ),
  on(
    loadRepeaterSuccess,
    (state: RepeatersState, action): RepeatersState =>
      repeatersEntityAdapter.upsertOne(action.payload, {
        ...state,
        pending: cleanupPending(state.pending, action.parentType)
      })
  )
);

<share-entry
  *ngIf="{
    scopePermissions: scopePermissions$ | async,
    orgPagePermission: orgPagePermission$ | async,
    codeInstrumentationPermission: codeInstrumentationPermission$ | async
  } as sync"
  class="entry">
  <!-- `/scans` atm works as home page, so no scope check is done -->
  <share-nav-item [icon]="'insert_chart'" [label]="'Scans'" [link]="'/scans'" [tooltip]="'Scans'">
    Scans
  </share-nav-item>
  <share-nav-item
    *ngIf="sync.scopePermissions[Scope.PROJECTS_READ]"
    [icon]="'folder'"
    [label]="'Projects'"
    [link]="'/projects'"
    [tooltip]="'Projects'">
    Projects
  </share-nav-item>
  <share-nav-item
    *ngIf="sync.scopePermissions[Scope.AUTH_OBJECTS_READ]"
    [icon]="'admin_panel_settings'"
    [label]="'Authentications'"
    [link]="'/auth-objects'"
    [tooltip]="'Authentications'">
    Authentications
  </share-nav-item>
  <share-nav-item
    *ngIf="sync.scopePermissions[Scope.REPEATERS_READ] && sync.codeInstrumentationPermission"
    [icon]="'device_hub'"
    [label]="'Repeaters'"
    [link]="'/repeaters'"
    [tooltip]="'Repeaters'">
    Repeaters
  </share-nav-item>
  <share-nav-item
    *ngIf="sync.scopePermissions[Scope.FILES_READ]"
    [icon]="'cloud'"
    [label]="'Storage'"
    [link]="'/storage'"
    [tooltip]="'Storage'">
    Storage
  </share-nav-item>
  <share-nav-item
    *ngIf="sync.orgPagePermission"
    [icon]="'domain'"
    [label]="'Organization'"
    [link]="'/organization'"
    [tooltip]="'Organization'">
    Organization
  </share-nav-item>
  <share-nav-item
    *ngIf="sync.scopePermissions[Scope.LOGS]"
    [icon]="'storage'"
    [label]="'Activity log'"
    [link]="'/activity'"
    [tooltip]="'Activity log'">
    Activity log
  </share-nav-item>

  <ng-container class="header-icons">
    <feed-icon *ngIf="sync.scopePermissions[Scope.ACTIVITIES]"></feed-icon>
  </ng-container>
</share-entry>

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Event, NavigationEnd, Router } from '@angular/router';
import { Observable, Subject, filter, map, take, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { logout, selectAuthActiveStatus } from '@neuralegion/auth-api';
import {
  closeSidebar,
  expandSidebar,
  openSidebar,
  selectIsSmallScreen,
  selectSidebarExpandedStatus,
  selectSidebarOpenedStatus
} from '@neuralegion/core';
import { AccessibilityService, ConfirmModalService } from '../../services';

@Component({
  selector: 'share-entry',
  templateUrl: './entry.component.html',
  styleUrls: ['./entry.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntryComponent implements OnInit, OnDestroy {
  public active$: Observable<boolean>;
  public sidebarOpened$: Observable<boolean>;
  public sidebarExpanded$: Observable<boolean>;
  public smallMedia$: Observable<boolean>;
  public adminApp$: Observable<boolean>;

  private readonly gc = new Subject<void>();

  constructor(
    private readonly router: Router,
    private readonly store: Store,
    private readonly confirmModalService: ConfirmModalService,
    private readonly accessibilityService: AccessibilityService,
    private readonly activatedRoute: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    this.active$ = this.store.select(selectAuthActiveStatus);

    this.smallMedia$ = this.store.select(selectIsSmallScreen);

    this.sidebarOpened$ = this.store.select(selectSidebarOpenedStatus);
    this.sidebarExpanded$ = this.store.select(selectSidebarExpandedStatus);

    this.adminApp$ = this.activatedRoute.root.firstChild.data.pipe(
      map((data: Data) => !!data.adminApp)
    );

    this.router.events
      .pipe(
        filter((e: Event) => e instanceof NavigationEnd),
        takeUntil(this.gc)
      )
      .subscribe(() => this.closeSidebar());
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }

  public openSidebar(): void {
    this.store.dispatch(openSidebar());
  }

  public expandSidebar(): void {
    this.store.dispatch(expandSidebar());
  }

  public closeSidebar(): void {
    this.store.dispatch(closeSidebar());
  }

  public logout(): void {
    this.confirmModalService
      .confirm('Do you really want to log out?')
      .pipe(
        take(1),
        filter((confirmed) => confirmed),
        takeUntil(this.gc)
      )
      .subscribe(() => this.store.dispatch(logout({ skipRouting: false })));
  }

  public focusOnFirstElementInWrapper(wrapper: HTMLElement): void {
    this.accessibilityService.getFirstTabbableElement(wrapper)?.focus();
  }
}

<mat-card>
  <ng-container *ngIf="!routeParams; else elseContainer">
    <mat-card-header><mat-card-title>Client error</mat-card-title></mat-card-header>
    <mat-card-content><p>Unknown error, sorry :(</p></mat-card-content>
  </ng-container>
  <ng-template #elseContainer>
    <mat-card-header>
      <mat-card-title>Client error: {{ routeParams?.name | uppercase }} </mat-card-title>
      <mat-card-subtitle>Client error: {{ routeParams?.message }} </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <p>Error in {{ routeParams?.url | uppercase }} page, sorry {{ routeParams?.user }} :(</p>
    </mat-card-content>
  </ng-template>
</mat-card>

import { BaseElementIdStrategy } from './base-element-id-strategy';
import { ButtonElementIdStrategy } from './button-element-id-strategy';

export class MatButtonToggleElementIdStrategy extends BaseElementIdStrategy {
  private readonly elementPrefix = ['mat', 'toggle'].join(this.SEPARATOR_INTERNAL);

  public override applyId(element: HTMLElement, dataId: string): void {
    const strategy = new ButtonElementIdStrategy(this.renderer);
    const el = element.querySelector<HTMLElement>('button');

    super.applyId(el, strategy.calculateId(el, dataId));
  }

  protected override getSubId(): string {
    return this.elementPrefix;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { SectionStatus, SettingsError } from '../components';
import { SectionRequiredCounters } from '../services';

@Pipe({
  name: 'settingsTabSectionStatus',
  pure: true
})
export class SettingsTabSectionStatusPipe<S> implements PipeTransform {
  public transform(
    counters: SectionRequiredCounters,
    error: SettingsError<S>,
    visited: boolean
  ): SectionStatus | null {
    if (!counters) {
      return null;
    }

    return error || counters.validRequired < counters.required
      ? visited
        ? SectionStatus.WARNING
        : SectionStatus.PRISTINE_WARNING
      : SectionStatus.SUCCESS;
  }
}

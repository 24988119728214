import { Action, createReducer, on } from '@ngrx/store';
import { cleanupPending, cleanupPendingState } from '@neuralegion/core';
import {
  addMember,
  addMemberFail,
  addMemberSuccess,
  loadMember,
  loadMemberFail,
  loadMemberSuccess,
  loadMembers,
  loadMembersFail,
  loadMembersSuccess,
  loadMembersV1,
  loadMembersV1Fail,
  loadMembersV1Success,
  removeMember,
  removeMemberFail,
  removeMemberSuccess,
  updateMember,
  updateMemberFail,
  updateMemberSuccess
} from './members.actions';
import { MembersState, initialMembersState, membersEntityAdapter } from './members.state';

export const membersReducer = createReducer<MembersState, Action>(
  initialMembersState,
  on(
    loadMember,
    loadMembers,
    loadMembersV1,
    addMember,
    updateMember,
    removeMember,
    (state: MembersState, action): MembersState => ({
      ...state,
      pending: [...state.pending, action]
    })
  ),
  on(
    loadMemberSuccess,
    (state: MembersState, action): MembersState =>
      membersEntityAdapter.setAll(
        action.payload ? [action.payload] : [],
        cleanupPendingState({ ...state, loaded: true }, action)
      )
  ),
  on(
    loadMembersSuccess,
    (state: MembersState, action): MembersState =>
      membersEntityAdapter.setAll(action.payload.items || [], cleanupPendingState(state, action))
  ),
  on(
    loadMembersV1Success,
    (state: MembersState, action): MembersState =>
      membersEntityAdapter.setAll(action.payload, {
        ...state,
        error: null,
        pending: cleanupPending(state.pending, action.parentType)
      })
  ),
  on(
    removeMemberSuccess,
    addMemberSuccess,
    updateMemberSuccess,
    (state: MembersState, action): MembersState => cleanupPendingState(state, action)
  ),
  on(
    loadMemberFail,
    loadMembersFail,
    loadMembersV1Fail,
    addMemberFail,
    updateMemberFail,
    removeMemberFail,
    (state: MembersState, action): MembersState => ({
      ...state,
      error: action.payload,
      pending: cleanupPending(state.pending, action.parentType)
    })
  )
);

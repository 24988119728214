import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  ActivityLogGuard,
  AuthGuard,
  AuthObjectsGuard,
  OrganizationGuard,
  ProjectsGuard,
  RepeaterScriptsGuard,
  RepeatersGuard,
  ScanTemplatesGuard,
  StorageGuard,
  WizardGuard
} from '@neuralegion/auth-api';
import { CodeInstrumentationGuard } from '@neuralegion/organization-api';
import { DateSettingsGuard } from '@neuralegion/profile-api';
import { AppComponent } from '../containers';

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          component: AppComponent,
          children: [
            {
              path: '',
              redirectTo: '/scans',
              pathMatch: 'full'
            },
            {
              path: 'scans',
              canActivate: [AuthGuard],
              canMatch: [AuthGuard, DateSettingsGuard],
              loadChildren: async () => (await import('@neuralegion/scans')).ScansModule
            },
            {
              path: 'scan-templates',
              canActivate: [AuthGuard],
              canMatch: [AuthGuard, ScanTemplatesGuard, DateSettingsGuard],
              loadChildren: async () =>
                (await import('@neuralegion/scan-templates')).ScanTemplatesModule
            },
            {
              path: 'auth-objects',
              canActivate: [AuthGuard],
              canMatch: [AuthGuard, AuthObjectsGuard, DateSettingsGuard],
              loadChildren: async () =>
                (await import('@neuralegion/auth-objects')).AuthObjectsModule
            },
            {
              path: 'projects',
              canActivate: [AuthGuard],
              canMatch: [AuthGuard, ProjectsGuard, DateSettingsGuard],
              loadChildren: async () => (await import('@neuralegion/projects')).ProjectsModule
            },
            {
              path: 'repeaters',
              canActivate: [AuthGuard],
              canMatch: [AuthGuard, CodeInstrumentationGuard, RepeatersGuard, DateSettingsGuard],
              loadChildren: async () => (await import('@neuralegion/repeaters')).RepeatersModule
            },
            {
              path: 'repeaters/scripts',
              canActivate: [AuthGuard],
              canMatch: [
                AuthGuard,
                CodeInstrumentationGuard,
                RepeaterScriptsGuard,
                DateSettingsGuard
              ],
              loadChildren: async () =>
                (await import('@neuralegion/repeater-scripts')).RepeaterScriptsModule
            },
            {
              path: 'profile',
              canActivate: [AuthGuard],
              canMatch: [AuthGuard, DateSettingsGuard],
              loadChildren: async () => (await import('@neuralegion/profile')).ProfileModule
            },
            {
              path: 'organization',
              canActivate: [AuthGuard],
              canMatch: [AuthGuard, OrganizationGuard, DateSettingsGuard],
              loadChildren: async () =>
                (await import('../../organization')).ConsumerOrganizationModule
            },
            {
              path: 'storage',
              canActivate: [AuthGuard],
              canMatch: [AuthGuard, StorageGuard, DateSettingsGuard],
              loadChildren: async () => (await import('@neuralegion/storage')).StorageModule
            },
            {
              path: 'activity',
              canActivate: [AuthGuard],
              canMatch: [AuthGuard, ActivityLogGuard, DateSettingsGuard],
              loadChildren: async () =>
                (await import('@neuralegion/activity-log')).ActivityLogModule
            },
            {
              path: 'wizard',
              outlet: 'dialog',
              canActivate: [WizardGuard],
              loadChildren: async () => (await import('@neuralegion/wizard')).WizardModule
            }
          ]
        }
      ],
      { paramsInheritanceStrategy: 'always' }
    )
  ],
  exports: [RouterModule]
})
export class AppRouterModule {}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { ChangedValue, Headers } from '@neuralegion/api';
import { trackByIdentity } from '@neuralegion/core';

interface HeaderAux {
  key: string;
  value: string;
  added: boolean;
  removed: boolean;
  existing: boolean;
  value$: Observable<ChangedValue<string>>;
}

@Component({
  selector: 'share-headers-viewer',
  templateUrl: './headers-viewer.component.html',
  styleUrls: ['./headers-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeadersViewerComponent implements OnInit {
  @Input()
  public headers$: Observable<ChangedValue<Headers>>;

  public headersMeta$: Observable<HeaderAux[]>;

  public readonly trackByIdentity = trackByIdentity;

  public ngOnInit(): void {
    this.headersMeta$ = this.headers$.pipe(
      map((value: ChangedValue<Headers>): HeaderAux[] =>
        [
          ...new Set<string>([...Object.keys(value.actual), ...Object.keys(value.previous || {})])
        ].map((key: string) => ({
          key,
          value: value.actual[key] ?? value.previous[key],
          added: value.previous && value.previous[key] == null,
          removed: value.actual[key] == null,
          existing: value.previous && value.previous[key] != null,
          value$: of({ actual: value.actual[key], previous: value.previous && value.previous[key] })
        }))
      )
    );
  }
}

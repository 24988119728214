<section class="stepper-container" role="tablist" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start">
  <mat-selection-list
    aria-label="Steps"
    #selectionList
    [multiple]="false"
    (selectionChange)="selectStepByIndex(selectionList.selectedOptions.selected[0].value)">
    <mat-list-option
      *ngFor="let step of steps; let idx = index; trackBy: trackByIdentity"
      cdkStepHeader
      [disabled]="!step.interacted"
      [ngClass]="{ interacted: step.interacted, active: selectedIndex === idx }"
      [selected]="idx === selectedIndex"
      [value]="idx">
      <div class="nav-container" fxLayout="row" fxLayoutAlign="start center">
        <div class="nav-number" fxLayoutAlign="center center">{{ idx + 1 }}</div>
        <div class="nav-label">
          <ng-container *ngIf="step.stepLabel; else showLabelText" [ngTemplateOutlet]="step.stepLabel.template">
          </ng-container>
          <ng-template #showLabelText>
            {{ step.label }}
          </ng-template>
        </div>
      </div>
    </mat-list-option>
  </mat-selection-list>

  <div class="stepper-content full-width">
    <ng-container *ngTemplateOutlet="selected ? selected.content : null"></ng-container>
  </div>
</section>

import { HttpErrorResponse } from '@angular/common/http';
import { createAction } from '@ngrx/store';
import { HttpErrorHandlerOptions } from '../models';

export const addHttpError = createAction(
  '[ErrorHandler] ADD_HTTP_ERROR',
  (payload: { error: HttpErrorResponse; options?: HttpErrorHandlerOptions }) => ({
    payload
  })
);

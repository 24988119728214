import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, filter, map, of } from 'rxjs';
import { UploadFilePayload } from './upload-file-payload';
import { UploadStatus } from './upload-status';

export interface UploadAPIService<P, T> {
  upload: (payload: P) => Observable<HttpEvent<T>>;
}

@Injectable()
export abstract class UploaderService<T, P = UploadFilePayload> {
  protected abstract storageService: UploadAPIService<P, T>;

  public upload(payload: P, maxProgress: number = 0.98): Observable<UploadStatus<T>> {
    return this.storageService.upload(payload).pipe(
      filter(
        (event: HttpEvent<T>) =>
          event.type === HttpEventType.Response || event.type === HttpEventType.UploadProgress
      ),
      map((event: HttpEvent<T>) => {
        let progress = 1;
        if (event.type === HttpEventType.UploadProgress) {
          const progressEvent = event;
          progress = Math.min(progressEvent.loaded / progressEvent.total, maxProgress);
        }

        return {
          progress,
          file: event.type === HttpEventType.Response ? event.body : null
        };
      }),
      catchError(() =>
        of({
          progress: -1,
          file: null,
          error: 'Uploading error'
        })
      )
    );
  }
}

import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, exhaustMap, filter, map, of, tap, withLatestFrom } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { ConfiguredProvider, LoggedInUser, SsoConfig } from '@neuralegion/api';
import { LocationStorageService, UserSessionStorageService } from '@neuralegion/browser-storage';
import {
  SnackbarService,
  activateLayout,
  closeAllDialogs,
  deactivateLayout
} from '@neuralegion/core';
import { ExternalErrorReporterService } from '@neuralegion/error-handler';
import { enableMfaSuccess, verifyMfaOtpSuccess } from '@neuralegion/multi-factor-api';
import { AuthError, InvalidOAuthEmailError } from '../models/auth-error';
import { AuthService, BaseService, MarketplaceCallbackStateStorageService } from '../services';
import {
  activateOnboarding,
  confirmRequired,
  createRequiredOrg,
  createRequiredOrgFail,
  createRequiredOrgSuccess,
  invalidOAuthEmail,
  loadUserInfo,
  loadUserInfoFail,
  loadUserInfoSuccess,
  login,
  loginFail,
  loginRedirect,
  loginSuccess,
  logout,
  mfaRequired,
  oauthDisconnect,
  oauthDisconnectFail,
  oauthDisconnectSuccess,
  oauthFinalize,
  oauthFinalizeFail,
  oauthFinalizeSuccess,
  passwordChangeRequired,
  ssoInit,
  ssoInitFail,
  ssoInitSuccess,
  ssoRequired
} from './auth.actions';
import { selectTempToken } from './auth.selectors';
import {
  forgotPassword,
  forgotPasswordFail,
  forgotPasswordSuccess,
  resetPassword,
  resetPasswordFail,
  resetPasswordSuccess
} from './forgot-password.actions';
import { acceptInviteSuccess } from './signup.actions';

@Injectable()
export class AuthEffects {
  public readonly login$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(login),
      exhaustMap((action: ReturnType<typeof login>) =>
        this.authService.login(action.payload).pipe(
          map(() => loginSuccess()),
          catchError((err: HttpErrorResponse) => of(loginFail(err.error)))
        )
      )
    )
  );

  public readonly ssoInit$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ssoInit),
      exhaustMap((action: ReturnType<typeof ssoInit>) =>
        this.baseService.loadSSOProviders(action.payload).pipe(
          map((sso: SsoConfig) => ssoInitSuccess({ sso, initiatedBy: action.payload.initiatedBy })),
          catchError((err: HttpErrorResponse) =>
            of(
              ssoInitFail({
                message: err.error,
                redirectToLogin: !!action.payload.initiatedBy
              })
            )
          )
        )
      )
    )
  );

  public readonly ssoInitSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ssoInitSuccess),
        tap(({ payload }: ReturnType<typeof ssoInitSuccess>) => {
          const target: ConfiguredProvider | null =
            payload.sso.provider.providerType === payload.initiatedBy ? payload.sso.provider : null;

          if (target) {
            this.document.location.href = target.authUrl;
          } else {
            void this.router.navigate(['/sso/providers'], {
              skipLocationChange: true
            });
          }
        })
      ),
    { dispatch: false }
  );

  public readonly loginFail$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loginFail, oauthFinalizeFail),
      exhaustMap((action: ReturnType<typeof loginFail> | ReturnType<typeof oauthFinalizeFail>) => {
        let postLoginAction: Action | null;

        switch (action.payload.error) {
          case 'invalid_email':
            postLoginAction = invalidOAuthEmail(action.payload);
            break;
          case 'mfa_required':
            postLoginAction = mfaRequired(action.payload);
            break;
          case 'sso_required':
            postLoginAction = ssoRequired(action.payload);
            break;
          case 'password_change_required':
            postLoginAction = passwordChangeRequired(action.payload);
            break;
          case 'email_confirm_required':
            postLoginAction = confirmRequired(action.payload);
        }

        return postLoginAction ? [postLoginAction] : [];
      })
    )
  );

  public readonly ssoInitFail$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ssoInitFail),
        tap((action: ReturnType<typeof ssoInitFail>) => {
          if (action.payload.redirectToLogin) {
            void this.router.navigate(['/login']);
          }
        })
      ),
    { dispatch: false }
  );

  public readonly oauthFinalize$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(oauthFinalize),
      exhaustMap((action: ReturnType<typeof oauthFinalize>) =>
        this.authService.socialLogin(action.payload.providerType, action.payload.params).pipe(
          map(() => oauthFinalizeSuccess()),
          catchError((err: HttpErrorResponse) => of(oauthFinalizeFail(err.error)))
        )
      )
    )
  );

  public readonly oauthDisconnect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(oauthDisconnect),
      exhaustMap((action: ReturnType<typeof oauthDisconnect>) =>
        this.baseService.oauthDisconnect(action.payload).pipe(
          map(() => oauthDisconnectSuccess()),
          catchError((err: HttpErrorResponse) => of(oauthDisconnectFail(err.error)))
        )
      )
    )
  );

  public readonly ssoRequired$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ssoRequired),
      withLatestFrom(this.store.select(selectTempToken)),
      map(([, token]) => ssoInit({ token }))
    )
  );

  public readonly invalidOAuthEmail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(invalidOAuthEmail),
        tap(() => {
          void this.router.navigateByUrl('/signup');
          this.snackbarService.open('Please use your company email account', true, {
            timeOut: 10000
          });
        })
      ),
    { dispatch: false }
  );

  public readonly emailConfirmationRequired$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(confirmRequired),
        tap((action: ReturnType<typeof confirmRequired>) =>
          this.router.navigate(['/confirm-create-user'], {
            queryParams: action.payload
          })
        )
      ),
    { dispatch: false }
  );

  public readonly mfaRequired$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(mfaRequired),
        tap(() => this.router.navigateByUrl('/mfa'))
      ),
    { dispatch: false }
  );

  public readonly passwordChangeRequired$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(passwordChangeRequired),
        withLatestFrom(this.store.select(selectTempToken)),
        tap(([, token]) => this.router.navigate([`forgot-password/${token}`]))
      ),
    { dispatch: false }
  );

  public readonly loginRedirect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginRedirect),
      tap((action: ReturnType<typeof loginRedirect>) => {
        this.locationStorageService.store(action.payload.location);

        if (action.payload.notification) {
          this.snackbarService.open(action.payload.notification, true, {
            timeOut: 10000
          });
        }
      }),
      exhaustMap(() => [closeAllDialogs(), logout({ skipRouting: false })])
    )
  );

  public readonly oauthFinalizeFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(oauthFinalizeFail),
        filter(
          (action: ReturnType<typeof oauthFinalizeFail>) => !this.isOAuthEmailError(action.payload)
        ),
        tap(() =>
          this.userSessionStorageService.hasLoggedIn()
            ? this.locationStorageService.restore()
            : this.router.navigateByUrl('/login')
        )
      ),
    { dispatch: false }
  );

  public readonly logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logout),
        exhaustMap((action) =>
          this.authService
            .logout()
            .pipe(
              map((res) => ({ logoutUrl: res?.logoutUrl, skipRouting: action.payload.skipRouting }))
            )
        ),
        tap(({ skipRouting, logoutUrl }) => {
          if (!skipRouting && !logoutUrl) {
            void this.router.navigateByUrl('/login');
          }
          if (logoutUrl) {
            this.document.location.href = logoutUrl;
          }
        })
      ),
    { dispatch: false }
  );

  public readonly createRequiredOrg$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createRequiredOrg),
      exhaustMap((action: ReturnType<typeof createRequiredOrg>) => {
        const marketplaceCallbackState = this.marketplaceCallbackStateStorageService.get();

        return this.baseService
          .createRequiredOrg({
            ...action.payload,
            ...(marketplaceCallbackState ? { marketplaceCallbackState } : {})
          })
          .pipe(
            tap(() => this.marketplaceCallbackStateStorageService.clear()),
            map(() => createRequiredOrgSuccess()),
            catchError((err: HttpErrorResponse) => of(createRequiredOrgFail(err.error)))
          );
      })
    )
  );

  public readonly refreshUserInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        loginSuccess,
        oauthFinalizeSuccess,
        createRequiredOrgSuccess,
        verifyMfaOtpSuccess,
        enableMfaSuccess,
        acceptInviteSuccess,
        resetPasswordSuccess
      ),
      filter((action) => action.type !== enableMfaSuccess.type || action.payload.forcedSetup),
      exhaustMap((action: Action) => [
        loadUserInfo({
          skipNavigation: false,
          welcomeWizard:
            createRequiredOrgSuccess.type === action.type ||
            acceptInviteSuccess.type === action.type
        })
      ])
    )
  );

  public readonly loadUserInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserInfo),
      exhaustMap((action: ReturnType<typeof loadUserInfo>) =>
        this.baseService.loadUserInfo().pipe(
          map((res: LoggedInUser) =>
            loadUserInfoSuccess({
              userInfo: res,
              skipNavigation: action.payload.skipNavigation,
              welcomeWizard: action.payload.welcomeWizard
            })
          ),
          catchError((err: HttpErrorResponse) => of(loadUserInfoFail(err.error)))
        )
      )
    )
  );

  public readonly loadUserInfoSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadUserInfoSuccess),
        tap((action: ReturnType<typeof loadUserInfoSuccess>) => {
          this.externalErrorReporter.setUser(action.payload.userInfo);

          const { skipNavigation, userInfo, welcomeWizard } = action.payload;
          if (!skipNavigation && this.router.url !== userInfo?.nextUrl) {
            if (userInfo.nextUrl === '/') {
              if (welcomeWizard) {
                void this.router.navigateByUrl('/scans(dialog:wizard)');
              } else {
                void this.locationStorageService.restore();
              }
            } else {
              void this.router.navigateByUrl(userInfo.nextUrl);
            }
          }
        })
      ),
    { dispatch: false }
  );

  public readonly activateOnboarding$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createRequiredOrgSuccess, acceptInviteSuccess),
      map(() => activateOnboarding())
    )
  );

  public readonly deactivateLayout$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<Action>(
        createRequiredOrg,
        loadUserInfo,
        login,
        oauthFinalize,
        ssoInit,

        forgotPassword,
        resetPassword
      ),
      exhaustMap(() => [deactivateLayout()])
    )
  );

  public readonly activateLayout$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<Action>(
        createRequiredOrgFail,
        createRequiredOrgSuccess,
        loadUserInfoFail,
        loadUserInfoSuccess,
        loginFail,
        loginSuccess,
        oauthFinalizeFail,
        oauthFinalizeSuccess,
        ssoInitFail,
        ssoInitSuccess,

        forgotPasswordFail,
        forgotPasswordSuccess,
        resetPasswordFail,
        resetPasswordSuccess
      ),
      exhaustMap(() => [activateLayout()])
    )
  );

  private isOAuthEmailError(payload: AuthError): payload is InvalidOAuthEmailError {
    return payload.error === 'invalid_email';
  }

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly store: Store,
    private readonly snackbarService: SnackbarService,
    private readonly authService: AuthService,
    private readonly baseService: BaseService,
    private readonly externalErrorReporter: ExternalErrorReporterService,
    private readonly locationStorageService: LocationStorageService,
    private readonly userSessionStorageService: UserSessionStorageService,
    private readonly marketplaceCallbackStateStorageService: MarketplaceCallbackStateStorageService
  ) {}
}

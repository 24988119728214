import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AvailableExternalScanConfigSource, Board, ServiceName } from '@neuralegion/api';
import { AzureOrg, Integration, SnykOrg } from '../models';

@Injectable({ providedIn: 'root' })
export class IntegrationService {
  constructor(private readonly http: HttpClient) {}

  public addIntegration(integration: Omit<Integration, 'id'>): Observable<{ backToUrl?: string }> {
    return this.http.post<{ backToUrl?: string }>(`/api/v1/integrations`, integration);
  }

  public addGitlabIntegration(integration: Omit<Integration, 'id'>): Observable<void> {
    return this.http.post<void>(`/api/v1/integrations/gitlab/install`, integration);
  }

  public updateIntegration(integration: Integration): Observable<void> {
    return this.http.put<void>(`/api/v1/integrations/${integration.service}`, integration);
  }

  public removeIntegration(service: ServiceName, integrationId: string): Observable<void> {
    return this.http.delete<void>(`/api/v1/integrations/${service}/${integrationId}`);
  }

  public loadIntegrations(): Observable<Integration[]> {
    return this.http.get<Integration[]>(`/api/v1/integrations`);
  }

  public loadAzureOrgs(): Observable<AzureOrg[]> {
    return this.http.get<AzureOrg[]>(`/api/v1/integrations/azure/orgs`);
  }

  public loadSnykOrgs(integrationId?: string): Observable<SnykOrg[]> {
    return this.http.get<SnykOrg[]>(`/api/v1/integrations/snyk/orgs`, {
      params: integrationId
        ? {
            integrationId
          }
        : undefined
    });
  }

  public loadAvailableBoards(serviceName: ServiceName, integrationId: string): Observable<Board[]> {
    return this.http.get<Board[]>(`/api/v1/integrations/${serviceName}/boards`, {
      params: {
        integrationId
      }
    });
  }

  public loadAvailableIssueSources(
    serviceName: ServiceName,
    integrationId: string
  ): Observable<AvailableExternalScanConfigSource[]> {
    return this.http.get<AvailableExternalScanConfigSource[]>(
      `/api/v1/integrations/${serviceName}/issue-sources`,
      {
        params: {
          integrationId
        }
      }
    );
  }
}

import { Action, createReducer, on } from '@ngrx/store';
import { cleanupPending } from '@neuralegion/core';
import { FeedActivity } from '../models';
import {
  clear,
  clearAll,
  clearAllFail,
  clearAllSuccess,
  clearFail,
  clearSuccess,
  closeFeed,
  loadFeedNext,
  loadFeedNextFail,
  loadFeedNextSuccess,
  loadFeedStart,
  loadSubscriptions,
  loadSubscriptionsFail,
  loadSubscriptionsSuccess,
  markAllAsSeen,
  markAllAsSeenFail,
  markAllAsSeenSuccess,
  markAsSeen,
  markAsSeenFail,
  markAsSeenSuccess,
  openFeed,
  toggleFeed,
  updateSubscriptions,
  updateSubscriptionsFail,
  updateSubscriptionsSuccess
} from './feed.actions';
import { FEED_LOAD_LIMIT, FeedState, initialFeedState } from './feed.state';

export const feedReducer = createReducer<FeedState, Action>(
  initialFeedState,
  on(
    openFeed,
    (state: FeedState): FeedState => ({
      ...state,
      opened: true
    })
  ),
  on(
    closeFeed,
    (state: FeedState): FeedState => ({
      ...state,
      opened: false
    })
  ),
  on(
    toggleFeed,
    (state: FeedState): FeedState => ({
      ...state,
      opened: !state.opened
    })
  ),
  on(
    loadFeedStart,
    (state: FeedState): FeedState => ({
      ...state,
      activities: [],
      offset: 0,
      endReached: false
    })
  ),
  on(
    loadFeedNext,
    markAsSeen,
    markAllAsSeen,
    clear,
    clearAll,
    loadSubscriptions,
    updateSubscriptions,
    (state: FeedState, action): FeedState => ({
      ...state,
      pending: [...state.pending, action],
      error: null
    })
  ),
  on(
    loadFeedNextSuccess,
    (state: FeedState, action): FeedState => ({
      ...state,
      endReached: action.payload.activities.length < FEED_LOAD_LIMIT,
      offset: state.offset + FEED_LOAD_LIMIT,
      unseen: action.payload.unseen,
      activities: [...state.activities, ...action.payload.activities],
      lastSuccess: action,
      pending: cleanupPending(state.pending, action.parentType),
      error: null
    })
  ),
  on(
    clearSuccess,
    (state: FeedState, action): FeedState => ({
      ...state,
      activities: state.activities.filter(
        (activity: FeedActivity) => action.payload !== activity.id
      ),
      lastSuccess: action,
      pending: cleanupPending(state.pending, action.parentType),
      error: null
    })
  ),
  on(
    clearAllSuccess,
    (state: FeedState, action): FeedState => ({
      ...state,
      unseen: 0,
      activities: [],
      lastSuccess: action,
      pending: cleanupPending(state.pending, action.parentType),
      error: null
    })
  ),
  on(markAsSeenSuccess, markAllAsSeenSuccess, (state: FeedState, action): FeedState => {
    const ids = action.type === markAsSeenSuccess.type && action.payload;
    return {
      ...state,
      unseen: Math.max(0, ids ? state.unseen - ids.length : 0),
      activities: state.activities.map(
        (activity: FeedActivity): FeedActivity => ({
          ...activity,
          isSeen: !ids || ids.includes(activity.id) ? true : activity.isSeen
        })
      ),
      lastSuccess: action,
      pending: cleanupPending(state.pending, action.parentType),
      error: null
    };
  }),
  on(
    loadSubscriptionsSuccess,
    updateSubscriptionsSuccess,
    (state: FeedState, action): FeedState => ({
      ...state,
      subscriptions: action.payload,
      lastSuccess: action,
      pending: cleanupPending(state.pending, action.parentType),
      error: null
    })
  ),
  on(
    loadFeedNextFail,
    markAsSeenFail,
    markAllAsSeenFail,
    clearFail,
    clearAllFail,
    loadSubscriptionsFail,
    updateSubscriptionsFail,
    (state: FeedState, action): FeedState => ({
      ...state,
      pending: cleanupPending(state.pending, action.parentType),
      error: action.payload
    })
  )
);

import { Pipe, PipeTransform } from '@angular/core';
import { FindPipe } from './find.pipe';
import { SimplePluralPipe } from './simple-plural.pipe';

@Pipe({
  name: 'selectTriggerLabel',
  pure: true
})
export class SelectTriggerLabelPipe<T extends { id: string; label: string }>
  implements PipeTransform
{
  private readonly findPipe = new FindPipe<T>();
  private readonly simplePluralPipe = new SimplePluralPipe();

  public transform(value: string | string[], items: T[], itemName: string): string {
    if (!Array.isArray(value)) {
      return this.getLabel(value, items);
    }

    if (value?.length === 1) {
      return this.getLabel(value[0], items);
    }

    return this.simplePluralPipe.transform(value?.length || 0, itemName);
  }

  private getLabel(value: string, items: T[]): string {
    return this.findPipe.transform(value, items)?.label;
  }
}

import { BaseElementIdStrategy } from './base-element-id-strategy';
import { ButtonElementIdStrategy } from './button-element-id-strategy';
import { MatSelectElementIdStrategy } from './mat-select-element-id-strategy';

export class MatPaginatorElementIdStrategy extends BaseElementIdStrategy {
  private readonly elementPrefix = 'paginator';
  private readonly pageSizeElementPrefix = ['form', 'field', 'page', 'size'].join(
    this.SEPARATOR_INTERNAL
  );
  private readonly rangeLabelElementPrefix = ['range', 'label'].join(this.SEPARATOR_INTERNAL);

  public override applyId(element: HTMLElement, dataId: string): void {
    super.applyId(element, dataId);
    this.applyIdToButtons(element, dataId);
    this.applyIdToPageSizeFormField(element, dataId);
    this.applyIdToRangeLabel(element, dataId);
    this.applyIdToPageSizeSelect(element, dataId);
  }

  protected override getSubId(): string {
    return this.elementPrefix;
  }

  private applyIdToButtons(element: HTMLElement, dataId: string): void {
    const strategy = new ButtonElementIdStrategy(this.renderer);
    [...element.querySelectorAll<HTMLElement>('.mat-mdc-paginator-range-actions button')].forEach(
      (el: HTMLElement) => super.applyId(el, strategy.calculateId(el, dataId))
    );
  }

  private applyIdToPageSizeFormField(element: HTMLElement, dataId: string): void {
    this.applyIdIfExists(
      element.querySelector<HTMLElement>('.mat-mdc-paginator-page-size mat-form-field'),
      dataId,
      this.pageSizeElementPrefix
    );
  }

  private applyIdToRangeLabel(element: HTMLElement, dataId: string): void {
    this.applyIdIfExists(
      element.querySelector<HTMLElement>('.mat-mdc-paginator-range-label'),
      dataId,
      this.rangeLabelElementPrefix
    );
  }

  private applyIdIfExists(element: HTMLElement | null, dataId: string, subId: string): void {
    if (element) {
      super.applyId(element, this.calculateId(element, dataId, subId));
    }
  }

  private applyIdToPageSizeSelect(element: HTMLElement, dataId: string): void {
    const selectElement = element.querySelector<HTMLElement>(
      '.mat-mdc-paginator-page-size mat-select'
    );
    if (selectElement) {
      const strategy = new MatSelectElementIdStrategy(this.renderer);
      super.applyId(selectElement, strategy.calculateId(selectElement, dataId));
    }
  }
}

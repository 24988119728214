import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { ValidationErrorCode } from '../models';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[matErrorTmpl]'
})
export class MatErrorOverrideDirective {
  @Input('matErrorTmpl')
  public errorCode: ValidationErrorCode;

  @Input('matErrorTmplPriority')
  public priority: number;

  constructor(
    public template: TemplateRef<{ $implicit: unknown }>,
    public viewContainerRef: ViewContainerRef
  ) {}
}

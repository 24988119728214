import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MultiFactorService } from '../services';
import { MultiFactorEffects } from '../store/multi-factor.effects';
import { multiFactorReducer } from '../store/multi-factor.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature('mfa', multiFactorReducer),
    EffectsModule.forFeature([MultiFactorEffects])
  ],
  providers: [MultiFactorService]
})
export class MultiFactorApiModule {}

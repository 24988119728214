export enum FormValidationError {
  REQUIRED = 'required',
  MIN = 'min',
  MAX = 'max',
  MINLENGTH = 'minlength',
  MAXLENGTH = 'maxlength',
  URL = 'url',
  NEXTEMPLATE = 'nexTemplate',
  PATTERN = 'pattern',
  DUPLICATE = 'duplicate',
  DESERIALIZATION = 'deserialization',
  EMAIL = 'email',
  HOST = 'host',
  IP = 'ip',
  REGEXP = 'regExp',
  XPATH = 'xpath',
  CC_NUMBER = 'ccNumber',
  EXP_DATE = 'expDate',
  LIST_LENGTH_EXCEEDED = 'listLengthExceeded',
  DUPLICATED_LIST_ITEM = 'duplicatedListItem',
  MIN_START_TIME = 'minStartTime',
  HEADER_NAMES = 'headerNames',
  HEADER_NAME = 'headerName',
  HEADER_VALUE = 'headerValue',
  WILDCARD_HOST = 'wildcardHost',
  MAT_DATEPICKER_MIN = 'matDatepickerMin',
  MAT_DATEPICKER_MAX = 'matDatepickerMax',
  EMPTY_RELATED = 'emptyRelated',
  INTEGER = 'integer',
  BYTE_SIZE = 'byteSize',
  BLOCK_LIST = 'blockList',
  HTTP_STATUSES = 'httpStatuses',
  CARD_EXPIRED = 'cardExpired',
  ENTITY_ID = 'entityId',
  COMPANY_EMAIL = 'companyEmail',
  FILE_NOT_READY = 'fileNotReady',
  FILE_NOT_ALLOWED = 'fileNotAllowed',
  AUTH_OBJECT_PROJECTS_EQUALS_SELECTED_PROJECT = 'authObjectProjectsEqualsSelectedProject',
  AUTH_OBJECT_REQUIRES_REPEATER = 'authObjectRequiresRepeater'
}

export type ValidationErrorCode = `${FormValidationError}`;

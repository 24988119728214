<sat-popover xAlign="end" yAlign="below">
  <div class="popover-container" [attr.data-id]="'overlay_date_range_filter'" fxLayout="column">
    <button class="text-button reset-button" fxFlexAlign="end" mat-button (click)="reset()">Reset</button>
    <share-calendar-range-picker
      [dateRange]="dateRange"
      [maxDate]="maxDate"
      [minDate]="minDate"
      (dateRangeChanged)="onDateRangeChange($event)">
    </share-calendar-range-picker>
  </div>
</sat-popover>

<span>
  <mat-form-field [satPopoverAnchor]="popover">
    <input
      aria-label="Date picker"
      tabindex="-1"
      matInput
      readonly
      [placeholder]="placeholder"
      [sharePopoverTrigger]="popover"
      [value]="dateRange | dateRange" />

    <button
      [attr.aria-label]="'Open date picker'"
      mat-icon-button
      matSuffix
      matTooltip="Open date picker"
      [sharePopoverTrigger]="popover">
      <mat-icon>date_range</mat-icon>
    </button>
  </mat-form-field>
</span>

import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AuthProvidersService, GithubClientService } from '../services';
import { AuthProvidersEffects } from '../store/auth-providers.effects';
import { authProvidersAPIReducer } from '../store/reducers';

@NgModule({
  imports: [
    StoreModule.forFeature('auth-providers', authProvidersAPIReducer),
    EffectsModule.forFeature([AuthProvidersEffects])
  ],
  providers: [AuthProvidersService, GithubClientService]
})
export class AuthProvidersApiModule {}

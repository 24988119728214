import {
  AfterViewInit,
  Directive,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  AbstractControl,
  AbstractControlOptions,
  ControlValueAccessor,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validator,
  ValidatorFn
} from '@angular/forms';
import {
  BehaviorSubject,
  Subject,
  combineLatest,
  debounceTime,
  filter,
  merge,
  scan,
  takeUntil
} from 'rxjs';
import { trackById } from '@neuralegion/core';
import { extractTouchedChanges, updateTreeValidity } from '../form';
import { SettingsFormService, SettingsFormServiceSection } from '../services';

export interface SubFormTouchedState {
  control: string;
  touched: boolean;
}

interface ControlToAttach {
  key: string;
  control: AbstractControl;
}

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class SettingsSubFormComponent<FV, S extends number | string, EV = FV>
  implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy, Validator
{
  @Input()
  set section(
    section: SettingsFormServiceSection<S> | [string, SettingsFormServiceSection<S>, ...string[]]
  ) {
    this.sectionSubject.next(
      Array.isArray(section) ? (section.join('.') as SettingsFormServiceSection<S>) : section
    );
  }

  get section(): SettingsFormServiceSection<S> {
    return this.sectionSubject.getValue();
  }

  @Output()
  public readonly touchedChanged = new EventEmitter<SubFormTouchedState[]>();

  public formControl: FormGroup;
  public readonly trackById = trackById;

  protected sectionSubject = new BehaviorSubject<SettingsFormServiceSection<S>>(
    '' as SettingsFormServiceSection<S>
  );
  protected controlsToAttachSubject = new BehaviorSubject<ControlToAttach>(null);

  protected readonly gc = new Subject<void>();
  protected onChange: (value: EV) => void;
  protected onTouched: () => void;

  protected initialValue: FV;
  protected parentControl: AbstractControl;
  protected revisited = false;
  protected onTouchedWaiting = false;

  constructor(protected readonly settingsFormService: SettingsFormService<S>) {
    this.initAttachControlsListener();
  }

  public ngOnInit(): void {
    this.initFormState();

    this.formControl.updateValueAndValidity();

    extractTouchedChanges(this.formControl)
      .pipe(
        filter((touched) => touched),
        takeUntil(this.gc)
      )
      .subscribe(() => {
        this.recalculateTouchedState();
        if (!this.onTouched) {
          this.onTouchedWaiting = true;
        } else {
          this.onTouched();
        }
        updateTreeValidity(this.formControl);
      });

    this.settingsFormService.revisitedSection$
      .pipe(
        filter((section) => `${this.sectionSubject.getValue()}`.startsWith(`${section}`)),
        takeUntil(this.gc)
      )
      .subscribe(() => {
        this.revisited = true;
        this.formControl.markAllAsTouched();
        updateTreeValidity(this.formControl);
      });
  }

  public ngAfterViewInit(): void {
    this.initialValue = this.formControl.getRawValue();
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }

  public registerOnChange(onChange: (value: EV) => void): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
    if (this.onTouchedWaiting) {
      this.onTouched();
      this.onTouchedWaiting = false;
    }
  }

  public setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }

    this.updateSectionCountersState();
  }

  public writeValue(value: EV): void {
    if (!value) {
      if (this.initialValue) {
        this.formControl.reset({ ...this.initialValue });
      }
      return;
    }

    this.formControl.patchValue({ ...this.mapToFormValue(value) });
  }

  public validate(parentControl: AbstractControl): Record<string, ValidationErrors | null> {
    if (!this.parentControl) {
      this.parentControl = parentControl;
    }

    return this.validateControlsTree(this.formControl);
  }

  protected recalculateTouchedState(): void {
    this.touchedChanged.emit(this.collectTreeTouched(this.formControl));
  }

  protected createControl<T>(
    key: keyof T & string,
    initialValue?: unknown,
    options?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null
  ): FormControl {
    const control = new FormControl(initialValue, options);
    this.attachControl(key, control);
    return control;
  }

  protected createFormGroup(
    section: string,
    controls: Record<string, AbstractControl>,
    options?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null
  ): FormGroup {
    const groupControl = new FormGroup(controls, options);
    this.attachControl(section, groupControl);

    Object.entries(controls).forEach(([key, control]) =>
      this.attachControl(`${section}.${key}`, control)
    );
    return groupControl;
  }

  protected attachControl(key: string, control: AbstractControl) {
    this.controlsToAttachSubject.next({ key, control });
  }

  protected detachNestedSections(subSection: string): void {
    this.settingsFormService.detachNestedSections(
      `${this.sectionSubject.getValue()}${subSection ? `.${subSection}` : ''}`
    );
  }

  protected updateSectionCountersState(): void {
    this.settingsFormService.updateSectionCountersState(this.sectionSubject.getValue());
  }

  protected initFormState(): void {
    merge(this.formControl.valueChanges, this.formControl.statusChanges)
      .pipe(debounceTime(100), takeUntil(this.gc))
      .subscribe(() => {
        this.onTouched?.();
        this.onChange?.(this.mapFromFormValue(this.formControl.getRawValue()));
        this.updateSectionCountersState();
      });
  }

  protected mapFromFormValue(value: FV): EV {
    return value as unknown as EV;
  }

  protected mapToFormValue(value: EV): FV {
    return value as unknown as FV;
  }

  private validateControlsTree(formGroup: FormGroup): Record<string, ValidationErrors | null> {
    return Object.entries(formGroup.controls).reduce(
      (
        errors: Record<string, ValidationErrors | null>,
        [controlName, control]: [string, AbstractControl]
      ) => {
        let controlErrors: Record<string, ValidationErrors | null> = {};
        if (control instanceof FormGroup) {
          controlErrors = this.validateControlsTree(control);
        }

        return {
          ...errors,
          ...(control?.errors ? { [controlName]: control.errors } : {}),
          ...controlErrors
        };
      },
      {}
    );
  }

  private initAttachControlsListener(): void {
    const controlsToAttach$ = this.controlsToAttachSubject.pipe(
      scan(
        (controlsToAttach: ControlToAttach[], control) =>
          control ? [...controlsToAttach, control] : [],
        []
      )
    );

    combineLatest([this.sectionSubject, controlsToAttach$])
      .pipe(
        filter(([section, controls]) => String(section).length > 0 && controls.length > 0),
        takeUntil(this.gc)
      )
      .subscribe(([section, controls]: [SettingsFormServiceSection<S>, ControlToAttach[]]) => {
        controls.forEach((control: ControlToAttach) =>
          this.settingsFormService.attachControl(`${section}.${control.key}`, control.control)
        );

        this.controlsToAttachSubject.next(null);
      });
  }

  private collectTreeTouched(target: FormGroup | FormArray): SubFormTouchedState[] {
    return Object.entries(target.controls).reduce(
      (touchedStates: SubFormTouchedState[], [controlName, control]: [string, AbstractControl]) => {
        let childrenTouchedState: SubFormTouchedState[] = [];
        if (control instanceof FormGroup || control instanceof FormArray) {
          childrenTouchedState = this.collectTreeTouched(control);
        }

        return [
          ...touchedStates,
          { control: controlName, touched: control?.touched },
          ...childrenTouchedState
        ];
      },
      []
    );
  }
}

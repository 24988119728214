export enum ProviderType {
  GITHUB = 'github',
  GOOGLE = 'google',
  OKTA = 'okta',
  ADFS = 'adfs',
  SAML = 'saml'
}

export const IDENTITY_PROVIDERS: readonly ProviderType[] = [
  ProviderType.GITHUB,
  ProviderType.GOOGLE
];

// ADHOC: temporarily disable GitHub SSO
export const ENTERPRISE_PROVIDERS: readonly ProviderType[] = [
  ProviderType.GOOGLE,
  ProviderType.OKTA,
  ProviderType.ADFS,
  ProviderType.SAML
];

import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CSSBeautifyOptions, HTMLBeautifyOptions, JSBeautifyOptions } from 'js-beautify';
import { Headers } from '@neuralegion/api';

type BeautifyOptions = CSSBeautifyOptions | HTMLBeautifyOptions | JSBeautifyOptions;

interface JsBeautify {
  js: (source: string, options?: JSBeautifyOptions) => string;
  css: (source: string, options?: CSSBeautifyOptions) => string;
  html: (source: string, options?: HTMLBeautifyOptions) => string;
}

export interface ReformatOptions {
  language?: string | Headers;
  jsBeautifyOptions?: BeautifyOptions;
}

/* eslint-disable @typescript-eslint/naming-convention */
const defaultOptions: BeautifyOptions = {
  indent_size: 2,
  indent_char: ' ',
  max_preserve_newlines: 1,
  wrap_line_length: 120
};
/* eslint-enable @typescript-eslint/naming-convention */

/**
 * Uses https://github.com/beautify-web/js-beautify
 * which reformats and re-indents html/css/js as well as partly deobfuscates scripts.
 */
@Injectable({
  providedIn: 'root'
})
export class CodeReformatService {
  private readonly jsBeautifyPromise: Promise<JsBeautify>;

  constructor() {
    this.jsBeautifyPromise = this.loadJsBeautify();
  }

  public async reformat(code: string, opts: ReformatOptions = {}): Promise<string> {
    if (!code) {
      return code;
    }

    const jsBeautify: JsBeautify = await this.jsBeautifyPromise;
    const beautifyOptions: BeautifyOptions = {
      ...(opts.jsBeautifyOptions || {}),
      ...defaultOptions
    };

    const lang = typeof opts.language === 'string' ? opts.language : this.langByMIME(opts.language);
    switch (lang) {
      case 'css':
        return jsBeautify.css(code, beautifyOptions);
      case 'js':
        return jsBeautify.js(code, beautifyOptions as JSBeautifyOptions);
      case 'html':
        return jsBeautify.html(code, beautifyOptions);
      default:
        return code;
    }
  }

  public langByMIME(headers: Headers): string {
    const type: string = new HttpHeaders(headers).get('Content-Type');

    if (!type) {
      return null;
    }

    if (type.includes('html') || type.includes('xml')) {
      return 'html';
    }

    if (type.includes('javascript') || type.includes('json')) {
      return 'js';
    }

    if (type.includes('css')) {
      return 'css';
    }

    return null;
  }

  private async loadJsBeautify(): Promise<JsBeautify> {
    const jsBeautify: { default: JsBeautify } = await import(
      /* webpackChunkName: "js-beautify" */ 'js-beautify'
    );
    if (!jsBeautify.default) {
      throw new Error('Cannot load js-beautify');
    }
    return jsBeautify.default;
  }
}

import { Observable, Subject } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PlainObject = any;

export class BasicStorageService implements Map<string, PlainObject> {
  public readonly [Symbol.toStringTag]: 'Map';

  private readonly changesSubject: Subject<string> = new Subject<string>();
  public readonly changes$: Observable<string> = this.changesSubject.asObservable();

  constructor(private readonly storage: Storage) {}

  get size(): number {
    return this.storage.length;
  }

  public [Symbol.iterator](): IterableIterator<[string, PlainObject]> {
    return this.entries();
  }

  public clear(): void {
    this.storage.clear();
  }

  public delete(key: string): boolean {
    this.storage.removeItem(key);
    return this.has(key);
  }

  public entries(): IterableIterator<[string, PlainObject]> {
    const arr = Object.entries(this.storage).map(([key, value]): [string, PlainObject] => [
      key,
      this.parseJSON(value)
    ]);
    return arr[Symbol.iterator]();
  }

  public forEach(
    callbackFn: (value: PlainObject, key: string, map: Map<string, PlainObject>) => void,
    thisArg?: never
  ): void {
    return Array.from(this.entries()).forEach(
      ([key, value]) => callbackFn(value, key, this),
      thisArg
    );
  }

  public get(key: string): PlainObject {
    return this.parseJSON(this.storage.getItem(key));
  }

  public has(key: string): boolean {
    return this.get(key) != null;
  }

  public keys(): IterableIterator<string> {
    return Object.keys(this.storage)[Symbol.iterator]();
  }

  public set(key: string, value: PlainObject): this {
    this.storage.setItem(key, this.serializeObject(value));
    this.changesSubject.next(key);
    return this;
  }

  public values(): IterableIterator<PlainObject> {
    const arr = Object.values(this.storage).map(
      (value: string): PlainObject => this.parseJSON(value)
    );
    return arr[Symbol.iterator]();
  }

  private parseJSON(json: string): PlainObject {
    try {
      return JSON.parse(json);
    } catch (e) {
      return undefined;
    }
  }

  private serializeObject(obj: PlainObject): string | undefined {
    try {
      return JSON.stringify(obj);
    } catch (e) {
      return undefined;
    }
  }
}

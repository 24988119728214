import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CodeEscaperService {
  /* eslint-disable @typescript-eslint/naming-convention */
  private readonly escapeMapping: Record<string, string> = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#39;'
  };
  /* eslint-enable */

  public escapeHtml(unsafe: string): string {
    return !unsafe ? unsafe : unsafe.replace(/[&<>"']/g, (ch: string) => this.escapeMapping[ch]);
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, exhaustMap, map, of, switchMap, withLatestFrom } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Scope } from '@neuralegion/api';
import { selectScopePermission } from '@neuralegion/auth-api';
import { AppRouterState, ofPrimaryRouterNavigated } from '@neuralegion/core';
import { Payment, PaymentResult, UpcomingInvoice } from '../models';
import { PaymentsService } from '../services';
import { billingRefresh } from './billing.actions';
import {
  loadPaymentHistory,
  loadPaymentHistoryFail,
  loadPaymentHistorySuccess,
  loadUpcomingInvoice,
  loadUpcomingInvoiceFail,
  loadUpcomingInvoiceSuccess,
  repay,
  repayFail,
  repaySuccess,
  unsubscribe,
  unsubscribeFail,
  unsubscribeSuccess
} from './payments.actions';

@Injectable()
export class PaymentsEffects {
  public readonly loadPaymentHistory$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPaymentHistory),
      withLatestFrom(this.store.select(selectScopePermission(Scope.PAYMENTS))),
      exhaustMap(
        ([, paymentsPermission]: [
          ReturnType<typeof loadPaymentHistory>,
          boolean
        ]): Observable<Action> =>
          paymentsPermission
            ? this.paymentsService.loadPaymentHistory().pipe(
                map((paymentHistory: Payment[]) => loadPaymentHistorySuccess({ paymentHistory })),
                catchError((err: HttpErrorResponse) => of(loadPaymentHistoryFail(err.error)))
              )
            : of(loadPaymentHistorySuccess({ paymentHistory: [] }))
      )
    )
  );

  public readonly loadUpcomingInvoice$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUpcomingInvoice),
      withLatestFrom(this.store.select(selectScopePermission(Scope.PAYMENTS))),
      exhaustMap(
        ([
          {
            payload: { planId, coupon }
          },
          paymentsPermission
        ]: [ReturnType<typeof loadUpcomingInvoice>, boolean]): Observable<Action> =>
          paymentsPermission
            ? this.paymentsService.loadUpcomingInvoice(planId, coupon).pipe(
                map((upcomingInvoice: UpcomingInvoice) =>
                  loadUpcomingInvoiceSuccess({ upcomingInvoice })
                ),
                catchError((err: HttpErrorResponse) => of(loadUpcomingInvoiceFail(err.error)))
              )
            : of(loadUpcomingInvoiceFail('Not allowed'))
      )
    )
  );

  public readonly repay$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(repay),
      switchMap(
        ({
          payload: { paymentId, paymentMethodId }
        }: ReturnType<typeof repay>): Observable<Action> =>
          this.paymentsService.repayInvoice(paymentId, paymentMethodId).pipe(
            map((paymentResult: PaymentResult) =>
              repaySuccess({ paymentId, paymentMethodId, paymentResult })
            ),
            catchError((err: HttpErrorResponse) => of(repayFail(err.error)))
          )
      )
    )
  );

  public readonly unsubscribe$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(unsubscribe),
      switchMap(
        (action: ReturnType<typeof unsubscribe>): Observable<Action> =>
          this.paymentsService.removeSubscription(action.payload.subscriptionId).pipe(
            map(() => unsubscribeSuccess()),
            catchError((err: HttpErrorResponse) => of(unsubscribeFail(err.error)))
          )
      )
    )
  );

  public readonly unsubscribeSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(unsubscribeSuccess),
      map(() => billingRefresh())
    )
  );

  public readonly refresh$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(billingRefresh),
      map(() => loadPaymentHistory())
    )
  );

  public readonly navigated$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofPrimaryRouterNavigated((state: AppRouterState) => state.pathname.startsWith('/billing')),
      switchMap(() => [loadPaymentHistory()])
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly paymentsService: PaymentsService
  ) {}
}

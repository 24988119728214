import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DateSettings, MemberNotificationSettings } from '@neuralegion/api';
import { ProfileSettingsState } from './profile-settings.state';

// eslint-disable-next-line @typescript-eslint/ban-types
type State = object;
export const selectProfileSettingsState =
  createFeatureSelector<ProfileSettingsState>('profileSettings');

export const selectNotificationSettings = createSelector<
  State,
  [ProfileSettingsState],
  MemberNotificationSettings
>(selectProfileSettingsState, (state: ProfileSettingsState) => state.notifications);

export const selectDateSettings = createSelector<State, [ProfileSettingsState], DateSettings>(
  selectProfileSettingsState,
  (state: ProfileSettingsState) => state.dateSettings
);

export const selectProfileSettingsPendingStatus = createSelector<
  State,
  [ProfileSettingsState],
  boolean
>(selectProfileSettingsState, (state: ProfileSettingsState) => state.pending);

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { ApiVersionService, HttpInterceptorVersionHandlerService } from '../services';
import { UpdaterEffects } from '../store/updater.effects';

@NgModule({
  imports: [EffectsModule.forFeature([UpdaterEffects])],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorVersionHandlerService,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (apiVersionService: ApiVersionService) => (): void =>
        apiVersionService.clearApiVersion(),
      deps: [ApiVersionService],
      multi: true
    },
    ApiVersionService
  ]
})
export class UpdaterModule {}

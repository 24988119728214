import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ConfiguredProvider,
  LoggedInUser,
  Scope,
  ScopeGroup,
  ScopePermissions
} from '@neuralegion/api';
import { AuthState } from './auth.state';

type State = object;

export const selectAuthState = createFeatureSelector<AuthState>('auth');

export const selectAccessFailedCount = createSelector<State, [AuthState], number>(
  selectAuthState,
  (state: AuthState) => state.accessFailedCount
);

export const selectTempToken = createSelector<State, [AuthState], string>(
  selectAuthState,
  (state: AuthState) => state.token
);

export const selectSsoProvider = createSelector<State, [AuthState], ConfiguredProvider>(
  selectAuthState,
  (state: AuthState) => state.provider
);

export const selectOnboardingActive = createSelector<State, [AuthState], boolean>(
  selectAuthState,
  (state: AuthState) => state.onboardingActive
);

export const selectUserInfo = createSelector<State, [AuthState], LoggedInUser>(
  selectAuthState,
  (state: AuthState) => state.userInfo
);

export const selectOwnMemberId = createSelector<State, [LoggedInUser], string>(
  selectUserInfo,
  (userInfo: LoggedInUser) => userInfo?.memberId
);

export const selectOwnOrganizationId = createSelector<State, [LoggedInUser], string>(
  selectUserInfo,
  (userInfo: LoggedInUser) => userInfo?.organizationId
);

export const selectUserScopes = createSelector<State, [LoggedInUser], ReadonlySet<Scope>>(
  selectUserInfo,
  (userInfo: LoggedInUser) => new Set<Scope>(userInfo?.scopes || [])
);

const getParentScope = (scope: Scope): Scope => scope.toString().split(':')[0] as Scope;

const hasScope = (scopes: ReadonlySet<Scope>, scope: Scope) => {
  if (scopes.has(scope)) {
    return true;
  }

  const parentScope = getParentScope(scope);
  return (
    scopes.has(parentScope) ||
    (parentScope !== scope && scopes.has(`${parentScope}:admin` as Scope))
  );
};

export const selectScopePermissions = createSelector<State, [ReadonlySet<Scope>], ScopePermissions>(
  selectUserScopes,
  (existingScopes: ReadonlySet<Scope>) =>
    Object.values(Scope).reduce(
      (res: ScopePermissions, scope: Scope) => ({
        ...res,
        [scope]: hasScope(existingScopes, scope)
      }),
      {} as ScopePermissions
    )
);

export const selectScopePermission = (scope: Scope) =>
  createSelector<State, [ScopePermissions], boolean>(
    selectScopePermissions,
    (scopePermissions: ScopePermissions) => scopePermissions[scope]
  );

export const selectOrgPagePermission = createSelector<State, [ReadonlySet<Scope>], boolean>(
  selectUserScopes,
  (userScopes: ReadonlySet<Scope>) =>
    [Scope.ORG, Scope.ORG_READ].some((scope: Scope) => userScopes.has(scope)) &&
    [
      Scope.AUTH_PROVIDERS,
      Scope.BILLING,
      Scope.GROUPS_MANAGE,
      Scope.INTEGRATIONS_READ,
      Scope.ORG_API_KEYS,
      Scope.ORG_MEMBERSHIPS_MANAGE,
      Scope.ORG_WRITE,
      Scope.PRODUCTS,
      Scope.ROLES_READ
    ].some((scope: Scope) => userScopes.has(scope))
);

export const scopesGroupingComparator = (scopeX: Scope, scopeY: Scope): number => {
  const xParts = scopeX.split(':');
  const yParts = scopeY.split(':');
  for (let i = 0; i < Math.min(xParts.length, yParts.length); ++i) {
    const res = xParts[i].localeCompare(yParts[i]);
    if (res) {
      return res;
    }
  }

  return xParts.length - yParts.length;
};

export const groupScopes = (scopes: Scope[] | ReadonlySet<Scope>): ScopeGroup[] => {
  const allScopes = new Set<Scope>(scopes);
  return [...scopes]
    .sort(scopesGroupingComparator)
    .reduce((res: ScopeGroup[], scope: Scope): ScopeGroup[] => {
      const parentScope = getParentScope(scope);
      if (res.length && parentScope === res[res.length - 1].parentScope) {
        res[res.length - 1].subScopes.push(scope);
        return res;
      }

      return [
        ...res,
        {
          parentScope,
          artificialParent: !allScopes.has(parentScope),
          subScopes: scope === parentScope ? [] : [scope]
        }
      ];
    }, []);
};

export const selectScopeGroups = createSelector<State, [ReadonlySet<Scope>], ScopeGroup[]>(
  selectUserScopes,
  (scopes: ReadonlySet<Scope>) => groupScopes(scopes)
);

export const selectAuthActiveStatus = createSelector<State, [LoggedInUser], boolean>(
  selectUserInfo,
  (userInfo: LoggedInUser) => userInfo?.nextUrl === '/'
);

export const selectAuthPendingStatus = createSelector<State, [AuthState], boolean>(
  selectAuthState,
  (state: AuthState) => state.pending
);

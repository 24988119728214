import { CdkTrapFocus, FocusTrap } from '@angular/cdk/a11y';
import {
  AfterViewInit,
  ContentChild,
  Directive,
  ElementRef,
  HostListener,
  OnDestroy
} from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { Subject, takeUntil } from 'rxjs';

@Directive({
  selector: '[shareFilterableSelectPanelFocus]'
})
export class FilterableSelectPanelFocusDirective implements AfterViewInit, OnDestroy {
  @ContentChild('optionsPanel')
  public readonly optionsPanel: ElementRef;

  private readonly focusTrap: FocusTrap;
  private readonly gc = new Subject<void>();

  constructor(
    private readonly matSelect: MatSelect,
    trapFocusDirective: CdkTrapFocus
  ) {
    this.focusTrap = trapFocusDirective.focusTrap;
  }

  @HostListener('keydown.shift.tab', ['$event'])
  public onShiftTabKeyPress(event: KeyboardEvent) {
    event.stopImmediatePropagation();
  }

  @HostListener('keydown.tab', ['$event'])
  public onTabKeyPress(event: KeyboardEvent) {
    event.stopImmediatePropagation();

    if (!this.isFiredOnOptionsPanel(event)) {
      return;
    }

    event.preventDefault();
    this.focusTrap.focusFirstTabbableElement();
  }

  public ngAfterViewInit(): void {
    this.disableMatTriggerFocusWhenPanelOpened();
    this.initSelectPanelOpenStatusListener();
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }

  private disableMatTriggerFocusWhenPanelOpened(): void {
    /**
     * Invoke original focus handler only if mat-select panel is closed.
     * When the panel is open, we should preserve focus inside it.
     */
    const origFocus = this.matSelect.focus;
    this.matSelect.focus = (options?: FocusOptions): void => {
      if (!this.matSelect.panelOpen) {
        origFocus.call(this.matSelect, options);
      }
    };
  }

  private initSelectPanelOpenStatusListener(): void {
    this.matSelect.openedChange.pipe(takeUntil(this.gc)).subscribe((opened) => {
      if (opened) {
        this.focusTrap.destroy();
        this.focusTrap.attachAnchors();
        this.focusTrap.focusFirstTabbableElement();
      } else {
        this.matSelect.focus();
      }
    });
  }

  private isFiredOnOptionsPanel(event: KeyboardEvent): boolean {
    return event.target === this.optionsPanel?.nativeElement;
  }
}

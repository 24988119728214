import { Router, UrlTree } from '@angular/router';
import { Observable, map } from 'rxjs';
import { Store } from '@ngrx/store';
import { Scope } from '@neuralegion/api';
import { selectScopePermission } from '../../store';

export abstract class SbacGuard {
  protected constructor(
    private readonly router: Router,
    private readonly store: Store,
    private readonly scope: Scope
  ) {}

  public canActivate(): Observable<boolean | UrlTree> {
    return this.store
      .select(selectScopePermission(this.scope))
      .pipe(map((allowed) => (!allowed ? this.router.parseUrl('/') : true)));
  }

  public canMatch(): Observable<boolean | UrlTree> {
    return this.canActivate();
  }
}

<ng-container *ngIf="{ inviteDetails: inviteDetails$ | async, error: error$ | async } as sync">
  <share-spinner *ngIf="!sync.inviteDetails && !sync.error" [inner]="true"></share-spinner>

  <ng-container *ngIf="sync.error">
    <mat-card>
      <mat-card-content>
        <p>Error occurred</p>
        <button class="single-button" mat-raised-button [routerLink]="['/login']">Go to sign in</button>
      </mat-card-content>
    </mat-card>
  </ng-container>

  <ng-container *ngIf="sync.inviteDetails as inviteDetails">
    <ng-container *ngIf="!inviteDetails.existingMember; else tmplAlreadyExisting">
      <ng-container *ngIf="inviteDetails.needsAuthentication; else tmplFlowFinished">
        <mat-card>
          <mat-card-header>
            <mat-card-title role="heading" aria-level="1">
              <div>
                You have been invited to <span class="org-name">{{ inviteDetails.orgName }}</span> organization.
              </div>
            </mat-card-title>
          </mat-card-header>

          <mat-card-content>
            <ng-container [ngSwitch]="inviteDetails.requiredSsoProvider">
              <p *ngSwitchCase="true">
                <b>{{ inviteDetails.orgName }}</b> requires Single Sign-On (SSO) using
                <b>{{ inviteDetails.requiredSsoProvider | titlecase }}</b> SSO.
              </p>
              <p *ngSwitchDefault>To continue, please create a new account or connect using an existing account.</p>
            </ng-container>

            <ng-container [ngSwitch]="!!inviteDetails.requiredSsoProvider">
              <div class="buttons">
                <button *ngSwitchCase="true" mat-raised-button [routerLink]="['/sso']">
                  Connect with <b>{{ inviteDetails.requiredSsoProvider | titlecase }} SSO</b>
                </button>
                <ng-container *ngSwitchDefault>
                  <button *ngIf="inviteDetails.hasSso" mat-raised-button [routerLink]="['/sso']">
                    Connect with single sign-on (SSO)
                  </button>
                  <button mat-raised-button [routerLink]="['./create']">Create a new account</button>
                </ng-container>
              </div>
            </ng-container>
          </mat-card-content>
        </mat-card>
      </ng-container>
    </ng-container>

    <ng-template #tmplAlreadyExisting>
      <mat-card>
        <mat-card-content>
          <div>
            You account <b>{{ inviteDetails.email }}</b> is already member of organization
            <b>{{ inviteDetails.orgName }}</b
            >.
          </div>
          <button class="single-button" mat-raised-button [routerLink]="['/login']">Go to sign in</button>
        </mat-card-content>
      </mat-card>
    </ng-template>

    <ng-template #tmplFlowFinished>
      <mat-card fxLayout="column">
        <mat-card-content>
          <div>
            Everything is ready for your join to <b>{{ inviteDetails?.orgName }}</b> organization
          </div>
          <button class="single-button" color="primary" mat-raised-button (click)="onJoinClick()">Join</button>
        </mat-card-content>
      </mat-card>
    </ng-template>
  </ng-container>
</ng-container>

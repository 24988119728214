import { Action, createReducer, on } from '@ngrx/store';
import { cleanupPending, cleanupPendingState } from '@neuralegion/core';
import { loadSessions, loadSessionsFail, loadSessionsSuccess } from './sessions.actions';
import { SessionsState, initialSessionsState, sessionsEntityAdapter } from './sessions.state';

export const sessionReducer = createReducer<SessionsState, Action>(
  initialSessionsState,
  on(
    loadSessions,
    (state: SessionsState, action): SessionsState => ({
      ...state,
      pending: [...state.pending, action],
      error: null
    })
  ),
  on(
    loadSessionsSuccess,
    (state: SessionsState, action): SessionsState =>
      sessionsEntityAdapter.setAll(action.payload, cleanupPendingState({ ...state }, action))
  ),
  on(
    loadSessionsFail,
    (state: SessionsState, action): SessionsState => ({
      ...state,
      pending: cleanupPending(state.pending, action.parentType),
      error: action.payload
    })
  )
);

import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  distinctUntilChanged,
  map,
  takeUntil,
  tap,
  withLatestFrom
} from 'rxjs';
import equal from 'fast-deep-equal/es6';
import { ID } from '@neuralegion/api';
import { trackById } from '@neuralegion/core';
import { TextFilterComponent } from '../text-filter/text-filter.component';

interface Item extends ID {
  label: string;
  hidden?: boolean;
}

@Component({
  selector: 'share-filterable-multi-line-select',
  templateUrl: './filterable-multi-line-select.component.html',
  styleUrls: ['./filterable-multi-line-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FilterableMultiLineSelectComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: FilterableMultiLineSelectComponent,
      multi: true
    }
  ]
})
export class FilterableMultiLineSelectComponent<T extends ID & { label: string }>
  implements ControlValueAccessor, Validator, OnInit, OnChanges, AfterViewInit
{
  @Input()
  public items$: Observable<T[]>;

  @Input()
  public filterPlaceholder = 'Search…';

  @Input()
  public itemName = 'item';

  @Input()
  public required = false;

  @ViewChild(TextFilterComponent)
  private readonly textFilter: TextFilterComponent;

  public filteredItems$: Observable<Item[]>;
  public readonly trackById = trackById;

  public readonly selectAllCheckbox = new FormControl();
  public readonly selectControl = new FormControl([]);

  private readonly gc = new Subject<void>();
  private readonly filterValueSubject = new BehaviorSubject<string>('');
  private readonly filterValue$ = this.filterValueSubject.asObservable();

  private onChange: (ids: string[] | string) => void = () => {};
  private onTouched: () => void = () => {};

  public ngOnChanges(): void {
    if (coerceBooleanProperty(this.required)) {
      return this.selectControl.setValidators(Validators.required);
    }

    this.selectControl.clearValidators();
  }

  public ngOnInit(): void {
    this.initFilteredItems();
    this.initItemsSelection();
    this.initSelectAllCheckboxToggle();
  }

  public ngAfterViewInit(): void {
    this.textFilter.valueChanges
      .pipe(
        tap(() => this.onTouched()),
        takeUntil(this.gc)
      )
      .subscribe(this.filterValueSubject);
  }

  public writeValue(value: string[]): void {
    if (!value) {
      this.selectControl.reset();
    }

    this.selectControl.setValue(value ?? []);
  }

  public registerOnChange(onChange: (ids: string[] | string) => void): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  public setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.selectAllCheckbox.disable();
      this.selectControl.disable();
    } else {
      this.selectAllCheckbox.enable();
      this.selectControl.enable();
    }
  }

  public validate(): ValidationErrors | null {
    return this.selectControl.valid ? null : { required: true };
  }

  private initFilteredItems(): void {
    this.filteredItems$ = combineLatest([
      this.items$.pipe(
        map((items: T[]): Item[] =>
          items.map(
            (item: T): Item => ({
              ...item,
              hidden: false
            })
          )
        )
      ),
      this.filterValue$
    ]).pipe(
      map(([data, filterValue]: [Item[], string]) =>
        !filterValue
          ? data
          : data.map((item: Item) => ({
              ...item,
              hidden: !item.label.toLowerCase().includes(filterValue.toLowerCase())
            }))
      )
    );
  }

  private initItemsSelection(): void {
    this.selectControl.valueChanges
      .pipe(
        withLatestFrom(this.items$),
        distinctUntilChanged(equal),
        tap(() => this.onTouched()),
        takeUntil(this.gc)
      )
      .subscribe(([value, items]: [string[], Item[]]) => {
        this.updateSelectAllCheckboxState(items, value || []);
        this.onChange(value || []);
      });
  }

  private initSelectAllCheckboxToggle(): void {
    this.selectAllCheckbox.valueChanges
      .pipe(withLatestFrom(this.filteredItems$), takeUntil(this.gc))
      .subscribe(([checked, items]) => {
        if (checked) {
          return this.selectControl.setValue(items.map((item: Item): string => item.id));
        }
        this.selectControl.setValue([]);
      });
  }

  private updateSelectAllCheckboxState(allItems: Item[], selectedItems: string[]): void {
    const allSelected = selectedItems.length && selectedItems.length === allItems.length;

    if (this.selectAllCheckbox.value !== allSelected) {
      this.selectAllCheckbox.setValue(allSelected, { emitEvent: false });
    }
  }
}

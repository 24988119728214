import { FormErrorHandler, ValidationErrorCode } from '../../models';
import { ErrorHandlerMap } from './form-error.service';

type FormErrorMaxLength = { requiredLength: number; actualLength: number };
type FormErrorMinLength = { requiredLength: number; actualLength: number };
type FormErrorMin = { min: number; actual: number };
type FormErrorMax = { max: number; actual: number };

export const DEFAULT_FIELD_NAME = 'Field';

export const defaultErrorMapFactory = (): ErrorHandlerMap => {
  const errors: { [P in ValidationErrorCode]?: FormErrorHandler } = {
    required: {
      messageFn: ({ fieldName = DEFAULT_FIELD_NAME }) =>
        `${fieldName ? fieldName + ' is' : 'Is'} required`
    },
    min: {
      messageFn: ({ error, fieldName = DEFAULT_FIELD_NAME }) =>
        `${fieldName ? fieldName + ' must' : 'Must'} be greater than ${(error as FormErrorMin).min}`
    },
    max: {
      messageFn: ({ error, fieldName = DEFAULT_FIELD_NAME }) =>
        `${fieldName ? fieldName + ' must' : 'Must'} be less than ${(error as FormErrorMax).max}`
    },
    maxlength: {
      messageFn: ({ error }) =>
        `Too many characters, the maximum is ${(error as FormErrorMaxLength).requiredLength}`
    },
    minlength: {
      messageFn: ({ error, fieldName = DEFAULT_FIELD_NAME }) =>
        `${fieldName ? fieldName + ' must' : 'Should'} be at least ${
          (error as FormErrorMinLength).requiredLength
        } characters`
    },
    url: { message: 'Invalid URL' },
    nexTemplate: { messageFn: ({ error }) => `${error as string}` },
    pattern: { message: 'Special characters are not allowed' },
    duplicate: { message: 'Value is duplicated' },
    deserialization: { message: 'There is an error during value deserialization' },
    email: { message: 'Incorrect email format' },
    host: { message: 'Invalid host' },
    ip: { message: 'Invalid IP address' },
    regExp: { message: 'Incorrect RegExp pattern' },
    xpath: { message: 'Invalid XPath' },
    ccNumber: { message: 'Wrong card number' },
    expDate: { message: 'Wrong expiration date' },
    listLengthExceeded: {
      messageFn: ({ error }) => `Maximum items limit of ${error as string} exceeded`
    },
    duplicatedListItem: { message: 'Each item must be unique' },
    minStartTime: { messageFn: ({ error }) => `Min start time is ${error as string}` },
    headerNames: { message: 'Some of the Header names are invalid' },
    headerName: { message: 'Incorrect name format' },
    headerValue: { message: 'Incorrect value format' },
    wildcardHost: {
      message: 'You should enter host or ip address. Subdomain wildcards are supported.'
    },
    integer: {
      messageFn: ({ fieldName = DEFAULT_FIELD_NAME }) =>
        `${fieldName ? fieldName + ' must' : 'Must'} be an integer`
    },
    byteSize: {
      messageFn: ({ error, fieldName = DEFAULT_FIELD_NAME }) =>
        `${fieldName ? fieldName + ' must' : 'Must'} be less than ${error as string} bytes`
    },
    blockList: { message: 'Value is in the block list' },
    httpStatuses: { message: 'One or more HTTP status codes are invalid' },
    cardExpired: { message: 'Payment card is expired' },
    entityId: { message: 'Invalid ID' },
    authObjectProjectsEqualsSelectedProject: {
      message: `The selected authentication doesn't belong to the specified project`
    },
    authObjectRequiresRepeater: { message: 'The selected authentication requires repeater' }
  };

  return new Map(
    Object.entries(errors).map(([errorCode, handler]: [string, FormErrorHandler]) => [
      errorCode as ValidationErrorCode,
      { ...handler }
    ])
  );
};

<ng-container *ngIf="groupedFiltersConfig?.length" [formGroup]="filterForm">
  <share-table-filter
    [formControlName]="'groupedFilters'"
    [length]="(pagination$ | async)?.length"
    [options]="{
      filterNamePipe,
      config: groupedFiltersConfig,
      tableName: (title | lowercase)
    }"
    [pending$]="pending$">
  </share-table-filter>
</ng-container>

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Email, ResetPasswordPayload, Token } from '@neuralegion/api';
import { IGNORE_ERROR_INTERCEPTOR_TOKEN } from '@neuralegion/error-handler';

@Injectable()
export class ForgotPasswordService {
  constructor(private readonly http: HttpClient) {}

  public forgotPassword(data: Email): Observable<void> {
    return this.http.post<void>('/api/v1/password-reset', data);
  }

  public resetPassword(data: ResetPasswordPayload): Observable<Token | undefined> {
    return this.http.put<Token | undefined>(
      `/api/v1/password-reset/?token=${data.token}`,
      {
        password: data.password
      },
      {
        withCredentials: true,
        context: new HttpContext().set(IGNORE_ERROR_INTERCEPTOR_TOKEN, true)
      }
    );
  }
}

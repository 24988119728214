import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, NgZone, OnDestroy } from '@angular/core';
import { Observable, Subject, Subscriber, debounceTime, takeUntil } from 'rxjs';
import { ELEMENT_LOCATION_TOKEN, ElementLocation } from './element-location';

enum Reason {
  MISSING,
  INVALID,
  DUPLICATE
}

@Injectable({
  providedIn: 'root'
})
export class InvalidDataIdReporterService implements OnDestroy {
  private readonly selectors = [
    '.counters-label',
    '.custom-tooltip',
    '.dataset-empty > span',
    '.header-value',
    '.mat-mdc-menu-panel',
    '.mat-sort-header',
    '.mat-tab-label',
    '.snack-bar',
    'a',
    'button',
    'input',
    'markdown',
    'mat-checkbox',
    'mat-chip-grid',
    'mat-chip-listbox',
    'mat-chip-option',
    'mat-chip-row',
    'mat-dialog-container',
    'mat-error',
    'mat-expansion-panel',
    'mat-form-field',
    'mat-hint',
    'mat-icon[role="button"]',
    'mat-list',
    'mat-list-item',
    'mat-list-option',
    'mat-option',
    'mat-progress-bar',
    'mat-radio-button',
    'mat-radio-group',
    'mat-row',
    'mat-select',
    'mat-selection-list',
    'mat-slide-toggle',
    'mat-spinner',
    'mat-tab-body',
    'mat-table',
    'discovery-settings-target-status',
    'share-note',
    'span.status',
    'table',
    'textarea',
    'tr'
  ];

  private readonly gc = new Subject<void>();
  private readonly ignoredMissingIds: ((element: HTMLElement) => boolean)[] = [
    (element) =>
      element.tagName === 'MAT-OPTION' &&
      !!element.closest('[aria-labelledby*="mat-paginator-page-size-label"]'),
    (element) => !!element.closest('.cm-editor'),
    (element) => !!element.closest('.mat-datepicker-content-container, .mat-calendar'),
    (element) => !!element.closest('color-picker'),
    (element) =>
      element.tagName === 'INPUT' &&
      !!element.closest('.mdc-list-item--with-trailing-radio, .mat-mdc-list-option-checkbox-before')
  ];
  private readonly reportedElements = new Map<string, Reason[]>();

  private elementObserver: MutationObserver;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly ngZone: NgZone,
    @Inject(ELEMENT_LOCATION_TOKEN) private readonly elementLocation: ElementLocation
  ) {}

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();

    this.elementObserver?.disconnect();
  }

  public init(): void {
    this.ngZone.runOutsideAngular(() => {
      new Observable<void>((subscriber: Subscriber<void>) => {
        this.elementObserver = new MutationObserver(() => subscriber.next());

        this.elementObserver.observe(this.document.body, {
          attributes: true,
          childList: true,
          subtree: true
        });
      })
        .pipe(debounceTime(500), takeUntil(this.gc))
        .subscribe(() => {
          this.report(this.findMissingIds(), Reason.MISSING);
          this.report(this.findInvalidIds(), Reason.INVALID);
          this.report(this.findDuplicatedIds(), Reason.DUPLICATE);
        });
    });
  }

  private findMissingIds(): Element[] {
    return Array.from(
      this.document.querySelectorAll(
        this.selectors.map((selector) => `${selector}:not([data-id])`).join(',')
      )
    ).filter((element) => !this.ignoredMissingIds.some((fn) => fn(element as HTMLElement)));
  }

  private findInvalidIds(): Element[] {
    return Array.from(this.document.querySelectorAll('*[data-id*="unknown_id"]'));
  }

  private findDuplicatedIds(): Element[] {
    const ids = new Set<string>();
    return Array.from(this.document.querySelectorAll('[data-id]')).reduce(
      (res: Element[], element) => {
        const id = element.getAttribute('data-id');
        if (ids.has(id)) {
          res.push(element);
        } else {
          ids.add(id);
        }
        return res;
      },
      []
    );
  }

  private report(elements: Element[], reason: Reason): void {
    elements.forEach((element: Element) => {
      const xpath = this.elementLocation.getElementLocation(element);

      const reportedReasons = this.reportedElements.get(xpath);
      if (!reportedReasons?.includes(reason)) {
        this.reportedElements.set(xpath, [...(reportedReasons ?? []), reason]);
        console.error(new Error(this.format(element, xpath, reason)));
      }
    });
  }

  private format(element: Element, xpath: string, reason: Reason): string {
    const dataId = element.getAttribute('data-id');
    switch (reason) {
      case Reason.MISSING:
        return `Element at ${xpath} has no data-id attribute`;
      case Reason.INVALID:
        return `Element at ${xpath} has invalid data-id attribute: ${dataId}`;
      case Reason.DUPLICATE:
        return `Element at ${xpath} has duplicated data-id attribute: ${dataId}`;
    }
  }
}

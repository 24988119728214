import { Action, createReducer, on } from '@ngrx/store';
import { cleanupPending } from '@neuralegion/core';
import {
  addGitlabIntegration,
  addGitlabIntegrationFail,
  addGitlabIntegrationSuccess,
  addIntegration,
  addIntegrationFail,
  addIntegrationSuccess,
  loadAvailableExternalScanConfigSources,
  loadAvailableExternalScanConfigSourcesFail,
  loadAvailableExternalScanConfigSourcesSuccess,
  loadAzureOrgs,
  loadAzureOrgsFail,
  loadAzureOrgsSuccess,
  loadIntegrations,
  loadIntegrationsFail,
  loadIntegrationsSuccess,
  loadRepositories,
  loadRepositoriesFail,
  loadRepositoriesSuccess,
  loadSnykOrgs,
  loadSnykOrgsFail,
  loadSnykOrgsSuccess,
  removeIntegration,
  removeIntegrationFail,
  removeIntegrationSuccess,
  updateIntegration,
  updateIntegrationFail,
  updateIntegrationSuccess
} from './integrations.actions';
import {
  IntegrationsState,
  initialIntegrationsState,
  integrationsEntityAdapter
} from './integrations.state';

export const integrationsReducer = createReducer<IntegrationsState, Action>(
  initialIntegrationsState,
  on(
    loadIntegrations,
    (state: IntegrationsState, action): IntegrationsState => ({
      ...integrationsEntityAdapter.removeAll(state),
      pending: [...state.pending, action]
    })
  ),
  on(
    addIntegration,
    addGitlabIntegration,
    updateIntegration,
    removeIntegration,
    (state: IntegrationsState, action): IntegrationsState => ({
      ...state,
      pending: [...state.pending, action]
    })
  ),
  on(
    loadAzureOrgs,
    (state: IntegrationsState, action): IntegrationsState => ({
      ...state,
      azureOrgs: null,
      pending: [...state.pending, action]
    })
  ),
  on(
    loadSnykOrgs,
    (state: IntegrationsState, action): IntegrationsState => ({
      ...state,
      snykOrgs: null,
      pending: [...state.pending, action]
    })
  ),
  on(
    loadRepositories,
    loadAvailableExternalScanConfigSources,
    (state: IntegrationsState, action): IntegrationsState => {
      const repositories = new Map(state.repositories);
      if (repositories.has(action.payload.serviceName)) {
        repositories.delete(action.payload.serviceName);
      }

      return {
        ...state,
        repositories,
        pending: [...state.pending, action]
      };
    }
  ),
  on(
    loadIntegrationsSuccess,
    (state: IntegrationsState, action): IntegrationsState =>
      integrationsEntityAdapter.setAll(action.payload, {
        ...state,
        error: null,
        pending: cleanupPending(state.pending, action.parentType)
      })
  ),
  on(
    removeIntegrationSuccess,
    (state: IntegrationsState, action): IntegrationsState =>
      integrationsEntityAdapter.removeOne(action.payload.integrationId, {
        ...state,
        error: null,
        pending: cleanupPending(state.pending, action.parentType)
      })
  ),
  on(
    addIntegrationSuccess,
    addGitlabIntegrationSuccess,
    updateIntegrationSuccess,
    (state: IntegrationsState, action): IntegrationsState => ({
      ...state,
      error: null,
      pending: cleanupPending(state.pending, action.parentType)
    })
  ),
  on(
    loadAzureOrgsSuccess,
    (state: IntegrationsState, action): IntegrationsState => ({
      ...state,
      azureOrgs: action.payload,
      error: null,
      pending: cleanupPending(state.pending, action.parentType)
    })
  ),
  on(
    loadSnykOrgsSuccess,
    (state: IntegrationsState, action): IntegrationsState => ({
      ...state,
      snykOrgs: action.payload,
      error: null,
      pending: cleanupPending(state.pending, action.parentType)
    })
  ),
  on(loadRepositoriesSuccess, (state: IntegrationsState, action): IntegrationsState => {
    const repositories = new Map(state.repositories);
    if (!repositories.has(action.payload.serviceName)) {
      repositories.set(action.payload.serviceName, {});
    }
    repositories.get(action.payload.serviceName)[action.payload.integrationId] =
      action.payload.repositories;
    return {
      ...state,
      repositories,
      error: null,
      pending: cleanupPending(state.pending, action.parentType)
    };
  }),
  on(
    loadAvailableExternalScanConfigSourcesSuccess,
    (state: IntegrationsState, action): IntegrationsState => {
      const repositories = new Map(state.repositories);
      if (!repositories.has(action.payload.serviceName)) {
        repositories.set(action.payload.serviceName, {});
      }
      repositories.get(action.payload.serviceName)[action.payload.integrationId] =
        action.payload.configSources;

      return {
        ...state,
        repositories,
        error: null,
        pending: cleanupPending(state.pending, action.parentType)
      };
    }
  ),

  on(
    loadIntegrationsFail,
    addIntegrationFail,
    addGitlabIntegrationFail,
    updateIntegrationFail,
    removeIntegrationFail,
    loadAzureOrgsFail,
    loadSnykOrgsFail,
    loadRepositoriesFail,
    loadAvailableExternalScanConfigSourcesFail,
    (state: IntegrationsState, action): IntegrationsState => ({
      ...state,
      error: action.payload,
      pending: cleanupPending(state.pending, action.parentType)
    })
  )
);

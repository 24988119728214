import { ValidatorFn } from '@angular/forms';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { DateRange } from './DateRange';

export enum FilterType {
  TEXT = 'TEXT',
  DATE_RANGE = 'DATE_RANGE',
  SELECT = 'SELECT',
  ASYNC_SELECT = 'ASYNC_SELECT',
  NUMBER_COMPARE = 'NUMBER_COMPARE',
  COMPOSITE = 'COMPOSITE',
  EXTERNAL_FILTRATION_SELECT = 'EXTERNAL_FILTRATION_SELECT'
}

export enum ComparisonOperator {
  EQ = 'eq',
  GT = 'gt',
  GTE = 'gte',
  LT = 'lt',
  LTE = 'lte'
}

export type TextFilterInputType = 'text' | 'number';

interface Cloneable<T> {
  clone(): T;
}

interface BaseFilter {
  readonly type: FilterType;
  readonly name?: string;
}

export interface FilterValueObject<T> extends BaseFilter {
  value: T;
  singular?: boolean;
}

interface FilterConfigSettings {
  readonly multiple: boolean;
  readonly validators?: ValidatorFn[];
}

export interface TextFilterSettings extends FilterConfigSettings {
  readonly singular?: boolean;
  readonly inputType?: TextFilterInputType;
  readonly unique?: boolean;
}

export interface DateRangeFilterSettings extends FilterConfigSettings {
  readonly minDateFilterName?: string;
  readonly maxDateFilterName?: string;
}

export interface SelectItem<T = string> {
  readonly value: T;
  readonly label: string;
}

export interface SelectFilterSettings extends FilterConfigSettings {
  readonly items: SelectItem[];
  readonly singular?: boolean;
}

export interface AsyncSelectFilterSettings extends FilterConfigSettings {
  readonly items$: Observable<SelectItem[]>;
  readonly itemsFilterFn?: (filters: Filter[], items: SelectItem[]) => Observable<SelectItem[]>;
}

export interface NumberCompareFilterSettings extends FilterConfigSettings {
  readonly items: SelectItem[];
  readonly numberValidators: ValidatorFn[];
}

export interface ExternalFiltrationSettings extends FilterConfigSettings {
  maxItemsLength: number;
  items$: Observable<SelectItem[]>;
  pending$: Observable<boolean>;
  couldBeRefined$: Observable<boolean>;
  queryFieldValidators?: ValidatorFn[];
  onQueryChanged(query: string): void;
  onOpenedStateChanged(isOpened: boolean, filters: Filter[]): void;
}

export class FilterConfig implements BaseFilter {
  constructor(type: FilterType.TEXT, name: string, settings: TextFilterSettings);
  constructor(type: FilterType.DATE_RANGE, name: string, settings: DateRangeFilterSettings);
  constructor(type: FilterType.SELECT, name: string, settings: SelectFilterSettings);
  constructor(type: FilterType.ASYNC_SELECT, name: string, settings: AsyncSelectFilterSettings);
  constructor(type: FilterType.NUMBER_COMPARE, name: string, settings: NumberCompareFilterSettings);
  constructor(
    type: FilterType.EXTERNAL_FILTRATION_SELECT,
    name: string,
    settings: ExternalFiltrationSettings
  );
  constructor(
    public readonly type: FilterType,
    public readonly name: string,
    public readonly settings:
      | FilterConfigSettings
      | TextFilterSettings
      | DateRangeFilterSettings
      | SelectFilterSettings
      | AsyncSelectFilterSettings
      | NumberCompareFilterSettings
      | ExternalFiltrationSettings
  ) {}
}

export abstract class Filter<T = unknown> implements BaseFilter, Cloneable<Filter<T>> {
  protected constructor(protected readonly config: FilterConfig) {}

  get type(): FilterType {
    return this.config.type;
  }

  get name(): string {
    return this.config.name;
  }

  get settings(): FilterConfigSettings {
    return this.config?.settings;
  }

  abstract get value(): T;
  abstract set value(value: T);

  public abstract clone(): Filter<T>;

  public toJSON(): FilterValueObject<T> {
    return {
      type: this.type,
      name: this.name,
      value: this.value
    };
  }
}

export class TextFilter extends Filter<string | number> {
  public value: string | number;

  constructor(protected override readonly config: FilterConfig) {
    super(config);
  }

  override get type(): FilterType {
    return FilterType.TEXT;
  }

  public clone(): TextFilter {
    const clone = new TextFilter(this.config);
    clone.value = this.value;

    return clone;
  }

  public override toJSON(): FilterValueObject<string | number> {
    return {
      type: this.type,
      name: this.name,
      value: this.value,
      singular: (this.settings as TextFilterSettings).singular
    };
  }
}

export class DateRangeFilter extends Filter<DateRange> {
  public value: DateRange;

  constructor(protected override readonly config: FilterConfig) {
    super(config);
  }

  override get type(): FilterType.DATE_RANGE {
    return FilterType.DATE_RANGE;
  }

  public clone(): DateRangeFilter {
    const clone = new DateRangeFilter(this.config);

    if (this.value) {
      clone.value = {
        startDate: new Date(this.value.startDate),
        endDate: new Date(this.value.endDate)
      };
    }

    return clone;
  }
}

export abstract class BaseCompositeFilter<T extends Filter> extends Filter<T[]> {
  protected filters: T[] = [];

  protected constructor(protected override readonly config: FilterConfig) {
    super(config);
  }

  get value(): T[] {
    return this.filters;
  }

  public add(filter: T): void {
    this.filters = [...this.filters, filter];
  }

  public remove(filter: T): void {
    this.filters = this.filters.filter((v: Filter) => v !== filter);
  }

  public clear(): void {
    this.filters = [];
  }
}

export class CompositeFilter extends BaseCompositeFilter<Filter> {
  constructor() {
    super(null);
  }

  override get name(): string {
    return '';
  }

  override get type(): FilterType.COMPOSITE {
    return FilterType.COMPOSITE;
  }

  override get value() {
    return super.value;
  }

  public clone(): CompositeFilter {
    const clone = new CompositeFilter();

    this.filters.forEach((filter: Filter) => {
      clone.add(filter.clone());
    });

    return clone;
  }
}

export class SelectFilter extends BaseCompositeFilter<TextFilter> {
  constructor(protected override readonly config: FilterConfig) {
    super(config);
  }

  override get settings(): SelectFilterSettings {
    return super.settings as SelectFilterSettings;
  }

  get availableItems(): SelectItem[] {
    return this.settings.items.filter(
      (item) => !this.filters.map((filter) => filter.value).includes(item.value)
    );
  }

  public addValue(value: string): void {
    const filter = new TextFilter(this.config);
    filter.value = value;
    super.add(filter);
  }

  public clone(): SelectFilter {
    const clone = new SelectFilter(this.config);

    this.filters.forEach((filter: TextFilter) => {
      clone.add(filter.clone());
    });

    return clone;
  }
}

export class AsyncSelectFilter extends BaseCompositeFilter<TextFilter> {
  constructor(protected override readonly config: FilterConfig) {
    super(config);
  }

  override get settings(): AsyncSelectFilterSettings {
    return super.settings as AsyncSelectFilterSettings;
  }

  get availableItems$(): Observable<SelectItem[]> {
    return this.settings.items$.pipe(
      map((items: SelectItem[]) => {
        const filterValues: (string | number)[] = this.filters.map((filter) => filter.value);
        return items.filter((item: SelectItem) => !filterValues.includes(item.value));
      })
    );
  }

  public addValue(value: string): void {
    const filter = new TextFilter(this.config);
    filter.value = value;
    super.add(filter);
  }

  public clone(): AsyncSelectFilter {
    const clone = new AsyncSelectFilter(this.config);

    this.filters.forEach((filter: TextFilter) => {
      clone.add(filter.clone());
    });

    return clone;
  }
}

export interface NumberCompareValue {
  item: SelectItem;
  operator: ComparisonOperator;
  value: number;
}

export class NumberCompareFilter extends Filter<NumberCompareValue> {
  public value: NumberCompareValue;

  constructor(protected override readonly config: FilterConfig) {
    super(config);
  }

  public clone(): NumberCompareFilter {
    const clone = new NumberCompareFilter(this.config);

    clone.value = {
      item: this.value.item,
      operator: this.value.operator,
      value: this.value.value
    };

    return clone;
  }
}

export class ExternalFiltrationSelectFilter extends BaseCompositeFilter<TextFilter> {
  private readonly valueSubject = new BehaviorSubject<Filter[]>([]);

  public readonly valueChanges = this.valueSubject.asObservable();

  constructor(public override readonly config: FilterConfig) {
    super(config);
  }

  override get settings(): ExternalFiltrationSettings {
    return super.settings as ExternalFiltrationSettings;
  }

  get selectedItemsLength(): number {
    return this.value.length;
  }

  get availableItems$(): Observable<SelectItem[]> {
    return this.settings.items$.pipe(
      map((items) => {
        const selectedIds = this.filters.map((filter) => filter.value);
        return items.filter(({ value }) => !selectedIds.includes(value));
      })
    );
  }

  public addValue(values: string[] | string): void {
    if (Array.isArray(values)) {
      values.map((value) => this.addSingleValue(value));
      return;
    }

    this.addSingleValue(values);
  }

  public override add(filter: TextFilter): void {
    super.add(filter);
    this.valueSubject.next(this.value);
  }

  public override remove(filter: TextFilter): void {
    super.remove(filter);
    this.valueSubject.next(this.value);
  }

  public override clear(): void {
    super.clear();
    this.valueSubject.next(this.value);
  }

  public clone(): ExternalFiltrationSelectFilter {
    const clone = new ExternalFiltrationSelectFilter(this.config);

    this.filters.forEach((filter: TextFilter) => {
      clone.add(filter.clone());
    });

    return clone;
  }

  private addSingleValue(value: string): void {
    const filter = new TextFilter(this.config);
    filter.value = value;
    this.add(filter);
  }
}

export class FilterFactory {
  public static createFilter(filterConfig: FilterConfig): Filter {
    switch (filterConfig?.type) {
      case FilterType.TEXT:
        return new TextFilter(filterConfig);
      case FilterType.DATE_RANGE: {
        return new DateRangeFilter(filterConfig);
      }
      case FilterType.SELECT: {
        return new SelectFilter(filterConfig);
      }
      case FilterType.ASYNC_SELECT: {
        return new AsyncSelectFilter(filterConfig);
      }
      case FilterType.NUMBER_COMPARE: {
        return new NumberCompareFilter(filterConfig);
      }
      case FilterType.EXTERNAL_FILTRATION_SELECT: {
        return new ExternalFiltrationSelectFilter(filterConfig);
      }
      case FilterType.COMPOSITE: {
        return new CompositeFilter();
      }
      default:
        return new CompositeFilter();
    }
  }
}

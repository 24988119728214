import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, exhaustMap, map, of, switchMap, withLatestFrom } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Scope } from '@neuralegion/api';
import { selectScopePermission } from '@neuralegion/auth-api';
import { AppRouterState, ofPrimaryRouterNavigated } from '@neuralegion/core';
import { BillingSummary, Product } from '../models';
import { BillingService } from '../services';
import { BillingContactInfoService } from '../services/billing-contact-info.service';
import {
  billingRefresh,
  loadProducts,
  loadProductsFail,
  loadProductsSuccess,
  loadSummary,
  loadSummaryFail,
  loadSummarySuccess,
  saveContactInfo,
  saveContactInfoFail,
  saveContactInfoSuccess
} from './billing.actions';

@Injectable()
export class BillingEffects {
  public readonly loadSummary$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSummary),
      withLatestFrom(this.store.select(selectScopePermission(Scope.BILLING))),
      exhaustMap(([, billingsPermission]: [ReturnType<typeof loadSummary>, boolean]) =>
        billingsPermission
          ? this.billingService.loadSummary().pipe(
              map((summary: BillingSummary) => loadSummarySuccess({ summary })),
              catchError((err: HttpErrorResponse) => of(loadSummaryFail(err.error)))
            )
          : of(loadSummaryFail('Forbidden'))
      )
    )
  );

  public readonly loadProducts$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadProducts),
      withLatestFrom(this.store.select(selectScopePermission(Scope.PRODUCTS))),
      exhaustMap(
        ([, productsPermission]: [ReturnType<typeof loadProducts>, boolean]): Observable<Action> =>
          productsPermission
            ? this.billingService.loadProducts().pipe(
                map((products: Product[]) => loadProductsSuccess({ products })),
                catchError((err: HttpErrorResponse) => of(loadProductsFail(err.error)))
              )
            : of(loadProductsFail('Forbidden'))
      )
    )
  );

  public readonly saveContactInfo$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(saveContactInfo),
      withLatestFrom(this.store.select(selectScopePermission(Scope.BILLING))),
      exhaustMap(
        ([action, billingsPermission]: [
          ReturnType<typeof saveContactInfo>,
          boolean
        ]): Observable<Action> =>
          billingsPermission
            ? this.billingContactInfoService
                .saveBillingContactInfo(action.payload.contactInfo)
                .pipe(
                  map(() => saveContactInfoSuccess()),
                  catchError((err: HttpErrorResponse) => of(saveContactInfoFail(err.error)))
                )
            : of(saveContactInfoFail('Forbidden'))
      )
    )
  );

  public readonly refresh$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(billingRefresh),
      switchMap(() => [loadProducts(), loadSummary()])
    )
  );

  public readonly reloadOnContactInfoSave$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(saveContactInfoSuccess),
      map(() => loadSummary())
    )
  );

  public readonly navigated$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofPrimaryRouterNavigated((state: AppRouterState) => state.pathname.startsWith('/billing')),
      switchMap(() => [loadSummary(), loadProducts()])
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly billingContactInfoService: BillingContactInfoService,
    private readonly billingService: BillingService
  ) {}
}

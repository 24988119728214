import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import {
  DateSettings,
  Email,
  MemberNotificationSettings,
  Session,
  User,
  timeZones
} from '@neuralegion/api';
import { UserDateService } from '@neuralegion/core';
import { ChangePasswordPayload } from '../models';

@Injectable()
export class ProfileService {
  constructor(
    private readonly http: HttpClient,
    private readonly userDateService: UserDateService
  ) {}

  public loadProfile(): Observable<User> {
    return this.http.get<User>('/api/v1/me');
  }

  public updateProfile(profile: Partial<User>): Observable<void> {
    return this.http.put<void>('/api/v1/me', profile);
  }

  public changePassword(payload: ChangePasswordPayload): Observable<void> {
    return this.http.put<void>('/api/v1/auth/password', payload, {
      withCredentials: true
    });
  }

  public setPassword(data: Email): Observable<void> {
    return this.http.post<void>('/api/v1/password-reset', data);
  }

  public loadNotifications(): Observable<MemberNotificationSettings> {
    return this.http.get<MemberNotificationSettings>('/api/v1/member/notifications');
  }

  public updateNotifications(settings: MemberNotificationSettings): Observable<void> {
    return this.http.put<void>('/api/v1/member/notifications', settings);
  }

  public loadDateSettings(): Observable<DateSettings> {
    return this.http.get<DateSettings>('/api/v1/me/date-settings').pipe(
      map((userSettings: DateSettings) => {
        const defaultSettings = this.userDateService.getDefaultDateSettings();

        if (!userSettings) {
          return defaultSettings;
        }

        return {
          ...userSettings,
          timeZone: timeZones.includes(userSettings.timeZone)
            ? userSettings.timeZone
            : defaultSettings.timeZone
        };
      })
    );
  }

  public updateDateSettings(settings: DateSettings): Observable<void> {
    return this.http.put<void>('/api/v1/me/date-settings', settings);
  }

  public loadSessions(): Observable<Session[]> {
    return this.http.get<Session[]>('/api/v1/auth/sessions');
  }
}

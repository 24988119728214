import { Pipe, PipeTransform } from '@angular/core';
import { DateRange } from '@neuralegion/api';
import { DateFormatAlias, UserDateService } from '@neuralegion/core';

@Pipe({
  name: 'dateRange',
  pure: true
})
export class DateRangePipe implements PipeTransform {
  constructor(private readonly userDateService: UserDateService) {}

  public transform(
    dateRange: DateRange,
    format: DateFormatAlias = DateFormatAlias.DATE_ONLY
  ): string {
    return dateRange
      ? `${this.formatDate(dateRange.startDate, format)} - ${this.formatDate(
          dateRange.endDate,
          format
        )}`
      : '';
  }

  private formatDate(date: Date, format: DateFormatAlias): string {
    return date ? this.userDateService.formatDate(date, { format }) : '';
  }
}

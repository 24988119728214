import { Injectable } from '@angular/core';
import { Observable, Subscription, filter, map, pairwise, switchMap, take } from 'rxjs';
import { ComponentStore } from '@ngrx/component-store';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  FilterValueObject,
  PaginationResponse,
  TablePaginationConfig,
  TableSortConfig
} from '@neuralegion/api';
import { deepMerge } from '@neuralegion/lang';
import {
  LoadActionFactory,
  LoadActionType,
  LoadFailureActionType,
  LoadSuccessActionType,
  PaginationAction,
  PaginationActionType,
  PaginationState,
  PartialPaginationState
} from '../../models';
import { PaginationStateSerializationService } from './pagination-state-serialization.service';

export type ActionSuccessCallbackFn<
  T extends { id: string; createdAt?: string },
  S extends PaginationState = PaginationState,
  P extends PartialPaginationState = PartialPaginationState
> = (_state: S, payload: PaginationResponse<T>) => P;

@Injectable()
export abstract class PaginationStore<
  T extends { id: string; createdAt?: string },
  S extends PaginationState = PaginationState,
  P extends PartialPaginationState = PartialPaginationState
> extends ComponentStore<S> {
  private readonly callbackRegistry = new Map<
    PaginationActionType,
    (x: PaginationResponse<T> | string) => void
  >();

  public readonly pagination$: Observable<TablePaginationConfig> = this.select(
    (state: S) => state.pagination
  );

  public readonly filter$: Observable<FilterValueObject<unknown>> = this.select(
    (state: S) => state.filters
  );

  public readonly sort$: Observable<TableSortConfig> = this.select((state: S) => state.sort);

  protected abstract readonly resetUpdaterFn: (state: S) => P;
  protected abstract readonly resetSuccessCallbackFn: ActionSuccessCallbackFn<T, S, P>;
  public reset: () => void;

  protected abstract readonly updatePageSizeUpdaterFn: (state: S, pageSize: number) => P;
  protected abstract readonly updatePageSizeSuccessCallbackFn: ActionSuccessCallbackFn<T, S, P>;
  public updatePageSize: (observableOrValue: number | Observable<number>) => Subscription;

  protected abstract readonly updateFilterUpdaterFn: (
    state: S,
    filters: FilterValueObject<unknown>
  ) => P;
  protected abstract readonly updateFilterSuccessCallbackFn: ActionSuccessCallbackFn<T, S, P>;
  public updateFilter: (
    observableOrValue: FilterValueObject<unknown> | Observable<FilterValueObject<unknown>>
  ) => Subscription;

  protected abstract readonly updateSortUpdaterFn: (state: S, sort: TableSortConfig) => P;
  protected abstract readonly updateSortSuccessCallbackFn: ActionSuccessCallbackFn<T, S, P>;
  public updateSort: (
    observableOrValue: Observable<TableSortConfig> | TableSortConfig
  ) => Subscription;

  protected abstract readonly nextPageUpdaterFn: (state: S) => P;
  protected abstract readonly nextPageSuccessCallbackFn: ActionSuccessCallbackFn<T, S, P>;
  public nextPage: () => void;

  protected abstract readonly prevPageUpdaterFn: (state: S) => P;
  protected abstract readonly prevPageSuccessCallbackFn: ActionSuccessCallbackFn<T, S, P>;
  public prevPage: () => void;

  protected abstract readonly firstPageUpdaterFn: (state: S) => P;
  protected abstract readonly firstPageSuccessCallbackFn: ActionSuccessCallbackFn<T, S, P>;
  public firstPage: () => void;

  protected abstract readonly reloadPageUpdaterFn: (state: S) => P;
  protected abstract readonly reloadPageSuccessCallbackFn: ActionSuccessCallbackFn<T, S, P>;
  public reloadPage: () => void;

  public readonly loadStateFromStorage = this.updater(
    (_state: S, options: { rewriteFromURL: boolean }): S =>
      this.paginationStateSerializationService.deserializeState(options)
  );

  private readonly saveStateToStorage = (state: S): void => {
    this.paginationStateSerializationService.serializeState(state);
  };

  private readonly paginationAction$: Observable<PaginationAction | null> = this.select(
    (state: S) => state.activeAction
  );

  protected constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly paginationStateSerializationService: PaginationStateSerializationService<S>,
    defaultState: S
  ) {
    super(defaultState);
  }

  protected initActions(): void {
    this.reset = this.createActionWithSuccessCallback({
      actionType: PaginationActionType.RESET,
      updaterFn: this.resetUpdaterFn,
      successCallbackFn: this.resetSuccessCallbackFn
    });

    this.updatePageSize = this.createActionWithSuccessCallback({
      actionType: PaginationActionType.UPDATE_PAGE_SIZE,
      updaterFn: this.updatePageSizeUpdaterFn,
      successCallbackFn: this.updatePageSizeSuccessCallbackFn
    });

    this.updateFilter = this.createActionWithSuccessCallback({
      actionType: PaginationActionType.UPDATE_FILTER,
      updaterFn: this.updateFilterUpdaterFn,
      successCallbackFn: this.updateFilterSuccessCallbackFn
    });

    this.updateSort = this.createActionWithSuccessCallback({
      actionType: PaginationActionType.UPDATE_SORT,
      updaterFn: this.updateSortUpdaterFn,
      successCallbackFn: this.updateSortSuccessCallbackFn
    });

    this.nextPage = this.createActionWithSuccessCallback({
      actionType: PaginationActionType.LOAD_NEXT_PAGE,
      updaterFn: this.nextPageUpdaterFn,
      successCallbackFn: this.nextPageSuccessCallbackFn
    });

    this.prevPage = this.createActionWithSuccessCallback({
      actionType: PaginationActionType.LOAD_PREV_PAGE,
      updaterFn: this.prevPageUpdaterFn,
      successCallbackFn: this.prevPageSuccessCallbackFn
    });

    this.firstPage = this.createActionWithSuccessCallback({
      actionType: PaginationActionType.LOAD_FIRST_PAGE,
      updaterFn: this.firstPageUpdaterFn,
      successCallbackFn: this.firstPageSuccessCallbackFn
    });

    this.reloadPage = this.createActionWithSuccessCallback({
      actionType: PaginationActionType.RELOAD_PAGE,
      updaterFn: this.reloadPageUpdaterFn,
      successCallbackFn: this.reloadPageSuccessCallbackFn
    });
  }

  public init(
    loadActionOrFactory: LoadActionType | LoadActionFactory,
    loadSuccessAction: LoadSuccessActionType<T>,
    loadFailAction: LoadFailureActionType
  ) {
    this.initDataLoadSuccessListener();

    this.initDataLoader(
      this.callbackRegistry,
      loadActionOrFactory,
      loadSuccessAction,
      loadFailAction
    )(this.paginationAction$);
  }

  private readonly initDataLoader = (
    callbackRegistry: Map<PaginationActionType, (x: PaginationResponse<T> | string) => void>,
    loadActionOrFactory: LoadActionType | LoadActionFactory,
    loadSuccessAction: LoadSuccessActionType<T>,
    loadFailAction: LoadFailureActionType
  ) =>
    this.effect((action$: Observable<PaginationAction>) =>
      action$.pipe(
        filter(Boolean),
        switchMap((action: PaginationAction) => {
          this.store.dispatch(loadActionOrFactory({ params: action.payload }));
          return this.actions$.pipe(
            ofType(loadSuccessAction, loadFailAction),
            take(1),
            map(
              (
                resultAction:
                  | ReturnType<LoadSuccessActionType<T>>
                  | ReturnType<LoadFailureActionType>
              ) => {
                if (resultAction.type === loadSuccessAction.type) {
                  callbackRegistry.get(action.type)?.(resultAction.payload);
                }
              }
            )
          );
        })
      )
    );

  private readonly initDataLoadSuccessListener = () => {
    this.effect((action$: Observable<PaginationAction>) =>
      action$.pipe(
        pairwise(),
        filter(
          ([prevAction, currAction]: [PaginationAction, PaginationAction]) =>
            prevAction != null && currAction == null
        ),
        map(([prevAction]: [PaginationAction, PaginationAction]) => {
          const updatedState = {
            ...this.get(),
            refreshParams: prevAction.payload
          };
          this.setState(updatedState);
          this.saveStateToStorage(updatedState);
        })
      )
    )(this.paginationAction$);
  };

  protected createActionWithSuccessCallback<
    ProvidedType = void,
    ReturnType = ProvidedType extends void
      ? () => void
      : (observableOrValue: ProvidedType | Observable<ProvidedType>) => Subscription
  >({
    actionType,
    updaterFn,
    successCallbackFn
  }: {
    actionType: PaginationActionType;
    updaterFn: (state: S, value: ProvidedType) => P;
    successCallbackFn: ActionSuccessCallbackFn<T, S, P>;
  }): ReturnType {
    if (this.callbackRegistry.has(actionType)) {
      throw new Error(`callback for action ${actionType} is already registered`);
    }
    this.callbackRegistry.set(
      actionType,
      this.updater((state: S, value: PaginationResponse<T>) => {
        const updatedState = deepMerge(state, successCallbackFn(state, value));
        this.saveStateToStorage(updatedState);
        return updatedState;
      })
    );

    return this.updater((state: S, value: ProvidedType) => {
      const updatedState = deepMerge(state, updaterFn(state, value));
      updatedState.activeAction.type = actionType;
      return updatedState;
    });
  }
}

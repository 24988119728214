import { BaseElementIdStrategy } from './base-element-id-strategy';

export class SnackbarElementIdStrategy extends BaseElementIdStrategy {
  private readonly elementPrefix = 'snackbar';

  protected override getSubId(element: HTMLElement): string {
    return [this.elementPrefix, this.getNotificationIndex(element)].join(this.SEPARATOR_INTERNAL);
  }

  private getNotificationIndex(element: HTMLElement): number {
    const toasts = element.closest<HTMLElement>('#toast-container').querySelectorAll('.snack-bar');

    const ids = Array.from(toasts).map(
      (toast) => +toast.getAttribute('data-id')?.split(this.SEPARATOR_INTERNAL).at(1) || 0
    );

    return Math.max(...ids, 0) + 1;
  }
}

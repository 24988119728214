import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

export interface HighlightOptions {
  /**
   * The language of the code to render:
   * "bsh", "c", "cc", "cpp", "cs", "csh", "cyc", "cv", "htm", "html", "java",
   *  "js", "m", "mxml", "perl", "pl", "pm", "py", "rb", "sh", "xhtml", "xml", "xsl"
   * Full list: https://github.com/google/code-prettify
   */
  language?: string;
  /**
   * Whether to display line numbers:
   *  - false: don't display
   *  - true: do display
   */
  linenums?: number | boolean;
}

const defaultOptions: HighlightOptions = {
  language: null,
  linenums: false
};

type PrettyPrintFn = (code: string, language?: string, linenums?: number | boolean) => string;

/**
 * Uses https://github.com/google/code-prettify to format code snippet into highlighted html.
 */
@Injectable({
  providedIn: 'root'
})
export class CodeHighlightService {
  private readonly prettyPrintFnPromise: Promise<PrettyPrintFn>;

  constructor(@Inject(DOCUMENT) private readonly document: Document) {
    this.prettyPrintFnPromise = this.loadCodePrettify();
  }

  public async highlight(code: string, options?: HighlightOptions): Promise<string> {
    const opts = {
      ...defaultOptions,
      ...(options || {})
    };

    if (!code) {
      return code;
    }

    const prettyPrint: PrettyPrintFn = await this.prettyPrintFnPromise;
    try {
      return prettyPrint(code, opts.language, opts.linenums);
    } catch (err) {
      const msg = `Could not format code that begins '${code.substring(0, 50)}...'.`;
      console.error(msg, err);
      throw new Error(msg);
    }
  }

  private async loadCodePrettify(): Promise<PrettyPrintFn> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    await import(/* webpackChunkName: "code-format" */ 'code-prettify');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ppo: PrettyPrintFn = (this.document.defaultView as any).PR.prettyPrintOne;
    if (!ppo) {
      throw new Error('Cannot load code-prettify');
    }
    return ppo;
  }
}

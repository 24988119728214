import { ID } from '../common/ID';
import { ProviderType } from '../user/ProviderType';

export interface OidcSettings {
  clientId: string;
  clientSecret: string;
  metadataURL: string;
}

export enum ProviderProtocol {
  OIDC = 'oidc',
  SAML = 'saml'
}

export interface GithubSettings {
  orgId: string;
}

export interface GoogleSettings {
  domain: string;
}

export interface SamlSettings {
  metadataURL: string;
  entityId?: string;
  ssoUrl?: string;
  x509cert?: string;
  singleLogoutURL?: string;
}

export type AuthProviderConfig = OidcSettings | GithubSettings | GoogleSettings | SamlSettings;

export interface AuthProvider extends ID {
  providerType: ProviderType;
  defaultRoleId?: string;
  pendingLinks?: number;
  installed?: boolean;
  forceSso?: boolean;
  provisioning?: boolean;
  protocol?: ProviderProtocol;
  authUrl?: string;
  config?: AuthProviderConfig;
  attributes?: Record<string, string>;
  singleLogout?: boolean;
}

import { Pipe, PipeTransform } from '@angular/core';
import { HeaderConfig } from '@neuralegion/api';

@Pipe({
  name: 'headerConfig',
  pure: true
})
export class HeaderConfigPipe implements PipeTransform {
  public transform(value: HeaderConfig[]): string[] {
    return value?.length ? value?.map((v) => `(${v.mergeStrategy}) ${v.name} : ${v.value}\n`) : [];
  }
}

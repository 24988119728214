import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight',
  pure: true
})
export class HighlightPipe implements PipeTransform {
  private readonly ALLOWED_CHARSET = /^[\w.:/*-]+$/;

  public transform(value: string, needle: string): string {
    if (
      !needle ||
      !value ||
      !this.ALLOWED_CHARSET.test(value) ||
      !this.ALLOWED_CHARSET.test(needle)
    ) {
      return value;
    }

    return value.replace(new RegExp(`(${this.escapeRegExp(needle)})`, 'gi'), '<b>$1</b>');
  }

  private escapeRegExp(string: string): string {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }
}

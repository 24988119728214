import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isValidHeaderName, isValidHeaderValue, isValidUrl } from '@neuralegion/core';
import { PeggySyntaxError, parse } from '@neuralegion/nextemplate';

export enum NexTemplateContext {
  AUTH = 'auth_object',
  ENTRYPOINT = 'entrypoint'
}

export enum NexTemplateAuthObjectContextGroup {
  STAGES = 'stages',
  OTPS = 'otps'
}

export class NexTemplateValidators {
  public static readonly ANY_STAGE = 'any';

  public static template(c: AbstractControl): ValidationErrors | null {
    if (!c.value) {
      return null;
    }

    try {
      parse(c.value);
      return null;
    } catch (e) {
      return NexTemplateValidators.formatSyntaxError(e);
    }
  }

  public static templateContextOnly(
    context: NexTemplateContext,
    customErrorText?: string
  ): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      if (!c.value) {
        return null;
      }

      try {
        return (parse(c.value) as unknown[]).every(
          (item: string | { context?: string }) =>
            // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
            typeof item === 'string' || item?.context === context
        )
          ? null
          : {
              nexTemplate:
                customErrorText ??
                `Only '${context}' context can be used in ${
                  context === NexTemplateContext.ENTRYPOINT
                    ? 'entrypoint editor'
                    : 'authentications'
                }`
            };
      } catch (e) {
        return NexTemplateValidators.formatSyntaxError(e);
      }
    };
  }

  public static templateSteps(
    stepsProvider: () => { stepNames: string[]; stepIndex?: number }
  ): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      const { stepNames, stepIndex } = stepsProvider();
      const insideStep = typeof stepIndex === 'number';
      const res = NexTemplateValidators.template(c);
      if (!stepNames || !c.value || res) {
        return res;
      }

      const stepNamesSet = new Set<string>(stepNames);
      const validStepNamesSet = new Set<string>(
        stepNames.slice(0, insideStep ? stepIndex : stepNames.length).filter(Boolean)
      );

      if (!insideStep || stepIndex > 0) {
        validStepNamesSet.add(NexTemplateValidators.ANY_STAGE);
      }

      try {
        const usedStepNames = NexTemplateValidators.parseStepNames(
          c.value,
          NexTemplateAuthObjectContextGroup.STAGES
        );

        const unknownStepName = usedStepNames.find(
          (stepName) => !stepNamesSet.has(stepName) && stepName !== NexTemplateValidators.ANY_STAGE
        );
        if (unknownStepName) {
          return { nexTemplate: `Unknown stage: ${unknownStepName}` };
        }

        const invalidStepName = usedStepNames.find((stepName) => !validStepNamesSet.has(stepName));
        return !invalidStepName
          ? null
          : {
              nexTemplate: `Cannot reference stage "${invalidStepName}" ${
                stepIndex ? `from "${stepNames[stepIndex]}"` : ''
              }`
            };
      } catch (e) {
        return NexTemplateValidators.formatSyntaxError(e);
      }
    };
  }

  public static templateOtps(otpsProvider: () => { otpNames: string[] }): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      const { otpNames } = otpsProvider();
      const res = NexTemplateValidators.template(c);
      if (!otpNames || !c.value || res) {
        return res;
      }

      const otpNamesSet = new Set<string>(otpNames);

      try {
        const usedOtpNames = NexTemplateValidators.parseStepNames(
          c.value,
          NexTemplateAuthObjectContextGroup.OTPS
        );

        const unknownOtpName = usedOtpNames.find((otpName) => !otpNamesSet.has(otpName));

        if (unknownOtpName) {
          return { nexTemplate: `Unknown OTP token: ${unknownOtpName}` };
        }

        return null;
      } catch (e) {
        return NexTemplateValidators.formatSyntaxError(e);
      }
    };
  }

  private static parseTemplateVariables(controlValue: string): { source: string }[] {
    return (parse(controlValue) as { source: string }[]).filter(
      (item: unknown) => typeof item !== 'string' && (item as { type: string })?.type === 'variable'
    );
  }

  private static parseStepNames(
    controlValue: string,
    contextGroup: NexTemplateAuthObjectContextGroup
  ): string[] | undefined {
    return NexTemplateValidators.parseTemplateVariables(controlValue)
      .map((item: { source: string }) => item.source.split('.'))
      .reduce<string[]>((acc, [groupName, stepName]) => {
        if ((groupName as NexTemplateAuthObjectContextGroup) === contextGroup) {
          acc.push(stepName.trim());
        }
        return acc;
      }, []);
  }

  private static formatSyntaxError(e: unknown): ValidationErrors {
    return {
      nexTemplate:
        e instanceof PeggySyntaxError
          ? `Offset ${e.location?.start.offset || 'N/A'}: ${e.message}`
          : 'Invalid template'
    };
  }

  public static templatedUrl(c: AbstractControl): ValidationErrors | null {
    return NexTemplateValidators.validateInterpolatedValue(c, (interpolatedValue: string) =>
      isValidUrl(interpolatedValue) ? null : { url: true }
    );
  }

  public static templatedHeaderName(control: AbstractControl): ValidationErrors | null {
    return NexTemplateValidators.validateInterpolatedValue(control, (interpolatedValue: string) =>
      isValidHeaderName(interpolatedValue) ? null : { headerName: true }
    );
  }

  public static templatedHeaderValue(control: AbstractControl): ValidationErrors | null {
    return NexTemplateValidators.validateInterpolatedValue(control, (interpolatedValue: string) =>
      isValidHeaderValue(interpolatedValue) ? null : { headerValue: true }
    );
  }

  private static validateInterpolatedValue(
    control: AbstractControl,
    callback: (value: string) => ValidationErrors | null
  ): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    try {
      const parsed = parse(control.value) as unknown[];
      const interpolatedValue = parsed.reduce(
        (res: string, item: unknown) => res + (typeof item === 'string' ? item : 'abc'),
        ''
      );

      return callback(interpolatedValue);
    } catch (e) {
      return NexTemplateValidators.formatSyntaxError(e);
    }
  }

  public static templateContextGroupOnly(
    contextGroup: NexTemplateAuthObjectContextGroup
  ): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      if (!c.value) {
        return null;
      }

      try {
        const templateVariables = NexTemplateValidators.parseTemplateVariables(c.value);

        const invalidVariable = templateVariables.find(
          (variable) => !variable.source.startsWith(`${contextGroup}.`)
        );
        return invalidVariable
          ? {
              nexTemplate: `Unsupported interpolation token {{${invalidVariable.source}}} for this field`
            }
          : null;
      } catch (e) {
        return NexTemplateValidators.formatSyntaxError(e);
      }
    };
  }
}

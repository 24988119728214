import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, map } from 'rxjs';

// eslint-disable-next-line @angular-eslint/use-component-selector
@Component({
  templateUrl: './reset-password-sent-page.component.html',
  styleUrls: [
    '../signup-success-page/signup-success-page.component.scss',
    './reset-password-sent-page.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetPasswordSentPageComponent implements OnInit {
  public email$: Observable<string>;

  constructor(private readonly activatedRoute: ActivatedRoute) {}

  public ngOnInit(): void {
    this.email$ = this.activatedRoute.params.pipe(
      map((params: { email?: string }) => params.email)
    );
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Scope } from '@neuralegion/api';
import { SbacGuard } from './sbac.guard';

@Injectable()
export class IssuesGuard extends SbacGuard {
  constructor(router: Router, store: Store) {
    super(router, store, Scope.ISSUES_READ);
  }
}

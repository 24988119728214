import { NumberInput, coerceNumberProperty } from '@angular/cdk/coercion';
import { AfterViewInit, Directive, HostListener, Input, OnDestroy } from '@angular/core';
import { Subject, delay, of, switchMap, takeUntil } from 'rxjs';
import { SatPopoverAnchorDirective } from './popover-anchor.directive';

/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle */

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[satPopoverHover]'
})
export class SatPopoverHoverDirective implements AfterViewInit, OnDestroy {
  /**
   * Amount of time to delay (ms) after hovering starts before
   * the popover opens. Defaults to 0ms.
   */
  @Input()
  get satPopoverHover() {
    return this._satPopoverHover;
  }
  set satPopoverHover(val: NumberInput) {
    this._satPopoverHover = coerceNumberProperty(val);
  }
  private _satPopoverHover = 0;

  /** Emits when the directive is destroyed. */
  private readonly _onDestroy = new Subject<void>();

  /** Emits when the user's mouse enters the element. */
  private readonly _onMouseEnter = new Subject<void>();

  /** Emits when the user's mouse leaves the element. */
  private readonly _onMouseLeave = new Subject<void>();

  constructor(public anchor: SatPopoverAnchorDirective) {}

  public ngAfterViewInit(): void {
    // Whenever the user hovers this host element, delay the configured
    // amount of time and open the popover. Terminate if the mouse leaves
    // the host element before the delay is complete.
    this._onMouseEnter
      .pipe(
        switchMap(() => {
          return of(null).pipe(delay(this._satPopoverHover || 0), takeUntil(this._onMouseLeave));
        }),
        takeUntil(this._onDestroy)
      )
      .subscribe(() => this.anchor.popover.open());
  }

  public ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  @HostListener('mouseenter')
  public showPopover(): void {
    this._onMouseEnter.next();
  }

  @HostListener('mouseleave')
  public closePopover(): void {
    this._onMouseLeave.next();
    this.anchor.popover.close();
  }
}

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InviteDetails, SignedUpUser } from '@neuralegion/api';
import { IGNORE_ERROR_INTERCEPTOR_TOKEN } from '@neuralegion/error-handler';
import { RegistrationForm } from '../models';

@Injectable()
export class SignupService {
  constructor(private readonly http: HttpClient) {}

  // API sends null in case of account with these credits already exist
  // details: NLJ-2065
  public register(data: RegistrationForm): Observable<SignedUpUser | null> {
    return this.http.post<SignedUpUser>('/api/v1/register', data, {
      context: new HttpContext().set(IGNORE_ERROR_INTERCEPTOR_TOKEN, true)
    });
  }

  public loadInviteDetails(memberId: string, token: string): Observable<InviteDetails> {
    return this.http.get<InviteDetails>(`/api/v1/invites/${memberId}/${token}`);
  }

  public acceptInvite(memberId: string, token: string): Observable<void> {
    return this.http.post<void>(
      `/api/v1/invites/${memberId}/${token}`,
      {},
      { withCredentials: true }
    );
  }
}

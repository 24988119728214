import { ChangeDetectionStrategy, Component, Inject, TemplateRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';

export interface ConfirmModalData<T = unknown> {
  title?: string;
  message?: string;
  buttonOk?: string;
  buttonCancel?: string;
  contentTemplateRef?: TemplateRef<T>;
  contentTemplateContext?: T;
  prohibitCancel?: boolean;
  dataId?: string;
}

// eslint-disable-next-line @angular-eslint/use-component-selector
@Component({
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public readonly data$: Observable<ConfirmModalData>) {}
}

import { Pipe, PipeTransform } from '@angular/core';
import { UserDateService } from '@neuralegion/core';

@Pipe({
  name: 'tzDate'
})
export class TzDatePipe implements PipeTransform {
  constructor(private readonly userDateService: UserDateService) {}

  public transform(date: Date): Date {
    return date ? this.userDateService.applyTz(date) : date;
  }
}

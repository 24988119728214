import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { BillingSummary, Product } from '../models';

@Injectable()
export class BillingService {
  constructor(private readonly http: HttpClient) {}

  public loadSummary(): Observable<BillingSummary> {
    return this.http.get<BillingSummary>('/api/v1/billing');
  }

  public loadProducts(): Observable<Product[]> {
    return this.http
      .get<Product[]>('/api/v1/products')
      .pipe(map((products: Product[]): Product[] => [...products].sort(this.productsComparator)));
  }

  private productsComparator(a: Product, b: Product): number {
    if (!a.plans?.length) {
      return 1;
    }

    if (!b.plans?.length) {
      return -1;
    }

    return a.plans[0].price - b.plans[0].price;
  }
}

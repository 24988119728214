import { createAction } from '@ngrx/store';
import { Payment, PaymentResult, UpcomingInvoice } from '../models';

export const loadPaymentHistory = createAction('[BillingPayments] LOAD_PAYMENT_HISTORY');

export const loadPaymentHistoryFail = createAction(
  '[BillingPayments] LOAD_PAYMENT_HISTORY_FAIL',
  (payload: string) => ({ payload, parentType: loadPaymentHistory.type })
);

export const loadPaymentHistorySuccess = createAction(
  '[BillingPayments] LOAD_PAYMENT_HISTORY_SUCCESS',
  (payload: { paymentHistory: Payment[] }) => ({ payload, parentType: loadPaymentHistory.type })
);

export const loadUpcomingInvoice = createAction(
  '[BillingPayments] LOAD_UPCOMING_INVOICE',
  (payload: { planId: string; coupon?: string }) => ({ payload })
);

export const loadUpcomingInvoiceFail = createAction(
  '[BillingPayments] LOAD_UPCOMING_INVOICE_FAIL',
  (payload: string) => ({ payload, parentType: loadUpcomingInvoice.type })
);

export const loadUpcomingInvoiceSuccess = createAction(
  '[BillingPayments] LOAD_UPCOMING_INVOICE_SUCCESS',
  (payload: { upcomingInvoice: UpcomingInvoice }) => ({
    payload,
    parentType: loadUpcomingInvoice.type
  })
);

export const repay = createAction(
  '[BillingPayments] REPAY',
  (payload: { paymentId: string; paymentMethodId: string }) => ({ payload })
);

export const repayFail = createAction('[BillingPayments] REPAY_FAIL', (payload: string) => ({
  payload,
  parentType: repay.type
}));

export const repaySuccess = createAction(
  '[BillingPayments] REPAY_SUCCESS',
  (payload: { paymentId: string; paymentMethodId: string; paymentResult: PaymentResult }) => ({
    payload,
    parentType: repay.type
  })
);

export const unsubscribe = createAction(
  '[BillingPayments] UNSUBSCRIBE',
  (payload: { subscriptionId: string }) => ({
    payload
  })
);

export const unsubscribeFail = createAction(
  '[BillingPayments] UNSUBSCRIBE_FAIL',
  (payload: string) => ({
    payload,
    parentType: unsubscribe.type
  })
);

export const unsubscribeSuccess = createAction('[BillingPayments] UNSUBSCRIBE_SUCCESS', () => ({
  parentType: unsubscribe.type
}));

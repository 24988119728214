import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Subject, distinctUntilChanged, takeUntil } from 'rxjs';
import equal from 'fast-deep-equal/es6';
import { TabConfig } from '@neuralegion/api';
import { trackByIdentity } from '@neuralegion/core';

@Component({
  selector: 'share-tabs-config',
  templateUrl: './tabs-config.component.html',
  styleUrls: ['./tabs-config.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabsConfigComponent implements OnInit, OnDestroy {
  @Input()
  set tabsConfig(value: TabConfig[]) {
    this.valueSubject.next(value);
  }

  @Output()
  public readonly tabsConfigChanged = new EventEmitter<TabConfig[]>();

  public readonly trackByIdentity = trackByIdentity;

  public form: FormGroup = new FormGroup({ tabs: new FormArray([]) });

  get tabsFormGroups(): FormGroup[] {
    return (this.form.get('tabs') as FormArray).controls as FormGroup[];
  }

  public valueSubject = new BehaviorSubject<TabConfig[]>([]);

  private readonly gc = new Subject<void>();

  public ngOnInit(): void {
    this.form.setControl(
      'tabs',
      new FormArray(
        this.valueSubject.value.map(
          () =>
            new FormGroup({
              id: new FormControl(''),
              active: new FormControl(false),
              label: new FormControl(''),
              fixed: new FormControl(false)
            })
        )
      )
    );

    this.form.valueChanges
      .pipe(takeUntil(this.gc))
      .subscribe(({ tabs }: { tabs: { id: string; active: boolean }[] }) => {
        const tabsConfig = this.valueSubject.getValue();
        this.tabsConfigChanged.emit(
          tabsConfig.map((tabConfig: TabConfig) => {
            const tab = tabs.find((c: { id: string; active: boolean }) => c.id === tabConfig.id);
            return tab
              ? {
                  ...tabConfig,
                  active: tab.active
                }
              : { ...tabConfig };
          })
        );
      });

    this.valueSubject
      .pipe(distinctUntilChanged(equal), takeUntil(this.gc))
      .subscribe((tabsConfig: TabConfig[]) => {
        this.form.setValue(
          {
            tabs: tabsConfig.map((c: TabConfig) => ({
              id: c.id,
              active: c.active,
              label: c.label,
              fixed: c.fixed || false
            }))
          },
          { emitEvent: false }
        );

        this.setDisabledStateForFixed(tabsConfig);
      });
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }

  private setDisabledStateForFixed(value: TabConfig[]): void {
    value.forEach((c: TabConfig, idx: number) => {
      if (c.fixed) {
        (this.form.get('tabs') as FormArray).controls[idx].disable({ emitEvent: false });
      } else {
        (this.form.get('tabs') as FormArray).controls[idx].enable({ emitEvent: false });
      }
    });
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  BillingContactInfo,
  BillingSummary,
  Plan,
  Product,
  ProductCategory,
  Subscription
} from '../models';
import { BillingState } from './billing.state';

// eslint-disable-next-line @typescript-eslint/ban-types
type State = object;
const selectBillingState = createFeatureSelector<BillingState>('billing');

export const selectBillingSummary = createSelector<State, [BillingState], BillingSummary>(
  selectBillingState,
  (state: BillingState) => state.summary
);

export const selectBillingContactInfo = createSelector<State, [BillingSummary], BillingContactInfo>(
  selectBillingSummary,
  (summary: BillingSummary) => summary?.contactInfo
);

/*** All products/plans ***/

const selectProducts = createSelector<State, [BillingState], Product[]>(
  selectBillingState,
  (state: BillingState) => state.products
);

/*** Service products/plans ***/

export const selectServiceProducts = createSelector<State, [Product[]], Product[]>(
  selectProducts,
  (products: Product[]) =>
    products?.filter((product) => product.category === ProductCategory.SERVICE)
);

export const selectActiveServiceProducts = createSelector<State, [Product[]], Product[]>(
  selectServiceProducts,
  (products: Product[]) => products?.filter((product) => product.active)
);

export const selectServicePlans = createSelector<State, [Product[]], Plan[]>(
  selectServiceProducts,
  (products: Product[]) =>
    products?.reduce((res: Plan[], product) => [...res, ...product.plans], [])
);

export const selectCurrentServiceSubscription = createSelector<
  State,
  [BillingSummary, Plan[]],
  Subscription
>(selectBillingSummary, selectServicePlans, (summary: BillingSummary, plans: Plan[]) => {
  const servicePlanIds = new Set<string>(plans?.map((plan) => plan.id));
  return summary?.subscriptions.find((subscription: Subscription) =>
    servicePlanIds.has(subscription.plan?.id)
  );
});

export const selectCurrentServiceSubscriptionId = createSelector<State, [Subscription], string>(
  selectCurrentServiceSubscription,
  (subscription: Subscription) => subscription?.id
);

export const selectCurrentServicePlan = createSelector<State, [Subscription], Plan>(
  selectCurrentServiceSubscription,
  (subscription: Subscription) => subscription?.plan
);

export const selectCurrentServiceProduct = createSelector<State, [Plan, Product[]], Product>(
  selectCurrentServicePlan,
  selectServiceProducts,
  (currentPlan: Plan, products: Product[]) =>
    (products || []).find((product: Product) =>
      product.plans.some((plan: Plan) => plan.id === currentPlan?.id)
    )
);

export const selectCurrentServiceProductId = createSelector<State, [Product], string>(
  selectCurrentServiceProduct,
  (product: Product) => product?.id
);

/*** Engines products/plans ***/

export const selectEngineProducts = createSelector<State, [Product[]], Product[]>(
  selectProducts,
  (products: Product[]) =>
    products?.filter((product) => product.category === ProductCategory.ENGINES)
);

export const selectEnginePlans = createSelector<State, [Product[]], Plan[]>(
  selectEngineProducts,
  (products: Product[]) =>
    products?.reduce((res: Plan[], product) => [...res, ...product.plans], [])
);

const selectCurrentEngineSubscription = createSelector<
  State,
  [BillingSummary, Plan[]],
  Subscription
>(selectBillingSummary, selectEnginePlans, (summary: BillingSummary, plans: Plan[]) => {
  const enginePlanIds = new Set<string>(plans?.map((plan) => plan.id));
  return summary?.subscriptions.find((subscription: Subscription) =>
    enginePlanIds.has(subscription.plan?.id)
  );
});

export const selectEnginesQuantity = createSelector<State, [Subscription], number>(
  selectCurrentEngineSubscription,
  (subscription: Subscription) => subscription?.quantity || 0
);

/*** Storage products/plans ***/

export const selectStorageProducts = createSelector<State, [Product[]], Product[]>(
  selectProducts,
  (products: Product[]) =>
    products?.filter((product) => product.category === ProductCategory.FILE_STORAGE)
);

export const selectStoragePlans = createSelector<State, [Product[]], Plan[]>(
  selectStorageProducts,
  (products: Product[]) =>
    products?.reduce((res: Plan[], product) => [...res, ...product.plans], [])
);

const selectCurrentStorageSubscription = createSelector<
  State,
  [BillingSummary, Plan[]],
  Subscription
>(selectBillingSummary, selectStoragePlans, (summary: BillingSummary, plans: Plan[]) => {
  const storagePlanIds = new Set<string>(plans?.map((plan) => plan.id));
  return summary?.subscriptions.find((subscription: Subscription) =>
    storagePlanIds.has(subscription.plan?.id)
  );
});

export const selectStorageQuantity = createSelector<State, [Subscription], number>(
  selectCurrentStorageSubscription,
  (subscription: Subscription) => subscription?.quantity || 0
);

export const selectBillingPending = createSelector<State, [BillingState], boolean>(
  selectBillingState,
  (state: BillingState) => state?.pending?.length > 0
);

import { createAction } from '@ngrx/store';
import { Project } from '../models';

export const loadProjects = createAction(
  '[ProjectsLookup] LOAD_PROJECTS_BY_IDS',
  (payload: string[], force = false) => ({ payload, force })
);

export const loadProjectsSuccess = createAction(
  '[ProjectsLookup] LOAD_PROJECTS_BY_IDS_SUCCESS',
  (payload: Project[]) => ({ payload, parentType: loadProjects.type })
);

export const loadProjectsFail = createAction(
  '[ProjectsLookup] LOAD_PROJECTS_BY_IDS_FAIL',
  (payload: string) => ({
    payload,
    parentType: loadProjects.type
  })
);

export const loadPredefinedProject = createAction('[ProjectsLookup] LOAD_PREDEFINED_PROJECT');

export const loadPredefinedProjectSuccess = createAction(
  '[ProjectsLookup] LOAD_PREDEFINED_PROJECT_SUCCESS',
  (payload: Project | null) => ({ payload, parentType: loadPredefinedProject.type })
);

export const loadPredefinedProjectFail = createAction(
  '[ProjectsLookup] LOAD_PREDEFINED_PROJECT_FAIL',
  (payload: string) => ({
    payload,
    parentType: loadPredefinedProject.type
  })
);

export const resetProjects = createAction('[ProjectsLookup] RESET_PROJECTS');

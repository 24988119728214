import { HttpResponseBase } from '@angular/common/http';

export interface FileDownloadResponse<T = Blob> {
  readonly file: T;
  readonly filename: string;
}

export const getFileNameFromResponseContentDisposition = (res: HttpResponseBase): string => {
  const contentDisposition: string = res.headers.get('content-disposition') || '';
  const matches: string[] = /filename="?([^;"]+)"?/gi.exec(contentDisposition);
  return (matches?.[1] ?? 'untitled').trim();
};

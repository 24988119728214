<mat-card>
  <mat-card-title role="heading" aria-level="1"> Single Sign-On </mat-card-title>
  <mat-card-content>
    <auth-sso-providers-list
      fxLayout="column"
      fxLayoutAlign="space-around stretch"
      [btnPrefix]="'Sign in with '"
      [providers]="providerTypes$ | async"
      (selected)="ssoLogin()">
    </auth-sso-providers-list>

    <auth-link-signin [message]="'Want to use another account? Go to'"></auth-link-signin>
  </mat-card-content>
</mat-card>

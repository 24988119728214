import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Payment, UpcomingInvoice } from '../models';
import { PaymentsState, paymentsEntityAdapter } from './payments.state';

// eslint-disable-next-line @typescript-eslint/ban-types
type State = object;
const selectPaymentsState = createFeatureSelector<PaymentsState>('payments');

export const selectPaymentHistory = createSelector<State, [PaymentsState], Payment[]>(
  selectPaymentsState,
  paymentsEntityAdapter.getSelectors().selectAll
);

export const selectUpcomingInvoice = createSelector<State, [PaymentsState], UpcomingInvoice>(
  selectPaymentsState,
  (state: PaymentsState) => state.upcomingInvoice
);

export const selectCoupon = createSelector<State, [PaymentsState], string>(
  selectPaymentsState,
  (state: PaymentsState) => state.coupon
);

export const selectPaymentsPending = createSelector<State, [PaymentsState], boolean>(
  selectPaymentsState,
  (state: PaymentsState) => state.pending?.length > 0
);

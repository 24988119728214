import { Action } from '@ngrx/store';
import { UserConsents } from '@neuralegion/api';

export interface UserConsentsState {
  readonly pending: Action[];
  readonly consents: UserConsents | null;
}

export const initialUserConsentsState: UserConsentsState = {
  pending: [],
  consents: null
};

import { BaseElementIdStrategy } from './base-element-id-strategy';

export class MatOptionElementIdStrategy extends BaseElementIdStrategy {
  private readonly elementPrefix = ['dropdown', 'item'].join(this.SEPARATOR_INTERNAL);

  protected override getSubId(element: HTMLElement): string {
    return [this.elementPrefix, this.getElementIndex(element)].join(this.SEPARATOR_INTERNAL);
  }

  private getElementIndex(element: HTMLElement): number {
    return Array.from(element.parentNode.children).indexOf(element);
  }
}

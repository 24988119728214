<mat-toolbar role="banner">
  <mat-toolbar-row>
    <div aria-label="Primary" [attr.role]="opened ? 'none' : 'navigation'" fxHide fxShow.lt-md>
      <button
        aria-haspopup="true"
        [attr.aria-expanded]="opened"
        attr.aria-label="{{ opened ? 'Close' : 'Open' }} navigation"
        mat-icon-button
        (click)="openMenu.emit()">
        <mat-icon>menu</mat-icon>
      </button>
    </div>
    <share-breadcrumb></share-breadcrumb>
    <ng-content></ng-content>
  </mat-toolbar-row>
</mat-toolbar>

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SatPopoverComponent } from '../../sat-popover';

@Component({
  selector: 'share-info-tooltip',
  templateUrl: './info-tooltip.component.html',
  styleUrls: ['./info-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoTooltipComponent {
  @Input()
  public link: string;

  @Input()
  public linkText = 'More details';

  @Input()
  public label = '';

  @Input()
  public trigger: 'click' | 'hover' = 'click';

  @Output()
  public readonly clicked = new EventEmitter<void>();

  public onTooltipToggle(e: Event, tooltip: SatPopoverComponent): void {
    e.preventDefault();
    e.stopImmediatePropagation();

    tooltip.toggle();
    this.clicked.emit();
  }
}

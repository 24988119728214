import { Action, createReducer, on } from '@ngrx/store';
import { cleanupPendingState } from '@neuralegion/core';
import {
  associateFileWithProject,
  associateFileWithProjectFail,
  associateFileWithProjectSuccess,
  loadFiles,
  loadFilesFail,
  loadFilesSuccess,
  removeFiles,
  removeFilesFail,
  removeFilesSuccess
} from './storage.actions';
import { StorageState, initialStorageState } from './storage.state';

export const storageReducer = createReducer<StorageState, Action>(
  initialStorageState,
  on(
    removeFiles,
    (state: StorageState, action): StorageState => ({
      ...state,
      pending: [...state.pending, action],
      error: null
    })
  ),
  on(
    loadFiles,
    (state: StorageState, action): StorageState => ({
      ...state,
      pending: [...state.pending, action],
      files: [],
      error: null
    })
  ),
  on(
    associateFileWithProject,
    (state: StorageState, action): StorageState => ({
      ...state,
      pending: [...state.pending, action]
    })
  ),
  on(
    removeFilesSuccess,
    associateFileWithProjectSuccess,
    (state: StorageState, action): StorageState => cleanupPendingState(state, action)
  ),
  on(
    associateFileWithProjectSuccess,
    (
      state: StorageState,
      { payload: { fileId, projectId } }: ReturnType<typeof associateFileWithProjectSuccess>
    ) => ({
      ...state,
      files: state.files.map((file) => ({
        ...file,
        projectId: file.id === fileId ? projectId : file.projectId
      }))
    })
  ),
  on(
    loadFilesSuccess,
    (state: StorageState, action): StorageState =>
      cleanupPendingState(
        {
          ...state,
          files: action.payload
        },
        action
      )
  ),
  on(
    removeFilesFail,
    loadFilesFail,
    associateFileWithProjectFail,
    (state: StorageState, action): StorageState =>
      cleanupPendingState(state, action, action.payload)
  )
);

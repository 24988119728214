import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  PipeTransform
} from '@angular/core';
import {
  Observable,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  share,
  startWith
} from 'rxjs';
import equal from 'fast-deep-equal/es6';
import { trackByIdentity } from '@neuralegion/core';
import { PluckPipe, SectionErrorPipe, SettingsTabSectionStatusPipe } from '../../pipes';
import { SettingsSectionsRequiredCounters } from '../../services';
import { SectionStatus } from '../settings-tab-label/settings-tab-label.component';

export interface FieldLocation<S> {
  section: S;
  control: string;
}

export interface SettingsError<S> extends FieldLocation<S> {
  error: string;
}

export interface SettingsTouchedState<S> extends FieldLocation<S> {
  touched: boolean;
}

interface NamedError<S> extends SettingsError<S> {
  name: string;
}

@Component({
  selector: 'share-settings-error-bar',
  templateUrl: './settings-error-bar.component.html',
  styleUrls: ['./settings-error-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsErrorBarComponent<S extends string> implements OnInit {
  @Input()
  public errors$: Observable<SettingsError<S>[]>;

  @Input()
  public counters$: Observable<Record<S, SettingsSectionsRequiredCounters<S>>>;

  @Input()
  public visitedSections$: Observable<ReadonlySet<S>>;

  @Input()
  public sectionsOrder: readonly S[];

  @Input()
  public sectionNamePipe: PipeTransform;

  @Output()
  public readonly navigated = new EventEmitter<FieldLocation<S>>();

  public namedErrors$: Observable<NamedError<S>[]>;

  public readonly trackByIdentity = trackByIdentity;

  private readonly sectionErrorPipe = new SectionErrorPipe();
  private readonly settingsTabSectionStatusPipe = new SettingsTabSectionStatusPipe();
  private readonly pluckPipe = new PluckPipe();

  public ngOnInit(): void {
    this.namedErrors$ = combineLatest([
      this.errors$,
      this.counters$.pipe(startWith({} as Record<S, SettingsSectionsRequiredCounters<S>>)),
      this.visitedSections$.pipe(startWith(new Set<S>()))
    ]).pipe(
      debounceTime(100),
      distinctUntilChanged(equal),
      map(
        ([errors, counters, visitedSections]: [
          SettingsError<S>[],
          Record<S, SettingsSectionsRequiredCounters<S>>,
          ReadonlySet<S>
        ]) => {
          const errorSections: ReadonlySet<S> = new Set([
            ...errors.map(({ section }) => section),
            ...(Object.keys(counters) as S[])
          ]);

          return [...errorSections.keys()]
            .filter((errorSection: S) => {
              const error = this.sectionErrorPipe.transform(errors, errorSection);
              const tabCounters = this.pluckPipe.transform(counters, errorSection);

              const tabStatus: SectionStatus = this.settingsTabSectionStatusPipe.transform(
                tabCounters,
                error,
                visitedSections.has(errorSection)
              );

              return tabStatus === SectionStatus.WARNING;
            })
            .sort((a: S, b: S) => this.sectionsOrder.indexOf(a) - this.sectionsOrder.indexOf(b))
            .map((errorSection: S) => ({
              ...errors.find(({ section }) => section === errorSection),
              section: errorSection,
              name: this.sectionNamePipe.transform(errorSection)
            }));
        }
      ),
      share()
    );
  }

  public onClick({ section, control }: SettingsError<S>): void {
    this.navigated.emit({ section, control });
  }
}

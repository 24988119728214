import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgControl,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { Observable, Subject, distinctUntilChanged, filter, startWith, takeUntil } from 'rxjs';
import { HeaderConfig, MergeStrategy } from '@neuralegion/api';
import { CustomValidators, extractTouchedChanges } from '../../form';
import { KnownHeadersService } from '../../services';

export interface HeadersEditorSettings {
  nameValidators: ValidatorFn[];
  valueValidators: ValidatorFn[];
  validationTrigger$: Observable<unknown>;
  emptyPlaceholder: string;
}

export const DEFAULT_HEADER_NAME_VALIDATORS = [
  Validators.required,
  CustomValidators.byteSize(1024 * 8)
];

export const DEFAULT_HEADER_VALUE_VALIDATORS = [CustomValidators.byteSize(1024 * 16)];

@Component({
  selector: 'share-headers-editor',
  templateUrl: './headers-editor.component.html',
  styleUrls: ['./headers-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: HeadersEditorComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: HeadersEditorComponent,
      multi: true
    }
  ]
})
export class HeadersEditorComponent
  implements ControlValueAccessor, Validator, OnInit, AfterViewInit, OnDestroy
{
  @Input()
  public strategyMode = true;

  @Input()
  public copyAll = true;

  @Input()
  public settings?: Partial<HeadersEditorSettings>;

  public readonly listControl = new FormControl([]);
  public readonly autocompleteOptions = this.knownHeadersService.getHeaders();
  public readonly defaultValueFactory = (): HeaderConfig => ({
    name: '',
    value: '',
    ...(this.strategyMode ? { mergeStrategy: MergeStrategy.REPLACE } : {})
  });

  private readonly defaultSettings: HeadersEditorSettings = {
    validationTrigger$: null,
    nameValidators: [...DEFAULT_HEADER_NAME_VALIDATORS, CustomValidators.headerName],
    valueValidators: [...DEFAULT_HEADER_VALUE_VALIDATORS, CustomValidators.headerValue],
    emptyPlaceholder: 'No headers'
  };

  private onChange: (value: HeaderConfig[]) => void;
  private onTouched: () => void;
  public onValidatorChange: () => void;

  private readonly gc = new Subject<void>();

  constructor(
    private readonly knownHeadersService: KnownHeadersService,
    private readonly injector: Injector,
    private readonly cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.settings = {
      ...this.defaultSettings,
      ...(this.settings || {})
    };

    this.listControl.valueChanges
      .pipe(takeUntil(this.gc))
      .subscribe((value: HeaderConfig[]) => this.onChange?.(value));

    this.listControl.statusChanges
      .pipe(distinctUntilChanged(), takeUntil(this.gc))
      .subscribe(() => this.onValidatorChange?.());
  }

  public ngAfterViewInit(): void {
    this.initTouchedStateListeners();
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }

  public writeValue(rawValue: HeaderConfig[]): void {
    this.listControl.setValue(rawValue?.length ? rawValue : [], { emitEvent: false });
  }

  public registerOnChange(fn: (value: HeaderConfig[]) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  public registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  public setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.listControl.disable();
    } else {
      this.listControl.enable();
    }
  }

  public validate(): ValidationErrors | null {
    return this.listControl.valid ? null : { headersEditorError: true };
  }

  private initTouchedStateListeners(): void {
    const parentControl = this.injector.get(NgControl).control;

    extractTouchedChanges(parentControl)
      .pipe(startWith(parentControl.touched), distinctUntilChanged(), takeUntil(this.gc))
      .subscribe((touched: boolean) => {
        if (touched) {
          this.listControl.markAsTouched();
        } else {
          this.listControl.markAsUntouched();
        }
        this.cdr.markForCheck();
      });

    extractTouchedChanges(this.listControl)
      .pipe(
        distinctUntilChanged(),
        filter((touched: boolean) => touched),
        takeUntil(this.gc)
      )
      .subscribe(() => {
        this.onTouched?.();
      });
  }
}

import { Action } from '@ngrx/store';
import { FeedActivity, FeedSubscription } from '../models';

export const FEED_LOAD_LIMIT = 15;

export interface FeedState {
  readonly opened: boolean;
  readonly offset: number;
  readonly endReached: boolean;
  readonly unseen: number;
  readonly activities: FeedActivity[];
  readonly subscriptions: FeedSubscription[];
  readonly pending: Action[];
  readonly lastSuccess: Action;
  readonly error: string;
}

export const initialFeedState: FeedState = {
  opened: false,
  offset: 0,
  unseen: 0,
  endReached: false,
  activities: [],
  subscriptions: [],
  pending: [],
  lastSuccess: null,
  error: null
};

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  PipeTransform
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import { Subject, debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs';
import equal from 'fast-deep-equal/es6';
import { FilterConfig, FilterValueObject } from '@neuralegion/api';
import { trackByIdentity } from '@neuralegion/core';
import { extractTouchedChanges } from '../../form';

interface SearchFilterOptions {
  readonly config: readonly FilterConfig[];
  readonly filterNamePipe: PipeTransform;
  readonly placeholder: string;
}

@Component({
  selector: 'share-table-search-filter',
  templateUrl: './table-search-filter.component.html',
  styleUrls: ['./table-search-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: TableSearchFilterComponent, multi: true },
    { provide: NG_VALIDATORS, useExisting: TableSearchFilterComponent, multi: true }
  ]
})
export class TableSearchFilterComponent
  implements OnInit, OnDestroy, ControlValueAccessor, Validator
{
  @Input()
  public options: SearchFilterOptions;

  public form = new FormGroup({
    filter: new FormControl(null, [Validators.required]),
    value: new FormControl()
  });

  public filterLabelsMap: ReadonlyMap<string, string>;

  public readonly trackByIdentity = trackByIdentity;

  private readonly valueSubject = new Subject<FilterValueObject<unknown>>();

  private onChange: (value: FilterValueObject<string>) => void;
  private onTouched: () => void;

  private readonly gc = new Subject<void>();

  constructor() {}

  public ngOnInit() {
    this.filterLabelsMap = new Map(
      this.options.config.map((filterConfig: FilterConfig) => [
        filterConfig.name,
        this.options.filterNamePipe.transform(filterConfig.name)
      ])
    );

    this.initValueSubjectListener();
    this.initTouchedListener();
    this.initFormControlsListeners();
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }

  public writeValue(value: FilterValueObject<string>) {
    this.valueSubject.next(value);
  }

  public registerOnChange(fn: (value: FilterValueObject<string>) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public validate(_control: AbstractControl): ValidationErrors | null {
    return this.form.invalid ? { tableSearchFilter: true } : null;
  }

  public clearTextField(): void {
    this.form.controls.value.reset(''); // NLJ-3920
  }

  private initValueSubjectListener(): void {
    this.valueSubject
      .pipe(distinctUntilChanged(equal), takeUntil(this.gc))
      .subscribe((value: FilterValueObject<string>) => {
        this.form.setValue({
          filter: this.options.config.find(
            (filterConfig: FilterConfig) => filterConfig.name === value.name
          ),
          value: value.value || null
        });
      });
  }

  private initTouchedListener(): void {
    extractTouchedChanges(this.form)
      .pipe(
        filter((touched: boolean) => touched),
        takeUntil(this.gc)
      )
      .subscribe(() => this.onTouched?.());
  }

  private initFormControlsListeners(): void {
    this.form.controls.filter.valueChanges
      .pipe(
        filter(() => this.form.controls.filter.dirty),
        takeUntil(this.gc)
      )
      .subscribe((filterConfig: FilterConfig) => {
        this.clearTextField();
        this.form.controls.value.setValidators(filterConfig.settings.validators ?? []);
        setTimeout(() =>
          (
            this.form.controls.value as FormControl & { nativeElement: HTMLInputElement }
          ).nativeElement.focus()
        );
      });

    this.form.controls.value.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this.gc))
      .subscribe(
        (value: string) =>
          this.onChange?.({
            value,
            name: this.form.value.filter.name,
            type: this.form.value.filter.type,
            singular: this.form.value.filter.settings.singular
          })
      );
  }
}

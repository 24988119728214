import { BaseElementIdStrategy } from './base-element-id-strategy';

export class ButtonElementIdStrategy extends BaseElementIdStrategy {
  protected elementPrefix = 'btn';

  protected override getSubId(element: HTMLElement): string {
    return [
      this.elementPrefix,
      this.generateIdByText(
        element.getAttribute('aria-label') ||
          this.getInnerText(element) ||
          this.getInnerIconName(element)
      )
    ]
      .filter(Boolean)
      .join(this.SEPARATOR_INTERNAL);
  }

  private getInnerText(element: HTMLElement): string {
    return this.getElementText(
      element,
      (node: Node) => node instanceof HTMLElement && node.tagName === 'MAT-ICON'
    );
  }

  private getInnerIconName(element: HTMLElement): string | undefined {
    return element.querySelector('.mat-icon')?.textContent;
  }
}

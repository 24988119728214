import { AfterViewInit, Directive, Input, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { TableSettingsService } from '@neuralegion/browser-storage';
import { TableFiltersState } from '../models';

@Directive({
  selector: '[shareTableFiltersState]'
})
export class TableFiltersStateDirective implements AfterViewInit, OnDestroy {
  @Input()
  public shareTableFiltersState: TableFiltersState<unknown>;

  @Input()
  public shareTableFiltersStateKey: string;

  private readonly gc = new Subject<void>();

  constructor(private readonly tableSettingsService: TableSettingsService) {}

  public ngAfterViewInit(): void {
    this.shareTableFiltersState.applyFilters(
      this.tableSettingsService.getFilters(this.shareTableFiltersStateKey)
    );

    this.shareTableFiltersState.filters$
      .pipe(takeUntil(this.gc))
      .subscribe((filters: unknown) =>
        this.tableSettingsService.saveFilters(this.shareTableFiltersStateKey, filters)
      );
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }
}

import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input[blurOnEnter]'
})
export class InputBlurOnEnterDirective {
  @Input()
  public blurOnEnter: boolean | string;

  constructor(private readonly el: ElementRef) {}

  @HostListener('document:keydown.enter', ['$event'])
  public onKeydownHandler(e: KeyboardEvent): void {
    if (coerceBooleanProperty(this.blurOnEnter) && e.target === this.el.nativeElement) {
      (e.target as HTMLInputElement).blur();
    }
  }
}

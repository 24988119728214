import { Dictionary } from '@ngrx/entity';
import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { Project } from '../models';
import { loadProjects } from './projects-lookup.actions';
import { ProjectsLookupState, projectsLookupEntityAdapter } from './projects-lookup.state';

// eslint-disable-next-line @typescript-eslint/ban-types
type State = object;

const selectProjectsState = createFeatureSelector<ProjectsLookupState>('projectsLookup');

export const selectProjectEntities = createSelector<
  State,
  [ProjectsLookupState],
  Dictionary<Project>
>(selectProjectsState, projectsLookupEntityAdapter.getSelectors().selectEntities);

export const selectProjects = (ids: string[]): MemoizedSelector<State, Project[]> =>
  createSelector<State, [Dictionary<Project>], Project[]>(
    selectProjectEntities,
    (dictionary: Dictionary<Project>) => ids.map((id: string) => dictionary[id]).filter(Boolean)
  );

export const selectProject = (id: string): MemoizedSelector<State, Project | undefined> =>
  createSelector<State, [Dictionary<Project>], Project>(
    selectProjectEntities,
    (dictionary: Dictionary<Project>) => dictionary[id]
  );

export const selectPredefinedProject = createSelector<State, [Dictionary<Project>], Project>(
  selectProjectEntities,
  (projects: Dictionary<Project>) =>
    Object.values(projects).find((project: Project) => project.predefined)
);

export const selectProjectsLoadingByIdsPendingStatus = createSelector<
  State,
  [ProjectsLookupState],
  boolean
>(selectProjectsState, (state: ProjectsLookupState) =>
  state.pending.some((action) => action.type === loadProjects.type)
);

export const selectProjectsPendingStatus = createSelector<State, [ProjectsLookupState], boolean>(
  selectProjectsState,
  (state: ProjectsLookupState) => state.pending.length > 0
);

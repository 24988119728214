import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  Observable,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap
} from 'rxjs';
import { Store } from '@ngrx/store';
import { selectAuthActiveStatus } from '@neuralegion/auth-api';
import { BreadcrumbService, selectPendingStatus } from '@neuralegion/core';
import { WelcomeType } from '../../../models';

// eslint-disable-next-line @angular-eslint/use-component-selector
@Component({
  templateUrl: './auth-root.component.html',
  styleUrls: ['./auth-root.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthRootComponent implements OnInit {
  public readonly WelcomeType = WelcomeType;

  public pending$: Observable<boolean>;
  public welcomeType$: Observable<WelcomeType>;

  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    breadcrumbService: BreadcrumbService
  ) {
    breadcrumbService.configure([]);
  }

  public ngOnInit(): void {
    this.welcomeType$ = this.router.events.pipe(
      startWith(null as NavigationEnd),
      filter((e) => e instanceof NavigationEnd || !e),
      map(() => {
        let leafRoute = this.activatedRoute.firstChild;
        while (leafRoute.firstChild) {
          leafRoute = leafRoute.firstChild;
        }

        return leafRoute;
      }),
      switchMap((route) => route.data as Observable<{ welcomeType: WelcomeType }>),
      map((data) => data.welcomeType)
    );

    this.pending$ = combineLatest([
      this.store.select(selectPendingStatus),
      this.store.select(selectAuthActiveStatus)
    ]).pipe(
      map(([corePending, authActive]: [boolean, boolean]) => corePending && !authActive),
      distinctUntilChanged()
    );
  }
}

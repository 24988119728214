import { InjectionToken } from '@angular/core';

export interface CaptchaConfig {
  readonly maxFailedAccessAttempts: number;
  readonly reCaptchaSiteKey?: string;
  readonly invisibleCaptchaSiteKey?: string;
}

export const CAPTCHA_CONFIG_TOKEN: InjectionToken<CaptchaConfig> = new InjectionToken(
  'CaptchaConfig'
);

import { NgModule } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DATE_SETTINGS_TOKEN } from '@neuralegion/core';
import { DateSettingsGuard } from '../guards';
import { ProfileService } from '../services';
import { ProfileSettingsEffects } from '../store/profile-settings.effects';
import { profileSettingsReducer } from '../store/profile-settings.reducer';
import { ProfileEffects } from '../store/profile.effects';
import { profileReducer } from '../store/profile.reducer';
import { SessionsEffects } from '../store/sessions.effects';
import { sessionReducer } from '../store/sessions.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature('profile', profileReducer),
    EffectsModule.forFeature([ProfileEffects]),
    StoreModule.forFeature('profileSettings', profileSettingsReducer),
    EffectsModule.forFeature([ProfileSettingsEffects]),
    StoreModule.forFeature('sessions', sessionReducer),
    EffectsModule.forFeature([SessionsEffects])
  ],
  declarations: [],
  providers: [
    DateSettingsGuard,
    ProfileService,
    // eslint-disable-next-line rxjs/suffix-subjects
    [{ provide: DATE_SETTINGS_TOKEN, useValue: new BehaviorSubject(null) }]
  ],
  exports: []
})
export class ProfileApiModule {}

import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { Member } from '@neuralegion/api';
import { MembersState, membersEntityAdapter } from './members.state';
import { State } from './state';

const selectMembersState = createFeatureSelector<MembersState>('members');

const { selectAll } = membersEntityAdapter.getSelectors();

export const selectMemberById = (memberId: string): MemoizedSelector<State, Member> =>
  createSelector<State, [MembersState], Member>(
    selectMembersState,
    (state: MembersState): Member => state.entities[memberId]
  );

export const selectAllMembers = createSelector<State, [MembersState], Member[]>(
  selectMembersState,
  selectAll
);

export const selectMembersPendingStatus = createSelector<State, [MembersState], boolean>(
  selectMembersState,
  (state: MembersState): boolean => state.pending?.length > 0
);

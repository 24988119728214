import { Inject, Injectable, InjectionToken } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  DateFormat,
  DateRange,
  DateSettings,
  StartOfWeek,
  TimeFormat,
  TimeZone
} from '@neuralegion/api';
import {
  applyTz,
  endOfWeek,
  formatTzDate,
  parseDate,
  revertTz,
  startOfWeek,
  subDays
} from './date-utils';

export const DATE_SETTINGS_TOKEN: InjectionToken<BehaviorSubject<DateSettings>> =
  new InjectionToken<BehaviorSubject<DateSettings>>('DATE_SETTINGS_TOKEN');

export enum DateFormatAlias {
  DATE_TIME = 'DATE_TIME',
  DATE_TIME_SECONDS = 'DATE_TIME_SECONDS',
  DATE_ONLY = 'DATE_ONLY',
  TIME_ONLY = 'TIME_ONLY',
  TIME_ONLY_SECONDS = 'TIME_ONLY_SECONDS'
}

export interface DateFormatOptions {
  format: DateFormatAlias;
  dateFormat: DateFormat;
  timeFormat: TimeFormat;
  timeZone: TimeZone;
  customFormatString?: string;
}

@Injectable()
export class UserDateService {
  public readonly dateSettings$: Observable<DateSettings> = this.dateSettingsSubject.asObservable();

  private readonly DEFAULT_DATE_FORMAT = DateFormat.DMY_SLASH;
  private readonly DEFAULT_DATE_FORMAT_STRING = 'd/M/yyyy';

  constructor(
    @Inject(DATE_SETTINGS_TOKEN) private readonly dateSettingsSubject: BehaviorSubject<DateSettings>
  ) {}

  get dateSettings(): Readonly<DateSettings> {
    return Object.freeze(this.dateSettingsSubject.getValue());
  }

  private get tz(): TimeZone {
    return this.dateSettings.timeZone;
  }

  public getDefaultDateSettings(): DateSettings {
    const userOptions = Intl.DateTimeFormat().resolvedOptions();
    return {
      timeZone: userOptions.timeZone as TimeZone,
      startOfWeek: StartOfWeek.SUNDAY,
      dateFormat: this.DEFAULT_DATE_FORMAT,
      timeFormat: userOptions.hour12 ? TimeFormat.HOUR12 : TimeFormat.HOUR24
    };
  }

  public formatDate(
    date: Date | number | string,
    options: Partial<DateFormatOptions> = {}
  ): string {
    const opts = {
      format: DateFormatAlias.DATE_TIME,
      dateFormat: this.dateSettings.dateFormat,
      timeFormat: this.dateSettings.timeFormat,
      timeZone: this.tz,
      ...options
    };

    const dateFormatString = opts.customFormatString || this.getDateTimeFormatString(opts);
    return formatTzDate(date, dateFormatString, opts.timeZone);
  }

  public parse(dateStr: string): Date | null {
    return parseDate(dateStr, this.getDateFormatString(this.dateSettings.dateFormat));
  }

  public applyTz(date: Date): Date {
    return applyTz(date, this.tz);
  }

  public revertTz(date: Date): Date {
    return revertTz(date, this.tz);
  }

  public clearTime(date: Date): Date {
    const d = this.applyTz(date);
    d.setHours(0, 0, 0, 0);
    return this.revertTz(d);
  }

  public startOfWeek(date: Date): Date {
    return this.revertTz(
      startOfWeek(this.applyTz(date), { weekStartsOn: this.dateSettings.startOfWeek })
    );
  }

  public endOfWeek(date: Date): Date {
    return this.revertTz(
      endOfWeek(this.applyTz(date), { weekStartsOn: this.dateSettings.startOfWeek })
    );
  }

  public getAsDateRange(endDate: Date, numberOfDays: number): DateRange {
    return { startDate: subDays(endDate, numberOfDays), endDate };
  }

  public clearDateRangeTime(range: DateRange, revertTzRequired: boolean): DateRange {
    const endDate = this.clearTime(revertTzRequired ? this.revertTz(range.endDate) : range.endDate);
    endDate.setDate(endDate.getDate() + 1);
    endDate.setMinutes(endDate.getSeconds() - 1);

    return {
      endDate,
      startDate: this.clearTime(revertTzRequired ? this.revertTz(range.startDate) : range.startDate)
    };
  }

  private getTimeFormatString(
    timeFormat: TimeFormat,
    opts: { seconds: boolean } = { seconds: false }
  ): string {
    switch (timeFormat) {
      case TimeFormat.HOUR12:
        return opts.seconds ? 'h:mm:ss a' : 'h:mm a';
      case TimeFormat.HOUR24:
      default:
        return opts.seconds ? 'HH:mm:ss' : 'HH:mm';
    }
  }

  private getDateFormatString(dateFormat: DateFormat): string {
    switch (dateFormat) {
      case DateFormat.DMY_SLASH:
        return 'd/M/yyyy';
      case DateFormat.DMY_DOT:
        return 'd.M.yyyy';
      case DateFormat.DDMMY_SLASH:
        return 'dd/MM/yyyy';
      case DateFormat.DDMMY_DOT:
        return 'dd.MM.yyyy';
      case DateFormat.MDY_SLASH:
        return 'M/d/yyyy';
      case DateFormat.YMMDD_SLASH:
        return 'yyyy/MM/dd';
      case DateFormat.ISO:
        return 'yyyy-MM-dd';
      default:
        return this.DEFAULT_DATE_FORMAT_STRING;
    }
  }

  private getDateTimeFormatString(formatOptions: DateFormatOptions): string {
    const { dateFormat, timeFormat } = formatOptions;
    switch (formatOptions.format) {
      case DateFormatAlias.DATE_TIME:
        return `${this.getDateFormatString(dateFormat)}, ${this.getTimeFormatString(timeFormat)}`;
      case DateFormatAlias.DATE_TIME_SECONDS:
        return `${this.getDateFormatString(dateFormat)}, ${this.getTimeFormatString(timeFormat, {
          seconds: true
        })}`;
      case DateFormatAlias.DATE_ONLY:
        return this.getDateFormatString(dateFormat);
      case DateFormatAlias.TIME_ONLY:
        return this.getTimeFormatString(timeFormat);
      case DateFormatAlias.TIME_ONLY_SECONDS:
        return this.getTimeFormatString(timeFormat, {
          seconds: true
        });
    }
  }
}

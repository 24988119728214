import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[shareAutofocus]'
})
export class AutofocusDirective implements AfterViewInit {
  @Input('shareAutofocus')
  public autofocus: boolean | string;

  constructor(private readonly el: ElementRef<HTMLElement>) {}

  public ngAfterViewInit(): void {
    if (coerceBooleanProperty(this.autofocus)) {
      setTimeout(() => this.el.nativeElement.focus());
    }
  }
}

import { BaseElementIdStrategy } from './base-element-id-strategy';

export class MatSelectionListElementIdStrategy extends BaseElementIdStrategy {
  protected elementPrefix = ['selection', 'list'].join(this.SEPARATOR_INTERNAL);

  protected override getSubId(element: HTMLElement): string {
    return [
      this.elementPrefix,
      this.generateIdByText(element.getAttribute('aria-label')) || this.UNKNOWN_ID
    ]
      .filter(Boolean)
      .join(this.SEPARATOR_INTERNAL);
  }
}

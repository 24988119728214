import { Injectable, OnDestroy } from '@angular/core';

@Injectable()
export abstract class StorageCleanerService implements OnDestroy {
  protected abstract storageKeys: string[];

  public ngOnDestroy(): void {
    this.clearStorage();
  }

  protected clearStorage(): void {
    this.storageKeys.forEach((key) => this.clearKey(key));
  }

  protected abstract clearKey(key: string): void;
}

import { Pipe, PipeTransform } from '@angular/core';

interface BooleanPipeConfig {
  labelTrue: string;
  labelFalse: string;
}

const defaultConfig: BooleanPipeConfig = {
  labelTrue: 'Yes',
  labelFalse: 'No'
};

@Pipe({
  name: 'boolean',
  pure: true
})
export class BooleanPipe implements PipeTransform {
  public transform(value: unknown, config: BooleanPipeConfig = defaultConfig): string {
    const { labelTrue, labelFalse } = { ...defaultConfig, ...config };
    return value ? labelTrue : labelFalse;
  }
}

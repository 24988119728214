import { createAction } from '@ngrx/store';
import { MfaQrCode } from '@neuralegion/api';
import { MfaAnswer } from '../models';

export const loadMfaQrCode = createAction('[MFA] LOAD_MFA_QR_CODE');

export const loadMfaQrCodeSuccess = createAction(
  '[MFA] LOAD_MFA_QR_CODE_SUCCESS',
  (payload: MfaQrCode) => ({
    payload
  })
);

export const loadMfaQrCodeFail = createAction('[MFA] LOAD_MFA_QR_CODE_FAIL', (payload: string) => ({
  payload
}));

export const enableMfa = createAction(
  '[MFA] ENABLE_MFA',
  (payload: MfaAnswer & { forcedSetup: boolean }) => ({
    payload
  })
);

export const enableMfaSuccess = createAction(
  '[MFA] ENABLE_MFA_SUCCESS',
  (payload: { forcedSetup: boolean }) => ({
    payload
  })
);

export const enableMfaFail = createAction('[MFA] ENABLE_MFA_FAIL', (payload: string) => ({
  payload
}));

export const disableMfa = createAction('[MFA] DISABLE_MFA');

export const disableMfaSuccess = createAction('[MFA] DISABLE_MFA_SUCCESS');

export const disableMfaFail = createAction('[MFA] DISABLE_MFA_FAIL', (payload: string) => ({
  payload
}));

export const verifyMfaOtp = createAction(
  '[MFA] VERIFY_MFA_OTP',
  (payload: { code: string; token: string }) => ({
    payload
  })
);

export const verifyMfaOtpSuccess = createAction('[MFA] VERIFY_MFA_OTP_SUCCESS');

export const verifyMfaOtpFail = createAction('[MFA] VERIFY_MFA_OTP_FAIL', (payload: string) => ({
  payload
}));

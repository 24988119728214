import { createAction } from '@ngrx/store';
import { AuthProvider, IdPMetadata, ProviderType, SpMetadata } from '@neuralegion/api';
import { GitHubOrg } from '../models';

export const loadAuthProvider = createAction('[AuthProviders] LOAD');

export const loadAuthProviderSuccess = createAction(
  '[AuthProviders] LOAD_SUCCESS',
  (payload: AuthProvider) => ({
    payload,
    parentType: loadAuthProvider.type
  })
);

export const loadAuthProviderFail = createAction(
  '[AuthProviders] LOAD_FAIL',
  (payload: string) => ({
    payload,
    parentType: loadAuthProvider.type
  })
);

export const loadAuthProviderIdPMetadata = createAction(
  '[AuthProviders] LOAD_IDP_METADATA',
  (payload: { file: File }) => ({ payload })
);

export const loadAuthProviderIdPMetadataSuccess = createAction(
  '[AuthProviders] LOAD_IDP_METADATA_SUCCESS',
  (payload: IdPMetadata) => ({
    payload,
    parentType: loadAuthProviderIdPMetadata.type
  })
);

export const loadAuthProviderIdPMetadataFail = createAction(
  '[AuthProviders] LOAD_IDP_METADATA_FAIL',
  (payload: File) => ({
    payload,
    parentType: loadAuthProviderIdPMetadata.type
  })
);

export const loadAuthProviderSpMetadata = createAction('[AuthProviders] LOAD_SP_METADATA');

export const loadAuthProviderSpMetadataSuccess = createAction(
  '[AuthProviders] LOAD_SP_METADATA_SUCCESS',
  (payload: SpMetadata) => ({
    payload,
    parentType: loadAuthProviderSpMetadata.type
  })
);

export const loadAuthProviderSpMetadataFail = createAction(
  '[AuthProviders] LOAD_SP_METADATA_FAIL',
  (payload: string) => ({
    payload,
    parentType: loadAuthProviderSpMetadata.type
  })
);

export const disableAuthProvider = createAction(
  '[AuthProviders] DISABLE',
  (payload: { providerType: ProviderType }) => ({ payload })
);

export const disableAuthProviderSuccess = createAction(
  '[AuthProviders] DISABLE_SUCCESS',
  (payload: { providerType: ProviderType }) => ({ payload, parentType: disableAuthProvider.type })
);

export const disableAuthProviderFail = createAction(
  '[AuthProviders] DISABLE_FAIL',
  (payload: string) => ({
    payload,
    parentType: disableAuthProvider.type
  })
);

export const startSetupAuthProvider = createAction(
  '[AuthProviders] START_SETUP',
  (payload: AuthProvider) => ({
    payload
  })
);

export const startSetupAuthProviderSuccess = createAction(
  '[AuthProviders] START_SETUP_SUCCESS',
  (payload: AuthProvider) => ({ payload, parentType: startSetupAuthProvider.type })
);

export const startSetupAuthProviderFail = createAction(
  '[AuthProviders] START_SETUP_FAIL',
  (payload: string) => ({
    payload,
    parentType: startSetupAuthProvider.type
  })
);

export const updateAuthProvider = createAction(
  '[AuthProviders] UPDATE',
  (payload: AuthProvider) => ({ payload })
);

export const updateAuthProviderSuccess = createAction(
  '[AuthProviders] UPDATE_SUCCESS',
  (payload: AuthProvider) => ({
    payload,
    parentType: updateAuthProvider.type
  })
);

export const updateAuthProviderFail = createAction(
  '[AuthProviders] UPDATE_FAIL',
  (payload: string) => ({
    payload,
    parentType: updateAuthProvider.type
  })
);

export const loadGitHubOrgs = createAction('[AuthProviders] LOAD_GITHUB_ORGS');

export const loadGitHubOrgsSuccess = createAction(
  '[AuthProviders] LOAD_GITHUB_ORGS_SUCCESS',
  (payload: GitHubOrg[]) => ({ payload, parentType: loadGitHubOrgs.type })
);

export const loadGitHubOrgsFail = createAction(
  '[AuthProviders] LOAD_GITHUB_ORGS_FAIL',
  (payload: string) => ({
    payload,
    parentType: loadGitHubOrgs.type
  })
);

export const sendReminders = createAction('[AuthProviders] SEND_REMINDERS');

export const sendRemindersSuccess = createAction('[AuthProviders] SEND_REMINDERS_SUCCESS', () => ({
  parentType: sendReminders.type
}));

export const sendRemindersFail = createAction(
  '[AuthProviders] SEND_REMINDERS_FAIL',
  (payload: string) => ({
    payload,
    parentType: sendReminders.type
  })
);

import { Injectable } from '@angular/core';
import { Code } from './code';
import { CodeHexDiffService, DiffBlock } from './code-hex-diff.service';
import { CodeHexHtmlService } from './code-hex-html.service';
import { CodeHexLinesService } from './code-hex-lines.service';
import { PrettifyOptions, PrettifyResult, PrettifyResultFlags } from './code-prettify.service';
import { DiffResultItem, DiffService } from './diff.service';

@Injectable({
  providedIn: 'root'
})
export class CodeHexPrettifyService {
  constructor(
    private readonly diffService: DiffService,
    private readonly codeHexLinesService: CodeHexLinesService,
    private readonly codeHexDiffService: CodeHexDiffService,
    private readonly codeHexHtmlService: CodeHexHtmlService
  ) {}

  private async toHexDiffView(
    code: Code,
    thresholdDiffEntries: number,
    flags: PrettifyResultFlags
  ): Promise<PrettifyResult> {
    const diffResult: DiffResultItem[] = await this.diffService.diff(code);

    if (
      !diffResult ||
      diffResult.length > thresholdDiffEntries ||
      diffResult.every((item: DiffResultItem) => item[0] === 0)
    ) {
      return {
        ...flags,
        hexLines: this.codeHexLinesService.toHexLines(code.actual),
        diffEnabled: false,
        code: null
      };
    }

    const diffBlocks: DiffBlock[] = this.codeHexDiffService.prepareDiffBlocks(diffResult);
    return {
      ...flags,
      diffEnabled: true,
      code: null,
      hexLines: this.codeHexDiffService.formatHexDiff(code.actual, diffBlocks)
    };
  }

  public async prettify(
    code: Code,
    opts: PrettifyOptions,
    flags: PrettifyResultFlags
  ): Promise<PrettifyResult> {
    const res: PrettifyResult = flags.diffEnabled
      ? await this.toHexDiffView(code, opts.thresholdDiffEntries, flags)
      : {
          ...flags,
          diffEnabled: false,
          code: null,
          hexLines: this.codeHexLinesService.toHexLines(code.actual)
        };
    if (opts.hexHtml) {
      res.code = this.codeHexHtmlService.toHexHtml(res.hexLines);
    }

    return res;
  }
}

<share-server-filterable-select
  [aria-label]="'Select ' + filterConfig.name"
  [formControl]="formControl"
  [items$]="items$"
  [options]="{
    compact: true,
    multiple: settings.multiple,
    tmplOptionsHint: tmplMaxItemsLength,
    tmplItemsFooter,
    formatPipe,
    filterFieldValidators: settings.queryFieldValidators,
    isOptionDisabled,
    itemsSortComparator
  }"
  [pending]="settings.pending$ | async"
  (opened)="onOpenStateChanged($event)"
  (queryChanged)="onQueryChange($event)">
</share-server-filterable-select>

<ng-template #tmplMaxItemsLength>
  {{ totalSelectedItemsLength || 0 }} / {{ maxItemsLength || 0 }} labels selected
</ng-template>

<ng-template #tmplItemsFooter>
  <ng-container *ngIf="settings.couldBeRefined$ | async"> Refine your request to see more results </ng-container>
</ng-template>

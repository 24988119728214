import { DateSettings, MemberNotificationSettings } from '@neuralegion/api';

export interface ProfileSettingsState {
  readonly notifications: MemberNotificationSettings;
  readonly dateSettings: DateSettings;
  readonly error?: string;
  readonly pending: boolean;
}

export const initialProfileSettingsState: ProfileSettingsState = {
  error: null,
  pending: false,
  notifications: null,
  dateSettings: null
};

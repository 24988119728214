<form fxLayout="row" fxLayoutGap="16px" [formGroup]="form">
  <mat-form-field class="compact-field operator-field" fxFlex="1 1 auto">
    <mat-select [formControlName]="'operator'">
      <mat-option *ngFor="let operator of operators; trackBy: trackByIdentity" [value]="operator">
        {{ operator | comparisonOperatorName }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field *ngIf="form.value.operator" class="compact-field number-field" fxFlex="1 1 auto">
    <input type="number" blurOnEnter matInput shareAutofocus [formControlName]="'value'" />
  </mat-form-field>
</form>

<mat-form-field [class.compact-field]="options.compact" [class.custom-hint]="options.hintPosition === 'above'">
  <mat-label *ngIf="!options.noLabel">
    <ng-content select="[label]"></ng-content>
  </mat-label>

  <mat-error></mat-error>

  <mat-hint *ngIf="options.containsHint">
    <ng-content select="[mat-hint]"></ng-content>
  </mat-hint>

  <mat-select
    *ngIf="{
      data: filteredData$ | async,
      empty: empty$ | async
    } as sync"
    panelClass="panel-filterable-select {{ options.multiple ? 'multiple' : '' }}  {{
      options.compact ? 'compact' : ''
    }}"
    shareFilterableSelectRoleDialog
    [aria-label]="options.noLabel ? options.placeholder : null"
    [formControl]="selectControl"
    [multiple]="options.multiple"
    [placeholder]="options.placeholder"
    [required]="options.required"
    (openedChange)="onOpenedChange($event)">
    <mat-select-trigger fxLayout="row" fxLayoutAlign="space-between center">
      {{ selectControl.value | selectTriggerLabel: sync.data : options.itemName }}
    </mat-select-trigger>

    <div cdkTrapFocus shareFilterableSelectPanelFocus>
      <div class="controls-panel" shareFilterableSelectControlsKeydown>
        <share-text-filter-control
          *ngIf="options.searchField"
          #search
          [formControl]="textFilter"
          [options]="{ placeholder: 'Search…' }"></share-text-filter-control>

        <mat-checkbox
          *ngIf="sync.data?.length && !textFilter.value && options.multiple"
          aria-label="Select all"
          #selectAllCheckbox
          [disabled]="selectControl.disabled"
          (change)="onSelectAllChange($event, sync.data)">
          All
        </mat-checkbox>
      </div>

      <div
        *ngIf="!sync.empty; else tmplNoData"
        class="focus-ring"
        role="listbox"
        tabindex="0"
        [attr.aria-multiselectable]="options.multiple"
        #optionsPanel
        attr.aria-label="{{ options.itemName }} list"
        shareFilterableSelectOptionsFocus>
        <mat-option
          *ngFor="let item of sync.data; trackBy: trackByIdx"
          [disabled]="item.disabled"
          [matTooltip]="options.showTooltip ? item.label : null"
          [value]="item.id">
          <ng-container *ngTemplateOutlet="options.tmplItem; context: { $implicit: item }"></ng-container>
          <span *ngIf="!options.tmplItem">{{ item.label }}</span>
        </mat-option>
      </div>

      <ng-template #tmplNoData>
        <div class="empty">{{ textFilter?.value ? 'No results' : 'No data' }}</div>
      </ng-template>
    </div>
  </mat-select>
</mat-form-field>

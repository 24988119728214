import { createAction } from '@ngrx/store';
import { IndividualConfig } from 'ngx-toastr';
import { MqAlias } from '../models';

export const openSidebar = createAction('[Core] OPEN_SIDEBAR');

export const closeSidebar = createAction('[Core] CLOSE_SIDEBAR');

export const expandSidebar = createAction('[Core] EXPAND_SIDEBAR');

export const activateLayout = createAction('[Core] ACTIVATE_LAYOUT');

export const deactivateLayout = createAction('[Core] DEACTIVATE_LAYOUT');

export const mediaQueryChanged = createAction(
  '[Core] MEDIA_QUERY_CHANGED',
  (payload: { mqAliases: MqAlias[] }) => ({
    payload
  })
);

export const showSnackbar = createAction(
  '[Core] SHOW_SNACKBAR',
  (payload: {
    message: string;
    error: boolean;
    config: Partial<IndividualConfig>;
    title: string;
  }) => ({ payload })
);

export const hideSnackbarByMessage = createAction(
  '[Core] HIDE_SNACKBAR_BY_MESSAGE',
  (payload: { message: string }) => ({ payload })
);

export const showSnackbarWithReload = createAction(
  '[Core] SHOW_SNACKBAR_WITH_RELOAD',
  (payload: { message: string }) => ({ payload })
);

export const showSnackbarWithNavigation = createAction(
  '[Core] SHOW_SNACKBAR_WITH_NAVIGATION',
  (payload: {
    message: string;
    url: string;
    config: Partial<IndividualConfig>;
    title: string;
  }) => ({ payload })
);

export const closeAllDialogs = createAction('[Core] CLOSE_ALL_DIALOGS');

export const openInNewTab = createAction(
  '[Core] OPEN_IN_NEW_TAB',
  (payload: { url: string; closeAllDialogs: boolean }) => ({ payload })
);

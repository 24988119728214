import { BaseElementIdStrategy } from './base-element-id-strategy';

export class TableElementIdStrategy extends BaseElementIdStrategy {
  private readonly elementPrefix = 'table';

  protected override getSubId(element: HTMLElement): string {
    return [
      this.elementPrefix,
      this.generateIdByText(
        this.getTableCaptionText(element) || this.getParentWidgetText(element)
      ) || this.UNKNOWN_ID
    ]
      .filter(Boolean)
      .join(this.SEPARATOR_INTERNAL);
  }

  private getTableCaptionText(element: HTMLElement): string {
    return element.querySelector('caption')?.textContent;
  }

  private getParentWidgetText(element: HTMLElement): string {
    return element
      .closest('.viewer-container')
      ?.parentElement?.querySelector('.viewer-header-title')?.textContent;
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'auth-create-org',
  templateUrl: './create-org.component.html',
  styleUrls: ['./create-org.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateOrgComponent {
  @Output()
  public readonly created = new EventEmitter<{ name: string }>();

  public readonly form: FormGroup;

  constructor() {
    this.form = new FormGroup({
      name: new FormControl(null, Validators.required)
    });
  }

  public onSubmit(): void {
    this.created.emit(this.form.value);
  }
}

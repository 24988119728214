import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface Breadcrumb {
  readonly label: string;
  readonly url: string;
  readonly disable?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  private breadcrumbs: Map<string, Breadcrumb> = new Map<string, Breadcrumb>();
  private readonly breadcrumbsChangesSubject: BehaviorSubject<Breadcrumb[]> = new BehaviorSubject<
    Breadcrumb[]
  >([]);
  public readonly breadcrumbsChanges$: Observable<Breadcrumb[]> =
    this.breadcrumbsChangesSubject.asObservable();

  public getBreadcrumbs(): Breadcrumb[] {
    return Array.from(this.breadcrumbs.values());
  }

  public configure(config: Breadcrumb[]): void {
    this.breadcrumbs = new Map<string, Breadcrumb>(
      config
        .filter(({ disable = false }: Breadcrumb) => !disable)
        .map((breadcrumb: Breadcrumb): [string, Breadcrumb] => [breadcrumb.url, breadcrumb])
    );
    this.notifyAboutChanges();
  }

  private notifyAboutChanges(): void {
    this.breadcrumbsChangesSubject.next(this.getBreadcrumbs());
  }
}

<mat-card>
  <mat-card-header>
    <mat-card-title role="heading" aria-level="1">Forgot Password?</mat-card-title>
    <mat-card-subtitle>Enter your e-mail address below to reset your password</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <auth-forgot-password-form (submitted)="onSubmit($event)"> </auth-forgot-password-form>

    <auth-link-signin [message]="'Go back to'"></auth-link-signin>
  </mat-card-content>
</mat-card>

<sat-popover xAlign="center" yAlign="below" (closed)="onFeedClosed()" (opened)="onFeedOpened()">
  <feed-panel
    *ngIf="opened$ | async"
    class="popover-container"
    [attr.data-id]="'overlay_feed_panel'"
    (closeMenu)="popover.close()"></feed-panel>
</sat-popover>

<button
  aria-label="Activity feed"
  mat-icon-button
  matTooltip="Activity feed"
  [satPopoverAnchor]="popover"
  [sharePopoverTrigger]="popover">
  <mat-icon
    *ngIf="unseenCounter$ | async; else tmplNoNotifications"
    class="icon"
    aria-hidden="false"
    matBadgeSize="small"
    [matBadge]="(unseenCounter$ | async)?.toString(10)">
    notifications
  </mat-icon>

  <ng-template #tmplNoNotifications>
    <mat-icon class="icon">notifications_none</mat-icon>
  </ng-template>
</button>

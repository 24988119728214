import { Injectable } from '@angular/core';
import headers from './headers.json';

export interface HeaderMeta {
  name: string;
  description: string;
}

@Injectable({
  providedIn: 'root'
})
export class KnownHeadersService {
  public getHeaders(): HeaderMeta[] {
    return headers;
  }
}
